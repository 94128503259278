export default `<p>
    <strong>NEO（NEO）</strong>
</p>
<p>
    <br/>
</p>
<p>
    <strong>1.プロジェクト名</strong>
</p>
<p>
    NEO
</p>
<p>
    に
</p>
<p>
    <strong>2.プロジェクトのポジショニング</strong>
</p>
<p>
    スマートエコノミーの分散ネットワーク
</p>
<p>
    に
</p>
<p>
    <strong>3、プロジェクトの簡単なコメント</strong>
</p>
<p>
    NEOはブロックチェーンテクノロジーに基づいており、実物資産をデジタル資産に変換し、スマートコントラクトを通じてインテリジェントな管理を実現し、ユーザーはデジタル証明書認証機関を選択してID認証を実現します。 NEOは、NEOネットワークの管理権を実現するために使用されます。 NEOは、国内外で最も人気のあるパブリックチェーンプロジェクトの1つです。
</p>
<p>
    に
</p>
<p>
    <strong>4.プロジェクトの進捗状況</strong>
</p>
<p>
    2014年6月、NEOはプロジェクトを正式に設立し、シードラウンド投資を受け入れました。
</p>
<p>
    2015年10月、ICOの最初のラウンドが完了しました。
</p>
<p>
    2016年8月、ICOの第2ラウンドが完了しました。
</p>
<p>
    2016年10月、NEOメインネットが立ち上げられ、ブロックチェーンブラウザが立ち上げられました。
</p>
<p>
    2016年11月、AntsharesVM軽量ユニバーサルブロックチェーン仮想マシンのホワイトペーパーをリリースしました。
</p>
<p>
    2017年6月に、NEOに名前が変更され、スマートエコノミーの開発に重点を置くようにアップグレードされました。
</p>
<p>
    に
</p>
<p>
    <strong>5、プロジェクトの特徴</strong>
</p>
<p>
    1.国内外のNEOのコミュニティは活発であり、現在、コミュニティには200人以上の技術者がいます。
</p>
<p>
    2.技術文書が発表され、プロジェクトのコアテクノロジーが実現され、超電導トランザクションメカニズムが実現され、クロスチェーン相互運用性契約が徐々に実施されました。
</p>
<p>
    3. NEOは、デジタル資産の登録、流通、取引のためのインテリジェントなプラットフォームであり、幅広い用途があり、さまざまな技術サポートとプラグインを提供し、開発に便利です。
</p>
<p>
    に
</p>
<p>
    <strong>6、プロジェクトチーム</strong>
</p>
<p>
    創設者：華南理工大学を卒業したDa Hongfeiは、2011年にビットコインに連絡し、NEOの創設者であるOnchain（流通技術）のCEOであるブロックチェーンの研究を続けました。
</p>
<p>
    創設者：Zhang Zhengwenは、情報セキュリティの経験があり、後にブロックチェーンテクノロジーと接触し、NEOに参加しました。
</p>
<p>
    に
</p>
<p>
    <strong>7、ICOの進歩</strong>
</p>
<p>
    NEOには2ラウンドのICOがあります
</p>
<p>
    1. ICO時間：2015/10 / 20-2015 / 10/31
</p>
<p>
    ICO費用：0.25元/個
</p>
<p>
    ICOの進捗状況：100％完了
</p>
<p>
    2. ICO時間：2016/8 / 8-2016 / 9/8
</p>
<p>
    ICO費用：1.1元/個
</p>
<p>
    ICOの進捗状況：100％完了
</p>
<p>
    に
</p>
<p>
    <strong>8.プロジェクトコンサルタント</strong>
</p>
<p>
    番号
</p>
<p>
    に
</p>
<p>
    <strong>9、トークンの概要</strong>
</p>
<p>
    プロジェクトトークン：1億
</p>
<p>
    ICO比率：50％
</p>
<p>
    コミュニティのインセンティブ：10％
</p>
<p>
    チームのモチベーション：10％
</p>
<p>
    相互投資：15％
</p>
<p>
    その他：15％
</p>
<p>
    トークンの価格：0.25〜1.1元/個
</p>
<p>
    に
</p>
<p>
    10.プロジェクトの資金調達と評価
</p>
<p>
    ICO融資：フェーズ1融資は2100BTC、フェーズ2融資は6119BTCです。
</p>
<p>
    トークンの発行部数：6500万
</p>
<p>
    に
</p>
<p>
    11.トークンの生成と使用
</p>
<p>
    トークン生成：ICOセール
</p>
<p>
    トークン機能：NEO管理トークン保有者は、NEOネットワークの所有者および管理者です。管理権は、NEOネットワーク上で投票トランザクションを構築することによって実現され、NEO管理トークンに対応するGAS燃料トークンを取得することによって実現されます。NEOネットワークを使用する権利。
</p>
<p>
    に
</p>
<p>
    12.一般的なリンク
</p>
<p>
    公式サイト：https：//neo.org
</p>
<p>
    ホワイトペーパー：http：//docs.neo.org/zh-cn/
</p>
<p>
    <br/>
</p>
<p>
    13.通貨が配置されているゾーン
</p>
<p>
    イノベーションゾーン
</p>
<p>
    <br/>
</p>`