export default {
    title:"KYC 및 자금 세탁 방지 계약",
    content:`<p style="text-align: center;">
    <span style="font-size: 18px;"><strong>KYC 및 자금세탁방지 프로토콜</strong></span>
</p>
<p>
    코디 글로벌
</p>
<p>
    <br/>
</p>
<p>
    1. 소개
</p>
<p>
    &nbsp; &nbsp;1.1 우리는 고의로 고객 알기 및 자금세탁 방지 정책을 위반하지 않고 고객 알기 및 자금세탁 방지 관련 법규를 신중하게 준수할 것을 약속합니다. 합리적인 통제 범위 내에서 재정적으로 필요한 조치와 기술은 범죄 용의자의 자금 세탁으로 인한 손실로부터 귀하를 최대한 보호하기 위해 안전한 서비스를 제공합니다.
</p>
<p>
    <br/>
</p>
<p>
    1.2 당사의 고객 파악 및 자금 세탁 방지 정책은 고객 파악 및 귀하가 속한 다양한 법적 관할권의 자금 세탁 방지 정책을 포함하는 포괄적인 국제 정책 시스템입니다. 우리의 강력한 규정 준수 프레임워크는 지역 및 전 세계적으로 규정 요구 사항 및 규정 수준을 준수하고 이 웹 사이트의 지속적인 운영을 보장합니다.
</p>
<p>
    <br/>
</p>
<p>
    2 다음과 같이 고객 및 자금 세탁 방지 정책을 숙지하십시오.
</p>
<p>
    2.1 고객 알기 및 자금 세탁 방지 정책을 공포하고 해당 법률 및 규정에서 설정한 기준에 맞게 수시로 업데이트
</p>
<p>
    <br/>
</p>
<p>
    2.2 이 웹사이트의 운영을 위한 몇 가지 기본 원칙과 규칙을 공포하고 업데이트하며, 우리 직원은 원칙과 규칙의 지침에 따라 완전한 서비스를 제공할 것입니다.
</p>
<p>
    <br/>
</p>
<p>
    2.3 엄격한 방법으로 신원을 확인하고 자금 세탁 방지 업무를 담당할 전문 팀을 구성하는 등 거래에 대한 내부 모니터링 및 통제를 위한 절차를 설계하고 완료합니다.
</p>
<p>
    <br/>
</p>
<p>
    2.4 위험 방지 접근 방식을 사용하여 고객에 대한 실사 및 지속적인 모니터링 수행
</p>
<p>
    <br/>
</p>
<p>
    2.5 발생한 거래에 대한 검토 및 정기 점검
</p>
<p>
    <br/>
</p>
<p>
    2.6 관계 당국에 의심스러운 거래 보고
</p>
<p>
    <br/>
</p>
<p>
    2.7 신원 증명 문서, 주소 증명 및 거래 기록이 귀하에게 사전 통지 없이 규제 당국에 제출된 경우 최소 6년 동안 유지됩니다.
</p>
<p>
    <br/>
</p>
<p>
    2.8 전체 거래 과정에서 신용 카드 사용이 금지됩니다.
</p>
<p>
    <br/>
</p>
<p>
    2.9 관계 당국에서 제공하는 정기 교육에 참여하고 직원을 정기적으로 교육합니다.
</p>
<p>
    <br/>
</p>
<p>
    3 본인확인 및 본인확인
</p>
<p>
    &nbsp; &nbsp;3.1 신원 정보
</p>
<p>
    <br/>
</p>
<p>
    &nbsp; &nbsp;3.1.1 다른 관할권 및 다른 유형의 기관의 다른 규정에 따라 당사가 수집한 귀하의 정보 내용은 일치하지 않을 수 있습니다.원칙적으로 다음 정보는 등록된 개인으로부터 수집됩니다.
</p>
<p>
    <br/>
</p>
<p>
    &nbsp; &nbsp;기본 개인정보 : 이름, 거주지 주소(다른 경우 영구 주소), 생년월일, 국적 등 기타 정보 제공. 인증은 여권, ID 카드 또는 다른 관할 구역에서 요구하고 실행하는 기타 식별 문서와 같이 공무원 또는 기타 유사한 기관에서 발행한 문서를 기반으로 해야 합니다. 귀하가 제공한 주소는 여객 운송 청구서 또는 요금 청구서 확인 또는 유권자 등록 확인과 같은 적절한 방법을 사용하여 확인됩니다.
</p>
<p>
    <br/>
</p>
<p>
    유효한 사진: 등록하기 전에 가슴에 신분증을 들고 있는 사진을 제공해야 합니다.
</p>
<p>
    <br/>
</p>
<p>
    연락처: 전화/휴대폰 번호 및 유효한 이메일 주소
</p>
<p>
    <br/>
</p>
<p>
    3.1.2 귀하가 회사 또는 기타 법인인 경우 당사는 귀하 또는 신탁 계정의 최종 수혜자를 식별하기 위해 다음 정보를 수집합니다.
</p>
<p>
    <br/>
</p>
<p>
    회사 등록 및 등록 증명서, 회사 정관 및 각서 사본, 회사의 지분 구조 및 소유권 진술에 대한 자세한 인증 자료, 이 웹사이트에서 계좌 개설 결정 및 승인된 고객의 이사회 결의 이행을 증명 ; 회사 이사는 필요에 따라 대주주의 신원 증명서 및 이 웹사이트 계정의 승인된 서명인 회사의 주요 비즈니스 주소가 회사의 우편 주소와 다른 경우 우편 주소를 제공합니다. 회사의 현지 주소가 주요 비즈니스 주소와 일치하지 않는 경우 고위험 고객으로 간주되어 추가 문서를 제출해야 합니다.
</p>
<p>
    <br/>
</p>
<p>
    당사가 요구하는 기타 인증 및 기관에서 발행한 문서 및 다양한 관할 구역 및 다양한 유형의 법인의 다양한 규정에 따라 당사가 필요하다고 간주하는 문서
</p>
<p>
    <br/>
</p>
<p>
    3.1.3 영문,일문,한글로 본인확인이 가능하며, 영문,일문,한국어로 번역하여 공증해 주시기 바랍니다.
</p>
<p>
    <br/>
</p>
<p>
    3.2 확인 및 검증
</p>
<p>
    <br/>
</p>
<p>
    3.2.1 신원 문서의 양면을 제공해야 합니다.
</p>
<p>
    <br/>
</p>
<p>
    3.2.2 신분증을 가슴에 대고 있는 사진을 제공해야 합니다.
</p>
<p>
    <br/>
</p>
<p>
    3.2.3 증빙 서류의 사본은 일반적으로 원본 문서와 대조해야 합니다. 그러나 신뢰할 수 있고 적절한 인증자가 사본이 원본의 정확하고 완전한 복제임을 증명할 수 있는 경우 사본은 허용됩니다. 이러한 인증에는 대사, 사법 위원, 치안 판사 등이 포함됩니다.
</p>
<p>
    <br/>
</p>
<p>
    3.2.4 최종 수혜자 및 계정 통제를 식별하기 위한 요구 사항은 궁극적으로 직접 고객을 소유하거나 통제하는 개인을 식별하고/하거나 진행 중인 거래가 다른 사람을 대신하여 수행되는지 식별하는 것입니다. 사업체의 경우 대주주(의결권 10% 이상 보유자 등)의 신원을 확인하여야 한다. 일반적으로 25%의 지분을 보유하면 정상위험으로 간주하여 주주의 신원을 확인하여야 하며, 10% 이상의 의결권 또는 주식을 보유하고 있는 경우 고위험으로 판단하여 주주의 신원을 확인하여야 함
</p>
<p>
    <br/>
</p>
<p>
    4 거래 모니터링
</p>
<p>
    4.1 당사는 보안 및 실제 거래 조건에 따라 일일 거래 및 출금 한도를 수시로 설정 및 조정합니다.
</p>
<p>
    <br/>
</p>
<p>
    4.2 거래가 등록된 귀하에게 자주 집중되거나 합당하지 않은 경우, 당사의 전문 팀이 의심스러운지 여부를 평가하고 결정합니다.
</p>
<p>
    <br/>
</p>
<p>
    4.3 당사는 자체 판단에 의해 의심스러운 거래로 판명된 경우 거래를 중지하고 거래를 거부하는 등 제한 조치를 취할 수 있으며, 가능한 한 빠른 시일 내에 거래를 취소할 수도 있으며 동시에 주무관청에 보고할 수 있습니다. 다만, 당신에게 알리지 않을 것입니다
</p>
<p>
    <br/>
</p>
<p>
    4.4 우리는 국제 자금 세탁 방지 기준을 충족하지 않거나 정치적으로 노출된 사람으로 간주될 수 있는 관할 지역의 사람의 탑승 신청을 거부할 권리를 보유하고 의심스러운 것으로 판단되는 거래를 중단하거나 종료할 권리를 보유합니다. 시간, 그러나 우리는 당신에 대한 의무와 책임을 위반하지 않습니다
</p>
<p>
    <br/>
</p>`,
}