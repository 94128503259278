export default `<p>
    <strong>XTZ (Tezos)</strong>
</p>
<p>
    <br/>
</p>
<p>
    <strong>1. 프로젝트 이름</strong>
</p>
<p>
    Tezos (XTZ)
</p>
<p>
    <br/>
</p>
<p>
    <strong>2. 프로젝트 포지셔닝</strong>
</p>
<p>
    Tezos는 Turing 완전한 스마트 계약을 갖춘 블록 체인 네트워크입니다. 메인 네트워크는 2018 년 9 월에 공식적으로 시작되었습니다. Tezos는 빠르면 2014 년에 블록 체인 네트워크의 거버넌스 결함을 발견하고 처음으로 온 체인 자율성을위한 솔루션을 제안했습니다. Tezos 프로토콜은 매개 변수 수정 및 프로토콜의 반복적 인 업데이트를 용이하게하기 위해 신중하게 설계되었습니다. 온 체인 자율 시스템을 통해 토큰 소유자는 시스템의 업그레이드 경로와 우선 순위를 결정할 수 있으며, 이는 분쟁을 해결하고 네트워크 하드 포크에 작별을 고하는 데 도움이 될 것입니다. 또한 Tezos는 고유 한 LPOS 메커니즘을 채택하여 현재 전 세계 470여 명의 검증자가 네트워크 보호에 참여하고 있으며 코드 수준의 공식 검증 설계와 결합하여 전체 네트워크의 보안을 극대화합니다.
</p>
<p>
    <br/>
</p>
<p>
    <strong>3, 통화 개요 및 배포</strong>
</p>
<p>
    총 토큰 수 : 763,306,930 XTZ, 연간 약 5.5 % 증가
</p>
<p>
    유통량 : 607,489,041 XTZ
</p>
<p>
    토큰 가격 : $ 0.373995 USD
</p>
<p>
    <br/>
</p>
<p>
    <strong>4, 일반적으로 사용되는 링크</strong>
</p>
<p>
    프로젝트 웹 사이트 : https://tezos.com/
</p>
<p>
    재단 웹 사이트 : https://tezos.foundation/
</p>
<p>
    백서 : https://tezos.com/static/papers/white_paper.pdf
</p>
<p>
    블록 쿼리 : https://tezblock.io/
</p>
<p>
    <br/>
</p>
<p>
    <strong>5, 통화 영역</strong>
</p>
<p>
    혁신 존
</p>
<p>
    <br/>
</p>`