export default `<p>
XTZ (Tezos)
</p>
<p>
<br/>
</p>
<p>
<strong>1. Project name</strong>
</p>
<p>
Tezos (XTZ)
</p>
<p>
<br/>
</p>
<p>
<strong>2. Project positioning</strong>
</p>
<p>
Tezos is a blockchain network with Turing complete smart contracts. The main network has been officially launched in September 2018. Tezos has noticed the governance flaws of the blockchain network as early as 2014, and first proposed a solution for on-chain autonomy. The Tezos protocol has been carefully designed to facilitate parameter modification and iterative update of the protocol. Through the on-chain autonomous system, token holders can determine the upgrade route and priority of the system, which will help resolve disputes and bid farewell to network hard forks. In addition, Tezos adopts a unique LPOS mechanism. At present, more than 470 verifiers from all over the world have participated in the protection of the network. Coupled with the code-level formal verification design, the security of the entire network is maximized.
</p>
<p>
<br/>
</p>
<p>
<strong>3, currency overview and distribution</strong>
</p>
<p>
Total number of tokens: 763,306,930 XTZ, and increase at an annual rate of approximately 5.5%
</p>
<p>
Circulation: 607,489,041 XTZ
</p>
<p>
Token price: $0.373995 USD
</p>
<p>
<br/>
</p>
<p>
<strong>4, commonly used links</strong>
</p>
<p>
Project website: https://tezos.com/
</p>
<p>
Foundation website: https://tezos.foundation/
</p>
<p>
White paper: https://tezos.com/static/papers/white_paper.pdf
</p>
<p>
Block query: https://tezblock.io/
</p>
<p>
<br/>
</p>
<p>
<strong>5. The zone where the currency is located</strong>
</p>
<p>
Innovation Zone
</p>
<p>
<br/>
</p>`