export default `<p>
    <strong>QTUM (퀀텀 체인)</strong>
</p>
<p>
    <br/>
</p>
<p>
    <strong>1. 퀀텀 체인이란?</strong>
</p>
<p>
    <br/>
</p>
<p>
    Qtum Blockchain ( &quot;Quantum Chain&quot;또는 &quot;Qtum&quot;이라고 함)은 가치 전송 프로토콜 ( &quot;Value Transfer Protocol&quot;)을 통해 비트 코인 및 이더 리움 외에 제 3의 블록 체인 생태계를 개발하여 지점 간 가치 전송을 달성하기 위해 노력하고 있습니다. 그리고이 계약에 따라 여러 산업 (금융, 사물 인터넷, 공급망, 소셜, 게임 등)을 지원하는 분산 형 애플리케이션 개발 플랫폼 ( &quot;DApp 플랫폼&quot;)을 구축하십시오. 개선 된 버전의 비트 코인 핵심 인프라와 호환되는 버전의 이더 리움 가상 머신을 병합함으로써 퀀텀 체인은 비트 코인의 파괴 불가능한 블록 체인 네트워크를 보유 할뿐만 아니라 스마트 계약의 무한한 가능성을 가지고 있습니다.
</p>
<p>
    <br/>
</p>
<p>
    <strong>2. QTUM의 기능</strong>
</p>
<p>
    <br/>
</p>
<p>
    Dapp 사용 권한 / 토큰 투표 / 커뮤니티 자율성 / 사용 애플리케이션 기능 소비 / 결제.
</p>
<p>
    <br/>
</p>
<p>
    <strong>3. 세부 매개 변수</strong>
</p>
<p>
    <br/>
</p>
<p>
    총 토큰 : 100,196,328 QTUM
</p>
<p>
    총 유통량 : 73,696,328 QTUM
</p>
<p>
    ICO 가격 : $ 0.38
</p>
<p>
    <br/>
</p>
<p>
    <strong>4. 자주 사용하는 링크</strong>
</p>
<p>
    <br/>
</p>
<p>
    공식 웹 사이트 : https://www.qtum.org/zh/
</p>
<p>
    백서 : https://www.qtum.org/zh/white-papers
</p>
<p>
    <br/>
</p>`