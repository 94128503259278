export default `<h2 style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:48px">
<strong><span style="font-family: Times New Roman;font-size: 16px;background: rgb(255, 255, 255)">BHD (BitcoinHD (BHD))</span></strong>
</h2>
<p style=";padding: 0;line-height: 48px;background: rgb(255, 255, 255)">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">1. Project name</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">BitcoinHD (BHD)</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">Second, project positioning</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">BHD<span style="font-family:宋体">is based on</span><span style="font-family:Calibri"> Conditioned Proof Of Capacity </span><span style="font-family:宋体">’s new type of cryptocurrency, its main innovation is the adoption of a new type of conditional capacity proof consensus mechanism, the use of hard drives as consensus participants, allowing people Anyone can mine cryptocurrency. Hard disk mining makes</span><span style="font-family:Calibri">BHD</span><span style="font-family:宋体"> more decentralized, through mathematical algorithms and distributed Mining thus generates credit and value. </span><span style="font-family:Calibri">BHD</span><span style="font-family:宋体"> will be committed to building a valuable financial system that changes the way cryptocurrency is produced. </span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">3. Currency overview and distribution</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">Total supply:</span>2100<span style="font-family:宋体"> ">10,000 pieces</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">Current circulation:</span>6,457,200</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">4. Commonly used links</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">Official website:</span></span><a href="http:// www.btchd.org/"><span style=";font-family:&#39;Times New Roman&#39;;color:rgb(53,124,225)">www.btchd.org</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">White paper:</span>&nbsp;</span><a href="http: //www.btchd.org/BHD-%E7%99%BD%E7%9A%AE%E4%B9%A62.0.pdf"><span style=";font-family:&#39;Times New Roman&#39;;color:rgb(53,124,225)">www.btchd.org/BHD-<span style="font-family:宋体">White paper</span><span style="font-family:Times New Roman ">2.0.pdf</span></span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">Block query</span>:&nbsp;</span><a href=" http://www.btchd.org/explorer/"><span style=";font-family:&#39;Times New Roman&#39;;color:rgb(53,124,225)">www.btchd.org/explorer /</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p>
<span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
<p>
<br/>
</p>`