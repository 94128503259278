export default {
    title: "OTC交易规则",
    content: `<p>
    <span style="font-size: 18px;"><strong>OTC交易规则</strong></span>
</p>
<p>
    <br/>
</p>
<p>
    OTC交易规则
</p>
<p>
    <br/>
</p>
<p>
    OTC交易基本原则：
</p>
<p>
    1、本规则自发布之日起正式实施，并将不时予以修改或完善，修改或完善后的新规则自公布之日起即生效；
</p>
<p>
    2、如您不同意本规则及修改或完善后的规则内容，请立即停止使用平台服务；
</p>
<p>
    3、如您继续使用平台服务的，视为您完全理解并接受本规则及不时修改或完善后的规则内容，并自愿受其约束。
</p>
<p>
    <br/>
</p>
<p>
    买方应遵循的原则：
</p>
<p>
    创建订单后，买方应当：
</p>
<p>
    1、 在订单规定的付款时间内完成支付，再点击“我已付款”；
</p>
<p>
    2、 使用本人实名付款方式、按订单金额及订单指定的收款账户以实时到账方式完成订单支付；
</p>
<p>
    3、 选择非实时到账方式支付订单的，必须事先获得卖方同意；
</p>
<p>
    <br/>
</p>
<p>
    卖方应遵循的原则：
</p>
<p>
    生成订单后，卖方应当：
</p>
<p>
    1、 提供经平台认证的收款账户；
</p>
<p>
    2、 收到买方全额付款后，在订单规定时间内完成放行；
</p>
<p>
    3、 非经买方同意，不得要求买家/客服取消订单；
</p>
<p>
    4、 非经买方同意，不得对已创建的订单价格提出异议；
</p>
<p>
    <br/>
</p>
<p>
    注：非实时到账方式目前包括：Paypal转银行卡、Visa转银行卡、17:00-9:00超5万订单用单笔转账等方式，随着各支付通道业务的变化，非实时到账方式的具体类型将相应进行调整。
</p>
<p>
    <br/>
</p>
<p>
    用户应遵守反洗钱原则：
</p>
<p>
    <br/>
</p>
<p>
    1、用户应遵守平台的《StarthestarOTC平台反洗钱和反恐怖融资用户指引》并严格照此履行相关义务；
</p>
<p>
    <br/>
</p>
<p>
    2、用户应严格遵循平台对用户身份及账户的实名认证管理要求，确保在平台上显示的相关账号均系本人合法注册、使用的；
</p>
<p>
    <br/>
</p>
<p>
    3、用户在触发平台的反洗钱风控预警时，应积极配合平台的进一步反洗钱核实工作，并积极协助提供相关的风控举证材料（具体措施以届时平台风控审核要求为准）。
</p>
<p>
    <br/>
</p>
<p>
    买方异常
</p>
<p>
    <br/>
</p>
<p>
    处理措施
</p>
<p>
    <br/>
</p>
<p>
    1、订单创建后，买方主动取消订单，或者因付款超时而系统自动取消订单。
</p>
<p>
    <br/>
</p>
<p>
    当日达到4次，系统将限制买方买入功能；连续2日触发，系统将进一步限制买方账户部分功能。
</p>
<p>
    <br/>
</p>
<p>
    2、未付款的情况下，点击“我已付款”。
</p>
<p>
    <br/>
</p>
<p>
    卖方有权要求取消此笔订单，买方当日被投诉达到2次，系统将限制买方账户部分功能；此类投诉单笔金额超过5万资金的，系统将直接限制买方账户48小时。
</p>
<p>
    <br/>
</p>
<p>
    3、卖方无违规，买方未付款的情况下，无故点击“申诉”。
</p>
<p>
    <br/>
</p>
<p>
    卖方有权要求取消此笔订单，买方当日被投诉达到2次，系统将限制买方账户部分功能；
</p>
<p>
    <br/>
</p>
<p>
    4、未按照卖方订单详情页面显示的收款账户进行付款。
</p>
<p>
    <br/>
</p>
<p>
    卖家有权要求取消订单，数字资产归还给卖方，买方自行承担转错款的风险。
</p>
<p>
    <br/>
</p>
<p>
    5、未付款的情况下，优先点击“我已付款”，再进行支付。
</p>
<p>
    <br/>
</p>
<p>
    卖方有权选择继续成交或者退款后取消订单。
</p>
<p>
    1）如卖方选择成交，卖方用户需要在收到全部付款的10分钟内完成放行；
</p>
<p>
    2）如卖方选择取消订单，卖方应在订单申诉后2小时内完成退款，数字资产归还给卖方。
</p>
<p>
    <br/>
</p>
<p>
    6、未使用与平台账户实名一致的支付账户付款。
</p>
<p>
    <br/>
</p>
<p>
    卖方有权直接拒绝成交，卖方可在收到付款后的48小时内完成退款处理，数字资产归还给卖方。使用企业账户付款（不论法人与认证本人实名是否一致），依然属于非实名支付。买方因此类情况被投诉达到2次，系统将限制买方账户的部分功能。
</p>
<p>
    <br/>
</p>
<p>
    7、在订单规定的付款时间内付款。
</p>
<p>
    （1）买方已完成所有付款，订单被买方主动取消或被系统超时取消；
</p>
<p>
    （2）买方完成部分付款，但订单被买方主动取消或被系统超时取消。
</p>
<p>
    <br/>
</p>
<p>
    订单被买方主动取消以及订单被系统超时取消的，卖方有权选择继续成交或者退款不成交。
</p>
<p>
    <br/>
</p>
<p>
    1、如卖方选择成交，
</p>
<p>
    —卖方是普通用户，订单由平台直接重开，卖方需在重开后，10分钟内完成资产放行。
</p>
<p>
    —卖方是广告方用户，订单金额≤5万，请使用“重开订单并放行”完成资产放行操作；订单金额＞5万，请在订单重开10分钟内完成资产放行。
</p>
<p>
    <br/>
</p>
<p>
    2、如卖方选择取消订单，则卖方需在收到平台或者买方用户反馈后60分钟内完成退款，数字资产归还给卖方；
</p>
<p>
    <br/>
</p>
<p>
    8、如需要多笔付款，未完成所有转款的情况下，优先点击“我已付款”，再继续完成付款。
</p>
<p>
    <br/>
</p>
<p>
    1）提前与卖方沟通，卖方允许其行为的，卖方应继续完成订单；
</p>
<p>
    2）未征得卖方同意，卖方有权选择继续成交或者退款后取消订单。
</p>
<p>
    —如卖方选择继续成交，卖方应在收到所有付款的10分钟内完成放行。
</p>
<p>
    —如卖方选择取消订单，则卖方需在收到付款后30分钟内完成退款，数字资产归还给卖方。
</p>
<p>
    <br/>
</p>
<p>
    9、付款金额与订单金额不符。
</p>
<p>
    <br/>
</p>
<p>
    1）付款金额大于订单金额的，订单成交，卖家应于收到款项后30分钟内退还多余部分款项。
</p>
<p>
    2）付款金额小于订单金额的，卖方有权选择继续成交或者退款后要求取消订单。
</p>
<p>
    —如卖方选择继续成交，有权按实际订单金额进行成交，并完成此订单。
</p>
<p>
    —如卖方选择取消订单，则卖方需在收到付款后30分钟内完成退款，数字资产归还给卖方。
</p>
<p>
    <br/>
</p>
<p>
    10、买方点击“我已付款”后，卖方1小时内未收到付款。
</p>
<p>
    <br/>
</p>
<p>
    1）卖方有权选择继续成交或退款后取消订单。
</p>
<p>
    —如卖方选择成交，卖方需要在收到付款的10分钟内完成放行。
</p>
<p>
    —如卖方选择取消订单，则卖方需在收到付款后30分钟内完成退款，数字资产归还给卖方。
</p>
<p>
    <br/>
</p>
<p>
    2）卖方是广告发布方，申诉后10分钟内无法联系上买方，经核实卖方未收到款，在广告方保证资产范围内申诉专员会优先取消订单。
</p>
<p>
    <br/>
</p>
<p>
    11、付款到账后，卖方收款账户无法核实到账实名信息。
</p>
<p>
    <br/>
</p>
<p>
    1）经核实买方付款账户与平台实名账户一致，且在订单规定时间内完成了付款，此订单正常成交。
</p>
<p>
    <br/>
</p>
<p>
    2）经核实买方付款账户与平台实名账户不一致，按上述第6条措施处理。
</p>
<p>
    <br/>
</p>
<p>
    12、如买方付款后卖方收款账户异常。
</p>
<p>
    <br/>
</p>
<p>
    1）买方无法提供账户正常证明，卖方应在收款账户转至正常后，24小时内完成退款，平台取消订单。
</p>
<p>
    2）买方能提供账户正常证明，在核实买方账户无异常后，订单成交，数字资产应交付买方。
</p>
<p>
    <br/>
</p>
<p>
    如：卖方为加V用户，在保证资产范围内，数字资产归还给卖方，卖方应在收款账户转至正常后，24小时内完成退款。
</p>
<p>
    <br/>
</p>
<p>
    13、付款时备注：类似”数字货币、BTC、比特币、Starthestar“等字样的。
</p>
<p>
    <br/>
</p>
<p>
    卖方有权选择继续成交或退款不成交。
</p>
<p>
    1）如卖方选择成交，卖方应在收到付款的10分钟内完成放行。
</p>
<p>
    2）如卖方选择退款不成交，此订单取消，数字资产归还给卖方，卖方在收到付款后60分钟内完成退款；如卖方收款账户因此被冻结，按上述第12条措施处理。
</p>
<p>
    <br/>
</p>
<p>
    14、买方用户被风控系统判断恶意抢拍低价单
</p>
<p>
    <br/>
</p>
<p>
    1）买方未付款，平台将取消订单，资产归还给卖方。
</p>
<p>
    <br/>
</p>
<p>
    2）买方已付款，请卖方在申诉处理的10分钟内完成退款处理，平台将协助取消订单，资产归还给卖方。
</p>
<p>
    <br/>
</p>
<p>
    买方此类行为达到两次，风控系统将会限制买方账户部分功能。
</p>
<p>
    <br/>
</p>
<p>
注：本规则涉及卖方退款或放行，而卖家未按时完成操作的，系统将限制卖方账户部分功能。
</p>
<p>
    <br/>
</p>
<p>
    &nbsp; &nbsp;注：OTC交易使用三方电话系统过程中如您主动挑起语言冲突，有辱骂、嘲讽或其他语言过激行为等情况，系统将限制您账户部分功能。
</p>
<p>
    <br/>
</p>
<p>
    卖方异常
</p>
<p>
    <br/>
</p>
<p>
    处理措施
</p>
<p>
    <br/>
</p>
<p>
    1、卖方为订单详情页面展示的收款账户、以及订单聊天窗口发送的收款账户负全部责任；
</p>
<p>
    <br/>
</p>
<p>
    卖方提供的收款账户非本人所有，或受他人引导绑定他人收款账户，或发送他人账户到聊天窗。买方完成付款后，资产判定将属于买方。
</p>
<p>
    <br/>
</p>
<p>
    2、订单生成后，对订单价格有异议的。
</p>
<p>
    <br/>
</p>
<p>
    除经买方同意协商订单价格外，订单正常成交，卖方应在订单规定时间内放行。
</p>
<p>
    <br/>
</p>
<p>
    3、卖方所提供的收款账户出现异常。&nbsp;
</p>
<p>
    <br/>
</p>
<p>
    买方有权选择继续成交或取消订单。
</p>
<p>
    —如买方选择成交，确认买方已在订单规定的时间内全额付款的，卖家应在收到付款后，在订单规定的时间完成放行。
</p>
<p>
    —如买方选择取消订单的，卖方完成退款后数字资产归还给卖方。
</p>
<p>
    <br/>
</p>
<p>
    4、因卖方未及时提供有效的收款方式，买方无法完成付款，导致订单取消。
</p>
<p>
    <br/>
</p>
<p>
    因卖方不提供收款方式，此订单无法成交，平台在处理申诉时，会直接取消订单，并对卖方用户做处罚，平台取消订单不计买方当天取消次数；
</p>
<p>
    <br/>
</p>
<p>
    卖方因此被投诉达到2次，系统将限制卖方账户部分功能。
</p>
<p>
    <br/>
</p>
<p>
    5、卖方（非广告方），未绑定与本人实名一致的有效收款信息或向买方提供与平台实名不一致的收款方式，
</p>
<p>
    <br/>
</p>
<p>
    如：通过支付宝或者微信收款时，账号以及二维码收款人姓名与订单详情页展示的收款人姓名不匹配。
</p>
<p>
    <br/>
</p>
<p>
    1）买方有权取消订单并向平台投诉。
</p>
<p>
    2）卖方因此被投诉达到3次，系统将会永久限制卖方账户。
</p>
<p>
    买方选择继续成交，因此被投诉达到2次，系统将限制买方账户部分功能。
</p>
<p>
    <br/>
</p>
<p>
    6、卖方收取买方非平台实名一致的账户付款，未拒绝成交的。
</p>
<p>
    <br/>
</p>
<p>
    1）由此引发的风险，由卖方自行承担。
</p>
<p>
    2）因此而被投诉的，系统将限制卖方账户部分功能。
</p>
<p>
    <br/>
</p>
<p>
    7、买方全额付款后标记“我已付款”，卖方收到买方全额付款后，未在20分钟内完成放行。
</p>
<p>
    <br/>
</p>
<p>
    买方有权选择继续成交或取消订单后要求退款。
</p>
<p>
    —如买方选择成交，申诉专员将判定资产属于买方。
</p>
<p>
    —如买方要求退款后取消订单，卖方应在30分钟内完成退款。
</p>
<p>
    <br/>
</p>
<p>
    如：买方在标记付款20分钟以内点击申诉，导致卖方无法放行，且卖方在聊天窗无法与买方取得联系，此订单将正常成交。
</p>
<p>
    <br/>
</p>
<p>
    &nbsp; &nbsp;注：OTC交易使用三方电话系统过程中如您主动挑起语言冲突，有辱骂、嘲讽或其他语言过激行为等情况，系统将限制您账户部分功能。
</p>
<p>
    <br/>
</p>
<p>
    触发风控异常
</p>
<p>
    <br/>
</p>
<p>
    处理措施
</p>
<p>
    <br/>
</p>
<p>
    1、当用户被风控系统检测到异常，用户明确拒绝或不积极配合完成平台风控要求的进一步反洗钱核查工作。
</p>
<p>
    <br/>
</p>
<p>
    本平台将有权选择直接认定为此账户异常，并对此采取包括但不限于上调用户风险等级、直接冻结此类用户的账户及关联账户直至用户配合提供的风控举证材料已通过平台的风控审核。
</p>
<p>
    <br/>
</p>
<p>
    2、当用户被核实从StarthestarOTC买币去其他平台/场外群售出以获利等其他高危行为。
</p>
<p>
    <br/>
</p>
<p>
    本平台将视情况有权选择采取包括但不仅限于延迟提币、限制交易、冻结账户等处置措施；
</p>
<p>
    <br/>
</p>
<p>
    注：此类行为一经发现，如有进行中的订单，广告方有权选择不成交。
</p>
<p>
    <br/>
</p>
<p>
    3、账户非本人使用或为他人代买卖币：当用户被平台风控核实出账户存在出售、出借、租赁自身账户为他人使用的情况，或存在为他人代买卖币的行为。
</p>
<p>
    风险提醒：请注意自身信息安全，避免被他人利用进行犯罪行为。
</p>
<p>
    <br/>
</p>
<p>
    本平台将有权选择包括但不限于暂时或永久限制OTC交易、延迟提币、限制提币或直接冻结此类用户的账户及关联账户等某项或多项措施。
</p>
<p>
    <br/>
</p>
<p>
    注：此类行为一经发现，如有进行中的订单，广告方有权选择不成交。
</p>
<p>
    <br/>
</p>
<p>
    4、利用平台其他用户测卡：用户在订单未发生的情况下向平台其他用户小额打款，且无法合理解释该行为时。
</p>
<p>
    <br/>
</p>
<p>
    本平台将有权选择暂时或永久限制OTC交易、限制提币或冻结账户等某项或多项措施。
</p>
<p>
    <br/>
</p>
<p>
    5、接收或引入来源不合法的数字资产或资金：当用户明知或据合理常识判断可推知在其他平台/微信群收到的币为黑币或资金为黑钱时，但仍无视风险并直接将该黑币/黑钱流入到本平台其它用户的账户。
</p>
<p>
    <br/>
</p>
<p>
    本平台将直接永久限制此类用户的账户及关联账户全部功能。
</p>
<p>
    <br/>
</p>
<p>
    6、被认定洗钱的用户：当用户被风控系统检测或风控工作人员核实，用户直接参与或协助洗黑钱等犯罪行为。
</p>
<p>
    <br/>
</p>
<p>
    本平台将直接永久限制此类用户的账户及关联账户全部功能。
</p>
<p>
    <br/>
</p>
<p>
    7、被司法冻结：当用户账户被司法机构要求冻结时。
</p>
<p>
    <br/>
</p>
<p>
    本平台将严格按照司法机构出示的司法函件对用户账户进行冻结。当冻结期满，司法机构需出示续冻函方可继续冻结。冻结期间，用户向本平台申请解冻无效，请联系冻结机构协调。
</p>
<p>
    <br/>
</p>
<p>
    8、被司法调证：用户被司法机构要求调取本平台账户资料，包括但不限于：司法调证、咨询等形式。
</p>
<p>
    <br/>
</p>
<p>
    本平台将视情况限制此类用户的账户及关联账户的部分功能。
</p>
<p>
    <br/>
</p>
<p>
    客服邮箱 starthestar@starthestar.com
</p>
<p>
    <br/>
</p>`,
}