import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Download from '../views/Download.vue'
import Swiper from '../views/Swiper.vue'
import Home from '../views/Home.vue'
import Signup from '../views/Signup.vue'
import Login from '../views/Login.vue'
import Retrieve from '../views/Retrieve.vue'
import Trade from '../views/Trade_.vue'
import Wallet from '../views/Wallet.vue'
import Records from '../views/Records.vue'
import User from '../views/User.vue'
import Certified from '../views/Certified.vue'
import ContractInfo from '../views/ContractInfo.vue'
import LeverInfo from '../views/LeverInfo.vue'
import CoinsInfo from '../views/CoinsInfo.vue'
import FeeHistoryInfo from '../views/FeeHistoryInfo.vue'

// k线
import KlineChart from '../components/KlineChart'
// 币币交易
import CoinsRecord from '../components/coins/CoinsRecord'
import CoinsTrade from '../components/coins/CoinsTrade'

//杠杆交易
import LeverRecord from '../components/lever/LeverRecord'
import LeverTrade from '../components/lever/LeverTrade'

//秒合约交易
import ContractRecord from '../components/contract/ContractRecord'
import ContractTrade from '../components/contract/ContractTrade'

//金属杠杆交易
import TradingRecord from '../components/trading/TradingRecord'
import TradingTrade from '../components/trading/TradingTrade'

// //金属杠杆交易
// import MetalRecord from '../components/metal/MetalRecord'
// import MetalTrade from '../components/metal/MetalTrade'

//金属杠杆交易
// import EnergyRecord from '../components/energy/EnergyRecord'
// import EnergyTrade from '../components/energy/EnergyTrade'

// 帮助
import HBox from '../views/Help/Box'

import HIndex from '../views/Help/Index'
import HType from '../views/Help/Type'
import HContent from '../views/Help/Content'

// 新闻
import News from '../views/Help/News'
import Test from '@/views/Test'

//文档
import Docs from '@/views/Help/Docs'
import Asset from '@/views/Help/Asset'
import Guide from '@/views/Help/Guide'


//新币认购
import NewBox from '@/views/NewCurrency/Box'
import NewIndex from '@/views/NewCurrency/Index'
import NewTrade from '@/views/NewCurrency/Trade'

Vue.use(VueRouter)

const routes = [{
        path: '/swiper',
        name: 'Swiper',
        component: Swiper
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/test',
        name: 'Test',
        component: Test,
        meta: {
            // 页面标题title
            // title: '标题'
        }
    },
    {
        path: '/',
        name: 'Index',
        component: Index,
        meta: {
            // 页面标题title
            // title: '标题'
        }
    },
    {
        path: '/download',
        name: 'Download',
        component: Download,
        meta: {
            // 页面标题title
            // title: '标题'
        }
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
        children: [{
                path: '/signup',
                name: 'Signup',
                component: Signup
            },
            {
                path: '/login',
                name: 'Login',
                component: Login
            },
            {
                path: '/retrieve',
                name: 'Retrieve',
                component: Retrieve
            },
            {
                path: '/trade',
                name: 'Trade',
                component: Trade
            },
            {
                path: '/contract_info',
                name: 'ContractInfo',
                component: ContractInfo
            },
            {
                path: '/lever_info',
                name: 'LeverInfo',
                component: LeverInfo
            },
            {
                path: '/coins_info',
                name: 'CoinsInfo',
                component: CoinsInfo
            },
            {
                path: '/fee_history_info',
                name: 'FeeHistoryInfo',
                component: FeeHistoryInfo
            },
            {
                path: '/kline_chart',
                component: KlineChart,
                children: [{
                        path: '/coins',
                        name: 'Coins',
                        components: {
                            record: CoinsRecord,
                            trade: CoinsTrade
                        }
                    },
                    {
                        path: '/lever',
                        name: 'Lever',
                        components: {
                            record: LeverRecord,
                            trade: LeverTrade
                        }
                    },
                    {
                        path: '/contract',
                        name: 'Contract',
                        components: {
                            record: ContractRecord,
                            trade: ContractTrade
                        }
                    },
                    {
                        path: '/trading',
                        name: 'Trading',
                        components: {
                            record: TradingRecord,
                            trade: TradingTrade
                        }
                    }
                ],
            },
            {
                path: '/wallet',
                name: 'Wallet',
                meta:{isLogin:true},
                component: Wallet
            },
            {
                path: '/records',
                name: 'Records',
                meta:{isLogin:true},
                component: Records
            },
            {
                path: '/user',
                name: 'User',
                meta:{isLogin:true},
                component: User
            },
            {
                path: '/certified',
                name: 'Certified',
                meta:{isLogin:true},
                component: Certified
            }
        ]
    },
    {
        path: '/hbox',
        name: 'HBox',
        component: HBox,
        children: [{
                path: '/hindex',
                name: 'HIndex',
                component: HIndex
            },
            {
                path: '/htype',
                name: 'HType',
                component: HType
            },
            {
                path: '/hcontent',
                name: 'HContent',
                component: HContent
            },
            {
                path: '/news',
                name: 'News',
                component: News,
            },
            {
                path: '/docs',
                name: 'Docs',
                component: Docs,
            },
            {
                path: '/asset',
                name: 'Asset',
                component: Asset,
            },
            {
                path: '/guide',
                name: 'Guide',
                component: Guide,
            },
        ]
    },
    {
        path: '/',
        component: NewBox,
        children: [{
                path: '/new_currency',
                name: 'NewCurrency',
                component: NewIndex
            },
            {
                path: '/new_trade',
                name: 'NewTrade',
                component: NewTrade
            },
        ]
    }
]

const router = new VueRouter({
    mode: 'hash', //'history',
    base: process.env.BASE_URL,
    routes
})

// 导航守卫
router.beforeEach((to, from, next) => {
    //目的地是否需要登录
    if (to.meta.isLogin) {
      const token = localStorage.getItem("token");
      if (to.name !== "Login" && !token) {
        return next({ name: "Login" });
      }
    }
    next();
  });

export default router