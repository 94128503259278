<template>
<div class="route-box">
    <div class="theme--light" :style="'height:'+(screenHeight - 100)+'px;'">
        <div>
            <div class="head-top">
                <div class="head-box">
                    <div class="head-avatar">
                        <img src="../../static/imgs/t2.png" alt="" style="">
                    </div>
                    <div class="head-div">
                        <div>
                            <div class="head-name">
                                <span>Hi {{email}}</span>
                                <div class="head-uid">UID:{{uid}}</div>
                            </div>
                            <div class="head-desc">
                                <!-- <span>最后登录时间: 2021-06-16 15:38:32 </span> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="box-list">
                <div class="box-div">
                    <!-- <div class="box-icon">
                                    <img src="../../static/imgs/btc.png" alt="">
                                </div> -->
                    <div class="box-span">
                        <div>
                            <div class="box-title">{{$t('user.account')}}</div>
                            <div class="box-desc">{{$t('user.account_desc')}}</div>
                        </div>
                    </div>
                    <div class="box-right">
                        <router-link :to="{name:(is_auth == 0 || is_auth == 3?'Certified':'')}">
                            <span class="box-r-span">{{getAuthText()}}</span>
                        </router-link>
                    </div>
                </div>
                <div class="box-div">
                    <div class="box-span">
                        <div>
                            <div class="box-title">{{$t('user.password')}}</div>
                            <div class="box-desc">{{$t('user.password_desc')}}</div>
                        </div>
                    </div>
                    <div class="box-right" @click="onPwdBtn">
                        <span class="box-r-span">{{$t('user.modify')}}</span>
                    </div>
                </div>
                <div class="box-div">
                    <div class="box-span">
                        <div>
                            <div class="box-title">{{$t('user.pay_password')}}</div>
                            <div class="box-desc">{{$t('user.pay_password_desc')}}</div>
                        </div>
                    </div>
                    <div style="display: flex;">
                        <div class="box-right" @click="onFindPayBtn" v-if="is_pay_pwd == 2">
                            <span class="box-r-span">{{$t('user.find_paypwd')}}</span>
                        </div>
                        <div class="box-right" @click="onPayBtn">
                            <span class="box-r-span">{{is_pay_pwd == 1?$t('user.set'):$t('user.modify')}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 弹出窗口-修改密码 -->
        <div class="mt-7">
            <div :class="'my-modal modal theme--light ' + (updatePwd?'is-active':'')">
                <div class="modal-background"></div>
                <div class="modal-card border-radius-medium" style="">
                    <header class="modal-card-head has-bg-level-2 has-line-level-1 pa-7">
                        <div class="modal-card-title title-medium">
                            <p class="modal-card-title-log pb-3">{{$t('base.title')}}</p>
                            <div>{{$t('user.update_pwd')}}</div>
                        </div>
                        <div class="icomBox has-bg-level-1 cursor-pointer "><i class="iconfont icon-TurnOff" @click="onPwdBtn"></i></div>
                    </header>
                    <section class="modal-card-body has-bg-level-2 pa-7">
                        <div class="form-item">
                            <div class="form-item-title">{{$t('user.old_pwd')}}</div>
                            <div class="form-item-content form-item-content-btns">
                                <input type="password" :placeholder="$t('user.enter_old_pwd')" class="input" v-model="old_password">
                                <div class="btns-box pr-3">
                                    <span class="pr-2"></span>
                                </div>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-title">{{$t('user.new_pwd')}}</div>
                            <div class="form-item-content form-item-content-btns">
                                <input type="password" :placeholder="$t('user.enter_new_pwd')" class="input" v-model="new_password">
                                <div class="btns-box pr-3">
                                    <span class="pr-2"></span>
                                </div>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-title">{{$t('user.res_pwd')}}</div>
                            <div class="form-item-content form-item-content-btns">
                                <input type="password" :placeholder="$t('user.enter_res_pwd')" class="input" v-model="res_password">
                                <div class="btns-box pr-3">
                                    <span class="pr-2"></span>
                                </div>
                            </div>
                        </div>
                    </section>
                    <footer class="modal-card-foot has-bg-level-2 pa-7">
                        <button class="button is-primary font-size-2 has-text-white modal-default-btn button-large " @click="onSubmit">{{$t('user.submit')}}</button>
                    </footer>
                </div>
            </div>
        </div>

        <!-- 弹出窗口-修改密码 -->
        <div class="mt-7">
            <div :class="'my-modal modal theme--light ' + (updatePay?'is-active':'')">
                <div class="modal-background"></div>
                <div class="modal-card border-radius-medium" style="">
                    <header class="modal-card-head has-bg-level-2 has-line-level-1 pa-7">
                        <div class="modal-card-title title-medium">
                            <p class="modal-card-title-log pb-3">{{$t('base.title')}}</p>
                            <div>{{is_pay_pwd == 1?$t('user.set_pay_pwd'):$t('user.update_pay_pwd')}}</div>
                        </div>
                        <div class="icomBox has-bg-level-1 cursor-pointer "><i class="iconfont icon-TurnOff" @click="onPayBtn"></i></div>
                    </header>
                    <section class="modal-card-body has-bg-level-2 pa-7">
                        <div class="form-item" v-if="is_pay_pwd == 2">
                            <div class="form-item-title">{{$t('user.old_pwd')}}</div>
                            <div class="form-item-content form-item-content-btns">
                                <input type="password" :placeholder="$t('user.enter_old_pwd')" class="input" v-model="old_pay_password">
                                <div class="btns-box pr-3">
                                    <span class="pr-2"></span>
                                </div>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-title">{{$t('user.new_pwd')}}</div>
                            <div class="form-item-content form-item-content-btns">
                                <input type="password" :placeholder="$t('user.enter_new_pwd')" class="input" v-model="new_pay_password">
                                <div class="btns-box pr-3">
                                    <span class="pr-2"></span>
                                </div>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-title">{{$t('user.res_pwd')}}</div>
                            <div class="form-item-content form-item-content-btns">
                                <input type="password" :placeholder="$t('user.enter_res_pwd')" class="input" v-model="res_pay_password">
                                <div class="btns-box pr-3">
                                    <span class="pr-2"></span>
                                </div>
                            </div>
                        </div>
                    </section>
                    <footer class="modal-card-foot has-bg-level-2 pa-7">
                        <button class="button is-primary font-size-2 has-text-white modal-default-btn button-large " @click="onSubmitPay">{{$t('user.submit')}}</button>
                    </footer>
                </div>
            </div>
        </div>

        <!-- 弹出窗口-找回支付密码 -->
        <div class="mt-7">
            <div :class="'my-modal modal theme--light ' + (updateFindPay?'is-active':'')">
                <div class="modal-background"></div>
                <div class="modal-card border-radius-medium" style="">
                    <header class="modal-card-head has-bg-level-2 has-line-level-1 pa-7">
                        <div class="modal-card-title title-medium">
                            <p class="modal-card-title-log pb-3">{{$t('base.title')}}</p>
                            <div>{{is_pay_pwd == 1?$t('user.set_pay_pwd'):$t('user.find_pay_pwd')}}</div>
                        </div>
                        <div class="icomBox has-bg-level-1 cursor-pointer "><i class="iconfont icon-TurnOff" @click="onFindPayBtn"></i></div>
                    </header>
                    <section class="modal-card-body has-bg-level-2 pa-7">
                        <div class="form-item" v-if="is_pay_pwd == 2">
                            <div class="form-item-title">{{$t('user.email')}}</div>
                            <div class="form-item-content form-item-content-btns">
                                <input type="text" :placeholder="$t('user.enter_email')" readonly class="input" v-model="params.email">
                                <div class="btns-box pr-3">
                                    <span class="pr-2"></span>
                                </div>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-title">{{$t('user.email_code')}}</div>
                            <div class="form-item-content form-item-content-btns">
                                <input type="text" :placeholder="$t('user.enter_email_code')" class="input" v-model="params.email_code">
                                <div class="btns-box pr-3">
                                    <span class="pr-2">
                                    </span>
                                    <span class="cursor-pointer has-text-primary" @click="sendEmail">{{overTime > 0 ? overTime +' s':$t('user.send')}}</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-item">
                            <div class="form-item-title">{{$t('user.new_pwd')}}</div>
                            <div class="form-item-content form-item-content-btns">
                                <input type="password" :placeholder="$t('user.enter_new_pwd')" class="input" v-model="params.password">
                                <div class="btns-box pr-3">
                                    <span class="pr-2"></span>
                                </div>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-title">{{$t('user.res_pwd')}}</div>
                            <div class="form-item-content form-item-content-btns">
                                <input type="password" :placeholder="$t('user.enter_res_pwd')" class="input" v-model="params.res_password">
                                <div class="btns-box pr-3">
                                    <span class="pr-2"></span>
                                </div>
                            </div>
                        </div>
                    </section>
                    <footer class="modal-card-foot has-bg-level-2 pa-7">
                        <button class="button is-primary font-size-2 has-text-white modal-default-btn button-large " @click="onSubmitFindPay">{{$t('user.submit')}}</button>
                    </footer>
                </div>
            </div>
        </div>
        <!-- 弹出窗口-修改支付密码 -->
        <!-- <div class="mt-7">
            <div :class="'my-modal modal theme--light ' + (updatePay?'is-active':'')">
                <div class="modal-background"></div>
                <div class="modal-card border-radius-medium" style="">
                    <header class="modal-card-head has-bg-level-2 has-line-level-1 pa-7">
                        <div class="modal-card-title title-medium">
                            <p class="modal-card-title-log pb-3">Xmex</p>
                            <div>撤销申请C2C划转</div>
                        </div>
                        <div class="icomBox has-bg-level-1 cursor-pointer "><i class="iconfont icon-TurnOff" @click="onPwdBtn"></i></div>
                    </header>
                    <section class="modal-card-body has-bg-level-2 pa-7">
                        <div class="columns">
                            <div class="column">审核金额</div>
                            <div class="column has-text-right">undefined undefined</div>
                        </div>
                    </section>
                    <footer class="modal-card-foot has-bg-level-2 pa-7">
                        <button class="button is-primary is-outlined font-size-2 has-text-white modal-default-btn button-large has-text-primary">
                            取消
                        </button>
                        <div class="spacer"></div>
                        <button class="button is-primary font-size-2 has-text-white modal-default-btn button-large ">确定
                        </button>
                    </footer>
                </div>
            </div>
        </div> -->
    </div>
</div>
</template>

<script>
import {
    getCertified,
    setPassword,
    setPayPassword,
    setFindPayPassword,
    sendEmail
} from '@/api/auth'

export default {
    name: "User",
    props: ['screenHeight'],
    data() {
        return {
            token: '',
            email: '',
            uid: '',

            //是否认证
            is_auth: -1,
            //是否设置支付密码
            is_pay_pwd: 1,

            //修改密码弹框
            updatePwd: false,
            //修改密码
            old_password: '',
            new_password: '',
            res_password: '',

            //支付密码
            updatePay: false,
            //修改密码
            old_pay_password: '',
            new_pay_password: '',
            res_pay_password: '',

            //找回支付密码
            overTime:0,
            updateFindPay: false,
            params:{
                email:localStorage.getItem('email'),
                email_code:'',
            }
        }
    },
    watch: {
        screenHeight(a) {
            console.log(a)
        },
    },
    created() {
        this.checkToken()
        this.getCertified()
    },
    methods: {
        checkToken() {
            //获取缓存的token
            let token = localStorage.getItem('token')
            if (token) {
                this.token = token
                this.email = localStorage.getItem('email')
                this.email = this.email.substr(0, 2) + '***' + this.email.substr(-7)
                this.uid = localStorage.getItem('uid')
                this.is_auth = localStorage.getItem('is_auth')
                this.is_pay_pwd = localStorage.getItem('is_pay_pwd')

            } else {
                this.token = ''
                this.email = ''
                this.uid = ''
                this.is_auth = 0
                this.is_pay_pwd = 1
            }
        },
        getAuthText() {
            if (this.is_auth == 0) {
                return this.$t('user.not_certified')
            } else if (this.is_auth == 1) {
                return this.$t('user.certification')
            } else if (this.is_auth == 2) {
                return this.$t('user.verified')
            } else if (this.is_auth == 3) {
                return this.$t('user.rejected')
            }
            return ''
        },
        getCertified() {
            getCertified().then(res => {
                this.is_auth = res.data.is_auth
                this.is_pay_pwd = res.data.is_paypwd

                localStorage.setItem('is_auth', this.is_auth)
                localStorage.setItem('is_pay_pwd', this.is_pay_pwd)
            })
        },
        onPwdBtn() {
            this.updatePwd = !this.updatePwd
        },
        /**
         * 
         */
        onSubmit() {
            if (!this.old_password) {
                this.$message({
                    message: this.$t("message.invalid_password"),
                    type: "warning",
                });
                return
            }
            if (!this.new_password) {
                this.$message({
                    message: this.$t("message.invalid_new_password"),
                    type: "warning",
                });
                return
            }
            if (!this.res_password) {
                this.$message({
                    message: this.$t("message.invalid_res_password"),
                    type: "warning",
                });
                return
            }

            if (this.new_password !== this.res_password) {
                this.$message({
                    message: this.$t("message.password_inconsistent"),
                    type: "warning",
                });
                return
            }

            // loading
            this.$ui.openLoading()

            setPassword({
                old_password: this.old_password,
                new_password: this.new_password,
                res_password: this.res_password,
            }).then(res => {
                // loading
                this.$ui.closeLoading()

                if (res.code == 1) {
                    this.$message({
                        message: res.msg,
                        type: "error",
                    });
                    return
                }

                this.$message({
                    message: res.msg,
                    type: "success",
                });

                this.old_password = ''
                this.new_password = ''
                this.res_password = ''

                this.updatePwd = false
            }).catch(() => {
                // loading
                this.$ui.closeLoading()
            })
        },

        onPayBtn() {
            this.updatePay = !this.updatePay
        },
        /**
         * 
         */
        onSubmitPay() {
            if (this.is_pay_pwd == 2 && !this.old_pay_password) {
                this.$message({
                    message: this.$t("message.invalid_old_password"),
                    type: "warning",
                });
                return
            }
            if (!this.new_pay_password) {
                this.$message({
                    message: this.$t("message.invalid_new_password"),
                    type: "warning",
                });
                return
            }
            if (!this.res_pay_password) {
                this.$message({
                    message: this.$t("message.invalid_res_password"),
                    type: "warning",
                });
                return
            }

            if (this.new_pay_password != this.res_pay_password) {
                this.$message({
                    message: this.$t("message.password_inconsistent"),
                    type: "warning",
                });
                return
            }

            // loading
            this.$ui.openLoading()

            setPayPassword({
                old_pay_password: this.old_pay_password,
                new_pay_password: this.new_pay_password,
                res_pay_password: this.res_pay_password,
            }).then(res => {
                // loading
                this.$ui.closeLoading()
                if (res.code == 1) {
                    this.$message({
                        message: res.msg,
                        type: "error",
                    });
                    return
                }

                this.$message({
                    message: res.msg,
                    type: "success",
                });

                this.old_pay_password = ''
                this.new_pay_password = ''
                this.res_pay_password = ''

                this.updatePay = false

                //更新
                this.getCertified()
            }).catch(() => {
                // loading
                this.$ui.closeLoading()
            })
        },
        /**
         * 找回支付密码
         */
        onFindPayBtn() {
            this.updateFindPay = !this.updateFindPay
        },
        /**
         * 倒计时
         */
        runTime() {
            var _this = this
            if (_this.overTimeInterval == null) {
                this.setTime()
                _this.overTimeInterval = setInterval(function () {
                    //递减
                    _this.setTime()
                    console.log(_this.overTime, '倒计时')
                    if (_this.overTime <= 0) {
                        clearInterval(_this.overTimeInterval)
                        _this.overTimeInterval = null
                        console.log('倒计时结束')
                        return
                    }
                }, 1000)
            }
        },
        /**
         * 初始化时间
         */
        setTime() {
            // 初始化
            var endTime = parseInt(localStorage.getItem('findpay_endTime'))
            if (endTime) {
                this.overTime = parseInt(endTime - parseInt(new Date().getTime() / 1000))
            }
        },
        startTime(time) {
            if (this.overTime <= 0) {
                var endTime = parseInt(new Date().getTime() / 1000) + time;
                localStorage.setItem('findpay_endTime', endTime)
            }
            //倒计时
            this.runTime()
        },
        /**
         * 发送验证码
         */
        sendEmail() {

            let _this = this

            // 验证参数
            if (!this.params.email) {
                this.$message({
                    message: this.$t("message.invalid_email"),
                    type: "warning",
                });
                return;
            }

            if (this.overTime > 0) {
                this.$message({
                    message: this.$t("message.send_email", {
                        s: this.overTime
                    }),
                    type: "warning",
                });
                return;
            }

            // loading
            this.$ui.openLoading()

            // 请求接口
            sendEmail({
                    scene: "find_password",
                    email: this.params.email,
                })
                .then((res) => {
                    // close loading
                    _this.$ui.closeLoading();

                    if (res.code == 1) {
                        _this.$message({
                            message: res.msg,
                            type: "error",
                        });
                        return;
                    }

                    //倒计时
                    _this.startTime(res.data.time)

                    _this.$message({
                        message: res.msg,
                        type: "success",
                    });
                })
                .catch((res) => {
                    _this.$ui.closeLoading();
                    console.log("异常错误", res);
                });
        },
        /**
         * 
         */
        onSubmitFindPay() {
            if ( ! this.params.email) {
                this.$message({
                    message: this.$t("message.invalid_email"),
                    type: "warning",
                });
                return
            }
            if (!this.params.email_code) {
                this.$message({
                    message: this.$t("message.invalid_email_code"),
                    type: "warning",
                });
                return
            }
            if (!this.params.password) {
                this.$message({
                    message: this.$t("message.invalid_new_password"),
                    type: "warning",
                });
                return
            }

            if ( !this.params.res_password) {
                this.$message({
                    message: this.$t("message.invalid_res_password"),
                    type: "warning",
                });
                return
            }

            // loading
            this.$ui.openLoading()

            setFindPayPassword(this.params).then(res => {
                // loading
                this.$ui.closeLoading()
                if (res.code == 1) {
                    this.$message({
                        message: res.msg,
                        type: "error",
                    });
                    return
                }

                this.$message({
                    message: res.msg,
                    type: "success",
                });

                this.params = {
                    email:localStorage.getItem('email'),
                    email_code:'',
                    password:'',
                    res_password:'',
                }

                this.updateFindPay = false

            }).catch(() => {
                // loading
                this.$ui.closeLoading()
            })
        },
    }
}
</script>

<style src="../../static/css/user.css" scoped type="text/css"></style>
