export default {
    pt1: "1. After the user logs in to STARTHESTAR, click \"Account Information\" to enter the account information interface,",
    pimg1: "k3",
    pt2: "2. Click \"Settings\" in the payment password field to enter the payment password setting interface;",
    pimg2: "k3",
    pt3: "3. Enter the password, re-enter the password again, and click \"Submit\" to set the payment password successfully.",
    pimg3: "k3",
    pt4: "4. Modify the login password in the account information interface, click \"modify\" in the login password field to enter the login password modification interface;",
    pimg4: "k3",
    pt5: "5. Enter the original password, then enter the new password, confirm the new password, and click \"Submit\" to complete the modification of the login password.",
    pimg5: "k3",
    pt6: "STARTHESTAR reminds you: Please keep the payment password and login password of your STARTHESTAR account properly.",
}