export default {
    pt1: "1. After the user logs in to STARTHESTAR, click on \"Assets\" and then click on \"My Wallet\" to enter the My Assets interface;",
    pimg1: "k4",
    pt2: "2. In the My Assets interface, click \"Deposit Coins\", ",
    pimg2: "k4",
    pt3: "3. The user chooses the currency in which you will deposit the coin. For example, if the user deposits the coin BTC, select the BTC currency, and select the corresponding currency for other currencies, and then click \"Copy\" or scan the QR code. ",
    pimg3: "k4",
    pt4: "4. The user pastes the copied wallet address or scans the QR code to the platform where the coin needs to be withdrawn. Complete the withdrawal process and wait for the successful processing on the chain, that is, deposit the coin to the STARTHESTAR platform. (View in the My Assets interface Account situation)",
    pt5: "STARTHESTAR reminds you:",
    pt6: "①Pay attention to the wallet address corresponding to the currency type when depositing coins, please do not fill in the wrong, otherwise the assets will not be retrieved.",
    pt7: "②When depositing USDT, pay attention to the blocks corresponding to USDT (ERC20, TRC20, OMNI), please do not fill in the error, otherwise the assets will not be retrieved.",
}