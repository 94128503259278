export default `<p>
    <strong>NEO (NEO)</strong>
</p>
<p>
    <br/>
</p>
<p>
    <strong>1. 프로젝트 이름</strong>
</p>
<p>
    네오
</p>
<p>
    <br/>
</p>
<p>
    <strong>2. 프로젝트 포지셔닝</strong>
</p>
<p>
    스마트 경제의 분산 네트워크
</p>
<p>
    <br/>
</p>
<p>
    <strong>3, 프로젝트 간단한 코멘트</strong>
</p>
<p>
    NEO는 블록 체인 기술을 기반으로 실물 자산을 디지털 자산으로 전환하고 스마트 계약을 통해 지능적인 관리를 실현하며 사용자는 디지털 인증서 인증 기관을 선택하여 신원 인증을 실현합니다. NEO는 NEO 네트워크의 관리권을 실현하는 데 사용됩니다. NEO는 국내외에서 가장 인기있는 퍼블릭 체인 프로젝트 중 하나입니다.
</p>
<p>
    <br/>
</p>
<p>
    <strong>4. 프로젝트 진행</strong>
</p>
<p>
    2014 년 6 월 NEO는 공식적으로 프로젝트를 설립하고 시드 라운드 투자를 수락했습니다.
</p>
<p>
    2015 년 10 월 1 차 ICO가 완료되었습니다.
</p>
<p>
    2016 년 8 월 2 차 ICO가 완료되었습니다.
</p>
<p>
    2016 년 10 월 NEO 메인 넷이 출시되고 블록 체인 브라우저가 출시되었습니다.
</p>
<p>
    2016 년 11 월 Antshares VM 경량 범용 블록 체인 가상 머신 백서를 발표했습니다.
</p>
<p>
    2017 년 6 월에는 NEO로 이름을 바꾸고 스마트 경제 발전에 주력하기 위해 업그레이드했습니다.
</p>
<p>
    <br/>
</p>
<p>
    <strong>5, 프로젝트 특성</strong>
</p>
<p>
    1. 국내외 NEO 커뮤니티가 활발히 활동하고 있으며 현재 커뮤니티에는 200 명 이상의 기술 인력이 있습니다.
</p>
<p>
    2. 기술 문서가 시작되고 프로젝트의 핵심 기술이 이행되었으며 초전도 거래 메커니즘 및 교차 체인 상호 운용성 계약이 점진적으로 구현되었습니다.
</p>
<p>
    3. NEO는 디지털 자산 등록, 유통 및 거래를위한 지능형 플랫폼으로 사용 범위가 넓고 다양한 기술 지원과 플러그인을 제공하며 개발이 편리합니다.
</p>
<p>
    <br/>
</p>
<p>
    <strong>6, 프로젝트 팀</strong>
</p>
<p>
    설립자 : 다홍 페이 (Da Hongfei)는 South China University of Technology를 졸업하고 2011 년에 비트 코인에 연락하여 NEO의 설립자 인 Onchain (유통 기술)의 CEO 인 블록 체인을 계속 연구했습니다.
</p>
<p>
    설립자 : Zhang Zhengwen, 정보 보안 경험이 있으며 나중에 블록 체인 기술과 접촉하여 NEO에 합류했습니다.
</p>
<p>
    <br/>
</p>
<p>
    <strong>7, ICO 진행</strong>
</p>
<p>
    NEO에는 두 차례의 ICO가 있습니다.
</p>
<p>
    1. ICO 시간 : 2015 / 10 / 20-2015 / 10 / 31
</p>
<p>
    ICO 비용 : 0.25 위안 / 개
</p>
<p>
    ICO 진행률 : 100 % 완료
</p>
<p>
    2. ICO 시간 : 2016 / 8 / 8-2016 / 9 / 8
</p>
<p>
    ICO 비용 : 1.1 위안 / 개
</p>
<p>
    ICO 진행률 : 100 % 완료
</p>
<p>
    <br/>
</p>
<p>
    <strong>8. 프로젝트 컨설턴트</strong>
</p>
<p>
    아니
</p>
<p>
    <br/>
</p>
<p>
    <strong>9, Token 개요</strong>
</p>
<p>
    프로젝트 토큰 : 1 억
</p>
<p>
    ICO 비율 : 50 %
</p>
<p>
    커뮤니티 인센티브 : 10 %
</p>
<p>
    팀 동기 : 10 %
</p>
<p>
    교차 투자 : 15 %
</p>
<p>
    기타 : 15 %
</p>
<p>
    토큰 가격 : 0.25-1.1 위안 / 개
</p>
<p>
    <br/>
</p>
<p>
    <strong>10. 프로젝트 파이낸싱 및 평가</strong>
</p>
<p>
    ICO 파이낸싱 : 1 단계 파이낸싱은 2100BTC, 2 단계 파이낸싱은 6119BTC입니다.
</p>
<p>
    토큰 순환 : 6500 만
</p>
<p>
    <br/>
</p>
<p>
    <strong>11. 토큰 생성 및 사용</strong>
</p>
<p>
    토큰 생성 : ICO 판매
</p>
<p>
    토큰 기능 : NEO 관리 토큰 보유자는 NEO 네트워크의 소유자이자 관리자입니다. 관리 권한은 NEO 네트워크에서 투표 거래를 구성하여 실현되며 NEO 관리 토큰에 해당하는 GAS 연료 토큰을 획득하여 NEO 네트워크 사용 권한을 얻습니다.
</p>
<p>
    <br/>
</p>
<p>
    <strong>12. 공통 링크</strong>
</p>
<p>
    공식 웹 사이트 : https://neo.org
</p>
<p>
    백서 : http://docs.neo.org/zh-cn/
</p>
<p>
    <br/>
</p>
<p>
    <strong>13. 통화가있는 구역</strong>
</p>
<p>
    혁신 존
</p>
<p>
    <br/>
</p>`