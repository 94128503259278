<template>
  <div class="TVChartContainer" :id="containerId" style="height: 100%;" />
</template>

<script>
import { widget } from '@/utils/chart/charting_library/charting_library.min'
import { getOverrides } from '@/utils/chart/charting_library/config'
import datafeed from '@/utils/datafeed'
export default {
  name: 'TVChartContainer',
  data() {
    return {
      containerId: 'tv_chart_container',
      tvWidget: null,
      currency: {}
    }
  },
  methods: {
    /**
     * 切换币种
     */
    changeSymbol(Currency) {
      //console.log("[changeSymbol]切换币种", Currency)
      //数据初始化
      datafeed.init(Currency)

      if (this.tvWidget._ready) {
        // console.log(this.tvWidget.getIntervals())
        this.tvWidget.setSymbol(Currency.symbol, 1)
      }
    },
    initWidget(currency) {
      // console.log(currency,"[initWidget] 图表初始化")
      //数据初始化
      datafeed.init(currency)

      //图表参数
      const widgetOptions = {
        //symbol: 'btcusdt',                    //商品标识
        symbol: currency.symbol,
        interval: '1', //初始化显示的时间范围
        timezone: 'America/New_York', //"Asia/Shanghai", //时区
        customFormatters: {
          timeFormatter: {
            format: function(date) {
              var _format_str = '%h:%m'

              var h = date.getUTCHours()
              if (h < 10) {
                h = '0' + h
              }
              var m = date.getUTCMinutes()
              if (m < 10) {
                m = '0' + m
              }
              var s = date.getUTCSeconds()
              if (s < 10) {
                s = '0' + s
              }
              return _format_str
                .replace('%h', h, 2)
                .replace('%m', m, 2)
                .replace('%s', s, 2)
            }
          },
          dateFormatter: {
            format: function(date) {
              var m = date.getUTCMonth() + 1
              if (m < 10) {
                m = '0' + m
              }
              var d = date.getUTCDate()
              if (d < 10) {
                d = '0' + d
              }

              return m + '/' + d + '/' + date.getUTCFullYear()
            }
          }
        },
        fullscreen: false, //是否全屏
        autosize: true, //自动大小
        debug: false, //调试模式
        theme: 'Dark', //light                     //主题
        overrides: getOverrides('black'),
        toolbar_bg: '#0b121d', //工具栏背景颜色
        charts_storage_api_version: '1.15', //后台版本"1.0"|"1.1"
        container_id: this.containerId, //div
        // custom_css_url: "../../static/chart.css",
        client_id: 'tradingview.com',
        user_id: 'public_user_id',
        datafeed: datafeed,
        library_path: '/', //库目录
        locale: 'en',
        disabled_features: [
          'use_localstorage_for_settings',
          'header_symbol_search',
          'header_resolutions',
          'header_interval_dialog_button',
          'show_interval_dialog_on_key_press',
          'caption_buttons_text_if_possible',
          'header_chart_type',
          //'header_settings',
          'header_indicators',
          'header_compare',
          'header_undo_redo',
          //'header_fullscreen_button',
          'header_saveload',
          'header_screenshot',
          'timeframes_toolbar',
          'left_toolbar',
          'volume_force_overlay', //k线图和柱状图分离
          'widget_logo' //隐藏左下角logo
          //'compare_symbol',
          //'display_market_status'
        ],
        show_logo_on_all_charts: 'on',
        enabled_features: [
          'study_templates',
          'legend_context_menu',
          'keep_left_toolbar_visible_on_small_screens',
          'side_toolbar_in_fullscreen_mode', // 全屏模式启用绘图工具
          'property_pages'
        ],
        charts_storage_url: 'https://saveload.tradingview.com',
        studies_overrides: {
          'volume.volume.color.0': '#ff5353',
          'volume.volume.color.1': '#00b07c',
          'volume.volume.transparency': '53',
          'volume.show ma': !0,
          'volume.volume ma.plottype': 'line'
        } //线条样式的重载
      }

      const tvWidget = new widget(widgetOptions)
      this.tvWidget = tvWidget

      var buttons = [
        {
          title: '1min',
          resolution: '1',
          chartType: 1
        },
        {
          title: '5min',
          resolution: '5',
          chartType: 1
        },
        {
          title: '15min',
          resolution: '15',
          chartType: 1
        },
        {
          title: '30min',
          resolution: '30',
          chartType: 1
        },
        {
          title: '60min',
          resolution: '60',
          chartType: 1
        },
        {
          title: '1day',
          resolution: '1D',
          chartType: 1
        },
        {
          title: '1week',
          resolution: '1W',
          chartType: 1
        },
        {
          title: '1month',
          resolution: '1M',
          chartType: 1
        }
        // {
        //     title: '1year',
        //     resolution: '12M',
        //     chartType: 1
        // },
        /*{ title: '1month', resolution: '1M', chartType: 1 },*/
      ]

      function createButton(buttons) {
        for (var i = 0; i < buttons.length; i++) {
          tvWidget
            .createButton()
            .attr('title', buttons[i].title)
            .attr('data-interval', buttons[i].resolution)
            .addClass('btn-m')
            .text(buttons[i].title)
            .on('click', function(e) {
              // console.log("===========切换视图===========", e.currentTarget.dataset.interval)
              datafeed.init()
              // tvWidget.setSymbol(datafeed.getSymbol(), e.currentTarget.dataset.interval.toString());
              tvWidget.chart().setResolution(e.currentTarget.dataset.interval)
            })
        }
      }
      var studies = []
      function createStudy() {
        var id = tvWidget.chart().createStudy('Moving Average', false, false, [5], null, { 'Plot.color': 'rgb(150, 95, 196)', 'Plot.linewidth': 1 })
        studies.push(id)
        id = tvWidget.chart().createStudy('Moving Average', false, false, [10], null, { 'Plot.color': 'rgb(116,149,187)', 'Plot.linewidth': 1 })
        studies.push(id)
        id = tvWidget.chart().createStudy('Moving Average', false, false, [30], null, { 'Plot.color': 'rgb(116,149,187)', 'Plot.linewidth': 1 })
        studies.push(id)
        id = tvWidget.chart().createStudy('Moving Average', false, false, [60], null, { 'Plot.color': 'rgb(255,181,36)', 'Plot.linewidth': 1 })
        studies.push(id)
      }

      tvWidget.onChartReady(() => {
        createButton(buttons)
        createStudy()
      })
    }
  },
  destroyed() {
    if (this.tvWidget !== null) {
      this.tvWidget.remove()
      this.tvWidget = null
    }
  }
}
</script>

<style lang="scss" scoped>
.TVChartContainer {
  height: calc(100vh - 80px);
}
</style>
