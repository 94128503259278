export default `<p>
    <strong>MANA (디 센트럴 랜드)</strong>
</p>
<p>
    <br/>
</p>
<p>
    <strong>1. 프로젝트 이름</strong>
</p>
<p>
    디 센트럴 랜드 (MANA)
</p>
<p>
    <br/>
</p>
<p>
    <strong>2. 프로젝트 포지셔닝</strong>
</p>
<p>
    Ethereum 블록 체인을 기반으로하고 VR을 지원하는 Minecraft 및 Second Life 스타일 게임 플랫폼.
</p>
<p>
    <br/>
</p>
<p>
    <strong>3, 프로젝트 간단한 코멘트</strong>
</p>
<p>
    프로젝트 개발 진척이 비교적 완료되고 소규모 공개 테스트가 곧 진행될 예정이며 최근 거래량이 상대적으로 크고 사용자 커뮤니티가 활발하며 투자 잠재력이 있습니다.
</p>
<p>
    가장 큰 위험은 다음과 같습니다 : 1. 최근 가격 변동의 위험 2. 내부 베타 버전에 주요 버그가 있거나 사용자의 기대를 충족하지 못합니다.
</p>
<p>
    <br/>
</p>
<p>
    <strong>4. 프로젝트 진행</strong>
</p>
<p>
    1. ICO가 종료되었습니다.
</p>
<p>
    2. 게임 내부 도메인 경매에 참여하기위한 내부 베타 사용자 등록은 12 월 15 일까지 가능합니다.
</p>
<p>
    3. 12 월 15 일 이후 Iron Age의 테스트 버전이 출시되고 게임의 내부 영역은 구매 및 민영화를 위해 개방됩니다.
</p>
<p>
    4. Iron Age는 다중 사용자 지원, 가상 초상화 및 인스턴트 채팅 기능을 추가하고 사용자 지정 항목 및 스크립트를 자신의 영역에서 실행할 수 있도록합니다.
</p>
<p>
    <br/>
</p>
<p>
    <strong>5, 프로젝트 특성</strong>
</p>
<p>
    1. 사용자가 완전히 제어하는 최초의 가상 현실 플랫폼, 상호 작용할 수있는 완전히 통합 된 3D 가상 세계.
</p>
<p>
    2. 사용자는 이더 리움 블록 체인 플랫폼을 통해 토지를 구매하고 토지의 소유자가 될 수 있습니다.
</p>
<p>
    3. 사용자는 자신의 영역에서 콘텐츠를 완전히 제어 할 수 있습니다.
</p>
<p>
    4. 플랫폼은 개방형 표준 프로토콜에 의해 관리되고 보호됩니다.
</p>
<p>
    5. 사용자의 도메인 소유권은 희소성과 위조 방지를 보장하기 위해 블록 체인 기술을 사용하여 기록됩니다.
</p>
<p>
    6. 이제 Microsoft MR (Mixed Reality Platform)을 지원합니다.
</p>
<p>
    <br/>
</p>
<p>
    <strong>6, 프로젝트 팀</strong>
</p>
<p>
    프로젝트 리더 : 전 Benchrise의 공동 창립자 인 Ari Meilich.
</p>
<p>
    설립자이자 기술 리더 : Esteban Ordano, 전 스마트 계약 솔루션 창립자이자 BitPay의 소프트웨어 엔지니어.
</p>
<p>
    <br/>
</p>
<p>
    <strong>7. 프로젝트 컨설턴트</strong>
</p>
<p>
    Li Xiaolai : INBlockchain의 설립자
</p>
<p>
    Jake Brukhman : Coinfund의 설립자
</p>
<p>
    Luis Cuende : Aragon의 프로젝트 리더
</p>
<p>
    Diego Doval : 전 Ning 최고 기술 책임자
</p>
<p>
    <br/>
</p>
<p>
    <strong>8. ICO 진행</strong>
</p>
<p>
    ICO 시간 : 2017 년 8 월 18 일
</p>
<p>
    ICO 진행률 : 100 %
</p>
<p>
    <br/>
</p>
<p>
    <strong>9, Token 개요</strong>
</p>
<p>
    프로젝트 토큰 : MANA (ERC20)
</p>
<p>
    총 토큰 : 28 억 5 천만
</p>
<p>
    ICO 비율 : 40 %
</p>
<p>
    커뮤니티 및 파트너 : 20 %
</p>
<p>
    팀 및 초기 투자자 : 20 % (3 년 고정)
</p>
<p>
    재단 : 20 %
</p>
<p>
    토큰 ICO 가격 : MANA = 0.16RMB
</p>
<p>
    <br/>
</p>
<p>
    <strong>10. 프로젝트 파이낸싱 및 평가</strong>
</p>
<p>
    ICO 파이낸싱 : 2400 만
</p>
<p>
    ICO 파이낸싱 평가 : 6 천만
</p>
<p>
    토큰 순환 : 81 %
</p>
<p>
    <br/>
</p>
<p>
    <strong>11. 토큰 생성 및 사용</strong>
</p>
<p>
    토큰 생성 : 스마트 계약
</p>
<p>
    토큰 인플레이션 : 연간 8 % 인플레이션, 인플레이션 율은 매년 감소하고 있습니다.
</p>
<p>
    토큰 기능 : 게임은 영토 및 기타 가상 제품 및 서비스를 구매하는 데 사용되며 영토를 구매 한 후 토큰은 소멸됩니다.
</p>
<p>
    <br/>
</p>
<p>
    <strong>12. 공통 링크</strong>
</p>
<p>
    공식 웹 사이트 : https://decentraland.org/
</p>
<p>
    백서 : https://decentraland.org/whitepaper.pdf
</p>
<p>
    <br/>
</p>
<p>
    <strong>13. 통화가있는 구역</strong>
</p>
<p>
    혁신 존
</p>
<p>
    <br/>
</p>`