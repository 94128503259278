<template>
<div class="app">
    <!-- user-head -->
    <section class="bg">
        <div class="info-bg">
            <div class="title">{{$t('certified.title')}}</div>
            <div class="content">
                <div class="avatar-div">
                    <div class="upload-div">
                        <el-upload class="avatar-uploader" action="/web2/helper/upload" :headers="{'Access-Token':token}" :data="{path:'certification',param:'front'}" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                            <img v-if="imgs.front" :src="imgs.front" class="avatar">
                            <div v-else style="display: grid;">
                                <i class="el-icon-picture-outline avatar-uploader-icon"></i>
                                <span class="avatar-span">{{$t('certified.front')}}</span>
                            </div>
                        </el-upload>
                    </div>
                    <div class="upload-div">
                        <el-upload class="avatar-uploader" action="/web2/helper/upload" :headers="{'Access-Token':token}" :data="{path:'certification',param:'back'}" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                            <img v-if="imgs.back" :src="imgs.back" class="avatar">
                            <div v-else style="display: grid;">
                                <i class="el-icon-picture-outline avatar-uploader-icon"></i>
                                <span class="avatar-span">{{$t('certified.back')}}</span>
                            </div>
                        </el-upload>
                    </div>
                    <div class="upload-div">
                        <el-upload class="avatar-uploader" action="/web2/helper/upload" :headers="{'Access-Token':token}" :data="{path:'certification',param:'hand'}" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                            <img v-if="imgs.hand" :src="imgs.hand" class="avatar">
                            <div v-else style="display: grid;">
                                <i class="el-icon-user avatar-uploader-icon"></i>
                                <span class="avatar-span">{{$t('certified.hand')}}</span>
                            </div>
                        </el-upload>
                    </div>
                </div>
            </div>
            <div class="info-input">
                <div class="info-div">
                    <div class="form-input">
                        <div class="name">{{$t('certified.name')}}：</div>
                        <input type="text" v-model="form.name" :placeholder="$t('certified.name_desc')">
                    </div>
                    <div class="form-input">
                        <div class="name">{{$t('certified.card')}}：</div>
                        <input type="text" v-model="form.card" :placeholder="$t('certified.card_desc')">
                    </div>
                </div>
            </div>
            <div class="submit-btn"><button class="btn" @click="onSubmit">{{$t('certified.submit')}}</button></div>
        </div>
    </section>
</div>
</template>

<script>
import {
    saveCertified
} from '@/api/auth'
export default {
    components: {
    },
    name: 'Certified',
    data() {
        return {
            token: '',
            imgs: {
                front: '',
                back: '',
                hand: '',
            },
            form: {
                front: '',
                back: '',
                hand: '',
                name: '',
                card: '',
            }
        }
    },
    created() {
        this.token = localStorage.getItem('token');
    },
    methods: {
        handleAvatarSuccess(res, file) {
            //加载中
            this.$ui.closeLoading()
            console.log(res, file)
            this.imgs[res.data.param] = URL.createObjectURL(file.raw);
            this.form[res.data.param] = res.data.path
        },
        beforeAvatarUpload(file) {

            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
            const isLt10M = file.size / 1024 / 1024 < 10;

            if (!isJPG) {
                this.$message.error(this.$t('message.upload_pic_type'));
            }
            if (!isLt10M) {
                this.$message.error(this.$t('message.upload_pic_size'));
            }
            //加载中
            this.$ui.openLoading()
            return isJPG && isLt10M;
        },
        validParams() {
            if (!this.form.front) {
                this.$message({
                    message: this.$t("message.invalid_upload_front"),
                    type: "warning",
                });
                return false;
            }
            if (!this.form.back) {
                this.$message({
                    message: this.$t("message.invalid_upload_back"),
                    type: "warning",
                });
                return false;
            }
            if (!this.form.hand) {
                this.$message({
                    message: this.$t("message.invalid_upload_hand"),
                    type: "warning",
                });
                return false;
            }

            if (!this.form.name) {
                this.$message({
                    message: this.$t("message.invalid_upload_name"),
                    type: "warning",
                });
                return false;
            }

            if (!this.form.card) {
                this.$message({
                    message: this.$t("message.invalid_upload_card"),
                    type: "warning",
                });

                return false;
            }
            return true;
        },
        onSubmit() {
            if (!this.validParams()) {
                return
            }

            let _this = this
            saveCertified(this.form).then(res => {
                if (res.code == 1) {
                    this.$message({
                        message: res.msg,
                        type: "error",
                    });
                    return
                }
                this.$message({
                    message: res.msg,
                    type: "success",
                });

                //已经提交申请
                localStorage.setItem('is_auth',1);

                _this.$router.push({name:'User'})
                
            })
        }
    }
}
</script>

<style>
.el-upload {
    display: grid !important;
    width: 100% !important;
    height: 8rem !important;
}
</style>
<style src="../../static/css/certified.css" scoped tyle="text/css"></style>
