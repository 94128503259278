export default `<p>
<strong>ADA (Cardano)</strong>
</p>
<p>
<br/>
</p>
<p>
<strong>1. 프로젝트 이름</strong>
</p>
<p>
카르 다노 (ADA)
</p>
<p>
<br/>
</p>
<p>
<strong>2. 프로젝트 소개</strong>
</p>
<p>
Cardano는 분산 형 개방형 블록 체인이자 암호 화폐 프로젝트이며 완전히 오픈 소스입니다. Cardano는 이전에 개발 된 어떤 프로토콜보다 더 고급 기능을 제공하는 것을 목표로하는 스마트 계약 플랫폼을 개발하고 있습니다. 과학 철학과 연구 중심 오리엔테이션에서 진화 한 최초의 블록 체인 플랫폼입니다. 개발팀은 글로벌 전문 엔지니어와 연구원으로 구성되어 있습니다.
</p>
<p>
<strong>3. 통화 개요 및 분배</strong>
</p>
<p>
최대 공급량 : 45,000,000,000 ADA
</p>
<p>
총 공급량 : 31,112,483,745 ADA
</p>
<p>
유통량 : 25,927,070,538 ADA
</p>
<p>
크라우드 펀딩 가격 : $ 0.0026
</p>
<p>
토큰 분배 :
</p>
<p>
그중 ADA 코인은 Cardano 계약이 발표되었을 때 총 25,927,070,538 개의 ADA 코인을 판매했습니다. 판매 된 ADA 수의 20 %에 해당하는 또 다른 5,185,414,108 개의 ADA 코인이 생성되어 Cardano 기술 및 비즈니스 생태계를 구성하는 3 개 법인 인 IOHK, Emurgo 및 Cardano Foundation에 배포됩니다. 따라서 출시 당시 총 ADA 수는 31,112,484,646 개였습니다.
</p>
<p>
<br/>
</p>
<p>
카르 다노 프로토콜 운영 중에 발행 된 ADA 코인 중 나머지 13,887,515,354 개 ADA 코인은 채굴 과정에서 발행됩니다.
</p>
<p>
<br/>
</p>
<p>
참고 : ADA의 수는 45,000,000,000을 초과하지 않습니다.
</p>
<p>
에
</p>
<p>
<strong>4, 일반적으로 사용되는 링크</strong>
</p>
<p>
프로젝트 웹 사이트 : cardanofoundation.org, iohk.io, emurgo.io
</p>
<p>
백서 : whycardano.com
</p>
<p>
<br/>
</p>
<p>
다른:
</p>
<p>
<br/>
</p>
<p>
<br/>
</p>
<p>
cardano.org
</p>
<p>
cardanodocs.com
</p>
<p>
cardanoexplorer.com
</p>
<p>
cardanoroadmap.com
</p>
<p>
https://github.com/input-output-hk/cardano-sl
</p>
<p>
https://www.reddit.com/r/cardano/
</p>
<p>
https://daedaluswallet.io/
</p>
<p>
<br/>
</p>
<p>
<strong>5, 통화 영역</strong>
</p>
<p>
메인 존
</p>
<p>
<br/>
</p>`
