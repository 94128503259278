export default {
    title:"隐私保护",
    content:`<p style="text-align: center;">
    <span style="font-size: 18px;"><strong>隐私条款</strong></span>
</p>
<p>
    Starthestar
</p>
<p>
    <br/>
</p>
<p>
    &nbsp; &nbsp; Starthestar已了解用户个人信息的重要性，并将遵守例如《个人信息保护法》等相关法律法规，时刻尊重并保护用户个人信息的安全。为了提供更好的访问实用程序或服务，我们将收集、存储、维护和使用您的个人信息。因此，Starthestar通过隐私条款披露我们在进行服务时如何使用、存储您个人信息以确保安全。
</p>
<p>
    <br/>
</p>
<p>
    1 收集信息
</p>
<p>
    &nbsp; &nbsp;您在注册、交易或使用我们的服务，则表示您同意我们采集您的个人信息，即我们已经获得您的许可。除此之外，Starthestar不会利用欺诈或不正当手段采集您的个人信息，也不会要求您提供超出条款范围的相关信息。我们采集您的个人信息主要目的是为了方便和您的沟通，以提供更好的服务，采集的个人信息可能包括：姓名、联系方式、电子邮箱地址、交易信息、您提供的身份信息的副本、政府发行的身份证号、护照号码、您的设备或互联网服务相关的任何信息（如IP地址和MAC号码），以及在使用特殊服务时，可能需要您提供银行账户等相关信息。&nbsp;
</p>
<p>
    <br/>
</p>
<p>
    2 目的:
</p>
<p>
    我们将您的个人信息用于：
</p>
<p>
    <br/>
</p>
<p>
    2.1 您在Starthestar交易过程汇总，我们将处理您提供给我们的个人信息
</p>
<p>
    <br/>
</p>
<p>
    2.2 校验并确认真实信息，避免有关用户欺诈行为；
</p>
<p>
    <br/>
</p>
<p>
    2.3 验证合法公民，我们已采用国际标准以防止洗钱等其他不正当行为
</p>
<p>
    <br/>
</p>
<p>
    2.4 即时通知您相关条款、政策的修订
</p>
<p>
    <br/>
</p>
<p>
    2.5 紧急情况下进行及时沟通；
</p>
<p>
    <br/>
</p>
<p>
    2.6 遵循国家政府机构相关部门的要求
</p>
<p>
    <br/>
</p>
<p>
    2.7 为用户个性化服务提供支持
</p>
<p>
    <br/>
</p>
<p>
    2.8 方便与Starthestar的交流，即时获得相关服务以及在活动期间获得奖品
</p>
<p>
    <br/>
</p>
<p>
    2.9 相关法律法规的需要
</p>
<p>
    <br/>
</p>
<p>
    2.10 分析Starthestar运行情况，以期提供更好的功能和产品
</p>
<p>
    <br/>
</p>
<p>
    3 个人信息安全保护
</p>
<p>
    &nbsp; Starthestar不会将您个人信息用于其他用途，未经您的允许，Starthestar不会出售、泄露信息给任何第三方(除与服务相关的第三方安全合作伙伴)；Starthestar将您的个人信息去中心化存储确保信息的安全性，以应对丢失、毁坏、篡改或泄露风险等；Starthestar将您的信息通过SSL技术进行加密存储和管理，只有您本人才可以使用、修改，未经授权，其他人将被限制访问您的信息；此外，Starthestar团队内部自治，将最大限度保护您个人信息的安全性，并且不会泄露、篡改等
</p>
<p>
    <br/>
</p>
<p>
    4 个人信息设置
</p>
<p>
    &nbsp; &nbsp;您可以随时登录Starthestar，查看、增加、修改、删除个人信息，特殊情况可能需要您通过邮件等方式联系我们，进行修改。如果您不同意隐私条款，Starthestar提供的相关服务可能会终止
</p>
<p>
    <br/>
</p>
<p>
    5 声明
</p>
<p>
    &nbsp; &nbsp; &nbsp;5.1 为确保您对我们在处理个人资料上有充分信心, 您切要详细阅读及理解隐私政策的条文。特别是您一旦登陆我们的网站，不论您是否在该网站上注册，您均向我们表明您接受、同意、承诺和确认：
</p>
<p>
    <br/>
</p>
<p>
    5.2 您在自愿下连同所需的同意向我们披露个人资料
</p>
<p>
    <br/>
</p>
<p>
    5.3 您会遵守本隐私政策的全部条款和限制
</p>
<p>
    <br/>
</p>
<p>
    5.4 您同意我们通过您登陆本网站、在本网站上注册和/或使用我们提供的服务收集您的信息；您同意日后我们对隐私政策的任何修改
</p>
<p>
    <br/>
</p>
<p>
    6 隐私问题与疑问
</p>
<p>
    &nbsp; &nbsp; &nbsp;您的ID密码由您个人保管，请你时刻保护密码的安全，不要轻易泄露给他人，如果您发现密码泄露、信息异常或对Starthestar隐私条款等相关问题有任何疑问、建议，请通过以下方式与我们联系，starthestar@starthestar.com。
</p>
<p>
    <br/>
</p>
<p>
    &nbsp;
</p>
<p>
    <br/>
</p>`,
}