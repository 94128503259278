export default {
    title:"KYC和反洗钱协议",
    content:`<p style="text-align: center;">
    <span style="font-size: 18px;"><strong>KYC和反洗钱协议</strong></span>
</p>
<p>
    Starthestar
</p>
<p>
    <br/>
</p>
<p>
    1 导言
</p>
<p>
    &nbsp; &nbsp;1.1我们保证审慎遵守与了解你的客户和反洗钱相关的法律法规且不得故意违反该了解你的客户和反洗钱政策。在我们合理控制的范围内我们财务必要的措施和技术为您提供安全的服务，尽可能使您免于遭受犯罪嫌疑人的洗钱行为带来的损失&nbsp;
</p>
<p>
    <br/>
</p>
<p>
    1.2我们的了解你的客户和反洗钱政策是一个综合性的国际政策体系，包括您隶属的不同法律辖区的了解你的客户和反洗钱政策。我们健全的合规框架确保我们在在本地和全球层面均符合监管要求和监管水平，并确保本网站持续性运行
</p>
<p>
    <br/>
</p>
<p>
    2 了解你的客户和反洗钱政策如下:
</p>
<p>
    2.1颁布了解你的客户和反洗钱政策并时时更新以满足相应的法律法规规定的标准
</p>
<p>
    <br/>
</p>
<p>
    2.2颁布和更新运行本网站的一些指导原则和规则，且我们的员工将按照该原则和规则的指导提供全程服务
</p>
<p>
    <br/>
</p>
<p>
    2.3 设计并完成内部监测和控制交易的程序，如以严格的手段验证身份，安排组建专业团队专门负责反洗钱工作
</p>
<p>
    <br/>
</p>
<p>
    2.4 采用风险预防的方法对客户进行尽职调查和持续的监督
</p>
<p>
    <br/>
</p>
<p>
    2.5 审查和定期检查已发生的交易
</p>
<p>
    <br/>
</p>
<p>
    2.6 向主管当局报告可可疑交易
</p>
<p>
    <br/>
</p>
<p>
    2.7 身份证明文件、地址证明文件和交易记录的证明文件将会维持至少六年，如被提交给监管部门，恕不另行通知您
</p>
<p>
    <br/>
</p>
<p>
    2.8 整个交易过程中，信用卡被禁止使用
</p>
<p>
    <br/>
</p>
<p>
    2.9 定期参加相关当局举办的培训并定期培训员工
</p>
<p>
    <br/>
</p>
<p>
    3 身份信息与核实确认
</p>
<p>
    &nbsp; &nbsp;3.1 身份信息
</p>
<p>
    <br/>
</p>
<p>
    &nbsp; &nbsp;3.1.1 根据不同的司法管辖区的不同规定及不同的实体类型，我们收集的您的信息内容可能不一致，原则上将向注册的个人您收集以下信息：
</p>
<p>
    <br/>
</p>
<p>
    &nbsp; &nbsp;个人基本信息：他/她的姓名，住址（及永久地址，如果不同） ，出生日期及国籍等可获得的其他情况。身份验证应该是根据官方或其他类似权威机构发放的文件进行，比如护照，身份证或其他不同的辖区要求的和引发的身份证明文件。您提供的地址将使用适当的方法进行验证，比如检查乘用交通工具的票据或利率票据或检查选民登记册
</p>
<p>
    <br/>
</p>
<p>
    有效的照片：在您注册之前，您须提供您将您的身份证件放在胸前的照片
</p>
<p>
    <br/>
</p>
<p>
    联系方式：电话/手机号码和有效的电子邮件地址
</p>
<p>
    <br/>
</p>
<p>
    3.1.2如果您是一个公司或其他合法实体，我们将收集以下信息以确定您或信托帐户的最终受益人
</p>
<p>
    <br/>
</p>
<p>
    公司注册、登记证；公司的章程与备忘录副本；公司的股权机构和所有权说明的详细证明材料，证明决定本网站账户的开立以及执行的授权委托人的董事会决议；按照要求需要提供的公司董事、大股东及本网站账户有权签字人的身份证明文件；该公司的主要营业地址，如果与公司的邮寄地址不同，提供邮寄地址。如果公司在本地的地址与它的主要营业地址不一致的，则被视为风险较高的客户，需要提交额外附加文件
</p>
<p>
    <br/>
</p>
<p>
    根据不同的司法管辖区的不同规定及不同的实体类型，我们要求的其他认证和权威部门发布的文件以及我们认为必要的文件
</p>
<p>
    <br/>
</p>
<p>
    3.1.3 我们接受英语版本、日语版本、韩语版本的身份信息，如不是，请您将您的身份信息翻译成英文、日文、韩文的版本并加以公证
</p>
<p>
    <br/>
</p>
<p>
    3.2确认核实
</p>
<p>
    <br/>
</p>
<p>
    3.2.1我们要求您提供身份证明文件的正反两面
</p>
<p>
    <br/>
</p>
<p>
    3.2.2 我们要求您提供您将您的身份证明文件放在您胸前的照片
</p>
<p>
    <br/>
</p>
<p>
    3.2.3证明文件的副本一般应核和原始凭证进行核对。然而，如果某个可信赖的合适的认证人可证明该副本文件是原始文件准确而全面的复制的，该副本可接受。这样的认证人包括大使、司法委员、地方治安官等
</p>
<p>
    <br/>
</p>
<p>
    3.2.4 对识别最终受益人和账户控制权的要求是确定哪些个人最终所有或控制直接客户，和/或确定正在进行的交易是由他人代为执行。如果是企业，则大股东的身份（例如那些持有10％或以上的投票权益）应被验证。一般，持股25 ％会被认定为正常风险内，其股东身份须验证；持股10%或拥有更多的投票权或股票时被认定为高风险的情况，股东身份须加以验证
</p>
<p>
    <br/>
</p>
<p>
    4 监控交易
</p>
<p>
    4.1 我们根据安全性和实际交易情况时时设置和调整日常交易和提现最高限额
</p>
<p>
    <br/>
</p>
<p>
    4.2如果交易频繁集中发生在某个注册您身上或超乎合理的情况，我们的专业团队将评估并决定他们是否可疑
</p>
<p>
    <br/>
</p>
<p>
    4.3我们凭借自身的判断认定为可疑交易的情况，我们可能会暂停该交易、拒绝该交易等限制性措施，甚至如果可能尽快逆转该交易，同时向主管部门报告，但不会通知您
</p>
<p>
    <br/>
</p>
<p>
    4.4我们保留拒绝来自于不符合国际反洗钱标准辖区的人或可被视为政治公众人物的人的登机申请，我们保留随时暂停或终止根据我们自身判断为可疑交易的交易，但我们这样做并不违反对您的任何义务和责任
</p>
<p>
    <br/>
</p>`,
}