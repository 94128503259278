//en.js
module.exports = {
  base: {
    title: 'STARTHESTAR',
    desc1: 'King of contract',
    desc2: 'Global leader in contract trading',
    d1: 'Trust',
    d2: 'Honesty',
    d3: 'Strength',
    more: 'More',
    top: 'back to the top',
    download: 'Download APP',
    zszh: 'Real Account',
    mnzh: 'Demo Account',
    content: 'Trade with a global market leader in contract trading'
  },
  demo: {
    title: 'signup success!',
    title2: 'Analog account',
    submit: 'Confirm'
  },
  lang: {
    title: 'English'
  },
  message: {
    invalid_email: 'Invalid email',
    invalid_password: 'Invalid password',
    invalid_new_password: 'Invalid new password',
    invalid_res_password: 'Invalid confirm password',
    invalid_email_code: 'Invalid email verification code',
    password_inconsistent: 'Inconsistent passwords',
    invalid_invitation_code: 'Invalid invitation code',
    should_agree: 'Please read and agree to the registration terms',
    not_null: 'This field cannot be empty',
    send_email: 'Please try again after {:s}s',
    invalid_number: 'Invalid value',
    invalid_balance: 'Invalid balance',

    invalid_rate: 'Please select multiple',
    invalid_rule: 'Please choose a rule',
    invalid_system_rule: 'No rules in the system',

    not_enough: 'Not enough available',
    price_high: 'Price must be higher than {price}',
    price_low: 'Price must be less than {price}',

    number_high: 'The maximum number of withdrawals is {num}',
    number_low: 'The minimum withdrawal amount is {num}',
    copy_address: 'Copy address successfully {text}',

    invalid_upload_front: 'Please upload the front photo of the ID',
    invalid_upload_back: 'Please upload a photo of the back side of your ID',
    invalid_upload_hand: 'Please upload a hand-held ID photo',
    invalid_upload_name: 'Please enter the name of the certificate',
    invalid_upload_card: 'Please enter the ID number',
    upload_pic_type: 'Upload pictures can only be in JPG, PNG format',
    upload_pic_size: 'Upload image size cannot exceed 10MB'
  },
  index: {
    title2: 'Global market free trading 24 hours non-stop',
    desc2: 'Implement your trading strategy in an extremely liquid market, with a 24-hour trading volume of ',
    desc2_1: 'on the entire platform',
    list: {
      name: 'market',
      h24_price: '24H latest price',
      h24_change: '24H change',
      h24_high: '24H highest price',
      h24_low: '24H lowest price',
      h24_vol: '24H volume'
    },
    footer: {
      desc: `starthestar Blockchain Technology Ltd, headquartered in the United States, is a world-leading blockchain technology company. The company has obtained the US MSB international financial license. It is a compliant blockchain technology service company that can operate in compliance on a global scale Blockchain business services. At the same time, starthestar Blockchain Technology Ltd technical team talents come from top international scientific research institutions and blockchain technology development teams to fully protect user funds and account security. In addition to the technical team, the company also has a professional blockchain team: digital assets AI quantitative investment team, blockchain project value evaluation team, digital asset venture investment team, etc., have a global layout to serve global blockchain users.
            The company's goal and vision is to provide safe, stable, considerate, and trustworthy transaction services for global digital asset enthusiasts, and to build a professional, digital, and international world-renowned blockchain technology company.`,

      item1: {
        tt: 'Product Display',
        pt1: 'OTC Trading Rules',
        pt2: "Beginner's Guide",
        pt3: 'Rate Standard',
        pt4: 'Contract Description'
      },
      item2: {
        tt: 'Support',
        pt1: 'KYC and Anti-Money Laundering Agreement',
        pt2: 'Enforcement Request Policy',
        pt3: 'Introduction to Digital Assets'
      },
      item3: {
        tt: 'Terms',
        pt1: 'Privacy Protection',
        pt2: 'User Agreement',
        pt3: 'Risk Notification'
      },
      item4: {
        tt: 'contact us',

        pt1: 'Business Cooperation',
        pt2: 'Institutional cooperation',
        pt3: 'OTC application',
        pt4: 'Customer Service Email',
        pt5: 'Complaints and Suggestions',
        pt6: 'media cooperation'
      }
    },
    bk3: {
      title: 'Proud of innovative R&D strength',
      desc: 'Unimaginable contract trading experience, designed for financial traders',

      pt1: 'Open order stop profit and stop loss',
      pd1: 'Pending orders do not stay up late, worry about not watching the order',

      pt2: 'Reverse Open Order',
      pd2: 'The market changes suddenly, keep up in one second',

      pt3: 'Support full warehouse by warehouse',
      pd3: 'Do whatever you want, do whatever you want',

      pt4: 'Full position stepped liquidation',
      pd4: 'Foreign liquidation first equals half and try to help investors reduce losses'
    },
    bk5: {
      title: 'Eight Advantages of Contract Trading Platform',
      desc: 'We listen all the time, continuously optimize and improve, and create a first-class trading experience for global trading users',

      pt1: 'Market Depth',
      pd1: "The market depth of the first three stalls on the platform has reached the order of millions of dollars, far exceeding the industry's average market depth of 10,000 and 100,000. The spread is small and the transaction is fast.",

      pt2: 'Smooth transaction',
      pd2: 'Using an efficient matching transaction engine to ensure a smooth transaction experience, it can process millions of orders per second, and can support smooth and stable operation with more than 20 million simultaneous online users.',

      pt3: 'Fair price',
      pd3: '"Marked Price" selects the real-time transaction price of the world\'s three major exchanges as the index price for regression calculation, which effectively prevents user losses caused by abnormal manipulation of the latest transaction price in a short period of time.',

      pt4: 'Self-developed technology',
      pd4: 'The matchmaking engine rooted in traditional finance, developed by a team of senior experts in the financial industry, and meticulously crafted every detail to ensure the safe, stable and efficient operation of the trading platform.',

      pt5: 'Top Risk Control',
      pd5: "Second level judgment, multi-layer encryption, high system security. The combination of hot and cold wallets, military-grade encryption measures, asset transaction security is guaranteed, and 100% guarantees the security of users' digital assets.",

      pt6: 'Funds Security',
      pd6: "Three-layer security system, comprehensive security audit. Self-built the industry's leading hierarchical deterministic cold wallet system, all users assign recharge addresses are cold wallet addresses, fund collection and withdrawal are completed through offline signatures.",

      pt7: 'Hardcore Power',
      pd7: "Tens of thousands of BTC reserves, financial innovation genes, and the world's top management team. starthestar promises that if account and transaction data errors are not caused due to system reasons, the platform will pay in full in case of errors!",

      pt8: 'Quality Service',
      pd8: '7*24h round-the-clock professional customer service, quick response. Starting from the thinking of service, provide first-class trading experience for global customers. The ultimate experience comes from the concept of serving customers.'
    },
    bk6: {
      title: 'Safety and compliance global operation',
      desc: 'Embrace supervision and always adhere to global compliance operations'
    },
    bk7: {
      title: 'Official Strategic Partner'
    },
    bk8: {
      title: 'Served more than 100,000 global trading users',
      desc: 'Currently, users are all over the United States, Japan, South Korea, Seychelles, Vietnam and other countries'
    },
    bk9: {
      title: 'Smooth transaction anytime, anywhere',
      pt1: 'Real-time matchmaking transactions, deposits and withdrawals arrive in seconds',
      pt2: 'Multiple trading modes, up to 125 times leverage',
      dt1: 'iOS version download',
      dt2: 'Android version download'
    },
    about: {
      title: 'About Us',
      pt1: 'Platform Concept',
      pd1:
        'Let global assets circulate freely and create a new contract experience. X-xenogenetic, represents innovative genes, and provides a steady stream of power for industry innovation. M-motor, represents the goal, and continues to move forward with the goal of leading the contract trading experience .EX-exchange, on behalf of the trading platform, is committed to creating a fair, transparent and efficient trading ecosystem with the operating philosophy of service, listening, and thinking.',

      pt2: 'Core Values',
      pd2:
        'We are always listening, paying attention, and thinking forward, and are committed to creating a fair, transparent and efficient trading ecosystem. Provide global traders with a better trading experience, meet the actual needs of traders, and promote the development of the digital asset trading market. ',

      pt3: 'Team Background',
      pd3: 'The founding team is composed of top blockchain investors, traditional financial executives, top digital asset experts, and traditional metalworking technology teams. The core members of the R&D team are from Morgan Stanley, CICC, Hang Seng, Tencent, Noah Wealth.'
    }
  },
  start: {
    p_title: 'Transaction, start here',
    p_desc: 'Please enter your email address in the following area to register immediately',
    p_email: 'email',
    p_btn: 'Create'
  },
  download: {
    p_title: 'Download APP client',
    p_desc: 'Anytime, anywhere, multi-platform terminal transaction',
    p_btn: 'Download',
    p_scan_android: 'Android app',
    p_scan_ios: 'IOS app'
  },
  downpage: {
    t1: {
      p_title: 'Lightning Transaction Performance',
      p_desc: 'Provide a variety of transaction types to choose from, order matching is extremely fast, one-click flash exchange, and trading is more convenient'
    },
    t2: {
      p_title: 'Easy transfer of assets',
      p_desc: 'Easy transfer of assets in the account, real-time overview of digital assets, increase the pie chart of currency ratio'
    },
    t3: {
      p_title: 'Custom Trading Panel',
      p_desc: 'Freely define the trading layout according to different trading habits, and increase the entry of K-line and depth map'
    },
    t4: {
      p_title: 'Account Integration Management',
      p_desc: 'Multiple security verification settings, one account manages C2C accounts and currency accounts'
    }
  },
  login: {
    login: 'log in',
    title: 'User login',
    desc1: 'Login please',
    desc2: 'Official website',
    email: 'email',
    email2: 'E-mail',
    email3: 'Phone number',
    email_desc: 'Please input your email',
    phone_desc: 'Please enter phone number',
    password: 'password',
    password_desc: 'Please enter the password',
    forgot_password: 'Forgot password',
    no_account: 'No account yet'
  },
  signup: {
    signup: 'Sign up',
    title: 'User Registration',
    desc1: 'Welcome to login',
    desc2: 'Official Website',
    invitation_code: 'invitation code',
    invitation_desc: 'Please enter the invitation code',
    accept: 'Accept',
    service: 'User Agreement',
    and: 'and',
    policy: 'Privacy Protection Policy',
    have_account: 'Already have an account'
  },
  retrieve: {
    ptitle: 'Retrieve Password',
    pemail_code: 'Email Verification Code',
    pset: 'Set Password',
    set_title: 'Set Password',
    set_desc: 'You are resetting your password, please complete the following settings',
    new_password: 'New Password',
    password_desc: 'Please enter a new password',
    res_passowrd: 'Confirm Password',
    res_passowrd_desc: 'Please enter the confirm password'
  },
  valid_email: {
    title: 'Registration Verification',
    desc: 'You are registering an account, please complete the following verification',
    email_code: 'Email Verification Code',
    email_desc: 'Please enter the verification code',
    get_code: 'Get verification code'
  },
  ihead: {
    begin_trade: 'Start trading',
    helper: 'Novice Help',
    text: 'Document Center',
    new_currency: 'New Currency Subscription'
  },
  uhead: {
    title1: 'Commodities',
    title2: 'International Forex',
    title3: 'International Index',
    currency: 'Crypto Currency',
    lever: 'Leveraged Transaction',
    coins: 'Currency Transaction',
    contract: 'Contract Transaction',
    metal: 'Metal',
    energy: 'Energy',
    contract_info: 'Contract Information',
    helper: 'Novice Help',
    text: 'Document Center',
    assets: 'Assets',
    u_wallet: 'My Wallet',
    u_contract: 'Contract Account',
    u_lever: 'Leverage Account',
    u_account: 'Account Information',
    u_logout: 'Logout'
  },
  news: {
    label: 'News',
    update: 'Updated on'
  },
  guide: {
    label: "Beginner's Guide",
    list: [
      {
        ptitle: 'How to register for starthestar',
        pkey: 'k1'
      },
      {
        ptitle: 'Identity Authentication',
        pkey: 'k2'
      },
      {
        ptitle: 'Login password and transaction password settings',
        pkey: 'k3'
      },
      {
        ptitle: 'Deposit Coins',
        pkey: 'k4'
      },
      {
        ptitle: 'Coin transaction process',
        pkey: 'k5'
      },
      {
        ptitle: 'Withdrawal',
        pkey: 'k6'
      },
      {
        ptitle: ' mobile APP download',
        pkey: 'k7'
      }
    ],
    content: {
      k1: require('./currency/en/guide/k1.js').default,
      k2: require('./currency/en/guide/k2.js').default,
      k3: require('./currency/en/guide/k3.js').default,
      k4: require('./currency/en/guide/k4.js').default,
      k5: require('./currency/en/guide/k5.js').default,
      k6: require('./currency/en/guide/k6.js').default,
      k7: require('./currency/en/guide/k7.js').default
    }
  },
  kline: {
    h24_high: '24H high',
    h24_low: '24H low',
    h24_vol: '24H Volume',
    commission: 'Current Commission',
    record: 'Transaction Record',
    commission_list: 'Commission List',
    deal_list: 'Latest Deal',
    price: 'Price',
    xprice: 'Profit spread',
    bzj: 'Margin',
    number: 'Number',
    total: 'Total',
    time: 'Time',
    currency: 'Positions',
    type: 'Type',
    direction: 'Direction',
    multiple: 'Leverage',
    fee: 'Commission',
    amount: 'Total',
    status: 'Status',
    operate: 'Operation',
    buy: 'Buy',
    sell: 'Sell',
    pending: 'Pending order',
    trade_success: 'Trading successful',
    retract: 'Cancel',
    profit: 'Take profit',
    loss: 'Stop loss',

    all: 'All',
    all_currency: 'All currencies',
    new_price: 'Latest Price',
    change: 'Rise and fall',
    protocol: 'protocol',
    _status1: 'Review',
    _status2: 'Successful',
    _status3: 'Rejected'
  },
  coins: {
    title: 'Currency Transaction',
    market: 'market price',
    limit: 'Limit Price',
    profit: 'Take profit',
    loss: 'Stop loss',
    price: 'Price',
    current: 'Current',
    price_desc: 'Please enter the price',
    number: 'Number',
    number_desc: 'Please enter the number',
    available: 'Available',
    half: 'half warehouse',
    all: 'Full Warehouse',
    fee: 'Commission',
    amount: 'Transaction Amount',
    buy: 'Buy',
    sell: 'Sell',
    current_price: 'Current',
    open_price: 'Open Today',
    high: 'High',
    low: 'Low',
    yesterday: 'Close Yesterday',
    today_zf: 'Price Change',
    now_cc: 'Position',
    y_cc: 'Y Position ',
    jsj: 'Settlement price',
    zrjsj: 'Y Settlement price '
  },
  lever: {
    title: 'Leveraged Transaction',
    multiple: 'Leverage',
    multiple_desc: 'Please select a multiple',
    double: 'double',
    buy: 'Buy (up)',
    sell: 'Sell (down)'
  },
  contract: {
    title: 'Contract Transaction',
    time: 'Time',
    time_desc: 'Please select a time',
    minute: 'minute',
    second: 'Second',
    buy: 'Buy (up)',
    sell: 'Sell (down)',
    rate: 'Royalty ratio'
  },
  wallet: {
    title: 'My Assets',
    valuation: 'Total Asset Valuation',
    my_wallet: 'My Wallet',
    my_trade: 'Trading Account',
    my_transfer: 'Withdrawals record',
    my_deposit: 'Deposit record',
    cbdz: 'Deposit address',
    cbsl: 'Quantity',
    deposit: 'Deposit coins',
    withdrawal: 'Withdrawal',
    search: 'Search',
    currency: 'Positions',
    protocol: 'Protocol',

    liquidation: 'Close Position',
    liquidation_title: 'Quick Liquidation',
    trade_pwd: 'Trading Password',
    enter_trade_pwd: 'Enter trading password',
    submit_liquidation: 'Close the position immediately',

    amount: 'Total',
    available: 'Available',
    freeze: 'Freeze',
    market: 'Valuation',
    ljsy: 'Cumulative income',
    zrsy: "Yesterday's earnings",
    operate: 'Operation',
    countdown: 'Countdown',

    lever_title: 'Leverage Account',
    contract_title: 'Contract Account',
    direction: 'Direction',
    bond: 'Margin',
    buy_rate: 'multiple',
    profit: 'Profit and loss',
    profit1: 'Profit and loss',
    buy: 'Buy',
    sell: 'Sell',
    many: 'Buy',
    empty: 'Sell',

    contract_time: 'Contract Time',
    premium: 'Privilege',
    minute: 'minute',

    address: 'Address',
    address_desc: 'Please enter the address',
    number: 'Number',
    number_desc: 'Please enter the number of withdrawals',
    copy: 'Copy',
    confirm: 'OK',

    all: 'All',
    fee: 'Commission',
    actual: 'Actual arrival',

    premark: 'Attention',
    text_1: 'This address only accepts {name} assets, please do not recharge other currencies, otherwise the assets will not be retrieved',
    text_2: "{name} miner's fee is: current market value {rate}%/transaction, minimum standard: {min}{name}/transaction",
    text_3: 'Please confirm that the receiving address information is correct before transferring the funds. Once the assets are transferred out, they cannot be withdrawn.',
    text_4: '{name} mining fee is: {val} {name}/transaction'
  },
  record: {
    title: 'Funds Record',
    type: 'Type',
    all_currency: 'All currencies',
    all_type: 'All Types',
    select: 'Query',
    clear: 'Clear Filter',
    receive: 'Deposit',
    transfer: 'Withdrawal',
    buy: 'Buy',
    sell: 'Sell',
    normal: 'Ordinary',
    pending: 'Stop-Limit',
    wallet: 'Position details',
    zzzqy: 'Balance',
    zqy: 'Free Margin',
    fdyk: 'Floating P/L',
    fxl: 'Risk rate'
  },
  user: {
    account: 'Account Authentication',
    account_desc: 'Credit deposit, transaction and other functions must first pass account verification',

    password: 'Login Password',
    password_desc: 'Used to protect account security',

    modify: 'Modify',
    set: 'Set',

    find_paypwd: 'Retrieve Password',
    find_pay_pwd: 'Retrieve payment password',
    email: 'Email',
    enter_email: 'Please enter your email',
    email_code: 'Verification Code',
    enter_email_code: 'Please enter the email verification code',
    send: 'Send',

    pay_password: 'Payment Password',
    pay_password_desc: 'Used to protect asset security when trading',

    not_certified: 'Not Certified',
    certification: 'Certification in progress',
    verified: 'Verified',
    rejected: 'rejected',

    update_pwd: 'Modify login password',
    update_pay_pwd: 'Modify payment password',
    set_pay_pwd: 'Set payment password',

    old_pwd: 'Original Password',
    enter_old_pwd: 'Please enter the original password',

    new_pwd: 'New Password',
    enter_new_pwd: 'Please enter a new password',

    res_pwd: 'Confirm Password',
    enter_res_pwd: 'Please enter the confirm password',

    submit: 'Submit'
  },
  certified: {
    title: 'Account Authentication',
    front: 'ID card front',
    back: 'The back of the ID card',
    hand: 'Handhold ID card',
    name: 'Name',
    name_desc: 'Please enter a name',
    card: 'ID number',
    card_desc: 'Please enter ID number',
    submit: 'Submit'
  },
  trade: {
    title: 'transaction password',
    enter_password: 'Please enter transaction password',
    cancel: 'cancel',
    submit: 'submit'
  },
  revoke: {
    title: 'Prompt message',
    message: 'Are you sure you want to withdraw the order?',
    cancel: 'cancel',
    submit: 'confirm'
  },
  coins_assets: {
    title: 'Currency assets',
    total_equity: 'Total equity',
    equity: 'Account equity',
    number: 'Number of holdings',
    market_value: 'Current valuation'
  },
  lever_assets: {
    title: 'Leveraged assets',
    total_equity: 'Total equity',
    equity: 'Account equity',
    profit: 'Unrealized profit and loss',
    risk: 'Risk'
  },
  contract_assets: {
    title: 'Contract assets',
    total_equity: 'Total equity',
    equity: 'Account equity',
    profit: 'Unrealized profit and loss'
  },

  //文档菜单
  contract_menu: {
    contract: 'Contract Details',
    lever: 'Leverage Details',
    coins: 'Coin Index',
    history: 'Fund rate history',
    coins_rules: [
      {
        value: '1D',
        name: 'On the day'
      },
      {
        value: '1W',
        name: 'Current Week'
      },
      {
        value: '1M',
        name: 'Current Month'
      },
      {
        value: '1J',
        name: 'Current Season'
      }
    ],
    lever_info: 'Leverage information',
    contract_info: 'Contract information'
  },

  //合约详情
  contract_info: {
    bzj: 'Security Deposit',

    pt1: 'Settlement Currency',
    pt2: 'Contract Face Value',
    pt3: 'Minimum Change Price',
    pt4: 'Contract Term',
    pt5: 'Fund Rate Time',
    pt6: 'Marked Price',
    pt7: 'Volume of Position',
    pt8: '24-hour trading volume',
    pt9: 'Privilege',
    pt10: 'Maturity Delivery Income',
    pt11: 'Open Position Spread',
    pt12: 'Delivery and Settlement Mechanism',
    pt13: 'Transaction Service Fee',
    pt14: 'Lock Position',

    pv5: 'Every day 08:00 16:00 24:00',
    pv10_1: 'Long position: (average closing price-average opening price) x quantity',
    pv10_2: 'Short position: (average opening price-average closing price) x quantity',
    pv11: 'no',
    pv12: 'Automatic delivery at maturity',
    pv13: 'no',
    pv14: 'not support'
  },
  //杠杆详情
  metal_info: {
    pt1: 'Trading varieties',
    pt2: 'Settlement currency',
    pt3: 'Quotation unit',
    pt4: 'Contract size',
    pt5: 'Minimum change unit',
    pt51: 'Minimum trading unit',
    pt52: 'Maximum trading unit',
    pt6: 'Contract time',
    pt7: 'Trading time',
    pt8: 'Commission',
    pt9: 'Listed exchange',
    pt10: 'target spread',

    pv4: 'sustainable',
    pv5: '24-hour trading from Monday to Friday',
    pv51: '24 hours trading',
    pv6: 'Contract value0.08%',
    pv7: 'London Stock Exchange (LME)',
    pv71: 'New York Stock Exchange (NYMEX)',
    pv10: '{name} target spread is {num} pips'
  },
  //杠杆详情
  lever_info: {
    title: 'Leverage Details',
    bei: 'times',

    pt1: 'Settlement Currency',
    pt2: 'Contract Face Value',
    pt3: 'Minimum Change Price',
    pt4: 'Contract Term',
    pt5: 'Fund Rate Time',
    pt6: 'Marked Price',
    pt7: 'Volume of Position',
    pt8: '24-hour trading volume',
    pt9: 'Risk Reserve',
    pt10: 'Liquidation Profit',
    pt11: 'Open Position Spread',
    pt12: 'Automatic liquidation mechanism',
    pt13: 'Transaction Service Fee',
    pt14: 'Lock Position',

    pv4: 'Sustainable',
    pv5: 'Every day 08:00 16:00 24:00',
    pv10_1: 'Long position: (average closing price-average opening price) x quantity',
    pv10_2: 'Short position: (average opening price-average closing price) x quantity',
    pv12: 'The reserve risk ratio reaches 80%',
    pv13: 'Contract Value',
    pv14: 'Not supported'
  },

  //币币信息
  coins_desc: require('./currency/en/coins.js').default,

  //资金费率历史
  fun_history: {
    t1: 'Date',
    t2: 'Estimated Funding Rate',
    t3: 'Actual Funding Rate'
  },

  // 文档
  docs: {
    list: [
      {
        name: 'OTC trading rules',
        key: 'otc'
      },
      {
        name: 'KYC and anti-money laundering agreements',
        key: 'kyc'
      },
      {
        name: 'Law enforcement request policy',
        key: 'law'
      },
      {
        name: 'privacy protection',
        key: 'privacy'
      },
      {
        name: 'User Agreement',
        key: 'protocol'
      },
      {
        name: 'Risk notification',
        key: 'risk'
      }
      // {
      //   name: "Financial Strength & Security",
      //   key: "about1",
      // },
      // {
      //   name: "Regulation and Funds Safety",
      //   key: "about2",
      // },
    ],
    otc: require('./currency/en/otc').default,
    kyc: require('./currency/en/kyc.js').default,
    law: require('./currency/en/law.js').default,
    privacy: require('./currency/en/privacy.js').default,
    protocol: require('./currency/en/protocol').default,
    risk: require('./currency/en/risk').default
    //about1: require("./currency/en/about1").default,
    //about2: require("./currency/en/about2").default,
  },

  // 数字资产介绍
  asset: {
    title: 'Digital asset introduction',
    list: 'List of digital assets'
  },
  num_asset: require('./currency/en/asset').default,

  //新币认购
  new_currency: {
    _title: 'New Currency Subscription',
    _all: 'All',
    _progress: 'In progress',
    _end: 'Over',
    _success: 'Exchange successful',
    _progress_str: 'Progress',
    _total_str: 'Total Released',
    _date_time: 'Start and end date',
    _submit: 'Redeem now',

    _base_title: 'Basic Information',
    _status: 'Status',
    _currency: 'Accepted Currency',
    _symbol: 'Currency Symbol',
    _amount: 'Total lifted ban',
    _price: 'Initial Price',
    _limit: 'Personal purchase limit',
    _limit_amount: 'Personal purchase limit',
    _limit_one: 'Personal single limit lower limit',
    _no_limit: 'No limit',
    _position: 'Position',
    _begin_time: 'Start Time',
    _end_time: 'End Time',
    _redeemed: 'Amount redeemed',
    _surplus: 'surplus',
    _wallet: 'Wallet',
    _have: 'Currently held',
    _input: 'input quantity',
    _choose: 'Please choose',

    _new_wallet: 'New Currency Account',
    _new_price: 'Latest Price',
    _lock: 'Lock period',

    _login: 'Please log in',
    _no_data: 'No data',

    _avg_price: 'average price'
  }
}
