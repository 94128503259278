<template>
<div class="c-2-bg b-2-cl">
    <!-- <header class="heade">
        <div class="h-box">
            <div class="logo-div">
                <router-link to="/">
                    <img src="../../../static/imgs/t-logo4.png" alt="" width="40">
                </router-link>
            </div>
            <div class="df"></div>
            <div class="menu">
                <ul>
                    <li>
                        <router-link :to="{name:'Coins'}">{{$t('ihead.begin_trade')}}</router-link>
                    </li>
                    <li>
                        <router-link :to="{name:'NewCurrency'}">{{$t('ihead.new_currency')}}</router-link>
                    </li>
                    <li>
                        <router-link :to="{name:'Guide',query:{key:'k1'}}">{{$t('ihead.helper')}}</router-link>
                    </li>
                </ul>
            </div>
        </div>
        <div class="h-box h-right">
            <div class="login-box" v-if="token == ''">
                <div class="login-btn" @click="goLogin()">{{$t('login.login')}}</div>
                <div class="login-btn border1" @click="goSignup()">{{$t('signup.signup')}}</div>
            </div>
            <div class="login-box lang-div" v-if="token != ''">
                <div class="login-lang">
                    <div>{{email}}</div>
                    <ul class="lang-list-div lang-user h-1-bg">
                        <router-link :to="{name:'Wallet',query:{type:1}}">
                            <li>{{$t('uhead.u_wallet')}}</li>
                        </router-link>
                        <router-link :to="{name:'Wallet',query:{type:2}}">
                            <li>{{$t('uhead.u_lever')}}</li>
                        </router-link>
                        <router-link :to="{name:'Wallet',query:{type:3}}">
                            <li>{{$t('uhead.u_contract')}}</li>
                        </router-link>
                        <router-link :to="{name:'Wallet',query:{type:4}}">
                            <li>{{$t('new_currency._new_wallet')}}</li>
                        </router-link>
                        <a href="javascript:">
                            <li @click="Logout">
                                <span>{{$t('uhead.u_logout')}}</span>
                            </li>
                        </a>
                    </ul>
                </div>
            </div>
            <div class="login-box lang-div">
                <div class="login-lang">
                    <div>{{$t('lang.title')}}</div>
                    <ul class="lang-list-div h-1-bg1">
                        <li :class="(lang == 'en-us'?'active':'')" @click="language('en-us')">English</li>
                        <li :class="(lang == 'zh-cn'?'active':'')" @click="language('zh-cn')">简体中文</li>
                        <li :class="(lang == 'ja'?'active':'')" @click="language('ja')">日本語</li>
                        <li :class="(lang == 'ko'?'active':'')" @click="language('ko')">한국어</li>
                    </ul>
                </div>
            </div>
        </div>
    </header> -->

    <!-- 头部 -->
    <head-nav></head-nav>

    <router-view></router-view>



    <div id="footer-box" class="footer-box y-3-bg y-4-bd">
        <footer-nav></footer-nav>
        <!---->
        <!-- <section>
            <div class="footer-bar footer-default">
                <div class="footer-menu clearfix">
                    <div class="footer-logo">
                        <img src="../../../static/imgs/t-logo4.png" alt="" width="150">
                    </div>
                    <div class="footer-template">
                        <ul class="menu-ul-default">
                            <li class="item">
                                <router-link :to="{name:'Docs',query:{docs:'otc'}}">
                                    <span class="z-2-cl hoverHide">{{$t('index.footer.item1.pt1')}}</span>
                                    <span class="z-1-cl hoverShow">{{$t('index.footer.item1.pt1')}}</span>
                                </router-link>
                            </li>
                            <li class="item">
                                <router-link :to="{name:'Docs',query:{docs:'kyc'}}">
                                    <span class="z-2-cl hoverHide">{{$t('index.footer.item2.pt1')}}</span>
                                    <span class="z-1-cl hoverShow">{{$t('index.footer.item2.pt1')}}</span>
                                </router-link>
                            </li>
                            <li class="item">
                                <router-link :to="{name:'Docs',query:{docs:'privacy'}}">
                                    <span class="z-2-cl hoverHide">{{$t('index.footer.item3.pt1')}}</span>
                                    <span class="z-1-cl hoverShow">{{$t('index.footer.item3.pt1')}}</span>
                                </router-link>
                            </li>
                            <li class="item">
                                <router-link :to="{name:'Guide',query:{key:'k1'}}">
                                    <span class="z-2-cl hoverHide">{{$t('index.footer.item1.pt2')}}</span>
                                    <span class="z-1-cl hoverShow">{{$t('index.footer.item1.pt2')}}</span>
                                </router-link>
                            </li>
                            <li class="item">
                                <router-link :to="{name:'Docs',query:{docs:'law'}}">
                                    <span class="z-2-cl hoverHide">{{$t('index.footer.item2.pt2')}}</span>
                                    <span class="z-1-cl hoverShow">{{$t('index.footer.item2.pt2')}}</span>
                                </router-link>
                            </li>
                            <li class="item">
                                <router-link :to="{name:'Docs',query:{docs:'protocol'}}">
                                    <span class="z-2-cl hoverHide">{{$t('index.footer.item3.pt2')}}</span>
                                    <span class="z-1-cl hoverShow">{{$t('index.footer.item3.pt2')}}</span>
                                </router-link>
                            </li>
                            <li class="item">
                                <router-link :to="{name:'FeeHistoryInfo'}">
                                    <span class="z-2-cl hoverHide">{{$t('index.footer.item1.pt3')}}</span>
                                    <span class="z-1-cl hoverShow">{{$t('index.footer.item1.pt3')}}</span>
                                </router-link>
                            </li>
                            <li class="item">
                                <router-link :to="{name:'Asset',query:{key:'ada'}}">
                                    <span class="z-2-cl hoverHide">{{$t('index.footer.item2.pt3')}}</span>
                                    <span class="z-1-cl hoverShow">{{$t('index.footer.item2.pt3')}}</span>
                                </router-link>
                            </li>
                            <li class="item">
                                <router-link :to="{name:'Docs',query:{docs:'risk'}}">
                                    <span class="z-2-cl hoverHide">{{$t('index.footer.item3.pt3')}}</span>
                                    <span class="z-1-cl hoverShow">{{$t('index.footer.item3.pt3')}}</span>
                                </router-link>
                            </li>
                            <li class="item">
                                <router-link :to="{name:'ContractInfo'}">
                                    <span class="z-2-cl hoverHide">{{$t('index.footer.item1.pt4')}}</span>
                                    <span class="z-1-cl hoverShow">{{$t('index.footer.item1.pt4')}}</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="copy z-2-cl" style="border-color: rgb(41, 52, 72) !important;">
                © 2018-2021 STARTHESTAR.CO. All rights reserved
            </div>
        </section> -->
    </div>
</div>
</template>

<script>
import {
    setup,
    getLanguage
} from '@/utils/vue-i18n'
import HeadNav from '../../components/HeadNav.vue'
import FooterNav from '../../components/FooterNav.vue';
export default {
  components: {
      HeadNav,
      FooterNav
    },
    name: "Box",
    date() {
        return {
            //登录标识
            token: '',
            email: '',
            uid: '',
            lang: 'en-us'
        }
    },
    created() {
        this.lang = localStorage.getItem('lang')

        //检查登录
        this.checkToken()
    },
    methods: {
        checkToken() {
            //获取缓存的token
            let token = localStorage.getItem('token')
            if (token) {
                this.token = token
                this.email = localStorage.getItem('email')
                this.email = this.email.substr(0, 2) + '***' + this.email.substr(-7)
                this.uid = localStorage.getItem('uid')
            } else {
                this.token = ''
                this.email = ''
                this.uid = ''
            }
        },
        Logout() {
            localStorage.removeItem('token')
            localStorage.removeItem('email')
            localStorage.removeItem('uid')
            this.checkToken()
            let _this = this
            setTimeout(function () {
                _this.$router.push({
                    name: 'Login'
                })
            }, 1000)
        },
        goLogin() {
            this.$router.push({
                name: "Login"
            })
        },
        goSignup() {
            this.$router.push({
                name: "Signup"
            })
        },
        language(lang) {
            // 设置语言
            setup(lang)
            let local = getLanguage()
            this.lang = local.key
            //通知
            this.$emit('listenLang', lang)
        },
    }
}
</script>

<style src="../../../static/css/new/header.css" scoped type="text/css"></style>
<style src="../../../static/css/new/index.css" scoped type="text/css"></style>
