export default `<h2 style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:48px">
<strong><span style="font-family: 宋体;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 16px;background: rgb(255, 255, 255)">MANA (Decentraland)</span></strong>
</h2>
<p style=";text-indent: 0;padding: 0;line-height: 48px;background: rgb(255, 255, 255)">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 16px">&nbsp;</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: 宋体;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:宋体">一、项目名称</span></span></strong>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">Decentraland (MANA)</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: 宋体;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:宋体">二、项目定位</span></span></strong>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">基于以太坊区块链的、支持</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">VR</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">的</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">Minecraft&nbsp;</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">及</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">Second Life</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">式游戏平台。</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: 宋体;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:宋体">三、项目简评</span></span></strong>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">项目开发进度较为完善，即将进行小规模公测，近期交易量较大，用户社群活跃，有投资潜力。</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">最大的风险是：</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">1.&nbsp;</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">近期价格波动风险；</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">2.</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">内测版本出现重大</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">bug</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">或不符合用户期望值。</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: 宋体;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:宋体">四、项目进展</span></span></strong>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">1.&nbsp;ICO</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">已结束。</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">2.&nbsp;</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">内测用户参与游戏内部领地拍卖注册开放至</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">12</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">月</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">15</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">日。</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">3.&nbsp;12</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">月</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">15</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">日后将发布铁器时代测试版本，并开放游戏内部领地购买与私有化。</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">4.&nbsp;</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">铁器时代将增加多用户支持以及虚拟肖像和即时聊天功能，将允许在自己的领地上创建自定义物品和脚本运行。</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: 宋体;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:宋体">五、项目特点</span></span></strong>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">1.&nbsp;</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">第一个完全由用户控制的虚拟现实平台，一个完全融入式的可以互动的</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">3D</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">虚拟世界。</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">2.&nbsp;</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">用户可以通过以太坊区块链平台购买土地，成为土地的拥有者。</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">3.&nbsp;</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">用户对自己领地里的内容拥有完全控制。</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">4.&nbsp;</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">平台由公开的标准协议管理和保护。</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">5.&nbsp;</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">用户的领地拥有权使用区块链技术记录，保障其稀缺性和防伪性。</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">6.&nbsp;</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">现已支持微软混合现实平台（</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">MR</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">）。</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: 宋体;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:宋体">六、项目团队</span></span></strong>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">项目负责人：</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">Ari Meilich</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">，前</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">Benchrise&nbsp;</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">联合创始人。</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">创始人及技术负责人：</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">Esteban Ordano</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">，前</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">Smart Contract Solutions&nbsp;</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">创始人、</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">BitPay&nbsp;</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">软件工程师。</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: 宋体;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:宋体">七、项目顾问</span></span></strong>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">李笑来：</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">INBlockchain</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">创始人</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">Jake Brukhman</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">：</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">Coinfund&nbsp;</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">创始人</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">Luis Cuende</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">：</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">Aragon&nbsp;</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">项目负责人</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">Diego Doval</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">：前</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">Ning</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">首席技术官</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: 宋体;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:宋体">八、</span></span></strong><strong><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">ICO</span></strong><strong><span style="font-family: 宋体;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:宋体">进度</span></span></strong>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">ICO</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">时间：</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">17</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">年</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">8</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">月</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">18</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">日</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">ICO</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">进度：</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">100%</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: 宋体;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:宋体">九、</span></span></strong><strong><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">Token</span></strong><strong><span style="font-family: 宋体;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:宋体">概况</span></span></strong>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">项目代币</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">: MANA</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">（</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">ERC20</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">）</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">代币总量：</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">28.05</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">亿</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">ICO</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">比例：</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">40%</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">社群及合作伙伴：</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">20%</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">团队及早期投资人：</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">20%</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">（</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">3</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">年锁定）</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">基金会：</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">20%</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">代币</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">ICO</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">定价：</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">MANA =0.16RMB</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: 宋体;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:宋体">十、项目融资与估值</span></span></strong>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">ICO</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">融资：</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">2400</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">万</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">ICO</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">融资估值：</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">6000</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">万</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">代币流通量：</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">81%</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: 宋体;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:宋体">十一、代币产生与使用</span></span></strong>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">代币产生：智能合约</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">代币通胀：每年通胀</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">8%</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">，通胀率逐年递减</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">代币功能：游戏内部购买领地及其他虚拟产品和服务之用；购买领地后的代币将会被销毁。</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: 宋体;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:宋体">十二、常用链接</span></span></strong>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">官网：</span></span><a href="https://decentraland.org/"><span style="font-family: Arial;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">https://decentraland.org/</span></a>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">白皮书：</span></span><a href="https://decentraland.org/whitepaper.pdf"><span style="font-family: Arial;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">https://decentraland.org/whitepaper.pdf</span></a>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: 宋体;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:宋体">十三、币种所在分区</span></span></strong>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">创新区</span></span>
</p>
<p>
<span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
<p>
<br/>
</p>`