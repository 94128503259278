export default {
    title:"법 집행 요청 정책",
    content:`<p style="text-align: center;">
    <span style="font-size: 18px;"><strong>법 집행 요청 정책</strong></span>
</p>
<p>
    코디 글로벌
</p>
<p>
    <br/>
</p>
<p>
    때때로 starthestar.com은 전 세계의 법 집행 기관으로부터 고객 정보에 대한 요청을 받으며 여기에 귀하와 법 집행 기관을 위한 이러한 요청을 처리하는 방법에 대한 정보가 있습니다.
</p>
<p>
    <br/>
</p>
<p>
    법 집행 기관은 일반적으로 고객의 신원에 대한 정보와 거래 활동에 대한 정보라는 두 가지 유형의 데이터에 관심이 있습니다. 우리는 법 집행 기관에 적극적으로 협력하고 권한 증명을 제공할 수 있는 권한 있는 법 집행관의 법 집행 기관 요청에만 응답합니다.
</p>
<p>
    <br/>
</p>
<p>
    당사는 서비스 약관, 개인정보 보호정책 및 해당 법률에 따라 계정 정보 기록을 국제 법 집행 기관에 공개합니다. starthestar.com은 공개를 제공하기 위해 법 집행 요청에 적법 절차를 동반해야 합니다.
</p>
<p>
    <br/>
</p>
<p>
    starthestar.com은 법 집행 기관의 정책 및 절차에 대한 질문을 환영합니다. starthestar@starthestar.com으로 문의하십시오.
</p>
<p>
    <br/>
</p>
<p>
    법 집행 요청을 보낼 때 다음 정보를 포함하십시오.
</p>
<p>
    <br/>
</p>
<p>
    1. 수사기관의 명칭
</p>
<p>
    <br/>
</p>
<p>
    2. 법 집행 공무원이 정보를 입수할 권한이 있다는 증거(승인 증명서)
</p>
<p>
    <br/>
</p>
<p>
    3. 법 집행관의 신분증(법 집행 기관의 내부 증명서)
</p>
<p>
    <br/>
</p>
<p>
    4. 법 집행 기관에서 발행한 조사 서신 문서;
</p>
<p>
    <br/>
</p>
<p>
    5. 정부 도메인 이름이 포함된 이메일 주소
</p>
<p>
    <br/>
</p>
<p>
    6. 법 집행에 필요한 정보에 해당하는 법적 주체의 이름을 명확히합니다.
</p>
<p>
    <br/>
</p>
<p>
    7. 고객정보의 구체적인 내용을 요구하는 행위
</p>
<p>
    <br/>
</p>
<p>
    8. 국제공조조약에 따라 국제법집행기관이 요구하는 합당한 기한 및 관련 사법서류 제공을 포함한 기타 세부사항.
</p>
<p>
    <br/>
</p>
<p>
    starthestar.com은 자신과 고객을 보호하기 위해 법 집행 기관에 정보를 공개할 권리가 있습니다.
</p>
<p>
    <br/>
</p>`,
}