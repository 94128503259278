export default `<p>
    <strong>ETH（イーサリアム）</strong>
</p>
<p>
    <br/>
</p>
<p>
    <strong>1.イーサリアムとは何ですか？</strong>
</p>
<p>
    <br/>
</p>
<p>
    イーサリアムは、通貨のユーザー発行、スマートプロトコル、分散型トランザクション、分散型自律組織（DAO）または分散型自律組織（DAC）の確立など、多くの高度な機能をサポートできる次世代の暗号化元帳です。イーサリアムは、機能としてすべてのタイプの機能を具体的にサポートしているわけではありません。それどころか、イーサリアムには、「コントラクト」コードと呼ばれるメカニズムを通じて実現したい機能を記述できるチューリング完全なスクリプト言語が組み込まれています。コントラクトは自動エージェントのようなものです。トランザクションを受信するたびに、コントラクトは特定のコードを実行します。これにより、コントラクト内のデータストレージを変更したり、トランザクションを送信したりできます。高度なコントラクトは、独自のコードを変更することもできます。
</p>
<p>
    <br/>
</p>
<p>
    素人の言葉で言えば、イーサリアムはオープンソースプラットフォームのデジタル通貨およびブロックチェーンプラットフォームであり、開発者にブロックチェーン上でアプリケーションを構築および公開するためのプラットフォームを提供します。イーサリアムは、あらゆるもののプログラミング、分散化、保証、取引、投票、ドメイン名、金融取引所、クラウドファンディング、会社の管理、契約とほとんどの契約、知的財産権、ハードウェア統合の恩恵を受けるスマートアセットに使用できます。
</p>
<p>
    <br/>
</p>
<p>
    2017年2月28日、石油およびガス業界、金融業界、ソフトウェア開発企業を代表するグローバル企業のグループが、イーサリアムをエンタープライズレベルのブロックチェーンに発展させることに専念するエンタープライズイーサリアムアライアンス（EEA）を正式に立ち上げました。これらの企業には、英国の石油大手BP、JPMorgan Chase、ソフトウェア開発者Microsoft、Accenture、Santander Bank、BlockApps、BNY Mellon、CME Group、ConsenSys、Intel Microsoft、Nucoが含まれます。多くの企業や組織は、イーサリアムブロックチェーンに基づいてプロジェクトを開発し、トークンを発行しています。国連世界食糧計画は、イーサリアムブロックチェーンを使用して10,000人の難民を救済することに成功しました。
</p>
<p>
    <br/>
</p>
<p>
    <strong>2.詳細なパラメータ</strong>
</p>
<p>
    <br/>
</p>
<p>
    中国語名：イーサリアム英語名：イーサリアム英語略語：ETH
</p>
<p>
    開発者：Vitalik Buterinコアアルゴリズム：Ethashリリース日：2015/3/20
</p>
<p>
    ブロック時間：約15-17秒/ブロックブロック報酬：5総通貨：7200万+1872万/年
</p>
<p>
    主な機能：デジタル通貨やスマートコントラクトなどの機能を含む
</p>
<p>
    <br/>
</p>
<p>
    <strong>3.頻繁に使用されるリンク</strong>
</p>
<p>
    <br/>
</p>
<p>
    公式サイト：https：//www.ethereum.org/
</p>
<p>
    ブロッククエリ：https：//etherscan.io/
</p>
<p>
    <br/>
</p>`