<template>
<div class="desc-content">
    <div class="desc-box" :style="'min-height:'+(screenHeight - 73)+'px;'">
        <!-- 左边 -->
        <left-item></left-item>
        <!-- 右边 -->
        <div class="right-box">

            <!-- 标题 -->
            <div class="l-title">
                <h2>{{$t('contract_menu.coins')}}</h2>
            </div>
            <!-- 选择 -->
            <div class="l-select">
                <div class="list-select">
                    <el-select v-model="contractId" placeholder="币种" @change="onChangeContract">
                        <el-option v-for="(item,index) in contractList" :key="index" :label="item.name + (item.name == 'USDT'?'HUSD':'USDT')" :value="index">
                        </el-option>
                    </el-select>
                </div>
                <div class="list-select">
                    <el-select v-model="usdtId" placeholder="请选择">
                        <el-option v-for="(item,index) in usdtList" :key="index" :label="item.label" :value="index">
                        </el-option>
                    </el-select>
                </div>
                <div class="list-select">
                    <el-select v-model="contractRuleId" placeholder="日期" @change="onChangeTimeRule">
                        <el-option v-for="(item,index) in contractRules" :key="index" :label="item.name" :value="index">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <!-- kline -->
            <div class="l-kline">
                <!-- 交易信息 -->
                <div class="l-kline-trade">
                    <div class="k-symbol">{{currentInfo.name}} / {{currentInfo.name == 'USDT'?'HUSD':'USDT'}}</div>
                    <div :class="'k-price '+ (currentInfo.change >= 0 ?'up':'down')">{{currentInfo.close}}</div>
                    <div :class="'k-change ' + (currentInfo.change >= 0 ?'up':'down')">{{currentInfo.change > 0 ?'+':''}}{{currentInfo.change}}%</div>
                    <div class="kline-24h">
                        <div class="k-24h-item">
                            <label class="k-title">{{$t('kline.h24_high')}}</label>
                            <label>{{currentInfo.high}}</label>
                        </div>
                        <div class="k-24h-item">
                            <label class="k-title">{{$t('kline.h24_low')}}</label>
                            <label>{{currentInfo.low}}</label>
                        </div>
                        <div class="k-24h-item">
                            <label class="k-title">{{$t('kline.h24_vol')}}</label>
                            <label>{{currentInfo.vol}}</label>
                        </div>
                    </div>
                </div>
                <div class="l-kline-box">
                    <div class="kline-chart">
                        <TVChartContainer ref="TVChartContainer"></TVChartContainer>
                    </div>
                </div>
            </div>

            <!-- 详情标题 -->
            <div class="l-title">
                {{currentInfo.name}} / {{currentInfo.name == 'USDT'?'HUSD':'USDT'}}
            </div>

            <!-- 详情 -->
            <div class="info-box">
                <div class="info-item" v-for="(item,index) in currentValue" :key="index">
                    <div class="item-title">{{item.name}}</div>

                    <div class="item-content" v-if="item.type == 1">{{item.value}}</div>
                    <a :href="item.value" v-if="item.type == 2" style="color:#8e9298;" target="_blank">
                        <div class="item-content">{{item.value}}</div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    getCoins,
    // getContractRules,
} from '@/api/currency'
import TVChartContainer from '../components/TVChartContainer.vue'
import socket from '../utils/socket'
import {
    GetFixed
} from '../utils/helper'
import LeftItem from '../components/LeftItem.vue'

export default {
    components: {
        TVChartContainer,
        LeftItem
    },
    name: "Desc1",
    props: ['screenHeight'],
    data() {
        return {
            //合约列表
            contractList: [],
            //USDT保证金
            usdtList: [],
            //期限列表
            contractRules: [],

            //语言信息
            contractInfo: [],

            //当前合约
            contractId: 0,
            //当前USDT
            usdtId: 0,
            //当前期限
            contractRuleId: 0,

            //当前合约信息
            currentInfo: {},
            //当前合约值
            currentValue: {},
            //当前货币
            currentUSDT: {},
            //当前期限信息
            currentRule: {},
        }
    },
    watch: {
        '$route'(to) {
            console.log('路由', to)
            this.Init()
        }
    },
    created() {

        //设置保证金列表
        this.setUsdt()

        //获取对应语言信息
        this.getContractInfo()

        //获取合约列表
        this.getContract()
    },
    methods: {
        changList(type) {
            this.currentListType = type
        },

        /**
         * 切换合约币种
         */
        onChangeContract(index) {
            this.contractId = index
            this.setContractInfo(index)
        },
        /**
         * 切换期限
         */
        onChangeTimeRule(index) {
            this.contractRuleId = index
            this.setTimeRules(index)
        },
        /**
         * 获取语言的信息
         */
        getContractInfo() {
            this.contractInfo = this.$t('coins_desc')
        },
        /**
         * usdt保证金
         */
        setUsdt() {
            this.usdtList = [{
                value: "USDT",
                label: "USDT" + this.$t('contract_info.bzj')
            }]

            //默认就是usdt
            this.currentUSDT = this.usdtList[this.usdtId]

            //获取日期规则
            this.contractRules = this.$t('contract_menu.coins_rules')
        },
        /**
         * 设置当前币
         */
        setContractInfo(index) {
            //当前合约信息
            this.currentInfo = this.contractList[index]

            //当前合约值
            let symbol = this.currentInfo.symbol
            let item = this.contractInfo[symbol]
            this.currentValue = item

            //监听
            this.socketListen()
            // 初始化k线
            this.$refs.TVChartContainer.initWidget(this.currentInfo)

            console.log(this.currentValue, '当前币种信息')
        },
        /**
         * 设置当前规则
         */
        setTimeRules(index) {
            let item = this.contractRules[index]
            this.currentRule = item
            console.log(item, '当前期限')
        },
        /**
         * 获取合约
         */
        getContract() {
            // 获取列表
            getCoins().then(res => {
                this.contractList = res.data

                //设置默认
                this.setContractInfo(this.contractId)
            })

            // 获取期限
            // getContractRules().then(res => {
            //     this.contractRules = res.data

            //     //设置默认
            //     this.setTimeRules(this.contractRuleId)
            // })

            this.setTimeRules(this.contractRuleId)
        },
        socketListen() {
            let _this = this
            // 初始化历史k线
            socket.on("detail", function (data) {
                // 最新信息
                if (_this.currentInfo.symbol == data.symbol) {
                    _this.currentInfo.close = GetFixed(data.close)
                    _this.currentInfo.change = data.change
                    _this.currentInfo.high = GetFixed(data.high)
                    _this.currentInfo.low = GetFixed(data.low)
                    _this.currentInfo.vol = GetFixed(data.vol)
                    _this.currentInfo.amount = GetFixed(data.amount)
                }
            })

            // 连接后
            socket.on("onReady", function () {
                //启动心跳
                socket.sendPing()
            })

            socket.on("onReconnect", function () {
                // 断线重连
                // _this.getCoins()
            })
        },
    }
}
</script>

<style src="../../static/css/contract_info.css" scoped type="text/css"></style>
