export default `<p>
    <strong>UIP (향후 저작권)</strong>
</p>
<p>
    <br/>
</p>
<p>
    <strong>1. 프로젝트 이름</strong>
</p>
<p>
    향후 저작권 (UIP)
</p>
<p>
    <br/>
</p>
<p>
    <strong>2. 프로젝트 소개</strong>
</p>
<p>
    Future Copyright는 싱가포르 UIP 재단이 관리하는 블록 체인 기술을 기반으로하는 문화 및 엔터테인먼트 저작권을위한 지능형 거래 플랫폼입니다. 퓨처 저작권은 글로벌 엔터테인먼트 IP 저작권 보유자에게 저작권 예치금, 저작권 거래, NFT 상품 인큐베이션, 고급 암호화 아트 NFT 거래를 제공하는 원 스톱 스마트 엔터테인먼트 저작권 서비스입니다. 앞으로 저작권은 저작권 보유자가 저작권 디지털 자산 NFT를 만들고, 저작권 NFT를 거래하고, 블록 체인 기술이 가져온 자산 토큰 화, 소유권 표시, 디지털 희소성 등을 통해 저작권 가치를 극대화하는 데 도움이 될 것입니다.
</p>
<p>
    <br/>
</p>
<p>
    <strong>3. 토큰 개요 및 배포</strong>
</p>
<p>
    총 토큰 : 3,000,000,000
</p>
<p>
    총 유통량 : 1,340,000,000
</p>
<p>
    토큰 가격 : $ 0.029
</p>
<p>
    토큰 분배 :
</p>
<p>
    <br/>
</p>
<p>
    50 %는 주요 거래소에서 유통됩니다.
</p>
<p>
    16.7 %는 UIP가 문화 및 엔터테인먼트 생태계를 전개하기위한 커뮤니티 기금으로 사용될 것입니다.
</p>
<p>
    33.3 %는 UIP 프로젝트의 개발 기금으로 사용되며 무기한 고정되며 UIP 저작권 거래의 생태 건설, 거래 플랫폼의 매개체 및 팀 확장에 사용됩니다. 에
</p>
<p>
    <br/>
</p>
<p>
    <strong>4, 일반적으로 사용되는 링크</strong>
</p>
<p>
    프로젝트 웹 사이트 : http://www.uip.group/#/
</p>
<p>
    백서 : http://cdn.uip.vip/whitePaper/UnlimitedIP_WhitePaper.pdf
</p>
<p>
    블록 쿼리 : https://etherscan.io/token/0x4290563c2d7c255b5eec87f2d3bd10389f991d68
</p>
<p>
    <br/>
</p>`