<template>
<div class="a-7-bg">
        <head-nav></head-nav>
        <div class="page-content page-content-chain" style="position: relative; min-height: 710px;">
            <section data-v-c59c66e2="">
                <div data-v-c59c66e2="" class="pcAppDownload">
                    <div data-v-c59c66e2="" class="download-container f-2-cl appbg">
                        <div class="download-options c-5-bd">
                            <div class="download-options-logo"><img src="../../static/imgs/app.png" alt=""></div>
                            <p class="download-options-name f-8-cl">
                                {{$t('download.p_title')}}
                            </p>
                            <p class="download-options-text">{{$t('download.p_desc')}}</p>
                            <div class="download-options-buttons">
                                <button class="common-button common-button-solid u-8-bg u-16-cl download-button" style="height: 40px; line-height: 40px; padding-left: 20px; padding-right: 20px; margin-top: 0px;">
                                    <div class="common-button-slot">
                                        <!----><svg aria-hidden="true" class="icon icon-18">
                                            <use xlink:href="#icon-b_14"></use>
                                        </svg>
                                        &nbsp;
                                        Android
                                    </div>
                                    <!---->
                                </button>
                                <button class="common-button common-button-solid u-8-bg u-16-cl download-button" style="height: 40px; line-height: 40px; padding-left: 20px; padding-right: 20px; margin-top: 0px;">
                                    <div class="common-button-slot">
                                        <!----><svg aria-hidden="true" class="icon icon-18">
                                            <use xlink:href="#icon-b_13"></use>
                                        </svg>
                                        &nbsp;
                                        iPhone
                                    </div>
                                    <!---->
                                </button>
                                
                                </div>
                            <div class="download-options-rq" style="display: flex;align-items: center;justify-content: center;">
                                <div style="margin: 0px 25px;">
                                    <img src="../../static/imgs/download_app1.png" alt="">
                                    <p class="download-options-reText">
                                        {{$t('download.p_scan_android')}}
                                    </p>
                                </div>
                                <div style="margin: 0px 25px;">
                                    <img src="../../static/imgs/download_app2.png" alt="">
                                    <p class="download-options-reText">
                                        {{$t('download.p_scan_ios')}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="download-nav c-5-bd">
                            <ul class="download-nav-list clearfix">
                                <li class="download-nav-even clearfix"><img src="../../static/imgs/tag1.png" alt="" class="download-nav-img">
                                    <div class="download-nav-mode">
                                        <p class="download-nav-title f-1-cl">{{$t('downpage.t1.p_title')}}</p>
                                        <p class="download-nav-text">{{$t('downpage.t1.p_desc')}}</p>
                                    </div>
                                </li>
                                <li class="download-nav-even clearfix"><img src="../../static/imgs/tag2.png" alt="" class="download-nav-img">
                                    <div class="download-nav-mode">
                                        <p class="download-nav-title f-1-cl">{{$t('downpage.t2.p_title')}}</p>
                                        <p class="download-nav-text">{{$t('downpage.t2.p_desc')}}</p>
                                    </div>
                                </li>
                                <li class="download-nav-even clearfix"><img src="../../static/imgs/tag3.png" alt="" class="download-nav-img">
                                    <div class="download-nav-mode">
                                        <p class="download-nav-title f-1-cl">{{$t('downpage.t3.p_title')}}</p>
                                        <p class="download-nav-text">{{$t('downpage.t3.p_desc')}}</p>
                                    </div>
                                </li>
                                <li class="download-nav-even clearfix"><img src="../../static/imgs/tag4.png" alt="" class="download-nav-img">
                                    <div class="download-nav-mode">
                                        <p class="download-nav-title f-1-cl">{{$t('downpage.t4.p_title')}}</p>
                                        <p class="download-nav-text">{{$t('downpage.t4.p_desc')}}</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="download-step">
                            <p class="download-step-title f-1-cl">iOS 用户安装教程</p>
                            <ul class="download-step-list">
                                <li class="download-step-even">
                                    <div class="download-step-label c-5-bd">1</div>
                                    <div class="download-step-mode">
                                        <p class="download-step-h f-1-cl">下载APP</p>
                                        <p class="download-step-t">在手机中打开设置功能，选择"通用"；</p>
                                    </div>
                                    <div class="download-step-img c-5-bg"><img src="../../static/imgs/ios1.png" alt=""></div>
                                </li>
                                <li class="download-step-even">
                                    <div class="download-step-label c-5-bd">2</div>
                                    <div class="download-step-mode">
                                        <p class="download-step-h f-1-cl">设备管理</p>
                                        <p class="download-step-t">在通用中，选择"描述文件与设备管理"功能；</p>
                                    </div>
                                    <div class="download-step-img c-5-bg"><img src="../../static/imgs/ios2.png" alt=""></div>
                                </li>
                                <li class="download-step-even">
                                    <div class="download-step-label c-5-bd">3</div>
                                    <div class="download-step-mode">
                                        <p class="download-step-h f-1-cl">选择安装</p>
                                        <p class="download-step-t">在描述文件与设备管理中的企业级应用分类中，选择要安装的企业应用的文件名称（与打开时的提示一致），点击进入；</p>
                                    </div>
                                    <div class="download-step-img c-5-bg"><img src="../../static/imgs/ios3.png" alt=""></div>
                                </li>
                                <li class="download-step-even">
                                    <div class="download-step-label c-5-bd">4</div>
                                    <div class="download-step-mode">
                                        <p class="download-step-h f-1-cl">设置信任</p>
                                        <p class="download-step-t">进入企业签名页面后，确认企业签名中的公司名称与应用名称后，点击信任"企业签名公司名称"；</p>
                                    </div>
                                    <div class="download-step-img c-5-bg"><img src="../../static/imgs/ios4.png" alt=""></div>
                                </li>
                                <li class="download-step-even">
                                    <div class="download-step-label c-5-bd">5</div>
                                    <div class="download-step-mode">
                                        <p class="download-step-h f-1-cl">启动APP</p>
                                        <p class="download-step-t">回到桌面，重新打开应用即可使用。</p>
                                    </div>
                                    <div class="download-step-img c-5-bg"><img src="../../static/imgs/ios5.png" alt=""></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- <div data-v-c59c66e2="" class="h5AppDownload">
                    <div data-v-c59c66e2="" class="download-container f-2-cl" style="background-image: url(&quot;https://chaindown-oss.oss-accelerate.aliyuncs.com/static/1/images/e251ac08917590692f19318e974e91ae.png#0E1A2E&quot;);">
                        <div class="download-options c-5-bd">
                            <div class="download-options-logo"><img src="https://saas-oss.oss-cn-hongkong.aliyuncs.com/upload/20190701104146540.png " alt=""></div>
                            <p class="download-options-name f-8-cl">
                                下载APP客户端
                            </p>
                            <p class="download-options-text">随时随地 多平台终端交易</p>
                            <div class="download-options-buttons"><button class="common-button common-button-solid u-8-bg u-16-cl download-button" style="height: 40px; line-height: 40px; padding-left: 20px; padding-right: 20px; margin-top: 0px;">
                                    <div class="common-button-slot">
                                        <svg aria-hidden="true" class="icon icon-18">
                                            <use xlink:href="#icon-b_14"></use>
                                        </svg>
                                        &nbsp;
                                        Android
                                    </div>
                                </button></div>
                        </div>
                        <div class="download-nav c-5-bd">
                            <ul class="download-nav-list clearfix">
                                <li class="download-nav-even clearfix"><img src="https://chaindown-oss.oss-accelerate.aliyuncs.com/static/1/images/d83960abb2c25e1d08d8c14729343b63.png" alt="" class="download-nav-img">
                                    <div class="download-nav-mode">
                                        <p class="download-nav-title f-1-cl">闪电交易性能</p>
                                        <p class="download-nav-text">提供多样交易类型选择，订单极速撮合，一键闪兑，买卖更便捷</p>
                                    </div>
                                </li>
                                <li class="download-nav-even clearfix"><img src="https://chaindown-oss.oss-accelerate.aliyuncs.com/static/1/images/89476306bd62a47fd331508fdb3d0fee.png" alt="" class="download-nav-img">
                                    <div class="download-nav-mode">
                                        <p class="download-nav-title f-1-cl">资产轻松划转</p>
                                        <p class="download-nav-text">轻松划转账户内资产，实时的数字资产概览，增加币种占比饼图</p>
                                    </div>
                                </li>
                                <li class="download-nav-even clearfix"><img src="https://chaindown-oss.oss-accelerate.aliyuncs.com/static/1/images/398b3fbc35f8944f5af1cbfb7047aea9.png" alt="" class="download-nav-img">
                                    <div class="download-nav-mode">
                                        <p class="download-nav-title f-1-cl">自定义交易面板</p>
                                        <p class="download-nav-text">根据不同交易习惯自由定义交易布局，增加K线与深度图入口</p>
                                    </div>
                                </li>
                                <li class="download-nav-even clearfix"><img src="https://chaindown-oss.oss-accelerate.aliyuncs.com/static/1/images/299f9725cf2ec259323a27fba7598a5e.png" alt="" class="download-nav-img">
                                    <div class="download-nav-mode">
                                        <p class="download-nav-title f-1-cl">账户集成管理</p>
                                        <p class="download-nav-text">多重安全验证设置，一个账户管理C2C账户与币币账户</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <a href="https://app-build.oss-accelerate.aliyuncs.com/BBKX/BBKX_5.2.2_RN_5232.apk"></a>
                    </div>
                </div> -->
            </section>
        </div>
        <!-- footer -->

        <footer-nav></footer-nav>
    </div>
</template>

<script>
import FooterNav from '../components/FooterNav.vue'
import HeadNav from '../components/HeadNav.vue'
export default {
  components: { HeadNav, FooterNav },

}
</script>
<style src="../../static/css/download.css" scoped type="text/css"></style>
