export default `<h2 style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:48px">
<strong><span style="font-family: Times New Roman;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 16px;background: rgb(255, 255, 255)">UIP (Future copyright)</span></strong>
</h2>
<p style=";text-indent: 0;padding: 0;line-height: 48px;background: rgb(255, 255, 255)">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">1. Project name</span></span></strong><span style="font-family: Microsoft Yahei; color: rgb(35, 42, 74); letter-spacing: 0; font-size: 14px"><br/></span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px "><span style="font-family:Microsoft Yahei">Future Copyright</span> (UIP)</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter- spacing: 0;font-size: 14px"><br/></span><strong><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">II. Project introduction</span></span></strong><span style="font-family: Microsoft Yahei Black;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><span style="font-family: Microsoft Yahei;color: rgb (35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">The future copyright is Singapore</span> UIP A cultural and entertainment copyright intelligent trading platform based on blockchain technology managed by the foundation. Future Copyright is committed to providing copyright deposits, copyright transactions, NFT product incubation, and high-end encryption art NFT transactions for global entertainment IP copyright holders. It is a one-stop entertainment copyright intelligent service. In the future, copyright will help copyright holders create copyright digital asset NFTs, trade copyright NFTs, and maximize copyright value through asset tokenization, ownership marking, digital scarcity, etc. brought by blockchain technology. </span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><strong><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei">Three ,</span>Token overview and distribution</span></strong><span style="font-family: Microsoft Yahei; color: rgb(35, 42, 74); letter-spacing: 0;font-size : 14px"><br/></span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft YaHei">Total Tokens:</span> 3,000,000,000</span><span style="font-family: Microsoft YaHei;color: rgb(35, 42, 74) ;letter-spacing: 0;font-size: 14px"><br/></span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">Total circulation:</span> 1,340,000,000&nbsp;</span><span style="font-family: Microsoft Yahei ;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><span style="font-family: Microsoft Yahei;color: rgb( 35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">Generation Coin price:</span> $0.029&nbsp; &nbsp;&nbsp;</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font- size: 14px"><br/></span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"> <span style="font-family:Microsoft Yahei">Token distribution:</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">50% are circulated on major exchanges;&nbsp;</ span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><span style ="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">16.7% will be used as a community fund for UIP's cultural and entertainment ecology;&nbsp;</ span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><span style ="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">33.3% is used as the UIP project development fund, locked indefinitely, and will be used The ecological construction of UIP copyright trading, as the medium of the trading platform, and the expansion of the team. &nbsp;</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span ><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><strong><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei"> Four, commonly used links</span></span></strong><span style="font-family: Microsoft Yahei; color: rgb(35, 42, 74); letter-spacing: 0; font-size: 14px"><br/></span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">Project website:</span></span><a href="http://www.unlimitedip.io/"></a><a href="# /"><span style="font-family: Microsoft Yahei;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">http://www.uip.group/ #/</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >White paper:</span></span><a href="http://cdn.uip.vip/whitePaper/UnlimitedIP_WhitePaper.pdf"><span style="font-family: Microsoft Yahei;color: rgb( 53, 124, 225);letter-spacing: 0;font-size: 14px">http://cdn.uip.vip/whitePaper/UnlimitedIP_WhitePaper.pdf</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Block query:</span></span><a href="https://etherscan.io/token/0x4290563c2d7c255b5eec87f2d3bd10389f991d68"><span style="font-family: Microsoft Yahei;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">https://etherscan.io/token/0x4290563c2d7c255b5eec87f2d3bd10389f991d68</span></a><span style="font-family: Microsoft Yahei; color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p>
<span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
<p>
<br/>
</p>`