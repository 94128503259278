export default `<p>
    <strong>BTC（ビットコイン）</strong>
</p>
<p>
    <br/>
</p>
<p>
    <strong>1.ビットコインとは何ですか？</strong>
</p>
<p>
    <br/>
</p>
<p>
    ビットコイン（略してBTC）は、現在最も広く使用されているデジタル通貨です。2009年1月3日に誕生しました。これは、ピアツーピア（P2P）送信用のデジタル暗号化通貨であり、合計で2,100万です。ビットコインネットワークは10分ごとに一定量のコインをリリースしますが、これは2140年に制限に達すると予想されています。ビットコインは投資家から「デジタルゴールド」と呼ばれています。ビットコインは、特定のアルゴリズムに基づく多数の計算によって生成されます。発行するのは特定の通貨機関に依存しません。P2Pネットワーク全体の多数のノードで構成される分散データベースを使用して、すべてのトランザクション動作を確認および記録し、通貨の流通を確保するための暗号化設計。リンクのセキュリティにより、ビットコインの大量生産を通じて通貨の価値を操作できないようにすることができます。暗号化に基づく設計により、ビットコインは実際の所有者のみが転送、支払い、および現金化することができます。また、通貨の所有権と流通取引の匿名性を保証します。
</p>
<p>
    に
</p>
<p>
    ビットコインは、その分散化、グローバルな流通、低い取引コスト、匿名の流通により、テクノロジー愛好家に支持されています。最近、ウォール街や多くの国の中央銀行などの伝統的な金融機関がビットコインブロックチェーン技術を研究し始めました。日本政府はビットコインを合法的な支払い方法として公式に認めており、ますます多くの日本企業がビットコイン支払いを受け入れています。
</p>
<p>
    <br/>
</p>
<p>
    <strong>2.ビットコインの利点は何ですか？</strong>
</p>
<p>
    に
</p>
<p>
    支払いの自由-いつでもどこでも、任意の金額の資金を即座に支払い、受け取ることができます。銀行の休日、国境、制限はありません。ビットコインを使用すると、ユーザーは自分の資金を完全に管理できます。
</p>
<p>
    に
</p>
<p>
    非常に低い手数料-現在、ビットコインの支払いの処理には手数料がかからないか、ごくわずかな手数料しかありません。ユーザーは、トランザクションに手数料を含めて処理の優先順位を取得し、ネットワークからトランザクションの確認をより早く受け取ることができます。さらに、マーチャントがトランザクションを処理し、ビットコインを法定通貨に毎日変換し、マーチャントの銀行口座に直接資金を預けるのを支援するマーチャントプロセッサーがあります。これらのサービスはビットコインに基づいているため、PayPalやクレジットカードネットワークよりもはるかに低い料金を提供できます。
</p>
<p>
    に
</p>
<p>
    商人のリスクを軽減する-ビットコイン取引は安全で取り消し不能であり、顧客の機密情報や個人情報は含まれていません。これにより、不正または不正なチャージバックによる加盟店への損失が回避され、PCI標準に準拠する必要がありません。加盟店は、クレジットカードが使用できない、または不正率が許容できないほど高い新しい市場に簡単に拡大することもできます。その結果、手数料が安くなり、市場が大きくなり、管理コストが少なくなります。
</p>
<p>
    に
</p>
<p>
    セキュリティと制御-ビットコインユーザーはトランザクションを完全に制御できます。マーチャントは、他の支払い方法で発生する可能性のある、見つけるのが簡単ではない、または簡単ではない料金を強制的に請求することはできません。ビットコインで支払うと、トランザクションで個人情報をバインドする必要がなくなり、個人情報の盗難に対する優れた保護が提供されます。ビットコインユーザーは、バックアップと暗号化を通じて資金を保護することもできます。
</p>
<p>
    に
</p>
<p>
    透明性と中立性-ビットコインの資金供給自体に関するすべての情報はブロックチェーンに保存されており、誰でもリアルタイムで確認して使用できます。 Bitcoinプロトコルはパスワードで保護されているため、個人または組織が制御または操作することはできません。これにより、ビットコインコアは完全に中立で、透過的で、予測可能であると信じられています。
</p>
<p>
    に
</p>
<p>
    <strong>3.ビットコインの欠点は何ですか？</strong>
</p>
<p>
    に
</p>
<p>
    受け入れレベル-ビットコインを知らない人はまだたくさんいます。毎日、より多くの企業がビットコインの恩恵を受けたいという理由でビットコインを受け入れていますが、このリストはまだ少ないです。ネットワーク効果の恩恵を受けるために、より多くの企業がビットコインをサポートする必要があります。
</p>
<p>
    に
</p>
<p>
    ボラティリティ-流通しているビットコインの合計値とビットコインを使用している企業の数は、可能な規模と比較してまだ非常に小さいです。したがって、比較的小さなイベント、トランザクション、またはビジネス活動は、その価格に大きな影響を与える可能性があります。理論的には、ビットコイン市場とテクノロジーが成熟するにつれて、このボラティリティは減少します。この世界はこれまでに新しい通貨を見たことがないので、それがどのように進行するかを想像することは本当に難しいです（そして同時にエキサイティングです）。
</p>
<p>
    に
</p>
<p>
    開発段階-ビットコインソフトウェアはまだベータ版であり、多くの未完成の機能がアクティブな開発段階にあります。ビットコインをより安全にし、一般の人々がよりアクセスしやすくするために、新しいツール、機能、およびサービスが開発されています。これらの機能の一部は、現在すべてのユーザーが利用できるわけではありません。ビットコインビジネスのほとんどは新しく、まだ保険を提供していません。一般的に言って、ビットコインはまだ成熟の過程にあります。
</p>
<p>
    に
</p>
<p>
    <strong>4.詳細なパラメータ</strong>
</p>
<p>
    に
</p>
<p>
    中国語名：ビットコイン英語名：ビットコイン英語略語：BTC
</p>
<p>
    開発者：中本聡コアアルゴリズム：SHA-256リリース日：2009/01/03
</p>
<p>
    合計：2100万ブロック時間：約600秒/ブロックコンセンサスの証明：POW
</p>
<p>
    リスク：拡張紛争でフォークの潜在的なリスクがあります
</p>
<p>
    通貨単位：BTC / XBT
</p>
<p>
    1ビットコイン（ビットコイン、BTC）
</p>
<p>
    10-2ビットセント（ビットセント、cBTC）
</p>
<p>
    10-3ミリビットコイン（ミリビットコイン、mBTC）
</p>
<p>
    10-6マイクロビットコイン（μBTC）
</p>
<p>
    10-8聡
</p>
<p>
    に
</p>
<p>
    <strong>5.頻繁に使用されるリンク</strong>
</p>
<p>
    <br/>
</p>
<p>
    公式サイト：https：//bitcoin.org/zh_CN/
</p>
<p>
    ビットコインFAQ：https：//bitcoin.org/zh_CN/faq
</p>
<p>
    ブロッククエリ：https：//blockchain.info/
</p>
<p>
    ビットコインフォーラム：https：//bitcointalk.org/
</p>
<p>
    市場価値クエリのウェブサイト：https：//coinmarketcap.com
</p>
<p>
    <br/>
</p>`