export default `<h2 style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:48px">
<strong><span style="font-family: 宋体;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 16px;background: rgb(255, 255, 255)">LTC (莱特币)</span></strong>
</h2>
<p style=";text-indent: 0;padding: 0;line-height: 48px;background: rgb(255, 255, 255)">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<strong><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">1.什么是莱特币？</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">莱特币诞生于</span>2011年11月9日，被称为是“数字白银”。莱特币在技术上和比特币具有相同的实现原理。它是第一个基于Scrypt算法的网络数字货币，与比特币相比，莱特币拥有更快的交易确认时间，更高的网络交易容量和效率。莱特币现在拥有完整的产业链，充分的流动性，足以证明其是成熟、安全、稳定的商用金融系统。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<strong><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">2.数据块链</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">莱特币数据块链与其竞争者</span>——比特币相比起来，能够处理更大的交易量。由于数据块的产出更加频繁，该网络可以支持更多的交易，并且在将来无需修改软件。&nbsp;</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">因此，商家可以获得更快的交易确认，而且在销售大额商品时依然能够等待更多的交易确认。</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<strong><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">3.钱包加密</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">钱包加密能够保证您钱包中私钥的安全，从而让您可以查看交易情况及账户余额，但是在您使用莱特币之前必须输入密码。该功能不仅能够防止病毒及木马的侵扰，同时还是支付之前，一项合法性的有效检查。</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<strong><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">4.挖矿奖励</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">“矿工们”目前每数据块可以产出25个莱特币。每4年，生产的莱特币货币量将减少一半(每经过840000个数据块)。&nbsp;</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">因此，莱特币网络生产的货币总量将是比特币的</span>4倍，即8400万个莱特币。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">莱特币的创造和转让基于一种开源的加密协议，不受任何中央机构的管理。莱特币旨在改比特币，与其相比，莱特币具有三种显著差异：</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">（</span>1）莱特币网络每2.5分钟（而不是10分钟）就可以处理一个块，因此可以提供更快的交易确认。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">（</span>2）莱特币网络预期产出8400万个，是比特币网络发行货币量的四倍之多。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">（</span>3）莱特币在其工作量证明算法中使用了由Colin Percival首次提出的scrypt加密算法。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">2017年4&nbsp;月，莱特币社区经过投票达成协议，决定通过隔离验证软分叉对其区块链进行升级。同年6月，莱特币闪电网络正式上线。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<strong><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">5.详细参数</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">中文名：莱特币</span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;英文名：Litecoin&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 英文简称：LTC</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">研发者：</span>Charlie Lee&nbsp; &nbsp; &nbsp;核心算法：Scrypt&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 发布日期：2011/10/7</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">区块时间：</span>150秒/块&nbsp; &nbsp; &nbsp;发行总量：8400万&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;减半时间：4年</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">共识证明：</span>POW&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;难度调整：2016个区块&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;区块奖励：最初50LTC，当前25LTC</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">主要特色：发行量大：发行量是比特币的</span>4倍；交易快速：确认时间仅2.5分钟；隔离验证激活</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">不足之处：莱特币相关应用还较少</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">风险：隔离验证激活后，网络漏洞的风险</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<strong><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">6.常用链接</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">官方网站：</span></span><a href="https://litecoin.org/"><span style="font-family: 微软雅黑;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">https://litecoin.org/</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">区块查询：</span></span><a href="http://explorer.litecoin.net/"><span style="font-family: 微软雅黑;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">http://explorer.litecoin.net/</span></a>
</p>
<p>
<span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
<p>
<br/>
</p>`