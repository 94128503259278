export default `<h2 style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:48px">
<strong><span style="font-family: 宋体;font-size: 16px;background: rgb(255, 255, 255)">BHD (BitcoinHD (BHD))</span></strong>
</h2>
<p style=";padding: 0;line-height: 48px;background: rgb(255, 255, 255)">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">一、项目名称</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">BitcoinHD (BHD)</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">二、项目定位</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">BHD<span style="font-family:宋体">是基于 </span><span style="font-family:Calibri">Conditioned Proof Of Capacity </span><span style="font-family:宋体">的新型加密货币，其主要创新点是采用新型的条件化容量证明共识机制，使用硬盘作为共识的参与者，让人人都能开采加密货币。硬盘挖矿使得</span><span style="font-family:Calibri">BHD</span><span style="font-family:宋体">更趋向去中心化，通过以数学算法和分布式开采由此产生信用和价值。</span><span style="font-family:Calibri">BHD</span><span style="font-family:宋体">将致力于打造成改变加密货币生产方式的有价金融系统。</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">三、币概况与分配</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">供应总量：</span>2100<span style="font-family:宋体">万枚</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">现流通量：</span>6,457,200</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">四、常用链接</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">官网：</span></span><a href="http://www.btchd.org/"><span style=";font-family:&#39;Times New Roman&#39;;color:rgb(53,124,225)">www.btchd.org</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">白皮书：</span>&nbsp;</span><a href="http://www.btchd.org/BHD-%E7%99%BD%E7%9A%AE%E4%B9%A62.0.pdf"><span style=";font-family:&#39;Times New Roman&#39;;color:rgb(53,124,225)">www.btchd.org/BHD-<span style="font-family:宋体">白皮书</span><span style="font-family:Times New Roman">2.0.pdf</span></span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">区块查询</span>:&nbsp;</span><a href="http://www.btchd.org/explorer/"><span style=";font-family:&#39;Times New Roman&#39;;color:rgb(53,124,225)">www.btchd.org/explorer/</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p>
<span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
<p>
<br/>
</p>`