export default `<p>
    <strong>BCH（ビットコインキャッシュノード）</strong>
</p>
<p>
    <br/>
</p>
<p>
    1.プロジェクト名
</p>
<p>
    ビットコインキャッシュノード（BCH）
</p>
<p>
    に
</p>
<p>
    2.プロジェクト紹介
</p>
<p>
    BCH（ビットコインキャッシュ）は2020年11月15日にハードフォークアップグレードを受けます。 BCHNコンピューティング能力の現在の割合には、コミュニティとユーザーのフィードバックに基づいて絶対的な利点があるため、HuobiGlobalは次の手配を行います。
</p>
<p>
    1. BCHNノードスキームは、BCHの元の名前、Kライン、および取引ペアを継承します。 フォーク中の市場センチメントにより大幅な変動が生じる可能性がありますので、事前に対応・リスク管理を行ってください。 合理的な判断と慎重な意思決定。
</p>
<p>
    2. BCHフォークの後、Huobi GlobalはすべてのユーザーのBCHアセットのスナップショットを取り、フォークトークンをエアドロップします。 フォークノードが安定して稼働した後、コミュニティのフィードバックに基づいてフォーク通貨取引ペアを開くかどうかを評価します。
</p>
<p>
    に
</p>
<p>
    3、通貨の概要と流通
</p>
<p>
    トークンの合計：21,000,000 BCH
</p>
<p>
    <br/>
</p>`