export default {
    title: "OTC 거래 규칙",
    content: `<p>
    <span style="font-size: 18px;"><strong>장외 거래 규칙</strong></span>
</p>
<p>
    <br/>
</p>
<p>
    장외 거래 규칙
</p>
<p>
    <br/>
</p>
<p>
    OTC 거래의 기본 원칙:
</p>
<p>
    1. 이 규정은 공포된 날부터 공식적으로 시행되며, 수시로 개정 또는 개선되며, 개정 또는 개선된 새로운 규정은 공포된 날부터 시행합니다.
</p>
<p>
    2. 본 규정 및 개정 또는 개선된 규정에 동의하지 않을 경우 즉시 플랫폼 서비스 이용을 중단하시기 바랍니다.
</p>
<p>
    3. 귀하가 플랫폼 서비스를 계속 사용하는 경우, 귀하는 이 규칙과 수시로 개정되거나 개선되는 규칙의 내용을 완전히 이해하고 수락하며 자발적으로 이에 구속되는 것으로 간주합니다.
</p>
<p>
    <br/>
</p>
<p>
    구매자가 따라야 하는 원칙:
</p>
<p>
    주문을 생성한 후 구매자는 다음을 수행해야 합니다.
</p>
<p>
    1. 주문서에 명시된 결제 시간 내에 결제를 완료한 후 &quot;결제 완료&quot;를 클릭합니다.
</p>
<p>
    2. 내 실명결제수단, 주문금액, 주문이 지정한 추심계좌를 이용하여 실시간으로 주문결제를 완료하는 행위
</p>
<p>
    3. 비실시간 결제를 선택하는 경우에는 사전에 판매자의 동의를 받아야 합니다.
</p>
<p>
    <br/>
</p>
<p>
    판매자가 따라야 하는 원칙:
</p>
<p>
    주문을 생성한 후 판매자는 다음을 수행해야 합니다.
</p>
<p>
    1. 플랫폼에서 인증한 징수 계정을 제공합니다.
</p>
<p>
    2. 구매자로부터 전액 지불을 받은 후 주문에 지정된 시간 내에 릴리스를 완료합니다.
</p>
<p>
    3. 구매자의 동의 없이 주문을 취소할 수 없습니다.
</p>
<p>
    4. 생성된 주문가격에 대해 구매자의 동의 없이는 이의를 제기할 수 없습니다.
</p>
<p>
    <br/>
</p>
<p>
    참고: 비실시간 도착 방법은 현재 Paypal에서 은행 카드로, Visa에서 은행 카드로, 17:00-9:00 사이 50,000건 이상의 주문에 대한 단일 송금 등입니다. 각 결제 채널의 비즈니스 변화에 따라, 비실시간 도착 특정 유형의 계정 방법은 그에 따라 조정됩니다.
</p>
<p>
    <br/>
</p>
<p>
    사용자는 자금 세탁 방지 원칙을 준수해야 합니다.
</p>
<p>
    <br/>
</p>
<p>
    1. 사용자는 플랫폼의 &quot;StarthestarOTC 플랫폼 자금 세탁 방지 및 테러 자금 조달 방지 사용자 지침&quot;을 준수하고 관련 의무를 엄격하게 수행해야 합니다.
</p>
<p>
    <br/>
</p>
<p>
    2. 사용자는 사용자 신원 및 계정에 대한 플랫폼의 실명 인증 관리 요구 사항을 엄격히 준수해야 하며 플랫폼에 표시된 관련 계정이 모두 합법적으로 등록되고 사용되는지 확인해야 합니다.
</p>
<p>
    <br/>
</p>
<p>
    3. 사용자가 플랫폼의 자금세탁 방지 위험 통제 경고를 발동하면 플랫폼의 추가 자금 세탁 방지 검증 작업에 적극적으로 협력하고 관련 위험 통제 증거 자료 제공을 적극 지원해야 합니다(특정 조치는 플랫폼의 위험 통제 대상). 당시 감사 요구 사항).
</p>
<p>
    <br/>
</p>
<p>
    구매자 예외
</p>
<p>
    <br/>
</p>
<p>
    치료 조치
</p>
<p>
    <br/>
</p>
<p>
    1. 주문이 생성된 후 구매자가 자발적으로 주문을 취소하거나 결제 시간 초과로 인해 시스템이 자동으로 주문을 취소하는 경우.
</p>
<p>
    <br/>
</p>
<p>
    당일 4회에 도달하면 시스템은 구매자의 구매 기능을 제한하고, 2일 연속 트리거되면 시스템은 구매자 계정의 일부 기능을 추가로 제한합니다.
</p>
<p>
    <br/>
</p>
<p>
    2. 결제가 완료되지 않은 경우 &quot;결제 완료&quot;를 클릭합니다.
</p>
<p>
    <br/>
</p>
<p>
    판매자는 주문 취소를 요청할 권리가 있으며 구매자가 같은 날 두 번 불만을 제기한 경우 시스템은 구매자 계정의 일부 기능을 제한합니다. 구매자의 계정을 48시간 동안 제한합니다.
</p>
<p>
    <br/>
</p>
<p>
    3. 판매자가 규칙을 위반하지 않고 구매자가 지불하지 않는 경우 이유없이 &quot;이의 제기&quot;를 클릭하십시오.
</p>
<p>
    <br/>
</p>
<p>
    판매자는 주문 취소를 요청할 권리가 있습니다. 구매자가 같은 날 두 번 불만을 제기하는 경우 시스템은 구매자 계정의 일부 기능을 제한합니다.
</p>
<p>
    <br/>
</p>
<p>
    4. 판매자의 주문내역 페이지에 표시된 수취계좌로 결제가 이루어지지 않습니다.
</p>
<p>
    <br/>
</p>
<p>
    판매자는 주문을 취소하고 판매자에게 디지털 자산을 반환하도록 요청할 권리가 있으며 구매자는 잘못된 지불을 전송할 위험이 있습니다.
</p>
<p>
    <br/>
</p>
<p>
    5. 결제가 되지 않을 경우, 먼저 &quot;결제했습니다&quot;를 클릭하신 후 결제를 진행해주세요.
</p>
<p>
    <br/>
</p>
<p>
    판매자는 환불 후 거래를 계속하거나 주문을 취소할 수 있는 권리가 있습니다.
</p>
<p>
    1) 판매자가 거래를 선택하는 경우 판매자 사용자는 전체 지불을 받은 후 10분 이내에 해제를 완료해야 합니다.
</p>
<p>
    2) 판매자가 주문 취소를 선택하는 경우 판매자는 주문 이의 제기 후 2시간 이내에 환불을 완료해야 하며 디지털 자산은 판매자에게 반환됩니다.
</p>
<p>
    <br/>
</p>
<p>
    6. 플랫폼 계정의 실명과 일치하는 결제 계정을 사용하여 결제하지 않는 행위
</p>
<p>
    <br/>
</p>
<p>
    판매자는 거래를 직접 거부할 권리가 있으며 판매자는 지불을 받은 후 48시간 이내에 환불 절차를 완료할 수 있으며 디지털 자산은 판매자에게 반환됩니다. 법인계좌를 이용한 결제(법인과 인증인의 실명 동일 여부 불문)는 여전히 비실명결제입니다. 이러한 상황으로 인해 구매자가 2회 불만을 제기한 경우 시스템은 구매자 계정의 일부 기능을 제한합니다.
</p>
<p>
    <br/>
</p>
<p>
    7. 주문서에 명시된 결제 시간 내에 결제하세요.
</p>
<p>
    (1) 구매자가 모든 지불을 완료하고 구매자가 자발적으로 또는 시스템 초과 근무에 의해 주문을 취소한 경우
</p>
<p>
    (2) 구매자가 부분결제를 완료하였으나 구매자의 자발적인 주문 또는 초과근무 시스템에 의해 주문이 취소된 경우.
</p>
<p>
    <br/>
</p>
<p>
    주문이 구매자에 의해 자발적으로 취소되고 시간 초과로 시스템에 의해 주문이 취소된 경우 판매자는 거래를 계속할지 또는 거래를 환불할지 선택할 권리가 있습니다.
</p>
<p>
    <br/>
</p>
<p>
    1. 판매자가 거래를 선택하는 경우,
</p>
<p>
    - 판매자는 일반 사용자이며 플랫폼에서 직접 주문을 재개설합니다. 판매자는 재개방 후 10분 이내에 자산 해제를 완료해야 합니다.
</p>
<p>
    - 판매자가 광고주 사용자이고 주문금액이 50,000 이하인 경우 &quot;Reopen order and release&quot;를 이용하여 Asset Release 작업을 완료하고, 주문금액이 50,000 이상인 경우 Asset Release 후 10분 이내에 Asset Release를 완료하시기 바랍니다. 주문이 재개됩니다.
</p>
<p>
    <br/>
</p>
<p>
    2. 판매자가 주문을 취소하기로 선택한 경우 판매자는 플랫폼 또는 구매자의 사용자로부터 피드백을 받은 후 60분 이내에 환불을 완료하고 디지털 자산을 판매자에게 반환해야 합니다.
</p>
<p>
    <br/>
</p>
<p>
    8. 복수 결제가 필요한 경우, 모든 송금이 완료되지 않은 경우 먼저 &quot;결제 완료&quot;를 클릭한 후 계속해서 결제를 완료합니다.
</p>
<p>
    <br/>
</p>
<p>
    1) 판매자와 사전에 의사 소통하고 판매자가 자신의 행동을 허용하는 경우 판매자는 계속 주문을 완료해야 합니다.
</p>
<p>
    2) 판매자는 판매자의 동의 없이 거래를 계속하거나 환불 후 주문을 취소할 수 있는 권리가 있습니다.
</p>
<p>
    - 판매자가 거래 진행을 선택하는 경우 판매자는 모든 지불을 받은 후 10분 이내에 해제를 완료해야 합니다.
</p>
<p>
    - 판매자가 주문 취소를 선택하는 경우 판매자는 결제 후 30분 이내에 환불을 완료해야 하며, 디지털 자산은 판매자에게 반환됩니다.
</p>
<p>
    <br/>
</p>
<p>
    9. 결제 금액이 주문 금액과 일치하지 않습니다.
</p>
<p>
    <br/>
</p>
<p>
    1) 결제금액이 주문금액보다 많고 주문이 완료된 경우 판매자는 결제완료 후 30분 이내에 초과금액을 환불하여야 합니다.
</p>
<p>
    2) 결제 금액이 주문 금액보다 적은 경우 판매자는 거래를 계속할지 또는 환불 후 주문 취소를 요청할지 선택할 수 있습니다.
</p>
<p>
    - 판매자가 거래를 계속하기로 선택하면 실제 주문 금액에 따라 거래를 완료하고 주문을 완료할 권리가 있습니다.
</p>
<p>
    - 판매자가 주문 취소를 선택하는 경우 판매자는 결제 후 30분 이내에 환불을 완료해야 하며, 디지털 자산은 판매자에게 반환됩니다.
</p>
<p>
    <br/>
</p>
<p>
    10. 구매자가 &quot;결제했습니다&quot;를 클릭한 후 판매자가 1시간 이내에 결제를 받지 못했습니다.
</p>
<p>
    <br/>
</p>
<p>
    1) 판매자는 환불 후 거래를 계속하거나 주문을 취소할 권리가 있습니다.
</p>
<p>
    - 판매자가 거래를 선택하는 경우 판매자는 지불을 받은 후 10분 이내에 해제를 완료해야 합니다.
</p>
<p>
    - 판매자가 주문 취소를 선택하는 경우 판매자는 결제 후 30분 이내에 환불을 완료해야 하며, 디지털 자산은 판매자에게 반환됩니다.
</p>
<p>
    <br/>
</p>
<p>
    2) 판매자가 광고주이며, 이의 제기 후 10분 이내에 구매자와 연락이 되지 않으며, 판매자가 대금을 받지 못한 것으로 확인되면 광고주의 보증 자산 범위 내에서 옴부즈맨이 우선적으로 주문을 취소합니다.
</p>
<p>
    <br/>
</p>
<p>
    11. 결제 완료 후 판매자의 수취계좌는 해당 계좌의 실명확인이 불가능합니다.
</p>
<p>
    <br/>
</p>
<p>
    1) 구매자의 결제계좌가 플랫폼 실명계좌와 일치함을 확인하고, 주문서에 명시된 시간 내에 결제를 완료하면 정상적으로 주문이 완료됩니다.
</p>
<p>
    <br/>
</p>
<p>
    2) 구매자의 결제계좌가 플랫폼의 실명계좌와 일치하지 않음을 확인한 후, 제6조의 조치에 따라 처리합니다.
</p>
<p>
    <br/>
</p>
<p>
    12. 구매자가 결제 후 판매자의 추심계좌가 비정상적인 경우
</p>
<p>
    <br/>
</p>
<p>
    1) 구매자가 정상 계좌를 증명할 수 없는 경우 판매자는 수령 계좌가 정상으로 전환된 후 24시간 이내에 환불을 완료해야 하며 플랫폼은 주문을 취소합니다.
</p>
<p>
    2) 구매자는 계정이 정상임을 증명할 수 있으며 구매자의 계정이 정상임을 확인한 후 주문이 완료되고 디지털 자산이 구매자에게 전달되어야 합니다.
</p>
<p>
    <br/>
</p>
<p>
    예: 판매자가 V-plus 사용자이고 보증 자산 범위 내에서 디지털 자산은 판매자에게 반환되며 판매자는 수령 계정이 정상으로 전환된 후 24시간 이내에 환불을 완료해야 합니다.
</p>
<p>
    <br/>
</p>
<p>
    13. 결제 시 주의사항: &quot;디지털 화폐, BTC, 비트코인, Starthestar&quot; 등과 유사합니다.
</p>
<p>
    <br/>
</p>
<p>
    판매자는 거래를 계속할지 또는 거래를 환불할지 선택할 권리가 있습니다.
</p>
<p>
    1) 판매자가 거래를 선택하는 경우 판매자는 지불을 받은 후 10분 이내에 해제를 완료해야 합니다.
</p>
<p>
    2) 판매자가 환불을 선택하고 거래가 완료되지 않은 경우 주문이 취소되고 디지털 자산이 판매자에게 반환되며 판매자는 결제를 받은 후 60분 이내에 환불을 완료합니다. 따라서 계정이 동결된 경우 위 12조의 조치에 따라 처리됩니다.
</p>
<p>
    <br/>
</p>
<p>
    14. 위험관리시스템에 의해 구매자 사용자가 악의적으로 저가의 주문을 경매에 부치는 것으로 판단되는 경우
</p>
<p>
    <br/>
</p>
<p>
    1) 구매자가 지불하지 않으면 플랫폼은 주문을 취소하고 자산을 판매자에게 반환합니다.
</p>
<p>
    <br/>
</p>
<p>
    2) 구매자가 결제를 완료했으며 이의 제기 처리 후 10분 이내에 환불 절차를 완료하면 플랫폼에서 주문 취소 및 판매자에게 자산 반환을 지원합니다.
</p>
<p>
    <br/>
</p>
<p>
    구매자가 이와 같이 두 번 행동하면 위험 관리 시스템이 구매자 계정의 일부 기능을 제한합니다.
</p>
<p>
    <br/>
</p>
<p>
    참고: 이 규칙에는 판매자의 환불 또는 해제가 포함되며 판매자가 제시간에 작업을 완료하지 못할 경우 시스템은 판매자 계정의 일부 기능을 제한합니다.
</p>
<p>
    <br/>
</p>
<p>
    &nbsp; &nbsp;참고: OTC 거래에 제3자 전화 시스템을 사용하는 과정에서 언어 충돌, 학대, 조롱 또는 기타 과도한 언어 행동을 적극적으로 유발하는 경우 시스템은 계정의 일부 기능을 제한합니다.
</p>
<p>
    <br/>
</p>
<p>
    판매자 예외
</p>
<p>
    <br/>
</p>
<p>
    치료 조치
</p>
<p>
    <br/>
</p>
<p>
    1. 주문내역 페이지에 표시되는 수취계좌와 주문채팅창에 발송되는 수취계좌에 대한 책임은 전적으로 판매자에게 있습니다.
</p>
<p>
    <br/>
</p>
<p>
    판매자가 제공한 추심계좌는 본인 소유가 아니거나, 타인의 안내에 따라 타인의 추심계좌를 묶거나, 타인의 계좌를 채팅창으로 보낼 수 있습니다. 구매자가 지불을 완료한 후 자산 결정은 구매자에게 귀속됩니다.
</p>
<p>
    <br/>
</p>
<p>
    2. 주문이 생성된 후 주문 가격에 대한 이의가 있습니다.
</p>
<p>
    <br/>
</p>
<p>
    구매자의 동의하에 주문 가격이 협상되지 않는 한 주문은 정상적으로 체결되며 판매자는 주문에 지정된 시간 내에 주문을 해제합니다.
</p>
<p>
    <br/>
</p>
<p>
    3. 판매자가 제공한 추심계좌가 비정상입니다.
</p>
<p>
    <br/>
</p>
<p>
    구매자는 거래를 계속하거나 주문을 취소할 권리가 있습니다.
</p>
<p>
    - 구매자가 거래를 선택하고 주문에 명시된 기간 내에 구매자가 전액을 지불한 것으로 확인되는 경우 판매자는 결제를 받은 후 주문에 지정된 시간 내에 해제를 완료해야 합니다.
</p>
<p>
    - 구매자가 주문 취소를 선택하면 판매자가 환불을 완료한 후 디지털 자산이 판매자에게 반환됩니다.
</p>
<p>
    <br/>
</p>
<p>
    4. 판매자가 유효한 지불 방법을 제때 제공하지 않았기 때문에 구매자가 지불을 완료하지 못하여 주문이 취소되었습니다.
</p>
<p>
    <br/>
</p>
<p>
    판매자가 결제수단을 제공하지 않아 주문을 완료할 수 없습니다.플랫폼이 이의 제기를 처리하면 주문을 직접 취소하고 판매자 사용자에게 위약금을 부과합니다.플랫폼에 의한 주문 취소는 숫자를 계산하지 않습니다 당일 구매자의 취소
</p>
<p>
    <br/>
</p>
<p>
    판매자는 2번의 항의를 받았으며 시스템은 판매자 계정의 일부 기능을 제한합니다.
</p>
<p>
    <br/>
</p>
<p>
    5. 판매자(비광고주)는 자신의 실명과 일치하는 유효한 결제 정보를 구속하지 않거나 구매자에게 플랫폼의 실명과 일치하지 않는 결제 수단을 제공하지 않습니다.
</p>
<p>
    <br/>
</p>
<p>
    예를 들어, Alipay 또는 WeChat을 통해 결제를 받을 때 주문 내역 페이지에 표시된 수취인 이름과 계좌 번호 및 QR 코드 수취인 이름이 일치하지 않습니다.
</p>
<p>
    <br/>
</p>
<p>
    1) 구매자는 주문을 취소하고 플랫폼에 불만을 제기할 권리가 있습니다.
</p>
<p>
    2) 판매자는 3번의 항의를 받았으며, 시스템은 판매자의 계정을 영구적으로 제한합니다.
</p>
<p>
    구매자는 거래를 계속하기로 선택하므로 불만 사항이 2회에 도달하면 시스템이 구매자 계정의 일부 기능을 제한합니다.
</p>
<p>
    <br/>
</p>
<p>
    6. 판매자는 플랫폼에서 동일한 이름이 아닌 구매자의 계정에서 지불을 수집하고 거래를 거부하지 않습니다.
</p>
<p>
    <br/>
</p>
<p>
    1) 이로 인한 위험은 판매자가 부담합니다.
</p>
<p>
    2) 이로 인한 불만 사항이 있을 경우 시스템은 판매자 계정의 일부 기능을 제한합니다.
</p>
<p>
    <br/>
</p>
<p>
    7. 구매자가 전액 결제 후 &quot;결제 완료&quot;라고 표시하고 판매자가 구매자로부터 전액 결제를 받은 후 20분 이내에 해제가 완료되지 않습니다.
</p>
<p>
    <br/>
</p>
<p>
    구매자는 거래를 계속하거나 주문을 취소하고 환불을 요청할 권리가 있습니다.
</p>
<p>
    - 구매자가 폐쇄를 선택하면 옴부즈맨은 자산이 구매자에게 속하는 것으로 결정합니다.
</p>
<p>
    - 구매자가 환불 요청 후 주문을 취소하는 경우 판매자는 30분 이내에 환불을 완료해야 합니다.
</p>
<p>
    <br/>
</p>
<p>
    예: 구매자가 결제를 표시한 후 20분 이내에 이의 제기를 클릭하여 판매자가 릴리스할 수 없고 판매자가 채팅 창에서 구매자에게 연락할 수 없는 경우 주문이 정상적으로 완료됩니다.
</p>
<p>
    <br/>
</p>
<p>
    &nbsp; &nbsp;참고: OTC 거래에 제3자 전화 시스템을 사용하는 과정에서 언어 충돌, 학대, 조롱 또는 기타 과도한 언어 행동을 적극적으로 유발하는 경우 시스템은 계정의 일부 기능을 제한합니다.
</p>
<p>
    <br/>
</p>
<p>
    위험 제어 예외 트리거
</p>
<p>
    <br/>
</p>
<p>
    치료 조치
</p>
<p>
    <br/>
</p>
<p>
    1. 사용자가 위험 제어 시스템에 의해 비정상적으로 감지되면 사용자는 플랫폼의 위험 제어에서 요구하는 추가 자금 세탁 방지 검증 완료를 명시적으로 거부하거나 적극적으로 협조하지 않습니다.
</p>
<p>
    <br/>
</p>
<p>
    플랫폼은 이 계정을 비정상으로 직접 식별하고 사용자의 협력에 의해 제공된 위험 통제 증거가 완료될 때까지 사용자의 위험 수준을 높이고 해당 사용자의 계정 및 관련 계정을 직접 동결하는 것을 포함하되 이에 국한되지 않는 조치를 취할 권리가 있습니다. 플랫폼의 위험 관리를 통과했습니다. 통제 감사.
</p>
<p>
    <br/>
</p>
<p>
    2. 사용자가 StarthestarOTC에서 코인을 다른 플랫폼/창구 판매 그룹으로 구매하여 영리 및 기타 고위험 행위를 위해 판매하는 것으로 확인된 경우.
</p>
<p>
    <br/>
</p>
<p>
    플랫폼은 상황에 따라 인출 지연, 거래 제한, 계정 동결 등을 포함하되 이에 국한되지 않는 조치를 선택할 권리가 있습니다.
</p>
<p>
    <br/>
</p>
<p>
    참고: 이러한 행동이 발견되면 진행 중인 주문이 있는 경우 광고주는 거래를 완료하지 않도록 선택할 권리가 있습니다.
</p>
<p>
    <br/>
</p>
<p>
    3. 본인 또는 타인을 대신하여 계정을 사용하지 않는 경우: 플랫폼 리스크 관리를 통해 사용자가 계정을 판매, 대여, 임대하여 타인이 사용하도록 하거나 코인을 매매하는 행위가 있는 경우 다른 사람을 대신하여.
</p>
<p>
    위험 알림: 자신의 정보 보안에 주의를 기울이고 다른 사람이 범죄에 이용하지 않도록 하십시오.
</p>
<p>
    <br/>
</p>
<p>
    플랫폼은 OTC 거래의 일시적 또는 영구적 제한, 인출 지연, 인출 제한 또는 해당 사용자의 계정 및 관련 계정 직접 동결을 포함하되 이에 국한되지 않는 하나 이상의 조치를 선택할 권리가 있습니다.
</p>
<p>
    <br/>
</p>
<p>
    참고: 이러한 행동이 발견되면 진행 중인 주문이 있는 경우 광고주는 거래를 완료하지 않도록 선택할 권리가 있습니다.
</p>
<p>
    <br/>
</p>
<p>
    4. 플랫폼의 다른 사용자의 테스트 카드를 사용하는 경우: 사용자가 주문 없이 플랫폼의 다른 사용자에게 소액을 지불하고 그 행동을 합리적으로 설명할 수 없는 경우.
</p>
<p>
    <br/>
</p>
<p>
    플랫폼은 OTC 거래를 일시적 또는 영구적으로 제한, 통화 인출 제한 또는 계정 동결과 같은 하나 이상의 조치를 선택할 권리가 있습니다.
</p>
<p>
    <br/>
</p>
<p>
    5. 불법적인 출처로부터 디지털 자산 또는 자금을 수수 또는 도입: 다른 플랫폼/위챗 그룹에서 받은 코인 또는 자금이 블랙 코인이거나 자금이 블랙 머니임을 사용자가 알고 합리적인 상식으로 판단하지만 여전히 위험을 무시하고 블랙 코인/돈 흐름을 이 플랫폼의 다른 사용자 계정으로 직접 이체합니다.
</p>
<p>
    <br/>
</p>
<p>
    이 플랫폼은 그러한 사용자의 계정 및 관련 계정의 모든 기능을 직접적이고 영구적으로 제한합니다.
</p>
<p>
    <br/>
</p>
<p>
    6. 자금세탁으로 판명된 이용자: 이용자가 위험관리시스템에 의해 적발되거나 위험관리담당자의 확인이 있는 경우, 이용자는 자금세탁 등의 범죄행위에 직접 가담하거나 이를 조장하는 행위를 말합니다.
</p>
<p>
    <br/>
</p>
<p>
    이 플랫폼은 그러한 사용자의 계정 및 관련 계정의 모든 기능을 직접적이고 영구적으로 제한합니다.
</p>
<p>
    <br/>
</p>
<p>
    7. 사법적 동결 : 사법당국이 이용자 계정의 동결을 요구하는 경우
</p>
<p>
    <br/>
</p>
<p>
    이 플랫폼은 사법 당국이 작성한 사법 서한에 따라 사용자 계정을 동결합니다. 동결 기간이 만료되면 사법부는 동결을 계속하기 위해 동결 갱신 서한을 제시해야 합니다. 동결 기간 동안 이 플랫폼에 대한 사용자의 동결 해제 신청이 무효가 됩니다. 조정을 위해 동결 기관에 문의하십시오.
</p>
<p>
    <br/>
</p>
<p>
    8. 사법 조사: 사법 당국은 사용자가 사법 조사, 상담 및 기타 형식을 포함하되 이에 국한되지 않는 이 플랫폼의 계정 정보를 얻어야 합니다.
</p>
<p>
    <br/>
</p>
<p>
    이 플랫폼은 해당 사용자의 계정 및 관련 계정의 일부 기능을 적절하게 제한합니다.
</p>
<p>
    <br/>
</p>
<p>
    고객 서비스 이메일 starthestar@starthestar.com
</p>
<p>
    <br/>
</p>`,
}