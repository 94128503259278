<template>
  <div id="dark">
    <!-- 头部 -->
    <head-box @listenLang="listenLang"></head-box>
    <!-- <head-nav @listenLang="listenLang"></head-nav> -->

    <!-- 内容 -->
    <router-view ref="view" :screenHeight="screenHeight" @onLogout="onLogout"></router-view>
    <!-- 页脚 -->
    <!-- <footer-box></footer-box> -->
    <!-- <footer-box-index></footer-box-index> -->
  </div>
</template>

<script>
import HeadBox from "@/components/HeadBox";
// import HeadNav from "@/components/HeadNav";
// import FooterBox from "@/components/FooterBox";
// import FooterBoxIndex from '../components/FooterBoxIndex';
export default {
  name: "User",
  props: ["screenHeight"],
  components: {
    // FooterBox,
    HeadBox,
    // FooterBoxIndex
    // HeadNav
  },
  methods: {
    onLogout() {
      console.log("登录失效了");
      this.$emit("onLogout");
    },
    listenLang(local) {
      console.log(local, "headbox");
      if (typeof this.$refs.view.listenLang == "function") {
        this.$refs.view.listenLang(local);
      }
    },
  },
};
</script>

<style scoped>
</style>
