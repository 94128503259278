module.exports = {
  base: {
    title: 'STARTHESTAR',
    desc1: 'König des Vertrags',
    desc2: 'Weltweit führend im Kontrakthandel',
    d1: 'Vertrauen',
    d2: 'Ehrlichkeit',
    d3: 'Stärke',
    more: 'Mehr',
    top: 'zurück an die Spitze',
    download: 'Lade App herunter',
    zszh: 'Echter Account',
    mnzh: 'Demokonto',
    content: 'Handeln Sie mit einem Weltmarktführer im Kontrakthandel'
  },
  demo: {
    title: 'Registrieren erfolgreich!',
    title2: 'Analoges Konto',
    submit: 'Bestätigen'
  },
  lang: {
    title: 'Deutsch'
  },
  message: {
    invalid_email: 'Ungültige E-Mail',
    invalid_password: 'Ungültiges Passwort',
    invalid_new_password: 'Ungültiges neues Passwort',
    invalid_res_password: 'Ungültiges Bestätigungspasswort',
    invalid_email_code: 'Ungültiger E-Mail-Bestätigungscode',
    password_inconsistent: 'Inkonsistente Passwörter',
    invalid_invitation_code: 'Ungültiger Einladungscode',
    should_agree: 'Bitte lesen und akzeptieren Sie die Registrierungsbedingungen',
    not_null: 'Dieses Feld kann nicht leer sein',
    send_email: 'Bitte versuchen Sie es nach {:s}s erneut',
    invalid_number: 'Ungültiger Wert',
    invalid_balance: 'Ungültiges Guthaben',
    invalid_rate: 'Bitte wählen Sie mehrere aus',
    invalid_rule: 'Bitte wählen Sie eine Regel aus',
    invalid_system_rule: 'Keine Regeln im System',
    not_enough: 'Nicht genug vorhanden',
    price_high: 'Der Preis muss höher sein als {Preis}',
    price_low: 'Preis muss kleiner als {Preis} sein',
    number_high: 'Die maximale Anzahl an Abhebungen beträgt {num}',
    number_low: 'Der Mindestauszahlungsbetrag beträgt {num}',
    copy_address: 'Adresse erfolgreich kopieren {text}',
    invalid_upload_front: 'Bitte laden Sie das Vorderseitenfoto des Ausweises hoch',
    invalid_upload_back: 'Bitte laden Sie ein Foto der Rückseite Ihres Ausweises hoch',
    invalid_upload_hand: 'Bitte laden Sie ein Passfoto aus der Hand hoch',
    invalid_upload_name: 'Bitte geben Sie den Namen des Zertifikats ein',
    invalid_upload_card: 'Bitte geben Sie die ID-Nummer ein',
    upload_pic_type: 'Bilder können nur im JPG- oder PNG-Format hochgeladen werden',
    upload_pic_size: 'Die Bildgröße des Uploads darf 10 MB nicht überschreiten'
  },
  index: {
    title2: 'Kostenloser Handel auf dem globalen Markt 24 Stunden ohne Unterbrechung',
    desc2: 'Implementieren Sie Ihre Handelsstrategie in einem extrem liquiden Markt mit einem 24-Stunden-Handelsvolumen von',
    desc2_1: 'auf der gesamten Plattform',
    list: {
      name: 'Markt',
      h24_price: '24 Stunden aktueller Preis',
      h24_change: '24H Wechsel',
      h24_high: '24H höchster Preis',
      h24_low: '24 Stunden niedrigster Preis',
      h24_vol: '24-Stunden-Lautstärke'
    },
    footer: {
      desc:
        'starthestar Blockchain Technology Ltd mit Hauptsitz in den Vereinigten Staaten ist ein weltweit führendes Blockchain-Technologieunternehmen. Das Unternehmen hat die internationale US-Finanzlizenz MSB erhalten. Es ist ein konformes Blockchain-Technologiedienstleistungsunternehmen, das Blockchain-Business-Services auf globaler Ebene konform betreiben kann. Gleichzeitig kommen die technischen Teamtalente von starthestar Blockchain Technology Ltd von führenden internationalen wissenschaftlichen Forschungseinrichtungen und Blockchain-Technologie-Entwicklungsteams, um Benutzergelder und Kontosicherheit vollständig zu schützen. Neben dem technischen Team verfügt das Unternehmen auch über ein professionelles Blockchain-Team: Das quantitative Investmentteam für digitale Assets AI, das Bewertungsteam für Blockchain-Projektwerte, das Venture-Investmentteam für digitale Assets usw. haben ein globales Layout, um globale Blockchain-Benutzer zu bedienen. Das Ziel und die Vision des Unternehmens ist es, sichere, stabile, rücksichtsvolle und vertrauenswürdige Transaktionsdienste für globale Digital-Asset-Enthusiasten bereitzustellen und ein professionelles, digitales und internationales, weltbekanntes Blockchain-Technologieunternehmen aufzubauen.',
      item1: {
        tt: 'Warenpräsentation',
        pt1: 'OTC-Handelsregeln',
        pt2: 'Ratgeber für Anfänger',
        pt3: 'Tarif Standard',
        pt4: 'Vertragsbeschreibung'
      },
      item2: {
        tt: 'Unterstützung',
        pt1: 'KYC- und Anti-Geldwäsche-Vereinbarung',
        pt2: 'Durchsetzungsanforderungsrichtlinie',
        pt3: 'Einführung in digitale Assets'
      },
      item3: {
        tt: 'Bedingungen',
        pt1: 'Datenschutz',
        pt2: 'Nutzungsbedingungen',
        pt3: 'Risikobenachrichtigung'
      },
      item4: {
        tt: 'kontaktiere uns',
        pt1: 'Zusammenarbeit von Unternehmen',
        pt2: 'Institutionelle Zusammenarbeit',
        pt3: 'OTC-Anwendung',
        pt4: 'Kundendienst-E-Mail',
        pt5: 'Beschwerden und Vorschläge',
        pt6: 'Medienkooperation'
      }
    },
    bk3: {
      title: 'Stolz auf innovative F&E-Stärke',
      desc: 'Unvorstellbares Kontrakthandelserlebnis, entwickelt für Finanzhändler',
      pt1: 'Open-Order-Stop-Profit und Stop-Loss',
      pd1: 'Ausstehende Bestellungen bleiben nicht lange auf, machen Sie sich Sorgen, die Bestellung nicht zu beobachten',
      pt2: 'Offene Order umkehren',
      pd2: 'Der Markt ändert sich plötzlich, halten Sie in einer Sekunde Schritt',
      pt3: 'Unterstützen Sie volles Lager für Lager',
      pd3: 'Mach was du willst, mach was du willst',
      pt4: 'Vollständige schrittweise Liquidation',
      pd4: 'Die ausländische Liquidation entspricht zunächst der Hälfte und versucht, den Anlegern zu helfen, Verluste zu reduzieren'
    },
    bk5: {
      title: 'Acht Vorteile der Kontrakthandelsplattform',
      desc: 'Wir hören ständig zu, optimieren und verbessern uns kontinuierlich und schaffen ein erstklassiges Handelserlebnis für globale Handelsbenutzer',
      pt1: 'Markttiefe',
      pd1: 'Die Markttiefe der ersten drei Stände auf der Plattform hat die Größenordnung von Millionen von Dollar erreicht und übertrifft damit die durchschnittliche Markttiefe der Branche von 10.000 und 100.000 bei weitem. Der Spread ist klein und die Transaktion ist schnell.',
      pt2: 'Reibungslose Abwicklung',
      pd2:
        'Durch die Verwendung einer effizienten Matching-Transaktions-Engine zur Gewährleistung eines reibungslosen Transaktionserlebnisses kann es Millionen von Bestellungen pro Sekunde verarbeiten und einen reibungslosen und stabilen Betrieb mit mehr als 20 Millionen gleichzeitigen Online-Benutzern unterstützen.',
      pt3: 'Fairer Preis',
      pd3: '„Marked Price“ wählt den Echtzeit-Transaktionspreis der drei größten Börsen der Welt als Indexpreis für die Regressionsberechnung aus, wodurch Benutzerverluste durch abnormale Manipulation des letzten Transaktionspreises in kurzer Zeit wirksam verhindert werden.',
      pt4: 'Selbst entwickelte Technologie',
      pd4: 'Die Matchmaking-Engine, die im traditionellen Finanzwesen verwurzelt ist, wurde von einem Team hochrangiger Experten der Finanzbranche entwickelt und jedes Detail sorgfältig ausgearbeitet, um den sicheren, stabilen und effizienten Betrieb der Handelsplattform zu gewährleisten.',
      pt5: 'Top-Risikokontrolle',
      pd5: 'Urteil auf zweiter Ebene, mehrschichtige Verschlüsselung, hohe Systemsicherheit. Die Kombination aus Hot- und Cold-Wallets, militärtauglichen Verschlüsselungsmaßnahmen, Asset-Transaktionssicherheit ist garantiert und garantiert zu 100 % die Sicherheit der digitalen Assets der Benutzer.',
      pt6: 'Fondssicherheit',
      pd6:
        'Dreischichtiges Sicherheitssystem, umfassendes Sicherheitsaudit. Das branchenführende, hierarchische, deterministische Cold-Wallet-System wurde selbst entwickelt. Alle Benutzer weisen Aufladeadressen als Cold-Wallet-Adressen zu, Geldeinzug und -abhebung werden durch Offline-Signaturen abgeschlossen.',
      pt7: 'Hardcore-Power',
      pd7: 'Zehntausende BTC-Reserven, Finanzinnovationsgene und das weltweit führende Managementteam. starthestar verspricht, dass, wenn Konto- und Transaktionsdatenfehler nicht systembedingt verursacht werden, die Plattform im Fehlerfall voll bezahlt!',
      pt8: 'Qualitätsdienstleistung; Qualitätsservice',
      pd8: '7 * 24 Stunden rund um die Uhr professioneller Kundenservice, schnelle Antwort. Bieten Sie ausgehend vom Servicegedanken erstklassige Handelserfahrung für globale Kunden. Das ultimative Erlebnis ergibt sich aus dem Konzept, Kunden zu dienen.'
    },
    bk6: {
      title: 'Sicherheit und Compliance weltweiter Betrieb',
      desc: 'Übernehmen Sie die Aufsicht und halten Sie sich immer an globale Compliance-Vorgänge'
    },
    bk7: {
      title: 'Offizieller strategischer Partner'
    },
    bk8: {
      title: 'Betreute mehr als 100.000 globale Handelsbenutzer',
      desc: 'Derzeit gibt es Benutzer in den Vereinigten Staaten, Japan, Südkorea, den Seychellen, Vietnam und anderen Ländern'
    },
    bk9: {
      title: 'Reibungslose Transaktion jederzeit und überall',
      pt1: 'Echtzeit-Matchmaking-Transaktionen, Ein- und Auszahlungen erfolgen in Sekundenschnelle',
      pt2: 'Mehrere Handelsmodi, bis zu 125-fache Hebelwirkung',
      dt1: 'iOS-Version herunterladen',
      dt2: 'Android-Version herunterladen'
    },
    about: {
      title: 'Über uns',
      pt1: 'Plattformkonzept',
      pd1:
        'Lassen Sie globale Vermögenswerte frei zirkulieren und schaffen Sie ein neues Vertragserlebnis. X-xenogenetisch steht für innovative Gene und liefert einen stetigen Energiestrom für Innovationen in der Branche. M-motor vertritt das Ziel und bewegt sich weiter mit dem Ziel, das Kontrakthandelserlebnis anzuführen. EX-exchange verpflichtet sich im Namen der Handelsplattform, mit der Betriebsphilosophie ein faires, transparentes und effizientes Handelsökosystem zu schaffen des Dienens, Zuhörens und Denkens.',
      pt2: 'Grundwerte',
      pd2:
        'Wir hören immer zu, achten und denken voraus und setzen uns dafür ein, ein faires, transparentes und effizientes Handelsökosystem zu schaffen. Bieten Sie globalen Händlern ein besseres Handelserlebnis, erfüllen Sie die tatsächlichen Bedürfnisse der Händler und fördern Sie die Entwicklung des Marktes für den Handel mit digitalen Vermögenswerten.',
      pt3: 'Teamhintergrund',
      pd3: 'Das Gründungsteam besteht aus Top-Blockchain-Investoren, traditionellen Finanzmanagern, Top-Experten für digitale Assets und traditionellen Technologieteams für die Metallbearbeitung. Die Kernmitglieder des F&E-Teams stammen von Morgan Stanley, CICC, Hang Seng, Tencent und Noah Wealth.'
    }
  },
  start: {
    p_title: 'Transaktion, starten Sie hier',
    p_desc: 'Bitte geben Sie Ihre E-Mail-Adresse in den folgenden Bereich ein, um sich sofort zu registrieren',
    p_email: 'Email',
    p_btn: 'Erstellen'
  },
  download: {
    p_title: 'Laden Sie den APP-Client herunter',
    p_desc: 'Jederzeit und überall Terminaltransaktion auf mehreren Plattformen',
    p_btn: 'Herunterladen',
    p_scan_android: 'Android App',
    p_scan_ios: 'IOS-App'
  },
  downpage: {
    t1: {
      p_title: 'Lightning-Transaktionsleistung',
      p_desc: 'Bieten Sie eine Vielzahl von Transaktionstypen zur Auswahl, der Auftragsabgleich ist extrem schnell, der Flash-Austausch mit einem Klick und der Handel ist bequemer'
    },
    t2: {
      p_title: 'Einfache Übertragung von Vermögenswerten',
      p_desc: 'Einfache Übertragung von Vermögenswerten auf das Konto, Echtzeit-Übersicht über digitale Vermögenswerte, Erhöhung des Tortendiagramms des Währungsverhältnisses'
    },
    t3: {
      p_title: 'Benutzerdefiniertes Handelspanel',
      p_desc: 'Definieren Sie das Handelslayout frei nach unterschiedlichen Handelsgewohnheiten und erhöhen Sie die Eingabe von K-Linie und Tiefenkarte'
    },
    t4: {
      p_title: 'Kontointegrationsverwaltung',
      p_desc: 'Mehrere Sicherheitsüberprüfungseinstellungen, ein Konto verwaltet C2C-Konten und Währungskonten'
    }
  },
  login: {
    login: 'Anmeldung',
    title: 'Benutzer-Anmeldung',
    desc1: 'Bitte melden Sie sich auf der offiziellen',
    desc2: '- Website an',
    email: 'Email',
    email2: 'E-Mail',
    email3: 'Telefonnummer',
    email_desc: 'Bitte geben Sie Ihre E-Mail ein',
    phone_desc: 'Bitte Telefonnummer eingeben',
    password: 'Passwort',
    password_desc: 'Bitte geben Sie das Passwort ein',
    forgot_password: 'Passwort vergessen',
    no_account: 'Noch keinen Account'
  },
  signup: {
    signup: 'Registrieren',
    title: 'Benutzer Registration',
    desc1: 'Willkommen zum Anmelden',
    desc2: 'Offizielle Website',
    invitation_code: 'Einladungscode',
    invitation_desc: 'Bitte geben Sie den Einladungscode ein',
    accept: 'Akzeptieren',
    service: 'Nutzungsbedingungen',
    and: 'Und',
    policy: 'Datenschutzrichtlinie',
    have_account: 'Sie haben bereits ein Konto'
  },
  retrieve: {
    ptitle: 'Passwort abrufen',
    pemail_code: 'E-Mail-Bestätigungscode',
    pset: 'Passwort festlegen',
    set_title: 'Passwort festlegen',
    set_desc: 'Sie setzen Ihr Passwort zurück, bitte vervollständigen Sie die folgenden Einstellungen',
    new_password: 'Neues Kennwort',
    password_desc: 'Bitte geben Sie ein neues Passwort ein',
    res_passowrd: 'Bestätige das Passwort',
    res_passowrd_desc: 'Bitte geben Sie das Bestätigungspasswort ein'
  },
  valid_email: {
    title: 'Registrierungsbestätigung',
    desc: 'Sie registrieren ein Konto, bitte führen Sie die folgende Überprüfung durch',
    email_code: 'E-Mail-Bestätigungscode',
    email_desc: 'Bitte den Bestätigungscode eingeben',
    get_code: 'Bestätigungscode erhalten'
  },
  ihead: {
    begin_trade: 'Mit dem handel beginnen',
    helper: 'Anfängerhilfe',
    text: 'Dokumentenzentrum',
    new_currency: 'Neues Währungsabonnement'
  },
  uhead: {
    title1: 'Waren',
    title2: 'Internationale Devisen',
    title3: 'Internationaler Index',
    currency: 'Währung',
    lever: 'Gehebelte Transaktion',
    coins: 'Währungstransaktion',
    contract: 'Vertragsabwicklung',
    metal: 'Metall',
    energy: 'Energie',
    contract_info: 'Vertragsinformation',
    helper: 'Anfängerhilfe',
    text: 'Dokumentenzentrum',
    assets: 'Vermögenswerte',
    u_wallet: 'Mein Geldbeutel',
    u_contract: 'Vertragskonto',
    u_lever: 'Hebelkonto',
    u_account: 'Kontoinformationen',
    u_logout: 'Ausloggen'
  },
  news: {
    label: 'Nachricht',
    update: 'Aktualisiert am'
  },
  guide: {
    label: 'Ratgeber für Anfänger',
    list: [
      {
        ptitle: 'So registrieren Sie sich für starthestar',
        pkey: 'k1'
      },
      {
        ptitle: 'Identitätsauthentifizierung',
        pkey: 'k2'
      },
      {
        ptitle: 'Einstellungen für Anmeldepasswort und Transaktionspasswort',
        pkey: 'k3'
      },
      {
        ptitle: 'Münzen hinterlegen',
        pkey: 'k4'
      },
      {
        ptitle: 'Münztransaktionsprozess',
        pkey: 'k5'
      },
      {
        ptitle: 'Rückzug',
        pkey: 'k6'
      },
      {
        ptitle: 'Herunterladen der mobilen -APP',
        pkey: 'k7'
      }
    ],
    content: {
      k1: require('./currency/en/guide/k1.js').default,
      k2: require('./currency/en/guide/k2.js').default,
      k3: require('./currency/en/guide/k3.js').default,
      k4: require('./currency/en/guide/k4.js').default,
      k5: require('./currency/en/guide/k5.js').default,
      k6: require('./currency/en/guide/k6.js').default,
      k7: require('./currency/en/guide/k7.js').default
    }
  },
  kline: {
    h24_high: '24 Stunden hoch',
    h24_low: '24 Std. niedrig',
    h24_vol: '24-Stunden-Lautstärke',
    commission: 'Aktuelle Kommission',
    record: 'Transaktionen Aufzeichnung',
    commission_list: 'Kommissionsliste',
    deal_list: 'Neuestes Angebot',
    price: 'Preis',
    xprice: 'Gewinnverteilung',
    bzj: 'Rand',
    number: 'Nummer',
    total: 'Gesamt',
    time: 'Zeit',
    currency: 'Positionen',
    type: 'Typ',
    direction: 'Richtung',
    multiple: 'Hebelwirkung',
    fee: 'Kommission',
    amount: 'Gesamt',
    status: 'Status',
    operate: 'Betrieb',
    buy: 'Kaufen',
    sell: 'Verkaufen',
    pending: 'Ausstehende Bestellung',
    trade_success: 'Handel erfolgreich',
    retract: 'Stornieren',
    profit: 'Gewinn mitnehmen',
    loss: 'Stoppen Sie den Verlust',
    all: 'Alle',
    all_currency: 'Alle Währungen',
    new_price: 'Letzter Preis',
    change: 'Aufstieg und Fall',
    protocol: 'Protokoll',
    _status1: 'Rezension',
    _status2: 'Erfolgreich',
    _status3: 'Abgelehnt'
  },
  coins: {
    title: 'Währungstransaktion',
    market: 'Marktpreis',
    limit: 'Preis begrenzen',
    profit: 'Gewinn mitnehmen',
    loss: 'Stoppen Sie den Verlust',
    price: 'Preis',
    current: 'Aktuell',
    price_desc: 'Bitte geben Sie den Preis ein',
    number: 'Nummer',
    number_desc: 'Bitte geben Sie die Nummer ein',
    available: 'Verfügbar',
    half: 'halbes Lager',
    all: 'Volles Lager',
    fee: 'Kommission',
    amount: 'Transaktionshöhe',
    buy: 'Kaufen',
    sell: 'Verkaufen',
    current_price: 'Aktuell',
    open_price: 'Heute geöffnet',
    high: 'Hoch',
    low: 'Niedrig',
    yesterday: 'Gestern geschlossen',
    today_zf: 'Preisänderung',
    now_cc: 'Position',
    y_cc: 'Y-Position',
    jsj: 'Abrechnungspreis',
    zrjsj: 'Y Abrechnungspreis'
  },
  lever: {
    title: 'Gehebelte Transaktion',
    multiple: 'Hebelwirkung',
    multiple_desc: 'Bitte wählen Sie ein Vielfaches aus',
    double: 'doppelt',
    buy: 'Kaufen (hoch)',
    sell: 'Verkaufen (runter)'
  },
  contract: {
    title: 'Vertragsabwicklung',
    time: 'Zeit',
    time_desc: 'Bitte wählen Sie eine Zeit aus',
    minute: 'Minute',
    second: 'Zweite',
    buy: 'Kaufen (hoch)',
    sell: 'Verkaufen (runter)',
    rate: 'Lizenzverhältnis'
  },
  wallet: {
    title: 'Mein Vermögen',
    valuation: 'Gesamtvermögensbewertung',
    my_wallet: 'Mein Geldbeutel',
    my_trade: 'Handelskonto',
    my_transfer: 'Aufzeichnung der Auszahlungen',
    my_deposit: 'Münzeinzahlungsdatensatz',
    cbdz: 'Einzahlungsadresse',
    cbsl: 'Menge',
    deposit: 'Münzen einzahlen',
    withdrawal: 'Rückzug',
    search: 'Suchen',
    currency: 'Positionen',
    protocol: 'Protokoll',
    liquidation: 'Position schließen',
    liquidation_title: 'Schnelle Liquidation',
    trade_pwd: 'Trading-Passwort',
    enter_trade_pwd: 'Handelspasswort eingeben',
    submit_liquidation: 'Schließen Sie die Position sofort',
    amount: 'Gesamt',
    available: 'Verfügbar',
    freeze: 'Einfrieren',
    market: 'Bewertung',
    ljsy: 'Kumuliertes Einkommen',
    zrsy: 'Verdienst von gestern',
    operate: 'Betrieb',
    countdown: 'Countdown',
    lever_title: 'Hebelkonto',
    contract_title: 'Vertragskonto',
    direction: 'Richtung',
    bond: 'Rand',
    buy_rate: 'mehrere',
    profit: 'Gewinn-und Verlust',
    profit1: 'Gewinn-und Verlust',
    buy: 'Kaufen',
    sell: 'Verkaufen',
    many: 'Kaufen',
    empty: 'Verkaufen',
    contract_time: 'Vertragszeit',
    premium: 'Privileg',
    minute: 'Minute',
    address: 'Adresse',
    address_desc: 'Bitte geben Sie die Adresse ein',
    number: 'Nummer',
    number_desc: 'Bitte geben Sie die Anzahl der Auszahlungen ein',
    copy: 'Kopieren',
    confirm: 'OK',
    all: 'Alle',
    fee: 'Kommission',
    actual: 'Tatsächliche Ankunftszeit',
    premark: 'Aufmerksamkeit',
    text_1: 'Diese Adresse akzeptiert nur Guthaben von {name}, bitte laden Sie keine anderen Währungen auf, sonst werden die Guthaben nicht abgerufen',
    text_2: 'Die Miner-Gebühr von {name} beträgt: aktueller Marktwert {Rate} %/Transaktion, Mindeststandard: {min}{name}/Transaktion',
    text_3: 'Bitte bestätigen Sie, dass die Informationen zur Empfangsadresse korrekt sind, bevor Sie das Geld überweisen. Sobald die Vermögenswerte übertragen wurden, können sie nicht mehr abgezogen werden.',
    text_4: '{name} Die Mining-Gebühr beträgt: {val} {name}/pen'
  },
  record: {
    title: 'Fondsaufzeichnung',
    type: 'Typ',
    all_currency: 'Alle Währungen',
    all_type: 'Alle Arten',
    select: 'Anfrage',
    clear: 'Filter löschen',
    receive: 'Kaution',
    transfer: 'Rückzug',
    buy: 'Kaufen',
    sell: 'Verkaufen',
    normal: 'Normal',
    pending: 'Stop-Limit',
    wallet: 'Positionsangaben',
    zzzqy: 'Kontostand',
    zqy: 'Freie Marge',
    fdyk: 'Floating P/L',
    fxl: 'Risikorate'
  },
  user: {
    account: 'Kontoauthentifizierung',
    account_desc: 'Krediteinzahlung, Transaktion und andere Funktionen müssen zuerst die Kontoverifizierung bestehen',
    password: 'Passwort',
    password_desc: 'Wird zum Schutz der Kontosicherheit verwendet',
    modify: 'Ändern',
    set: 'Satz',
    find_paypwd: 'Passwort abrufen',
    find_pay_pwd: 'Zahlungskennwort abrufen',
    email: 'Email',
    enter_email: 'Bitte geben Sie ihre E-Mail-Adresse ein',
    email_code: 'Bestätigungs-Code',
    enter_email_code: 'Bitte geben Sie den E-Mail-Bestätigungscode ein',
    send: 'Schicken',
    pay_password: 'Zahlungskennwort',
    pay_password_desc: 'Wird verwendet, um die Sicherheit von Vermögenswerten beim Handel zu schützen',
    not_certified: 'Nicht zertifiziert',
    certification: 'Zertifizierung läuft',
    verified: 'Verifiziert',
    rejected: 'abgelehnt',
    update_pwd: 'Login-Passwort ändern',
    update_pay_pwd: 'Zahlungskennwort ändern',
    set_pay_pwd: 'Zahlungskennwort festlegen',
    old_pwd: 'Ursprüngliches Passwort',
    enter_old_pwd: 'Bitte geben Sie das ursprüngliche Passwort ein',
    new_pwd: 'Neues Kennwort',
    enter_new_pwd: 'Bitte geben Sie ein neues Passwort ein',
    res_pwd: 'Bestätige das Passwort',
    enter_res_pwd: 'Bitte geben Sie das Bestätigungspasswort ein',
    submit: 'Einreichen'
  },
  certified: {
    title: 'Kontoauthentifizierung',
    front: 'Ausweis vorne',
    back: 'Die Rückseite des Personalausweises',
    hand: 'Personalausweis in der Hand halten',
    name: 'Name',
    name_desc: 'Bitte geben Sie einen Namen ein',
    card: 'ID-Nummer',
    card_desc: 'Bitte ID-Nummer eingeben',
    submit: 'Einreichen'
  },
  trade: {
    title: 'Transaktions Passwort',
    enter_password: 'Bitte geben Sie das Transaktionspasswort ein',
    cancel: 'stornieren',
    submit: 'einreichen'
  },
  revoke: {
    title: 'Sofortige Nachricht',
    message: 'Möchten Sie die Bestellung wirklich zurückziehen?',
    cancel: 'stornieren',
    submit: 'bestätigen'
  },
  coins_assets: {
    title: 'Währungsvermögen',
    total_equity: 'Gesamtkapital',
    equity: 'Kontokapital',
    number: 'Anzahl der Betriebe',
    market_value: 'Aktuelle Bewertung'
  },
  lever_assets: {
    title: 'Gehebelte Vermögenswerte',
    total_equity: 'Gesamtkapital',
    equity: 'Kontokapital',
    profit: 'Nicht realisierter Gewinn und Verlust',
    risk: 'Risiko'
  },
  contract_assets: {
    title: 'Vertragsvermögenswerte',
    total_equity: 'Gesamtkapital',
    equity: 'Kontokapital',
    profit: 'Nicht realisierter Gewinn und Verlust'
  },
  contract_menu: {
    contract: 'Vertragsdetails',
    lever: 'Hebeldetails',
    coins: 'Münzindex',
    history: 'Historie der Fondssätze',
    coins_rules: [
      {
        value: '1D',
        name: 'An dem Tag'
      },
      {
        value: '1W',
        name: 'Diese Woche'
      },
      {
        value: '1M',
        name: 'Aktueller Monat'
      },
      {
        value: '1J',
        name: 'Aktuelle Saison'
      }
    ],
    lever_info: 'Nutzen Sie Informationen',
    contract_info: 'Vertragsinformation'
  },
  contract_info: {
    bzj: 'Kaution',
    pt1: 'Abrechnungswährung',
    pt2: 'Vertragsnennwert',
    pt3: 'Mindeständerungspreis',
    pt4: 'Vertragslaufzeit',
    pt5: 'Fondssatzzeit',
    pt6: 'Markierter Preis',
    pt7: 'Positionsvolumen',
    pt8: '24-Stunden-Handelsvolumen',
    pt9: 'Privileg',
    pt10: 'Erträge aus Fälligkeitslieferungen',
    pt11: 'Offener Positions-Spread',
    pt12: 'Liefer- und Abrechnungsmechanismus',
    pt13: 'Transaktionsservicegebühr',
    pt14: 'Sperrposition',
    pv5: 'Täglich 08:00 16:00 24:00',
    pv10_1: 'Long-Position: (durchschnittlicher Schlusskurs – durchschnittlicher Eröffnungskurs) x Menge',
    pv10_2: 'Short-Position: (durchschnittlicher Eröffnungskurs – durchschnittlicher Schlusskurs) x Menge',
    pv11: 'NEIN',
    pv12: 'Automatische Lieferung bei Fälligkeit',
    pv13: 'NEIN',
    pv14: 'nicht unterstützt'
  },
  metal_info: {
    pt1: 'Sorten tauschen',
    pt2: 'Abrechnungswährung',
    pt3: 'Angebotseinheit',
    pt4: 'Vertragsgröße',
    pt5: 'Mindestwechseleinheit',
    pt51: 'Minimale Handelseinheit',
    pt52: 'Maximale Handelseinheit',
    pt6: 'Vertragszeit',
    pt7: 'Handelszeit',
    pt8: 'Kommission',
    pt9: 'Gelistete Börse',
    pt10: 'Zielverbreitung',
    pv4: 'nachhaltig',
    pv5: '24-Stunden-Handel von Montag bis Freitag',
    pv51: '24 Stunden Handel',
    pv6: 'Vertragswert0,08 %',
    pv7: 'Londoner Börse (LME)',
    pv71: 'New Yorker Börse (NYMEX)',
    pv10: '{name} Ziel-Spread beträgt {num} Pips'
  },
  lever_info: {
    title: 'Hebeldetails',
    bei: 'mal',
    pt1: 'Abrechnungswährung',
    pt2: 'Vertragsnennwert',
    pt3: 'Mindeständerungspreis',
    pt4: 'Vertragslaufzeit',
    pt5: 'Fondssatzzeit',
    pt6: 'Markierter Preis',
    pt7: 'Positionsvolumen',
    pt8: '24-Stunden-Handelsvolumen',
    pt9: 'Risikoreserve',
    pt10: 'Liquidationsgewinn',
    pt11: 'Offener Positions-Spread',
    pt12: 'Automatischer Liquidationsmechanismus',
    pt13: 'Transaktionsservicegebühr',
    pt14: 'Sperrposition',
    pv4: 'Nachhaltig',
    pv5: 'Täglich 08:00 16:00 24:00',
    pv10_1: 'Long-Position: (durchschnittlicher Schlusskurs – durchschnittlicher Eröffnungskurs) x Menge',
    pv10_2: 'Short-Position: (durchschnittlicher Eröffnungskurs – durchschnittlicher Schlusskurs) x Menge',
    pv12: 'Die Reserverisikoquote erreicht 80 %',
    pv13: 'Vertragswert',
    pv14: 'Nicht unterstützt'
  },
  //币币信息
  coins_desc: require('./currency/en/coins.js').default,

  fun_history: {
    t1: 'Datum',
    t2: 'Geschätzte Finanzierungsrate',
    t3: 'Tatsächliche Finanzierungsrate'
  },
  docs: {
    list: [
      {
        name: 'Regeln für den OTC-Handel',
        key: 'otc'
      },
      {
        name: 'KYC- und Anti-Geldwäsche-Vereinbarungen',
        key: 'kyc'
      },
      {
        name: 'Richtlinie für Anfragen von Strafverfolgungsbehörden',
        key: 'Gesetz'
      },
      {
        name: 'Datenschutz',
        key: 'Privatsphäre'
      },
      {
        name: 'Nutzungsbedingungen',
        key: 'Protokoll'
      },
      {
        name: 'Risikohinweis',
        key: 'Risiko'
      }
    ],
    otc: require('./currency/en/otc').default,
    kyc: require('./currency/en/kyc.js').default,
    law: require('./currency/en/law.js').default,
    privacy: require('./currency/en/privacy.js').default,
    protocol: require('./currency/en/protocol').default,
    risk: require('./currency/en/risk').default
  },
  asset: {
    title: 'Einführung digitaler Assets',
    list: 'Liste der digitalen Assets'
  },
  num_asset: require('./currency/en/asset').default,

  new_currency: {
    _title: 'Neues Währungsabonnement',
    _all: 'Alle',
    _progress: 'Im Gange',
    _end: 'Über',
    _success: 'Austausch erfolgreich',
    _progress_str: 'Fortschritt',
    _total_str: 'Insgesamt freigegeben',
    _date_time: 'Start- und Enddatum',
    _submit: 'Jetzt einlösen',
    _base_title: 'Grundinformation',
    _status: 'Status',
    _currency: 'Akzeptierte Währung',
    _symbol: 'Währungszeichen',
    _amount: 'Totales Verbot aufgehoben',
    _price: 'Anfangspreis',
    _limit: 'Persönliches Einkaufslimit',
    _limit_amount: 'Persönliches Einkaufslimit',
    _limit_one: 'Persönliche Einzellimit-Untergrenze',
    _no_limit: 'Keine Begrenzung',
    _position: 'Position',
    _begin_time: 'Startzeit',
    _end_time: 'Endzeit',
    _redeemed: 'Eingelöster Betrag',
    _surplus: 'Überschuss',
    _wallet: 'Geldbörse',
    _have: 'Derzeit gehalten',
    _input: 'Eingabemenge',
    _choose: 'Bitte auswählen',
    _new_wallet: 'Neues Währungskonto',
    _new_price: 'Letzter Preis',
    _lock: 'Sperrfrist',
    _login: 'Bitte loggen Sie sich ein',
    _no_data: 'Keine Daten',

    _avg_price: 'Durchschnittspreis'
  }
}
