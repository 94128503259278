import { render, staticRenderFns } from "./ContractRecord.vue?vue&type=template&id=e97b2240&scoped=true&"
import script from "./ContractRecord.vue?vue&type=script&lang=js&"
export * from "./ContractRecord.vue?vue&type=script&lang=js&"
import style0 from "../../../static/css/coins.css?vue&type=style&index=0&id=e97b2240&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e97b2240",
  null
  
)

export default component.exports