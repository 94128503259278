export default `<p>
    <strong>BCH (비트 코인 캐시 노드)</strong>
</p>
<p>
    <br/>
</p>
<p>
    1. 프로젝트 이름
</p>
<p>
    비트 코인 캐시 노드 (BCH)
</p>
<p>
    에
</p>
<p>
    2. 프로젝트 소개
</p>
<p>
    BCH (Bitcoin Cash)는 2020 년 11 월 15 일에 하드 포크 업그레이드가 진행됩니다. BCHN 컴퓨팅 파워의 현재 비율이 절대적인 이점을 가지고 있기 때문에 커뮤니티 및 사용자 피드백을 기반으로 Huobi Global은 다음과 같은 조치를 취할 것입니다.
</p>
<p>
    1. BCHN 노드 체계는 BCH의 원래 이름, K- 라인 및 거래 쌍을 상속합니다. 포크 중 시장 심리에 따라 큰 변동이있을 수 있으니 사전에 대응 조치 및 리스크 관리를 부탁드립니다. 합리적인 판단과 신중한 의사 결정
</p>
<p>
    2. BCH 포크 후, Huobi Global은 모든 사용자의 BCH 자산의 스냅 샷을 찍고 포크 토큰을 에어 드랍합니다. 포크 노드가 안정적으로 실행되면 커뮤니티 피드백을 기반으로 포크 통화 거래 쌍을 열지 여부를 평가합니다.
</p>
<p>
    에
</p>
<p>
    세, 통화 개요 및 배포
</p>
<p>
    총 토큰 : 21,000,000 BCH
</p>
<p>
    <br/>
</p>`