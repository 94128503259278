export default `<h2 style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:48px">
<strong><span style="font-family: Times New Roman;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 16px;background: rgb(255, 255, 255)">DASH (Dash)</span></strong>
</h2>
<p style=";text-indent: 0;padding: 0;line-height: 48px;background: rgb(255, 255, 255)">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">1. What is Dash? </span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Dash, the English name</span> DASH, is an open source peer-to-peer (P2P) encrypted currency that can provide instant transaction, anonymous transaction and alternative currency functions. </span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >The Dash network is protected by a proof-of-work mining (consensus mechanism used by Bitcoin) mechanism, and it uses the X11 algorithm based on </span>11 different hash functions. Its master node is a proof-of-service layer. These nodes act as transaction mixers and boosters at the same time. In addition, it also serves as the voting mechanism of the Dash management system. </span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >The block reward of Dash will be divided into two layers (miners and master nodes). Among them, the miners get </span> 45% of the return, the master node receives 45%, and the remaining 10% is allocated to the decentralized Dash budget system. The generation time of each block is 2.5 minutes, one block Give 3.6 Dash rewards to miners. The details are as follows:</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">2. Background</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Considering the anonymity of Bitcoin and the speed of transaction processing, Evan Duffield and his team started the research and development of DASH, and formally established Dash in January 2014. So far, Dash has completed related improvements on the basis of Bitcoin, and has improved the anonymity of transactions by obfuscating transactions by the decentralized network server "master node". At the same time, based on the node, the transaction processing speed is improved, and thus a decentralized and well-anonymized Dash was born. </span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">3. Features</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >High anonymity: In Bitcoin transactions, all transaction data will be recorded in the data block chain, and the whereabouts of the Bitcoin can be seen, so that the receiving and sending parties can be queried. However, Dash uses an anonymous sending technology to obfuscate transactions with a decentralized web server</span> "master node", so that transactions cannot be tracked and queried, thereby achieving high anonymity. </span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Instant send: Bitcoin network needs to spend </span>10 minutes or even several hours to confirm the transaction, and Dash can send it instantly. </span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Independent management system: Through this system, Dash Network can allocate resources for R&D and marketing through proposals and voting, and any user can create and submit proposals on the network. </span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">4. Frequently used links</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<a href="https://www.dash.org/"><span style="font-family: Microsoft Yahei;color: rgb(53, 124, 225);letter-spacing: 0;font-size : 14px">DASH official website</span></a>
<span style="font-family: Microsoft Yahei;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px" ><span style="font-family: Microsoft Yahei">:</span>https://www.dash.org/</span>
<span style="font-family: Microsoft Yahei;color: rgb (53, 124, 225);letter-spacing: 0;font-size: 14px"><br/></span>
<a href="https://explorer.dash.org/chain/Dash">
<span style="font-family: Microsoft Yahei;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">
<span style="font-family: Microsoft Yahei"> Block Explorer</span></span></a>
<span style="font-family: Microsoft Yahei; color: rgb(53, 124, 225); letter-spacing: 0; font-size: 14px">
<span style="font-family:Microsoft Yahei">:</span>https://explorer.dash.org/chain/Dash</span>
<span style="font-family: Microsoft Yahei Black; color: rgb(53, 124, 225); letter-spacing: 0;font-size: 14px"><br/>
</span><a href="https://www.dash.org/cn /get-dash/"><span style="font-family: Microsoft Yahei;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14 px">
<span style="font-family:Microsoft Yahei">Wallet download</span></span></a><span style="font-family: Microsoft Yahei;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">
<span style="font-family:Microsoft Yahei">:</span></span><span style="font-family: Microsoft Yahei;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">https://www.dash.org/</span>
<span style="font-family : Microsoft Yahei; color: rgb(53, 124, 225); letter-spacing: 0;font-size: 14px"><br/></span><a href="https://www.dash. org/forum/">
<span style="font-family: Microsoft Yahei;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">DASH Forum</span></a>
<span style="font-family: Microsoft Yahei;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">:</span>https://www.dash.org/forum/</span><span style="font-family: Microsoft Yahei;color: rgb(53, 124, 225);letter -spacing: 0;font-size: 14px"><br/></span><a href="https://twitter.com/Dashpay/with_replies"><span style="font-family: Microsoft Yahei ;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">Twiter</span></a><span style= "font-family: Microsoft Yahei;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei">:</ span>https://twitter.com/Dashpay/with_replies</span><span style="font-family: Microsoft Yahei; color: rgb(53, 124, 225); letter-spacing: 0;font-size : 14px"><br/></span><a href="https://www.facebook.com/DashPay/"><span style="font-family: Microsoft Yahei; color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">Facebook</span></a><span style="font-family: Microsoft Yahei; color: rgb(53, 124, 225) ;letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">:</span>https://www.facebook.com/DashPay/</span>
</p>
<p>
<span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
<p>
<br/>
</p>`