export default {
    pt1: "1. 사용자가 STARTHESTAR에 로그인 한 후\"거래 시작 \"을 클릭하여 플랫폼 거래 인터페이스로 들어갑니다.",
    pimg1: "k5",
    pt2: "2.\"통화 거래 \"를 클릭하여 플랫폼에 충전 된 통화 (BTC, ETH, TRX)를 USDT로 변환합니다. 예를 들어 사용자가 BTC를 충전하는 경우 BTC 시장을 클릭하고\"매도 \"를 클릭하고 교환이 필요한 경우 \"매도\"를 클릭합니다",
    pimg2: "k5",
    pt3: "3. 팝업 인터페이스에 거래 비밀번호를 입력하고\"제출 \"을 클릭하여 환전을 완료합니다.",
    pimg3: "k5",
    pt4: "STARTHESTAR는 다음을 상기시킵니다 :",
    pt5: "① 사용자가 플랫폼에서 거래 할 때 플랫폼에서\"마진 거래 \"와\"계약 거래 \"를 수행하기 위해서는 플랫폼에서 USDT가 아닌 통화를 재충전하고\"통화 거래 \"를 통해 USDT로 교환해야합니다.",
    pt6: "② 사용자는\"폴바 거래 \"와\"계약 거래 \"를 통해 STARTHESTAR 플랫폼에서 수익을 얻은 후\"코인 거래 \"를 통해 해당 통화 (BTC, ETH, TRX)를 구매하여 통화를 인출 할 수 있습니다.",
}