<template>
  <div class="route-box">
    <div class="theme--light">
      <div class="views-components-indexHeader tabs has-bg-sub-level-1 theme--light">
        <div class="container content-width">
          <ul>
            <li
              @click="
                () => {
                  this.$router.push('/wallet')
                }
              "
            >
              <span class="mr-5 has-text-level-4 not-border">{{ $t('wallet.title') }}</span>
            </li>
            <li>
              <span class="mr-5 has-text-primary has-line-primary">{{ $t('record.title') }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="theme--light page-myAssets-assetRecord">
        <div class="has-bg-level-1 is-align-items-center py-7">
          <div class="has-bg-level-2 content-width">
            <div class="tabs mb-0">
              <ul>
                <li :class="option == Option.wallet ? 'is-active' : ''" @click="onOption(Option.wallet)">
                  <a class="pa-0 mx-4">{{ $t('wallet.my_wallet') }}</a>
                </li>
                <li :class="option == Option.trade ? 'is-active' : ''" @click="onOption(Option.trade)">
                  <a class="pa-0 mx-4">{{ $t('wallet.my_trade') }}</a>
                </li>
                <li :class="option == Option.deposit ? 'is-active' : ''" @click="onOption(Option.deposit)">
                  <a class="pa-0 mx-4">{{ $t('wallet.my_deposit') }}</a>
                </li>
                <li :class="option == Option.transfer ? 'is-active' : ''" @click="onOption(Option.transfer)">
                  <a class="pa-0 mx-4">{{ $t('wallet.my_transfer') }}</a>
                </li>
              </ul>
            </div>
            <div class="tabs mb-0" v-if="option == Option.trade">
              <ul>
                <li :class="option2 == Option.new_currency ? 'is-active' : ''" @click="onOption2(Option.new_currency)">
                  <a class="pa-0 mx-4">{{ $t('new_currency._title') }}</a>
                </li>
                <li :class="option2 == Option.lever ? 'is-active' : ''" @click="onOption2(Option.lever)">
                  <a class="pa-0 mx-4">{{ $t('wallet.lever_title') }}</a>
                </li>
                <!-- <li :class="(option2 == Option.contract?'is-active':'')" @click="onOption2(Option.contract)"><a class="pa-0 mx-4">{{$t('wallet.contract_title')}}</a></li> -->
              </ul>
            </div>
            <!-- <div class="tabs mb-0" style="display: none;">
                        <ul style="display: flex;">
                            <li class=""><a class="pa-0 mx-4">跟单账户</a></li>
                        </ul>
                    </div> -->
            <div class="table-bg px-5 pb-5">
              <div class="assetSelectBox mt-7" v-if="option != Option.transfer && option != Option.deposit && option2 !== Option.new_currency">
                <div class="columns is-variable is-6">
                  <div class="column is-3">{{ $t('wallet.currency') }}</div>
                  <div class="column is-3">{{ $t('record.type') }}</div>
                  <div class="column"></div>
                </div>
                <div class="columns is-variable is-6">
                  <div class="column is-3">
                    <div :class="' my-dropdown dropdown ' + (currency_option ? 'is-active' : '')">
                      <div class="dropdown-trigger has-text-1">
                        <button class="button " style="" @click="onCurrencyOption">
                          {{ (currency && currency.name) || $t('record.all_currency') }}
                          <i class="my-trigger-icon iconfont icon-xiala has-text-level-3"></i>
                        </button>
                      </div>
                      <div class="dropdown-menu" style="">
                        <div class="dropdown-content" style="">
                          <a :class="'dropdown-item ' + (currency ? '' : 'has-active')" @click="setCurrencyOption()">
                            <div class="my-menu-label">{{ $t('record.all_currency') }}</div>
                          </a>
                          <a :class="'dropdown-item ' + (currency && currency.id == item.id ? 'has-active' : '')" v-for="(item, index) in currencyList" :key="index" @click="setCurrencyOption(item)">
                            <div class="my-menu-label">{{ item.name }}</div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="column is-3">
                    <div :class="' my-dropdown dropdown ' + (type_option ? 'is-active' : '')">
                      <div class="dropdown-trigger has-text-1">
                        <button class="button " style="" @click="onTypeOption">{{ (type && type.name) || $t('record.all_type') }}<i class="my-trigger-icon iconfont icon-xiala has-text-level-3"></i></button>
                      </div>
                      <div class="dropdown-menu" style="">
                        <div class="dropdown-content" style="">
                          <a :class="'dropdown-item ' + (type ? '' : 'has-active')" @click="setTypeOption()">
                            <div class="my-menu-label">{{ $t('record.all_type') }}</div>
                          </a>
                          <a :class="'dropdown-item ' + (type && type.id == item.id ? 'has-active' : '')" v-for="(item, index) in typeList" :key="index" @click="setTypeOption(item)">
                            <div class="my-menu-label">{{ item.name }}</div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="column">
                    <button class="button is-primary font-size-2 has-text-white modal-default-btn button-large mr-4 btn-width" @click="onSelect">
                      {{ $t('record.select') }}
                    </button>
                    <button class="button is-primary is-outlined font-size-2 button-large has-text-primary btn-width" @click="onClear">
                      {{ $t('record.clear') }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="mt-7">
                <!-- <div class="my-modal modal theme--light  ">
                                <div class="modal-background"></div>
                                <div class="modal-card border-radius-medium" style="">
                                    <header class="modal-card-head has-bg-level-2 has-line-level-1 pa-7">
                                        <div class="modal-card-title title-medium">
                                            <p class="modal-card-title-log pb-3">Xmex</p>
                                            <div>撤销申请C2C划转</div>
                                        </div>
                                        <div class="icomBox has-bg-level-1 cursor-pointer "><i class="iconfont icon-TurnOff"></i></div>
                                    </header>
                                    <section class="modal-card-body has-bg-level-2 pa-7">
                                        <div class="columns">
                                            <div class="column">审核金额</div>
                                            <div class="column has-text-right">undefined undefined</div>
                                        </div>
                                    </section>
                                    <footer class="modal-card-foot has-bg-level-2 pa-7">
                                        <button class="button is-primary is-outlined font-size-2 has-text-white modal-default-btn button-large has-text-primary">
                                            取消
                                        </button>
                                        <div class="spacer"></div>
                                        <button class="button is-primary font-size-2 has-text-white modal-default-btn button-large ">确定
                                        </button>
                                    </footer>
                                </div>
                            </div> -->
                <!-- 我的钱包 -->
                <div class="assetTable" v-if="option == Option.wallet">
                  <div class="columns body-4 has-text-level-4">
                    <div class="column is-1">{{ $t('kline.currency') }}</div>
                    <div class="column is-1">{{ $t('kline.type') }}</div>
                    <div class="column isw-2">{{ $t('kline.number') }}</div>
                    <div class="column is-2">{{ $t('kline.price') }}(USD)</div>
                    <div class="column is-2">{{ $t('kline.fee') }}(USD)</div>
                    <div class="column is-2">USD {{ $t('wallet.market') }}</div>
                    <div class="column is-2 has-text-right">{{ $t('kline.time') }}</div>
                    <!-- <div class="column is-2 has-text-right">备注</div> -->
                  </div>
                  <div class="columns body-4 has-text-level-1 my-7" v-for="(item, index) in coinsList" :key="index">
                    <div class="column is-1">
                      {{ item.name }} <span v-if="item.type == 1">({{ item.cp_name }})</span>
                    </div>
                    <div class="column is-1">{{ getRecordType(item.type) }}</div>
                    <div class="column isw-2">{{ item.number }}</div>
                    <div class="column is-2">{{ item.price }}</div>
                    <div class="column is-2">{{ item.fee }}</div>
                    <div class="column is-2">{{ item.usdt }}</div>
                    <div class="column is-2 has-text-right">{{ item.create_time }}</div>
                    <!-- <div class="column is-2 has-text-right">─ ─</div> -->
                  </div>
                </div>
                <!-- 新币 -->
                <div class="assetTable" v-if="option == Option.trade && option2 == Option.new_currency">
                  <div class="columns body-4 has-text-level-4">
                    <div class="column is-1">{{ $t('kline.currency') }}</div>
                    <div class="column isw-2">{{ $t('kline.price') }}(USD)</div>
                    <div class="column isw-2">{{ $t('wallet.number') }}</div>
                    <div class="column isw-2">USDT</div>
                    <div class="column isw-2 has-text-right">{{ $t('kline.time') }}</div>
                    <!-- <div class="column is-2 has-text-right">备注</div> -->
                  </div>
                  <div class="columns body-4 has-text-level-1 my-7" v-for="(item, index) in NewCurrenyList" :key="index">
                    <div class="column is-1">{{ item.name }}</div>
                    <div class="column isw-2">{{ item.price }}</div>
                    <div class="column isw-2">{{ item.amount }}</div>
                    <div class="column isw-2">{{ item.use_number }}</div>
                    <div class="column isw-2 has-text-right">{{ item.create_time }}</div>
                    <!-- <div class="column is-2 has-text-right">─ ─</div> -->
                  </div>
                </div>
                <!-- 杠杆 -->
                <div class="assetTable" v-if="option == Option.trade && option2 == Option.lever">
                  <div class="columns body-4 has-text-level-4">
                    <div class="column is-1">{{ $t('kline.currency') }}</div>
                    <div class="column is-1">{{ $t('kline.type') }}</div>
                    <div class="column is-1">{{ $t('kline.direction') }}</div>
                    <div class="column isw-2">{{ $t('kline.number') }}</div>
                    <div class="column isw-2">{{ $t('kline.price') }}(USD)</div>
                    <div class="column is-1">{{ $t('kline.multiple') }}</div>
                    <div class="column issw-2">{{ $t('wallet.bond') }}</div>
                    <div class="column issw-2">{{ $t('wallet.fee') }}</div>
                    <div class="column is-1">{{ $t('wallet.profit1') }}</div>
                    <div class="column isw-2 has-text-right">{{ $t('kline.time') }}</div>
                    <!-- <div class="column is-2 has-text-right">备注</div> -->
                  </div>
                  <div class="columns body-4 has-text-level-1 my-7" v-for="(item, index) in leverList" :key="index">
                    <div class="column is-1">{{ item.name }}</div>
                    <div class="column is-1">{{ item.type == 1 ? $t('record.buy') : $t('record.sell') }}</div>
                    <div class="column is-1">{{ item.direction == 1 ? $t('wallet.many') : $t('wallet.empty') }}</div>
                    <div class="column isw-2">{{ item.number }}</div>
                    <div class="column isw-2">{{ item.price }}</div>
                    <div class="column is-1">{{ item.buy_rate }}</div>
                    <div class="column issw-2">{{ item.bond }}</div>
                    <div class="column issw-2">{{ item.fee }}</div>
                    <div class="column is-1">{{ item.profit }}</div>
                    <div class="column isw-2 has-text-right">{{ item.create_time }}</div>
                    <!-- <div class="column is-2 has-text-right">─ ─</div> -->
                  </div>
                </div>
                <!-- 合约 -->
                <div class="assetTable" v-if="option == Option.trade && option2 == Option.contract">
                  <div class="columns body-4 has-text-level-4">
                    <div class="column is-1">{{ $t('kline.currency') }}</div>
                    <div class="column is-1">{{ $t('kline.type') }}</div>
                    <div class="column is-1">{{ $t('kline.direction') }}</div>
                    <div class="column isw-2">{{ $t('kline.number') }}</div>
                    <div class="column isw-2">{{ $t('kline.price') }}(USD)</div>
                    <div class="column is-1">{{ $t('kline.multiple') }}</div>
                    <div class="column isw-2">{{ $t('wallet.premium') }}</div>
                    <div class="column isw-2">{{ $t('wallet.profit1') }}</div>
                    <div class="column is-2 has-text-right">{{ $t('kline.time') }}</div>
                    <!-- <div class="column is-2 has-text-right">备注</div> -->
                  </div>
                  <div class="columns body-4 has-text-level-1 my-7" v-for="(item, index) in contractList" :key="index">
                    <div class="column is-1">{{ item.name }}</div>
                    <div class="column is-1">{{ item.type == 1 ? $t('record.buy') : $t('record.sell') }}</div>
                    <div class="column is-1">{{ item.direction == 1 ? $t('wallet.many') : $t('wallet.empty') }}</div>
                    <div class="column isw-2">{{ item.number }}</div>
                    <div class="column isw-2">{{ item.price }}</div>
                    <div class="column is-1">{{ item.buy_rate }}</div>
                    <div class="column isw-2">{{ item.bond }}</div>
                    <div class="column isw-2">{{ item.profit }}</div>
                    <div class="column is-2 has-text-right">{{ item.create_time }}</div>
                    <!-- <div class="column is-2 has-text-right">─ ─</div> -->
                  </div>
                </div>
                 <!-- 存币记录 -->
                 <div class="assetTable" v-if="option == Option.deposit">
                  <div class="columns body-4 has-text-level-4">
                    <div class="column is-1">{{ $t('kline.currency') }}</div>
                    <div class="column is-1">{{ $t('kline.protocol') }}</div>
                    <div class="column isw-2">{{ $t('wallet.cbsl') }}</div>
                    <div class="column isw-2">{{ $t('kline.price') }}(USD)</div>
                    <div class="column isw-2">USD{{ $t('wallet.market') }}</div>
                    <div class="column isw-20">{{ $t('wallet.cbdz') }}</div>
                    <div class="column is-2 has-text-right">{{ $t('kline.time') }}</div>
                    <!-- <div class="column is-2 has-text-right">备注</div> -->
                  </div>
                  <div class="columns body-4 has-text-level-1 my-7" v-for="(item, index) in depositList" :key="index">
                    <div class="column is-1">{{ item.name }}</div>
                    <div class="column is-1">{{ item.cp_name }}</div>
                    <div class="column isw-2">{{ item.real_number }}</div>
                    <div class="column isw-2">{{ item.price }}</div>
                    <div class="column isw-2">{{ item.market_value }}</div>
                    <div class="column isw-20">{{ item.address }}</div>
                    <div class="column is-2 has-text-right">{{ item.create_time }}</div>
                    <!-- <div class="column is-2 has-text-right">─ ─</div> -->
                  </div>
                </div>
                <!-- 提现记录 -->
                <div class="assetTable" v-if="option == Option.transfer">
                  <div class="columns body-4 has-text-level-4">
                    <div class="column is-1">{{ $t('kline.currency') }}</div>
                    <div class="column is-1">{{ $t('kline.protocol') }}</div>
                    <div class="column isw-2">{{ $t('wallet.cbsl') }}</div>
                    <div class="column isw-2">{{ $t('kline.price') }}(USD)</div>
                    <div class="column isw-2">{{ $t('kline.fee') }}(USD)</div>
                    <div class="column isw-2">USD{{ $t('wallet.market') }}</div>
                    <div class="column isw-2 has-text-right">{{ $t('kline.status') }}</div>
                    <div class="column is-2 has-text-right">{{ $t('kline.time') }}</div>
                    <!-- <div class="column is-2 has-text-right">备注</div> -->
                  </div>
                  <div class="columns body-4 has-text-level-1 my-7" v-for="(item, index) in transferList" :key="index">
                    <div class="column is-1">{{ item.name }}</div>
                    <div class="column is-1">{{ item.cp_name }}</div>
                    <div class="column isw-2">{{ item.real_number }}</div>
                    <div class="column isw-2">{{ item.price }}</div>
                    <div class="column isw-2">{{ item.fee }}</div>
                    <div class="column isw-2">{{ item.market_value }}</div>
                    <div class="column isw-2 has-text-right">{{ item.status == 1 ? $t('kline._status1') : item.status == 2 ? $t('kline._status2') : $t('kline._status3') }}</div>
                    <div class="column is-2 has-text-right">{{ item.create_time }}</div>
                    <!-- <div class="column is-2 has-text-right">─ ─</div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCoinsRecord, getLeverRecord, getNewCurrenyRecord, getContractRecord, getTransferRecord,getDepositRecord } from '@/api/user'
import { getCurrencyList } from '@/api/currency'
const Option = {
  wallet: 1,
  trade: 2,
  lever: 3,
  contract: 4,
  transfer: 5,
  new_currency: 6,
  deposit: 7
}
export default {
  name: 'Records',
  data() {
    return {
      Option,
      // 按钮
      option: Option.wallet,
      option2: Option.lever,

      // 我的钱包
      coinsList: [],

      //新币记录
      NewCurrenyList: [],

      // 杠杆
      leverList: [],

      // 合约
      contractList: [],

      // 提现记录
      transferList: [],
      depositList:[],

      //筛选
      currencyList: [],
      currency_option: false,
      currency: null,

      //类型筛选
      typeList1: [],
      typeList2: [],
      typeList: [],
      type_option: false,
      type: null
    }
  },
  created() {
    this.getCurrencyList()
    this.getCoinsRecord()
    this.getLeverRecord()
    this.getNewCurrenyRecord()
    // this.getContractRecord()
    this.getTransferRecord()
    this.getDepositRecord()

    //初始化
    this.init()
  },
  methods: {
    getRecordType(type) {
      if (type == 1) {
        return this.$t('record.receive')
      } else if (type == 2) {
        return this.$t('record.transfer')
      } else if (type == 3) {
        return this.$t('record.buy')
      } else if (type == 4) {
        return this.$t('record.sell')
      }
    },
    listenLang(lang) {
      lang
      this.init()
    },
    init() {
      this.typeList1 = [
        {
          id: 1,
          name: this.$t('wallet.deposit')
        },
        {
          id: 2,
          name: this.$t('wallet.withdrawal')
        },
        {
          id: 3,
          name: this.$t('record.buy')
        },
        {
          id: 4,
          name: this.$t('record.sell')
        }
      ]

      this.typeList2 = [
        {
          id: 1,
          name: this.$t('record.buy')
        },
        {
          id: 2,
          name: this.$t('record.sell')
        }
      ]

      this.typeList = this.typeList1
    },
    onClear() {
      this.type = null
      this.currency = null
    },
    onSelect() {
      if (this.option == Option.wallet) {
        this.getCoinsRecord()
      } else if (this.option2 == Option.lever) {
        this.getLeverRecord()
      } else if (this.option2 == Option.contract) {
        this.getContractRecord()
      }
    },
    /**
     * 设置选项id
     */
    setTypeOption(item) {
      this.type = item
      this.onTypeOption()
    },
    /**
     * 币种筛选
     */
    onTypeOption() {
      this.type_option = !this.type_option
    },
    /**
     * 设置选项id
     */
    setCurrencyOption(item) {
      this.currency = item
      this.onCurrencyOption()
    },
    /**
     * 币种筛选
     */
    onCurrencyOption() {
      this.currency_option = !this.currency_option
    },
    /**
     * 选项
     */
    onOption(index) {
      this.option = index
      if (this.option == Option.wallet) {
        this.typeList = this.typeList1
      } else if (this.option == Option.trade) {
        this.typeList = this.typeList2
      }

      this.onClear()
    },
    /**
     * 二级选项
     */
    onOption2(index) {
      this.option2 = index
    },
    /**
     * 币列表
     */
    getCurrencyList() {
      getCurrencyList().then(res => {
        this.currencyList = res.data
      })
    },
    getCoinsRecord() {
      // loading
      this.$ui.openLoading()
      let c_id = (this.currency && this.currency.id) || 0
      let type = (this.type && this.type.id) || 0
      getCoinsRecord({
        c_id: c_id,
        type: type
      })
        .then(res => {
          this.$ui.closeLoading()
          this.coinsList = res.data
        })
        .catch(() => {
          this.$ui.closeLoading()
        })
    },
    getNewCurrenyRecord() {
      // loading
      this.$ui.openLoading()
      getNewCurrenyRecord()
        .then(res => {
          this.$ui.closeLoading()
          this.NewCurrenyList = res.data
        })
        .catch(() => {
          this.$ui.closeLoading()
        })
    },
    getLeverRecord() {
      // loading
      this.$ui.openLoading()
      let c_id = (this.currency && this.currency.id) || 0
      let type = (this.type && this.type.id) || 0
      getLeverRecord({
        c_id: c_id,
        type: type
      })
        .then(res => {
          this.$ui.closeLoading()
          this.leverList = res.data
        })
        .catch(() => {
          this.$ui.closeLoading()
        })
    },
    getContractRecord() {
      // loading
      this.$ui.openLoading()
      let c_id = (this.currency && this.currency.id) || 0
      let type = (this.type && this.type.id) || 0
      getContractRecord({
        c_id: c_id,
        type: type
      })
        .then(res => {
          this.$ui.closeLoading()
          this.contractList = res.data
        })
        .catch(() => {
          this.$ui.closeLoading()
        })
    },
    getDepositRecord() {
      // loading
      this.$ui.openLoading()
      let c_id = (this.currency && this.currency.id) || 0
      let type = (this.type && this.type.id) || 0
      getDepositRecord({
        c_id: c_id,
        type: type
      })
        .then(res => {
          this.$ui.closeLoading()
          this.depositList = res.data
        })
        .catch(() => {
          this.$ui.closeLoading()
        })
    },
    getTransferRecord() {
      // loading
      this.$ui.openLoading()
      let c_id = (this.currency && this.currency.id) || 0
      let type = (this.type && this.type.id) || 0
      getTransferRecord({
        c_id: c_id,
        type: type
      })
        .then(res => {
          this.$ui.closeLoading()
          this.transferList = res.data
        })
        .catch(() => {
          this.$ui.closeLoading()
        })
    }
  }
}
</script>

<style src="../../static/css/records.css" scoped type="text/css"></style>
