export default `<p>
<strong>ADA (Cardano)</strong>
</p>
<p>
<br/>
</p>
<p>
<strong>1. Project name</strong>
</p>
<p>
Cardano (ADA)
</p>
<p>
<br/>
</p>
<p>
<strong>2. Project introduction</strong>
</p>
<p>
Cardano is a decentralized and open blockchain, also a cryptocurrency project, and it is completely open source. Cardano is developing a smart contract platform that aims to provide more advanced features than any previously developed protocol. It is the first blockchain platform to evolve from the philosophy of science and research-driven orientation. The development team is composed of global professional engineers and researchers.
</p>
<p>
<strong>3. Currency overview and distribution</strong>
</p>
<p>
Maximum supply: 45,000,000,000 ADA
</p>
<p>
Total supply: 31,112,483,745 ADA
</p>
<p>
Circulation supply: 25,927,070,538 ADA
</p>
<p>
Crowdfunding price: $0.0026
</p>
<p>
Token distribution:
</p>
<p>
Among them, ADA coins sold 25,927,070,538 ADA coins when the Cardano agreement was released. Another 5,185,414,108 ADA coins, equivalent to 20% of the number of ADA sold, are generated and distributed to the three entities that make up the Cardano technology and business ecosystem: IOHK, Emurgo and the Cardano Foundation. Therefore, the total number of ADA at the time of release was 31,112,484,646.
</p>
<p>
<br/>
</p>
<p>
The remaining 13,887,515,354 ADA coins of the ADA coins issued during the operation of the Cardano protocol will be issued during the minting process.
</p>
<p>
<br/>
</p>
<p>
Note: The number of ADA will never exceed 45,000,000,000
</p>
<p>
To
</p>
<p>
<strong>4, commonly used links</strong>
</p>
<p>
Project website: cardanofoundation.org, iohk.io, emurgo.io
</p>
<p>
White paper: whycardano.com
</p>
<p>
<br/>
</p>
<p>
other:
</p>
<p>
<br/>
</p>
<p>
<br/>
</p>
<p>
cardano.org
</p>
<p>
cardanodocs.com
</p>
<p>
cardanoexplorer.com
</p>
<p>
cardanoroadmap.com
</p>
<p>
https://github.com/input-output-hk/cardano-sl
</p>
<p>
https://www.reddit.com/r/cardano/
</p>
<p>
https://daedaluswallet.io/
</p>
<p>
<br/>
</p>
<p>
<strong>5. The zone where the currency is located</strong>
</p>
<p>
Main zone
</p>
<p>
<br/>
</p>`