export default `<p>
    <strong>ETH (이더 리움)</strong>
</p>
<p>
    <br/>
</p>
<p>
    <strong>1. 이더 리움이란?</strong>
</p>
<p>
    <br/>
</p>
<p>
    이더 리움은 사용자 통화 발행, 스마트 프로토콜, 분산 트랜잭션, 분산 자율 조직 (DAO) 또는 분산 자율 회사 (DAC) 설립 등 많은 고급 기능을 지원할 수있는 차세대 암호화 원장입니다. Ethereum은 모든 단일 유형의 기능을 기능으로 특별히 지원하지는 않습니다. 반대로 Ethereum에는 &quot;contracts&quot;코드라는 메커니즘을 통해 달성하려는 기능을 작성할 수있는 기본 제공 Turing-complete 스크립팅 언어가 포함되어 있습니다. 계약은 자동 에이전트와 비슷합니다. 트랜잭션이 수신 될 때마다 계약은 특정 코드를 실행하여 계약 내의 데이터 저장소를 수정하거나 트랜잭션을 보낼 수 있습니다. 고급 계약은 자체 코드를 수정할 수도 있습니다.
</p>
<p>
    <br/>
</p>
<p>
    평신도의 관점에서 Ethereum은 오픈 소스 플랫폼 디지털 통화 및 블록 체인 플랫폼으로 개발자에게 블록 체인에서 애플리케이션을 구축하고 게시 할 수있는 플랫폼을 제공합니다. 이더 리움은 무엇이든 프로그래밍, 탈 중앙화, 보증 및 거래, 투표, 도메인 이름, 금융 거래, 크라우드 펀딩, 회사 관리, 계약 및 대부분의 계약, 지적 재산권 및 하드웨어 통합의 혜택을받는 스마트 자산에 사용할 수 있습니다.
</p>
<p>
    <br/>
</p>
<p>
    2017 년 2 월 28 일 석유 및 가스 산업, 금융 산업 및 소프트웨어 개발 회사를 대표하는 글로벌 기업 그룹은 Ethereum을 엔터프라이즈 수준의 블록 체인으로 개발하는 데 전념하는 EEA (Enterprise Ethereum Alliance)를 공식적으로 시작했습니다. 이러한 회사에는 영국의 거대 석유 회사 인 BP, JPMorgan Chase, 소프트웨어 개발자 Microsoft, Accenture, Santander Bank, BlockApps, BNY Mellon, CME Group, ConsenSys, Intel Microsoft 및 Nuco가 포함됩니다. 많은 회사 또는 조직이 이더 리움 블록 체인을 기반으로 프로젝트를 개발하고 토큰을 발행합니다. 유엔 세계 식량 프로그램은 이더 리움 블록 체인을 성공적으로 사용하여 10,000 명의 난민을 구호했습니다.
</p>
<p>
    <br/>
</p>
<p>
    <strong>2. 세부 매개 변수</strong>
</p>
<p>
    <br/>
</p>
<p>
    중국어 이름 : Ethereum 영어 이름 : Ethereum 영어 약어 : ETH
</p>
<p>
    개발자 : Vitalik Buterin Core Algorithm : Ethash 출시 날짜 : 2015/3/20
</p>
<p>
    블록 시간 : 약 15-17 초 / 블록 블록 보상 : 5 총 통화 : 7200 만 + 1,872 만 / 년
</p>
<p>
    주요 기능 : 디지털 통화 및 스마트 계약과 같은 기능 포함
</p>
<p>
    <br/>
</p>
<p>
    <strong>3. 자주 사용하는 링크</strong>
</p>
<p>
    <br/>
</p>
<p>
    공식 웹 사이트 : https://www.ethereum.org/
</p>
<p>
    블록 쿼리 : https://etherscan.io/
</p>
<p>
    <br/>
</p>`