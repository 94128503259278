export default {
    title:"用户协议",
    content:`<p style="text-align: center;">
    <span style="font-size: 18px;"><strong>用户协议</strong></span>
</p>
<p>
    Starthestar
</p>
<p>
    <br/>
</p>
<p>
    Starthestar是一家面向未来的数字货币和数字资产流通交易平台，运营网站https://starthestar.com/（以下称“本网站”或“网站”），该网站是一个专门供用户进行数字资产交易和提供相关服务（以下称“该服务”或“服务”）的平台，除非Starthestar另行声明，网站使用及发布的产品、技术、软件、程序、数据及其他信息的所有权利均归Starthestar Limited（以下简称公司）所有。为了本协议表述之方便，公司和该网站在本协议中合称使用“我们”或其他第一人称称呼。
</p>
<p>
    <br/>
</p>
<p>
    重要提示 ：
</p>
<p>
    我们在此特别提醒您：
</p>
<p>
    <br/>
</p>
<p>
    1 数字资产本身不由任何金融机构或政府背书。数字资产市场是全新的、并没有明确、稳定的预期 ；
</p>
<p>
    <br/>
</p>
<p>
    2 数字资产交易存在极高风险，其全天不间断交易，没有涨跌限制，价格容易大幅波动；
</p>
<p>
    <br/>
</p>
<p>
    3 因各国法律、法规和规范性文件的制定或者修改，数字资产交易随时可能被暂停或被禁止。。
</p>
<p>
    <br/>
</p>
<p>
    数字资产交易有极高风险，并不适合绝大部分人士。您了解和理解此投资有可能导致部分损失或全部损失，所以您应该以能承受的损失程度来决定投资的金额。请确认您了解和理解数字资产会产生衍生风险。此外，除了上述提及过的风险以外，还会有未能预测的风险存在。在作出任何买卖数字资产的决定之前，您应慎重考虑并用清晰的判断能力去评估自己的财政状况及上述各项风险，并承担由此产生的全部损失，我们对此不承担任何责任。。
</p>
<p>
    <br/>
</p>
<p>
    一、总则
</p>
<p>
    1.1您在使用本网站提供的各项服务之前，应仔细阅读本协议，如有不理解之处或其他必要，请咨询专业律师。如您不同意本协议及/或随时对其的修改，请您立即停止使用本网站提供的服务或不再登陆本网站。您一旦登陆本网站、使用本网站的任何服务或任何其他类似行为即表示您已了解并完全同意本协议各项内容，包括本网站对本协议随时所做的任何修改。。
</p>
<p>
    <br/>
</p>
<p>
    1.2 您通过按照本网站的要求填写相关信息，并经过其他相关程序后成功注册可以成为本网站的会员（以下称“会员”），在进行注册过程中点击“同意”按钮即表示您以电子签署的形式与公司达成协议；或您在使用本网站过程中点击任何标有“同意”或类似意思的按钮的行为或以其他本网站允许的方式实际使用本网站提供的服务时，均表示您完全了解、同意且接受本协议项下的全部条款的约束，无您手写的书面签字就本协议对您的法律约束力无任何影响。
</p>
<p>
    <br/>
</p>
<p>
    1.3 您成为本网站的会员后，您将获得一个会员帐号及相应的密码，该会员帐号和密码由会员负责保管；会员应当对以其您帐号进行的所有活动和事件负法律责任。。
</p>
<p>
    <br/>
</p>
<p>
    1.2 您通过按照本网站的要求填写相关信息，并经过其他相关程序后成功注册可以成为本网站的会员（以下称“会员”），在进行注册过程中点击“同意”按钮即表示您以电子签署的形式与公司达成协议；或您在使用本网站过程中点击任何标有“同意”或类似意思的按钮的行为或以其他本网站允许的方式实际使用本网站提供的服务时，均表示您完全了解、同意且接受本协议项下的全部条款的约束，无您手写的书面签字就本协议对您的法律约束力无任何影响。：
</p>
<p>
    <br/>
</p>
<p>
    1.4.1 接受本协议所有条款及条件的约束。。
</p>
<p>
    <br/>
</p>
<p>
    1.4.2 您确认您已到达根据不同的可适用的法律规定的具有可订立合同的法定年龄，并有充分的能力接受这些条款。。
</p>
<p>
    <br/>
</p>
<p>
    1.4.3 您保证交易中涉及到的属于您的数字资产均为合法取得并所有。。
</p>
<p>
    <br/>
</p>
<p>
    1.4.4 您同意您为您自身的交易或非交易行为承担全部责任和任何收益或亏损。。
</p>
<p>
    <br/>
</p>
<p>
    1.4.5 您确认注册时提供的信息是真实和准确的。。
</p>
<p>
    <br/>
</p>
<p>
    1.4.6 您同意遵守任何有关法律的规定，就税务目的而言，包括报告任何交易利润。。
</p>
<p>
    <br/>
</p>
<p>
    1.4.7 本协议只是就您与我们之间达成的权利义务关系进行约束，而并不涉及本网站用户之间与其他网站和您之间因数字资产交易而产生的法律关系及法律纠纷。。
</p>
<p>
    <br/>
</p>
<p>
    二、协议修订
</p>
<p>
    我们保留不时修订本协议、并以网站公示的方式进行公告、不再单独通知您的权利，变更后的协议会在本协议首页标注变更时间，一经在网站公布，立即自动生效。您应不时浏览及关注本协议的更新变更时间及更新内容，如您不同意相关变更，应当立即停止使用本网站服务；您继续使用本网站服务，即表示您接受并同意经修订的协议的约束。。
</p>
<p>
    <br/>
</p>
<p>
    三、注册
</p>
<p>
    3.1 注册资格
</p>
<p>
    您确认并承诺：在您完成注册程序或以其他本网站允许的方式实际使用本网站提供的服务时，您应当是具备可适用的法律规定的可签署本协议及使用本网站服务应具有的能力的自然人、法人或其他组织。您一旦点击同意注册按钮，即表示您自身或您的有权代理人已经同意该协议内容并由其代理注册及使用本网站服务。若您不具备前述主体资格，则您及您的有权代理人应承担因此而导致的一切后果，且公司保留注销或永久冻结您账户，并向您及您的有权代理人追究责任的权利。。
</p>
<p>
    <br/>
</p>
<p>
    3.2 注册目的
</p>
<p>
    您确认并承诺：您进行本网站注册并非出于违反法律法规或破坏本网站数字资产交易秩序的目的。。
</p>
<p>
    <br/>
</p>
<p>
    3.3 注册流程
</p>
<p>
    3.3.1 您同意根据本网站用户注册页面的要求提供有效电子邮箱、手机号码等信息，您可以使用您提供或确认的邮箱、手机号码或者本网站允许的其它方式作为登陆手段进入本网站。如有必要，按照不同法域相关法律法规的规定，您必须提供您的真实姓名、身份证件等法律法规及隐私条款及反洗钱条款规定的相关信息并不断更新注册资料，符合及时、详尽、准确的要求。所有原始键入的资料将引用为注册资料。您应对该等信息的真实性、完整性和准确性负责，并承担因此产生的任何直接或间接损失及不利后果。。
</p>
<p>
    <br/>
</p>
<p>
    3.3.2 如您所在主权国家或地区的法律法规、规则、命令等规范对手机号码有实名要求，您同意提供注册的手机号码是经过实名登记的，如您不按照规定提供，因此给您带来的任何直接或间接损失及不利后果均应由您承担。。
</p>
<p>
    <br/>
</p>
<p>
    3.3.3 您合法、完整并有效提供注册所需信息并经验证，有权获得本网站账号和密码，您获得本网站账号及密码时视为注册成功，可在本网站进行会员登陆。。
</p>
<p>
    <br/>
</p>
<p>
    3.3.4 您同意接收本网站发送的与本网站管理、运营相关的电子邮件和/或短消息。。
</p>
<p>
    <br/>
</p>
<p>
    四、服务
</p>
<p>
    本网站只为您通过本网站进行数字资产交易活动（包括但不限于数字资产交易等服务）提供网络交易平台服务，本网站并不作为买家或卖家参与买卖数字资产行为本身。。
</p>
<p>
    <br/>
</p>
<p>
    4.1 服务内容
</p>
<p>
    4.1.1 您有权在本网站浏览数字资产各项产品的实时行情及交易信息、有权通过本网站提交数字资产交易指令并完成数字资产交易。。
</p>
<p>
    <br/>
</p>
<p>
    4.1.2 您有权按照本网站发布的活动规则参与本网站组织的网站活动。。
</p>
<p>
    <br/>
</p>
<p>
    4.1.3 本网站承诺为您提供的其他服务。。
</p>
<p>
    <br/>
</p>
<p>
    4.2.服务规则
</p>
<p>
    您承诺遵守下列本网站服务规则：
</p>
<p>
    <br/>
</p>
<p>
    4.2.1 您应当遵守法律法规、规章、及政策要求的规定，保证账户中所有数字资产来源的合法性，不得在本网站或利用本网站服务从事非法或其他损害本网站或第三方权益的活动，如发送或接收任何违法、违规、侵犯他人权益的信息，发送或接收传销材料或存在其他危害的信息或言论，未经本网站授权使用或伪造本网站电子邮件题头信息等。
</p>
<p>
    <br/>
</p>
<p>
    4.2.2 您应当遵守法律法规并妥善使用和保管其本网站账号及登陆密码、资金密码、和其注册时绑定的手机号码、以及手机接收的手机验证码的安全。您对使用其本网站账号和登陆密码、资金密码、手机验证码进行的任何操作和后果承担全部责任。当您发现本网站账号、登陆密码、或资金密码、验证码被未经其授权的第三方使用，或存在其他账号安全问题时，应立即有效通知本网站，要求本网站暂停本网站账号的服务。本网站有权在合理时间内对您的该等请求采取行动，但本网站对在采取行动前已经产生的后果（包括但不限于您的任何损失）不承担任何责任。您在未经本网站同意的情况下不得将本网站账号以赠与、借用、租用、转让或其他方式处分给他人。。
</p>
<p>
    <br/>
</p>
<p>
    4.2.3 您同意您对您的本网站的账号、密码下发生的所有活动（包括但不限于信息披露、发布信息、网上点击同意或提交各类规则协议、网上续签协议或购买服务等）承担责任。。
</p>
<p>
    <br/>
</p>
<p>
    4.2.4 您在本网站进行数字资产交易时不得恶意干扰数字资产交易的正常进行、破坏交易秩序；不得以任何技术手段或其他方式干扰本网站的正常运行或干扰其他用户对本网站服务的使用；不得以虚构事实等方式恶意诋毁本网站的商誉。。
</p>
<p>
    <br/>
</p>
<p>
    4.2.5 如您因网上交易与其他用户产生纠纷的，不得通过司法或行政以外的途径要求本网站提供相关资料。。
</p>
<p>
    <br/>
</p>
<p>
    4.2.6 您在使用本网站提供的服务过程中，所产生的应纳税赋，以及一切硬件、软件、服务及其它方面的费用，均由您独自承担。。
</p>
<p>
    <br/>
</p>
<p>
    4.2.7 您应当遵守本网站不时发布和更新的本协议以及其他服务条款和操作规则，有权随时终止使用本网站提供的服务。。
</p>
<p>
    <br/>
</p>
<p>
    4.3.产品规则
</p>
<p>
    4.3.1 币币交易产品规则
</p>
<p>
    <br/>
</p>
<p>
    您承诺在其进入本网站交易，通过本网站与其他用户进行币币交易的过程中良好遵守如下交易规则。
</p>
<p>
    <br/>
</p>
<p>
    4.3.1.1 浏览交易信息
</p>
<p>
    您在本网站浏览币币交易信息时，应当仔细阅读交易信息中包含的全部内容，包括但不限于价格、委托量、手续费、买入或卖出方向， 您完全接受交易信息中包含的全部内容后方可点击按钮进行交易。。
</p>
<p>
    <br/>
</p>
<p>
    4.3.1.2 提交委托
</p>
<p>
    在浏览完交易信息确认无误之后您可以提交交易委托。您提交交易委托后，即您授权本网站代理您进行相应的交易撮合，本网站在有满足您委托价格的交易时将会自动完成撮合交易而无需提前通知您。。
</p>
<p>
    <br/>
</p>
<p>
    4.3.1.3 查看交易明细
</p>
<p>
    您可以通过管理中心的交易明细中查看相应的成交记录，确认自己的详情交易记录。
</p>
<p>
    <br/>
</p>
<p>
    4.3.1.4 撤销/修改委托，在委托未达成交易之前，您有权随时撤销或修改委托。
</p>
<p>
    <br/>
</p>
<p>
    五、本网站的权利和义务
</p>
<p>
    5.1 如您不具备本协议约定的注册资格，则本网站有权拒绝您进行注册，对已注册的，本网站有权注销您的会员账号，本网站保留向您或您的有权代理人追究责任的权利。同时，本网站保留其他任何情况下决定是否接受您注册的权利。。
</p>
<p>
    <br/>
</p>
<p>
    5.2 本网站发现账户使用者并非账户初始注册人时，有权中止或终止该账户的使用。。
</p>
<p>
    <br/>
</p>
<p>
    5.3 本网站通过技术检测、人工抽检等检测方式合理怀疑您提供的信息错误、不实、失效或不完整时，有权通知您更正、更新信息或中止、终止为其提供本网站服务。。
</p>
<p>
    <br/>
</p>
<p>
    5.4 本网站有权在发现本网站上显示的任何信息存在明显错误时，对信息予以更正。。
</p>
<p>
    <br/>
</p>
<p>
    5.5 本网站有权在发现任何错误交易时，对执行错误交易的执行方或/与接收方（受益一方）进行核实，如果确认为错误交易的，该错误交易的接收方（受益一方）需配合本网站返还其已接收的交易内容；如该接收方（受益一方）在接到本网站或工作人员通知后，不予以配合的，本网站有权采取相关措施进行追回，包括但不限于根据本协议约定提起仲裁或采取司法强制措施（保全查封等）；如该接收方（受益一方）在接到本网站或工作人员通知后，不仅拒绝返还，还恶意转移、变卖该错误交易内容的，本网站除采取前述措施外，还有权要求该接受方（受益一方）按照错误交易内容原确定币种、数量进行返还，并要求该接受方（受益一方）将已经转移、变卖所获增值部分一同作为不当得利的其他孳息进行返还。
</p>
<p>
    <br/>
</p>
<p>
    5.6 本网站保留随时修改、中止或终止本网站服务的权利，本网站行使修改或中止服务的权利不需事先告知您；本网站终止本网站一项或多项服务的，终止自本网站在网站上发布终止公告之日生效。。
</p>
<p>
    <br/>
</p>
<p>
    5.7 本网站应当采取必要的技术手段和管理措施保障本网站的正常运行，并提供必要、可靠的交易环境和交易服务，维护数字资产交易秩序。。
</p>
<p>
    <br/>
</p>
<p>
    5.8 本网站通过加强技术投入、提升安全防范等措施保障您的数字资产的安全，有义务在您账户出现可以预见的安全风险时提前通知您。。
</p>
<p>
    <br/>
</p>
<p>
    5.9 本网站有权根据您所属主权国家或地区的法律法规、规则、命令等规范的要求，向您要求提供更多的信息或资料等，并采取合理的措施，以符合当地的规范之要求，您有义务配合；本网站有权根据您所属主权国家或地区的法律法规、规则、命令等规范的要求，暂停或永久停止对您的开放本网站及其部分或全部服务。。
</p>
<p>
    <br/>
</p>
<p>
    六、赔偿
</p>
<p>
    6.1 在任何情况下，我们对您的直接损害的赔偿责任均不会超过您从使用本网站服务产生的为期三（ 3）个月的总费用。
</p>
<p>
    <br/>
</p>
<p>
    6.2 如您发生违反本协议或其他法律法规等情况
</p>
<p>
    <br/>
</p>`,
}