export default {
    pt1:"1.用户打开STARTHESTAR官方网址：https://starthestar.com，在首页右上角点击“下载”图标，用对应系统手机扫描对应二维码下载。",
    pimg1:"k7",
    pt2:"或者用户打开STARTHESTAR官方手机网页版地址：https://wap.starthestar.com，手机网页可以直接点击下载。以IOS系统为例：在下方出现的“下载IOS应用”中点击“下载”。",
    pimg2:"k7",
    //pt3:"在跳转的网页界面中，第一步获取TestFlight，点击“在App Store中查看”或者直接打开手机中App Store搜索TestFlight，并下载安装到手机上。",
    //pimg3:"k7",
	//pimg4:"k7",
    //pt4:"安装好TestFlight后，回到网页端进行第二步，加入此Bata版本，点击“开始测试”",
	//pimg5:"k7",
    //pt5:"在出现的界面先点击“接受”测试完成后，最后点击“下载”。即完成了IOS版苹果手机的下载安装流程。",
    pt6:"STARTHESTAR温馨提示您：Android版手机下载，浏览器打开后点击下载完成安装即可，部分Android手机在安装时需要系统授权，增加信任APP许可，点击确认即可。",
}