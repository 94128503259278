export default {
    pt1: "1. After the user logs in to STARTHESTAR, click \"Start Trading\" to enter the platform trading interface;",
    pimg1: "k5",
    pt2: "2. Click \"Currency Transaction\" to convert the currency (BTC, ETH, TRX) recharged to the platform into USDT. For example, if the user recharges BTC, click the BTC market, click \"Sell\", and enter the required exchange And then click \"Sell\"",
    pimg2: "k5",
    pt3: "3. Enter the transaction password in the pop-up interface, and click \"Submit\" to complete the currency exchange.",
    pimg3: "k5",
    pt4: "STARTHESTAR reminds you:",
    pt5: "①When users are trading on the platform, they must recharge the non-USDT currency on the platform and exchange them for USDT through \"currency trading\" in order to conduct \"margin trading\" and \"contract trading\" on the platform.",
    pt6: "②Users can buy the corresponding currencies (BTC, ETH, TRX) through the \"coin transaction\" after gaining profits on the STARTHESTAR platform through \"Pole-bar transaction\" and \"Contract transaction\" to withdraw the currency.",
}