export default `<p>
    <strong>BAND（バンドプロトコル）</strong>
</p>
<p>
    <br/>
</p>
<p>
    1.プロジェクト名
</p>
<p>
    BAND（バンドプロトコル）
</p>
<p>
    <br/>
</p>
<p>
    2.プロジェクトのポジショニング
</p>
<p>
    Band Protocolの目標は、分散型の世界で最も安全でスケーラブルなデータベースを作成し、分散型アプリケーション（dApp）に信頼できるデータを提供することです。 BandProtocolを使用する開発者とdApp企業は、ブロックチェーンから分離された一元化されたオラクルに基づくのではなく、パブリックスマートコントラクトデータポイントを介してデータ消費を行います。 このようにして、dAppはインターネット上の既存のデータを使用しながらセキュリティを確保し、Web2.0とWeb3.0のユースケース間のブリッジを構築します。
</p>
<p>
    <br/>
</p>
<p>
    3、通貨の概要と流通
</p>
<p>
    トークンの合計：100,000,000
</p>
<p>
    総循環：20,494,033
</p>
<p>
    <br/>
</p>
<p>
    4つの一般的に使用されるリンク
</p>
<p>
    プロジェクトのウェブサイト：https：//bandprotocol.com/
</p>
<p>
    ホワイトペーパー：https：//docs.bandchain.org/whitepaper/
</p>
<p>
    ブロッククエリ：https：//cosmoscan.io/
</p>
<p>
    <br/>
</p>`