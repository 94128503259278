export default {
    title:"위험 알림",
    content:`<p style="text-align: center;">
    <span style="font-size: 18px;"><strong>&quot;Shouaa 글로벌 위험 고지&quot;</strong></span>
</p>
<p>
    친애하는 Shouaa 글로벌 플랫폼 등록 사용자:
</p>
<p>
    <br/>
</p>
<p>
    암호화된 디지털 자산 거래와 관련된 위험을 더 잘 이해할 수 있도록 Starthestar 플랫폼은 이제 다음을 엄숙하게 상기시킵니다. Starthestar 플랫폼에서 암호화된 디지털 자산을 거래하기 전에 암호화된 디지털 자산의 거래 위험을 이해하고 신중하게 평가해야 합니다. 디지털 자산 귀하의 위험 허용 범위는 충분히 고려한 후 Starthestar 플랫폼에서 암호화된 디지털 자산을 거래하기로 결정합니다.
</p>
<p>
    <br/>
</p>
<p>
    Starthestar 플랫폼에서 암호화된 디지털 자산 거래의 위험에 대한 팁
</p>
<p>
    <br/>
</p>
<p>
    1. 장외 거래, 통화 거래, 레버리지 거래, 계약 거래, PoS 마이닝 풀, Starthestar 플랫폼의 상장 투표 및 기타 온라인 콘텐츠는 Starthestar 플랫폼의 등록 요구 사항을 충족하는 자격을 갖춘 사용자에게만 제공됩니다. &quot;&quot; 플랫폼 사용자&quot;)
</p>
<p>
    <br/>
</p>
<p>
    2. 플랫폼 이용자는 등록된 계정에 대해 실명인증을 하고 개인정보를 보완·개선해야 합니다.
</p>
<p>
    <br/>
</p>
<p>
    3. 플랫폼 사용자는 자신의 계정과 비밀번호를 잘 관리해야 하며, 다른 사람에게 자신의 계정 관리를 위임하거나 다른 사람에게 자신의 계정과 비밀번호를 알려주지 않아야 합니다. 그렇지 않으면 플랫폼 사용자 자신이 위험을 감수해야 합니다.
</p>
<p>
    <br/>
</p>
<p>
    4. 플랫폼 사용자는 Starthestar 플랫폼에서 암호화된 디지털 자산을 거래하는 과정에서 정책 위험, 규정 준수 위험, 수익 위험, 거래 위험 및 불가항력 위험 측면에서 위험에 직면할 수 있습니다. 구체적인 위험은 다음과 같습니다.
</p>
<p>
    <br/>
</p>
<p>
    정책적 위험: 국가(본 고시 국가는 미국, 캐나다, 싱가포르 공화국 및 플랫폼 사용자가 위치한 국가를 포함)의 거시적 정책 및 관련 법률, 규정 및 정책이 변경되는 경우, 암호화된 디지털 자산에 영향을 미칠 수 있으며 정상적인 거래는 플랫폼 사용자에게 손실로 이어집니다.
</p>
<p>
    <br/>
</p>
<p>
    b.준수 위험: 암호화된 디지털 자산을 거래하는 동안 국내 법률 및 규정을 위반하여 발생하는 위험은 플랫폼 사용자가 스스로 부담해야 합니다.
</p>
<p>
    <br/>
</p>
<p>
    c. 소득 위험: 암호화된 디지털 자산 거래 산업의 특수성으로 인해 암호화된 디지털 자산의 평가 절하가 크게 변동하며 플랫폼 사용자는 부정적인 거래 소득 및 금융 소득의 위험을 감수해야 합니다.
</p>
<p>
    <br/>
</p>
<p>
    d. 거래 위험: 플랫폼 사용자 간의 성공적인 전송 거래는 쌍방의 전송 행위에 대한 상호 인식을 기반으로 하며 Starthestar 플랫폼은 성공적인 거래를 약속하거나 보장하지 않습니다.
</p>
<p>
    <br/>
</p>
<p>
    e. 불가항력적 위험: 천재지변, 전쟁, 파업, 해커 공격 등과 같이 예측할 수 없고 피할 수 없고 극복할 수 없는 객관적인 상황이 발생하여 Starthestar 플랫폼의 정상적인 운영에 심각한 영향을 미치고 이로 인해 손실이 발생하는 것을 말합니다. 플랫폼 이용자 또는 암호화된 디지털 자산의 파기, 도난 등 Starthestar 플랫폼은 불가항력으로 인한 손실에 대해 어떠한 민사적 책임도 지지 않습니다.
</p>
<p>
    <br/>
</p>
<p>
    f.암호화된 디지털 자산의 오프라인 위험: 암호화된 디지털 자산의 개발자가 파산, 청산, 해산 등이 있는 경우 또는 국가 법률 및 규정 위반으로 인해 Starthestar이 이를 오프라인으로 전환하거나 암호화된 디지털 자산의 개발자가 거래 중단을 위해 오프라인으로 요청하는 데 앞장서십시오.의 위험.
</p>
<p>
    <br/>
</p>
<p>
    g.기술적 위험: 암호화된 디지털 자산의 일상적인 거래에서 기술 시스템의 장애 또는 오류는 거래의 정상적인 운영에 영향을 미치거나 플랫폼 사용자의 이익에 영향을 미칠 수 있습니다.
</p>
<p>
    <br/>
</p>
<p>
    h. 운영상의 위험: 운영상의 오류 또는 운영절차의 위반으로 인해 플랫폼 사용자에 의해 발생하는 위험.
</p>
<p>
    <br/>
</p>
<p>
    i. 계정 동결 위험: 플랫폼 사용자의 채무 불이행 또는 의심되는 불법 또는 범죄 행위로 인해 사법적으로 동결되거나 집행될 위험.
</p>
<p>
    <br/>
</p>
<p>
    Shouaa 글로벌 플랫폼에서의 암호화폐 거래 금지 행위에 대한 공지
</p>
<p>
    <br/>
</p>
<p>
    1. 자금세탁, 허위거래, 불법자금조달, 사기 등을 금지하며, 타인의 명의를 등록에 도용하여서는 아니되며, Shouaa에 대한 KYC 인증 시 고의로 정보를 은폐하거나 기만해서는 안 됩니다. 글로벌 플랫폼. Starthestar 플랫폼은 위에서 언급한 의심스러운 행동을 한 플랫폼 사용자에 대해 계정 동결, 계정 사용 종료, 암호화된 디지털 자산 숨기기 또는 상장 해제와 같은 조치를 취할 권리가 있습니다.
</p>
<p>
    <br/>
</p>
<p>
    2. 이 위험 고지에서 통지하는 사항은 본질적으로 나열되어 있을 뿐이며 플랫폼 사용자가 직면하는 모든 위험과 손실을 유발할 수 있는 모든 요소를 나열할 수 없습니다. Starthestar 플랫폼에서 암호화된 디지털 자산을 거래하기 전에 플랫폼 사용자는 관련 거래 규칙, 제품 활동 내용 및 기타 관련 계약과 이 위험 고지의 전체 내용을 주의 깊게 읽고 이해해야 하며 충분한 위험 평가 및 암호화된 디지털 자산 거래에 참여하여 감당할 수 없는 손실을 방지하기 위한 금융 약정.
</p>
<p>
    <br/>
</p>
<p>
    3. Starthestar 플랫폼에서 암호화된 디지털 자산을 거래하는 위험은 플랫폼 사용자가 부담해야 하며, Starthestar 플랫폼은 플랫폼 사용자에게 암호화된 디지털 자산의 원금이 손실되지 않을 것이라는 약속을 하지 않습니다. 또는 특정 소득을 얻게 될 것입니다.
</p>
<p>
    <br/>
</p>
<p>
    플랫폼 사용자 약속
</p>
<p>
    <br/>
</p>
<p>
    플랫폼 사용자는 Starthestar 플랫폼에서 미국, 캐나다, 싱가포르 공화국 및 플랫폼 사용자가 거주하는 국가의 법률 및 규정을 위반할 수 있는 행위를 하지 않습니다. 플랫폼 사용자가 법률을 위반할 수 있는 경우 또는 플랫폼 사용자의 규정 또는 거래 행동이 Starthestar 플랫폼의 위험 제어 메커니즘을 유발하는 경우 플랫폼 사용자는 Starthestar 플랫폼이 계정 동결 및 계정 취소와 같은 조치를 취할 권리가 있으며 법적 조치를 취할 권리가 있음에 동의합니다. 플랫폼 사용자의 책임.
</p>
<p>
    <br/>
</p>
<p>
    특별 알림: 플랫폼 사용자는 거래 위험과 손실을 이해하고 스스로 감수할 의향이 있음을 나타내는 이 위험 고지에 동의하기 위해 클릭하며 Starthestar 플랫폼은 원금 반환에 대해 어떠한 보증이나 공동 책임도 지지 않습니다. 암호화된 디지털 자산의 수입.
</p>
<p>
    <br/>
</p>`,
}