module.exports = {
  base: {
    title: 'SOUAA',
    desc1: 'Koning van het contract',
    desc2: 'Wereldleider in contracthandel',
    d1: 'Vertrouwen',
    d2: 'Eerlijkheid',
    d3: 'Kracht',
    more: 'Meer',
    top: 'terug naar de top',
    download: 'Download app',
    zszh: 'Echte rekening',
    mnzh: 'Demo-account',
    content: 'Handel met een wereldmarktleider in contracthandel'
  },
  demo: {
    title: 'aanmelding succes!',
    title2: 'Analoge rekening',
    submit: 'Bevestigen'
  },
  lang: {
    title: 'Nederlands'
  },
  message: {
    invalid_email: 'Ongeldig e-mail',
    invalid_password: 'Ongeldig wachtwoord',
    invalid_new_password: 'Ongeldig nieuw wachtwoord',
    invalid_res_password: 'Ongeldig bevestigingswachtwoord',
    invalid_email_code: 'Ongeldige e-mailverificatiecode',
    password_inconsistent: 'Inconsistente wachtwoorden',
    invalid_invitation_code: 'Ongeldige uitnodigingscode',
    should_agree: 'Lees en ga akkoord met de registratievoorwaarden',
    not_null: 'Dit veld kan niet leeg zijn',
    send_email: 'Probeer het opnieuw na {:s}s',
    invalid_number: 'ongeldige waarde',
    invalid_balance: 'Ongeldig saldo',
    invalid_rate: 'Selecteer meerdere',
    invalid_rule: 'Kies een regel',
    invalid_system_rule: 'Geen regels in het systeem',
    not_enough: 'Niet genoeg beschikbaar',
    price_high: 'Prijs moet hoger zijn dan {price}',
    price_low: 'Prijs moet lager zijn dan {price}',
    number_high: 'Het maximale aantal opnames is {num}',
    number_low: 'Het minimale opnamebedrag is {num}',
    copy_address: 'Adres succesvol gekopieerd {text}',
    invalid_upload_front: 'Upload de voorfoto van het identiteitsbewijs',
    invalid_upload_back: 'Upload een foto van de achterkant van uw identiteitsbewijs',
    invalid_upload_hand: 'Upload een hand-held ID-foto',
    invalid_upload_name: 'Voer de naam van het certificaat in',
    invalid_upload_card: 'Voer het ID-nummer in',
    upload_pic_type: "Upload foto's kunnen alleen in JPG, PNG formaat zijn",
    upload_pic_size: 'De grootte van de geüploade afbeelding mag niet groter zijn dan 10 MB'
  },
  index: {
    title2: 'Wereldwijde markt gratis handel 24 uur non-stop',
    desc2: 'Implementeer uw handelsstrategie in een extreem liquide markt, met een 24-uurs handelsvolume van',
    desc2_1: 'op het hele platform',
    list: {
      name: 'markt',
      h24_price: '24H laatste prijs',
      h24_change: '24 uur wissel',
      h24_high: '24H hoogste prijs',
      h24_low: '24H laagste prijs',
      h24_vol: '24H-volume'
    },
    footer: {
      desc:
        'starthestar Blockchain Technology Ltd, met hoofdkantoor in de Verenigde Staten, is een toonaangevend blockchain-technologiebedrijf. Het bedrijf heeft de Amerikaanse MSB internationale financiële licentie verkregen. Het is een compliant blockchain-technologieservicebedrijf dat op wereldwijde schaal kan opereren in overeenstemming met Blockchain-bedrijfsdiensten. Tegelijkertijd komen de talenten van het technische team van starthestar Blockchain Technology Ltd van internationale wetenschappelijke onderzoeksinstellingen en ontwikkelingsteams voor blockchain-technologie om gebruikersfondsen en accountbeveiliging volledig te beschermen. Naast het technische team heeft het bedrijf ook een professioneel blockchain-team: digital assets AI-kwantitatief investeringsteam, blockchain-projectwaarde-evaluatieteam, digital asset venture-investeringsteam, enz., hebben een wereldwijde lay-out om wereldwijde blockchain-gebruikers te bedienen. Het doel en de visie van het bedrijf is om veilige, stabiele, attente en betrouwbare transactiediensten te bieden aan liefhebbers van wereldwijde digitale activa, en om een professioneel, digitaal en internationaal wereldberoemd blockchain-technologiebedrijf op te bouwen.',
      item1: {
        tt: 'Productweergave',
        pt1: 'OTC-handelsregels',
        pt2: 'beginners gids',
        pt3: 'Tarief Standaard',
        pt4: 'Contractbeschrijving'
      },
      item2: {
        tt: 'Steun',
        pt1: 'KYC en antiwitwasovereenkomst',
        pt2: 'Beleid voor handhavingsverzoeken',
        pt3: 'Inleiding tot digitale middelen'
      },
      item3: {
        tt: 'Voorwaarden',
        pt1: 'Privacy bescherming',
        pt2: 'gebruikersovereenkomst',
        pt3: 'Risicomelding'
      },
      item4: {
        tt: 'Neem contact met ons op',
        pt1: 'Zakelijke samenwerking',
        pt2: 'Institutionele samenwerking',
        pt3: 'OTC-toepassing',
        pt4: 'E-mail klantenservice',
        pt5: 'Klachten en suggesties',
        pt6: 'media samenwerking'
      }
    },
    bk3: {
      title: 'Trots op innovatieve R&D-kracht',
      desc: 'Onvoorstelbare contracthandelervaring, ontworpen voor financiële handelaren',
      pt1: 'Open order stop winst en stop verlies',
      pd1: 'Wachtende bestellingen blijven niet laat op, maak je zorgen dat je de bestelling niet in de gaten houdt',
      pt2: 'Omgekeerde openstaande volgorde',
      pd2: 'De markt verandert plotseling, houd het in een seconde bij',
      pt3: 'Ondersteun het volledige magazijn per magazijn',
      pd3: 'Doe wat je wilt, doe wat je wilt',
      pt4: 'Volledige positie getrapte liquidatie',
      pd4: 'Buitenlandse liquidatie is eerst gelijk aan de helft en probeert beleggers te helpen verliezen te verminderen'
    },
    bk5: {
      title: 'Acht voordelen van contracthandelsplatform',
      desc: 'We luisteren de hele tijd, optimaliseren en verbeteren continu en creëren een eersteklas handelservaring voor wereldwijde handelsgebruikers',
      pt1: 'Diepte van de markt',
      pd1: 'De marktdiepte van de eerste drie kraampjes op het platform heeft de orde van miljoenen dollars bereikt, veel meer dan de gemiddelde marktdiepte van de industrie van 10.000 en 100.000. De spread is klein en de transactie is snel.',
      pt2: 'Vlotte transactie',
      pd2: 'Met behulp van een efficiënte matching-transactie-engine om een soepele transactie-ervaring te garanderen, kan het miljoenen bestellingen per seconde verwerken en kan het een soepele en stabiele werking ondersteunen met meer dan 20 miljoen gelijktijdige online gebruikers.',
      pt3: 'Eerlijke prijs',
      pd3: "'Marked Price' selecteert de real-time transactieprijs van 's werelds drie belangrijkste beurzen als de indexprijs voor regressieberekening, waardoor gebruikersverliezen als gevolg van abnormale manipulatie van de laatste transactieprijs in korte tijd effectief worden voorkomen.",
      pt4: 'Zelf ontwikkelde technologie',
      pd4: 'De matchmaking-engine is geworteld in traditionele financiën, ontwikkeld door een team van senior experts in de financiële sector en heeft elk detail zorgvuldig uitgewerkt om de veilige, stabiele en efficiënte werking van het handelsplatform te garanderen.',
      pt5: 'Toprisicobeheersing',
      pd5:
        'Beoordeling op het tweede niveau, meerlaagse codering, hoge systeembeveiliging. De combinatie van warme en koude portefeuilles, versleutelingsmaatregelen van militaire kwaliteit, beveiliging van activatransacties is gegarandeerd en garandeert 100% de veiligheid van de digitale activa van gebruikers.',
      pt6: 'Fondsen Beveiliging',
      pd6:
        'Drielaags beveiligingssysteem, uitgebreide beveiligingsaudit. Zelf gebouwd, het toonaangevende hiërarchische deterministische cold wallet-systeem in de branche, alle gebruikers die oplaadadressen toewijzen, zijn cold wallet-adressen, het verzamelen en opnemen van fondsen wordt voltooid via offline handtekeningen.',
      pt7: 'Hardcore-kracht',
      pd7: "Tienduizenden BTC-reserves, genen voor financiële innovatie en 's werelds topmanagementteam. starthestar belooft dat als account- en transactiegegevensfouten niet worden veroorzaakt door systeemredenen, het platform volledig zal betalen in geval van fouten!",
      pt8: 'Kwaliteitsservice',
      pd8: '7 * 24 uur per dag professionele klantenservice, snelle reactie. Uitgaande van het denken aan service, biedt u eersteklas handelservaring voor wereldwijde klanten. De ultieme ervaring komt voort uit het concept van het bedienen van klanten.'
    },
    bk6: {
      title: 'Wereldwijde werking op het gebied van veiligheid en naleving',
      desc: 'Omarm supervisie en houd u altijd aan wereldwijde nalevingsoperaties'
    },
    bk7: {
      title: 'Officiële strategische partner'
    },
    bk8: {
      title: 'Meer dan 100.000 wereldwijde handelsgebruikers bediend',
      desc: 'Momenteel bevinden gebruikers zich overal in de Verenigde Staten, Japan, Zuid-Korea, de Seychellen, Vietnam en andere landen'
    },
    bk9: {
      title: 'Vlotte transactie altijd en overal',
      pt1: 'Realtime matchmaking-transacties, stortingen en opnames komen binnen enkele seconden aan',
      pt2: 'Meerdere handelsmodi, tot 125 keer hefboomwerking',
      dt1: 'iOS-versie downloaden',
      dt2: 'Android-versie downloaden'
    },
    about: {
      title: 'Over ons',
      pt1: 'Platformconcept',
      pd1:
        'Laat wereldwijde activa vrij circuleren en creëer een nieuwe contractervaring. X-xenogenetisch, vertegenwoordigt innovatieve genen en zorgt voor een gestage stroom van kracht voor industriële innovatie. M-motor, vertegenwoordigt het doel en blijft vooruitgaan met als doel de contracthandelervaring te leiden. EX-exchange zet zich namens het handelsplatform in voor het creëren van een eerlijk, transparant en efficiënt handelsecosysteem met de bedrijfsfilosofie van dienstbaarheid, luisteren en denken.',
      pt2: 'Kernwaarden',
      pd2:
        'We luisteren altijd, besteden aandacht en denken vooruit, en zetten ons in voor het creëren van een eerlijk, transparant en efficiënt handelsecosysteem. Wereldwijde handelaren een betere handelservaring bieden, voldoen aan de werkelijke behoeften van handelaren en de ontwikkeling van de markt voor digitale activahandel bevorderen.',
      pt3: 'Teamachtergrond',
      pd3:
        'Het oprichtende team bestaat uit de beste blockchain-investeerders, traditionele financiële executives, topexperts op het gebied van digitale activa en traditionele teams voor metaalbewerkingstechnologie. De kernleden van het R&D-team zijn van Morgan Stanley, CICC, Hang Seng, Tencent, Noah Wealth.'
    }
  },
  start: {
    p_title: 'Transactie, begin hier',
    p_desc: 'Voer uw e-mailadres in het volgende gebied in om u onmiddellijk te registreren',
    p_email: 'e-mailen',
    p_btn: 'Creëren'
  },
  download: {
    p_title: 'APP-client downloaden',
    p_desc: 'Altijd en overal, multi-platform terminaltransactie',
    p_btn: 'Downloaden',
    p_scan_android: 'Android-app',
    p_scan_ios: 'IOS-app'
  },
  downpage: {
    t1: {
      p_title: 'Prestaties van Lightning-transacties',
      p_desc: 'Bied een verscheidenheid aan transactietypen om uit te kiezen, ordermatching is extreem snel, flash-uitwisseling met één klik en handelen is handiger'
    },
    t2: {
      p_title: 'Gemakkelijke overdracht van activa',
      p_desc: 'Eenvoudige overdracht van activa op de rekening, realtime overzicht van digitale activa, verhoging van het cirkeldiagram van de valutaverhouding'
    },
    t3: {
      p_title: 'Aangepast handelspaneel',
      p_desc: 'Definieer vrij de handelslay-out volgens verschillende handelsgewoonten en verhoog de invoer van K-lijn en dieptekaart'
    },
    t4: {
      p_title: 'Beheer van accountintegratie',
      p_desc: 'Meerdere beveiligingsverificatie-instellingen, één account beheert C2C-accounts en valutarekeningen'
    }
  },
  login: {
    login: 'Log in',
    title: 'Gebruiker login',
    desc1: 'Gelieve in te loggen',
    desc2: 'Officiële website',
    email: 'E-mail',
    email2: 'E-mail',
    email3: 'Telefoonnummer',
    email_desc: 'Voer uw e-mailadres in',
    phone_desc: 'Voer het telefoonnummer in',
    password: 'wachtwoord',
    password_desc: 'Vul alstublieft het wachtwoord in',
    forgot_password: 'Wachtwoord vergeten',
    no_account: 'Nog geen rekening'
  },
  signup: {
    signup: 'register',
    title: 'Gebruikersregistratie',
    desc1: 'Welkom bij inloggen',
    desc2: 'Officiële website',
    invitation_code: 'uitnodigingscode',
    invitation_desc: 'Voer de uitnodigingscode in',
    accept: 'Aanvaarden',
    service: 'gebruikersovereenkomst',
    and: 'En',
    policy: 'Privacybeleid',
    have_account: 'Heb al een account'
  },
  retrieve: {
    ptitle: 'Wachtwoord ophalen',
    pemail_code: 'E-mailverificatiecode',
    pset: 'Stel een wachtwoord in',
    set_title: 'Stel een wachtwoord in',
    set_desc: 'U bent uw wachtwoord aan het resetten, vul de volgende instellingen in',
    new_password: 'nieuw paswoord',
    password_desc: 'Voer een nieuw wachtwoord in',
    res_passowrd: 'bevestig wachtwoord',
    res_passowrd_desc: 'Voer het bevestigingswachtwoord in'
  },
  valid_email: {
    title: 'Registratie Verificatie',
    desc: 'U registreert een account, voltooi de volgende verificatie',
    email_code: 'E-mailverificatiecode',
    email_desc: 'Vul alstublieft de verificatiecode in',
    get_code: 'Verificatiecode ophalen'
  },
  ihead: {
    begin_trade: 'Begin met handelen',
    helper: 'Hulp voor beginners',
    text: 'Documentcentrum',
    new_currency: 'Nieuw valuta-abonnement'
  },
  uhead: {
    title1: 'waren',
    title2: 'Internationale Forex',
    title3: 'Internationale index',
    currency: 'Munteenheid',
    lever: 'Hefboomtransactie',
    coins: 'Valutatransactie',
    contract: 'Contracttransactie',
    metal: 'Metaal',
    energy: 'Energie',
    contract_info: 'Contractinformatie',
    helper: 'Hulp voor beginners',
    text: 'Documentcentrum',
    assets: 'Activa',
    u_wallet: 'Mijn portemonnee',
    u_contract: 'Contractrekening',
    u_lever: 'Hefboomaccount',
    u_account: 'Account Informatie',
    u_logout: 'Uitloggen'
  },
  news: {
    label: 'Nieuws',
    update: 'Bijgewerkt op'
  },
  guide: {
    label: 'beginners gids',
    list: [
      {
        ptitle: 'Hoe te registreren voor starthestar',
        pkey: 'k1'
      },
      {
        ptitle: 'Identiteitsverificatie',
        pkey: 'k2'
      },
      {
        ptitle: 'Inlogwachtwoord en transactiewachtwoordinstellingen',
        pkey: 'k3'
      },
      {
        ptitle: 'Munten storten',
        pkey: 'k4'
      },
      {
        ptitle: 'Munttransactieproces',
        pkey: 'k5'
      },
      {
        ptitle: 'Opname',
        pkey: 'k6'
      },
      {
        ptitle: ' mobiele APP downloaden',
        pkey: 'k7'
      }
    ],
    content: {
      k1: require('./currency/en/guide/k1.js').default,
      k2: require('./currency/en/guide/k2.js').default,
      k3: require('./currency/en/guide/k3.js').default,
      k4: require('./currency/en/guide/k4.js').default,
      k5: require('./currency/en/guide/k5.js').default,
      k6: require('./currency/en/guide/k6.js').default,
      k7: require('./currency/en/guide/k7.js').default
    }
  },
  kline: {
    h24_high: '24H hoog',
    h24_low: '24 uur laag',
    h24_vol: '24H-volume',
    commission: 'Huidige Commissie',
    record: 'Transactieoverzicht',
    commission_list: 'Commissie lijst',
    deal_list: 'Laatste aanbieding',
    price: 'Prijs',
    xprice: 'Winstspreiding',
    bzj: 'Marge',
    number: 'Nummer',
    total: 'Totaal',
    time: 'Tijd',
    currency: 'Posities',
    type: 'Type',
    direction: 'Richting',
    multiple: 'Hefboom',
    fee: 'Commissie',
    amount: 'Totaal',
    status: 'Toestand',
    operate: 'Operatie',
    buy: 'Kopen',
    sell: 'Verkopen',
    pending: 'Bestelling in behandeling',
    trade_success: 'Handel succesvol',
    retract: 'Annuleren',
    profit: 'Neem winst',
    loss: 'Stoppen met verlies',
    all: 'Alle',
    all_currency: "Alle valuta's",
    new_price: 'Laatste prijs',
    change: 'Opkomst en ondergang',
    protocol: 'protocol',
    _status1: 'Beoordeling',
    _status2: 'Succesvol',
    _status3: 'Afgewezen'
  },
  coins: {
    title: 'Valutatransactie',
    market: 'marktprijs',
    limit: 'Limietprijs',
    profit: 'Neem winst',
    loss: 'Stoppen met verlies',
    price: 'Prijs',
    current: 'Huidig',
    price_desc: 'Voer de prijs in',
    number: 'Nummer',
    number_desc: 'Voer het nummer in',
    available: 'Beschikbaar',
    half: 'half magazijn',
    all: 'Volledig magazijn',
    fee: 'Commissie',
    amount: 'Transactiebedrag',
    buy: 'Kopen',
    sell: 'Verkopen',
    current_price: 'Huidig',
    open_price: 'Vandaag geopend',
    high: 'Hoog',
    low: 'Laag',
    yesterday: 'Sluit gisteren',
    today_zf: 'Prijs verandering',
    now_cc: 'Positie',
    y_cc: 'Y-positie',
    jsj: 'Afwikkelingsprijs',
    zrjsj: 'Y Verrekeningsprijs'
  },
  lever: {
    title: 'Hefboomtransactie',
    multiple: 'Hefboom',
    multiple_desc: 'Selecteer een veelvoud',
    double: 'dubbele',
    buy: 'Kopen (omhoog)',
    sell: 'Verkopen (omlaag)'
  },
  contract: {
    title: 'Contracttransactie',
    time: 'Tijd',
    time_desc: 'Selecteer een tijd',
    minute: 'minuut',
    second: 'Seconde',
    buy: 'Kopen (omhoog)',
    sell: 'Verkopen (omlaag)',
    rate: 'Royalty-verhouding'
  },
  wallet: {
    title: 'Mijn bezittingen',
    valuation: 'Totale activawaardering',
    my_wallet: 'Mijn portemonnee',
    my_trade: 'Handelaccount',
    my_transfer: 'Opnames opnemen',
    my_deposit: 'Muntstortingsrecord',
    cbdz: 'stortingsadres',
    cbsl: 'Hoeveelheid',
    deposit: 'Munten storten',
    withdrawal: 'Opname',
    search: 'Zoekopdracht',
    currency: 'Posities',
    protocol: 'Protocol',
    liquidation: 'Positie sluiten',
    liquidation_title: 'Snelle liquidatie',
    trade_pwd: 'Handelswachtwoord',
    enter_trade_pwd: 'Voer het handelswachtwoord in',
    submit_liquidation: 'Sluit de positie onmiddellijk',
    amount: 'Totaal',
    available: 'Beschikbaar',
    freeze: 'Bevriezen',
    market: 'waardering',
    ljsy: 'Cumulatief inkomen',
    zrsy: 'Inkomsten van gisteren',
    operate: 'Operatie',
    countdown: 'Aftellen',
    lever_title: 'Hefboomaccount',
    contract_title: 'Contractrekening',
    direction: 'Richting',
    bond: 'Marge',
    buy_rate: 'meerdere',
    profit: 'Winst en verlies',
    profit1: 'Winst en verlies',
    buy: 'Kopen',
    sell: 'Verkopen',
    many: 'Kopen',
    empty: 'Verkopen',
    contract_time: 'Contract tijd',
    premium: 'Voorrecht',
    minute: 'minuut',
    address: 'Adres',
    address_desc: 'Voer het adres in',
    number: 'Nummer',
    number_desc: 'Vul a.u.b. het aantal opnames in',
    copy: 'Kopiëren',
    confirm: 'OK',
    all: 'Alle',
    fee: 'Commissie',
    actual: 'Daadwerkelijke aankomst',
    premark: 'Aandacht',
    text_1: 'Dit adres accepteert alleen {name}-tegoeden, laad geen andere valuta op, anders worden de tegoeden niet opgehaald',
    text_2: "{name} miner's fee is: huidige marktwaarde {rate}%/transaction, minimum standaard: {min}{name}/transaction",
    text_3: 'Bevestig dat de ontvangende adresgegevens correct zijn voordat u het geld overmaakt. Zodra de activa zijn overgedragen, kunnen ze niet worden ingetrokken.',
    text_4: '{name} Mijnbouwkosten bedragen: {val} {name}/ pen'
  },
  record: {
    title: 'Fondsen registreren',
    type: 'Type',
    all_currency: "Alle valuta's",
    all_type: 'Alle typen',
    select: 'Vraag',
    clear: 'Filter wissen',
    receive: 'Borg',
    transfer: 'Opname',
    buy: 'Kopen',
    sell: 'Verkopen',
    normal: 'Normaal',
    pending: 'Stop-limiet',
    wallet: 'Positie details',
    zzzqy: 'Balans',
    zqy: 'Vrije Marge',
    fdyk: 'Zwevende P/L',
    fxl: 'Risicopercentage'
  },
  user: {
    account: 'Accountverificatie',
    account_desc: 'Creditstorting, transactie en andere functies moeten eerst de accountverificatie doorstaan',
    password: 'Login wachtwoord',
    password_desc: 'Wordt gebruikt om accountbeveiliging te beschermen',
    modify: 'Bewerken',
    set: 'Set',
    find_paypwd: 'Wachtwoord ophalen',
    find_pay_pwd: 'Betalingswachtwoord ophalen',
    email: 'E-mail',
    enter_email: 'Vul alstublieft uw e-mailadres in',
    email_code: 'Verificatie code',
    enter_email_code: 'Voer de e-mailverificatiecode in',
    send: 'Versturen',
    pay_password: 'Betalingswachtwoord',
    pay_password_desc: 'Gebruikt om activabeveiliging te beschermen tijdens het handelen',
    not_certified: 'Niet gecertificeerd',
    certification: 'Certificering in uitvoering',
    verified: 'Geverifieerd',
    rejected: 'afgewezen',
    update_pwd: 'Wijzig het inlogwachtwoord',
    update_pay_pwd: 'Betalingswachtwoord wijzigen',
    set_pay_pwd: 'Betalingswachtwoord instellen',
    old_pwd: 'Origineel wachtwoord',
    enter_old_pwd: 'Voer het oorspronkelijke wachtwoord in',
    new_pwd: 'nieuw paswoord',
    enter_new_pwd: 'Voer een nieuw wachtwoord in',
    res_pwd: 'bevestig wachtwoord',
    enter_res_pwd: 'Voer het bevestigingswachtwoord in',
    submit: 'Indienen'
  },
  certified: {
    title: 'Accountverificatie',
    front: 'ID-kaart voorzijde',
    back: 'De achterkant van de identiteitskaart',
    hand: 'Handgreep ID-kaart',
    name: 'Naam',
    name_desc: 'Voer een naam in',
    card: 'ID nummer',
    card_desc: 'Voer het ID-nummer in',
    submit: 'Indienen'
  },
  trade: {
    title: 'transactie wachtwoord',
    enter_password: 'Voer het transactiewachtwoord in',
    cancel: 'annuleren',
    submit: 'indienen'
  },
  revoke: {
    title: 'Snel bericht',
    message: 'Weet je zeker dat je de bestelling wilt intrekken?',
    cancel: 'annuleren',
    submit: 'bevestigen'
  },
  coins_assets: {
    title: 'Valuta activa',
    total_equity: 'Het totale eigen vermogen',
    equity: 'Rekening eigen vermogen',
    number: 'Aantal bedrijven',
    market_value: 'Actuele waardering'
  },
  lever_assets: {
    title: 'Hefboomactiva',
    total_equity: 'Het totale eigen vermogen',
    equity: 'Rekening eigen vermogen',
    profit: 'Ongerealiseerde winst en verlies',
    risk: 'Risico'
  },
  contract_assets: {
    title: 'Contractactiva',
    total_equity: 'Het totale eigen vermogen',
    equity: 'Rekening eigen vermogen',
    profit: 'Ongerealiseerde winst en verlies'
  },
  contract_menu: {
    contract: 'Contractgegevens',
    lever: 'Maak gebruik van details',
    coins: 'Munt Index',
    history: 'Geschiedenis van de fondsrente',
    coins_rules: [
      {
        value: '1D',
        name: 'Op de dag'
      },
      {
        value: '1W',
        name: 'Deze week'
      },
      {
        value: '1M',
        name: 'Deze maand'
      },
      {
        value: '1J',
        name: 'Dit seizoen'
      }
    ],
    lever_info: 'Maak gebruik van informatie',
    contract_info: 'Contract informatie'
  },
  contract_info: {
    bzj: 'Borg',
    pt1: 'Afwikkelingsvaluta',
    pt2: 'Contract nominale waarde',
    pt3: 'Minimale wijzigingsprijs',
    pt4: 'Contractduur',
    pt5: 'Fonds Tarief Tijd',
    pt6: 'Gemarkeerde prijs',
    pt7: 'Volume van positie',
    pt8: '24-uurs handelsvolume',
    pt9: 'Voorrecht',
    pt10: 'Looptijd leveringsinkomen',
    pt11: 'Open positiespreiding',
    pt12: 'Leverings- en afwikkelingsmechanisme',
    pt13: 'Transactie Servicekosten',
    pt14: 'Positie vergrendelen',
    pv5: 'Elke dag 08:00 16:00 24:00',
    pv10_1: 'Longpositie: (gemiddelde slotkoers - gemiddelde openingskoers) x hoeveelheid',
    pv10_2: 'Shortpositie: (gemiddelde openingskoers - gemiddelde slotkoers) x hoeveelheid',
    pv11: 'Nee',
    pv12: 'Automatische levering op de eindvervaldag',
    pv13: 'Nee',
    pv14: 'niet ondersteunen'
  },
  metal_info: {
    pt1: 'Handelsvariëteiten',
    pt2: 'Afwikkelingsvaluta',
    pt3: 'Offerte eenheid',
    pt4: 'Contractgrootte',
    pt5: 'Minimale wijzigingseenheid',
    pt51: 'Minimale handelseenheid',
    pt52: 'Maximale handelseenheid',
    pt6: 'Contract tijd',
    pt7: 'Handelstijd',
    pt8: 'Commissie',
    pt9: 'Beursgenoteerde beurs',
    pt10: 'doel verspreid',
    pv4: 'duurzaam',
    pv5: '24-uurs handel van maandag tot en met vrijdag',
    pv51: '24 uur handel',
    pv6: 'Contractwaarde0,08%',
    pv7: 'Londense effectenbeurs (LME)',
    pv71: 'New York Stock Exchange (NYMEX)',
    pv10: '{name} doelspread is {num} pips'
  },
  lever_info: {
    title: 'Maak gebruik van details',
    bei: 'keer',
    pt1: 'Afwikkelingsvaluta',
    pt2: 'Contract nominale waarde',
    pt3: 'Minimale wijzigingsprijs',
    pt4: 'Contractduur',
    pt5: 'Fonds Tarief Tijd',
    pt6: 'Gemarkeerde prijs',
    pt7: 'Volume van positie',
    pt8: '24-uurs handelsvolume',
    pt9: 'Risico reserve',
    pt10: 'Liquidatie Winst',
    pt11: 'Open positiespreiding',
    pt12: 'Automatisch liquidatiemechanisme',
    pt13: 'Transactie Servicekosten',
    pt14: 'Positie vergrendelen',
    pv4: 'Duurzaam',
    pv5: 'Elke dag 08:00 16:00 24:00',
    pv10_1: 'Longpositie: (gemiddelde slotkoers - gemiddelde openingskoers) x hoeveelheid',
    pv10_2: 'Shortpositie: (gemiddelde openingskoers - gemiddelde slotkoers) x hoeveelheid',
    pv12: 'De reserverisicoratio bereikt 80%',
    pv13: 'Contract waarde',
    pv14: 'Niet ondersteund'
  },
  //币币信息
  coins_desc: require('./currency/en/coins.js').default,

  fun_history: {
    t1: 'Datum',
    t2: 'Geschat financieringspercentage',
    t3: 'Daadwerkelijk financieringspercentage'
  },
  docs: {
    list: [
      {
        name: 'Regels voor OTC-handel',
        key: 'otc'
      },
      {
        name: 'KYC en antiwitwasovereenkomsten',
        key: 'kyc'
      },
      {
        name: 'Verzoekbeleid voor rechtshandhaving',
        key: 'wet'
      },
      {
        name: 'privacy bescherming',
        key: 'privacy'
      },
      {
        name: 'gebruikersovereenkomst',
        key: 'protocol'
      },
      {
        name: 'Risicomelding',
        key: 'risico'
      }
    ],
    otc: require('./currency/en/otc').default,
    kyc: require('./currency/en/kyc.js').default,
    law: require('./currency/en/law.js').default,
    privacy: require('./currency/en/privacy.js').default,
    protocol: require('./currency/en/protocol').default,
    risk: require('./currency/en/risk').default
  },
  asset: {
    title: 'Introductie van digitale activa',
    list: 'Lijst met digitale middelen'
  },
  num_asset: require('./currency/en/asset').default,

  new_currency: {
    _title: 'Nieuw valuta-abonnement',
    _all: 'Alle',
    _progress: 'Bezig',
    _end: 'Over',
    _success: 'Uitwisseling succesvol',
    _progress_str: 'Voortgang',
    _total_str: 'Totaal vrijgegeven',
    _date_time: 'Begin- en einddatum',
    _submit: 'Verzilver nu',
    _base_title: 'Basis informatie',
    _status: 'Toestand',
    _currency: 'Geaccepteerde valuta',
    _symbol: 'Symbool van munteenheid',
    _amount: 'Totaal opgeheven verbod',
    _price: 'Oorspronkelijke prijs',
    _limit: 'Persoonlijke aankooplimiet',
    _limit_amount: 'Persoonlijke aankooplimiet',
    _limit_one: 'Persoonlijke enkelvoudige ondergrens',
    _no_limit: 'Geen limiet',
    _position: 'Positie',
    _begin_time: 'Starttijd',
    _end_time: 'Eindtijd',
    _redeemed: 'Bedrag verzilverd',
    _surplus: 'overschot',
    _wallet: 'Portemonnee',
    _have: 'Momenteel gehouden',
    _input: 'ingevoerde hoeveelheid',
    _choose: 'Gelieve te kiezen',
    _new_wallet: 'Nieuwe valutarekening',
    _new_price: 'Laatste prijs',
    _lock: 'Vergrendel periode',
    _login: 'Log alsjeblieft in',
    _no_data: 'Geen gegevens',

    _avg_price: 'gemiddelde prijs'
  }
}
