<template>
<main role="main">
    <h1 class="visibility-hidden">{{$t('base.title')}}</h1>

    <section class="section hero">
        <div class="hero-inner">
            <h2 class="visibility-hidden">搜索</h2>
            <form role="search" class="search search-full" data-search="" data-instant="true" autocomplete="off" action="/hc/zh-cn/search" accept-charset="UTF-8" method="get">
                <input name="utf8" type="hidden" value="✓">
                <input type="search" name="query" id="query" placeholder="搜索" autocomplete="off" aria-label="搜索">
            </form>
        </div>
    </section>

    <div class="container">
        <section class="section knowledge-base">
            <h2 class="visibility-hidden">类别</h2>
            <section class="categories blocks">
                <ul class="blocks-list">
                    <li class="blocks-item">
                        <router-link :to="{name:'HType',query:{id:2}}" class="blocks-item-link">
                            <span class="blocks-item-title">合约交易</span>
                            <span class="blocks-item-description"></span>
                        </router-link>
                    </li>
                    <li class="blocks-item">
                        <router-link :to="{name:'HType',query:{id:2}}" class="blocks-item-link">
                            <span class="blocks-item-title">合约跟单</span>
                            <span class="blocks-item-description"></span>
                        </router-link>
                    </li>
                    <li class="blocks-item">
                        <router-link :to="{name:'HType',query:{id:2}}" class="blocks-item-link">
                            <span class="blocks-item-title">新手指引</span>
                            <span class="blocks-item-description"></span>
                        </router-link>
                    </li>
                    <li class="blocks-item">
                        <router-link :to="{name:'HType',query:{id:2}}" class="blocks-item-link">
                            <span class="blocks-item-title">公告中心</span>
                            <span class="blocks-item-description"></span>
                        </router-link>
                    </li>
                    <li class="blocks-item">
                        <router-link :to="{name:'HType',query:{id:2}}" class="blocks-item-link">
                            <span class="blocks-item-title">关于平台</span>
                            <span class="blocks-item-description"></span>
                        </router-link>
                    </li>
                </ul>
            </section>
            <section class="articles">
                <h2>被推荐的文章</h2>
                <ul class="article-list promoted-articles">

                    <li class="promoted-articles-item">
                        <a href="javascript:">
                            STARTHESTAR永续合约用户使用协议
                        </a>
                    </li>

                    <li class="promoted-articles-item">
                        <a href="javascript:">
                            五一邀友聚STARTHESTAR，享“手续费巨能返”！

                        </a>
                    </li>

                    <li class="promoted-articles-item">
                        <a href="javascript:">
                            STARTHESTAR关于APP端更新的公告【5月7日】

                        </a>
                    </li>

                    <li class="promoted-articles-item">
                        <a href="javascript:">
                            关于警惕冒充STARTHESTAR官网的严正声明及风险提示

                        </a>
                    </li>

                    <li class="promoted-articles-item">
                        <a href="javascript:">
                            致全球300万STARTHESTAR用户的一封信

                        </a>
                    </li>

                    <li class="promoted-articles-item">
                        <a href="javascript:">
                            2021 STARTHESTAR合伙人全员奖金及分红政策
                        </a>
                    </li>

                </ul>
            </section>

        </section>

        <section class="section home-section activity">

            <div data-app="recent-activity">
                <div class="recent-activity">
                    <h2 class="recent-activity-header">最近的活动</h2>
                    <ul class="recent-activity-list" style="">
                        <li class="recent-activity-item" data-recent-activity-action="article_created">
                            <a class="recent-activity-item-parent" href="javascript:">最新公告</a>
                            <a class="recent-activity-item-link" href="javascript:">STARTHESTAR微访谈第【20210512】期即将开启</a>
                            <div class="recent-activity-item-meta">
                                <div class="recent-activity-item-time">文章创建于 3 小时前</div>
                                <div class="recent-activity-item-comment"><span>0</span></div>
                            </div>
                        </li>
                        <li class="recent-activity-item" data-recent-activity-action="article_created">
                            <a class="recent-activity-item-parent" href="javascript:">最新公告</a>
                            <a class="recent-activity-item-link" href="javascript:">关于5月10日网络波动的补偿方案</a>
                            <div class="recent-activity-item-meta">
                                <div class="recent-activity-item-time">文章创建于 1 天前</div>
                                <div class="recent-activity-item-comment"><span>0</span></div>
                            </div>
                        </li>
                        <li class="recent-activity-item" data-recent-activity-action="article_created">
                            <a class="recent-activity-item-parent" href="javascript:">最新公告</a>
                            <a class="recent-activity-item-link" href="javascript:">关于5月10日网络波动的恢复公告</a>
                            <div class="recent-activity-item-meta">
                                <div class="recent-activity-item-time">文章创建于 1 天前</div>
                                <div class="recent-activity-item-comment"><span>0</span></div>
                            </div>
                        </li>
                        <li class="recent-activity-item" data-recent-activity-action="article_created">
                            <a class="recent-activity-item-parent" href="javascript:">最新公告</a>
                            <a class="recent-activity-item-link" href="javascript:">关于5月10日服务器网络波动异常的公告</a>
                            <div class="recent-activity-item-meta">
                                <div class="recent-activity-item-time">文章创建于 1 天前</div>
                                <div class="recent-activity-item-comment"><span>0</span></div>
                            </div>
                        </li>
                        <li class="recent-activity-item" data-recent-activity-action="article_created">
                            <a class="recent-activity-item-parent" href="javascript:">STARTHESTAR平台周报</a>
                            <a class="recent-activity-item-link" href="javascript:">STARTHESTAR平台周报【2021年5月3日-5月9日】</a>
                            <div class="recent-activity-item-meta">
                                <div class="recent-activity-item-time">文章创建于 2 天前</div>
                                <div class="recent-activity-item-comment"><span>0</span></div>
                            </div>
                        </li>
                    </ul>
                    <div class="recent-activity-no-activities" style="display: none;"></div>
                    <div class="recent-activity-loader" style="display: none;">...</div>
                    <div class="recent-activity-controls" style="">
                        <a href="#" data-recent-activity-seemore="">
                            查看更多
                        </a>
                    </div>
                </div>
            </div>

        </section>
    </div>

</main>
</template>

<script>
export default {
    name: "Index"
}
</script>

<style src="../../../static/css/helpbox.css" scoped type="text/css"></style>
