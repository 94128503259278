export default `<h2 style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:48px">
<strong><span style="font-family: 宋体;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 16px;background: rgb(255, 255, 255)">UIP (未来版权)</span></strong>
</h2>
<p style=";text-indent: 0;padding: 0;line-height: 48px;background: rgb(255, 255, 255)">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">一、项目名称</span></span></strong><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">未来版权</span> (UIP)</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><strong><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">二、项目介绍</span></span></strong><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">未来版权是新加坡</span>UIP基金会管理的一款基于区块链技术的文娱版权智能交易平台。未来版权致力于为全球文娱IP版权持有者提供版权存证、版权交易，NFT产品孵化，高端加密艺术NFT交易等于一体的一站式文娱版权智能服务。未来版权通过区块链技术所带来的资产通证化、所有权标记、数字稀缺性等，帮助版权持有者创建版权数字资产NFT，交易版权NFT，最大化实现版权价值。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><strong><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">三、</span>Token概况与分配</span></strong><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">代币总量：</span> 3,000,000,000</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">流通总量：</span> 1,340,000,000&nbsp;</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">代币价格：</span> $0.029&nbsp; &nbsp;&nbsp;</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">代币分配：</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">50%在各大交易所流通；&nbsp;</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">16.7%将作为UIP布局文娱生态的社区基金；&nbsp;</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">33.3%作为UIP项目的开发基金，无限期锁定，将用于UIP版权交易的生态建设、作为交易平台的介质以及进行团队扩充。&nbsp;</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><strong><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">四、常用链接</span></span></strong><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">项目网站：</span></span><a href="http://www.unlimitedip.io/"></a><a href="#/"><span style="font-family: 微软雅黑;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">http://www.uip.group/#/</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">白皮书：</span></span><a href="http://cdn.uip.vip/whitePaper/UnlimitedIP_WhitePaper.pdf"><span style="font-family: 微软雅黑;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">http://cdn.uip.vip/whitePaper/UnlimitedIP_WhitePaper.pdf</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">区块查询：</span></span><a href="https://etherscan.io/token/0x4290563c2d7c255b5eec87f2d3bd10389f991d68"><span style="font-family: 微软雅黑;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">https://etherscan.io/token/0x4290563c2d7c255b5eec87f2d3bd10389f991d68</span></a><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p>
<span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
<p>
<br/>
</p>`