export default {
    title: "KYC and anti-money laundering agreements",
    content: `<p style="text-align: center;">
    <span style="font-size: 18px;"><strong>KYC and Anti-Money Laundering Protocol</strong></span>
</p>
<p>
    Starthestar
</p>
<p>
    <br/>
</p>
<p>
    1 Introduction
</p>
<p>
    &nbsp; &nbsp;1.1 We promise to prudently comply with know-your-customer and anti-money laundering-related laws and regulations and not knowingly violate the know-your-customer and anti-money laundering policies. Within the scope of our reasonable control, our financially necessary measures and technologies provide you with a safe service, as far as possible to protect you from the loss caused by the money laundering of criminal suspects
</p>
<p>
    <br/>
</p>
<p>
    1.2 Our Know Your Customer and Anti-Money Laundering Policy is a comprehensive international policy system, including the Know Your Customer and Anti-Money Laundering policies of the different legal jurisdictions to which you are affiliated. Our robust compliance framework ensures that we comply with regulatory requirements and levels of regulation locally and globally, and ensures the continued operation of this website
</p>
<p>
    <br/>
</p>
<p>
    2 Know your customer and anti-money laundering policies as follows:
</p>
<p>
    2.1 Promulgate know-your-customer and anti-money laundering policies and update them from time to time to meet the standards set by the corresponding laws and regulations
</p>
<p>
    <br/>
</p>
<p>
    2.2 Promulgate and update some guiding principles and rules for the operation of this website, and our employees will provide full service in accordance with the guidance of the principles and rules
</p>
<p>
    <br/>
</p>
<p>
    2.3 Design and complete procedures for internal monitoring and control of transactions, such as verifying identity by strict means, and arranging to set up a professional team to be responsible for anti-money laundering work
</p>
<p>
    <br/>
</p>
<p>
    2.4 Conduct due diligence and ongoing monitoring of clients using a risk-prevention approach
</p>
<p>
    <br/>
</p>
<p>
    2.5 Review and regular inspection of transactions that have occurred
</p>
<p>
    <br/>
</p>
<p>
    2.6 Reporting suspicious transactions to competent authorities
</p>
<p>
    <br/>
</p>
<p>
    2.7 Proof of identity documents, proof of address and transaction records will be maintained for at least six years, if submitted to regulatory authorities without prior notice to you
</p>
<p>
    <br/>
</p>
<p>
    2.8 During the entire transaction process, the credit card is prohibited from being used
</p>
<p>
    <br/>
</p>
<p>
    2.9 Participate in regular training provided by relevant authorities and regularly train employees
</p>
<p>
    <br/>
</p>
<p>
    3 Identity information and verification confirmation
</p>
<p>
    &nbsp; &nbsp;3.1 Identity Information
</p>
<p>
    <br/>
</p>
<p>
    &nbsp; &nbsp;3.1.1 According to different regulations in different jurisdictions and different types of entities, the content of your information collected by us may be inconsistent. In principle, the following information will be collected from registered individuals:
</p>
<p>
    <br/>
</p>
<p>
    &nbsp; &nbsp;Basic personal information: his/her name, residential address (and permanent address, if different), date of birth and nationality, etc. other information available. Authentication should be based on documents issued by official or other similar authorities, such as passports, ID cards or other identification documents required and triggered by different jurisdictions. The address you provide will be verified using appropriate methods, such as checking passenger transport bills or rate bills or checking the voter register
</p>
<p>
    <br/>
</p>
<p>
    Valid photo: Before you register, you must provide a photo of you holding your ID on your chest
</p>
<p>
    <br/>
</p>
<p>
    Contact: Phone/mobile number and valid email address
</p>
<p>
    <br/>
</p>
<p>
    3.1.2 If you are a company or other legal entity, we will collect the following information to identify you or the ultimate beneficiary of the trust account
</p>
<p>
    <br/>
</p>
<p>
    Company registration and registration certificate; a copy of the company&#39;s articles of association and memorandum; detailed certification materials of the company&#39;s shareholding structure and ownership statement, proving the decision to open an account on this website and the implementation of the board resolution of the authorized client; company directors as required , the identity document of the major shareholder and the authorized signatory of the account on this website; the main business address of the company, if it is different from the company&#39;s mailing address, provide the mailing address. If the company&#39;s local address is inconsistent with its principal business address, it is considered a higher risk customer and needs to submit additional additional documents
</p>
<p>
    <br/>
</p>
<p>
    Documents issued by other certifications and authorities required by us and documents we deem necessary according to different regulations in different jurisdictions and different types of entities
</p>
<p>
    <br/>
</p>
<p>
    3.1.3 We accept the identity information in English, Japanese and Korean. If not, please translate your identity information into English, Japanese and Korean and notarize it
</p>
<p>
    <br/>
</p>
<p>
    3.2 Confirmation and verification
</p>
<p>
    <br/>
</p>
<p>
    3.2.1 We require you to provide both sides of your identity document
</p>
<p>
    <br/>
</p>
<p>
    3.2.2 We ask you to provide a photo of you holding your identification document on your chest
</p>
<p>
    <br/>
</p>
<p>
    3.2.3 Copies of supporting documents should generally be checked against the original documents. However, a copy is acceptable if a reliable and appropriate certifier can certify that the copy is an accurate and complete reproduction of the original. Such certifiers include ambassadors, judicial commissioners, magistrates, etc.
</p>
<p>
    <br/>
</p>
<p>
    3.2.4 The requirement to identify ultimate beneficiaries and account control is to identify which individuals ultimately own or control the direct customer, and/or identify that ongoing transactions are performed on behalf of others. In the case of a business, the identity of major shareholders (such as those holding 10% or more voting interest) should be verified. Generally, holding 25% of the shares will be considered as normal risk, and the shareholder identity must be verified; if holding 10% or more voting rights or shares, it is considered high risk, and the shareholder identity must be verified
</p>
<p>
    <br/>
</p>
<p>
    4 Monitor transactions
</p>
<p>
    4.1 We set and adjust the daily transaction and withdrawal maximum limit from time to time according to security and actual transaction conditions
</p>
<p>
    <br/>
</p>
<p>
    4.2 If the transaction is frequently concentrated on a registered you or is beyond reasonable, our professional team will evaluate and decide whether they are suspicious
</p>
<p>
    <br/>
</p>
<p>
    4.3 We may suspend the transaction, reject the transaction and other restrictive measures if it is identified as suspicious transaction by our own judgment, and even reverse the transaction as soon as possible if possible, and report to the competent authority at the same time, but will not notify you
</p>
<p>
    <br/>
</p>
<p>
    4.4 We reserve the right to refuse boarding applications from persons from jurisdictions that do not meet international anti-money laundering standards or who could be considered politically exposed persons, and we reserve the right to suspend or terminate transactions that we judge to be suspicious at any time, but we do does not violate any obligations and responsibilities towards you
</p>
<p>
    <br/>
</p>`,
}