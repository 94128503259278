<template>
    <div class="footer-box no-margin y-3-bg y-4-bd b-2-cl" id="footer-box">
        <section>
            <div class="footer-bar">
                <div class="clearfix footer-menu">
                    <div class="footer-logo" style="max-width: 230px;padding: 0 20px;">
                        <img alt="" src="../../static/imgs/logo52.png" style="width: 100%;">
                    </div>
                    <div class="footer-template">
                        <div class="title-list">
                            <div class="f-title">{{ $t('index.footer.item1.tt') }}</div>
                            <div class="f-text">
                                <div>
                                    <router-link :to="{ name: 'Docs', query: { docs: 'otc' } }" class="x-2-cl">
                                        {{ $t('index.footer.item1.pt1') }}
                                    </router-link>
                                </div>
                                <div>
                                    <router-link :to="{ name: 'Guide', query: { key: 'k1' } }" class="x-2-cl">
                                        {{ $t('index.footer.item1.pt2') }}
                                    </router-link>
                                </div>
                                <div>
                                    <router-link :to="{ name: 'FeeHistoryInfo' }" class="x-2-cl">
                                        {{ $t('index.footer.item1.pt3') }}
                                    </router-link>
                                </div>
                                <div>
                                    <router-link :to="{ name: 'ContractInfo' }" class="x-2-cl">
                                        {{ $t('index.footer.item1.pt4') }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="title-list">
                            <div class="f-title">{{ $t('index.footer.item2.tt') }}</div>
                            <div class="f-text">
                                <div>
                                    <router-link :to="{ name: 'Docs', query: { docs: 'kyc' } }" class="x-2-cl">
                                        {{ $t('index.footer.item2.pt1') }}
                                    </router-link>
                                </div>
                                <div>
                                    <router-link :to="{ name: 'Docs', query: { docs: 'law' } }" class="x-2-cl">
                                        {{ $t('index.footer.item2.pt2') }}
                                    </router-link>
                                </div>
                                <!-- <div>
                                    <router-link :to="{name:'Asset',query:{key:'ada'}}" class="x-2-cl">
                                        {{$t('index.footer.item2.pt3')}}
                                    </router-link>
                                </div> -->
                            </div>
                        </div>
                        <div class="title-list">
                            <div class="f-title">{{ $t('index.footer.item3.tt') }}</div>
                            <div class="f-text">
                                <div>
                                    <router-link :to="{ name: 'Docs', query: { docs: 'privacy' } }" class="x-2-cl">
                                        {{ $t('index.footer.item3.pt1') }}
                                    </router-link>
                                </div>
                                <div>
                                    <router-link :to="{ name: 'Docs', query: { docs: 'protocol' } }" class="x-2-cl">
                                        {{ $t('index.footer.item3.pt2') }}
                                    </router-link>
                                </div>
                                <div>
                                    <router-link :to="{ name: 'Docs', query: { docs: 'risk' } }" class="x-2-cl">
                                        {{ $t('index.footer.item3.pt3') }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="title-list">
                            <div class="f-title">{{ $t('index.footer.item4.tt') }}</div>
                            <div class="f-text">
                                <div><a style="white-space: nowrap;" class="x-2-cl">{{ $t('index.footer.item4.pt1')
                                        }}：starthestar@starthestar.com</a>
                                </div>
                                <div><a style="white-space: nowrap;" class="x-2-cl">{{ $t('index.footer.item4.pt2')
                                        }}：starthestar@starthestar.com</a>
                                </div>
                                <div><a style="white-space: nowrap;" class="x-2-cl">{{ $t('index.footer.item4.pt3')
                                        }}：starthestar@starthestar.com</a>
                                </div>
                                <div><a style="white-space: nowrap;" class="x-2-cl">{{ $t('index.footer.item4.pt4')
                                        }}：starthestar@starthestar.com</a>
                                </div>
                                <div><a style="white-space: nowrap;" class="x-2-cl">{{ $t('index.footer.item4.pt5')
                                        }}：starthestar@starthestar.com</a>
                                </div>
                                <div><a style="white-space: nowrap;" class="x-2-cl">{{ $t('index.footer.item4.pt6')
                                        }}：starthestar@starthestar.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {

}
</script>
<style src="../../static/css/index.css" scoped type="text/css"></style>