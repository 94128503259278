export default `<p>
    <strong>KNC（カイバーネットワーク）</strong>
</p>
<p>
    <br/>
</p>
<p>
    <strong>1.カイバーネットワーク（KNC）とは</strong>
</p>
<p>
    <br/>
</p>
<p>
    Kyber Networkは、サードパーティを信頼する必要のない分散型取引所であり、暗号化された資産間の即時の変換/交換を容易にすることができます。 Kyber Networkを介して、ユーザーはトークンAを使用して、トークンBの支払いを受け取りたい対応する受信者に支払うことができ、プロセス全体が1つのステップで実行されます。それだけでなく、Kyber Networkは、特定のトークンのみを受け入れる既存のウォレットが既存または将来のトークン（PAYやCVCなど）を受け入れることを可能にする新しいスマートコントラクトインターフェイスも立ち上げました。言い換えれば、Kyber Networkは、スマートコントラクトと支払い受信者がより広い範囲のユーザーに到達し、それらのプラットフォームでサポートされている任意のトークンで支払いを受け取ることを可能にします。
</p>
<p>
    <br/>
</p>
<p>
    Kyber Networkは革新的な方法を使用して、リザーブマネージャー、リザーブコントリビューター、動的リザーブプール、リザーブを管理するスマートコントラクトなど、トークンの流動性、信頼性、即時交換を保証します。前述の各参加者は、異なる方法でスマートコントラクトと対話します。Kyberネットワークの動的予約は、ユーザーの要求に応え、任意のトークンの変換を1つのステップで実現できます。
</p>
<p>
    <br/>
</p>
<p>
    さらに、Kyber Networkでは複数の予約を共存させることができ、プラットフォームは一連の比較で最高の為替レートをユーザーに提供できます。オペレーターに加えて、Kyber Networkに関係のない個人または組織も、このシステムに参加するために登録して、予備プール全体に貢献し、個人資産の予備マネージャーになることができます。この場合、Kyber Networkは、実際には、トランザクション量の少ないトークンに対して、より多くの交換チャネルとトランザクションチャネルを提供します。 Kyber Networkは、独立したリザーブマネージャーが管理するリザーブファンドを保有していません（これらのファンドはリザーブ契約に保管されています）が、リザーブ契約がKyberNetworkプラットフォームの条件に準拠していることを確認してください。
</p>
<p>
    <br/>
</p>
<p>
    Kyber Networkは、リザーブマネージャーが他のアイドル資産をトークン化するためのプラットフォームを作成しました。ユーザーのトランザクション要求にサービスを提供することにより、予備エンティティは価格スプレッドから利益を得ます。 Kyber Networkは、ウォレットプロバイダーと協力して、他のさまざまなトークンプロジェクトと組み合わせて、より多くのトラフィックを取得し、Kyber Network内のネットワーク効果により、リザーブマネージャーはトランザクション量から直接利益を得ることができます。
</p>
<p>
    <br/>
</p>
<p>
    さらに、Kyber Networkは、リザーブマネージャーがリザーブインおよびリザーブアウトトランザクションを管理するのに役立つリザーブ情報パネルソフトウェアも提供します。リザーブ情報パネルには、リザーブマネージャーが自動的に価格を設定し、投資ポートフォリオを再調整できるように、標準的で人気のある取引アルゴリズム/戦略が含まれます。プラットフォームのこの柔軟性により、リザーブマネージャーはいつでもどこでも独自の戦略を実装および展開できます。
</p>
<p>
    <br/>
</p>
<p>
    <strong>2.一般的なリンク</strong>
</p>
<p>
    <br/>
</p>
<p>
    公式サイト：https：//kyber.network/
</p>
<p>
    ホワイトペーパー：https：//cn.kyber.network/assets/KyberNetworkWhitepaper-cn.pdf
</p>
<p>
    <br/>
</p>`