export default `<p>
    <strong>HT（Huobiトークン）</strong>
</p>
<p>
    <br/>
</p>
<p>
    HT（Huobi Global Integral Points）は、ブロックチェーンの発行と管理に基づくロイヤルティシステムであり、Huobiが発行する唯一の公式のロイヤルティポイントです。 HTは、Huobiのグローバルビジネスと、VIP手数料の割引、定期的なプラットフォームの買い戻し、認定マーチャントデポジット、Huobiポイントの独占活動、人気通貨との取引、Huobiビジネスへの参加などのフルラインの製品をサポートします。
</p>
<p>
    <br/>
</p>
<p>
    発行時間：2018-1-24
</p>
<p>
    既存の合計：228.443百万HT
</p>
<p>
    総循環：180.647百万HT
</p>
<p>
    公式サイト：https：//www.huobi.be
</p>
<p>
    ブロッククエリ：https：//etherscan.io/address/0x6f259637dcd74c767781e37bc6133cd6a68aa161
</p>
<p>
    <br/>
</p>`