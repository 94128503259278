export default `<p>
    <strong>HT (후 오비 토큰)</strong>
</p>
<p>
    <br/>
</p>
<p>
    HT (Huobi Global Integral Points)는 블록 체인 발행 및 관리를 기반으로 한 로열티 시스템으로, Huobi가 발행하는 유일한 공식 로열티 포인트입니다. HT는 후 오비의 글로벌 비즈니스와 VIP 수수료 할인, 정기 플랫폼 재구매, 인증 된 가맹점 입금, 독점 후 오비 포인트 활동, 인기 통화 거래, 후 오비 비즈니스 참여 등의 전체 제품군을 지원합니다.
</p>
<p>
    <br/>
</p>
<p>
    발행 시간 : 2018-1-24
</p>
<p>
    기존 총액 : 228.443 백만 HT
</p>
<p>
    총 유통량 : 180.647 만 HT
</p>
<p>
    공식 웹 사이트 : https://www.huobi.be
</p>
<p>
    블록 쿼리 : https://etherscan.io/address/0x6f259637dcd74c767781e37bc6133cd6a68aa161
</p>
<p>
    <br/>
</p>`