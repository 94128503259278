export default `<p>
    <strong>DASH (대시)</strong>
</p>
<p>
    <br/>
</p>
<p>
    1. 대시 란?
</p>
<p>
    에
</p>
<p>
    영어 이름 DASH 인 Dash는 즉각적인 거래, 익명 거래 및 대체 통화 기능을 제공 할 수있는 오픈 소스 P2P (Peer-to-Peer) 암호화 통화입니다.
</p>
<p>
    <br/>
</p>
<p>
    대시 네트워크는 작업 증명 마이닝 (비트 코인에서 사용하는 합의 메커니즘) 메커니즘으로 보호되며 11 개의 서로 다른 해시 함수를 기반으로하는 X11 알고리즘을 사용합니다. 마스터 노드는 서비스 증명 계층으로, 이러한 노드는 트랜잭션 믹서와 부스터 역할을 동시에 수행하며 대시 관리 시스템의 투표 메커니즘 역할도합니다.
</p>
<p>
    <br/>
</p>
<p>
    대시의 블록 보상은 채굴 자와 마스터 노드의 두 계층으로 나뉩니다. 이 중 채굴자는 수익의 45 %, 마스터 노드는 45 %, 나머지 10 %는 분산 형 대시 예산 시스템에 할당됩니다. 각 블록의 생성 시간은 2.5 분이며 각 블록은 광부 3.6 대시 보상. 세부 사항은 다음과 같습니다.
</p>
<p>
    <br/>
</p>
<p>
    2. 배경
</p>
<p>
    Bitcoin의 익명 성과 트랜잭션 처리 속도를 고려하여 Evan Duffield와 그의 팀은 DASH의 연구 및 개발을 시작했으며 2014 년 1 월에 공식적으로 Dash를 설립했습니다. 지금까지 대시는 비트 코인을 기반으로 관련 개선을 완료했으며, 분산 형 네트워크 서버 &quot;마스터 노드&quot;를 통해 트랜잭션을 난독 화하여 트랜잭션의 익명 성을 개선했습니다. 동시에 노드를 기반으로 트랜잭션 처리 속도가 향상되어 탈 중앙화되고 잘 익명화 된 Dash가 탄생했습니다.
</p>
<p>
    <br/>
</p>
<p>
    3. 특징
</p>
<p>
    높은 익명 성 : 비트 코인 거래에서 모든 거래 데이터는 데이터 블록 체인에 기록되고 비트 코인의 소재를 볼 수 있으므로 수신 및 발신 당사자를 쿼리 할 수 있습니다. 그러나 Dash는 익명 전송 기술과 분산 네트워크 서버 &quot;마스터 노드&quot;를 사용하여 트랜잭션을 난독 화하므로 트랜잭션을 추적하고 쿼리 할 수 없으므로 높은 익명 성을 달성합니다.
</p>
<p>
    에
</p>
<p>
    즉시 배송 : 비트 코인 네트워크는 거래를 확인하는 데 10 분 또는 몇 시간이 걸리며 대시는 즉시 전송 될 수 있습니다.
</p>
<p>
    에
</p>
<p>
    독립적 인 관리 시스템 : Dash Network는이 시스템을 통해 제안 및 투표를 통해 R &amp; D 및 마케팅을위한 자원을 할당 할 수 있으며, 모든 사용자가 네트워크에서 제안서를 작성하고 제출할 수 있습니다.
</p>
<p>
    에
</p>
<p>
    4. 자주 사용하는 링크
</p>
<p>
    DASH 공식 웹 사이트 : https://www.dash.org/
</p>
<p>
    블록 탐색기 : https://explorer.dash.org/chain/Dash
</p>
<p>
    지갑 다운로드 : https://www.dash.org/
</p>
<p>
    DASH 포럼 : https://www.dash.org/forum/
</p>
<p>
    트위터 : https://twitter.com/Dashpay/with_replies
</p>
<p>
    페이스 북 : https://www.facebook.com/DashPay/
</p>
<p>
    <br/>
</p>`