import request from "@/api/request"

const api = {
    getNewCurrency:'new_currency/getNewCurrency',
    getInfo:'new_currency/getInfo',
    getMyWallet:'new_currency/getMyWallet',
    exchange:'new_currency/exchange',
    getNewWallet:'new_currency/getNewWallet',
}

export function getNewCurrency(parameter) {
    return request({
        url: api.getNewCurrency,
        method: 'post',
        data: parameter
    })
}


export function getInfo(parameter) {
    return request({
        url: api.getInfo,
        method: 'post',
        data: parameter
    })
}

export function getMyWallet(parameter) {
    return request({
        url: api.getMyWallet,
        method: 'post',
        data: parameter
    })
}
export function exchange(parameter) {
    return request({
        url: api.exchange,
        method: 'post',
        data: parameter
    })
}
export function getNewWallet(parameter) {
    return request({
        url: api.getNewWallet,
        method: 'post',
        data: parameter
    })
}
