export default `<p>
    <strong>BAND (밴드 프로토콜)</strong>
</p>
<p>
    <br/>
</p>
<p>
    1. 프로젝트 이름
</p>
<p>
    BAND (밴드 프로토콜)
</p>
<p>
    <br/>
</p>
<p>
    2. 프로젝트 포지셔닝
</p>
<p>
    Band Protocol의 목표는 분산 된 세계에서 가장 안전하고 확장 가능한 데이터베이스를 생성하여 분산 된 애플리케이션 (dApp)에 신뢰할 수있는 데이터를 제공하는 것입니다. BandProtocol을 사용하는 개발자와 dApp 회사는 블록 체인과 분리 된 중앙 집중식 오라클을 기반으로하지 않고 공개 스마트 계약 데이터 포인트를 통해 데이터 소비를 수행합니다. 이러한 방식으로 dApp은 인터넷에서 기존 데이터를 사용하면서 보안을 보장하고 Web2.0과 Web3.0의 사용 사례 사이에 다리를 구축합니다.
</p>
<p>
    <br/>
</p>
<p>
    세, 통화 개요 및 배포
</p>
<p>
    총 토큰 : 100,000,000
</p>
<p>
    총 유통량 : 20,494,033
</p>
<p>
    <br/>
</p>
<p>
    네, 일반적으로 사용되는 링크
</p>
<p>
    프로젝트 웹 사이트 : https://bandprotocol.com/
</p>
<p>
    백서 : https://docs.bandchain.org/whitepaper/
</p>
<p>
    블록 쿼리 : https://cosmoscan.io/
</p>
<p>
    <br/>
</p>`