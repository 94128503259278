<template>
  <div>
    <!-- 注册信息 -->
    <div v-if="progress == 'signup'" class="route-box">
      <div class="has-bg-level-1 theme--dark view-page-user-login">
        <div class="" style="height: 850px">
          <div class="box has-bg-level-2 views-page-login-box-width px-7 pt-8 pb-7">
            <div class="myTitle mb-3">
              <div class="gang has-line-primary mr-4"></div>
              <span class="title-large has-text-white">{{
                $t("signup.title")
              }}</span>
            </div>
            <div style="color: #fff; padding: 20px 0; display: flex">
              <div :class="['sbtn', params.type == 1 ? 'act' : '']" @click="onType(1)">
                {{ $t("base.zszh") }}
              </div>
              <!-- <div :class="['sbtn', params.type == 2 ? 'act' : '']" @click="onType(2)">
                {{ $t("base.mnzh") }}
              </div> -->
            </div>
            <!-- <div class="mt-3 mb-7 body-6">
                        <span class="has-text-white">{{$t('signup.desc1')}}</span>
                        <span class="has-text-primary"> {{$t('base.title')}} </span>
                        <span class="has-text-white">{{$t('signup.desc2')}}</span>
                    </div> -->
            <!-- <div class="tabs mb-7" style="display: none">
                        <ul>
                            <li class="mr-6">
                                <span class="has-text-white cursor-pointer">手机</span>
                            </li>
                            <li>
                                <span class="has-text-white cursor-pointer is-active has-line-primary has-text-primary noneBor">邮箱</span>
                            </li>
                        </ul>
                    </div> -->
            <div class="py-0 mb-3 has-text-level-2 body-5">
              {{ params.type == 1 ? $t("login.email2") : $t("login.email3") }}
            </div>
            <input type="text" class="input" v-model="params.email" @input="() => {
              /*this.setEmailError(false)*/
            }
              " :placeholder="(params.type == 1 ? $t('login.email_desc') : $t('login.phone_desc'))" />
            <div class="pt-2" style="height: 26px">
              <div class="body-3 has-text-tip-error" v-show="email_error.show">
                {{ email_error.text }}
              </div>
            </div>
            <div class="py-0 mb-3 has-text-level-2 body-5 mt-4">
              {{ $t("login.password") }}
            </div>
            <div class="input-with-components">
              <div class="input px-1">
                <input :type="isEyes ? 'text' : 'password'" class="input without-border in-input"
                  v-model="params.password" @input="() => {
                    /*this.setPwdError(false)*/
                  }
                    " :placeholder="$t('login.password_desc')" />
                <i :class="'iconfont mx-2 ' +
                  (isEyes ? 'icon-yincang' : 'icon-zichanzhengyan')
                  " @click="() => {
                    this.isEyes = !this.isEyes;
                  }
                    "></i>
              </div>
            </div>
            <div class="pt-2" style="height: 26px">
              <div class="body-3 has-text-tip-error" v-show="pwd_error.show">
                {{ pwd_error.text }}
              </div>
            </div>
            <!-- 邀请码 -->
            <div class="py-0 mb-3 has-text-level-2 body-5 mt-4">
              {{ $t("signup.invitation_code") }}
            </div>
            <input class="input mb-5" v-model="params.invitation_code" :placeholder="$t('signup.invitation_desc')" />
            <i :class="'iconfont iconfont-medium cursor-pointer icon-Unselected ' +
              (isAgree ? 'icon-u_check-square has-text-primary' : '')
              " @click="() => {
                this.isAgree = !this.isAgree;
              }
                "></i>
            <span class="
                body-4
                views-page-register-checkbox-label
                has-text-white
                ml-1
              ">
              {{ $t("signup.accept") }}{{ $t("base.title") }}
              <router-link :to="{ name: 'Docs', query: { docs: 'protocol' } }" class="has-text-primary">《{{
                $t("signup.service") }}》</router-link>{{ $t("signup.and") }}
              <router-link :to="{ name: 'Docs', query: { docs: 'privacy' } }" class="has-text-primary">《{{
                $t("signup.policy") }}》</router-link>
            </span>
            <!--disabled=""-->
            <button class="
                button
                mt-7
                has-bg-primary
                button-large
                is-fullwidth
                has-text-white
                mb-5
              " @click="onNext">
              {{ $t("signup.signup") }}
            </button>
            <div class="has-text-centered body-4 has-text-white">
              {{ $t("signup.have_account") }}？<router-link to="login" class="has-text-primary">{{ $t("login.login")
                }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 收取验证码 -->
    <div v-if="progress == 'email'" class="route-box">
      <div class="has-bg-level-1 theme--dark view-page-user-login">
        <div class="" style="height: 850px">
          <div class="box has-bg-level-2 views-page-login-box-width px-7 pt-8 pb-7">
            <div class="title-large views-page-login-title opacity">
              {{ $t("base.title") }}
            </div>
            <div class="title-large has-text-white">
              {{ $t("valid_email.title") }}
            </div>
            <div class="py-0 mb-7 body-3 has-text-level-3">
              {{ $t("valid_email.desc") }}
            </div>
            <div class="py-0 mb-2 title-small has-text-level-2 noneWeight">
              {{ $t("valid_email.email_code") }}
            </div>
            <div class="control has-icons-right mb-6">
              <div class="input-with-components">
                <div class="input px-1">
                  <input maxlength="6" class="input without-border in-input" v-model="params.email_code"
                    :placeholder="$t('valid_email.email_desc')" />
                  <span class="line"></span>
                  <a class="body-1 views-page-login-send-code px-2" @click="sendEmail">{{
                    overTime > 0
                      ? overTime + " s"
                      : $t("valid_email.get_code")
                  }}</a>
                </div>
              </div>
            </div>
            <!-- disabled="" -->
            <button class="
                button
                my-3
                has-bg-primary
                button-medium
                is-fullwidth
                has-text-white
                mb-2
              " @click="onSignup">
              {{ $t("signup.signup") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- demo账号提示 -->
    <el-dialog :title="$t('demo.title')" :visible.sync="demoVisible" :before-close="confirmDemo"
      class="trade-dialog trade-dg" style="width: 50%; margin: 0 auto; top: 20%">
      <el-row>
        <el-col :span="24">
          <div style="
              font-size: 25px;
              display: flex;
              align-items: center;
              justify-content: center;
            ">
            {{ $t('demo.title2') }}：{{ demoEmail }}
          </div>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="cancelDemo">{{ $t("demo.cancel") }}</el-button> -->
        <el-button @click="confirmDemo">{{ $t("demo.submit") }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { signup, sendEmail, checkInvitationCode } from "@/api/auth";
export default {
  name: "Signup",
  data() {
    return {
      progress: "signup",
      //眼睛
      isEyes: false,
      //协议勾选
      isAgree: false,
      //email 错误
      email_error: {
        show: false,
        text: this.$t("message.not_null"),
      },
      pwd_error: {
        show: false,
        text: this.$t("message.not_null"),
      },
      params: {
        email: "",
        password: "",
        invitation_code: "",//默认与后台对应
        email_code: "",
        type: 1,
      },

      //倒计时
      overTimeInterval: null,
      overTime: "",

      //弹框
      demoVisible: false,
      demoEmail: "",
    };
  },
  created() {
    //带参数过来
    if (this.$route.query.username != undefined) {
      this.params.email = this.$route.query.username;
    }

    //初始化倒计时
    this.runTime();
  },
  methods: {
    confirmDemo() {
      this.demoVisible = false;
      // 前往登录
      setTimeout(() => {
        this.$router.push({
          name: "Login",
        });
      }, 1000);
    },
    onType(v) {
      this.params.type = v;
    },
    setProgress: function (val) {
      this.progress = val;
    },
    setEmailError(show) {
      this.email_error.show = show;
      this.email_error.text = this.$t("message.invalid_email");
    },
    setPwdError(show) {
      this.pwd_error.show = show;
      this.pwd_error.text = this.$t("message.invalid_password");
    },
    validParam() {
      if (!this.params.email) {
        //this.setEmailError(true)
        this.$message({
          message: this.$t("message.invalid_email"),
          type: "warning",
        });
        return false;
      }

      if (!this.params.password) {
        //this.setPwdError(true)
        this.$message({
          message: this.$t("message.invalid_password"),
          type: "warning",
        });
        return false;
      }

      if (!this.params.invitation_code) {
        this.$message({
          message: this.$t("message.invalid_invitation_code"),
          type: "warning",
        });
        return false;
      }

      if (!this.isAgree) {
        this.$message({
          message: this.$t("message.should_agree"),
          type: "warning",
        });
        return false;
      }

      return true;
    },
    onNext() {
      //如果是模拟直接注册
      if (this.params.type == 2) {
        this.onSignup();
        return;
      }

      if (!this.validParam()) {
        return;
      }

      // loading
      this.$ui.openLoading();

      //检查邀请码
      checkInvitationCode({
        invitation_code: this.params.invitation_code,
      })
        .then((res) => {
          // close
          this.$ui.closeLoading();
          if (res.code == 1) {
            this.$message({
              message: res.msg,
              type: "warning",
            });
            return;
          }

          //进入下一阶段
          this.progress = "email";
        })
        .catch(() => {
          // close
          this.$ui.closeLoading();
        });

      //进入下一阶段
      // this.progress = "email";
    },

    /**
     * 倒计时
     */
    runTime() {
      var _this = this;
      if (_this.overTimeInterval == null) {
        this.setTime();
        _this.overTimeInterval = setInterval(function () {
          //递减
          _this.setTime();
          console.log(_this.overTime, "倒计时");
          if (_this.overTime <= 0) {
            clearInterval(_this.overTimeInterval);
            _this.overTimeInterval = null;
            console.log("倒计时结束");
            return;
          }
        }, 1000);
      }
    },
    /**
     * 初始化时间
     */
    setTime() {
      // 初始化
      var endTime = parseInt(localStorage.getItem("endTime"));
      if (endTime) {
        this.overTime = parseInt(
          endTime - parseInt(new Date().getTime() / 1000)
        );
      }
    },
    startTime(time) {
      if (this.overTime <= 0) {
        var endTime = parseInt(new Date().getTime() / 1000) + time;
        localStorage.setItem("endTime", endTime);
      }
      //倒计时
      this.runTime();
    },
    /**
     * 发送验证码
     */
    sendEmail() {
      let _this = this;

      // 验证参数
      if (!this.params.email) {
        this.$message({
          message: this.$t("message.invalid_email"),
          type: "warning",
        });
        return;
      }

      if (this.overTime > 0) {
        this.$message({
          message: this.$t("message.send_email", {
            s: this.overTime,
          }),
          type: "warning",
        });
        return;
      }

      // loading
      this.$ui.openLoading();

      // 请求接口
      sendEmail({
        scene: "signup",
        email: this.params.email,
      })
        .then((res) => {
          // close loading
          _this.$ui.closeLoading();

          if (res.code == 1) {
            _this.$message({
              message: res.msg,
              type: "error",
            });
            return;
          }

          //倒计时
          _this.startTime(res.data.time);

          _this.$message({
            message: res.msg,
            type: "success",
          });
        })
        .catch((res) => {
          _this.$ui.closeLoading();
          console.log("异常错误", res);
        });
    },
    onSignup() {
      let _this = this;
      if (!this.validParam()) {
        return;
      }

      // loading
      this.$ui.openLoading();

      // 提交注册
      signup(this.params)
        .then((res) => {
          // close
          _this.$ui.closeLoading();

          if (res.code === 1) {
            this.$message({
              message: res.msg,
              type: "error",
            });
            return;
          }

          this.$message({
            message: res.msg,
            type: "success",
          });

          //删除之前的验证码倒计时
          localStorage.removeItem("endTime");
          //登录账号
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("uid", res.data.uid);
          localStorage.setItem("email", res.data.email);

          if (_this.params.type == 1) {
            // 前往登录
            setTimeout(() => {
              this.$router.push({
                name: "Login",
              });
            }, 1000);
          } else {
            _this.demoVisible = true;
            //模拟账号
            _this.demoEmail = res.data.email;
          }
        })
        .catch(() => {
          // close
          _this.$ui.closeLoading();
        });
    },
  },
};
</script>

<style src="../../static/css/signup.css" scoped type="text/css"></style>
