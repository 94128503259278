export default {
    title: "OTC取引ルール",
    content: `<p>
    <span style="font-size: 18px;"><strong>OTC取引ルール</strong></span>
</p>
<p>
    <br/>
</p>
<p>
    OTC取引ルール
</p>
<p>
    <br/>
</p>
<p>
    OTC取引の基本原則：
</p>
<p>
    1.これらの規則は、発行日から正式に実施され、随時改訂または改善されます。改訂または改善された新しい規則は、発行日から有効になります。
</p>
<p>
    2.これらのルールおよび改訂または改善されたルールに同意しない場合は、プラットフォームサービスの使用を直ちに停止してください。
</p>
<p>
    3.プラットフォームサービスを継続して利用する場合は、これらのルールおよびルールの内容を随時改訂または改善し、自主的に拘束することを十分に理解し、承諾したものとみなします。
</p>
<p>
    <br/>
</p>
<p>
    バイヤーが従うべき原則：
</p>
<p>
    注文を作成した後、購入者は次のことを行う必要があります。
</p>
<p>
    1.注文で指定された支払い時間内に支払いを完了し、[支払い済み]をクリックします。
</p>
<p>
    2.私の本名の支払い方法、注文金額、および注文で指定された回収アカウントを使用して、注文の支払いをリアルタイムで完了します。
</p>
<p>
    3.非リアルタイムの支払い方法で注文を支払うことを選択した場合は、事前に販売者の同意を得る必要があります。
</p>
<p>
    <br/>
</p>
<p>
    売り手が従うべき原則：
</p>
<p>
    注文を生成した後、売り手は次のことを行う必要があります。
</p>
<p>
    1.プラットフォームによって認定された収集アカウントを提供します。
</p>
<p>
    2.購入者から全額の支払いを受け取った後、注文で指定された時間内にリリースを完了します。
</p>
<p>
    3.購入者の同意なしに、購入者/カスタマーサービスは注文をキャンセルする必要はありません。
</p>
<p>
    4.購入者の同意なしに、作成された注文価格に異議を唱えることはできません。
</p>
<p>
    <br/>
</p>
<p>
    注：現在、非リアルタイムの到着方法には、Paypalから銀行カード、Visaから銀行カード、17：00〜9：00の50,000件を超える注文の1回の転送などがあります。各支払いチャネルのビジネスの変化に伴い、非リアルタイムの到着特定の種類の口座方法は、それに応じて調整されます。
</p>
<p>
    <br/>
</p>
<p>
    ユーザーは、マネーロンダリング防止の原則に従う必要があります。
</p>
<p>
    <br/>
</p>
<p>
    1.ユーザーは、プラットフォームの「StarthestarOTCプラットフォームのマネーロンダリング防止およびテロ対策資金調達ユーザーガイドライン」を遵守し、関連する義務を厳格に履行する必要があります。
</p>
<p>
    <br/>
</p>
<p>
    2.ユーザーは、ユーザーIDとアカウントに関するプラットフォームの実名認証管理要件に厳密に従い、プラットフォームに表示される関連するアカウントがすべて合法的に登録され、使用されていることを確認する必要があります。
</p>
<p>
    <br/>
</p>
<p>
    3.ユーザーがプラットフォームのマネーロンダリング防止リスク管理警告をトリガーする場合、ユーザーはプラットフォームのさらなるマネーロンダリング防止検証作業に積極的に協力し、関連するリスク管理証拠資料の提供を積極的に支援する必要があります（特定の対策はプラットフォームのリスク管理の対象となります）その時の監査要件）。
</p>
<p>
    <br/>
</p>
<p>
    バイヤーの例外
</p>
<p>
    <br/>
</p>
<p>
    治療法
</p>
<p>
    <br/>
</p>
<p>
    1.注文が作成された後、購入者が自発的に注文をキャンセルするか、支払いのタイムアウトのためにシステムが自動的に注文をキャンセルします。
</p>
<p>
    <br/>
</p>
<p>
    1日に4回に達すると、システムは購入者の購入機能を制限します。2日間連続してトリガーされると、システムは購入者のアカウントの一部の機能をさらに制限します。
</p>
<p>
    <br/>
</p>
<p>
    2.支払いが行われていない場合は、[支払い済み]をクリックします。
</p>
<p>
    <br/>
</p>
<p>
    売り手は注文のキャンセルを要求する権利を有します。買い手が同じ日に2回苦情を申し立てた場合、システムは買い手のアカウントの一部の機能を制限します。そのような苦情の1回の金額が50,000ファンドを超える場合、システムは直接購入者のアカウントを48時間制限します。
</p>
<p>
    <br/>
</p>
<p>
    3.売り手が規則に違反せず、買い手が支払いを怠った場合は、理由もなく[アピール]をクリックします。
</p>
<p>
    <br/>
</p>
<p>
    売り手は注文のキャンセルを要求する権利があります。買い手が同じ日に2回苦情を申し立てられた場合、システムは買い手のアカウントの一部の機能を制限します。
</p>
<p>
    <br/>
</p>
<p>
    4.販売者の注文詳細ページに表示されている受け取りアカウントに従って支払いが行われない。
</p>
<p>
    <br/>
</p>
<p>
    売り手は注文をキャンセルしてデジタル資産を売り手に返却するよう要求する権利があり、買い手は間違った支払いを転送するリスクを負います。
</p>
<p>
    <br/>
</p>
<p>
    5.お支払いがない場合は、まず「お支払い済み」をクリックしてからお支払いください。
</p>
<p>
    <br/>
</p>
<p>
    売り手は、取引を続行するか、払い戻し後に注文をキャンセルするかを選択する権利があります。
</p>
<p>
    1）販売者が取引を選択した場合、販売者ユーザーは全額の支払いを受け取ってから10分以内にリリースを完了する必要があります。
</p>
<p>
    2）販売者が注文をキャンセルすることを選択した場合、販売者は注文が上訴されてから2時間以内に払い戻しを完了する必要があり、デジタル資産は販売者に返却されます。
</p>
<p>
    <br/>
</p>
<p>
    6.プラットフォームアカウントの本名と一致する支払いアカウントを使用して支払いを行わない。
</p>
<p>
    <br/>
</p>
<p>
    販売者は、取引を直接拒否する権利を有します。販売者は、支払いを受け取ってから48時間以内に払い戻しプロセスを完了することができ、デジタル資産は販売者に返却されます。法人口座を使用して行われる支払い（法人と認定された人の本名が同じであるかどうかに関係なく）は、依然として非実名の支払いです。このような状況で購入者から2回の苦情があった場合、システムは購入者のアカウントの一部の機能を制限します。
</p>
<p>
    <br/>
</p>
<p>
    7.注文で指定された支払い時間内に支払います。
</p>
<p>
    （1）購入者がすべての支払いを完了し、注文が購入者によって自発的に、またはシステムによって時間外にキャンセルされた場合。
</p>
<p>
    （2）購入者は部分的な支払いを完了しますが、注文は購入者によって自発的に、またはシステムによって時間外にキャンセルされます。
</p>
<p>
    <br/>
</p>
<p>
    注文が買い手によって自発的にキャンセルされ、注文がシステムによって時間外にキャンセルされた場合、売り手は取引を続行するか、取引を返金するかを選択する権利があります。
</p>
<p>
    <br/>
</p>
<p>
    1.売り手が取引を行うことを選択した場合、
</p>
<p>
    -売り手は通常のユーザーであり、注文はプラットフォームによって直接再開されます。売り手は再開後10分以内にアセットのリリースを完了する必要があります。
</p>
<p>
    -販売者は広告主ユーザーであり、注文金額は50,000以下です。「注文を再開してリリース」を使用してアセットリリース操作を完了してください。注文金額が50,000を超える場合は、10分以内にアセットリリースを完了してください。注文が再開されます。
</p>
<p>
    <br/>
</p>
<p>
    2.販売者が注文をキャンセルすることを選択した場合、販売者はプラットフォームまたは購入者のユーザーからフィードバックを受け取ってから60分以内に払い戻しを完了し、デジタル資産を販売者に返却する必要があります。
</p>
<p>
    <br/>
</p>
<p>
    8.複数回の支払いが必要な場合、すべての送金が完了していない場合は、最初に[支払い済み]をクリックしてから、引き続き支払いを完了します。
</p>
<p>
    <br/>
</p>
<p>
    1）事前に売り手と連絡を取り、売り手がその行動を許可した場合、売り手は注文を完了し続ける必要があります。
</p>
<p>
    2）販売者の同意なしに、販売者は取引を継続するか、返金後に注文をキャンセルするかを選択する権利を有します。
</p>
<p>
    -販売者が取引を続行することを選択した場合、販売者はすべての支払いを受け取ってから10分以内にリリースを完了する必要があります。
</p>
<p>
    -販売者が注文をキャンセルすることを選択した場合、販売者は支払いを受け取ってから30分以内に払い戻しを完了する必要があり、デジタル資産は販売者に返却されます。
</p>
<p>
    <br/>
</p>
<p>
    9.支払い金額が注文金額と一致しません。
</p>
<p>
    <br/>
</p>
<p>
    1）支払い金額が注文金額を上回り、注文が完了した場合、売り手は支払いを受け取ってから30分以内に超過金額を返金する必要があります。
</p>
<p>
    2）支払い金額が注文金額より少ない場合、売り手は取引を続行するか、返金後に注文のキャンセルを要求するかを選択する権利があります。
</p>
<p>
    -売り手が取引を継続することを選択した場合、売り手は実際の注文金額に従って取引を完了し、注文を完了する権利があります。
</p>
<p>
    -販売者が注文をキャンセルすることを選択した場合、販売者は支払いを受け取ってから30分以内に払い戻しを完了する必要があり、デジタル資産は販売者に返却されます。
</p>
<p>
    <br/>
</p>
<p>
    10.買い手が「支払い済み」をクリックした後、売り手は1時間以内に支払いを受け取っていません。
</p>
<p>
    <br/>
</p>
<p>
    1）販売者は、取引を続行するか、払い戻し後に注文をキャンセルするかを選択する権利があります。
</p>
<p>
    -売り手が取引を行うことを選択した場合、売り手は支払いを受け取ってから10分以内にリリースを完了する必要があります。
</p>
<p>
    -販売者が注文をキャンセルすることを選択した場合、販売者は支払いを受け取ってから30分以内に払い戻しを完了する必要があり、デジタル資産は販売者に返却されます。
</p>
<p>
    <br/>
</p>
<p>
    2）販売者は広告主であり、上訴後10分以内に購入者に連絡することはできません。販売者が支払いを受け取っていないことを確認した後、オンブズマンは広告主の保証資産の範囲内で注文をキャンセルすることを優先します。
</p>
<p>
    <br/>
</p>
<p>
    11.支払いを受け取った後、売り手の受け取りアカウントはアカウントの実名情報を確認できません。
</p>
<p>
    <br/>
</p>
<p>
    1）購入者の支払いアカウントがプラットフォームの実名アカウントと一致していることを確認し、注文で指定された時間内に支払いが完了すると、通常、注文は完了します。
</p>
<p>
    <br/>
</p>
<p>
    2）購入者の支払い口座がプラットフォームの実名口座と一致していないことを確認した後、第6条の上記の措置に従って処理されます。
</p>
<p>
    <br/>
</p>
<p>
    12.買い手が支払いを行った後、売り手の回収口座が異常である場合。
</p>
<p>
    <br/>
</p>
<p>
    1）買い手が通常のアカウントの証明を提供できない場合、売り手は受け取り側のアカウントが通常のアカウントに移行されてから24時間以内に払い戻しを完了する必要があり、プラットフォームは注文をキャンセルします。
</p>
<p>
    2）購入者は、アカウントが正常であることを証明できます。購入者のアカウントが正常であることを確認した後、注文が完了し、デジタル資産を購入者に配信する必要があります。
</p>
<p>
    <br/>
</p>
<p>
    例：販売者がV-plusユーザーであり、保証された資産の範囲内で、デジタル資産が販売者に返還され、販売者は、受信アカウントが通常に移行されてから24時間以内に払い戻しを完了する必要があります。
</p>
<p>
    <br/>
</p>
<p>
    13.支払い時の注意：「デジタル通貨、BTC、ビットコイン、ShouaaGlobal」などの言葉に似ています。
</p>
<p>
    <br/>
</p>
<p>
    売り手は、取引を継続するか、取引を返金するかを選択する権利があります。
</p>
<p>
    1）販売者が取引を選択した場合、販売者は支払いを受け取ってから10分以内にリリースを完了する必要があります。
</p>
<p>
    2）売り手が返金を選択し、取引を完了しなかった場合、注文はキャンセルされ、デジタル資産は売り手に返還され、売り手は支払いを受け取ってから60分以内に払い戻しを完了します。売り手が受け取った場合したがって、アカウントは凍結され、上記の第12条の措置に従って処理されます。
</p>
<p>
    <br/>
</p>
<p>
    14.購入者のユーザーは、リスク管理システムによって、低価格の注文を悪意を持ってオークションにかけたと判断されます。
</p>
<p>
    <br/>
</p>
<p>
    1）買い手が支払いを怠った場合、プラットフォームは注文をキャンセルし、資産を売り手に返します。
</p>
<p>
    <br/>
</p>
<p>
    2）購入者が支払いを済ませました。申し立て処理から10分以内に払い戻しプロセスを完了してください。プラットフォームは、注文のキャンセルと販売者へのアセットの返却を支援します。
</p>
<p>
    <br/>
</p>
<p>
    買い手がこのように2回行動した場合、リスク管理システムは買い手のアカウントの一部の機能を制限します。
</p>
<p>
    <br/>
</p>
<p>
    注：このルールには販売者の払い戻しまたはリリースが含まれ、販売者が時間どおりに操作を完了できなかった場合、システムは販売者のアカウントの一部の機能を制限します。
</p>
<p>
    <br/>
</p>
<p>
    &nbsp; &nbsp;注：OTC取引にサードパーティの電話システムを使用する過程で、言語の競合、乱用、嘲笑、またはその他の過度の言語行動を積極的に誘発すると、システムによってアカウントの一部の機能が制限されます。
</p>
<p>
    <br/>
</p>
<p>
    売り手の例外
</p>
<p>
    <br/>
</p>
<p>
    治療法
</p>
<p>
    <br/>
</p>
<p>
    1.販売者は、注文の詳細ページに表示される受信アカウントと、注文チャットウィンドウに送信される受信アカウントに対して全責任を負います。
</p>
<p>
    <br/>
</p>
<p>
    販売者から提供された収集アカウントは私が所有していないか、他の人が他の人の収集アカウントをバインドしたり、他の人のアカウントをチャットウィンドウに送信したりするように案内されています。買い手が支払いを完了すると、資産決定は買い手に帰属します。
</p>
<p>
    <br/>
</p>
<p>
    2.注文が生成された後、注文価格に異議があります。
</p>
<p>
    <br/>
</p>
<p>
    注文価格が買い手の合意で交渉されない限り、注文は通常実行され、売り手は注文で指定された時間内にそれを解放するものとします。
</p>
<p>
    <br/>
</p>
<p>
    3.販売者から提供された回収口座が異常です。
</p>
<p>
    <br/>
</p>
<p>
    購入者は、取引を続行するか、注文をキャンセルするかを選択する権利があります。
</p>
<p>
    -買い手が取引を行うことを選択し、買い手が注文で指定された時間内に全額を支払ったことを確認した場合、売り手は支払いを受け取った後、注文で指定された時間内にリリースを完了する必要があります。
</p>
<p>
    -購入者が注文をキャンセルすることを選択した場合、販売者が払い戻しを完了した後、デジタル資産は販売者に返還されます。
</p>
<p>
    <br/>
</p>
<p>
    4.売り手が有効な支払い方法を時間内に提供しなかったため、買い手は支払いを完了できず、注文がキャンセルされました。
</p>
<p>
    <br/>
</p>
<p>
    販売者が支払い方法を提供していないため、注文を完了できません。プラットフォームがアピールを処理すると、注文が直接キャンセルされ、販売者のユーザーにペナルティが課されます。プラットフォームによる注文のキャンセルは、数をカウントしません。当日の購入者によるキャンセルの
</p>
<p>
    <br/>
</p>
<p>
    売り手は2回苦情を申し立てられており、システムは売り手のアカウントの一部の機能を制限します。
</p>
<p>
    <br/>
</p>
<p>
    5.販売者（非広告主）は、本名と一致する有効な支払い情報を拘束したり、プラットフォームの本名と一致しない支払い方法を購入者に提供したりしません。
</p>
<p>
    <br/>
</p>
<p>
    たとえば、AlipayまたはWeChatで支払いを受け取る場合、アカウント番号とQRコードの受取人の名前が注文の詳細ページに表示されている受取人の名前と一致しません。
</p>
<p>
    <br/>
</p>
<p>
    1）購入者は、注文をキャンセルしてプラットフォームに苦情を申し立てる権利があります。
</p>
<p>
    2）販売者から3回の苦情があり、システムは販売者のアカウントを恒久的に制限します。
</p>
<p>
    購入者は取引を継続することを選択するため、苦情が2回に達した場合、システムは購入者のアカウントの一部の機能を制限します。
</p>
<p>
    <br/>
</p>
<p>
    6.売り手は、プラットフォーム上で名前が同じではない買い手のアカウントから支払いを回収し、取引を拒否しません。
</p>
<p>
    <br/>
</p>
<p>
    1）そこから生じるリスクは、売り手が負担するものとします。
</p>
<p>
    2）これにより苦情があった場合、システムは販売者のアカウントの一部の機能を制限します。
</p>
<p>
    <br/>
</p>
<p>
    7.買い手が全額を支払った後、「支払い済み」とマークします。売り手が買い手から全額を受け取った後、リリースは20分以内に完了しません。
</p>
<p>
    <br/>
</p>
<p>
    購入者は、取引を続行するか、注文をキャンセルして払い戻しをリクエストするかを選択する権利があります。
</p>
<p>
    -買い手が閉鎖することを選択した場合、オンブズマンは資産が買い手に属していると判断します。
</p>
<p>
    -購入者が払い戻しをリクエストした後に注文をキャンセルした場合、販売者は30分以内に払い戻しを完了する必要があります。
</p>
<p>
    <br/>
</p>
<p>
    例：購入者が支払いをマークしてから20分以内にアピールをクリックすると、販売者はリリースできなくなり、販売者はチャットウィンドウで購入者に連絡できなくなり、注文は正常に完了します。
</p>
<p>
    <br/>
</p>
<p>
    &nbsp; &nbsp;注：OTC取引にサードパーティの電話システムを使用する過程で、言語の競合、乱用、嘲笑、またはその他の過度の言語行動を積極的に誘発すると、システムによってアカウントの一部の機能が制限されます。
</p>
<p>
    <br/>
</p>
<p>
    リスク制御例外をトリガーする
</p>
<p>
    <br/>
</p>
<p>
    治療法
</p>
<p>
    <br/>
</p>
<p>
    1.ユーザーがリスク管理システムによって異常を検出された場合、ユーザーは、プラットフォームのリスク管理に必要なさらなるマネーロンダリング防止の検証を完了することを明示的に拒否するか、積極的に協力しません。
</p>
<p>
    <br/>
</p>
<p>
    プラットフォームは、このアカウントを異常であると直接特定することを選択し、ユーザーのリスクレベルを上げる、ユーザーの協力によって提供されるリスク管理の証拠が得られるまで、そのようなユーザーのアカウントと関連するアカウントを直接凍結するなどの措置を講じる権利を有します。プラットフォームのリスク管理に合格しました。監査を管理します。
</p>
<p>
    <br/>
</p>
<p>
    2.ユーザーがShouaaGlobalOTCから他のプラットフォーム/市販グループにコインを購入して、利益やその他のリスクの高い行動のために販売することが確認された場合。
</p>
<p>
    <br/>
</p>
<p>
    プラットフォームは、状況に応じて、引き出しの遅延、取引の制限、アカウントの凍結などを含むがこれらに限定されない措置を講じることを選択する権利を有します。
</p>
<p>
    <br/>
</p>
<p>
    注：そのような動作が発見されると、進行中の注文がある場合、広告主はトランザクションを完了しないことを選択する権利があります。
</p>
<p>
    <br/>
</p>
<p>
    3.アカウントがユーザーまたは他のユーザーに代わって使用されていない：ユーザーがプラットフォームのリスク管理によってアカウントが販売、貸与、他のユーザーが使用できるようにリースされていることを確認された場合、またはコインを売買する行動がある場合他の人に代わって。
</p>
<p>
    リスクリマインダー：あなた自身の情報セキュリティに注意を払い、犯罪を犯すために他人に使用されないようにしてください。
</p>
<p>
    <br/>
</p>
<p>
    プラットフォームは、OTC取引の一時的または恒久的な制限、引き出しの遅延、引き出しの制限、またはそのようなユーザーのアカウントと関連アカウントの直接凍結を含むがこれらに限定されない1つ以上の手段を選択する権利を有します。
</p>
<p>
    <br/>
</p>
<p>
    注：そのような動作が発見されると、進行中の注文がある場合、広告主はトランザクションを完了しないことを選択する権利があります。
</p>
<p>
    <br/>
</p>
<p>
    4.プラットフォームの他のユーザーのテストカードの使用：ユーザーが注文なしでプラットフォームの他のユーザーに少額の支払いを行い、動作を合理的に説明できない場合。
</p>
<p>
    <br/>
</p>
<p>
    プラットフォームは、OTC取引の一時的または恒久的な制限、通貨の引き出しの制限、口座の凍結など、1つまたは複数の手段を選択する権利を有します。
</p>
<p>
    <br/>
</p>
<p>
    5.違法なソースからデジタル資産または資金を受け取るまたは導入する：ユーザーが他のプラットフォーム/ WeChatグループで受け取ったコインまたは資金がブラックコインまたはファンドがブラックマネーであることを合理的な常識で知っているか判断する場合、リスクを無視し、ブラックコイン/マネーフローをこのプラットフォームの他のユーザーのアカウントに直接送金します。
</p>
<p>
    <br/>
</p>
<p>
    このプラットフォームは、そのようなユーザーのアカウントおよび関連するアカウントのすべての機能を直接かつ恒久的に制限します。
</p>
<p>
    <br/>
</p>
<p>
    6.マネーロンダリングとして識別されたユーザー：ユーザーがリスク管理システムによって検出された場合、またはリスク管理スタッフによって確認された場合、ユーザーはマネーロンダリングなどの犯罪行為に直接参加または支援します。
</p>
<p>
    <br/>
</p>
<p>
    このプラットフォームは、そのようなユーザーのアカウントおよび関連するアカウントのすべての機能を直接かつ恒久的に制限します。
</p>
<p>
    <br/>
</p>
<p>
    7.司法凍結：司法当局からユーザーアカウントの凍結を要求された場合。
</p>
<p>
    <br/>
</p>
<p>
    このプラットフォームは、司法当局によって作成された司法書に厳密に従ってユーザーアカウントを凍結します。凍結期間が満了した場合、司法は凍結を継続するために凍結更新書を提示しなければなりません。凍結期間中、このプラットフォームへの凍結解除のユーザーの申請は無効です。調整については凍結機関に連絡してください。
</p>
<p>
    <br/>
</p>
<p>
    8.司法調査：ユーザーは、司法当局から、司法調査、相談、その他のフォームを含むがこれらに限定されない、このプラットフォームのアカウント情報を取得する必要があります。
</p>
<p>
    <br/>
</p>
<p>
    このプラットフォームは、そのようなユーザーのアカウントおよび関連するアカウントの一部の機能を適切に制限します。
</p>
<p>
    <br/>
</p>
<p>
    カスタマーサービスの電子メールstarthestar@starthestar.com
</p>
<p>
    <br/>
</p>`,
}