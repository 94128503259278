export default {
    pt1: "1.ユーザーがSTARTHESTARにログインした後、[アセット]をクリックし、[マイウォレット]をクリックしてマイアセットインターフェイスに入ります;",
    pimg1: "k4",
    pt2: "2. My Assetsインターフェースで、\" Deposit Coins \"をクリックします",
    pimg2: "k4",
    pt3: "3。ユーザーがデポジットする通貨を選択します。たとえば、ユーザーがBTCをデポジットする場合は、BTC通貨を選択し、他の通貨に対応する通貨を選択してから、[コピー]をクリックするか、QRコードをスキャンします。",
    pimg3: "k4",
    pt4: "4.ユーザーは、コピーしたウォレットアドレスを貼り付けるか、QRコードをスキャンして、コインを引き出す必要のあるプラットフォームに移動します。引き出しプロセスを完了し、チェーンでの処理が成功するのを待ちます。つまり、コインをSTARTHESTARプラットフォーム。（マイアセットインターフェースアカウントの状況で表示） ",
    pt5: "STARTHESTARはあなたに思い出させます:",
    pt6: "①コインを入金する際は、通貨の種類に応じたウォレットアドレスに注意してください。間違って入力しないでください。入力しないと、資産が取得されません。",
    pt7: "②USDTを預けるときは、USDTに対応するブロック（ERC20、TRC20、OMNI）に注意してください。エラーを入力しないでください。入力しないと、資産が取得されません。",
}