export default `<p>
    <strong>USDT (테더)</strong>
</p>
<p>
    <br/>
</p>
<p>
    <strong>1. USDT 란?</strong>
</p>
<p>
    USDT는 Tether가 출시 한 안정된 가치 통화 인 미국 달러 (USD)를 기반으로 한 토큰 Tether USD (이하 USDT)입니다. 1USDT = 1 미국 달러입니다. 사용자는 USDT를 사용하여 언제든지 USD와 1 : 1로 교환 할 수 있습니다. 테더는 1 : 1 예비 보증을 엄격히 준수합니다. 즉, 발행 된 모든 USDT 토큰에 대해 은행 계좌에 1 USD 자금 보증이 적용됩니다. 사용자는 투명성을 보장하기 위해 Tether 플랫폼에서 자금 조회를 수행 할 수 있습니다. 사용자는 SWIFT를 통해 미국 달러를 Tether에서 제공하는 은행 계좌로 송금하거나 거래소를 통해 USDT를 교환 할 수 있습니다. USD를 상환 할 때 역순으로 충분합니다. 사용자는 거래소에서 비트 코인을 USDT로 교환 할 수도 있습니다.
</p>
<p>
    <br/>
</p>
<p>
    USDT는 비트 코인 블록 체인에서 출시 된 옴니 레이어 프로토콜을 기반으로하는 디지털 자산입니다. USDT의 가장 큰 특징은 미국 달러와 동일한 금액이라는 점입니다. USDT는 디지털 네트워크에서 법적 통화의 복제품으로 설계되어 변동이 심한 암호 화폐 시장에서 좋은 가치를 보존하는 토큰입니다.
</p>
<p>
    <br/>
</p>
<p>
    <strong>2. 특징</strong>
</p>
<p>
    직관 : USDT와 USD는 동일하며 1USDT = 1 USD입니다. 각 통화 = 미국 달러 단위의 단가에 해당하는 USDT 수.
</p>
<p>
    <br/>
</p>
<p>
    안정성 : 천진 개발구는 법정 화폐로 뒷받침되기 때문에 사용자는 대부분의 블록 체인 자산의 가격 변동에 영향을받지 않고 블록 체인 자산 시장에서 여전히 거래 할 수 있습니다.
</p>
<p>
    <br/>
</p>
<p>
    투명성 : 천진 개발구 주화를 발행하는 회사 인 테더는 시장에서 유통되는 모든 천진 개발구 주화가 상응하는 미화로 뒷받침 될 수 있도록 법정 통화 저장 계좌를 정기적으로 감사한다고 주장합니다. 저장소 계정의 상태는 공개이며 언제든지 쿼리 할 수 있습니다. 또한 모든 천진 개발구 코인 거래 기록은 공개 체인에 게시됩니다.
</p>
<p>
    <br/>
</p>
<p>
    소액 거래 수수료 : 테더 계좌 간 거래 또는 TEDA 코인을 보관하는 지갑 간의 거래에는 수수료가 없습니다. 천진 개발구 동전을 법정 화폐로 전환하기 위해서는 거래 수수료가 필요합니다.
</p>
<p>
    <br/>
</p>
<p>
    <strong>3. 자주 사용하는 링크</strong>
</p>
<p>
    공식 웹 사이트 : https://tether.to/
</p>
<p>
    블록 쿼리 : https://www.omniexplorer.info/
</p>
<p>
    <br/>
</p>`