export default {
    title:"法執行機関の要求ポリシー",
    content:`<p style="text-align: center;">
    <span style="font-size: 18px;"><strong>法執行機関の要求ポリシー</strong></span>
</p>
<p>
    コブディグローバル
</p>
<p>
    <br/>
</p>
<p>
    starthestar.comは、世界中の法執行機関から顧客情報の要求を受け取ることがあります。これらの要求の処理方法に関する情報は、あなたと法執行機関に提供されます。
</p>
<p>
    <br/>
</p>
<p>
    法執行機関は一般に、顧客の身元に関する情報と顧客の取引活動に関する情報の2種類のデータに関心を持っています。私たちは法執行機関に積極的に協力し、認可の証拠を提供できる認可された法執行官からの法執行機関の要求にのみ対応します
</p>
<p>
    <br/>
</p>
<p>
    当社は、利用規約、プライバシーポリシー、および適用法に従って、アカウント情報の記録を国際法執行機関に開示します。 starthestar.comは、開示を提供するために、法の適正手続きに伴う法執行機関の要求を要求しています
</p>
<p>
    <br/>
</p>
<p>
    starthestar.comは、そのポリシーと手順に関する法執行機関からの質問を歓迎します。starthestar@ starthestar.comまでご連絡ください。
</p>
<p>
    <br/>
</p>
<p>
    法執行機関のリクエストを送信するときは、次の情報を含めてください。
</p>
<p>
    <br/>
</p>
<p>
    1.法執行機関の名前。
</p>
<p>
    <br/>
</p>
<p>
    2.法執行官が情報を取得する権限を与えられていることの証明（承認証明書）。
</p>
<p>
    <br/>
</p>
<p>
    3.法執行官の身分証明書（法執行機関の内部証明書）。
</p>
<p>
    <br/>
</p>
<p>
    4.法執行機関によって発行された調査レター文書。
</p>
<p>
    <br/>
</p>
<p>
    5.政府のドメイン名を含む電子メールアドレス。
</p>
<p>
    <br/>
</p>
<p>
    6.法執行に必要な情報に対応する法的主体の名前を明確にします。
</p>
<p>
    <br/>
</p>
<p>
    7.顧客情報の特定の内容を要求します。
</p>
<p>
    <br/>
</p>
<p>
    8.その他の詳細。これには、国際刑事共助条約の下で国際法執行機関が要求する合理的な期限および関連する司法文書の提供が含まれます。
</p>
<p>
    <br/>
</p>
<p>
    starthestar.comは、自身とそのクライアントを保護するために法執行機関に開示する権利を留保します。
</p>
<p>
    <br/>
</p>`,
}