import Vue from 'vue'
import App from './App.vue'
import router from './router'

// 滚动特效
import './utils/vue-animate-onscroll'

// 锚点
import './utils/vue-scrollto'

// swiper
import './utils/vue-awesome-swiper'

// 多语言
import i18n from './utils/vue-i18n'

// ui
import './utils/vue-elementUI'

// 引入公共CSS
import '../static/css/common.css'

// 复制功能
import './utils/vue-clipboard2'

Vue.config.productionTip = false

router.afterEach((to, from, next) => {
  to,from,next
  window.scrollTo(0, 0);
})

Vue.prototype.$vue = Vue

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
