export const getOverrides = (theme) => {
	var themes = {
		"white": {
			// up: "#df294a",
			up: "#2ebd85",

			down: "#df294a",
			// down: "#2ebd85",
			bg: "#fcfcfc",
			grid1: "#F1F1F1",
			grid2: "#F1F1F1",
			cross: "#23283D",
			border: "#6F87C7",
			text: "#7F8AA1",
			areatop: "rgba(71, 78, 112, 0.1)",
			areadown: "rgba(71, 78, 112, 0.02)",
			line: "#737375",
			borderLine: '#ffffff'
		},
		"black": {
			// up: "#df294a",
			up: "#2ebd85",
			down: "#df294a",
			// down: "#2ebd85",
			// bg: "#0c1b32",
			bg:"#121823",
			grid1: "#182033",
			grid2: "#182033",
			cross: "#9194A3",
			border: "#6F87C7",
			text: "#7F8AA1",
			areatop: "rgba(122, 152, 247, .1)",
			areadown: "rgba(122, 152, 247, .02)",
			line: "#737375",
			borderLine: '#0F1725'
		}
	};
	var t = themes[theme];
	return {
		"volumePaneSize": "medium",
		"scalesProperties.lineColor": t.borderLine,
		"scalesProperties.textColor": t.text,
		"paneProperties.background": t.bg,
		"paneProperties.vertGridProperties.color": t.grid1,
		"paneProperties.horzGridProperties.color": t.grid2,
		"paneProperties.crossHairProperties.color": t.cross,
		"paneProperties.legendProperties.showLegend": true,
		"paneProperties.legendProperties.showStudyArguments": !0,
		"paneProperties.legendProperties.showStudyTitles": !0,
		"paneProperties.legendProperties.showStudyValues": !0,
		"paneProperties.legendProperties.showSeriesTitle": !0,
		"paneProperties.legendProperties.showSeriesOHLC": !0,
		"paneProperties.topMargin": 20,
		"paneProperties.bottomMargin": 5,
		"mainSeriesProperties.candleStyle.upColor": t.up,
		"mainSeriesProperties.candleStyle.downColor": t.down,
		"mainSeriesProperties.candleStyle.drawWick": !0,
		"mainSeriesProperties.candleStyle.drawBorder": !0,
		"mainSeriesProperties.candleStyle.borderColor": t.border,
		"mainSeriesProperties.candleStyle.borderUpColor": t.up,
		"mainSeriesProperties.candleStyle.borderDownColor": t.down,
		"mainSeriesProperties.candleStyle.wickUpColor": t.up,
		"mainSeriesProperties.candleStyle.wickDownColor": t.down,
		"mainSeriesProperties.candleStyle.barColorsOnPrevClose": !1,
		"mainSeriesProperties.hollowCandleStyle.upColor": t.up,
		"mainSeriesProperties.hollowCandleStyle.downColor": t.down,
		"mainSeriesProperties.hollowCandleStyle.drawWick": !0,
		"mainSeriesProperties.hollowCandleStyle.drawBorder": !0,
		"mainSeriesProperties.hollowCandleStyle.borderColor": t.border,
		"mainSeriesProperties.hollowCandleStyle.borderUpColor": t.up,
		"mainSeriesProperties.hollowCandleStyle.borderDownColor": t.down,
		"mainSeriesProperties.hollowCandleStyle.wickColor": t.line,
		"mainSeriesProperties.haStyle.upColor": t.up,
		"mainSeriesProperties.haStyle.downColor": t.down,
		"mainSeriesProperties.haStyle.drawWick": !0,
		"mainSeriesProperties.haStyle.drawBorder": !0,
		"mainSeriesProperties.haStyle.borderColor": t.border,
		"mainSeriesProperties.haStyle.borderUpColor": t.up,
		"mainSeriesProperties.haStyle.borderDownColor": t.down,
		"mainSeriesProperties.haStyle.wickColor": t.border,
		"mainSeriesProperties.haStyle.barColorsOnPrevClose": !1,
		"mainSeriesProperties.barStyle.upColor": t.up,
		"mainSeriesProperties.barStyle.downColor": t.down,
		"mainSeriesProperties.barStyle.barColorsOnPrevClose": !1,
		"mainSeriesProperties.barStyle.dontDrawOpen": !1,
		"mainSeriesProperties.lineStyle.color": t.border,
		"mainSeriesProperties.lineStyle.linewidth": 1,
		"mainSeriesProperties.lineStyle.priceSource": "close",
		"mainSeriesProperties.areaStyle.color1": t.areatop,
		"mainSeriesProperties.areaStyle.color2": t.areadown,
		"mainSeriesProperties.areaStyle.linecolor": t.border,
		"mainSeriesProperties.areaStyle.linewidth": 3,
		"mainSeriesProperties.areaStyle.priceSource": "close",
		// "volumePaneSize": size || "medium",
	}
};

export const getStudiesOverrides = (theme) =>  {
	var themes = {
		"white": {
			c0: "#02c287",
			c1: "#f3736b",
			t: 30,
			v: !1
		},
		"black": {
			c0: "#02c287",
			c1: "#f3736b",
			t: 30,
			v: !1
		}
	};
	var t = themes[theme];
	return {
		"volume.volume.color.0": t.c0,
		"volume.volume.color.1": t.c1,
		"volume.volume.transparency": t.t,
		"volume.options.showStudyArguments": t.v
	}
}



export const line = {
    "mainSeriesProperties.style": 3,
    "symbolWatermarkProperties.color": "#fff",// 大灰字的颜色
    "mainSeriesProperties.areaStyle.color1": "#98C7FF",
    "mainSeriesProperties.areaStyle.color2": "#fff",
    "timeScale.barSpacing": 2, //设置每个格子的宽度，可以用来改变显示的时间区间
    "mainSeriesProperties.areaStyle.linecolor": "#6F87C7",
    "mainSeriesProperties.areaStyle.linestyle": 0,
    "mainSeriesProperties.areaStyle.linewidth": 3,
    "mainSeriesProperties.areaStyle.priceSource": "close",
    "mainSeriesProperties.candleStyle.barColorsOnPrevClose": false,
    "mainSeriesProperties.candleStyle.borderDownColor": "#22B86E",
    "mainSeriesProperties.candleStyle.borderUpColor": "#F96D46",
    "mainSeriesProperties.candleStyle.downColor": "#22B86E",
    "mainSeriesProperties.candleStyle.wickDownColor": "#22B86E",
    "mainSeriesProperties.candleStyle.wickUpColor": "#F96D46",
    "mainSeriesProperties.candleStyle.drawBorder": false,
    "mainSeriesProperties.candleStyle.drawWick": true,
    "mainSeriesProperties.candleStyle.upColor": "#F96D46",
    "mainSeriesProperties.showCountdown": false,
    "paneProperties.horzGridProperties.color": "#f8f8f8",
    "paneProperties.legendProperties.showLegend": false,
    "paneProperties.legendProperties.showSeriesTitle": true,
    "paneProperties.vertGridProperties.color": "#f8f8f8",
    "paneProperties.background": "#fff",//背景颜色,
    // "paneProperties.horzGridProperties.color": "#fff", //横向网格的颜色
	// "paneProperties.vertGridProperties.color": "#fff", //竖直网格的颜色
    volumePaneSize: "medium"
};

export const volume = {
    // "volume.volume.color.0": "#22B86E",
    // "volume.volume.color.1": "#F96D46",
	// "volume.volume.transparency": 30,


    "volume.volume.color.0": "rgba(223,41,74,1)",
    "volume.volume.color.1": "rgba(46,189,133,1)",
	"volume.volume.transparency": 10,
};

