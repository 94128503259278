export default `<p>
    <strong>LTC（ライトコイン）</strong>
</p>
<p>
    <br/>
</p>
<p>
    <strong>1.ライトコインとは何ですか？</strong>
</p>
<p>
    <br/>
</p>
<p>
    ライトコインは2011年11月9日に誕生し、「デジタルシルバー」と呼ばれています。ライトコインは、技術的にはビットコインと同じ実装原理を持っています。これは、Scryptアルゴリズムに基づく最初のネットワークデジタル通貨です。ビットコインと比較して、ライトコインは、より速いトランザクション確認時間、より高いネットワークトランザクション容量および効率を備えています。ライトコインは現在、完全な産業チェーンと十分な流動性を備えており、成熟した安全で安定した商業金融システムであることを証明するのに十分です。
</p>
<p>
    <br/>
</p>
<p>
    <strong>2.データブロックチェーン</strong>
</p>
<p>
    <br/>
</p>
<p>
    競合他社のビットコインと比較して、ライトコインデータブロックチェーンはより多くのトランザクション量を処理できます。データブロックの出力がより頻繁になるため、ネットワークはより多くのトランザクションをサポートでき、将来的にソフトウェアを変更する必要はありません。に
</p>
<p>
    したがって、マーチャントはより迅速な取引確認を取得でき、高額商品を販売する際にさらに多くの取引確認を待つことができます。
</p>
<p>
    <br/>
</p>
<p>
    <strong>3.ウォレットの暗号化</strong>
</p>
<p>
    <br/>
</p>
<p>
    ウォレットの暗号化により、ウォレットの秘密鍵のセキュリティを確保できるため、トランザクションとアカウントの残高を表示できますが、ライトコインを使用する前にパスワードを入力する必要があります。この機能は、ウイルスやトロイの木馬の侵入を防ぐだけでなく、支払い前に合法性を効果的にチェックすることもできます。
</p>
<p>
    <br/>
</p>
<p>
    <strong>4.マイニング報酬</strong>
</p>
<p>
    <br/>
</p>
<p>
    「マイナー」は現在、データブロックごとに25個のライトコインを生成しています。 4年ごとに、生産されるライトコイン通貨の量は半分に減少します（840,000データブロックごとに渡されます）。に
</p>
<p>
    したがって、ライトコインネットワークによって生成される合計金額は、ビットコインの4倍、つまり8400万ライトコインになります。
</p>
<p>
    <br/>
</p>
<p>
    ライトコインの作成と転送はオープンソースの暗号化プロトコルに基づいており、中央当局の管理の対象ではありません。ライトコインはビットコインを変えることを目指しています。それと比較して、ライトコインには3つの重要な違いがあります。
</p>
<p>
    <br/>
</p>
<p>
    （1）ライトコインネットワークは（10分ではなく）2.5分ごとにブロックを処理できるため、トランザクションの確認を高速化できます。
</p>
<p>
    <br/>
</p>
<p>
    （2）ライトコインネットワークの予想出力は8400万で、これはビットコインネットワークが発行する通貨の4倍です。
</p>
<p>
    <br/>
</p>
<p>
    （3）Litecoinは、ColinPercivalによって最初に提案されたscrypt暗号化アルゴリズムをプルーフオブワークアルゴリズムで使用します。
</p>
<p>
    <br/>
</p>
<p>
    2017年4月、ライトコインコミュニティは合意に達することを決議し、分離検証ソフトフォークを介してブロックチェーンをアップグレードすることを決定しました。同年6月、ライトコインライトニングネットワークが正式に発足しました。
</p>
<p>
    <br/>
</p>
<p>
    <strong>5.詳細なパラメータ</strong>
</p>
<p>
    <br/>
</p>
<p>
    中国語名：ライトコイン英語名：ライトコイン英語略語：LTC
</p>
<p>
    <br/>
</p>
<p>
    開発者：Charlie Leeコアアルゴリズム：Scryptリリース日：2011/10/7
</p>
<p>
    <br/>
</p>
<p>
    ブロック時間：150秒/ブロック総発行数：8400万半減時間：4年
</p>
<p>
    <br/>
</p>
<p>
    コンセンサスプルーフ：POW難易度調整：2016ブロックブロック報酬：最初は50LTC、現在は25LTC
</p>
<p>
    <br/>
</p>
<p>
    主な機能：大循環：ビットコインの循環の4倍;高速トランザクション：確認時間はわずか2.5分;分離検証のアクティブ化
</p>
<p>
    <br/>
</p>
<p>
    短所：ライトコイン関連のアプリケーションはまだ少ない
</p>
<p>
    <br/>
</p>
<p>
    リスク：分離検証がアクティブ化された後、ネットワークの脆弱性のリスク
</p>
<p>
    <br/>
</p>
<p>
    <strong>6.一般的なリンク</strong>
</p>
<p>
    <br/>
</p>
<p>
    公式サイト：https：//litecoin.org/
</p>
<p>
    <br/>
</p>
<p>
    ブロッククエリ：http：//explorer.litecoin.net/
</p>
<p>
    <br/>
</p>`