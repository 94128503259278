export default `<p>
    <strong>BSV（ビットコインSV）</strong>
</p>
<p>
    <br/>
</p>
<p>
    1.プロジェクト名
</p>
<p>
    ビットコインSV（BSV）
</p>
<p>
    <br/>
</p>
<p>
    2.プロジェクトのポジショニング
</p>
<p>
    ビットコインSVは、ビットコインキャッシュ（BCH）の完全なノード実現です。 チームは、ビットコインキャッシュ（BCH）マイニングの巨人CoinGeekや他のマイナーの要求に応じて、ビットコインSVを作成しました。これは、マイナーに明確なビットコインキャッシュ（BCH）の実装オプションを提供し、企業がその堅固で信頼性の高い基盤のアプリやWebサイトを構築できるようにすることを目的としています。
</p>
<p>
    <br/>
</p>
<p>
    3、通貨の概要と流通
</p>
<p>
    合計トークン：21,000,000 BSV
</p>
<p>
    <br/>
</p>
<p>
    4つの一般的に使用されるリンク
</p>
<p>
    プロジェクトのウェブサイト：https：//bitcoinsv.io/
</p>
<p>
    <br/>
</p>`