export default `<p>
    <strong>DOT（ポルカドット（DOT））</strong>
</p>
<p>
    <br/>
</p>
<p>
    1.プロジェクト名
</p>
<p>
    ポルカドット（DOT）
</p>
<p>
    <br/>
</p>
<p>
    2.プロジェクトのポジショニング
</p>
<p>
    ポルカドットは、ユーザーが完全に制御できる完全に分散化されたインターネットを実現します。インターネットとは、中央機関の影響を受けることなく、すべての人のIDとデータを自分で管理することであると考えています。
</p>
<p>
    ポルカドットは、プライベートチェーン、コンソーシアムチェーン、パブリックチェーン、オープンネットワーク、オラクル、およびまだ作成されていない将来のテクノロジーを接続することを目的としています。ポルカドットはインターネットに便利さを提供し、独立したブロックチェーンはポルカドットのリレーチェーンを介して信頼できない方法で情報とトランザクションを交換することができます。
</p>
<p>
    Polkadotを使用すると、分散型のアプリケーション、サービス、および機関の作成/接続がこれまでになく簡単になります。イノベーターがより良いソリューションを構築できるようにすることで、私たちは、大規模な機関が私たちの信頼を侵害することができない壊れたネットワークに依存することから社会を解放しようとしています。
</p>
<p>
    に
</p>
<p>
    3、通貨の概要と流通
</p>
<p>
    トークンの合計：1,000,000,000 DOT（分割後）
</p>
<p>
    総循環：-
</p>
<p>
    トークン価格：-
</p>
<p>
    に
</p>
<p>
    4つの一般的に使用されるリンク
</p>
<p>
    プロジェクトのウェブサイト：https：//polkadot.network/
</p>
<p>
    ホワイトペーパー：https：//polkadot.network/PolkaDotPaper.pdf
</p>
<p>
    ブロッククエリ：https：//polkadot.js.org/apps/#/explorer
</p>
<p>
    <br/>
</p>`