import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const DEFAULT_LANG = "en-us";
const LOCALE_KEY = "lang";

const locales = {
  "en-us": require("/src/lang/en-us"),
  ja: require("/src/lang/ja"),
  ko: require("/src/lang/ko"),
  "zh-cn": require("/src/lang/zh-cn"),
  "no-no":require("/src/lang/no-no"),
  "it-it":require("/src/lang/it-it"),
  "fr-fr":require("/src/lang/fr-fr"),
  "es-es":require("/src/lang/es-es"),
  "de-de":require("/src/lang/de-de"),
  "nl-nl":require("/src/lang/nl-nl"),
  "fi-fi":require("/src/lang/fi-fi"),
  "da-dk":require("/src/lang/da-dk"),
  "sv-se":require("/src/lang/sv-se"),
};

//console.log(JSON.stringify(locales["en-us"]));

const i18n = new VueI18n({
  locale: DEFAULT_LANG,
  messages: locales
});

export const setup = (lang) => {
  if (lang === undefined) {
    lang = window.localStorage.getItem(LOCALE_KEY);
    if (locales[lang] === undefined) {
      lang = DEFAULT_LANG;
    }
  }
  window.localStorage.setItem(LOCALE_KEY, lang);

  Object.keys(locales).forEach((lang) => {
    document.body.classList.remove(`lang-${lang}`);
  });
  document.body.classList.add(`lang-${lang}`);
  document.body.setAttribute("lang", lang);

  Vue.config.lang = lang;
  i18n.locale = lang;
};
setup();
const lang_str = {
  "zh-cn": "简体中文",
  "en-us": "English",
  ja: "日本語",
  ko: "한국어"
};
export const getLanguage = () => {
  return { text: lang_str[i18n.locale], key: i18n.locale };
};
export default i18n;
