//zh.js
module.exports = {
  base: {
    title: 'STARTHESTAR',
    desc1: '契約の王',
    desc2: '契約取引のグローバルリーダー',
    d1: '信頼',
    d2: '正直',
    d3: '力',
    more: '続きを見る',
    top: 'トップに戻る',
    download: 'アプリをダウンロードする',
    zszh: 'リアルアカウント',
    mnzh: 'デモ口座'
  },
  lang: {
    title: '日本語'
  },
  message: {
    invalid_email: '無効なメール',
    invalid_password: '無効なパスワード',
    invalid_new_password: '新しいパスワードが無効です',
    invalid_res_password: '確認パスワードが無効です',
    invalid_email_code: '無効なメール確認コード',
    password_inconsistent: '一貫性のないパスワード',
    invalid_invitation_code: '無効な招待コード',
    should_agree: '登録条件を読んで同意してください',
    not_null: 'フィールドを空にすることはできません',
    send_email: '操作する前に{:s}秒待ってください',
    invalid_number: '無効な値',

    invalid_rate: '複数選択してください',
    invalid_rule: 'ルールを選択してください',
    invalid_system_rule: 'システムにルールはありません',

    not_enough: '十分に利用できません',
    price_high: '価格は{price}より高くなければなりません',
    price_low: '価格は{price}未満である必要があります',

    number_high: '引き出しの最大数は{num}です',
    number_low: '最小引き出し額は{num}です',
    copy_address: 'アドレスを正常にコピーしました {text}',

    invalid_upload_front: '身分証明書の正面写真をアップロードしてください',
    invalid_upload_back: '身分証明書の裏面の写真をアップロードしてください',
    invalid_upload_hand: '手持ちの証明写真をアップロードしてください',
    invalid_upload_name: '証明書の名前を入力してください',
    invalid_upload_card: 'ID番号を入力してください',
    upload_pic_type: 'アップロードする画像は JPG、PNG 形式のみです',
    upload_pic_size: 'アップロード画像のサイズは 10MB を超えることはできません'
  },
  index: {
    title2: 'グローバル市場の無料取引24時間ノンストップ',
    desc2: 'プラットフォーム全体で',
    desc2_1: 'の24時間取引量で、非常に流動性の高い市場で取引戦略を実装します',
    list: {
      name: '市場',
      h24_price: '24時間最新価格',
      h24_change: '24時間変更',
      h24_high: '24時間最高価格',
      h24_low: '24時間最低価格',
      h24_vol: '24時間ボリューム'
    },
    footer: {
      desc: `米国に本社を置くThissatBlockchain Technology Ltdは、世界をリードするブロックチェーンテクノロジー企業です。同社は米国のMSB国際金融ライセンスを取得しています。これは、グローバル規模のブロックチェーンビジネスサービスに準拠して運用できる、準拠したブロックチェーンテクノロジーサービス企業です。 。 同時に、テセサットブロックチェーンテクノロジーリミテッドの技術チームの才能は、ユーザーの資金とアカウントのセキュリティを完全に保護するために、一流の国際的な科学研究機関とブロックチェーン技術開発チームから来ています。技術チームに加えて、同社にはプロのブロックチェーンチームもあります：デジタル資産AI定量的投資チーム、ブロックチェーンプロジェクト価値評価チーム、デジタル資産ベンチャー投資チームなどは、グローバルなブロックチェーンユーザーにサービスを提供するためのグローバルなレイアウトを持っています。
            同社の目標とビジョンは、グローバルなデジタル資産愛好家に安全で安定した、思いやりのある、信頼できるトランザクションサービスを提供し、プロフェッショナルでデータベースの国際的に有名なグローバルブロックチェーンテクノロジー企業を構築することです。`,

      item1: {
        tt: '製品ディスプレイ',
        pt1: 'OTC取引ルール',
        pt2: '初心者向けガイド',
        pt3: 'レート標準',
        pt4: '契約の説明'
      },
      item2: {
        tt: 'サポート',
        pt1: 'KYCとマネーロンダリング防止協定',
        pt2: '施行要求ポリシー',
        pt3: 'デジタルアセットの紹介'
      },
      item3: {
        tt: '規約',
        pt1: 'プライバシー保護',
        pt2: 'ユーザー契約',
        pt3: 'リスク通知'
      },
      item4: {
        tt: 'お問い合わせ',

        pt1: '事業協力',
        pt2: '機関の協力',
        pt3: 'OTCアプリケーション',
        pt4: 'カスタマーサービスの電子メール',
        pt5: '苦情と提案',
        pt6: 'メディア協力'
      }
    },
    bk3: {
      title: '革新的な研究開発力の誇り',
      desc: '金融トレーダー向けに設計された想像を絶する契約取引経験',

      pt1: 'オープンオーダーのストッププロフィットとストップロス',
      pd1: '保留中の注文は遅れることはありません。注文を見ないことを心配してください',

      pt2: '逆オープンオーダー',
      pd2: '市場は突然変化します。1秒で追いついてください',

      pt3: '倉庫ごとに完全な倉庫をサポートする',
      pd3: 'やりたいことは何でもしなさい、やりたいことは何でもしなさい',

      pt4: 'フルポジションの段階的清算',
      pd4: '外国の清算は最初は半分に等しい。投資家が可能な限り損失を減らすのを助けるように努めなさい'
    },
    bk5: {
      title: '契約取引プラットフォームの8つの利点」',
      desc: '私たちは常に耳を傾け、継続的に最適化と改善を行い、グローバルな取引ユーザーのために一流の取引体験を生み出します」',

      pt1: '市場の深さ',
      pd1: 'プラットフォームの最初の3つの屋台の市場の深さは数百万ドルのオーダーに達し、業界の平均市場の深さである10,000と100,000をはるかに超えています。スプレッドは小さく、トランザクションは高速です。',

      pt2: 'スムーズなトランザクション',
      pd2: '効率的なマッチングトランザクションエンジンを使用してスムーズなトランザクションエクスペリエンスを保証し、1秒あたり数百万の注文を処理でき、2,000万人を超える同時オンラインユーザーによるスムーズで安定した運用をサポートできます。',

      pt3: '適正価格',
      pd3: '「マーク価格」は、世界の3大取引所のリアルタイム取引価格を回帰計算の指標価格として選択し、最新の取引価格の異常な操作によるユーザーの損失を短期間で効果的に防止します。',

      pt4: '自己開発技術',
      pd4: '伝統的な金融に根ざしたマッチメイキングエンジンは、金融業界の上級専門家のチームによって開発され、取引プラットフォームの安全で安定した効率的な運用を保証するために細部まで細心の注意を払って作成されました。',

      pt5: 'トップリスクコントロール',
      pd5: '第2レベルの判断、多層暗号化、高いシステムセキュリティ。ホットウォレットとコールドウォレットの組み合わせ、軍用グレードの暗号化対策、資産トランザクションのセキュリティが保証され、ユーザーのデジタル資産のセキュリティが100％保証されます。',

      pt6: '資金のセキュリティ',
      pd6: '3層セキュリティシステム、包括的なセキュリティ監査。業界をリードする階層的決定論的コールドウォレットシステムを自己構築し、すべてのユーザーがリチャージアドレスをコールドウォレットアドレスに割り当て、資金の収集と引き出しはオフライン署名を通じて完了します。',

      pt7: 'ハードコアパワー',
      pd7: '何万ものBTCリザーブ、金融革新遺伝子、そして世界のトップマネジメントチーム。starthestar は、システム上の理由でアカウントとトランザクションデータのエラーが発生しなければ、エラーが発生した場合にプラットフォームが全額支払うことを約束します！',

      pt8: '質の高いサービス',
      pd8: '7 * 24時間のプロフェッショナルカスタマーサービス、迅速な対応。サービスの考え方から始めて、グローバルな顧客に一流の取引体験を提供します。究極の体験は、顧客にサービスを提供するというコンセプトから生まれます。'
    },
    bk6: {
      title: '安全とコンプライアンスのグローバルオペレーション',
      desc: '監督を受け入れ、常にグローバルコンプライアンスオペレーションを順守する'
    },
    bk7: {
      title: '公式戦略パートナー'
    },
    bk8: {
      title: '10万人以上のグローバルトレーディングユーザーにサービスを提供',
      desc: '現在、ユーザーは米国、日本、韓国、セイシェル、ベトナム、その他の国々にいます'
    },
    bk9: {
      title: 'いつでもどこでもスムーズな取引',

      pt1: 'リアルタイムのマッチメイキング取引、入出金は数秒で到着します',
      pt2: '複数の取引モード、最大125倍のレバレッジ',

      dt1: 'iOSバージョンのダウンロード',
      dt2: 'Androidバージョンのダウンロード'
    },
    about: {
      title: '私たちに関しては',

      pt1: 'プラットフォームコンセプト',
      pd1:
        'グローバル資産を自由に循環させ、新しい契約体験を生み出しましょう。X-xenogeneticは革新的な遺伝子を表し、業界の革新に安定した力を提供します。M-motorは目標を表し、目標を前進し続けます.EX-exchangeは、取引プラットフォームに代わって、サービス、リスニング、思考の運用哲学を備えた、公正で透明性のある効率的な取引エコシステムの構築に取り組んでいます。',

      pt2: 'コアバリュー',
      pd2: '私たちは常に耳を傾け、注意を払い、前向きに考え、公正で透明かつ効率的な取引エコシステムの構築に取り組んでいます。グローバルなトレーダーに優れた取引体験を提供し、トレーダーの実際のニーズを満たし、デジタル資産取引市場。 ',

      pt3: 'チームの背景',
      pd3: '創設チームは、トップブロックチェーン投資家、伝統的な金融エグゼクティブ、トップデジタル資産の専門家、伝統的な金属加工技術チームで構成されています。R＆Dチームのコアメンバーは、モーガンスタンレー、CICC、ハンセン、テンセント、ノアウェルスです。'
    }
  },
  start: {
    p_title: 'トランザクション、ここから開始',
    p_desc: 'すぐに登録するには、次のエリアにメールアドレスを入力してください',
    p_email: 'Eメール',
    p_btn: '作成'
  },
  download: {
    p_title: 'APPクライアントのダウンロード',
    p_desc: 'いつでも、どこでも、マルチプラットフォームの端末トランザクション',
    p_btn: 'ダウンロード',
    p_scan_android: 'Android app',
    p_scan_ios: 'IOS app'
  },
  downpage: {
    t1: {
      p_title: 'Lightningトランザクションパフォーマンス',
      p_desc: '選択可能なさまざまなトランザクションタイプを提供します。注文の照合は非常に高速で、ワンクリックのフラッシュ交換であり、取引はより便利です'
    },
    t2: {
      p_title: 'アセットの簡単な転送',
      p_desc: 'アカウント内の資産の簡単な転送、デジタル資産のリアルタイムの概要、通貨比率の円グラフの増加'
    },
    t3: {
      p_title: 'カスタムトレーディングパネル',
      p_desc: 'さまざまな取引習慣に従って取引レイアウトを自由に定義し、Kラインと深度マップのエントリを増やします'
    },
    t4: {
      p_title: 'アカウント統合管理',
      p_desc: '複数のセキュリティ検証設定、1つのアカウントでC2Cアカウントと通貨アカウントを管理'
    }
  },
  login: {
    login: 'ログインする',
    title: 'ユーザーログイン',
    desc1: 'ログインしてください',
    desc2: '公式ウェブサイト',
    email: 'メールボックス',
    email2: '電子メール',
    email3: '電話番号',
    email_desc: 'メールアドレスを入力してください',
    phone_desc: 'Please enter phone number',
    password: 'パスワード',
    password_desc: 'パスワードを入力してください',
    forgot_password: 'パスワードをお忘れですか',
    no_account: 'アカウントはまだありません'
  },
  signup: {
    signup: '登録済み',
    title: 'ユーザー登録',
    desc1: 'ログインしてください',
    desc2: '公式ウェブサイト',
    invitation_code: '招待コード',
    invitation_desc: '招待コードを入力してください',
    accept: '受け入れる',
    service: 'ユーザー規約',
    and: 'と',
    policy: '個人情報保護方針',
    have_account: 'すでにアカウントをお持ちですか'
  },
  retrieve: {
    ptitle: 'パスワードの取得',
    pemail_code: 'メール確認コード',
    pset: 'パスワードの設定',
    set_title: 'パスワードの設定',
    set_desc: 'パスワードをリセットしています。次の設定を行ってください',
    new_password: '新しいパスワード',
    password_desc: '新しいパスワードを入力してください',
    res_passowrd: 'パスワードの確認',
    res_passowrd_desc: '確認パスワードを入力してください'
  },
  valid_email: {
    title: '登録確認',
    desc: 'アカウントを登録しています。次の確認を完了してください',
    email_code: 'Eメール確認コード',
    email_desc: '確認コードを入力してください',
    get_code: '確認コードを取得する'
  },
  ihead: {
    begin_trade: '取引を開始します',
    helper: '助ける新しい手',
    text: 'ドキュメントセンター',
    new_currency: '新しい通貨のサブスクリプション'
  },
  uhead: {
    title1: 'コモディティ',
    title2: '国際外国為替',
    title3: '国際索引',
    currency: 'デジタル通貨',
    lever: 'レバレッジ取引',
    coins: '通貨取引',
    contract: '契約取引',
    contract_info: '契約情報',
    helper: '助ける新しい手',
    text: 'ドキュメントセンター',
    assets: '資産',
    u_wallet: '私の財布',
    u_contract: '契約アカウント',
    u_lever: '証拠金口座',
    u_account: '口座情報',
    u_logout: 'サインアウト'
  },
  news: {
    label: 'ニュース',
    update: '更新日'
  },
  guide: {
    label: 'ビギナーズガイド',
    list: [
      {
        ptitle: 'STARTHESTAR への登録方法',
        pkey: 'k1'
      },
      {
        ptitle: 'ID認証',
        pkey: 'k2'
      },
      {
        ptitle: 'ログインパスワードとトランザクションパスワードの設定',
        pkey: 'k3'
      },
      {
        ptitle: 'デポジットコイン',
        pkey: 'k4'
      },
      {
        ptitle: 'コイン取引プロセス',
        pkey: 'k5'
      },
      {
        ptitle: '撤退',
        pkey: 'k6'
      },
      {
        ptitle: 'モバイルアプリのダウンロード',
        pkey: 'k7'
      }
    ],
    content: {
      k1: require('./currency/ja/guide/k1.js').default,
      k2: require('./currency/ja/guide/k2.js').default,
      k3: require('./currency/ja/guide/k3.js').default,
      k4: require('./currency/ja/guide/k4.js').default,
      k5: require('./currency/ja/guide/k5.js').default,
      k6: require('./currency/ja/guide/k6.js').default,
      k7: require('./currency/ja/guide/k7.js').default
    }
  },
  kline: {
    h24_high: '24時間最高',
    h24_low: '24時間最小',
    h24_vol: '24時間ボリューム',
    commission: '現在の手数料',
    record: '取引記録',
    commission_list: '委任リスト',
    deal_list: '最新の取引',
    price: '価格',
    number: '量',
    total: '合計',
    time: '時間',
    currency: '通貨',
    type: 'の種類',
    direction: '方向',
    multiple: '複数',
    fee: '手数料',
    amount: '一時金',
    status: '状態',
    operate: 'オペレーティング',
    buy: '購入',
    sell: '売る',
    pending: '保留中の注文',
    trade_success: '成功したトランザクション',
    retract: '撤退',
    profit: '利益を得る',
    loss: '損失を止める',

    all: 'すべて',
    all_currency: 'すべての通貨',
    new_price: '最新価格',
    change: '上昇と下降',
    protocol: 'プロトコル',
    _status1: 'レビュー中',
    _status2: '引き出しは成功しました',
    _status3: '拒否されました'
  },
  coins: {
    title: '通貨取引',
    market: '市場価格',
    limit: '制限価格',
    profit: '利益を得る',
    loss: '損失を止める',
    price: '価格',
    current: '電流',
    price_desc: '価格を入力してください',
    number: '量',
    number_desc: '数量を入力してください',
    available: '利用可能',
    half: 'ハーフウェアハウス',
    all: 'フルウェアハウス',
    fee: '手数料',
    amount: '取引金額',
    buy: 'バイイン',
    sell: '売る'
  },
  lever: {
    title: 'レバレッジ取引',
    multiple: '複数',
    multiple_desc: '複数選択してください',
    double: 'タイムズ',
    buy: 'バイイン(上昇)',
    sell: '売る(秋)'
  },
  contract: {
    title: '契約取引',
    time: '時間',
    time_desc: '時間をお選びください',
    minute: '分',
    second: '2番目',
    buy: 'バイイン(上昇)',
    sell: '売る(秋)',
    rate: 'ロイヤリティ比率'
  },
  wallet: {
    title: '私の資産',
    valuation: '総資産評価',
    my_wallet: '私の財布',
    my_trade: '当座預金口座',
    my_transfer: '引き出し記録',
    my_deposit: 'コイン入金記録',
    cbdz: '入金アドレス',
    CBSL: '数量',
    deposit: 'コインを入金する',
    withdrawal: '撤退',
    search: '検索する',
    currency: '通貨',
    protocol: 'プロトコル',

    liquidation: 'クローズポジション',
    liquidation_title: 'クイック清算',
    trade_pwd: '取引パスワード',
    enter_trade_pwd: '取引パスワードを入力してください',
    submit_liquidation: 'ポジションをすぐに閉じる',

    amount: '一時金',
    available: '利用可能',
    freeze: '氷結',
    market: '評価',
    ljsy: '累計収入',
    zrsy: '昨日の収益',
    operate: 'オペレーティング',
    countdown: '秒読み',

    lever_title: '証拠金口座',
    contract_title: '契約アカウント',
    direction: '方向',
    bond: 'マージン',
    buy_rate: '複数',
    profit: '変動損益',
    profit1: '利益と損失',
    buy: '購入',
    sell: '売る',
    many: '購入',
    empty: '売る',

    contract_time: '契約時間',
    premium: 'プレミアム',
    minute: '分',

    address: '住所',
    address_desc: '住所を入力してください',
    number: '量',
    number_desc: '出金金額を入力してください',
    copy: 'コピー',
    confirm: '決定する',

    all: 'すべて',
    fee: '手数料',
    actual: '実際に到着しました',

    premark: '注意',
    text_1: 'このアドレスは{name}アセットのみを受け入れます。他の通貨をリチャージしないでください。そうしないと、アセットが取得されません',
    text_2: '{name}マイナーの料金は：現在の市場価値{rate}％/トランザクション、最低基準：{min}{name} /トランザクション',
    text_3: '譲渡する前に、受取人の住所情報が正しいことを確認してください。資産が譲渡されると、資産を引き出すことはできません。',
    text_4: '{name} のマイニング料金は次のとおりです: {val} {name}/ペン'
  },
  record: {
    title: '資金調達記録',
    type: 'の種類',
    all_currency: 'すべての通貨',
    all_type: 'いろんなタイプ',
    select: 'お問い合わせ',
    clear: 'クリアフィルター',
    receive: '保存',
    transfer: '引き出し',
    buy: 'バイイン',
    sell: '売る',
    normal: '通常の手数料',
    pending: '利益と損失を止める'
  },
  user: {
    account: 'アカウント認証',
    account_desc: '預金、取引、その他の機能は、最初にアカウントの確認に合格する必要があります',

    password: 'ログインパスワード',
    password_desc: 'アカウントのセキュリティを保護するために使用されます',

    modify: '変更する',
    set: 'セットアップ',

    find_paypwd: 'パスワードの取得',
    find_pay_pwd: '支払いパスワードを取得する',
    email: 'メール',
    enter_email: 'メールアドレスを入力してください',
    email_code: '確認コード',
    enter_email_code: 'メール確認コードを入力してください',
    send: '送信',

    pay_password: '支払いパスワード',
    pay_password_desc: '取引時の資産の安全性を保護するために使用されます',

    not_certified: '認定されていません',
    certification: '認証',
    verified: '検証済み',
    rejected: '拒否されました',

    update_pwd: 'ログインパスワードの変更',
    update_pay_pwd: '支払いパスワードの変更',
    set_pay_pwd: '支払いパスワードの設定',

    old_pwd: '元のパスワード',
    enter_old_pwd: '元のパスワードを入力してください',

    new_pwd: '新しいパスワード',
    enter_new_pwd: '新しいパスワードを入力してください',

    res_pwd: 'パスワードの確認',
    enter_res_pwd: '確認パスワードを入力してください',

    submit: '送信'
  },
  certified: {
    title: 'アカウント認証',
    front: 'IDカードの前面',
    back: 'IDカードの裏面',
    hand: 'IDカードを持っている',
    name: '名前',
    name_desc: '名前を入力してください',
    card: '識別番号',
    card_desc: 'ID番号を入力してください',
    submit: '参加する'
  },
  trade: {
    title: 'トランザクションパスワード',
    enter_password: 'トランザクションパスワードを入力してください',
    cancel: 'キャンセル',
    submit: '参加する'
  },
  revoke: {
    title: 'プロンプトメッセージ',
    message: '注文を取り下げてもよろしいですか？',
    cancel: 'キャンセル',
    submit: '確認'
  },
  coins_assets: {
    title: '通貨資産',
    total_equity: '総勘定資本',
    equity: 'アカウントエクイティ',
    number: '保有数',
    market_value: '現在の評価'
  },
  lever_assets: {
    title: 'レバレッジ資産',
    total_equity: '総勘定資本',
    equity: 'アカウントエクイティ',
    profit: '未実現損益',
    risk: '危険'
  },
  contract_assets: {
    title: '契約資産',
    total_equity: '総勘定資本',
    equity: 'アカウントエクイティ',
    profit: '未実現損益'
  },

  //文档菜单
  contract_menu: {
    contract: '契約内容',
    lever: '詳細を活用する',
    coins: '通貨指数',
    history: '資金調達率の履歴',
    coins_rules: [
      {
        value: '1D',
        name: 'あの日'
      },
      {
        value: '1W',
        name: '1週間'
      },
      {
        value: '1M',
        name: '今月'
      },
      {
        value: '1J',
        name: '今シーズン'
      }
    ],
    lever_info: '情報を活用する',
    contract_info: '契約情報'
  },

  //合约详情
  contract_info: {
    bzj: 'マージン',

    pt1: '決済通貨',
    pt2: '契約額面',
    pt3: '最小変更価格',
    pt4: '契約期間',
    pt5: 'ファンドレート時間',
    pt6: '表示価格',
    pt7: 'ポジションのボリューム',
    pt8: '24時間の取引量',
    pt9: '特権',
    pt10: '満期配達収入',
    pt11: 'オープンポジションスプレッド',
    pt12: '配達と決済のメカニズム',
    pt13: '取引手数料',
    pt14: 'ロック位置',

    pv5: '毎日08：0016：00 24:00',
    pv10_1: 'ロングポジション:(平均終値-平均始値）x 量',
    pv10_2: 'ショートポジション:(平均始値-平均終値）x 量',
    pv11: '番号',
    pv12: '満期時の自動配達',
    pv13: '番号',
    pv14: 'サポートしません'
  },

  //杠杆详情
  lever_info: {
    title: 'レバレッジの詳細',
    bei: '回',

    pt1: '決済通貨',
    pt2: '契約額',
    pt3: '最小変更価格',
    pt4: '契約期間',
    pt5: 'ファンドレート時間',
    pt6: '表示価格',
    pt7: 'ポジションのボリューム',
    pt8: '24時間の取引量',
    pt9: 'リスクリザーブ',
    pt10: '清算利益',
    pt11: 'オープンポジションスプレッド',
    pt12: '自動清算メカニズム',
    pt13: '取引手数料',
    pt14: 'ロック位置',

    pv4: '持続可能な',
    pv5: '毎日08:00 16:00 24:00',
    pv10_1: 'ロングポジション:(平均終値-平均始値）*数量',
    pv10_2: 'ショートポジション:(平均始値-平均終値）*数量',
    pv12: '準備リスク率が80％に達する',
    pv13: '契約価値',
    pv14: 'サポートされていません'
  },

  //币币信息
  coins_desc: require('./currency/ja/coins.js').default,

  //资金费率历史
  fun_history: {
    t1: '日付',
    t2: '推定資金調達率',
    t3: '実際の資金調達率'
  },

  // 文档
  docs: {
    list: [
      {
        name: 'OTC取引ルール',
        key: 'otc'
      },
      {
        name: 'KYCおよびマネーロンダリング防止協定',
        key: 'kyc'
      },
      {
        name: '法執行機関の要求ポリシー',
        key: 'law'
      },
      {
        name: 'プライバシー保護',
        key: 'privacy'
      },
      {
        name: 'ユーザー規約',
        key: 'protocol'
      },
      {
        name: 'リスク通知',
        key: 'risk'
      }
    ],
    otc: require('./currency/ja/otc').default,
    kyc: require('./currency/ja/kyc').default,
    law: require('./currency/ja/law').default,
    privacy: require('./currency/ja/privacy').default,
    protocol: require('./currency/ja/protocol').default,
    risk: require('./currency/ja/risk').default
  },

  // 数字资产介绍
  asset: {
    title: 'デジタル資産の紹介',
    list: 'デジタル資産のリスト'
  },
  num_asset: require('./currency/ja/asset').default,

  //新币认购
  new_currency: {
    _title: '新しい通貨のサブスクリプション',
    _all: 'すべて',
    _progress: '進行中',
    _end: '以上',
    _success: 'Exchangeが成功しました',
    _progress_str: '進捗状況',
    _total_str: 'リリースされた合計',
    _date_time: '開始日と終了日',
    _submit: '今すぐ利用',

    _base_title: '基本情報',
    _status: 'ステータス',
    _currency: '承認された通貨',
    _symbol: '通貨記号',
    _amount: '全面禁止解除',
    _price: '初期価格',
    _limit: '個人購入制限',
    _limit_amount: '個人購入制限',
    _limit_one: '個人の単一制限の下限',
    _no_limit: '制限なし',
    _position: '位置',
    _begin_time: '開始時間',
    _end_time: '終了時間',
    _redeemed: '償還額',
    _surplus: '余剰',
    _wallet: 'ウォレット',
    _have: '現在開催中',
    _input: '入力数量',
    _choose: '選択してください',

    _new_wallet: '新しい通貨口座',
    _new_price: '最新価格',
    _lock: 'ロック期間',

    _login: 'ログインしてください',
    _no_data: 'データなし',
    _avg_price: '平均価格'
  }
}
