<template>
<div class="left-box">
    <ul class="list-ul">
        <!-- <router-link :to="{name:'ContractInfo'}">
            <li :class="{active:current == List.contract}">{{$t('contract_menu.contract')}}</li>
        </router-link> -->
        <router-link :to="{name:'LeverInfo'}">
            <li :class="{active:current == List.lever}">{{$t('contract_menu.lever')}}</li>
        </router-link>
        <router-link :to="{name:'CoinsInfo'}">
            <li :class="{active:current == List.coins}">{{$t('contract_menu.coins')}}</li>
        </router-link>
        <router-link :to="{name:'FeeHistoryInfo'}">
            <li :class="{active:current == List.history}">{{$t('contract_menu.history')}}</li>
        </router-link>
    </ul>
</div>
</template>

<script>
const List = {
    contract: 1,
    lever: 2,
    coins: 3,
    history: 4,
}
export default {
    data() {
        return {
            List,
            current: List.contract,
        }
    },
    created() {
        //初始化
        this.Init()
    },
    methods: {
        Init() {
            let name = this.$route.name
            console.log(name)
            if (name == "ContractInfo") {
                this.current = List.contract
            } else if (name == "LeverInfo") {
                this.current = List.lever
            } else if (name == "CoinsInfo") {
                this.current = List.coins
            } else if (name == "FeeHistoryInfo") {
                this.current = List.history
            }
            console.log(name)
        },
    }
}
</script>

<style src="../../static/css/left_item.css" scoped type="text/css"></style>
