import request from "@/api/request"

const api = {
    getNewsList:'news/getNewsList',
    getNewsInfo:'news/getNewsInfo',
}

export function getNewsList(parameter) {
    return request({
        url: api.getNewsList,
        method: 'post',
        data: parameter
    })
}
export function getNewsInfo(parameter) {
    return request({
        url: api.getNewsInfo,
        method: 'post',
        data: parameter
    })
}

