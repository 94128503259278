<template>
  <main role="main">
    <div class="container-divider"></div>
    <div class="container">
      <nav class="sub-nav">
        <ol class="breadcrumbs">
          <li title="STARTHESTAR">
            <a href="/hc/zh-cn">STARTHESTAR</a>
          </li>
          <li title="新手指引">
            新手指引
          </li>
        </ol>
        <form role="search" class="search" data-search="" action="/hc/zh-cn/search" accept-charset="UTF-8" method="get">
          <input name="utf8" type="hidden" value="✓">
          <input type="search" name="query" id="query" placeholder="搜索" aria-label="搜索">
        </form>
      </nav>

      <div class="category-container">
        <div class="category-content">
          <header class="page-header">
            <h1>新手指引</h1>
          </header>
          <div class="section-tree">
            <section class="section">
              <h2 class="section-tree-title">
                <a href="javascript:">操作指引</a>
              </h2>
              <ul class="article-list">
                <li class="article-list-item">
                  <router-link :to="{name:'HContent'}" class="article-list-link">
                    STARTHESTAR iOS端 APP下载教程
                  </router-link>
                </li>

                <li class="article-list-item">

                  <a href="javascript:"
                     class="article-list-link">如何注册STARTHESTAR</a>

                </li>

                <li class="article-list-item">

                  <a href="javascript:"
                     class="article-list-link">如何绑定邮箱</a>

                </li>

                <li class="article-list-item">

                  <a href="javascript:"
                     class="article-list-link">如何绑定手机号</a>

                </li>

                <li class="article-list-item">

                  <a href="javascript:"
                     class="article-list-link">如何绑定谷歌验证器</a>

                </li>

                <li class="article-list-item">

                  <a href="javascript:"
                     class="article-list-link">如何进行身份认证</a>

                </li>

              </ul>

              <a href="javascript:" class="see-all-articles">
                查看所有 10 篇文章
              </a>


            </section>

            <section class="section">
              <h2 class="section-tree-title">
                <a href="javascript:">充币提币</a>
              </h2>

              <ul class="article-list">

                <li class="article-list-item">

                  <a href="javascript:"
                     class="article-list-link">如何充值</a>

                </li>

                <li class="article-list-item">

                  <a href="javascript:"
                     class="article-list-link">如何提币</a>

                </li>

              </ul>


            </section>

            <section class="section">
              <h2 class="section-tree-title">
                <a href="javascript:">C2C交易</a>
              </h2>

              <ul class="article-list">

                <li class="article-list-item">

                  <a href="javascript:"
                     class="article-list-link">C2C商家申请流程说明</a>

                </li>

                <li class="article-list-item">

                  <a href="javascript:"
                     class="article-list-link">C2C广告方服务协议</a>

                </li>

                <li class="article-list-item">

                  <a href="javascript:"
                     class="article-list-link">C2C广告方合作条款</a>

                </li>

                <li class="article-list-item">

                  <a href="javascript:"
                     class="article-list-link">C2C交易规则</a>

                </li>

                <li class="article-list-item">

                  <a href="javascript:"
                     class="article-list-link">如何购买数字资产</a>

                </li>

                <li class="article-list-item">

                  <a href="javascript:"
                     class="article-list-link">如何卖出数字资产</a>

                </li>

              </ul>

              <a href="javascript:" class="see-all-articles">
                查看所有 8 篇文章
              </a>


            </section>

            <section class="section">
              <h2 class="section-tree-title">
                <a href="javascript:">小白数字资产100问</a>
              </h2>

              <ul class="article-list">

                <li class="article-list-item">

                  <a href="javascript:"
                     class="article-list-link">小白数字资产100问｜二、常见篇</a>

                </li>

                <li class="article-list-item">

                  <a href="javascript:"
                     class="article-list-link">小白数字资产100问｜行话篇</a>

                </li>

                <li class="article-list-item">

                  <a href="javascript:"
                     class="article-list-link">小白数字资产100问|
                    市场篇（上）</a>

                </li>

                <li class="article-list-item">

                  <a href="javascript:"
                     class="article-list-link">小白数字资产100问|
                    市场篇（下）</a>

                </li>

                <li class="article-list-item">

                  <a href="javascript:"
                     class="article-list-link">小白数字资产100问｜五、交易篇（上）</a>

                </li>

                <li class="article-list-item">

                  <a href="javascript:"
                     class="article-list-link">小白数字资产100问｜五、交易篇（下）</a>

                </li>

              </ul>

              <a href="javascript:"
                 class="see-all-articles">
                查看所有 9 篇文章
              </a>


            </section>

            <section class="section">
              <h2 class="section-tree-title">
                <a href="javascript:">视频讲解</a>
              </h2>

              <ul class="article-list">

                <li class="article-list-item">

                  <a href="javascript:"
                     class="article-list-link">STARTHESTAR合约平台视频大讲堂</a>

                </li>

              </ul>


            </section>

          </div>
        </div>
      </div>
    </div>

  </main>
</template>

<script>
export default {
  name: "Index",
}
</script>
<style src="../../../static/css/helpbox.css" scoped type="text/css"></style>