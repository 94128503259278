export default {
    pt1:"1.用户登录STARTHESTAR后，点击“资产”后在点“我的钱包“”进入我的资产界面；",
    pimg1:"k6",
    pt2:"2.在我的资产界面，点击“提币”，",
    pimg2:"k6",
    pt3:"3.用户选择你将提币的币种，例如用户将提币BTC，就选择BTC币种，提币其他币种就选择对应的币种，然后将需要存币的平台中对应的充币地址粘贴到“地址”栏，填入需要提币的数量，点击“确定”。",
    pimg3:"k6",
    pt4:"4.用户完成提币流程操作后，等待STARTHESTAR平台确认提币信息。（注意：提款金额目前设置最低1000USD，提币申请请勿多次提交，用户可在我的资产界面查看自己是否有冻结资产，从而判断是否完成提币操作，等待平台处理后，冻结资产即会清零，表示平台已通过提币申请，完成提币）。",
    pt5:"STARTHESTAR温馨提示您：",
    pt6:"①提币时注意币种对应存币平台的钱包地址，请勿填错，否则资产将无法找回。",
    pt7:"②提币USDT时，注意USDT对应的区块（ERC20、TRC20、OMNI），请勿填错，否则资产将无法找回。",
}