<template>
    <div class="page-content page-content-chain" style="position: relative; min-height: 800px;">
        <div class="innovation">
            <div class="page-banner">
                <h2 class="title f-8-cl">{{ $t("new_currency._title") }}</h2>
            </div>
            <div class="innovation-content">
                <ul class="c_list_nav c-5-bd" style="line-height: 55px;">
                    <li :class="{ 'f-1-cl': status == 0 }" style="margin-right: 46px; min-width: auto;"
                        @click="changeStatus(0)">
                        <span>
                            {{ $t("new_currency._all") }}
                            <b class="activeLine c-8-bg" v-show="status == 0"></b>
                        </span>
                    </li>
                    <li :class="{ 'f-1-cl': status == 2 }" style="margin-right: 46px; min-width: auto;"
                        @click="changeStatus(2)">
                        <span>
                            {{ $t("new_currency._progress") }}
                            <b class="activeLine c-8-bg" v-show="status == 2"></b>
                        </span>
                    </li>
                    <li :class="{ 'f-1-cl': status == 3 }" style="margin-right: 46px; min-width: auto;"
                        @click="changeStatus(3)">
                        <span>
                            {{ $t("new_currency._end") }}
                            <b class="activeLine c-8-bg" v-show="status == 3"></b>
                        </span>
                    </li>
                    <!-- <li :class="{'f-1-cl':status==4}" style="margin-right: 46px; min-width: auto;" @click="changeStatus(4)">
                    <span>
                        {{$t("new_currency._success")}}
                        <b class="activeLine c-8-bg" v-show="status==4"></b>
                    </span>
                </li> -->
                </ul>
                <div class="innovation-list">
                    <div class="innovation-item c-4-bg" v-for="(item, index) in list" :key="index">
                        <div class="img">
                            <img :src="item.currency.icon" alt="">
                        </div>
                        <div class="info" style="cursor: pointer;">
                            <h2 class="tit f-1-cl">
                                {{ item.currency.name }}
                                <label
                                    :class="'statusLabel  ' + (item.status == 2 ? 'f-71-cl f-71-bd' : 'f-7-cl f-7-bd')">{{ getStatusStr(item.status) }}</label>
                            </h2>
                            <div class="bf">
                                <p>
                                    <!-- <span>
                                    {{$t("new_currency._progress_str")}}
                                </span> -->
                                    <span class="f-r">
                                        {{ $t("new_currency._total_str") }}
                                    </span>
                                </p>
                                <div class="jdt d-5-bg">
                                    <div class="jdt-bar c-8-bg" style="width: 0%;"></div>
                                </div>
                                <div class="f-1-cl">
                                    <div style="width:40px;height:40px;" v-if="item.mp4 != ''">
                                        <i class="el-icon-video-play play-icon" style="font-size:35px"
                                            @click="openVideo(item.mp4)"></i>
                                    </div>
                                    <!-- <span>{{item.progress}}%</span> -->
                                    <span class="f-r">
                                        {{ item.total }}
                                        {{ item.currency.name }}
                                    </span>
                                </div>
                            </div>
                            <div class="baipishu">
                                <a :href="item.paper_en_url" target="_blank">
                                    <div class="img">
                                        <!-- <img src="../../../static/css/new/HTA.png" alt=""> -->
                                        <img :src="item.paper_en_icon" alt="">
                                    </div>
                                </a>
                            </div>
                            <div class="cz">
                                <div class="timer" v-if="item.status == 2">
                                    <span class="tim">
                                        {{ $t("new_currency._date_time") }}
                                    </span>
                                    <span class="val f-1-cl">
                                        <br>
                                        {{ item.begin_time }}
                                        -
                                        {{ item.end_time }}
                                    </span>
                                </div>
                                <div class="timer" v-if="item.status == 3">
                                    <span class="tim">
                                        {{ $t("new_currency._end") }}
                                    </span>
                                </div>
                                <router-link :to="{ name: 'NewTrade', query: { id: item.id } }" style="color: #FFF;">
                                    <button class="common-button common-button-solid u-8-bg u-16-cl  u-8-bg"
                                        style="width: 120px; height: 40px; padding-left: 0px; padding-right: 0px; margin-top: 0px;"
                                        v-if="item.status == 2">
                                        <div class="common-button-slot">

                                            <span>{{ $t("new_currency._submit") }}</span>

                                        </div>
                                        <!---->
                                    </button>
                                </router-link>
                            </div>
                            <div class="text c-5-bd">

                            </div>
                        </div>
                    </div>
                    <!---->
                </div>
                <div class="no-data" v-if="list.length <= 0">
                    <svg aria-hidden="true" class="icon icon-50">
                        <svg id="icon-g_2" viewBox="0 0 1024 1024" width="100%" height="100%">
                            <path d="M 122.88 0 h 778.24 v 841.032 H 122.88 Z" fill="#2d384d"></path>
                            <path
                                d="M 245.76 163.84 h 532.48 v 40.96 H 245.76 Z m 0 102.4 h 532.48 v 40.96 H 245.76 Z m 0 102.4 h 532.48 v 40.96 H 245.76 Z"
                                fill="#3f4d66"></path>
                            <path
                                d="M 0 512 h 332.882 c 31.396 95.314 92.221 142.991 182.477 142.991 S 661.38 607.314 682.68 512 H 1024 v 512 H 0 V 512 Z"
                                fill="#263043"></path>
                        </svg>
                    </svg>
                    <p>
                        {{ $t('new_currency._no_data') }}
                    </p>
                </div>
            </div>
        </div>
        <!--  -->
        <el-dialog :visible.sync="dialogVisible" width="40%" :before-close="handleClose">
            <span>
                <video-player v-if="dialogVisible" :options="playerOptions"
                    style="width:100%;height:100%;"></video-player>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {
    getNewCurrency
} from '@/api/new_currency'
import 'video.js/dist/video-js.css';
import {
    videoPlayer
} from 'vue-video-player'
export default {
    name: "NewCurrency",
    components: {
        videoPlayer
    },
    data() {
        return {
            list: [],
            status: 0,

            dialogVisible: false,
            playerOptions: {
                autoplay: true,
                muted: false,
                language: 'en',
                playbackRates: [0.7, 1.0, 1.5, 2.0],
                sources: [{
                    type: "video/mp4",
                    // src: "https://mp4.starthestar.com/8dcdbbb4b4e244e5bacf1291d1962aa0/0bc94fcd25834eb894767bfa2be63b6c-f5b51b25ef97c31afa118fd4e48eb24e-hd.mp4",
                }],
                poster: require("../../assets/ahe.png"),
            }
        }
    },
    created() {
        //
        this.getNewCurrency()
    },
    methods: {
        handleClose(done) {
            done()
        },
        openVideo(url) {
            console.log(url)
            this.playerOptions.sources[0].src = url
            this.dialogVisible = true
        },
        goTrade(id) {
            this.$router.push({
                name: "NewTrade",
                query: {
                    id: id
                }
            })
        },
        getNewCurrency() {
            let _this = this
            getNewCurrency({
                status: this.status
            }).then(res => {
                console.log(res)
                _this.list = res.data
            })
        },
        changeStatus(status) {
            this.status = status
            this.getNewCurrency()
        },
        getStatusStr(status) {
            switch (status) {
                case 2:
                    return this.$t('new_currency._progress')
                case 3:
                    return this.$t('new_currency._end')
                case 4:
                    return this.$t('new_currency._success')
            }
            return this.$t('new_currency._progress')
        }
    }
}
</script>

<style src="../../../static/css/new/index.css" scoped type="text/css"></style>
<style scoped>
.play-icon:hover {
    font-size: 40px !important;
    color: #13b887;
}
</style>
