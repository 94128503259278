export default {
    title:"privacy protection",
    content:`<p style="text-align: center;">
    <span style="font-size: 18px;"><strong>Privacy Policy</strong></span>
</p>
<p>
    Starthestar
</p>
<p>
    <br/>
</p>
<p>
    &nbsp; &nbsp; Starthestar has understood the importance of users&#39; personal information, and will comply with relevant laws and regulations such as the &quot;Personal Information Protection Law&quot;, and always respect and protect the security of users&#39; personal information. In order to provide better access to utilities or services, we will collect, store, maintain and use your personal information. Therefore, Starthestar discloses how we use and store your personal information to ensure security through our privacy policy.
</p>
<p>
    <br/>
</p>
<p>
    1 Gather information
</p>
<p>
    &nbsp; &nbsp;When you register, trade or use our services, you agree to our collection of your personal information, that is, we have obtained your permission. In addition, Starthestar will not use fraudulent or improper means to collect your personal information, nor will it ask you to provide relevant information beyond the scope of the terms. The main purpose of our collection of your personal information is to facilitate communication with you and provide better services. The collected personal information may include: name, contact information, e-mail address, transaction information, a copy of the identity information you provided, Government-issued ID numbers, passport numbers, any information related to your device or Internet services (such as IP address and MAC number), and when using special services, you may be asked to provide information such as bank accounts.
</p>
<p>
    <br/>
</p>
<p>
    2 Purpose:
</p>
<p>
    We use your personal information to:
</p>
<p>
    <br/>
</p>
<p>
    2.1 The summary of your transaction process in Starthestar, we will process the personal information you provide to us
</p>
<p>
    <br/>
</p>
<p>
    2.2 Verify and confirm the real information to avoid fraudulent behavior of relevant users;
</p>
<p>
    <br/>
</p>
<p>
    2.3 Verification of legitimate citizens, we have adopted international standards to prevent money laundering and other improper practices
</p>
<p>
    <br/>
</p>
<p>
    2.4 Immediately notify you of revisions to relevant terms and policies
</p>
<p>
    <br/>
</p>
<p>
    2.5 Timely communication in emergency situations;
</p>
<p>
    <br/>
</p>
<p>
    2.6 Follow the requirements of relevant departments of national government agencies
</p>
<p>
    <br/>
</p>
<p>
    2.7 Provide support for user personalized service
</p>
<p>
    <br/>
</p>
<p>
    2.8 Facilitate communication with Starthestar, get immediate access to related services and get prizes during the event
</p>
<p>
    <br/>
</p>
<p>
    2.9 The need for relevant laws and regulations
</p>
<p>
    <br/>
</p>
<p>
    2.10 Analyze the operation of Starthestar in order to provide better functions and products
</p>
<p>
    <br/>
</p>
<p>
    3 Personal information security protection
</p>
<p>
    &nbsp; Starthestar will not use your personal information for other purposes. Without your permission, Starthestar will not sell or disclose information to any third party (except third-party security partners related to services); Starthestar will use your personal information Decentralized storage of information ensures the security of information to deal with the risk of loss, destruction, tampering or leakage; Starthestar encrypts and manages your information through SSL technology, and only you can use and modify it without authorization. , other people will be restricted from accessing your information; in addition, the Starthestar team is autonomous, which will protect the security of your personal information to the greatest extent, and will not leak, tamper, etc.
</p>
<p>
    <br/>
</p>
<p>
    4 Personal Information Settings
</p>
<p>
    &nbsp; &nbsp;You can log in to Starthestar at any time to view, add, modify, and delete personal information. In special circumstances, you may need to contact us by email to make changes. If you do not agree to the Privacy Policy, the related services provided by Starthestar may be terminated
</p>
<p>
    <br/>
</p>
<p>
    5 Statement
</p>
<p>
    &nbsp; &nbsp; &nbsp;5.1 In order to ensure that you have full confidence in our handling of personal data, you must read and understand the terms of the Privacy Policy in detail. In particular, once you log on to our website, whether or not you are registered on the website, you indicate to us that you accept, agree, undertake and confirm:
</p>
<p>
    <br/>
</p>
<p>
    5.2 You voluntarily disclose personal data to us with the required consent
</p>
<p>
    <br/>
</p>
<p>
    5.3 You will abide by all terms and restrictions of this Privacy Policy
</p>
<p>
    <br/>
</p>
<p>
    5.4 You agree that we collect your information through your login to this website, registration on this website and/or use of the services we provide; you agree to any future revisions to our Privacy Policy
</p>
<p>
    <br/>
</p>
<p>
    6 Privacy Issues and Questions
</p>
<p>
    &nbsp; &nbsp; &nbsp;Your ID and password are kept by you personally. Please keep your password safe at all times and do not easily disclose it to others. If you find that your password is leaked, information is abnormal, or have any questions or suggestions about Starthestar&#39;s privacy policy, please contact us in the following ways Contact us at starthestar@starthestar.com.
</p>
<p>
    <br/>
</p>`,
}