export default `<p>
    <strong>LTC (라이트 코인)</strong>
</p>
<p>
    <br/>
</p>
<p>
    <strong>1. 라이트 코인은 무엇입니까?</strong>
</p>
<p>
    <br/>
</p>
<p>
    라이트 코인은 2011 년 11 월 9 일에 탄생했으며 &quot;디지털 실버&quot;라고 불립니다. Litecoin은 기술적으로 Bitcoin과 동일한 구현 원칙을 가지고 있습니다. Scrypt 알고리즘을 기반으로 한 최초의 네트워크 디지털 화폐로, 비트 코인에 비해 라이트 코인은 거래 확인 시간이 더 빠르며, 네트워크 거래 용량과 효율성이 높습니다. 라이트 코인은 이제 완전한 산업 체인과 충분한 유동성을 가지고있어 성숙하고 안전하며 안정적인 상업 금융 시스템임을 증명하기에 충분합니다.
</p>
<p>
    <br/>
</p>
<p>
    <strong>2. 데이터 블록 체인</strong>
</p>
<p>
    <br/>
</p>
<p>
    경쟁사 인 비트 코인에 비해 라이트 코인 데이터 블록 체인은 더 많은 거래량을 처리 할 수 있습니다. 데이터 블록의 출력이 더 빈번하기 때문에 네트워크는 더 많은 트랜잭션을 지원할 수 있으며 향후 소프트웨어를 수정할 필요가 없습니다. 에
</p>
<p>
    따라서 판매자는 더 빠른 거래 확인을 얻을 수 있으며 고가 상품을 판매 할 때 더 많은 거래 확인을 기다릴 수 있습니다.
</p>
<p>
    <br/>
</p>
<p>
    <strong>3. 지갑 암호화</strong>
</p>
<p>
    <br/>
</p>
<p>
    지갑 암호화는 지갑에있는 개인 키의 보안을 보장 할 수 있으므로 거래 및 계정 잔액을 볼 수 있지만 라이트 코인을 사용하려면 암호를 입력해야합니다. 이 기능은 바이러스 및 트로이 목마의 침입을 막을 수있을뿐만 아니라 결제 전 적법성 여부를 효과적으로 확인합니다.
</p>
<p>
    <br/>
</p>
<p>
    <strong>4. 채굴 보상</strong>
</p>
<p>
    <br/>
</p>
<p>
    &quot;광부&quot;는 현재 데이터 블록 당 25 개의 라이트 코인을 생산합니다. 4 년마다 생성되는 라이트 코인 통화의 양은 절반으로 감소합니다 (모든 840,000 개의 데이터 블록이 통과 됨). 에
</p>
<p>
    따라서 라이트 코인 네트워크가 생산하는 총 금액은 비트 코인의 4 배, 즉 8,400 만 라이트 코인이 될 것입니다.
</p>
<p>
    <br/>
</p>
<p>
    라이트 코인의 생성 및 전송은 오픈 소스 암호화 프로토콜을 기반으로하며 중앙 기관의 관리 대상이 아닙니다. 라이트 코인은 비트 코인을 바꾸는 것을 목표로하고 있으며, 이에 비해 라이트 코인은 세 가지 중요한 차이점이 있습니다.
</p>
<p>
    <br/>
</p>
<p>
    (1) 라이트 코인 네트워크는 10 분이 아닌 2.5 분마다 블록을 처리 할 수 있으므로 더 빠른 거래 확인을 제공 할 수 있습니다.
</p>
<p>
    <br/>
</p>
<p>
    (2) 라이트 코인 네트워크의 예상 출력은 8,400만이 며 이는 비트 코인 네트워크에서 발행 한 통화량의 4 배입니다.
</p>
<p>
    <br/>
</p>
<p>
    (3) 라이트 코인은 작업 증명 알고리즘에서 Colin Percival이 처음 제안한 scrypt 암호화 알고리즘을 사용합니다.
</p>
<p>
    <br/>
</p>
<p>
    2017 년 4 월 라이트 코인 커뮤니티는 합의에 도달하기로 투표하고 격리 검증 소프트 포크를 통해 블록 체인을 업그레이드하기로 결정했습니다. 같은 해 6 월 라이트 코인 라이트닝 네트워크가 공식적으로 시작되었습니다.
</p>
<p>
    <br/>
</p>
<p>
    <strong>5. 세부 매개 변수</strong>
</p>
<p>
    <br/>
</p>
<p>
    중국어 이름 : Litecoin 영어 이름 : Litecoin 영어 약어 : LTC
</p>
<p>
    <br/>
</p>
<p>
    개발자 : Charlie Lee Core Algorithm : Scrypt 출시 날짜 : 2011/10/7
</p>
<p>
    <br/>
</p>
<p>
    블록 시간 : 150 초 / 블록 총 발행 : 8400 만 반감 시간 : 4 년
</p>
<p>
    <br/>
</p>
<p>
    합의 증명 : POW 난이도 조정 : 2016 블록 블록 보상 : 초기 50LTC, 현재 25LTC
</p>
<p>
    <br/>
</p>
<p>
    주요 기능 : 대량 유통 : 비트 코인 유통의 4 배, 빠른 거래 : 확인 시간은 단 2.5 분, 격리 검증 활성화
</p>
<p>
    <br/>
</p>
<p>
    단점 : 라이트 코인 관련 애플리케이션은 여전히 적습니다
</p>
<p>
    <br/>
</p>
<p>
    위험 : 격리 확인이 활성화 된 후 네트워크 취약성 위험
</p>
<p>
    <br/>
</p>
<p>
    <strong>6. 공통 링크</strong>
</p>
<p>
    <br/>
</p>
<p>
    공식 웹 사이트 : https://litecoin.org/
</p>
<p>
    <br/>
</p>
<p>
    블록 쿼리 : http://explorer.litecoin.net/
</p>
<p>
    <br/>
</p>`