export default {
    pt1: "1. 사용자가 STARTHESTAR에 로그인 한 후\"계정 정보 \"를 클릭하여 계정 정보 인터페이스로 들어갑니다.",
    pimg1: "k3",
    pt2: "2. 결제 비밀번호 필드에서\"설정 \"을 클릭하여 결제 비밀번호 설정 인터페이스로 들어갑니다.",
    pimg2: "k3",
    pt3: "3. 비밀번호를 입력하고 비밀번호를 다시 입력 한 다음\"제출 \"을 클릭하여 결제 비밀번호를 성공적으로 설정합니다.",
    pimg3: "k3",
    pt4: "4. 계정 정보 인터페이스에서 로그인 비밀번호를 수정하고 로그인 비밀번호 필드에서\"수정 \"을 클릭하여 로그인 비밀번호 수정 인터페이스를 입력하십시오.",
    pimg4: "k3",
    pt5: "5. 원래 암호를 입력 한 다음 새 암호를 입력하고 새 암호를 확인한 다음\"제출 \"을 클릭하여 로그인 암호 수정을 완료합니다.",
    pimg5: "k3",
    pt6: "STARTHESTAR에서 알려줍니다 : STARTHESTAR 계정의 결제 비밀번호와 로그인 비밀번호를 올바르게 유지하십시오.",
}