export default `<h2 style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:48px">
<strong><span style="font-family: 宋体;font-size: 16px;background: rgb(255, 255, 255)">TRX (Tron)</span></strong>
</h2>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: &#39;Times New Roman&#39;">&nbsp;</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: &#39;Times New Roman&#39;"><span style="font-family:宋体">一、项目名称</span></span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">波场</span>TRON<span style="font-family:宋体">：让互联网重新去中心化</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:16px;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: &#39;Times New Roman&#39;"><span style="font-family:宋体">二、项目介绍</span></span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">波场</span>TRON<span style="font-family:宋体">以推动互联网去中心化为己任，致力于为去中心化互联网搭建基础设施。旗下的</span><span style="font-family:Calibri">TRON</span><span style="font-family:宋体">协议是全球最大的基于区块链的去中心化应用操作系统协 议之一，为协议上的去中心化应用运行提供高吞吐，高扩展，高可靠性的底层公链支持。波场</span><span style="font-family:Calibri">TRON</span><span style="font-family:宋体">还通过创新的可插拔智能合约平台为以太坊智能合约提供更好的兼容性。</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">自</span>2018<span style="font-family:宋体">年</span><span style="font-family:Calibri">7</span><span style="font-family:宋体">月</span><span style="font-family:Calibri">24</span><span style="font-family:宋体">日起，</span><span style="font-family:Calibri">TRON</span><span style="font-family:宋体">收购了位于旧金山的互联网技术公司</span><span style="font-family:Calibri">BitTorrent Inc.</span><span style="font-family:宋体">。 </span><span style="font-family:Calibri">BitTorrent Inc.</span><span style="font-family:宋体">设计的分布式技术能够有效扩展，保持智能化，并使创作者和消费者能够控制其内容和数据。 每个月有超过</span><span style="font-family:Calibri">1.7</span><span style="font-family:宋体">亿人使用</span><span style="font-family:Calibri">BitTorrent Inc.</span><span style="font-family:宋体">开发的产品。 </span><span style="font-family:Calibri">BitTorrent Inc.</span><span style="font-family:宋体">的协议每天可以传输全球</span><span style="font-family:Calibri">40</span><span style="font-family:宋体">％的互联网流量。</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: &#39;Times New Roman&#39;"><span style="font-family:宋体">三、</span>Token<span style="font-family:宋体">概况与分配</span></span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">代币总量</span>: 99,214,764,539.60352 TRX</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">流通量：</span></span><span style=";font-family:Helvetica;font-size:15px;background:rgb(255,255,255)">66,682,072,191</span><span style=";font-family:Calibri;font-size:16px">&nbsp;TRX</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">四、常用链接</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">项目网站：</span></span><a href="https://tron.network/" title="https://tron.network"><span style=";font-family:&#39;Times New Roman&#39;;color:rgb(53,124,225)">https://tron.network</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">白皮书：</span></span><a href="https://s3.amazonaws.com/tron.network/%E7%AE%80%E4%BB%8B%E4%B8%8E%E6%84%BF%E6%99%AF.pdf" title="https://tron.network/resources?lng=zh&amp;name=1"><span style=";font-family:&#39;Times New Roman&#39;;color:rgb(53,124,225)">https://s3.amazonaws.com/tron.network/%E7%AE%80%E4%BB%8B%E4%B8%8E%E6%84%BF%E6%99%AF.pdf</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;<span style="font-family:宋体">区块查询：</span></span><a href="#/" title="https://tronscan.org/#/"><span style=";font-family:&#39;Times New Roman&#39;;color:rgb(53,124,225)">https://tronscan.org/#/</span></a><span style=";font-family:Calibri;font-size:16px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a href="https://www.trxplorer.io/" title="https://www.trxplorer.io/"><span style=";font-family:&#39;Times New Roman&#39;;color:rgb(53,124,225)">https://www.trxplorer.io/</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p>
<span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
<p>
<br/>
</p>`