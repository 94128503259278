export default {
    title:"プライバシー保護",
    content:`<p style="text-align: center;">
    <span style="font-size: 18px;"><strong>プライバシーポリシー</strong></span>
</p>
<p>
    コブディグローバル
</p>
<p>
    <br/>
</p>
<p>
    &nbsp; &nbsp; Starthestarは、ユーザーの個人情報の重要性を理解しており、「個人情報保護法」などの法令を遵守し、ユーザーの個人情報のセキュリティを常に尊重し、保護します。ユーティリティまたはサービスへのより良いアクセスを提供するために、私たちはあなたの個人情報を収集、保存、維持および使用します。したがって、Starthestarは、プライバシーポリシーを通じてセキュリティを確保するために、お客様の個人情報をどのように使用および保存するかを開示します。
</p>
<p>
    <br/>
</p>
<p>
    1情報を収集する
</p>
<p>
    &nbsp; &nbsp;あなたが私たちのサービスを登録、取引、または使用するとき、あなたはあなたの個人情報の私たちの収集に同意します、つまり私たちはあなたの許可を得ています。さらに、Starthestarは、個人情報を収集するために詐欺的または不適切な手段を使用したり、条件の範囲を超えて関連情報を提供するように求めたりすることはありません。お客様の個人情報の収集の主な目的は、お客様とのコミュニケーションを促進し、より良いサービスを提供することです。収集される個人情報には、名前、連絡先情報、電子メールアドレス、取引情報、お客様が提供したID情報のコピーが含まれる場合があります。政府発行のID番号、パスポート番号、デバイスまたはインターネットサービスに関連する情報（IPアドレスやMAC番号など）。特別なサービスを使用する場合は、銀行口座などの情報の提供を求められる場合があります。
</p>
<p>
    <br/>
</p>
<p>
    2目的：
</p>
<p>
    私たちはあなたの個人情報を次の目的で使用します：
</p>
<p>
    <br/>
</p>
<p>
    2.1 Starthestarでの取引プロセスの概要、私たちはあなたが私たちに提供した個人情報を処理します
</p>
<p>
    <br/>
</p>
<p>
    2.2関連するユーザーの不正行為を回避するために、実際の情報を確認および確認します。
</p>
<p>
    <br/>
</p>
<p>
    2.3正当な市民の検証、マネーロンダリングやその他の不適切な慣行を防ぐために国際基準を採用しています
</p>
<p>
    <br/>
</p>
<p>
    2.4関連する規約およびポリシーの改訂について直ちに通知する
</p>
<p>
    <br/>
</p>
<p>
    2.5緊急事態におけるタイムリーなコミュニケーション。
</p>
<p>
    <br/>
</p>
<p>
    2.6国の政府機関の関連部門の要件に従う
</p>
<p>
    <br/>
</p>
<p>
    2.7ユーザーパーソナライズされたサービスのサポートを提供する
</p>
<p>
    <br/>
</p>
<p>
    2.8 Starthestarとのコミュニケーションを促進し、関連サービスにすぐにアクセスして、イベント中に賞品を獲得する
</p>
<p>
    <br/>
</p>
<p>
    2.9関連する法律および規制の必要性
</p>
<p>
    <br/>
</p>
<p>
    2.10より良い機能と製品を提供するために、ShouaaGlobalの運用を分析します
</p>
<p>
    <br/>
</p>
<p>
    3個人情報セキュリティ保護
</p>
<p>
    &nbsp; Starthestarは、お客様の個人情報を他の目的に使用することはありません。お客様の許可なしに、Starthestarは、第三者（サービスに関連するサードパーティのセキュリティパートナーを除く）に情報を販売または開示することはありません。情報は、情報のセキュリティを確保し、紛失、破壊、改ざん、または漏洩のリスクに対処します。ShouaaGlobalは、SSLテクノロジーを介して情報を暗号化および管理し、許可なく使用および変更できるのはあなただけです。他の人はアクセスを制限されます。さらに、Starthestarチームは自律的であり、個人情報のセキュリティを最大限に保護し、漏洩や改ざんなどを行いません。
</p>
<p>
    <br/>
</p>
<p>
    4個人情報の設定
</p>
<p>
    &nbsp; &nbsp;Starthestarにログインして、いつでも個人情報を表示、追加、変更、および削除できます。特別な状況では、変更を加えるために電子メールで当社に連絡する必要がある場合があります。プライバシーポリシーに同意しない場合、ShouaaGlobalが提供する関連サービスが終了する場合があります
</p>
<p>
    <br/>
</p>
<p>
    5ステートメント
</p>
<p>
    &nbsp; &nbsp; &nbsp;5.1個人データの取り扱いに完全な信頼を置くためには、プライバシーポリシーの条件を詳細に読んで理解する必要があります。特に、当社のWebサイトにログオンすると、Webサイトに登録されているかどうかに関係なく、以下を受け入れ、同意し、引き受け、確認することを当社に示します。
</p>
<p>
    <br/>
</p>
<p>
    5.2お客様は、必要な同意を得て、個人データを自発的に開示します
</p>
<p>
    <br/>
</p>
<p>
    5.3あなたはこのプライバシーポリシーのすべての条件と制限に従うものとします
</p>
<p>
    <br/>
</p>
<p>
    5.4お客様は、このWebサイトへのログイン、このWebサイトへの登録、および/または当社が提供するサービスの使用を通じてお客様の情報を収集することに同意します。お客様は、当社のプライバシーポリシーの将来の改訂に同意するものとします。
</p>
<p>
    <br/>
</p>
<p>
    6プライバシーの問題と質問
</p>
<p>
    &nbsp; &nbsp; &nbsp;IDとパスワードは個人的に保管します。パスワードは常に安全に保管し、他人に簡単に開示しないでください。パスワードが漏洩したり、情報が異常である場合、またはShouaaGlobalのプライバシーについて質問や提案がある場合ポリシーについては、次の方法でお問い合わせください。starthestar@ starthestar.comまでお問い合わせください。
</p>
<p>
    <br/>
</p>`,
}