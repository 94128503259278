export default `<p>
    <strong>ELA（エラストス）</strong>
</p>
<p>
    <br/>
</p>
<p>
    1.プロジェクトの基本的な状況
</p>
<p>
    プロジェクト名：エラストス
</p>
<p>
    プロジェクトの略語：Elastos
</p>
<p>
    トークン名：ELA
</p>
<p>
    <br/>
</p>
<p>
    2.プロジェクト紹介
</p>
<p>
    Elastosは、ブロックチェーンテクノロジーに基づく分散型SmartWebの構築に特化した次世代のWeb3.0プラットフォームです。
</p>
<p>
    Elastosは、メインサイドチェーン構造、マルチレイヤーコンセンサス、スマートコントラクトコンセンサスコンピューティングなどのブロックチェーンテクノロジーを備えたプラットフォームに安全で信頼できる基盤を提供し、分散型ID、通信、ストレージ、コンピューティングなどを構築します。プラットフォームサービスは、分散アプリケーションをサポートすると同時に、優れたユーザープライバシー保護とデータセキュリティを提供し、データ資産化の波のエンジンとなるシステムプラットフォームを提供することを目的としています。
</p>
<p>
    <br/>
</p>
<p>
    3.トークンの概要と配布
</p>
<p>
    総発行数：28,219,999（2021/02/23に更新）
</p>
<p>
    総発行部数：18,949,381（2021/02/23に更新）
</p>
<p>
    <br/>
</p>
<p>
    ELAが発行したトークンの合計額は約2,822万です。
</p>
<p>
    <br/>
</p>
<p>
    4つの一般的に使用されるリンク
</p>
<p>
    プロジェクトのウェブサイト：https：//www.elastos.org
</p>
<p>
    ホワイトペーパー：https：//www.elastos.org/static/file/elastos_whitepaper_v_0.2.pdf
</p>
<p>
    <br/>
</p>
<p>
    V.通貨が配置されているゾーン
</p>
<p>
    イノベーションゾーン
</p>
<p>
    <br/>
</p>`