export default {
    pt1: "1.ユーザーがSTARTHESTARにログインした後,[取引の開始]をクリックしてプラットフォーム取引インターフェースに入ります;",
    pimg1: "k5",
    pt2: "2。[通貨取引]をクリックして,プラットフォームにリチャージされた通貨（BTC,ETH,TRX）をUSDTに変換します。たとえば,ユーザーがBTCをリチャージする場合は,BTCマーケットをクリックし,[販売]をクリックして,必要な交換そして\"売る\"をクリックし",
    pimg2: "k5",
    pt3: "3.ポップアップインターフェイスにトランザクションパスワードを入力し,[送信]をクリックして外貨両替を完了します。",
    pimg3: "k5",
    pt4: "STARTHESTARはあなたに思い出させます:",
    pt5: "①ユーザーがプラットフォームで取引している場合,プラットフォームで\"証拠金取引\"と\"契約取引\"を行うには,プラットフォームで非USDT通貨を再チャージし,\"通貨取引\"を通じてUSDTと交換する必要があります。",
    pt6: "②ユーザーは,\"ポールバー取引\"と\"契約取引\"でSTARTHESTARプラットフォームで利益を得た後,\"コイン取引\"で対応する通貨（BTC,ETH,TRX）を購入して通貨を引き出すことができます。",
}