export default {
    title: "Currency information",
    btcusdt: [
        {
            type: 1,
            name: "Chinese Name",
            value: "Bitcoin",
        },
        {
            type: 1,
            name: "English name",
            value: "Bitcoin",
        },
        {
            type: 1,
            name: "English abbreviation",
            value: "BTC",
        },
        {
            type: 1,
            name: "Developer",
            value: "Satoshi Nakamoto",
        },
        {
            type: 1,
            name: "Core Algorithm",
            value: "SHA-256",
        },
        {
            type: 1,
            name: "Release Date",
            value: "2009/01/03",
        },
        {
            type: 1,
            name: "Total amount",
            value: "21 million",
        },
        {
            type: 1,
            name: "Block Time",
            value: "About 600 seconds/block",
        },
        {
            type: 1,
            name: "Proof of Consensus",
            value: "POW",
        },
        {
            type: 1,
            name: "Risk",
            value: "There is a potential risk of forks in the expansion dispute",
        },
        {
            type: 1,
            name: "Currency Unit",
            value: "BTC/XBT",
        },
        {
            type: 2,
            name: "Official Website",
            value: "https://bitcoin.org/zh_CN/",
        },
        {
            type: 2,
            name: "Bitcoin FAQ",
            value: "https://bitcoin.org/zh_CN/faq",
        },
        {
            type: 2,
            name: "Block Query",
            value: "https://blockchain.info/",
        },
        {
            type: 2,
            name: "Bitcoin Forum",
            value: "https://bitcointalk.org/",
        },
        {
            type: 2,
            name: "Market value query website",
            value: "https://coinmarketcap.com/",
        },
        {
            type: 3,
            name: "More",
            value: "https://bitcoin.org/zh_CN/",
        },
    ],
    ethusdt: [
        {
            type: 1,
            name: "Chinese Name",
            value: "Ethereum",
        },
        {
            type: 1,
            name: "English name",
            value: "Ethereum",
        },
        {
            type: 1,
            name: "English abbreviation",
            value: "ETH",
        },
        {
            type: 1,
            name: "Developer",
            value: "Vitalik Buterin",
        },
        {
            type: 1,
            name: "Core Algorithm",
            value: "Ethash",
        },
        {
            type: 1,
            name: "Release Date",
            value: "2015/3/20",
        },
        {
            type: 1,
            name: "Block Time",
            value: "About 15-17 seconds/block",
        },
        {
            type: 1,
            name: "Block Reward",
            value: "5",
        },
        {
            type: 1,
            name: "Money Total",
            value: "72 million + 18.72 million / year",
        },
        {
            type: 1,
            name: "Main Features",
            value: "Contains features such as digital currency and smart contracts",
        },
        {
            type: 2,
            name: "Official Website",
            value: "https://www.ethereum.org/",
        },
        {
            type: 2,
            name: "Block Query",
            value: "https://etherscan.io/",
        },
    ],
    usdthusd: [
        {
            type: 2,
            name: "Official website",
            value: "https://tether.to/",
        },
        {
            type: 2,
            name: "Block Query",
            value: "https://www.omniexplorer.info/",
        }
    ],
    trxusdt: [
        {
            type: 2,
            name: "Project Site",
            value: "https://tron.network",
        },
        {
            type: 2,
            name: "Block Query",
            value: "https://tronscan.org/#/",
        },
        {
            type: 3,
            name: "White Paper",
            value: "https://s3.amazonaws.com/tron.network/%E7%AE%80%E4%BB%8B%E4%B8%8E%E6%84%BF%E6%99%AF.pdf",
        },
    ],
    bsvusdt: [
        {
            type: 1,
            name: "Project name",
            value: "Bitcoin SV (BSV)",
        },
        {
            type: 1,
            name: "Project Positioning",
            value: "Bitcoin SV is a full node implementation of Bitcoin Cash (BCH). At the request of Bitcoin Cash (BCH) mining giant CoinGeek and other miners, the team created Bitcoin SV to provide miners with clear Bitcoin Cash (BCH) realizes the choice and allows companies to build applications and websites on its solid and reliable foundation",
        },
        {
            type: 1,
            name: "Total Tokens",
            value: "21,000,000 BSV",
        },
        {
            type: 2,
            name: "Project Site",
            value: "https://bitcoinsv.io/",
        },
    ],
    mkrusdt: [
        {
            type: 1,
            name: "Project name",
            value: "MakerDAO（MKR)",
        },
        {
            type: 1,
            name: "Project Positioning",
            value: "MakerDAO developed Dai, the first decentralized stablecoin on Ethereum, which is the infrastructure of decentralized finance (DeFi). MKR is the governance and equity token of the Maker system",
        },
        {
            type: 1,
            name: "Total Tokens",
            value: "1,005,577 MKR",
        },
        {
            type: 1,
            name: "Total Circulation",
            value: "1,005,577 MKR",
        },
        {
            type: 2,
            name: "Project Site",
            value: "https://makerdao.com",
        },
        {
            type: 2,
            name: "White Paper",
            value: "https://makerdao.com/whitepaper",
        },
        {
            type: 3,
            name: "Block Query",
            value: "https://etherscan.io/token/0x6b175474e89094c44da98b954eedeac495271d0f",
        },
    ],
    linkusdt: [
        {
            type: 1,
            name: "Project name",
            value: "Link",
        },
        {
            type: 1,
            name: "Total Issued",
            value: "1,000,000,000",
        },
        {
            type: 1,
            name: "Current Circulation",
            value: "350,000,000",
        },
        {
            type: 1,
            name: "Token Price",
            value: "$0.0914 USD",
        },
        {
            type: 2,
            name: "Project Site",
            value: "https://www.smartcontract.com/",
        },
        {
            type: 2,
            name: "White Paper",
            value: "https://link.smartcontract.com/whitepaper",
        }
    ],
    compusdt: [
        {
            type: 1,
            name: "Total Tokens",
            value: "10,000,000 COMP",
        },
        {
            type: 1,
            name: "Circulation",
            value: "2,561,279 COMP",
        },
        {
            type: 1,
            name: "Project Site",
            value: "https://compound.finance/",
        },
        {
            type: 3,
            name: "White Paper",
            value: "https://compound.finance/documents/Compound.Whitepaper.pdf",
        },
        {
            type: 3,
            name: "Block Query",
            value: "https://etherscan.io/token/0xc00e94cb662c3520282e6f5717214004a7f26888",
        },
    ],
    xmrusdt: [
        {
            type: 1,
            name: "Total Tokens",
            value: "About 16 million XMR + industrial and mining",
        },
        {
            type: 1,
            name: "Circulation Quantity",
            value: "100%",
        },
        {
            type: 2,
            name: "Project Site",
            value: "https://getmonero.org",
        },
        {
            type: 2,
            name: "Block Query",
            value: "https://xmrchain.net",
        },
        {
            type: 2,
            name: "Other website",
            value: "https://www.reddit.com/r/Monero",
        },
    ],
    bchusdt: [
        {
            type: 1,
            name: "Project name",
            value: "Bitcoin Cash Node (BCH)",
        },
        {
            type: 1,
            name: "Project Introduction",
            value: `BCH (Bitcoin Cash) will undergo a hard fork upgrade on November 15, 2020. As the current proportion of BCHN computing power has an absolute advantage, based on community and user feedback, Huobi Global will make the following arrangements:
            1. The BCHN node scheme will inherit the original name, K-line and trading pairs of BCH. There may be significant fluctuations due to market sentiment during the fork, so please make corresponding arrangements and risk control in advance. Reasonable judgment and prudent decision-making;
            2. After the BCH fork, Huobi Global will take a snapshot of all users' BCH assets and airdrop the fork tokens. After the fork node runs stably, we will evaluate whether to open a fork currency trading pair based on community feedback. `,
        },
        {
            type: 1,
            name: "Total Tokens",
            value: "21,000,000 BCH",
        },
    ],
    ltcusdt: [
        {
            type: 1,
            name: "Chinese name",
            value: "Litecoin",
        },
        {
            type: 1,
            name: "English name",
            value: "Litecoin",
        },
        {
            type: 1,
            name: "English abbreviation",
            value: "LTC",
        },
        {
            type: 1,
            name: "Developer",
            value: "Charlie Lee",
        },
        {
            type: 1,
            name: "Core Algorithm",
            value: "Scrypt",
        },
        {
            type: 1,
            name: "Release Date",
            value: "2011/10/7",
        },
        {
            type: 1,
            name: "Block Time",
            value: "150 seconds/block",
        },
        {
            type: 1,
            name: "Total issuance",
            value: "84 million",
        },
        {
            type: 1,
            name: "Half Time",
            value: "4 years",
        },
        {
            type: 1,
            name: "Proof of Consensus",
            value: "POW",
        },
        {
            type: 1,
            name: "Difficulty adjustment",
            value: "2016 blocks",
        },
        {
            type: 1,
            name: "Block Reward",
            value: "Initially 50LTC, current 25LTC",
        },
        {
            type: 2,
            name: "Official website",
            value: "https://litecoin.org/",
        },
        {
            type: 2,
            name: "Block Query",
            value: "http://explorer.litecoin.net/",
        },
    ],
    neousdt: [
        {
            type: 2,
            name: "Official Website",
            value: "https://neo.org"
        },
        {
            type: 2,
            name: "White Paper",
            value: "http://docs.neo.org/zh-cn/"
        },
    ],
    eosusdt: [
        {
            type: 2,
            name: "Official Website",
            value: "https://eos.io/"
        },
        {
            type: 3,
            name: "White Paper",
            value: "https://github.com/EOSIO/Documentation/blob/master/TechnicalWhitePaper.md#support-millions-of-users"
        },
    ],
    etcusdt: [
        {
            type: 2,
            name: "Official website",
            value: "https://ethereumclassic.org/"
        },
        {
            type: 2,
            name: "White Paper",
            value: "https://github.com/etclabscore"
        },
        {
            type: 2,
            name: "Block Explorer",
            value: "https://blockscout.com/etc/mainnet/"
        },
        {
            type: 2,
            name: "Official Twitter",
            value: "https://twitter.com/etclabs"
        },
        {
            type: 2,
            name: "Reddit",
            value: "https://www.reddit.com/r/EthereumClassic/"
        },
    ],
    zec: [
        {
            type: 2,
            name: "Official website",
            value: "https://z.cash/zh/"
        },
        {
            type: 3,
            name: "Technical Document",
            value: "http://zerocash-project.org/media/pdf/zerocash-extended-20140518.pdf"
        },
    ],
    adausdt: [
        {
            type: 2,
            name: "Official website",
            value: "https://cardanofoundation.org"
        },
        {
            type: 2,
            name: "White Paper",
            value: "whycardano.com"
        },
    ],
    dotusdt: [
        {
            type: 2,
            name: "Official website",
            value: "https://polkadot.network/"
        },
        {
            type: 2,
            name: "White Paper",
            value: "https://polkadot.network/PolkaDotPaper.pdf"
        },
        {
            type: 2,
            name: "Block Query",
            value: "https://polkadot.js.org/apps/#/explorer"
        },
    ],
    trbusdt: [
        {
            type: 2,
            name: "Official website",
            value: "http://www.tellor.io"
        },
        {
            type: 2,
            name: "White Paper",
            value: "http://www.tellor.io/whitepaper"
        },
        {
            type: 3,
            name: "Block Query",
            value: "https://explore.duneanalytics.com/public/dashboards/pVN6lRN8xxtfhjnEbcPbqreI8DNkPOng5P5fol0v"
        },
    ],
    bhdusdt: [
        {
            type: 2,
            name: "Official website",
            value: "http://www.tellor.io"
        },
        {
            type: 2,
            name: "White Paper",
            value: "www.btchd.org/BHD-White Paper 2.0.pdf"
        },
        {
            type: 2,
            name: "Block Query",
            value: "www.btchd.org/explorer/"
        },
    ],
    qtumusdt: [
        {
            type: 2,
            name: "Official website",
            value: "https://www.qtum.org/zh/"
        },
        {
            type: 2,
            name: "White Paper",
            value: "https://www.qtum.org/zh/white-papers"
        },
    ],
    bandusdt: [
        {
            type: 2,
            name: "Official website",
            value: "https://bandprotocol.com/"
        },
        {
            type: 2,
            name: "White Paper",
            value: "https://docs.bandchain.org/whitepaper/"
        },
        {
            type: 2,
            name: "Block Query",
            value: "https://cosmoscan.io/"
        },
    ],
    xtzusdt: [
        {
            type: 2,
            name: "Official website",
            value: "https://tezos.com/"
        },
        {
            type: 2,
            name: "Foundation Website",
            value: "https://tezos.foundation/"
        },
        {
            type: 2,
            name: "White Paper",
            value: "https://tezos.com/static/papers/white_paper.pdf"
        },
        {
            type: 2,
            name: "Block Query",
            value: "https://tezblock.io/"
        },
    ],
    balusdt: [
        {
            type: 2,
            name: "Official website",
            value: "https://balancer.finance/"
        },
        {
            type: 2,
            name: "White Paper",
            value: "https://balancer.finance/whitepaper/"
        },
        {
            type: 2,
            name: "Block Query",
            value: "https://etherscan.io/address/0xba100000625a3754423978a60c9317c58a424e3d"
        },
    ],
    imfusdt: [
        {
            type: 1,
            name: "English Name",
            value: "IMF",
        },
        {
            type: 2,
            name: "White Paper",
            value: "https://starthestar.com/whitepaper/IMF-WhitePaper.pdf"
        }
    ],
}