<template>
<main role="main">
    <div class="container-divider"></div>
    <div class="container" :style="'min-height:' + (screenHeight - 297) + 'px'">
        <nav class="sub-nav">
            <ol class="breadcrumbs">
                <li title="STARTHESTAR">
                    <a href="/">STARTHESTAR</a>
                </li>
                <li title="新手指引">
                    <a href="javascript:">新手指引</a>
                </li>
            </ol>

            <!-- <form role="search" class="search" data-search="" action="/hc/zh-cn/search" accept-charset="UTF-8" method="get">
                <input name="utf8" type="hidden" value="✓"><input type="hidden" name="category" id="category" value="360002866853">
                <input type="search" name="query" id="query" placeholder="搜索" aria-label="搜索">
            </form> -->
        </nav>

        <div class="article-container" id="article-container">
            <aside class="article-sidebar" aria-labelledby="section-articles-title">
                <div class="collapsible-sidebar">
                    <button type="button" class="collapsible-sidebar-toggle" aria-labelledby="section-articles-title" aria-expanded="false"></button>
                    <span id="section-articles-title" class="collapsible-sidebar-title sidenav-title">
                        此组别内的文章
                    </span>
                    <div class="collapsible-sidebar-body">
                        <ul>
                            <li>
                                <a href="/hc/zh-cn/articles/360053205773-STARTHESTAR-iOS%E7%AB%AF-APP%E4%B8%8B%E8%BD%BD%E6%95%99%E7%A8%8B" class="sidenav-item ">新手指导</a>
                            </li>

                            <li>
                                <a href="/hc/zh-cn/articles/360040901174-%E5%A6%82%E4%BD%95%E6%B3%A8%E5%86%8CSTARTHESTAR" class="sidenav-item current-article">如何注册STARTHESTAR</a>
                            </li>
                        </ul>

                    </div>
                </div>

            </aside>

            <article class="article">
                <header class="article-header">
                    <h1 title="如何注册STARTHESTAR" class="article-title">
                        如何注册STARTHESTAR
                    </h1>
                    <!-- <div class="article-author">
                        <div class="avatar article-avatar">
                            <span class="icon-agent"></span>
                            <img src="https://xmexhelp.zendesk.com/system/photos/361157335573/WechatIMG733.jpeg" alt="" class="user-avatar">
                        </div>
                        <div class="article-meta">
                            STARTHESTAR
                            <ul class="meta-group">
                                <li class="meta-data">
                                    <time datetime="2020-01-04T14:07:42Z" title="2020-01-04 22:07" data-datetime="relative">1 年前</time>
                                </li>
                                <li class="meta-data">更新于</li>
                            </ul>
                        </div>
                    </div> -->
                </header>

                <section class="article-info">
                    <div class="article-content">
                        <div class="article-body">
                            <h2><strong><span class="wysiwyg-font-size-large">注册STARTHESTAR</span></strong></h2>

                        </div>

                        <div class="article-attachments">
                            <ul class="attachments">

                            </ul>
                        </div>
                    </div>
                </section>
                <div class="article-relatives">
                </div>

            </article>
        </div>
    </div>
</main>
</template>

<script>
export default {
    name: "Content",
        props: ['screenHeight'],
}
</script>

<style src="../../../static/css/helpbox.css" scoped type="text/css"></style>
