export default {
    pt1:"1.ユーザーはSTARTHESTAR公式ウェブサイトhttps://starthestar.comを開き、ホームページの右上隅にある「ダウンロード」アイコンをクリックし、対応するシステム携帯電話で対応するQRコードをスキャンしてをダウンロードします。",
    pimg1:"k7",
    pt2:"または、ユーザーはSTARTHESTARの公式モバイルWebバージョンアドレスhttps://wap.starthestar.comを開くことができ、モバイルWebページを直接ダウンロードできます。 例としてIOSシステムを取り上げます。下に表示される「iOSアプリのダウンロード」で「ダウンロード」をクリックします。",
    pimg2:"k7",
    // pt3:"リダイレクトされたWebインターフェイスでの最初のステップは、TestFlightを取得し、[App Storeで表示]をクリックするか、電話でApp Storeを直接開いてTestFlightを検索し、ダウンロードして電話にインストールすることです。",
    // pimg3:"k7",
	// pimg4:"k7",
    // pt4:"TestFlightをインストールした後、Webページに戻って2番目のステップに進み、このBataバージョンを追加して、[テストの開始]をクリックします。",
	// pimg5:"k7",
    // pt5:"表示されるインターフェイスで、テストの完了後に最初に[同意する]をクリックし、最後に[ダウンロード]をクリックします。 Apple携帯電話のIOSバージョン用ののダウンロードとインストールのプロセスが完了しました。",
    pt6:"STARTHESTARは親切にあなたに思い出させます：携帯電話のAndroidバージョンをダウンロードするには、ブラウザを開いた後にダウンロードをクリックしてインストールを完了します。一部のAndroid電話はインストール中にシステム認証を必要とします。信頼できるAPP権限を増やすには、[OK]をクリックします。",
}