module.exports = {
  base: {
    title: 'STARTHESTAR',
    desc1: 'Roi du contrat',
    desc2: 'Leader mondial du négoce de contrats',
    d1: 'Confiance',
    d2: 'Honnêteté',
    d3: 'Force',
    more: 'Plus',
    top: 'retour au sommet',
    download: "Télécharger l'application",
    zszh: 'Vrai compte',
    mnzh: 'Compte démo',
    content: 'Négociez avec un leader mondial du marché du négoce de contrats'
  },
  demo: {
    title: 'inscription réussie !',
    title2: 'Compte analogique',
    submit: 'Confirmer'
  },
  lang: {
    title: 'Français'
  },
  message: {
    invalid_email: 'Email invalide',
    invalid_password: 'Mot de passe incorrect',
    invalid_new_password: 'Nouveau mot de passe invalide',
    invalid_res_password: 'Mot de passe de confirmation invalide',
    invalid_email_code: "Code de vérification d'e-mail invalide",
    password_inconsistent: 'Mots de passe incohérents',
    invalid_invitation_code: "Code d'invitation invalide",
    should_agree: "Veuillez lire et accepter les conditions d'inscription",
    not_null: 'Ce champ ne peut pas être vide',
    send_email: 'Veuillez réessayer après {:s}s',
    invalid_number: 'valeur invalide',
    invalid_balance: 'Solde invalide',
    invalid_rate: 'Veuillez sélectionner plusieurs',
    invalid_rule: 'Veuillez choisir une règle',
    invalid_system_rule: 'Aucune règle dans le système',
    not_enough: 'Pas assez disponible',
    price_high: 'Le prix doit être supérieur à {price}',
    price_low: 'Le prix doit être inférieur à {price}',
    number_high: 'Le nombre maximum de retraits est de {num}',
    number_low: 'Le montant minimum de retrait est de {num}',
    copy_address: "Copiez l'adresse avec succès {text}",
    invalid_upload_front: "Veuillez télécharger la photo avant de la pièce d'identité",
    invalid_upload_back: "Veuillez télécharger une photo du verso de votre pièce d'identité",
    invalid_upload_hand: "Veuillez télécharger une photo d'identité portable",
    invalid_upload_name: 'Veuillez entrer le nom du certificat',
    invalid_upload_card: "Veuillez entrer le numéro d'identification",
    upload_pic_type: "Les images téléchargées ne peuvent être qu'au format JPG, PNG",
    upload_pic_size: "La taille de l'image téléchargée ne peut pas dépasser 10 Mo"
  },
  index: {
    title2: 'Négociation libre sur le marché mondial 24 heures non-stop',
    desc2: 'Mettez en œuvre votre stratégie de trading dans un marché extrêmement liquide, avec un volume de trading sur 24h de',
    desc2_1: 'sur toute la plateforme',
    list: {
      name: 'marché',
      h24_price: '24H dernier prix',
      h24_change: 'Changement 24H',
      h24_high: 'Prix le plus élevé 24H',
      h24_low: 'Prix le plus bas 24H',
      h24_vol: 'Volume 24h'
    },
    footer: {
      desc:
        "starthestar Blockchain Technology Ltd, dont le siège est aux États-Unis, est une société de technologie de blockchain de premier plan au monde. La société a obtenu la licence financière internationale US MSB. Il s'agit d'une société de services de technologie blockchain conforme qui peut fonctionner en conformité à l'échelle mondiale. Services commerciaux Blockchain. Dans le même temps, les talents de l'équipe technique de starthestar Blockchain Technology Ltd proviennent des meilleures institutions internationales de recherche scientifique et des équipes de développement de la technologie blockchain pour protéger pleinement les fonds des utilisateurs et la sécurité des comptes. En plus de l'équipe technique, la société dispose également d'une équipe professionnelle de blockchain : l'équipe d'investissement quantitatif des actifs numériques AI, l'équipe d'évaluation de la valeur des projets de blockchain, l'équipe d'investissement dans les actifs numériques, etc., ont une disposition globale pour servir les utilisateurs mondiaux de la blockchain. L'objectif et la vision de l'entreprise sont de fournir des services de transaction sûrs, stables, prévenants et dignes de confiance aux passionnés mondiaux d'actifs numériques, et de créer une entreprise de technologie de blockchain professionnelle, numérique et internationale de renommée mondiale.",
      item1: {
        tt: 'Affichage du produit',
        pt1: 'Règles de négociation OTC',
        pt2: 'Guide du débutant',
        pt3: 'Tarif Standard',
        pt4: 'Description du contrat'
      },
      item2: {
        tt: 'Soutien',
        pt1: "KYC et accord anti-blanchiment d'argent",
        pt2: "Politique de demande d'application",
        pt3: 'Introduction aux actifs numériques'
      },
      item3: {
        tt: 'Conditions',
        pt1: 'La protection de la vie privée',
        pt2: "Accord de l'utilisateur",
        pt3: 'Avis de risque'
      },
      item4: {
        tt: 'Contactez-nous',
        pt1: 'La coopération commerciale',
        pt2: 'Coopération institutionnelle',
        pt3: 'Demande de gré à gré',
        pt4: 'E-mail du service client',
        pt5: 'Plaintes et suggestions',
        pt6: 'coopération avec les médias'
      }
    },
    bk3: {
      title: "Fier de la force d'innovation de la R&D",
      desc: 'Une expérience de trading de contrats inimaginable, conçue pour les traders financiers',
      pt1: 'Commande ouverte stop profit et stop loss',
      pd1: 'Les commandes en attente ne veillent pas tard, craignez de ne pas regarder la commande',
      pt2: "Inverser l'ordre ouvert",
      pd2: 'Le marché change soudainement, suivez en une seconde',
      pt3: "Prend en charge l'intégralité de l'entrepôt par entrepôt",
      pd3: 'Fais ce que tu veux, fais ce que tu veux',
      pt4: 'Liquidation échelonnée de la position complète',
      pd4: "La liquidation étrangère équivaut d'abord à la moitié et essaie d'aider les investisseurs à réduire les pertes"
    },
    bk5: {
      title: 'Huit avantages de la plate-forme de négociation de contrats',
      desc: 'Nous écoutons tout le temps, optimisons et améliorons continuellement, et créons une expérience de trading de première classe pour les utilisateurs du trading mondial',
      pt1: 'Profondeur du marché',
      pd1: "La profondeur du marché des trois premiers stands de la plate-forme a atteint l'ordre de millions de dollars, dépassant de loin la profondeur moyenne du marché de l'industrie de 10 000 et 100 000. La propagation est faible et la transaction est rapide.",
      pt2: 'Transaction fluide',
      pd2:
        "En utilisant un moteur de transaction de correspondance efficace pour assurer une expérience de transaction fluide, il peut traiter des millions de commandes par seconde et peut prendre en charge un fonctionnement fluide et stable avec plus de 20 millions d'utilisateurs en ligne simultanés.",
      pt3: 'Juste prix',
      pd3:
        "« Prix marqué » sélectionne le prix de transaction en temps réel des trois principales bourses du monde comme prix de l'indice pour le calcul de la régression, ce qui empêche efficacement les pertes d'utilisateurs causées par une manipulation anormale du dernier prix de transaction dans un court laps de temps.",
      pt4: 'Technologie auto-développée',
      pd4: "Le moteur de jumelage ancré dans la finance traditionnelle, développé par une équipe d'experts seniors du secteur financier, et méticuleusement conçu chaque détail pour assurer le fonctionnement sûr, stable et efficace de la plateforme de trading.",
      pt5: 'Contrôle des principaux risques',
      pd5:
        "Jugement de deuxième niveau, cryptage multicouche, haute sécurité du système. La combinaison de portefeuilles chauds et froids, de mesures de cryptage de niveau militaire, de la sécurité des transactions d'actifs est garantie et garantit à 100% la sécurité des actifs numériques des utilisateurs.",
      pt6: 'Sécurité des fonds',
      pd6:
        "Système de sécurité à trois couches, audit de sécurité complet. Auto-construit le système de portefeuille froid déterministe hiérarchique leader de l'industrie, tous les utilisateurs attribuent des adresses de recharge sont des adresses de portefeuille froid, la collecte et le retrait des fonds sont effectués via des signatures hors ligne.",
      pt7: 'Puissance hardcore',
      pd7:
        "Des dizaines de milliers de réserves BTC, des gènes d'innovation financière et la meilleure équipe de direction au monde. starthestar promet que si des erreurs de données de compte et de transaction ne sont pas causées pour des raisons système, la plateforme paiera intégralement en cas d'erreurs !",
      pt8: 'Service de qualité',
      pd8: "Service client professionnel 7*24h 24h/24, réponse rapide. En partant de la pensée du service, offrez une expérience commerciale de première classe aux clients mondiaux. L'expérience ultime vient du concept de servir les clients."
    },
    bk6: {
      title: 'Opération mondiale de sécurité et de conformité',
      desc: 'Adoptez la supervision et respectez toujours les opérations de conformité mondiales'
    },
    bk7: {
      title: 'Partenaire stratégique officiel'
    },
    bk8: {
      title: 'Servi plus de 100 000 utilisateurs commerciaux mondiaux',
      desc: "Actuellement, les utilisateurs sont partout aux États-Unis, au Japon, en Corée du Sud, aux Seychelles, au Vietnam et dans d'autres pays."
    },
    bk9: {
      title: "Transaction fluide à tout moment, n'importe où",
      pt1: 'Les transactions de jumelage en temps réel, les dépôts et les retraits arrivent en quelques secondes',
      pt2: "Plusieurs modes de trading, jusqu'à 125 fois l'effet de levier",
      dt1: 'Téléchargement de la version iOS',
      dt2: 'Télécharger la version Androïd'
    },
    about: {
      title: 'À propos de nous',
      pt1: 'Concept de plate-forme',
      pd1:
        "Laissez les actifs mondiaux circuler librement et créez une nouvelle expérience contractuelle. X-xénogénétique, représente des gènes innovants et fournit un flux constant de puissance pour l'innovation de l'industrie. M-motor représente l'objectif et continue d'aller de l'avant dans le but de diriger l'expérience de négociation de contrats. EX-exchange, au nom de la plateforme de négociation, s'engage à créer un écosystème de négociation équitable, transparent et efficace avec la philosophie de fonctionnement de service, d'écoute et de réflexion.",
      pt2: 'Valeurs fondamentales',
      pd2:
        "Nous sommes toujours à l'écoute, attentifs et prévoyants, et nous nous engageons à créer un écosystème commercial équitable, transparent et efficace. Offrez aux traders mondiaux une meilleure expérience de trading, répondez aux besoins réels des traders et favorisez le développement du marché du trading d'actifs numériques.",
      pt3: "Contexte de l'équipe",
      pd3:
        "L'équipe fondatrice est composée des meilleurs investisseurs blockchain, des cadres financiers traditionnels, des meilleurs experts en actifs numériques et des équipes de technologie de travail des métaux traditionnels. Les principaux membres de l'équipe R&D proviennent de Morgan Stanley, CICC, Hang Seng, Tencent, Noah Wealth."
    }
  },
  start: {
    p_title: 'Transaction, commencez ici',
    p_desc: 'Veuillez entrer votre adresse e-mail dans la zone suivante pour vous inscrire immédiatement',
    p_email: 'e-mail',
    p_btn: 'Créer'
  },
  download: {
    p_title: 'Télécharger le client APP',
    p_desc: "N'importe quand, n'importe où, transaction sur terminal multiplateforme",
    p_btn: 'Télécharger',
    p_scan_android: 'Application Androïd',
    p_scan_ios: 'Application IOS'
  },
  downpage: {
    t1: {
      p_title: 'Performances des transactions éclair',
      p_desc: "Fournissez une variété de types de transactions parmi lesquels choisir, la correspondance des commandes est extrêmement rapide, l'échange flash en un clic et le trading est plus pratique"
    },
    t2: {
      p_title: 'Transfert facile des actifs',
      p_desc: 'Transfert facile des actifs sur le compte, aperçu en temps réel des actifs numériques, augmentation du graphique à secteurs du ratio de devises'
    },
    t3: {
      p_title: 'Panneau de négociation personnalisé',
      p_desc: "Définissez librement la disposition de négociation en fonction de différentes habitudes de négociation et augmentez l'entrée de la ligne K et de la carte de profondeur"
    },
    t4: {
      p_title: "Gestion de l'intégration des comptes",
      p_desc: 'Plusieurs paramètres de vérification de sécurité, un seul compte gère les comptes C2C et les comptes en devises'
    }
  },
  login: {
    login: 'connexion',
    title: 'Utilisateur en ligne',
    desc1: "Connectez-vous s'il vous plaît",
    desc2: 'Site officiel',
    email: 'E-mail',
    email2: 'E-mail',
    email3: 'Numéro de téléphone',
    email_desc: 'Veuillez saisir votre e-mail',
    phone_desc: 'Veuillez entrer le numéro de téléphone',
    password: 'mot de passe',
    password_desc: "S'il vous plaît entrer le mot de passe",
    forgot_password: 'Mot de passe oublié',
    no_account: 'Pas encore de compte'
  },
  signup: {
    signup: "S'inscrire",
    title: "Enregistrement de l'utilisateur",
    desc1: 'Bienvenue pour vous connecter',
    desc2: 'Site officiel',
    invitation_code: "code d'invitation",
    invitation_desc: "Veuillez entrer le code d'invitation",
    accept: 'Accepter',
    service: "Accord de l'utilisateur",
    and: 'et',
    policy: 'Politique de protection de la vie privée',
    have_account: 'Vous avez déjà un compte'
  },
  retrieve: {
    ptitle: 'Récupérer mot de passe',
    pemail_code: 'Code de vérification de courrier électronique',
    pset: 'Définir le mot de passe',
    set_title: 'Définir le mot de passe',
    set_desc: 'Vous réinitialisez votre mot de passe, veuillez compléter les paramètres suivants',
    new_password: 'nouveau mot de passe',
    password_desc: 'Veuillez entrer un nouveau mot de passe',
    res_passowrd: 'Confirmez le mot de passe',
    res_passowrd_desc: 'Veuillez entrer le mot de passe de confirmation'
  },
  valid_email: {
    title: "Vérification de l'inscription",
    desc: 'Vous créez un compte, veuillez compléter la vérification suivante',
    email_code: 'Code de vérification de courrier électronique',
    email_desc: "entrez le code de vérification S'il vous plait",
    get_code: 'Obtenir le code de vérification'
  },
  ihead: {
    begin_trade: 'Commencer à négocier',
    helper: 'Aide aux novices',
    text: 'Centre de documentation',
    new_currency: "Nouvelle devise d'abonnement"
  },
  uhead: {
    title1: 'Marchandises',
    title2: 'Forex International',
    title3: 'Index international',
    currency: 'Monnaie',
    lever: 'Transaction à effet de levier',
    coins: 'Opération de devise',
    contract: 'Opération de contrat',
    metal: 'Métal',
    energy: 'Énergie',
    contract_info: 'Informations sur le contrat',
    helper: 'Aide aux novices',
    text: 'Centre de documentation',
    assets: 'Actifs',
    u_wallet: 'Mon portefeuille',
    u_contract: 'Compte de contrat',
    u_lever: 'Compte à effet de levier',
    u_account: 'Information sur le compte',
    u_logout: 'Se déconnecter'
  },
  news: {
    label: 'Nouvelles',
    update: 'Mis à jour le'
  },
  guide: {
    label: 'Guide du débutant',
    list: [
      {
        ptitle: "Comment s'inscrire à starthestar",
        pkey: 'k1'
      },
      {
        ptitle: "Authentification d'identité",
        pkey: 'k2'
      },
      {
        ptitle: 'Paramètres du mot de passe de connexion et du mot de passe de transaction',
        pkey: 'k3'
      },
      {
        ptitle: 'Déposer des pièces',
        pkey: 'k4'
      },
      {
        ptitle: 'Processus de transaction de pièces',
        pkey: 'k5'
      },
      {
        ptitle: 'Retrait',
        pkey: 'k6'
      },
      {
        ptitle: "Téléchargement de l'application mobile ",
        pkey: 'k7'
      }
    ],
    content: {
      k1: require('./currency/en/guide/k1.js').default,
      k2: require('./currency/en/guide/k2.js').default,
      k3: require('./currency/en/guide/k3.js').default,
      k4: require('./currency/en/guide/k4.js').default,
      k5: require('./currency/en/guide/k5.js').default,
      k6: require('./currency/en/guide/k6.js').default,
      k7: require('./currency/en/guide/k7.js').default
    }
  },
  kline: {
    h24_high: '24H de haut',
    h24_low: '24H bas',
    h24_vol: 'Volume 24h',
    commission: 'Commission actuelle',
    record: "Relevé d'opération",
    commission_list: 'Liste des commissions',
    deal_list: 'Dernière offre',
    price: 'Prix',
    xprice: 'Répartition des bénéfices',
    bzj: 'Marge',
    number: 'Nombre',
    total: 'Total',
    time: 'Temps',
    currency: 'Postes',
    type: 'Taper',
    direction: 'Direction',
    multiple: 'Effet de levier',
    fee: 'Commission',
    amount: 'Total',
    status: 'Statut',
    operate: 'Opération',
    buy: 'Acheter',
    sell: 'Vendre',
    pending: 'Commande en cours',
    trade_success: 'Commerce réussi',
    retract: 'Annuler',
    profit: 'Tirer profit',
    loss: 'Arrêter la perte',
    all: 'Tous',
    all_currency: 'Toutes les devises',
    new_price: 'Dernier prix',
    change: 'Ascension et chute',
    protocol: 'protocole',
    _status1: 'Examen',
    _status2: 'Réussi',
    _status3: 'Rejeté'
  },
  coins: {
    title: 'Opération de devise',
    market: 'prix du marché',
    limit: 'Prix limite',
    profit: 'Tirer profit',
    loss: 'Arrêter la perte',
    price: 'Prix',
    current: 'Actuel',
    price_desc: 'Veuillez entrer le prix',
    number: 'Nombre',
    number_desc: 'Veuillez saisir le numéro',
    available: 'Disponible',
    half: 'demi entrepôt',
    all: 'Entrepôt complet',
    fee: 'Commission',
    amount: 'Montant de la transaction',
    buy: 'Acheter',
    sell: 'Vendre',
    current_price: 'Actuel',
    open_price: "Ouvert aujourd'hui",
    high: 'Haut',
    low: 'Faible',
    yesterday: 'Fermer hier',
    today_zf: 'Changement de prix',
    now_cc: 'Position',
    y_cc: 'Position Y',
    jsj: 'Prix de règlement',
    zrjsj: 'Y Prix de règlement'
  },
  lever: {
    title: 'Transaction à effet de levier',
    multiple: 'Effet de levier',
    multiple_desc: 'Veuillez sélectionner plusieurs',
    double: 'double',
    buy: "Acheter (jusqu'à)",
    sell: 'Vendre (automne)'
  },
  contract: {
    title: 'Opération de contrat',
    time: 'Temps',
    time_desc: 'Veuillez sélectionner une heure',
    minute: 'minute',
    second: 'Deuxième',
    buy: "Acheter (jusqu'à)",
    sell: 'Vendre (automne)',
    rate: 'Taux de redevance'
  },
  wallet: {
    title: 'Mes actifs',
    valuation: "Évaluation totale de l'actif",
    my_wallet: 'Mon portefeuille',
    my_trade: 'Un compte de commerce',
    my_transfer: 'Registre des retraits',
    my_deposit: 'Enregistrement du dépôt de pièces',
    cbdz: 'adresse de dépôt',
    csl: 'Quantité',
    deposit: 'Déposer des pièces',
    withdrawal: 'Retrait',
    search: 'Recherche',
    currency: 'Postes',
    protocol: 'Protocole',
    liquidation: 'Fermer la position',
    liquidation_title: 'Liquidation rapide',
    trade_pwd: 'Mot de passe commercial',
    enter_trade_pwd: 'Entrez le mot de passe commercial',
    submit_liquidation: 'Fermer la position immédiatement',
    amount: 'Total',
    available: 'Disponible',
    freeze: 'Gel',
    market: 'évaluation',
    ljsy: 'Revenu cumulé',
    zrsy: "Revenus d'hier",
    operate: 'Opération',
    countdown: 'Compte à rebours',
    lever_title: 'Compte à effet de levier',
    contract_title: 'Compte de contrat',
    direction: 'Direction',
    bond: 'Marge',
    buy_rate: 'plusieurs',
    profit: 'Profit et perte',
    profit1: 'Profit et perte',
    buy: 'Acheter',
    sell: 'Vendre',
    many: 'Acheter',
    empty: 'Vendre',
    contract_time: 'Durée du contrat',
    premium: 'Privilège',
    minute: 'minute',
    address: 'Adresse',
    address_desc: "Veuillez entrer l'adresse",
    number: 'Nombre',
    number_desc: 'Veuillez entrer le nombre de retraits',
    copy: 'Copie',
    confirm: "D'ACCORD",
    all: 'Tous',
    fee: 'Commission',
    actual: 'Arrivée réelle',
    premark: 'Attention',
    text_1: "Cette adresse n'accepte que les actifs de {name}, veuillez ne pas recharger d'autres devises, sinon les actifs ne seront pas récupérés",
    text_2: 'Les frais du mineur de {name} sont : valeur marchande actuelle {rate} %/transaction, norme minimale : {min}{name}/transaction',
    text_3: "Veuillez confirmer que les informations d'adresse de réception sont correctes avant de transférer les fonds. Une fois les actifs transférés, ils ne peuvent plus être retirés.",
    text_4: 'Les frais de minage de {name} sont : {val} {name}/pen'
  },
  record: {
    title: 'Registre des fonds',
    type: 'Taper',
    all_currency: 'Toutes les devises',
    all_type: 'Tous les types',
    select: 'Mettre en doute',
    clear: 'Effacer le filtre',
    receive: 'Dépôt',
    transfer: 'Retrait',
    buy: 'Acheter',
    sell: 'Vendre',
    normal: 'Ordinaire',
    pending: 'Stop-Limit',
    wallet: 'Détails du poste',
    zzzqy: 'Équilibre',
    zqy: 'Marge libre',
    fdyk: 'P/L flottant',
    fxl: 'Taux de risque'
  },
  user: {
    account: 'Authentification du compte',
    account_desc: "Le dépôt de crédit, la transaction et d'autres fonctions doivent d'abord passer la vérification du compte",
    password: 'Mot de passe',
    password_desc: 'Utilisé pour protéger la sécurité du compte',
    modify: 'Modifier',
    set: 'Ensemble',
    find_paypwd: 'Récupérer mot de passe',
    find_pay_pwd: 'Récupérer le mot de passe de paiement',
    email: 'E-mail',
    enter_email: 'Veuillez saisir votre e-mail',
    email_code: 'Le code de vérification',
    enter_email_code: "Veuillez saisir le code de vérification de l'e-mail",
    send: 'Envoyer',
    pay_password: 'Mot de passe de paiement',
    pay_password_desc: 'Utilisé pour protéger la sécurité des actifs lors de la négociation',
    not_certified: 'Non certifié',
    certification: 'Certification en cours',
    verified: 'Vérifié',
    rejected: 'rejeté',
    update_pwd: 'Modifier le mot de passe de connexion',
    update_pay_pwd: 'Modifier le mot de passe de paiement',
    set_pay_pwd: 'Définir le mot de passe de paiement',
    old_pwd: "Mot de passe d'origine",
    enter_old_pwd: "Veuillez entrer le mot de passe d'origine",
    new_pwd: 'nouveau mot de passe',
    enter_new_pwd: 'Veuillez entrer un nouveau mot de passe',
    res_pwd: 'Confirmez le mot de passe',
    enter_res_pwd: 'Veuillez entrer le mot de passe de confirmation',
    submit: 'Soumettre'
  },
  certified: {
    title: 'Authentification du compte',
    front: "recto de la carte d'identité",
    back: "Le verso de la carte d'identité",
    hand: "Carte d'identité",
    name: 'Nom',
    name_desc: 'Veuillez entrer un nom',
    card: "numéro d'identification",
    card_desc: "Veuillez entrer le numéro d'identification",
    submit: 'Soumettre'
  },
  trade: {
    title: 'mot de passe des transactions',
    enter_password: 'Veuillez entrer le mot de passe de la transaction',
    cancel: 'Annuler',
    submit: 'soumettre'
  },
  revoke: {
    title: 'Message rapide',
    message: 'Êtes-vous sûr de vouloir retirer la commande ?',
    cancel: 'Annuler',
    submit: 'confirmer'
  },
  coins_assets: {
    title: 'Actifs en devises',
    total_equity: 'Fonds propres totaux',
    equity: 'Équité du compte',
    number: "Nombre d'exploitations",
    market_value: 'Valorisation actuelle'
  },
  lever_assets: {
    title: 'Actifs à effet de levier',
    total_equity: 'Fonds propres totaux',
    equity: 'Équité du compte',
    profit: 'Gains et pertes non réalisés',
    risk: 'Risque'
  },
  contract_assets: {
    title: 'Actifs contractuels',
    total_equity: 'Fonds propres totaux',
    equity: 'Équité du compte',
    profit: 'Gains et pertes non réalisés'
  },
  contract_menu: {
    contract: 'Détails du contrat',
    lever: "Détails de l'effet de levier",
    coins: 'Index des pièces',
    history: 'Historique des taux du fonds',
    coins_rules: [
      {
        value: '1D',
        name: 'Au jour'
      },
      {
        value: '1W',
        name: 'Cette semaine'
      },
      {
        value: '1M',
        name: 'Mois en cours'
      },
      {
        value: '1J',
        name: 'Saison en cours'
      }
    ],
    lever_info: "Informations sur l'effet de levier",
    contract_info: 'Informations sur le contrat'
  },
  contract_info: {
    bzj: 'Dépôt de garantie',
    pt1: 'Devise de règlement',
    pt2: 'Valeur nominale du contrat',
    pt3: 'Prix de changement minimum',
    pt4: 'Terme du contrat',
    pt5: 'Durée du taux du fonds',
    pt6: 'Prix marqué',
    pt7: 'Volume du poste',
    pt8: 'Volume de transactions sur 24 heures',
    pt9: 'Privilège',
    pt10: "Revenu de livraison à l'échéance",
    pt11: 'Écart de position ouverte',
    pt12: 'Mécanisme de livraison et de règlement',
    pt13: 'Frais de service de transaction',
    pt14: 'Position de verrouillage',
    pv5: 'Tous les jours 08h00 16h00 24h00',
    pv10_1: "Position longue : (cours de clôture moyen - cours d'ouverture moyen) x quantité",
    pv10_2: "Position courte : (cours d'ouverture moyen - cours de clôture moyen) x quantité",
    pv11: 'Non',
    pv12: "Livraison automatique à l'échéance",
    pv13: 'Non',
    pv14: 'pas de support'
  },
  metal_info: {
    pt1: 'Variétés commerciales',
    pt2: 'Devise de règlement',
    pt3: 'Unité de devis',
    pt4: 'Taille du contrat',
    pt5: 'Unité de changement minimale',
    pt51: 'Unité de négociation minimale',
    pt52: 'Unité de négociation maximale',
    pt6: 'Durée du contrat',
    pt7: 'Temps de négociation',
    pt8: 'Commission',
    pt9: 'Bourse cotée',
    pt10: 'propagation cible',
    pv4: 'durable',
    pv5: 'Négociation 24h/24 du lundi au vendredi',
    pv51: '24 heures de négociation',
    pv6: 'Valeur du contrat0,08 %',
    pv7: 'Bourse de Londres (LME)',
    pv71: 'Bourse de New York (NYMEX)',
    pv10: 'Le spread cible de {name} est de {num} pips'
  },
  lever_info: {
    title: "Détails de l'effet de levier",
    bei: 'fois',
    pt1: 'Devise de règlement',
    pt2: 'Valeur nominale du contrat',
    pt3: 'Prix de changement minimum',
    pt4: 'Terme du contrat',
    pt5: 'Durée du taux du fonds',
    pt6: 'Prix marqué',
    pt7: 'Volume du poste',
    pt8: 'Volume de transactions sur 24 heures',
    pt9: 'Réserve de risque',
    pt10: 'Bénéfice de liquidation',
    pt11: 'Écart de position ouverte',
    pt12: 'Mécanisme de liquidation automatique',
    pt13: 'Frais de service de transaction',
    pt14: 'Position de verrouillage',
    pv4: 'Durable',
    pv5: 'Tous les jours 08h00 16h00 24h00',
    pv10_1: "Position longue : (cours de clôture moyen - cours d'ouverture moyen) x quantité",
    pv10_2: "Position courte : (cours d'ouverture moyen - cours de clôture moyen) x quantité",
    pv12: 'Le ratio de risque de réserve atteint 80%',
    pv13: 'Valeur du contrat',
    pv14: 'Non supporté'
  },
  //币币信息
  coins_desc: require('./currency/en/coins.js').default,

  fun_history: {
    t1: 'Date',
    t2: 'Taux de financement estimé',
    t3: 'Taux de financement réel'
  },
  docs: {
    list: [
      {
        name: 'Règles de négociation OTC',
        key: 'OTC'
      },
      {
        name: 'Accords KYC et anti-blanchiment',
        key: 'kyc'
      },
      {
        name: "Politique de demande d'application de la loi",
        key: 'loi'
      },
      {
        name: 'la protection de la vie privée',
        key: 'confidentialité'
      },
      {
        name: "Accord de l'utilisateur",
        key: 'protocole'
      },
      {
        name: 'Avis de risque',
        key: 'risque'
      }
    ],
    otc: require('./currency/en/otc').default,
    kyc: require('./currency/en/kyc.js').default,
    law: require('./currency/en/law.js').default,
    privacy: require('./currency/en/privacy.js').default,
    protocol: require('./currency/en/protocol').default,
    risk: require('./currency/en/risk').default
  },
  asset: {
    title: 'Présentation des actifs numériques',
    list: 'Liste des actifs numériques'
  },
  num_asset: require('./currency/en/asset').default,

  new_currency: {
    _title: "Nouvelle devise d'abonnement",
    _all: 'Tous',
    _progress: 'En cours',
    _end: 'Sur',
    _success: 'Échange réussi',
    _progress_str: 'Progrès',
    _total_str: 'Total publié',
    _date_time: 'Date de début et de fin',
    _submit: 'Échangez maintenant',
    _base_title: 'Informations de base',
    _status: 'Statut',
    _currency: 'Devise acceptée',
    _symbol: 'Symbole de la monnaie',
    _amount: 'Interdiction totale levée',
    _price: 'Prix initial',
    _limit: "Limite d'achat personnelle",
    _limit_amount: "Limite d'achat personnelle",
    _limit_one: 'Limite inférieure de la limite personnelle unique',
    _no_limit: 'Sans limites',
    _position: 'Position',
    _begin_time: 'Heure de début',
    _end_time: 'Heure de fin',
    _redeemed: 'Montant remboursé',
    _surplus: 'surplus',
    _wallet: 'Portefeuille',
    _have: 'Actuellement détenu',
    _input: "quantité d'entrée",
    _choose: "Choisissez s'il vous plaît",
    _new_wallet: 'Nouveau compte en devise',
    _new_price: 'Dernier prix',
    _lock: 'Période de verrouillage',
    _login: 'Veuillez vous connecter',
    _no_data: 'Pas de données',

    _avg_price: 'prix moyen'
  }
}
