<template>
  <div class="inx b-2-cl c-2-bg Chainer" id="home-pc">
    <!-- 导航栏 -->
    <head-nav @listenLang="listenLang"></head-nav>
    <!-- 内容 -->
    <div class="page-content page-content-chain" style="position: relative; min-height: 800px" id="app">
      <section class="c-2-bg international-home-container internationalHome">
        <!-- 第一板块 -->
        <!-- 主题色 -->
        <section class="internationalHome-swiper-base">
          <div class="imgBox" style="display: flex;align-items: center;justify-content: center;padding: 0px 23%;">
            <div class="b-box">
              <div class="text">{{$t('base.d1')}} {{$t('base.d2')}} {{$t('base.d3')}}</div>
              <div class="b-8-cl title">{{ $t('base.content') }}</div>
              <ul class="clearfix img-ul" data-id="1" style="display:block">
                <swiper :slides-per-view="5" :space-between="50" :options="swiperOption" style="height:100%;">
                  <!-- <swiper-slide v-for="(item,index) in newsList" :key="index">
                                    <router-link :to="{name:'News',query:{id:item.id}}">
                                        <li class="u-8-bd">
                                            <a href="">
                                                <img :src="item.banner">
                                            </a>
                                        </li>
                                    </router-link>
                                </swiper-slide> -->
                </swiper>
              </ul>
            </div>
            <div class="b-box">
              <!-- <video-swiper></video-swiper> -->
            </div>
          </div>
        </section>
        <!-- <section class="lj1_css">
                <div class="lj11_css">
                    <div class="lj1_1_css">
                        <div class="lj123_css">Financial strength you can depend on</div>
                        <div class="lj1234_css">We are part of StoneX Group Inc. (NASDAQ: SNEX) a publicly traded company that meets the highest standards of corporate governance, financial reporting and disclosure</div>
                    </div>
                    <div class="lj1_2_css">
                        <img src="../../static/imgs/lj.png" alt="" srcset="">
                    </div>
                </div>
            </section> -->
        <!-- 新闻栏 -->
        <div class="bulletinBoard h-3-bd"></div>
        <!-- 新闻栏 -->
        <div class="bulletinBoard h-3-bd">
          <div style="display: flex;align-items: center;justify-content: center;height:100%">
            <div class="news-div" v-for="(item,index) in newsTextList" :key="index">
              <router-link :to="{name:'News',query:{id:item.id}}">{{item.title}}</router-link>
            </div>
          </div>
        </div>
        <!-- 新闻栏 -->
        <div class="bulletinBoard h-3-bd"></div>
        <!-- 行情轮播栏 -->
        <!-- <div id="echart" style="width: 400px;height: 400px;"></div> -->
        <section class="c-4-bg c-5-bd inter-recommend inter-recommend-international">
          <ul class="clearfix recommend-ul recommend-wrap ulCenter play" style="display: block; width: 3100px;">
            <li class="recommend-content recommend-even" v-for="(item,index) in marketList" :key="index" @click="goTrade(item)">
              <div class="bl c-5-bg"></div>
              <div class="symbol">
                {{item.name}}/{{item.desc}}
                <div class="coin-label">
                  <span class="a-12-cl coin-text">Hot</span>
                  <span class="a-12-bg coin-bg"></span>
                </div>
              </div>
              <div class="b-1-cl price recommendPrice"></div>
              <div class="l-2-xl recommendTime volume">24H Vol </div>
              <div :class="item.change == 0 ? '':(item.change > 0 ? 'green':'red')" class="percentage rose">{{item.close}} {{item.change == 0 ? '':(item.change > 0 ? '↑':'↓')}}</div>
              <div class="kline">
                <div class="echart-box" :id="item.symbol + '_chart'" style="height:32px"></div>
              </div>
            </li>
          </ul>
        </section>

        <!-- 行情展示 -->
        <div class="v5-marketPage">
          <div class="v5Market-container v5-5-cl" marketcurrent="all">
            <div class="v5Market-content">
              <h2 class="v5Market-title" style="text-align: center;">
                {{$t('index.title2')}}
              </h2>
              <div class="v5Market-table">
                <ul class="table-thead v5-7-cl v5-12-bd">
                  <li class="th">
                    <span>{{$t('index.list.name')}}</span>
                    <svg aria-hidden="true" class="icon icon-12">
                      <use xlink="" href="#icon-v5_11"></use>
                    </svg>
                  </li>
                  <li class="th">
                    <span>{{$t('index.list.h24_price')}}</span>
                    <svg aria-hidden="true" class="icon icon-12">
                      <use xlink="" href="#icon-v5_11"></use>
                    </svg>
                  </li>
                  <li class="th">
                    <span>{{$t('index.list.h24_change')}}</span>
                    <svg aria-hidden="true" class="icon icon-12">
                      <use xlink="" href="#icon-v5_11"></use>
                    </svg>
                  </li>
                  <li class="th">
                    <span>{{$t('index.list.h24_high')}}</span>
                    <svg aria-hidden="true" class="icon icon-12">
                      <use xlink="" href="#icon-v5_11"></use>
                    </svg>
                  </li>
                  <li class="th">
                    <span>{{$t('index.list.h24_low')}}</span>
                    <svg aria-hidden="true" class="icon icon-12">
                      <use xlink="" href="#icon-v5_11"></use>
                    </svg>
                  </li>
                  <li class="th">
                    <span>{{$t('index.list.h24_vol')}}</span>
                  </li>
                  <!-- <li class="th"><span>操作</span>
                                </li> -->
                </ul>
                <section class="table-tbody v5-5-cl" style="padding: 0 16px;">
                  <table style="width:100%" class="market_table">
                    <tbody>
                      <tr v-for="(item,index) in marketList" :key="index" @click="goTrade(item)">
                        <td>{{item.name}}/{{item.desc}}</td>
                        <td>
                          <span :class="item.change == 0 ? '':(item.change > 0 ? 'green':'red')" style="color: rgb(255, 255, 255);">
                            {{item.close}} {{item.change == 0 ? '':(item.change > 0 ? '↑':'↓')}}
                          </span>
                        </td>
                        <td>
                          <span :class="item.change == 0 ? '':(item.change > 0 ? 'green':'red')">{{item.change == 0 ? '':(item.change > 0 ? '+':'')}} {{item.change}}%</span>
                        </td>
                        <td>{{item.high}}</td>
                        <td>{{item.low}}</td>
                        <td>{{item.vol}}</td>
                      </tr>
                    </tbody>
                  </table>
                </section>
              </div>
            </div>
          </div>
        </div>

        <!-- 视频板块 -->
        <!-- <div class="c-2-bg inter-tableContentBase international-market-container" id="table-content">
                <div class="home-table">
                    <ul class="c-5-bd home-thead"></ul>
                        <video-swiper></video-swiper>
                    <div>
                        <div class="home-nodata" style="display:none"><svg aria-hidden="true" class="icon icon-50">
                                <use xlink:href="#icon-g_2"></use>
                            </svg>
                            <p>暂无数据</p>
                        </div>
                        <div class="c-2-bg home-tbody-box" style="display:none"></div>
                    </div>
                </div>
            </div> -->

        <!-- 媒体内容 -->
        <section class="interHome-custom">
          <section>
            <!-- <div class="navsBase c-5-bd" style="height: 650px; display:none">
                        <div class="title f-1-cl">媒体报道</div>
                        <div class="mess"> Media Coverage</div>
                        <div style="margin: 30px auto;width: 1200px;display: flex;justify-content: space-between;">
                            <div class="rp_img"> <a href="https://mp.weixin.qq.com/s/VW9XUGE57yP8G2396o2apw" target="_blank"><img src="https://saas-oss.oss-cn-hongkong.aliyuncs.com/upload/20191017155459753.png" style="width: 350px;"></a> </div>
                            <div class="rp_img"> <a href="https://www.jinse.com/blockchain/485892.html" target="_blank"><img src="https://saas-oss.oss-cn-hongkong.aliyuncs.com/upload/20191101141642188.png" style="width: 350px;"></a> </div>
                            <div class="rp_img"> <a href="https://mp.weixin.qq.com/s/V-JEaYd85Ro2FbkM7lr0MA" target="_blank"><img src="https://saas-oss.oss-cn-hongkong.aliyuncs.com/upload/20191101141712801.png" style="width: 350px;"></a> </div>
                        </div>
                        <div style="margin: 30px auto;width: 1200px;display: flex;justify-content: space-between;">
                            <div class="rp">
                                <p><a href="https://news.huoxing24.com/20191012101757388067.html" target="_blank"> 10.12 | MAX：我不是天生的王者，但我骨子里流着不服输的血液！</a> </p>
                                <p><a href="https://www.jinse.com/blockchain/488890.html" target="_blank"> 10.11 | 女侠灵魂拷问第十八期：都2019年了 BBKX还要做匠心典范的交易所吗？</a> </p>
                                <p><a href="https://mp.weixin.qq.com/s/zPx9sPOP7MOeEqEhZJAEsQ" target="_blank"> 10.11 | 做一个骄傲的非主流合伙人？BBKX你这玩法很狂野啊~</a> </p>
                            </div>
                            <div class="rp">
                                <p><a href="https://mp.weixin.qq.com/s/xS5vyo6rK0niTZ3ez4NiCw" target="_blank"> 09.30 | 隐形黑马崛起：直击场外交易痛点，BBKX推出Fabula备受瞩目</a> </p>
                                <p><a href="https://mp.weixin.qq.com/s/H4hQUB0PdG1lfe8dZ0gcdA" target="_blank"> 09.30 | “狼多肉少”的交易所格局下，凭借“二级市场”杀手锏，BBKX能否杀出重围？</a> </p>
                                <p><a href="https://mp.weixin.qq.com/s/LNr0h3bFATuB97pxMAf2tg" target="_blank"> 09.27 | BBKX创新合伙人令牌制度，能杀出重围吗？</a> </p>
                            </div>
                            <div class="rp">
                                <p><a href="https://www.jinse.com/blockchain/478362.html" target="_blank"> 09.26 | BBKX-做一家对用户最友好交易所</a> </p>
                                <p><a href="https://www.jinse.com/blockchain/477809.html" target="_blank"> 09.26 | 场外交易难做 BBKX携Fabula解决场外交易难题</a> </p>
                                <p><a href="http://finance.hyqcw.com/caij/2019/0929/24480.html" target="_blank"> 09.23 | 专注交易深度，BBKX让每一个非主流币都拥有一流的交易深度</a> </p>
                            </div>
                        </div>
                    </div>
                    <div class="navsBase c-5-bd" style="display:none">
                        <div class="title f-1-cl">合作机构</div>
                        <div class="mess"> Cooperative Institution</div>
                        <ul class="custom" style="width: 1000px;">
                            <li class="custom-even-base" style="width: 25%;"> <img src="https://saas-oss.oss-cn-hongkong.aliyuncs.com/upload/20191009112634770.png"></li>
                            <li class="custom-even-base" style="width: 25%;"><img src="https://saas-oss.oss-cn-hongkong.aliyuncs.com/upload/20191009112702982.png"></li>
                            <li class="custom-even-base" style="width: 25%;"><img src="https://saas-oss.oss-cn-hongkong.aliyuncs.com/upload/20191009112724207.png"></li>
                            <li class="custom-even-base" style="width: 25%;"><img src="https://saas-oss.oss-cn-hongkong.aliyuncs.com/upload/20191009112747007.png"></li>
                            <li class="custom-even-base" style="width: 25%;"><img src="https://saas-oss.oss-cn-hongkong.aliyuncs.com/upload/20190811194117801.png"></li>
                            <li class="custom-even-base" style="width: 25%;"><img src="https://saas-oss.oss-cn-hongkong.aliyuncs.com/upload/20190811192152397.png"></li>
                            <li class="custom-even-base" style="width: 25%;"><img src="https://saas-oss.oss-cn-hongkong.aliyuncs.com/upload/20190811192402603.png"></li>
                            <li class="custom-even-base" style="width: 25%;"><img src="https://saas-oss.oss-cn-hongkong.aliyuncs.com/upload/20190811192654522.png"></li>
                            <li class="custom-even-base" style="width: 25%;"><img src="https://saas-oss.oss-cn-hongkong.aliyuncs.com/upload/20190811193745169.png"></li>
                            <li class="custom-even-base" style="width: 25%;"><img src="https://saas-oss.oss-cn-hongkong.aliyuncs.com/upload/20190811193835454.png"></li>
                            <li class="custom-even-base" style="width: 25%;"><img src="https://saas-oss.oss-cn-hongkong.aliyuncs.com/upload/20190811193957088.png"></li>
                            <li class="custom-even-base" style="width: 25%;"><img src="https://saas-oss.oss-cn-hongkong.aliyuncs.com/upload/20191012183054163.png"></li>
                        </ul>
                    </div> -->
            <!-- 交易开始 -->
            <div class="invest-creat">
              <h2 class="invest-subtitle b-1-cl">{{$t('start.p_title')}}</h2>
              <p class="invest-subtitle-desc b-1-cl">{{$t('start.p_desc')}}</p>
              <div class="register_content">
                <input type="text" v-model="username" :placeholder="$t('start.p_email')" id="register_account" class="invest-creat-input">
                <button class="invest-creat-btn" @click="()=>{this.$router.push({name:'Signup',query:{username:this.username}});}">{{$t('start.p_btn')}}</button>
              </div>
            </div>
            <!-- 下载 -->
            <div class="appDownBase">
              <div class="appDownBase-center">
                <div class="text">
                  <p class="text-1 z-1-cl">{{$t('download.p_title')}}</p>
                  <p class="text-2 z-2-cl">{{$t('download.p_desc')}}</p>
                </div>
                <div class="appDownBase-button">
                  <a href="#/download" class="b-2-bd b-8-cl appDownBase-but">{{$t('download.p_btn')}}</a>
                </div>
                <div class="appDownBase-imgBox">
                  <img src="../../static/imgs/download.png" alt="">
                </div>
              </div>
            </div>
          </section>

          <!-- <section>
                    <div class="invest-creat">
                        <h2 class="invest-subtitle b-1-cl">{{$t('index.bk6.title')}}</h2>
                        <p class="invest-subtitle-desc b-1-cl">{{$t('index.bk6.desc')}}</p>
                        <div class="register_content cert-box">
                            <div class="cert-bg1 cert"></div>
                            <div class="cert-bg2 cert"></div>
                        </div>
                    </div>
                </section> -->
        </section>
      </section>
    </div>

    <footer-nav></footer-nav>

    <section class="common-dialog" style="display: none;">
      <div class="dialog-markAll u-7-bg"></div>
      <div class="dialog-frame a-5-bg">
        <div class="dialog-frame-head a-4-bg">
          <span class="dialog-frame-head-text b-1-cl">设置</span>
          <span class="dialog-frame-head-close">
            <section class="common-iconButton">
              <div class="iconButton-icon">
                <svg aria-hidden="true" class="icon icon-16">
                  <use xlink:href="#icon-c_7"></use>
                </svg>
              </div>
              <div class="iconButton-bg"></div>
            </section>
          </span>
        </div>
        <div class="dialog-frame-body" style="padding-top: 30px; padding-bottom: 30px;">
          <div class="setBox">
            <div class="setBox">
              <div class="setColor clearfix">
                <div class="setColor-key x-2-cl">色彩主体</div>
                <ul class="setColor-value colorList">
                  <li data-id="1" data-checked="checked" class="checked">
                    <section class="common-redio"><svg aria-hidden="true" class="icon icon-16 uncheck">
                        <use xlink:href="#icon-c_14"></use>
                      </svg> <svg aria-hidden="true" class="icon icon-16 check">
                        <use xlink:href="#icon-c_14_1"></use>
                      </svg></section><span class="b-1-cl">深色版</span>
                  </li>
                  <li data-id="2" data-checked="">
                    <section class="common-redio"><svg aria-hidden="true" class="icon icon-16 uncheck">
                        <use xlink:href="#icon-c_14"></use>
                      </svg> <svg aria-hidden="true" class="icon icon-16 check">
                        <use xlink:href="#icon-c_14_1"></use>
                      </svg></section><span class="b-1-cl">浅色版</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="dialog-frame-bottom">
          <div class="dialog-frame-options">
            <button class="common-button common-button-text u-8-cl closeBtnClass" style="height: 40px; padding-left: 31px; padding-right: 31px; margin-top: 0;">
              <div class="common-button-slot">取消</div>
            </button>
            <button class="common-button common-button-solid u-8-bg u-16-cl confirm" style="height: 40px; padding-left: 31px; padding-right: 31px; margin-top: 0;">
              <div class="common-button-slot">确定</div>
              <div class="solidBc bghover u-14-bg"></div>
              <div class="solidBc bgactive u-15-bg"></div>
            </button>
          </div>
        </div>
      </div>
    </section>
    <div id="userdata_el" style="visibility: hidden; position: absolute;"></div>
  </div>
</template>

<script>
import { getNewsList, getIndexCoins } from "@/api/index";
import socket from "../utils/socket";
import { GetFixed } from "../utils/helper";

import FooterNav from "../components/FooterNav.vue";
import HeadNav from "../components/HeadNav.vue";
// import VideoSwiper from '../components/VideoSwiper2.vue';

import * as echarts from "echarts";

export default {
  name: "Index",
  components: {
    // VideoSwiper,
    HeadNav,
    FooterNav,
  },
  data() {
    return {
      //用户
      username: "",
      //新闻
      newsList: [],
      newsTextList: [],

      //市场行情列表
      marketList: [],

      // swiper
      swiperOption: {
        loop: false,
        // direction: "vertical",
        slidesPerView: 1,
        spaceBetween: 50,
        autoplay: {
          delay: 50000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  created() {
    //获取新闻
    this.getNewsList();

    // socket数据
    this.socketListen();

    //第二板块数据
    this.getMarketList();
  },
  destroyed() {
    console.log("销毁");
    if (socket.getReadyState() == 1) {
      socket.send({
        type: "unsub.detail",
      });
    }
  },
  watch: {
    marketList() {
      this.$nextTick(function () {
        for (var i in this.marketList) {
          this.drawChart(
            this.marketList[i].symbol + "_chart",
            this.marketList[i].history,
            this.marketList[i].change
          );
        }
      });
    },
  },
  mounted() {
    // this.drawChart('echart',[1,2,3,4,5,6,7,8,9,10],1)
  },
  methods: {
    goTrade(item) {
      this.$router.push({
        name: "Coins",
        query: {
          symbol: item.symbol,
        },
      });
    },
    /**
     * 切换语言时重新加载数据
     */
    listenLang(lang) {
      // 设置语言
      console.log("监听", lang);

      //重新加载新闻
      this.getNewsList();
    },
    /**
     * 获取新闻
     */
    getNewsList() {
      getNewsList({
        is_top: 2,
        limit: 6,
      }).then((res) => {
        this.newsList = res.data.list;
      });

      getNewsList({
        is_top: 1,
        limit: 6,
      }).then((res) => {
        this.newsTextList = res.data.list;
      });
    },
    /**
     * 获取行情
     */
    getMarketList() {
      getIndexCoins({
        limit: 10,
      }).then((res) => {
        this.marketList = res.data;
      });
    },
    drawChart(id, history, change) {
      var color = change >= 0 ? "rgba(66,206,142,1)" : "rgba(247,79,79,1)";

      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById(id));
      // 绘制图表
      myChart.setOption({
        animation: false,
        xAxis: {
          show: false,
          type: "category",
          boundaryGap: false,
        },
        yAxis: {
          show: false,
          type: "value",
        },
        grid: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
        series: [
          {
            symbol: "none",
            smooth: false,
            data: history,
            type: "line",
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: color, // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(0,0,0,0.1)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            lineStyle: {
              color: {
                type: "linear",

                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: color, // 0% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
              width: 2,
            },
          },
        ],
      });
    },
    /**
     * 数据
     */
    socketListen() {
      let _this = this;
      // 初始化历史k线
      socket.on("detail", function (data) {
        // 最新信息
        for (var i in _this.marketList) {
          if (_this.marketList[i].symbol == data.symbol) {
            _this.marketList[i].close = GetFixed(data.close);
            _this.marketList[i].change = data.change;
            _this.marketList[i].high = GetFixed(data.high);
            _this.marketList[i].low = GetFixed(data.low);
            _this.marketList[i].vol = GetFixed(data.vol);
          }
        }
      });

      // 连接后
      socket.on("onReady", function () {
        // 发送消息
        socket.send({
          type: "sub.detail",
        });

        //启动心跳
        socket.sendPing();
      });

      socket.on("onReconnect", function () {
        // 断线重连
        _this.getMarketList();
      });
    },
  },
};
</script>

<style src="../../static/css/index.css" scoped type="text/css"></style>
