<template>
<main role="main">
    <div class="container-divider"></div>
    <div class="container">
        <nav class="sub-nav">
            <ol class="breadcrumbs">
                <li title="STARTHESTAR">
                    <router-link to="/">STARTHESTAR</router-link>
                </li>
                <li title="">
                    <a href="javascript:">{{docs.title}}</a>
                </li>
            </ol>
            <!-- <form role="search" class="search" accept-charset="UTF-8" method="get">
                <input name="utf8" type="hidden" value="✓"><input type="hidden" name="category" id="category" value="360002866853">
                <input type="search" name="query" id="query" placeholder="搜索" aria-label="搜索">
            </form> -->
        </nav>

        <div class="article-container" id="article-container">
            <aside class="article-sidebar" aria-labelledby="section-articles-title">

                <div class="collapsible-sidebar">
                    <button type="button" class="collapsible-sidebar-toggle" aria-labelledby="section-articles-title" aria-expanded="false"></button>
                    <span id="section-articles-title" class="collapsible-sidebar-title sidenav-title">
                        {{$t('base.more')}}
                    </span>
                    <div class="collapsible-sidebar-body">
                        <ul>
                            <li v-for="(item,index) in list" :key="index">
                                <router-link :to="{name:'Docs',query:{docs:item.key}}" :class="'sidenav-item ' + (key == item.key?'current-article':'')">{{item.name}}</router-link>
                            </li>
                        </ul>
                    </div>
                </div>

            </aside>

            <article class="article">
                <header class="article-header">
                    <h1 title="如何注册STARTHESTAR" class="article-title">
                        {{docs.title}}
                    </h1>
                    <!-- <div class="article-author">
                        <div class="avatar article-avatar">
                            <span class="icon-agent"></span>
                            <img src="../../../static/imgs/t1.png" width="30" alt="" class="user-avatar">
                        </div>
                        <div class="article-meta">
                            STARTHESTAR
                            <ul class="meta-group">
                                <li class="meta-data">
                                    <time datetime="2020-01-04T14:07:42Z" title="2020-01-04 22:07" data-datetime="relative">1 年前</time>
                                </li>
                                <li class="meta-data">更新于</li>
                            </ul>
                        </div>
                    </div> -->
                </header>
                <section class="article-info">
                    <div class="article-content">
                        <div class="article-body" v-html="docs.content">
                        </div>
                        <div class="article-attachments">
                            <ul class="attachments">
                            </ul>
                        </div>
                    </div>
                </section>
                <div class="article-relatives">
                </div>
            </article>
        </div>
    </div>

</main>
</template>

<script>
export default {
    name: "Docs",
    data() {
        return {
            list: [],

            //当前key
            key: '',
            //文档
            docs: {}
        }
    },
    watch: {
        '$route'(to) {
            to
            this.init()
        }
    },
    created() {
        this.init()
    },
    methods: {
        init() {
            //页面参数
            let docs = this.$route.query.docs
            console.log(docs)
            //列表
            this.list = this.$t('docs.list')

            //获取对应文档
            this.key = docs
            this.docs = this.$t(`docs.${docs}`)
        },
        listenLang(lang) {
            lang
            this.init()
        }
    }
}
</script>

<style src="../../../static/css/helpbox.css" scoped type="text/css"></style>
