export default {
    title:"ユーザー規約",
    content:`<p style="text-align: center;">
    <span style="font-size: 18px;"><strong>ユーザー規約</strong></span>
</p>
<p>
    コブディグローバル
</p>
<p>
    <br/>
</p>
<p>
    Starthestarは、未来志向のデジタル通貨およびデジタル資産の流通および取引プラットフォームであり、専用のWebサイトであるWebサイトhttps://starthestar.com/（以下「このWebサイト」または「Webサイト」と呼びます）を運営しています。 Starthestarが特に明記しない限り、デジタル資産取引を行うユーザー、および関連サービスを提供するプラットフォーム（以下「サービス」または「サービス」といいます）、製品、テクノロジー、ソフトウェア、プログラム、データ、およびウェブサイトで使用および公開されているその他の情報は、Starthestar Limited（以下、当社）に帰属します。本契約での表現の便宜のために、会社とこのウェブサイトは、本契約で「私たち」または他の一人称代名詞を集合的に使用します。
</p>
<p>
    <br/>
</p>
<p>
    重要なヒント：
</p>
<p>
    特に次のことをお知らせします。
</p>
<p>
    <br/>
</p>
<p>
    1デジタル資産自体は、金融機関や政府によって承認されていません。デジタル資産市場は真新しく、明確で安定した期待はありません。
</p>
<p>
    <br/>
</p>
<p>
    2デジタル資産取引には非常に高いリスクがあります。1日中途切れることなく取引され、浮き沈みに制限はなく、価格は大きく変動しがちです。
</p>
<p>
    <br/>
</p>
<p>
    3国内法、規制、規範文書の策定または改訂により、デジタル資産取引はいつでも停止または禁止される場合があります。 。
</p>
<p>
    <br/>
</p>
<p>
    デジタル資産取引は非常にリスクが高く、ほとんどの人には適していません。この投資は部分的または全体的な損失をもたらす可能性があることを理解し、理解しているため、許容できる損失のレベルに基づいて投資額を決定する必要があります。デジタル資産がデリバティブリスクを生み出すことを理解し、理解していることを確認してください。また、上記のリスクに加えて、予期せぬリスクが発生します。デジタル資産の売買を決定する前に、財務状況と上記のリスクを慎重に検討し、明確な判断を下して、結果として生じるすべての損失を負担する必要があります。当社は一切の責任を負いません。 。
</p>
<p>
    <br/>
</p>
<p>
    1.一般
</p>
<p>
    1.1このウェブサイトが提供するサービスを利用する前に、本契約を注意深くお読みください。理解できない場合やその他の必要がある場合は、専門の弁護士にご相談ください。本契約および/またはその変更にいつでも同意しない場合は、このWebサイトが提供するサービスの使用を停止するか、このWebサイトにすぐにログインしてください。このウェブサイトにログインし、このウェブサイトのサービスまたは他の同様の行動を使用すると、いつでもこのウェブサイトによってこの契約に加えられた変更を含め、この契約の内容を理解し、完全に同意したことになります。 。
</p>
<p>
    <br/>
</p>
<p>
    1.2このウェブサイトの要件に従って関連情報を入力し、他の関連手順の後に正常に登録することにより、このウェブサイトのメンバー（以下「メンバー」と呼びます）になることができます。登録プロセス中に「同意する」ボタンをクリックすると、電子的に署名するか、このウェブサイトを使用する過程で「同意する」などのマークが付いたボタンをクリックしたとき、またはこのウェブサイトで許可されている他の方法でこのウェブサイトが提供するサービスを実際に使用したとき、あなたは完全に理解し、同意し、この契約に基づくすべての条件に同意します。この契約は、手書きの署名がない限り、法的拘束力に影響を与えることはありません。
</p>
<p>
    <br/>
</p>
<p>
    1.3このウェブサイトのメンバーになると、メンバーアカウントとそれに対応するパスワードを取得し、メンバーアカウントとパスワードはメンバーが保持するものとします。メンバーは、アカウントでのすべての活動とイベントに対して法的に責任を負うものとします。 。
</p>
<p>
    <br/>
</p>
<p>
    1.2このウェブサイトの要件に従って関連情報を入力し、他の関連手順の後に正常に登録することにより、このウェブサイトのメンバー（以下「メンバー」と呼びます）になることができます。登録プロセス中に「同意する」ボタンをクリックすると、電子的に署名するか、このウェブサイトを使用する過程で「同意する」などのマークが付いたボタンをクリックしたとき、またはこのウェブサイトで許可されている他の方法でこのウェブサイトが提供するサービスを実際に使用したとき、あなたは完全に理解し、同意し、この契約に基づくすべての条件に同意します。この契約は、手書きの署名がない限り、法的拘束力に影響を与えることはありません。 ：
</p>
<p>
    <br/>
</p>
<p>
    1.4.1本契約のすべての条件に拘束されます。 。
</p>
<p>
    <br/>
</p>
<p>
    1.4.2お客様は、適用されるさまざまな法律に基づいて契約を締結する法定年齢に達し、これらの条件を完全に受け入れることができることを確認します。 。
</p>
<p>
    <br/>
</p>
<p>
    1.4.3お客様は、取引に関与するデジタル資産がお客様に帰属することを合法的に取得および所有することを保証します。 。
</p>
<p>
    <br/>
</p>
<p>
    1.4.4お客様は、お客様自身の取引または非取引行為、および利益または損失について単独で責任を負うことに同意するものとします。 。
</p>
<p>
    <br/>
</p>
<p>
    1.4.5登録時に提供された情報が真実かつ正確であることを確認します。 。
</p>
<p>
    <br/>
</p>
<p>
    1.4.6お客様は、税務上の目的で、取引利益の報告を含む、関連する法的要件を遵守することに同意するものとします。 。
</p>
<p>
    <br/>
</p>
<p>
    1.4.7本契約は、お客様と当社の間の権利と義務を拘束するだけであり、このWebサイトのユーザーと他のWebサイトとお客様との間のデジタル資産取引から生じる法的関係および法的紛争を含みません。 。
</p>
<p>
    <br/>
</p>
<p>
    2.契約の修正
</p>
<p>
    当社は、本契約を随時改訂し、別途通知することなく、ウェブサイトで発表する権利を留保します。変更された契約は、本契約のホームページに変更時刻が記載され、公開された後、ウェブサイトでは、すぐに自動的に有効になります。本契約の更新時期および内容を随時閲覧し、注意を払う必要があります。関連する変更に同意しない場合は、直ちに本ウェブサイトのサービスの利用を停止してください。本サービスを継続して利用する場合は、ウェブサイト、それはあなたが改訂された契約に拘束されることに同意し、同意することを意味します。 。
</p>
<p>
    <br/>
</p>
<p>
    3.登録
</p>
<p>
    3.1登録の資格
</p>
<p>
    登録プロセスを完了するか、このWebサイトで許可されている他の方法でこのWebサイトが提供するサービスを実際に使用する場合、適用法で義務付けられているように、この契約に署名し、このWebサイトのサービスを使用できる必要があります。自然人、法人またはその他の組織。 [登録に同意する]ボタンをクリックすると、あなたまたはあなたの許可された代理人がこの契約の内容に同意し、その代理人によってこのWebサイトのサービスを登録および使用したことを意味します。あなたが前述の主題の資格を持っていない場合、あなたとあなたの許可された代理人はそれから生じるすべての結果を負担するものとし、会社はあなたのアカウントをキャンセルまたは永久に凍結し、あなたとあなたの許可された代理人に責任を負わせる権利を留保します。 。
</p>
<p>
    <br/>
</p>
<p>
    3.2登録の目的
</p>
<p>
    あなたは、このウェブサイトへの登録が、法律や規制に違反したり、このウェブサイトでのデジタル資産取引の順序を乱したりすることを目的としていないことを確認し、約束します。 。
</p>
<p>
    <br/>
</p>
<p>
    3.3登録プロセス
</p>
<p>
    3.3.1あなたは、このウェブサイトのユーザー登録ページの要件に従って、有効な電子メール、携帯電話番号、およびその他の情報を提供することに同意します。提供または確認した電子メールアドレス、携帯電話番号、またはその他の方法で許可されている方法を使用できます。このウェブサイトに入るためのログイン手段としてのこのウェブサイト。必要に応じて、さまざまな法域の関連法規に従い、法規制、プライバシー条項、マネーロンダリング防止条項で要求される本名、身分証明書、その他の関連情報を提供し、登録情報を常に更新する必要があります。 、タイムリーで詳細かつ正確な情報に沿って。必須。最初に入力されたデータはすべて登録データとして引用されます。お客様は、かかる情報の真実性、完全性、正確性について責任を負い、直接的または間接的な損失およびそれから生じる悪影響を負うものとします。 。
</p>
<p>
    <br/>
</p>
<p>
    3.3.2主権国または地域の法律、規制、規則、命令、およびその他の規範で実名の携帯電話番号が要求される場合、登録された携帯電話番号が実名の登録で登録されることに同意するものとします。直接的または間接的な損失そして不利な結果はあなたが負担する必要があります。 。
</p>
<p>
    <br/>
</p>
<p>
    3.3.3登録に必要な情報を合法的に、完全かつ効果的に提供し、確認された場合、あなたはこのウェブサイトのアカウント番号とパスワードを取得する権利があります。このウェブサイトのアカウント番号とパスワードを取得すると、登録は成功したとみなされ、このウェブサイトのメンバーとしてログインできます。 。
</p>
<p>
    <br/>
</p>
<p>
    3.3.4お客様は、このWebサイトから、このWebサイトの管理および運用に関連する電子メールおよび/またはショートメッセージを受信することに同意するものとします。 。
</p>
<p>
    <br/>
</p>
<p>
    4.サービス
</p>
<p>
    このウェブサイトは、このウェブサイトを通じてデジタル資産取引活動（デジタル資産取引およびその他のサービスを含むがこれらに限定されない）のためのオンライン取引プラットフォームサービスのみを提供します。このウェブサイトは、買い手または売り手としてデジタル資産を売買する行為には参加しません。 。 。
</p>
<p>
    <br/>
</p>
<p>
    4.1サービスコンテンツ
</p>
<p>
    4.1.1あなたには、このWebサイトでさまざまなデジタル資産製品のリアルタイムの市場および取引情報を閲覧する権利があり、このWebサイトを通じてデジタル資産取引の指示を送信してデジタル資産取引を完了する権利があります。 。
</p>
<p>
    <br/>
</p>
<p>
    4.1.2あなたは、このウェブサイトで公開されている活動規則に従って、このウェブサイトが主催するウェブサイト活動に参加する権利を有します。 。
</p>
<p>
    <br/>
</p>
<p>
    4.1.3このウェブサイトがあなたに提供することを約束する他のサービス。 。
</p>
<p>
    <br/>
</p>
<p>
    4.2。サービスルール
</p>
<p>
    あなたはこのウェブサイトの以下のサービス規則に従うことを約束します：
</p>
<p>
    <br/>
</p>
<p>
    4.2.1アカウント内のすべてのデジタル資産のソースの合法性を確保するために、法律、規制、規則、およびポリシーの要件を遵守する必要があり、このWebサイトの権利と利益を損なう違法またはその他の活動に従事してはなりません。またはこのウェブサイト上の第三者またはそのサービスの使用（法律、規制に違反する情報の送受信、他者の権利と利益の侵害、ピラミッドスキームまたはその他の有害な情報や発言の送受信、使用または改ざんなど）このウェブサイトの許可なしにこのウェブサイトの電子メールヘッダー情報。
</p>
<p>
    <br/>
</p>
<p>
    4.2.2法律および規制を遵守し、登録時にWebサイトアカウントとログインパスワード、ファンドパスワード、それにバインドされた携帯電話番号、および受信した携帯電話確認コードのセキュリティを適切に使用および保持する必要があります。携帯電話。あなたはあなたのウェブサイトアカウントとログインパスワード、ファンドパスワード、そして携帯電話確認コードを使用することのすべての操作と結果に対して完全に責任があります。このウェブサイトのアカウント番号、ログインパスワード、ファンドパスワード、または確認コードが許可されていない第三者によって使用されていることがわかった場合、またはその他のアカウントのセキュリティ上の問題がある場合は、すぐに効果的にこのウェブサイトに通知し、このウェブサイトの停止をリクエストする必要がありますこのウェブサイトアカウントのサービス。このウェブサイトは、合理的な時間内にあなたの要求に応じて行動を起こす権利を有しますが、このウェブサイトは行動を起こす前に起こった結果（あなたへの損失を含むがこれに限定されない）に対して一切の責任を負いません。このウェブサイトの同意なしに、このウェブサイト上のアカウントを他人に譲渡、借用、リース、譲渡、またはその他の方法で処分することはできません。 。
</p>
<p>
    <br/>
</p>
<p>
    4.2.3お客様は、すべての活動（情報の開示、情報の公開、さまざまな規則や契約への同意または提出のためのオンラインクリック、契約のオンライン更新またはサービスの購入などを含むがこれらに限定されない）の責任を負うことに同意するものとします。 。
</p>
<p>
    <br/>
</p>
<p>
    4.2.4このウェブサイトでデジタル資産取引を行う場合、デジタル資産取引の通常の行為を悪意を持って妨害したり、取引順序を妨害したりしてはなりません。このウェブサイトの通常の運用を妨害したり、サービスの使用を妨害したりしてはなりません。技術的手段または他の手段による他のユーザーによるこのウェブサイトの使用;架空の事実によってこのウェブサイトの善意を悪意を持って誹謗中傷しないでください。 。
</p>
<p>
    <br/>
</p>
<p>
    4.2.5オンライン取引が原因で他のユーザーと紛争が発生した場合、司法または行政チャネルを通じて関連情報を提供するようにこのWebサイトに要求してはなりません。 。
</p>
<p>
    <br/>
</p>
<p>
    4.2.6お客様は、このWebサイトが提供するサービスの使用中に発生したすべてのハードウェア、ソフトウェア、サービス、およびその他の費用に加えて、適用されるすべての税金について単独で責任を負うものとします。 。
</p>
<p>
    <br/>
</p>
<p>
    4.2.7お客様は、本契約および本ウェブサイトによって随時公開および更新されるその他の利用規約および運用規則を遵守し、本ウェブサイトによって提供されるサービスの使用をいつでも終了する権利を有するものとします。 。
</p>
<p>
    <br/>
</p>
<p>
    4.3。製品ルール
</p>
<p>
    4.3.1トークン取引の製品ルール
</p>
<p>
    <br/>
</p>
<p>
    このウェブサイトにアクセスして取引を行い、このウェブサイトを通じて他のユーザーと通貨取引を行う場合は、以下の取引規則に従うことを約束します。
</p>
<p>
    <br/>
</p>
<p>
    4.3.1.1トランザクション情報を参照する
</p>
<p>
    このウェブサイトで通貨取引情報を閲覧するときは、価格、手数料、手数料、売買の方向性など、取引情報に含まれるすべての内容を注意深く読み、含まれるすべての内容を完全に受け入れる必要があります。取引情報の内容後、ボタンをクリックして取引することができます。 。
</p>
<p>
    <br/>
</p>
<p>
    4.3.1.2注文の送信
</p>
<p>
    取引情報を表示し、それが正しいことを確認した後、取引注文を送信できます。取引委託を提出した後、あなたはこのウェブサイトがあなたに代わって対応する取引マッチングを行うことを承認します。このウェブサイトは、事前に通知することなく、委託価格を満たす取引がある場合、自動的にマッチング取引を完了します。 。
</p>
<p>
    <br/>
</p>
<p>
    4.3.1.3トランザクションの詳細を表示する
</p>
<p>
    管理センターの取引詳細で対応する取引記録を確認して、詳細な取引記録を確認することができます。
</p>
<p>
    <br/>
</p>
<p>
    4.3.1.4注文をキャンセル/変更するには、注文が取引に達する前であればいつでも注文を取り消すか変更する権利があります。
</p>
<p>
    <br/>
</p>
<p>
    V.このウェブサイトの権利と義務
</p>
<p>
    5.1本契約に定める登録資格をお持ちでない場合、当ウェブサイトはご登録をお断りする権利を有します。既にご登録いただいている方は、当ウェブサイトが会員アカウントを解約する権利を有します。当ウェブサイトは、お客様を保持する権利を留保します。またはあなたの許可された代理人に責任があります。責任の権利。同時に、このウェブサイトは、他の状況で登録を受け入れるかどうかを決定する権利を留保します。 。
</p>
<p>
    <br/>
</p>
<p>
    5.2このウェブサイトは、アカウントユーザーがアカウントの最初の登録者ではないことが判明した場合、アカウントの使用を一時停止または終了する権利を有します。 。
</p>
<p>
    <br/>
</p>
<p>
    5.3このウェブサイトは、技術テスト、手動サンプリング、およびその他のテスト方法を通じて、お客様が提供する情報が間違っている、不正確である、無効である、または不完全であると合理的に疑う場合、情報を修正または更新するか、提供を一時停止または終了するように通知する権利を有します。このウェブサイトのサービスの。 。
</p>
<p>
    <br/>
</p>
<p>
    5.4このウェブサイトは、明らかなエラーを発見した場合、このウェブサイトに表示されている情報を修正する権利を留保します。 。
</p>
<p>
    <br/>
</p>
<p>
    5.5このウェブサイトは、誤った取引が見つかった場合に、誤った取引の実行者または/および受信者（受益者）を確認する権利を有します。誤った取引として確認された場合、誤った取引の受信者（受益者） ）協力するものとします。本契約に限定されます。受領者（受益者）が返品を拒否するだけでなく、通知を受け取った後に誤った取引内容を悪意を持って転送または販売した場合、仲裁を開始するか、司法の強制措置（保存および差し押さえなど）を行うことを規定します。当ウェブサイトまたはスタッフから、上記の措置を講じるほか、当ウェブサイトは、受取人（受益者）に対し、不正取引の内容に応じて当初決定した通貨および金額の返還を求める権利を有します。譲渡・販売された付加価値部分を譲渡・販売する受取人（受益者）は、その他の不当な富化の成果とともに返還するものとします。
</p>
<p>
    <br/>
</p>
<p>
    5.6このウェブサイトは、いつでもこのウェブサイトのサービスを変更、一時停止、または終了する権利を留保し、このウェブサイトは、サービスを変更または一時停止する権利を行使するために事前に通知する必要はありません。終了通知。 。
</p>
<p>
    <br/>
</p>
<p>
    5.7当ウェブサイトは、当ウェブサイトの正常な運用を確保するために必要な技術的手段および管理措置を講じ、デジタル資産取引の秩序を維持するために必要かつ信頼できる取引環境および取引サービスを提供するものとします。 。
</p>
<p>
    <br/>
</p>
<p>
    5.8このウェブサイトは、技術投資の強化、セキュリティ対策の改善などによりデジタル資産のセキュリティを保護し、アカウントに予見可能なセキュリティリスクがある場合は事前に通知する義務があります。 。
</p>
<p>
    <br/>
</p>
<p>
    5.9このウェブサイトは、主権国または地域の法律、規制、規則、命令、およびその他の規範の要件に従って、より多くの情報または資料を提供するように要求し、地域の規範の要件を満たすために合理的な措置を講じる権利を有します。あなたは協力する義務があります;このウェブサイトは、あなたの主権の法律、規制、規則、命令および他の規範の要件に従って、あなたへのこのウェブサイトおよびそのサービスの一部またはすべての開設を一時停止または永久に停止する権利を有します国や地域。 。
</p>
<p>
    <br/>
</p>
<p>
    6.補償
</p>
<p>
    6.1いかなる場合も、お客様の直接的な損害に対する当社の責任は、3か月間のWebサイトサービスの使用の総費用を超えないものとします。
</p>
<p>
    <br/>
</p>
<p>
    6.2本契約またはその他の法令等に違反した場合。少なくとも200万米ドルを補償し、そこから生じるすべての費用（弁護士費用などを含む）を負担する必要があります。実際の損失をカバーするのに十分でない場合は、それを完了する必要があります。 。
</p>
<p>
    <br/>
</p>
<p>
    7.差し止めによる救済を求める権利
</p>
<p>
    私たちとあなたの両方は、契約違反または違反の可能性に対するコモンローの救済は、私たちが被ったすべての損失をカバーするのに十分ではない可能性があることを認めます。違反または違反の可能性のある法律で許可されている他のすべての救済策。 。
</p>
<p>
    <br/>
</p>
<p>
    8.責任の制限および免責事項
</p>
<p>
    8.1お客様は、いかなる状況においても、当社が以下の責任を負わないことを理解し、同意するものとします。
</p>
<p>
    <br/>
</p>
<p>
    8.1.1収入の損失;
</p>
<p>
    <br/>
</p>
<p>
    8.1.2取引利益または契約損失。
</p>
<p>
    <br/>
</p>
<p>
    8.1.3サービスの中断
</p>
<p>
    <br/>
</p>
<p>
    8.1.4予想される金銭的節約の喪失。
</p>
<p>
    <br/>
</p>
<p>
    8.1.5情報の損失。
</p>
<p>
    <br/>
</p>
<p>
    8.1.6機会、信用または評判の喪失。
</p>
<p>
    <br/>
</p>
<p>
    8.1.7データの破損または損失。
</p>
<p>
    <br/>
</p>
<p>
    8.1.8代替製品またはサービスの購入費用。
</p>
<p>
    <br/>
</p>
<p>
    8.1.9不法行為（過失を含む）、契約違反、またはその他の原因から生じる間接的、特別、または偶発的な損失または損害。そのような損失または損害が当社によって合理的に予見できるかどうかにかかわらず、当社が事前にそのような損失または損傷の可能性が存在します。
</p>
<p>
    <br/>
</p>
<p>
    8.1.1項から8.1.9項は、互いに独立しています。
</p>
<p>
    <br/>
</p>
<p>
    8.2お客様は、以下の状況のいずれかから生じるお客様へのいかなる損害についても当社が責任を負わないことを理解し、同意するものとします。
</p>
<p>
    <br/>
</p>
<p>
    8.2.1当社には、お客様の特定の取引に重大な法律違反または契約違反がある可能性があると信じる合理的な根拠があります。 。
</p>
<p>
    <br/>
</p>
<p>
    8.2.2このウェブサイトでのあなたの行為が違法または不適切であると疑われると信じる合理的な理由があります。 。
</p>
<p>
    <br/>
</p>
<p>
    8.2.3このウェブサイトのサービスまたは代替行為を通じたデータ、情報、または取引の購入または取得から生じる費用および損失。 。
</p>
<p>
    <br/>
</p>
<p>
    8.2.4このウェブサイトのサービスに対するあなたの誤解。
</p>
<p>
    <br/>
</p>
<p>
    8.2.5当社が原因ではない、このウェブサイトによって提供されるサービスに関連するその他の損失。 。
</p>
<p>
    <br/>
</p>
<p>
    8.3当社は、情報ネットワーク機器、情報ネットワーク接続障害、コンピューター、通信またはその他のシステム障害、電源障害、天候、事故、ストライキ、労働争議、暴動、暴動、暴動、生産性または生産資材の不足、火災、洪水、暴風雨、爆発、戦争、銀行またはその他のパートナーの理由、デジタル資産市場の崩壊、政府の行動、司法または行政命令、当社の管理または管理能力を超えたその他の行為、または第三者サービスを提供または遅延させることができないこと、およびお客様の損失に対する責任。 。
</p>
<p>
    <br/>
</p>
<p>
    8.4このウェブサイトに含まれるすべての情報、プログラム、テキストなどが完全に安全であり、ウイルスやトロイの木馬などの悪意のあるプログラムによって妨害および破壊されないことを保証することはできません。ログインして使用してください。このウェブサイトのサービス、またはウェブサイトからダウンロードしたプログラムをダウンロードして使用すること、情報、データなどはすべてあなたの個人的な決定であり、あなた自身のリスクと起こり得る損失を負担します。 。
</p>
<p>
    <br/>
</p>
<p>
    8.5当社は、本ウェブサイトにリンクされている第三者のウェブサイトの情報、製品、ビジネス、または当社の主題に属さないその他のコンテンツについて、いかなる保証および約束も行いません。サービスを利用する場合、第三者が提供する情報-パーティーのウェブサイトや製品などはあなたの個人的な決定であり、そこから生じるすべての責任を負います。 。
</p>
<p>
    <br/>
</p>
<p>
    8.6当社は、本ウェブサイトが提供するサービスの適用可能性、誤りまたは脱落の欠如、継続性、正確性、信頼性、および適合性を含むがこれらに限定されない、本ウェブサイトのサービスの使用について明示または黙示の保証を行いません。特定の目的のために。同時に、当社は、このウェブサイトが提供するサービスに関連する技術および情報の有効性、正確性、正確性、信頼性、品質、安定性、完全性および適時性について、いかなる約束および保証も行いません。ログインするか、このウェブサイトが提供するサービスを使用するかはあなたの個人的な決定であり、あなた自身の責任と損失の可能性があります。私たちは、デジタル資産の市場、価値、価格について明示的または黙示的な保証を行いません。デジタル資産市場は不安定であり、価格と価値はいつでも変動または崩壊する可能性があることを理解し、理解してください。デジタル資産の取引はあなたの個人的な自由あなた自身のリスクと起こりうる損失で選択し、決定してください。 。
</p>
<p>
    <br/>
</p>
<p>
    8.7本契約に定められた当社の保証および約束は、本契約および本ウェブサイトによって提供されるサービスに関する当社の唯一の保証および表明であり、書面または口頭、明示または暗黙的。そのようなすべての保証および表明は、当社自身の約束および保証のみを表し、第三者が本契約に含まれる保証および約束を遵守することを保証するものではありません。 。
</p>
<p>
    <br/>
</p>
<p>
    8.8当社は、法律で適用される最大限の範囲で、損害に対する当社の責任を制限、除外、または相殺するために、本契約に記載されていない権利を放棄しません。 。
</p>
<p>
    <br/>
</p>
<p>
    8.9登録することにより、お客様は、本契約に定められた規則に従って当社が実行するすべての操作に同意するものとし、そこから生じるリスクはすべてお客様が負担するものとします。 。
</p>
<p>
    <br/>
</p>
<p>
    9.契約の終了
</p>
<p>
    9.1このウェブサイトは、この契約に従ってこのウェブサイトのアカウントをキャンセルする権利を有し、この契約はアカウントのキャンセル日に終了します。
</p>
<p>
    <br/>
</p>
<p>
    9.2本ウェブサイトは、本契約に基づき、本ウェブサイトのすべてのサービスを終了する権利を有します。本契約は、本ウェブサイトのすべてのサービスが終了した日に終了するものとします。 。
</p>
<p>
    <br/>
</p>
<p>
    9.3本契約の終了後、お客様は、このWebサイトにサービスの提供を継続すること、またはその他の義務を実行することを要求する権利はありません。これには、このWebサイトが元のWebサイトアカウントの情報を保持または開示することを要求することを含みますが、これに限定されません。第三者は、読んだり送信したりしていない情報などを転送します。 。
</p>
<p>
    <br/>
</p>
<p>
    9.4本契約の終了は、違反当事者からの他の責任を求める非遵守当事者の要求に影響を与えません。
</p>
<p>
    <br/>
</p>
<p>
    10.知的財産権
</p>
<p>
    10.1このウェブサイトに含まれるすべての知的成果には、ウェブサイトのロゴ、データベース、ウェブサイトのデザイン、テキストとグラフィック、ソフトウェア、写真、ビデオ、音楽、サウンドとそれらの組み合わせ、ソフトウェアのコンパイル、関連するソースコードとソフトウェア（小さなものを含む）が含まれますが、これらに限定されません。アプリケーションとスクリプト）は、このWebサイトが所有しています。前述の資料またはコンテンツを商業目的で複製、変更、コピー、送信、または使用することはできません。 。
</p>
<p>
    <br/>
</p>
<p>
    10.2このウェブサイトの名前に含まれるすべての権利（のれん、商標、ロゴを含むがこれらに限定されない）は会社が所有します。
</p>
<p>
    <br/>
</p>
<p>
    10.3本契約への同意は、複製権、配布権、レンタル権、展示権、演奏権、展示権、放送を含むがこれらに限定されない、このウェブサイトで公開されているあらゆる形式の情報の著作権を自発的に主張するものとみなされます。権利、情報ネットワークの普及権、撮影権、適応権、翻訳権、編集権、および著作権所有者が享受すべきその他の譲渡権は、このウェブサイトに独占的に無料で譲渡されます。この契約の有効性は、コンテンツがこの契約の署名の前または後に形成されているかどうかにかかわらず、このWebサイトで公開する著作権法によって保護されている作品のコンテンツに適用されます。 。
</p>
<p>
    <br/>
</p>
<p>
    10.4あなたは、このウェブサイトのサービスを使用する過程で、このウェブサイトまたは他者の知的財産権を違法に使用または処分してはなりません。他のウェブサイト（およびメディア）がこのウェブサイトで公開されている情報をいかなる形式でも使用することを公開または許可することはできません。 。
</p>
<p>
    <br/>
</p>
<p>
    10.5このウェブサイトへのログインまたはこのウェブサイトによって提供されるサービスの使用は、当社によるあなたへの知的財産権の譲渡とはみなされないものとします。
</p>
<p>
    <br/>
</p>
<p>
    他の11人
</p>
<p>
    11.1可分性
</p>
<p>
    本契約のいずれかの条項が管轄裁判所によって執行不能、無効、または違法であると判断された場合でも、本契約の残りの条項の有効性に影響を与えることはありません。
</p>
<p>
    <br/>
</p>
<p>
    11.2非代理店関係
</p>
<p>
    本契約のいかなる条項も、本契約に別段の定めがある場合を除き、当社をお客様の代理人、受託者、またはその他の代表者として作成、暗示、またはその他の方法で扱うものとは見なされないものとします。
</p>
<p>
    <br/>
</p>
<p>
    11.3権利放棄
</p>
<p>
    当社またはお客様のいずれかによる契約違反に対する責任または本契約に規定されているその他の責任の放棄は、契約違反に対するその他の責任の放棄とは見なまたは解釈されないものとします。権利または救済を行使しなかった場合は、いずれにせよ、そのような権利の放棄または救済策はあきらめると解釈されます
</p>
<p>
    <br/>
</p>
<p>
    11.4タイトル
</p>
<p>
    すべての見出しは便宜上提供されており、本契約の条件の内容または範囲を拡大または制限することを意図したものではありません。
</p>
<p>
    <br/>
</p>
<p>
    11.5紛争の解決
</p>
<p>
    契約の履行および使用プロセスから生じる紛争は、両当事者が友好的に交渉する必要があります。
</p>
<p>
    <br/>
</p>
<p>
    11.契約の有効性と解釈
</p>
<p>
    12.1この契約は、このWebサイトの登録ページをクリックして登録に同意し、登録プロセスを完了し、このWebサイトのアカウントとパスワードを取得し、このWebサイトとあなたを拘束する場合に有効になります。
</p>
<p>
    <br/>
</p>
<p>
    12.2本契約は、登録が成功した時点で有効になります。
</p>
<p>
    <br/>
</p>
<p>
    12.3本契約の最終的な解釈権は、このWebサイトに帰属します。
</p>
<p>
    <br/>
</p>`,
}