export default `<p>
    <strong>COMP (화합물)</strong>
</p>
<p>
    <br/>
</p>
<p>
    1. 프로젝트 이름
</p>
<p>
    화합물 (Comp)
</p>
<p>
    <br/>
</p>
<p>
    2. 프로젝트 포지셔닝
</p>
<p>
    Compound는 개발자가 일련의 개방형 금융 애플리케이션을 잠금 해제 할 수 있도록 구축 된 알고리즘의 자율적 인 금리 계약입니다. Compound는 이더 리움을 기반으로 공개적으로 액세스 할 수있는 스마트 계약 시스템으로, 대출자는 대출을 제공하는 동안 암호화 된 자산을 계약에 잠가 대출을받을 수 있도록합니다. 차용자와 대출자가 지불하고받는 이자율은 암호화 된 각 자산의 수요와 공급에 따라 결정됩니다. 각 블록을 채굴하면 이자율이 생성됩니다. 대출은 언제든지 상환 할 수 있으며 잠긴 자산은 인출 할 수 있습니다.
</p>
<p>
    에
</p>
<p>
    세, 통화 개요 및 배포
</p>
<p>
    총 토큰 : 10,000,000 COMP
</p>
<p>
    유통량 : 2,561,279 COMP
</p>
<p>
    에
</p>
<p>
    네, 일반적으로 사용되는 링크
</p>
<p>
    프로젝트 웹 사이트 : https://compound.finance/
</p>
<p>
    백서 : https://compound.finance/documents/Compound.Whitepaper.pdf
</p>
<p>
    블록 쿼리 : https://etherscan.io/token/0xc00e94cb662c3520282e6f5717214004a7f26888
</p>
<p>
    <br/>
</p>`