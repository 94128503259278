export default `<p>
    <strong>TRX（トロン）</strong>
</p>
<p>
    <br/>
</p>
<p>
    <strong>1.プロジェクト名</strong>
</p>
<p>
    TRON：インターネットを再分散化させましょう
</p>
<p>
    <br/>
</p>
<p>
    <strong>2.プロジェクト紹介</strong>
</p>
<p>
    TRONは、インターネットの分散化を促進することを使命とし、分散型インターネットのインフラストラクチャの構築に取り組んでいます。そのTRONプロトコルは、世界最大のブロックチェーンベースの分散型アプリケーションオペレーティングシステムプロトコルの1つであり、プロトコル上での分散型アプリケーションの操作に対するパブリックチェーンサポートの基礎となる高スループット、高スケーラビリティ、および高信頼性を提供します。 TRONはまた、革新的なプラグ可能なスマートコントラクトプラットフォームを通じて、イーサリアムスマートコントラクトの互換性を向上させます。
</p>
<p>
    2018年7月24日以降、TRONはサンフランシスコを拠点とするインターネットテクノロジー企業であるBitTorrentInc。を買収しました。 BitTorrent Inc.によって設計された分散テクノロジーは、効果的に拡張し、インテリジェントな状態を維持し、作成者と消費者がコンテンツとデータを制御できるようにします。毎月1億7000万人以上がBitTorrentInc。が開発した製品を使用しています。 BitTorrent Inc.のプロトコルは、世界のインターネットトラフィックの40％を毎日送信できます。
</p>
<p>
    に
</p>
<p>
    <strong>3.トークンの概要と配布</strong>
</p>
<p>
    合計トークン：99,214,764,539.60352 TRX
</p>
<p>
    循環：66,682,072,191 TRX
</p>
<p>
    に
</p>
<p>
    <strong>4つの一般的に使用されるリンク</strong>
</p>
<p>
    プロジェクトのウェブサイト：https：//tron.network
</p>
<p>
    ホワイトペーパー：https：//s3.amazonaws.com/tron.network/%E7%AE%80%E4%BB%8B%E4%B8%8E%E6%84%BF%E6%99%AF.pdf
</p>
<p>
    ブロッククエリ：https：//tronscan.org/#/ https://www.trxplorer.io/
</p>
<p>
    <br/>
</p>`