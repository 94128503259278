export default `<h2 style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:48px">
<strong><span style="font-family: 宋体;font-size: 16px;background: rgb(255, 255, 255)">DCR (Decred)</span></strong>
</h2>
<p style=";padding: 0;line-height: 48px;background: rgb(255, 255, 255)">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: &#39;Times New Roman&#39;">1<span style="font-family:宋体">、 项目名称</span></span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">Decred (DCR)</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: &#39;Times New Roman&#39;"><span style="font-family:宋体">二、项目定位</span></span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">Decred<span style="font-family:宋体">与比特币类似，强调社区投入，开放治理以及可持续的资金和开发。</span><span style="font-family:Calibri">DCR</span><span style="font-family:宋体">利用先进的</span><span style="font-family:Calibri">PoW + PoS</span><span style="font-family:宋体">混合机制来确保小数人不能在没有社区投入的情况下主宰交易流程或对</span><span style="font-family:Calibri">Decred</span><span style="font-family:宋体">进行修改。</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: &#39;Times New Roman&#39;"><span style="font-family:宋体">三、币概况与分配</span></span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">代币总量：</span> &nbsp;21,000,000</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">流通数量：</span> <span style="font-family:宋体">约</span>7,900,000 (<span style="font-family:宋体">每次新区块产生的奖励</span><span style="font-family:Calibri">60%</span><span style="font-family:宋体">属于</span><span style="font-family:Calibri">PoW</span><span style="font-family:宋体">矿工</span><span style="font-family:Calibri">, 30%</span><span style="font-family:宋体">属于</span><span style="font-family:Calibri">PoS</span><span style="font-family:宋体">投票者</span><span style="font-family:Calibri">, 10%</span><span style="font-family:宋体">属于</span><span style="font-family:Calibri">Decred</span><span style="font-family:宋体">研发</span><span style="font-family:Calibri">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:宋体">补贴</span><span style="font-family:Calibri">)</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: &#39;Times New Roman&#39;"><span style="font-family:宋体">四、常用链接</span></span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">项目网站</span>: &nbsp;&nbsp;</span><a href="https://decred.org/"><span style=";font-family:&#39;Times New Roman&#39;;color:rgb(53,124,225)">https://decred.org/</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">白皮书：</span></span><a href="https://decred.org/dtb001.pdf"><span style=";font-family:&#39;Times New Roman&#39;;color:rgb(53,124,225)">https://decred.org/dtb001.pdf</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">区块查询</span>: &nbsp;</span><a href="https://mainnet.decred.org/"><span style=";font-family:&#39;Times New Roman&#39;;color:rgb(53,124,225)">https://mainnet.decred.org/</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: &#39;Times New Roman&#39;"><span style="font-family:宋体">五、币种所在分区</span></span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">创新区</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p>
<span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
<p>
<br/>
</p>`