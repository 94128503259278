<template>
  <div class="route-box">
    <section>
      <div>
        <div class="pub-layout">
          <!-- 币种列表 -->
          <div class="pub-layout-title-header pb-1 pub-layout-title">
            <div class=" box pub-header-title is-flex py-3 px-3 header-title-body">
              <div class="is-flex pub-header-title-x">
                <div class="has-text-1 px-3 header-title-bor cursor-pointer spottick-prz-text-1">
                  <p class="">
                    <span class="header-title-futurelist">BTC</span>
                    <span class="has-text-3 px-1 spottick-text-color-4">/</span>
                    <span class="has-text-3 spottick-text-color-4">USDT永续</span>
                  </p>
                  <p class="header-title-text font-weight-bold is-flex">
                      <span class="heaer-title-span header-title-conent is-flex has-text-success">
                        <span class="header-title-span-text">59210.2</span>
                        <i class="iconfont spottick-prz-text-1 header-title-span-icon icon-rise"></i>
                      </span>
                    <span class="header-title-conent ">
                        <span class=" has-text-success">+</span>
                        <span class=" has-text-success">0.46%</span>
                      </span>
                  </p>
                  <hr class="my-0 has-background-hr1">
                </div>
                <div class="has-text-1 px-3 header-title-bor cursor-pointer spottick-prz-text-1">
                  <p class="">
                    <span class="header-title-futurelist">BTC</span>
                    <span class="has-text-3 px-1 spottick-text-color-4">/</span>
                    <span class="has-text-3 spottick-text-color-4">XUSD永续</span>
                  </p>
                  <p class="header-title-text font-weight-bold is-flex">
                      <span class="heaer-title-span header-title-conent is-flex has-text-danger">
                        <span class="header-title-span-text">59216.9</span>
                        <i class="iconfont spottick-prz-text-1 header-title-span-icon icon-fall"></i>
                      </span>
                    <span class="header-title-conent ">
                        <span class=" has-text-danger">-</span>
                        <span class=" has-text-danger">0.47%</span>
                      </span>
                  </p>
                  <hr class="my-0">
                </div>
                <div class="has-text-1 px-3 header-title-bor cursor-pointer spottick-prz-text-1"><p class=""><span
                    class="header-title-futurelist">ETH</span><span
                    class="has-text-3 px-1 spottick-text-color-4">/</span><span
                    class="has-text-3 spottick-text-color-4">USDT永续</span></p>
                  <p class="header-title-text font-weight-bold is-flex"><span
                      class="heaer-title-span header-title-conent is-flex "><span
                      class="header-title-span-text">4011.14</span><i
                      class="iconfont spottick-prz-text-1 header-title-span-icon  "></i></span><span
                      class="header-title-conent "><span class=" has-text-success">+</span><span
                      class=" has-text-success">1.87%</span></span></p>
                  <hr class="my-0">
                </div>
                <div class="has-text-1 px-3 header-title-bor cursor-pointer spottick-prz-text-1"><p class=""><span
                    class="header-title-futurelist">XRP</span><span
                    class="has-text-3 px-1 spottick-text-color-4">/</span><span
                    class="has-text-3 spottick-text-color-4">USDT永续</span></p>
                  <p class="header-title-text font-weight-bold is-flex"><span
                      class="heaer-title-span header-title-conent is-flex "><span
                      class="header-title-span-text">1.5716</span><i
                      class="iconfont spottick-prz-text-1 header-title-span-icon  "></i></span><span
                      class="header-title-conent "><span class=" has-text-success">+</span><span
                      class=" has-text-success">0.52%</span></span></p>
                  <hr class="my-0">
                </div>
                <div class="has-text-1 px-3 header-title-bor cursor-pointer spottick-prz-text-1"><p class=""><span
                    class="header-title-futurelist">EOS</span><span
                    class="has-text-3 px-1 spottick-text-color-4">/</span><span
                    class="has-text-3 spottick-text-color-4">USDT永续</span></p>
                  <p class="header-title-text font-weight-bold is-flex"><span
                      class="heaer-title-span header-title-conent is-flex "><span
                      class="header-title-span-text">10.590</span><i
                      class="iconfont spottick-prz-text-1 header-title-span-icon  "></i></span><span
                      class="header-title-conent "><span class=" has-text-danger"> </span><span
                      class=" has-text-danger">-0.65%</span></span></p>
                  <hr class="my-0">
                </div>
                <div class="has-text-1 px-3 header-title-bor cursor-pointer spottick-prz-text-1"><p class=""><span
                    class="header-title-futurelist">BCH</span><span
                    class="has-text-3 px-1 spottick-text-color-4">/</span><span
                    class="has-text-3 spottick-text-color-4">USDT永续</span></p>
                  <p class="header-title-text font-weight-bold is-flex"><span
                      class="heaer-title-span header-title-conent is-flex "><span
                      class="header-title-span-text">1485.74</span><i
                      class="iconfont spottick-prz-text-1 header-title-span-icon  "></i></span><span
                      class="header-title-conent "><span class=" has-text-success">+</span><span
                      class=" has-text-success">6.15%</span></span></p>
                  <hr class="my-0">
                </div>
                <div class="has-text-1 px-3 header-title-bor cursor-pointer spottick-prz-text-1"><p class=""><span
                    class="header-title-futurelist">ETC</span><span
                    class="has-text-3 px-1 spottick-text-color-4">/</span><span
                    class="has-text-3 spottick-text-color-4">USDT永续</span></p>
                  <p class="header-title-text font-weight-bold is-flex"><span
                      class="heaer-title-span header-title-conent is-flex "><span
                      class="header-title-span-text">124.649</span><i
                      class="iconfont spottick-prz-text-1 header-title-span-icon  "></i></span><span
                      class="header-title-conent "><span class=" has-text-danger"> </span><span
                      class=" has-text-danger">-0.40%</span></span></p>
                  <hr class="my-0">
                </div>
                <div class="has-text-1 px-3 header-title-bor cursor-pointer spottick-prz-text-1"><p class=""><span
                    class="header-title-futurelist">LTC</span><span
                    class="has-text-3 px-1 spottick-text-color-4">/</span><span
                    class="has-text-3 spottick-text-color-4">USDT永续</span></p>
                  <p class="header-title-text font-weight-bold is-flex"><span
                      class="heaer-title-span header-title-conent is-flex "><span
                      class="header-title-span-text">407.15</span><i
                      class="iconfont spottick-prz-text-1 header-title-span-icon  "></i></span><span
                      class="header-title-conent "><span class=" has-text-success">+</span><span
                      class=" has-text-success">16.45%</span></span></p>
                  <hr class="my-0">
                </div>
              </div>
            </div>
          </div>

          <!-- 币种信息 -->
          <div class="pub-layout-tick">
            <div class="pub-header-tick box is-flex pb-3">
              <div class="pub-header-tick-left">
                <div class="pub-header-tick-left-box1 is-flex">
                  <p class="tick-name px-3 spottick-prz-text-6 spottick-header-tick is-flex">
                    <span class=" has-text-1 header-title-futurelist">BTC</span>
                    <span class="has-text-3 px-2">/</span>
                    <span class="has-text-1 header-title-futurelist">USDT</span>
                    <span class="spottick-prz-text-7 spottick-name">永续</span>
                  </p>
                  <p class="tick-number has-text-1 px-3 spottick-prz-width has-text-success">
                    <span class="spottick-prz-text-6 font-weight-bold">58933.6</span>
                    <span class=" is-hidden-touch has-text-success">
                      <i class="icon-fall-rise iconfont icon-rise"></i>
                    </span>
                  </p>
                </div>
                <span class="is-hidden-touch spottick-prz-text-5 font-weight-bold header-tick-rf ">
                  <span class=" has-text-success">+</span>
                  <span class=" has-text-success">5.09%</span>
                </span>
                <table class="is-hidden-touch tick-info ml-2">
                  <tr class="">
                    <td class="pr-2">
                      <div class="">
                        <div class="mb-1">
                          <div class="my-tooltip dropdown is-hoverable ">
                            <div class="dropdown-trigger bdb-dashed spottick-text-color-4">指数价格</div>
                            <div
                                class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-center  ">
                              <div class="dropdown-content" style="">
                                <div class="dropdown-item tooltip-text-justify"><p>标的资产的价格，这里是EOS/USDT 永续的指数价格。</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <p class="has-text-1-important tick-info-number font-weight-bold">10.680</p>
                      </div>
                    </td>
                    <td class="pr-2">
                      <div class="">
                        <div class="mb-1">
                          <div class="my-tooltip dropdown is-hoverable ">
                            <div class="dropdown-trigger bdb-dashed spottick-text-color-4">标记价格</div>
                            <div
                                class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-center  ">
                              <div class="dropdown-content" style="">
                                <div class="dropdown-item tooltip-text-justify"><p>这是现在的标记价格。</p></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <p class="has-text-1-important font-weight-bold">10.688</p>
                      </div>
                    </td>
                    <td class="pr-2">
                      <div class="">
                        <p class="mb-1 spottick-text-color-4">24H最高</p>
                        <p class="has-text-1 tick-info-number has-text-1-important font-weight-bold">10.983</p>
                      </div>
                    </td>
                    <td class="pr-2">
                      <div class="">
                        <p class="mb-1 spottick-text-color-4">24H最低</p>
                        <p class="has-text-1 tick-info-number has-text-1-important font-weight-bold">9.104</p>
                      </div>
                    </td>
                    <td class="pr-2">
                      <div class="">
                        <p class="mb-1 spottick-text-color-4">24H成交量</p>
                        <p class="has-text-1 tick-info-number has-text-1-important font-weight-bold">26,648,169张</p>
                      </div>
                    </td>
                    <td class="pr-2">
                      <div class="">
                        <p class="mb-1 spottick-text-color-4">持仓量</p>
                        <p class="has-text-1 tick-info-number has-text-1-important font-weight-bold">209,659张</p>
                      </div>
                    </td>
                    <td class="pr-2">
                      <div class="mb-1">
                        <div class="my-tooltip dropdown is-hoverable ">
                          <div class="dropdown-trigger bdb-dashed spottick-text-color-4">资金费率</div>
                          <div
                              class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-center  ">
                            <div class="dropdown-content" style="">
                              <div class="dropdown-item tooltip-text-justify"><p>下次资金费率交换时间：2021-05-10 16:00:00</p>
                                <p>多头需要向空头补偿持仓价值的0.1847%</p></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p class="has-text-1-important font-weight-bold">0.1847%</p>
                    </td>
                    <td class="pr-2">
                      <div class="mb-1 spottick-text-color-4">
                        <div class="my-tooltip dropdown is-hoverable ">
                          <div class="dropdown-trigger bdb-dashed spottick-text-color-4">资金时间</div>
                          <div
                              class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-center  ">
                            <div class="dropdown-content" style="">
                              <div class="dropdown-item tooltip-text-justify"><p>到下个资金费率交换的剩余时间。</p></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p class="has-text-1-important font-weight-bold">02:27:18</p>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="spacer"></div>

              <div class="is-flex has-text-3 quotation-any px-2">
                <div class="px-2 quotation-right-show">
                  <div class="mb-1">
                    <div class="my-tooltip dropdown is-hoverable ">
                      <div class="dropdown-trigger bdb-dashed spottick-text-color-4">账户权益</div>
                      <div
                          class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-center  ">
                        <div class="dropdown-content" style="">
                          <div class="dropdown-item tooltip-text-justify">存入-取出+已实现盈亏+未实现盈亏</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p class="has-text-1 font-weight-bold">0.0000</p>
                </div>
                <div class="px-2 quotation-right-show">
                  <div class="mb-1">
                    <div class="my-tooltip dropdown is-hoverable ">
                      <div class="dropdown-trigger bdb-dashed spottick-text-color-4">未实现盈亏</div>
                      <div
                          class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-center  ">
                        <div class="dropdown-content" style="">
                          <div class="dropdown-item tooltip-text-justify">所有未平仓合约的当前盈亏</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p class="has-text-1 font-weight-bold">0.0000</p>
                </div>
                <div class="px-2 quotation-right-show">
                  <div class="mb-1">
                    <div class="my-tooltip dropdown is-hoverable ">
                      <div class="dropdown-trigger bdb-dashed spottick-text-color-4">全仓风险度</div>
                      <div
                          class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-left ">
                        <div class="dropdown-content" style="">
                          <div class="dropdown-item tooltip-text-justify"><p class="">
                            风险度是用来衡量全仓仓位风险的指标，当风险度达到100%时，将进入强平流程，请知悉风险！</p>
                            <p class="">风险度=(全仓维持保证金+委托保证金)/(账户权益-全仓未实现盈利-逐仓保证金)</p></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p class="has-text-1 font-weight-bold">0.00%</p>
                </div>
              </div>

            </div>
          </div>

          <!-- kline chart -->
          <div class="pub-layout-content is-clearfix ">
            <div class="pub-layout-content-left is-pulled-left is-clearfix pub-layout-content-kline-left">
              <div class="pub-layout-content-left-top columns is-flex">
                <div class="pub-layout-content-left-kline column py-1 pr-1">
                  <div class="pub-kline box">
                    <div class="pub-kline-left">
                      <div class="pub-kline-solid"></div>
                      <div class="pub-kline-linellae">
                        <div class="pub-kline-marking"><i class="iconfont icon-Fibona has-text-2"></i></div>
                        <div class="pub-kline-marking"><i class="iconfont icon-HorizontalRays has-text-2"></i></div>
                        <div class="pub-kline-marking"><i class="iconfont icon-HorizontalLine has-text-2"></i></div>
                        <div class="pub-kline-marking"><i class="iconfont icon-HorizontalStraightLine has-text-2"></i>
                        </div>
                        <div class="pub-kline-marking"><i class="iconfont icon-ParallelLines has-text-2"></i></div>
                        <div class="pub-kline-marking"><i class="iconfont icon-PriceChannel has-text-2"></i></div>
                        <div class="pub-kline-marking"><i class="iconfont icon-PriceChannelLine has-text-2"></i></div>
                        <div class="pub-kline-marking"><i class="iconfont icon-PriceLine has-text-2"></i></div>
                        <div class="pub-kline-marking"><i class="iconfont icon-Rays has-text-2"></i></div>
                        <div class="pub-kline-marking"><i class="iconfont icon-LineSegment has-text-2"></i></div>
                        <div class="pub-kline-marking"><i class="iconfont icon-StraightLine has-text-2"></i></div>
                        <div class="pub-kline-marking"><i class="iconfont icon-VerticalRays has-text-2"></i></div>
                        <div class="pub-kline-marking"><i class="iconfont icon-VerticalLine has-text-2"></i></div>
                        <div class="pub-kline-marking"><i class="iconfont icon-Delete1 has-text-2"></i></div>
                      </div>
                      <div class="pub-kline-Tooltip" style="top: 52px; display: none;">斐波纳契回调</div>
                    </div>
                    <div class="pub-kline-btns buttons has-addons is-hidden-desktop">
                      <!-- 时间线 -->
                      <div class="dropdown is-hidden-desktop">
                        <div class="dropdown-trigger">
                          <button class="button kline-index-pad is-selected is-background-3 has-text-2">分时
                            <div class="spacer"></div>
                            <span class="icon">
                              <i class="iconfont icon-xiala has-text-primary is-size-7"></i>
                            </span>
                          </button>
                        </div>
                        <div class="dropdown-menu">
                          <div class="dropdown-content">
                            <button class="button has-text-2 is-flex-fullhd k-line-button1 ">分时</button>
                            <button class="button has-text-2 is-flex-fullhd k-line-button1 ">1分钟</button>
                            <button class="button has-text-2 is-flex-fullhd k-line-button1 is-unshow-title">3分钟</button>
                            <button class="button has-text-2 is-flex-fullhd k-line-button1 ">5分钟</button>
                            <button class="button has-text-2 is-flex-fullhd k-line-button1 ">15分钟
                            </button>
                            <button class="button has-text-2 is-flex-fullhd k-line-button1 ">30分钟</button>
                            <button class="button has-text-2 is-flex-fullhd k-line-button1 ">1时</button>
                            <button class="button has-text-2 is-flex-fullhd k-line-button1 ">2时</button>
                            <button class="button has-text-2 is-flex-fullhd k-line-button1 ">4时</button>
                            <button class="button has-text-2 is-flex-fullhd k-line-button1 ">1天</button>
                            <button class="button has-text-2 is-flex-fullhd k-line-button1 is-unshow-title">1周</button>
                            <button class="button has-text-2 is-flex-fullhd k-line-button1 is-unshow-title">1月</button>
                          </div>
                        </div>
                      </div>

                      <!-- 时间线 -->
                      <button class="button kline-index-pad is-selected is-background-3 has-text-2 has-text-primary">1分</button>
                      <button class="button kline-index-pad is-selected is-background-3 has-text-2">30分</button>
                      <button class="button kline-index-pad is-selected is-background-3 has-text-2">1时</button>
                      <i class="iconfont icon-Screenshot has-text-2 is-white k-line-button is-size-5"></i>
                      <button class="button is-background-2 has-text-2 is-white k-line-button kline-btn-backcolor">指标</button>
                    </div>
                    <!-- pc时间线-->
                    <div class="pub-kline-btns-pc is-hidden-touch">
                      <div class="pub-kline-btns-pc-time">
                        <button class="button has-text-2 is-flex-fullhd k-line-button1 ">分时</button>
                        <button class="button has-text-2 is-flex-fullhd k-line-button1 ">1分钟</button>
                        <button class="button has-text-2 is-flex-fullhd k-line-button1 is-unshow-title">3分钟</button>
                        <button class="button has-text-2 is-flex-fullhd k-line-button1 ">5分钟</button>
                        <button class="button has-text-2 is-flex-fullhd k-line-button1 has-text-primary ">15分钟</button>
                        <button class="button has-text-2 is-flex-fullhd k-line-button1 ">30分钟</button>
                        <button class="button has-text-2 is-flex-fullhd k-line-button1 ">1时</button>
                        <button class="button has-text-2 is-flex-fullhd k-line-button1 ">2时</button>
                        <button class="button has-text-2 is-flex-fullhd k-line-button1 ">4时</button>
                        <button class="button has-text-2 is-flex-fullhd k-line-button1 ">1天</button>
                        <button class="button has-text-2 is-flex-fullhd k-line-button1 is-unshow-title">1周</button>
                        <button class="button has-text-2 is-flex-fullhd k-line-button1 is-unshow-title">1月</button>
                      </div>
                      <div class="pub-kline-btns-pc-time-dropdown dropdown is-hidden-touch">
                        <div class="dropdown-trigger">
                          <button class="button is-white k-line-button kline-btn-backcolor">15分钟
                            <div class="spacer"></div>
                            <span class="icon"><i class="iconfont icon-xiala has-text-primary is-size-7"></i></span>
                          </button>
                        </div>
                        <div class="dropdown-menu">
                          <div class="dropdown-content"><a class="dropdown-item k-line-button1">分时</a><a
                              class="dropdown-item k-line-button1">1分钟</a><a
                              class="dropdown-item k-line-button1">3分钟</a><a
                              class="dropdown-item k-line-button1">5分钟</a><a
                              class="dropdown-item k-line-button1 has-text-primary">15分钟</a><a
                              class="dropdown-item k-line-button1">30分钟</a><a
                              class="dropdown-item k-line-button1">1时</a><a
                              class="dropdown-item k-line-button1">2时</a><a
                              class="dropdown-item k-line-button1">4时</a><a
                              class="dropdown-item k-line-button1">1天</a><a
                              class="dropdown-item k-line-button1">1周</a><a class="dropdown-item k-line-button1">1月</a>
                          </div>
                        </div>
                      </div>
                      <span class="has-text-2">|</span>
                      <button class="button is-background-2 has-text-2 is-white k-line-button kline-btn-backcolor">指标
                      </button>
                      <span class="has-text-2">|</span><i
                        class="iconfont icon-Screenshot has-text-2 is-white k-line-button is-size-5"></i><span
                        class="spacer"></span>
                      <button class="button is-white"><span class="icon"><i
                          class="iconfont iconfont-large icon-fullScreen"></i></span></button>
                    </div>

                    <hr class="is-hidden-touch">
                    <div class=" pub-kline-iframe">
                      <div id="tv_chart_container" class="">

                      </div>
                    </div>
                    <div class="pub-kline-technical-indicator modal" style="display: none;">
                      <div class="modal-background"></div>
                      <div class="pub-kline-indicator-main">
                        <div class="modal-card">
                          <header class="pub-stoppl-head modal-card-head borderBottom set-back">
                            <div class="modal-card-title spottick-prz-text-6 header-title-futurelist">
                              <p class="modal-v-opacity">XMEX</p>
                              <p>指标</p>
                            </div>
                            <button aria-label="close" class="delete"></button>
                          </header>
                          <div class="pub-indicator-content modal-card-body pub-padding-32 set-back">
                            <div class="pub-indicator-left">
                              <div class="dropdown-content">
                                <div>
                                  <div class=""><p
                                      class="dropdown-item k-line-button2 has-text-1 set-back font-weight-bold">主图</p><a
                                      class="dropdown-item k-line-button1 has-text-3 contract-information has-text-primary"><i
                                      class="icon iconfont icon-Success"></i>MA (移动平均线)</a><a
                                      class="dropdown-item k-line-button1 has-text-3 contract-information"><i
                                      class="icon iconfont icon-Success"></i>BOLL (布林线)</a><a
                                      class="dropdown-item k-line-button1 has-text-3 contract-information"><i
                                      class="icon iconfont icon-Success"></i>EMA (指数平滑移动平均线)</a><a
                                      class="dropdown-item k-line-button1 has-text-3 contract-information"><i
                                      class="icon iconfont icon-Success"></i>SAR (停损点指向指标)</a></div>
                                  <div class=""><p
                                      class="dropdown-item k-line-button2 has-text-1 set-back font-weight-bold">副图</p><a
                                      class="dropdown-item k-line-button1 has-text-3 contract-information"><i
                                      class="icon iconfont icon-Success"></i>MACD (指数平滑异同移动平均线)</a><a
                                      class="dropdown-item k-line-button1 has-text-3 contract-information"><i
                                      class="icon iconfont icon-Success"></i>KDJ (随机指标)</a><a
                                      class="dropdown-item k-line-button1 has-text-3 contract-information"><i
                                      class="icon iconfont icon-Success"></i>RSI (相对强弱指标)</a><a
                                      class="dropdown-item k-line-button1 has-text-3 contract-information"><i
                                      class="icon iconfont icon-Success"></i>CCI (顺势指标)</a><a
                                      class="dropdown-item k-line-button1 has-text-3 contract-information"><i
                                      class="icon iconfont icon-Success"></i>DMI (动向指标)</a><a
                                      class="dropdown-item k-line-button1 has-text-3 contract-information"><i
                                      class="icon iconfont icon-Success"></i>OBV (能量潮指标)</a><a
                                      class="dropdown-item k-line-button1 has-text-3 contract-information"><i
                                      class="icon iconfont icon-Success"></i>BIAS (乖离率)</a><a
                                      class="dropdown-item k-line-button1 has-text-3 contract-information"><i
                                      class="icon iconfont icon-Success"></i>BOLL (布林线)</a><a
                                      class="dropdown-item k-line-button1 has-text-3 contract-information"><i
                                      class="icon iconfont icon-Success"></i>BRAR (情绪指标)</a><a
                                      class="dropdown-item k-line-button1 has-text-3 contract-information"><i
                                      class="icon iconfont icon-Success"></i>CR (能量指标)</a><a
                                      class="dropdown-item k-line-button1 has-text-3 contract-information"><i
                                      class="icon iconfont icon-Success"></i>DMA (平行线差指标)</a><a
                                      class="dropdown-item k-line-button1 has-text-3 contract-information"><i
                                      class="icon iconfont icon-Success"></i>EMA (指数平滑移动平均线)</a><a
                                      class="dropdown-item k-line-button1 has-text-3 contract-information"><i
                                      class="icon iconfont icon-Success"></i>EMV (简易波动指标)</a><a
                                      class="dropdown-item k-line-button1 has-text-3 contract-information"><i
                                      class="icon iconfont icon-Success"></i>MA (移动平均线)</a><a
                                      class="dropdown-item k-line-button1 has-text-3 contract-information"><i
                                      class="icon iconfont icon-Success"></i>MTM (动量指标)</a><a
                                      class="dropdown-item k-line-button1 has-text-3 contract-information"><i
                                      class="icon iconfont icon-Success"></i>PSY (心理线)</a><a
                                      class="dropdown-item k-line-button1 has-text-3 contract-information"><i
                                      class="icon iconfont icon-Success"></i>SAR (停损点指向指标)</a><a
                                      class="dropdown-item k-line-button1 has-text-3 contract-information"><i
                                      class="icon iconfont icon-Success"></i>TRIX (三重指数平滑平均线)</a><a
                                      class="dropdown-item k-line-button1 has-text-3 contract-information"><i
                                      class="icon iconfont icon-Success"></i>VOL (成交量)</a><a
                                      class="dropdown-item k-line-button1 has-text-3 contract-information"><i
                                      class="icon iconfont icon-Success"></i>VR (成交量变异率)</a><a
                                      class="dropdown-item k-line-button1 has-text-3 contract-information"><i
                                      class="icon iconfont icon-Success"></i>WR (威廉指标)</a></div>
                                </div>
                              </div>
                            </div>
                            <div class="pub-indicator-right"><p
                                class="pub-indicator-title k-line-button2 has-text-1 font-weight-bold">参数设置</p>
                              <div class="pub-indicator-list"></div>
                            </div>
                          </div>
                          <footer class="pub-stoppl-foot modal-card-foot borderTop pub-padding-top32 set-back"
                                  style="justify-content: flex-end;">
                            <button class="button has-text-white is-outlined moudal-cancel pub-grey-but">恢复默认值</button>
                            <button class="button is-primary has-text-white moudal-confirm buttonWhite"
                                    style="width: 25% !important;">确定
                            </button>
                          </footer>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pub-layout-content-left-dish py-1 px-0">
                  <div class="is-background-2" style="height: 100%;">
                    <div class="pub-dish has-text-1">
                      <div class="pub-dish-box">
                        <div class="pub-dish-box-title has-text-1 is-background-3">委托列表</div>
                        <div class=" pub-dish-top"><p class="pub-dish-top-pic has-text-2"><span>价格</span> <span
                            class="">(USDT)</span></p>
                          <p class="pub-dish-top-num has-text-2"><span>数量</span><span class="">(张)</span></p>
                          <p class="pub-dish-top-time is-hidden-touch has-text-2"><span>总量</span><span
                              class="">(张)</span></p></div>
                        <div class="pub-dish-list-item is-flex">
                          <div class=""><p class="w100 has-text-left has-text-danger">58879.4</p></div>
                          <div class=""><p class="w100">580</p></div>
                          <div class="is-hidden-touch"><p class="w100 has-text-right">4401</p></div>
                          <div class="pub-dish-list-item-mode has-background-danger dynamic-effect"
                               style="width: 100%;"></div>
                        </div>
                        <div class="pub-dish-list-item is-flex">
                          <div class=""><p class="w100 has-text-left has-text-danger">58875.0</p></div>
                          <div class=""><p class="w100">1278</p></div>
                          <div class="is-hidden-touch"><p class="w100 has-text-right">3821</p></div>
                          <div class="pub-dish-list-item-mode has-background-danger dynamic-effect"
                               style="width: 86.8212%;"></div>
                        </div>
                        <div class="pub-dish-list-item is-flex">
                          <div class=""><p class="w100 has-text-left has-text-danger">58874.0</p></div>
                          <div class=""><p class="w100">1387</p></div>
                          <div class="is-hidden-touch"><p class="w100 has-text-right">2543</p></div>
                          <div class="pub-dish-list-item-mode has-background-danger dynamic-effect"
                               style="width: 57.7823%;"></div>
                        </div>
                        <div class="pub-dish-list-item is-flex">
                          <div class=""><p class="w100 has-text-left has-text-danger">58870.2</p></div>
                          <div class=""><p class="w100">718</p></div>
                          <div class="is-hidden-touch"><p class="w100 has-text-right">1156</p></div>
                          <div class="pub-dish-list-item-mode has-background-danger dynamic-effect"
                               style="width: 26.2668%;"></div>
                        </div>
                        <div class="pub-dish-list-item is-flex">
                          <div class=""><p class="w100 has-text-left has-text-danger">58863.7</p></div>
                          <div class=""><p class="w100">438</p></div>
                          <div class="is-hidden-touch"><p class="w100 has-text-right">438</p></div>
                          <div class="pub-dish-list-item-mode has-background-danger dynamic-effect"
                               style="width: 9.95228%;"></div>
                        </div>
                      </div>
                      <div class="pub-dish-box">
                        <div class="pub-dish-tick">
                          <div class="is-flex">
                            <div class="">
                              <span class="has-text-weight-semibold is-size-4 has-text-success">58863.3</span>
                              <span class="has-text-weight-semibold is-size-4 has-text-success">
                              <i class="iconfont icon-rise"></i>
                            </span>
                            </div>
                            <div class="spacer"></div>
                            <div class=""><span
                                class="has-text-weight-semibold is-size-5  has-text-success">+</span><span
                                class="has-text-weight-semibold is-size-5  has-text-success">0.67%</span></div>
                          </div>
                          <div class="is-flex has-text-3 ">
                            <div class=""><span class=" is-size-7 ">指数 58860.8</span></div>
                            <div class="spacer"></div>
                            <div class=""><span class=" is-size-7 ">标记 58861.4</span></div>
                          </div>
                        </div>
                        <div class="pub-dish-list-item is-flex">
                          <div class=""><p class="w100 has-text-left has-text-success">58858.2</p></div>
                          <div class=""><p class="w100">344</p></div>
                          <div class="is-hidden-touch"><p class="w100 has-text-right">344</p></div>
                          <div class="pub-dish-list-item-mode has-background-success dynamic-effect"
                               style="width: 10.2534%;"></div>
                        </div>
                        <div class="pub-dish-list-item is-flex">
                          <div class=""><p class="w100 has-text-left has-text-success">58855.5</p></div>
                          <div class=""><p class="w100">82</p></div>
                          <div class="is-hidden-touch"><p class="w100 has-text-right">426</p></div>
                          <div class="pub-dish-list-item-mode has-background-success dynamic-effect"
                               style="width: 12.6975%;"></div>
                        </div>
                        <div class="pub-dish-list-item is-flex">
                          <div class=""><p class="w100 has-text-left has-text-success">58854.5</p></div>
                          <div class=""><p class="w100">1231</p></div>
                          <div class="is-hidden-touch"><p class="w100 has-text-right">1657</p></div>
                          <div class="pub-dish-list-item-mode has-background-success dynamic-effect"
                               style="width: 49.389%;"></div>
                        </div>
                        <div class="pub-dish-list-item is-flex">
                          <div class=""><p class="w100 has-text-left has-text-success">58850.3</p></div>
                          <div class=""><p class="w100">1160</p></div>
                          <div class="is-hidden-touch"><p class="w100 has-text-right">2817</p></div>
                          <div class="pub-dish-list-item-mode has-background-success dynamic-effect"
                               style="width: 83.9642%;"></div>
                        </div>
                        <div class="pub-dish-list-item is-flex">
                          <div class=""><p class="w100 has-text-left has-text-success">58849.1</p></div>
                          <div class=""><p class="w100">538</p></div>
                          <div class="is-hidden-touch"><p class="w100 has-text-right">3355</p></div>
                          <div class="pub-dish-list-item-mode has-background-success dynamic-effect"
                               style="width: 100%;"></div>
                        </div>
                      </div>
                      <div class="pub-dish-box mt-1">
                        <div class="pub-dish-box-title has-text-1 is-background-3">最新成交</div>
                        <div class="pub-new-trade">
                          <div class="pub-new-trade-top"><p class="pub-new-trade-top-pic has-text-2">价格 (USDT)</p>
                            <p class="pub-new-trade-top-num has-text-2">数量(张)</p>
                            <p class="pub-new-trade-top-time has-text-2">时间</p></div>
                          <div class="pub-new-trade-list">
                            <div class="pub-new-trade-list-item level is-relative">
                              <div class=" w30"><p class="w100 has-text-left  has-text-danger">58863.3</p></div>
                              <div class=" w30"><p class="w100  has-text-1 pub-new-trade-new">833</p></div>
                              <div class=" w30"><p class="w100 has-text-2">14:35:09</p></div>
                            </div>
                            <div class="pub-new-trade-list-item level is-relative">
                              <div class=" w30"><p class="w100 has-text-left  has-text-danger">58876.0</p></div>
                              <div class=" w30"><p class="w100  has-text-1 pub-new-trade-new">339</p></div>
                              <div class=" w30"><p class="w100 has-text-2">14:35:07</p></div>
                            </div>
                            <div class="pub-new-trade-list-item level is-relative">
                              <div class=" w30"><p class="w100 has-text-left  has-text-success">58879.4</p></div>
                              <div class=" w30"><p class="w100  has-text-1 pub-new-trade-new">390</p></div>
                              <div class=" w30"><p class="w100 has-text-2">14:35:06</p></div>
                            </div>
                            <div class="pub-new-trade-list-item level is-relative">
                              <div class=" w30"><p class="w100 has-text-left  has-text-success">58879.4</p></div>
                              <div class=" w30"><p class="w100  has-text-1 pub-new-trade-new">1</p></div>
                              <div class=" w30"><p class="w100 has-text-2">14:35:06</p></div>
                            </div>
                            <div class="pub-new-trade-list-item level is-relative">
                              <div class=" w30"><p class="w100 has-text-left  has-text-danger">58871.6</p></div>
                              <div class=" w30"><p class="w100  has-text-1 pub-new-trade-new">2</p></div>
                              <div class=" w30"><p class="w100 has-text-2">14:35:06</p></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="dropdown pub-dish-select is-hidden">
                        <div class="dropdown-trigger">
                          <button aria-haspopup="" aria-controls="dropdown-menu2"
                                  class="button is-outline is-fullwidth">
                            <div><span class="">icon-maimaipan</span><span class="icon "><i aria-hidden=""
                                                                                            class="iconfont icon-xiala has-text-primary"></i></span>
                            </div>
                          </button>
                        </div>
                        <div id="dropdown-menu2" role="menu"
                             class="dropdown-menu max-height-500 scroll-y dropdown-pad-right">
                          <div class="dropdown-content has-text-centered">
                            <div class=""><a class="dropdown-item has-text-primary">icon-maimaipan</a></div>
                            <div class=""><a class="dropdown-item">icon-maipan1</a></div>
                            <div class=""><a class="dropdown-item">icon-maipan</a></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pub-layout-content-left-list  pt-0 pb-1 pr-0">
                <div class="pub-trade-list py-0 px-0  box">
                  <div class="pub-trade-list-tabs tabs spottick-prz-text-2 is-background-3 px-3">
                    <ul>
                      <li class="spottick-text-color-3  is-active"><a href="javascript:void(0);"
                                                                      class="spottick-text-color-3 ">持有仓位[0]</a></li>
                      <li class="spottick-text-color-3 "><a href="javascript:void(0);" class="spottick-text-color-3 ">当前委托[0]</a>
                      </li>
                      <li class="spottick-text-color-3 "><a href="javascript:void(0);"
                                                            class="spottick-text-color-3  is-hidden-touch">历史委托</a></li>
                      <li class="spottick-text-color-3 "><a href="javascript:void(0);"
                                                            class="spottick-text-color-3  is-hidden-touch">成交记录</a></li>
                      <li class="spottick-text-color-3 "><a href="javascript:void(0);"
                                                            class="spottick-text-color-3  is-hidden-touch">合约账单</a></li>
                    </ul>
                    <div class="pub-trade-list-tabs-dropdown ">
                      <div class="my-dropdown dropdown  is-hidden-mobile ">
                        <div class="dropdown-trigger has-text-1">
                          <button class="button dropdown-btn-bg dropdown-btn-h" style="width: 110px;display: none">
                            显示全部
                            <i class="my-trigger-icon iconfont icon-xiala has-text-primary"></i>
                          </button>
                        </div>
                        <div class="dropdown-menu" style="width: 110px;">
                          <div class="dropdown-content" style=""><a class="dropdown-item has-hover has-active">
                            <div class="my-menu-label">显示全部</div>
                            <i class="my-menu-icon iconfont icon-fabijiaoyiwancheng "></i></a><a
                              class="dropdown-item has-hover ">
                            <div class="my-menu-label">显示当前合约</div>
                            <i class="my-menu-icon iconfont icon-fabijiaoyiwancheng is-hidden"></i></a></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pub-pos px-3">
                    <div class=" table-container">
                      <div class="pub-table-head-box is-hidden" style="width: 1837px;">
                        <table width="1837px" cellpadding="0" cellspacing="0" class="table is-hoverable">
                          <colgroup>
                            <col name="pub-table-1" width="480">
                            <col name="pub-table-2" width="80">
                            <col name="pub-table-3" width="150">
                            <col name="pub-table-4" width="80">
                            <col name="pub-table-5" width="100">
                            <col name="pub-table-6" width="100">
                            <col name="pub-table-7" width="120">
                            <col name="pub-table-8" width="120">
                            <col name="pub-table-9" width="130">
                            <col name="pub-table-10" width="180">
                            <col name="pub-table-11" width="180">
                            <col name="pub-table-12" width="117">
                          </colgroup>
                          <tr class="">
                            <th class="table-title-font-w spottick-text-color-4 position-buttons pub-table-11">
                              <div class="my-tooltip dropdown is-hoverable ">
                                <div class="dropdown-trigger  spottick-text-color-4">
                                  <div class=" cursor-pointer">一键平仓</div>
                                </div>
                                <div
                                    class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-center  is-hidden ">
                                  <div class="dropdown-content" style="">
                                    <div class="dropdown-item tooltip-text-justify"></div>
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th class="table-title-font-w spottick-text-color-4 position-pid pub-table-1">
                              <div class="my-tooltip dropdown is-hoverable ">
                                <div class="dropdown-trigger  spottick-text-color-4">
                                  <div class="">仓位ID</div>
                                </div>
                                <div
                                    class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-center  is-hidden ">
                                  <div class="dropdown-content" style="">
                                    <div class="dropdown-item tooltip-text-justify"></div>
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th class="table-title-font-w spottick-text-color-4 position-sym pub-table-2">
                              <div class="my-tooltip dropdown is-hoverable ">
                                <div class="dropdown-trigger  spottick-text-color-4">
                                  <div class="">合约</div>
                                </div>
                                <div
                                    class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-center  is-hidden ">
                                  <div class="dropdown-content" style="">
                                    <div class="dropdown-item tooltip-text-justify"></div>
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th class="table-title-font-w spottick-text-color-4 position-sz pub-table-3">
                              <div class="my-tooltip dropdown is-hoverable ">
                                <div class="dropdown-trigger  spottick-text-color-4">
                                  <div class="">数量</div>
                                </div>
                                <div
                                    class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-center  is-hidden ">
                                  <div class="dropdown-content" style="">
                                    <div class="dropdown-item tooltip-text-justify"></div>
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th class="table-title-font-w spottick-text-color-4 position-prz pub-table-4">
                              <div class="my-tooltip dropdown is-hoverable ">
                                <div class="dropdown-trigger bdb-dashed spottick-text-color-4">
                                  <div class="">开仓均价</div>
                                </div>
                                <div
                                    class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-center  ">
                                  <div class="dropdown-content" style="">
                                    <div class="dropdown-item tooltip-text-justify">目前仓位的平均成交价格</div>
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th class="table-title-font-w spottick-text-color-4 position-przLiq pub-table-5">
                              <div class="my-tooltip dropdown is-hoverable ">
                                <div class="dropdown-trigger bdb-dashed spottick-text-color-4">
                                  <div class="">强平价格</div>
                                </div>
                                <div
                                    class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-center  ">
                                  <div class="dropdown-content" style="">
                                    <div class="dropdown-item tooltip-text-justify">合约标记价格穿过该价格时触发强制平仓</div>
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th class="table-title-font-w spottick-text-color-4 position-stoppl pub-table-10">
                              <div class="my-tooltip dropdown is-hoverable ">
                                <div class="dropdown-trigger  spottick-text-color-4">
                                  <div class="">止盈</div>
                                </div>
                                <div
                                    class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-center  is-hidden ">
                                  <div class="dropdown-content" style="">
                                    <div class="dropdown-item tooltip-text-justify"></div>
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th class="table-title-font-w spottick-text-color-4 position-stoppl pub-table-10">
                              <div class="my-tooltip dropdown is-hoverable ">
                                <div class="dropdown-trigger  spottick-text-color-4">
                                  <div class="">止损</div>
                                </div>
                                <div
                                    class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-center  is-hidden ">
                                  <div class="dropdown-content" style="">
                                    <div class="dropdown-item tooltip-text-justify"></div>
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th class="table-title-font-w spottick-text-color-4 position-mm pub-table-7">
                              <div class="my-tooltip dropdown is-hoverable ">
                                <div class="dropdown-trigger bdb-dashed spottick-text-color-4">
                                  <div class="">保证金</div>
                                </div>
                                <div
                                    class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-center  ">
                                  <div class="dropdown-content" style="">
                                    <div class="dropdown-item tooltip-text-justify">被仓位占用的保证金</div>
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th class="table-title-font-w spottick-text-color-4 position-upnl pub-table-8">
                              <div class="my-tooltip dropdown is-hoverable ">
                                <div class="dropdown-trigger bdb-dashed spottick-text-color-4">
                                  <div class="">未实现盈亏(回报率)</div>
                                </div>
                                <div
                                    class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-center  ">
                                  <div class="dropdown-content" style="">
                                    <div class="dropdown-item tooltip-text-justify">所持仓位的当前盈亏(回报率=未实现盈亏/保证金)</div>
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th class="table-title-font-w spottick-text-color-4 position-pnl pub-table-9">
                              <div class="my-tooltip dropdown is-hoverable ">
                                <div class="dropdown-trigger bdb-dashed spottick-text-color-4">
                                  <div class="">已实现盈亏</div>
                                </div>
                                <div
                                    class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-center  ">
                                  <div class="dropdown-content" style="">
                                    <div class="dropdown-item tooltip-text-justify">仓位自开仓以来的已实现盈亏，包括手续费，资金费用</div>
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th class="table-title-font-w spottick-text-color-4 position-rate pub-table-6">
                              <div class="my-tooltip dropdown is-hoverable ">
                                <div class="dropdown-trigger bdb-dashed spottick-text-color-4">
                                  <div class="">风险度</div>
                                </div>
                                <div
                                    class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-center  ">
                                  <div class="dropdown-content" style="">
                                    <div class="dropdown-item tooltip-text-justify">
                                      <div class=""><p class="">风险度是衡量仓位风险的指标，风险度达到100%时，将进入强平流程。</p>
                                        <p class="">全仓风险度=(全仓维持保证金+委托保证金)/(账户权益-全仓未实现盈利-逐仓保证金）</p>
                                        <p class="">逐仓风险度=(开仓价格-标记价格)/(开仓价格-强平价格)</p></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </th>
                          </tr>
                        </table>
                      </div>
                      <div class="pub-table-body-box" style="width: 1837px;">
                        <table width="1837px" cellpadding="0" cellspacing="0" class="table is-hoverable ">
                          <colgroup>
                            <col name="pub-table-1" width="480">
                            <col name="pub-table-2" width="80">
                            <col name="pub-table-3" width="150">
                            <col name="pub-table-4" width="80">
                            <col name="pub-table-5" width="100">
                            <col name="pub-table-6" width="100">
                            <col name="pub-table-7" width="120">
                            <col name="pub-table-8" width="120">
                            <col name="pub-table-9" width="130">
                            <col name="pub-table-10" width="180">
                            <col name="pub-table-11" width="180">
                            <col name="pub-table-12" width="117">
                          </colgroup>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pub-layout-content-right is-pulled-right py-0 pub-layout-content-kline-right">
              <div class="pub-layout-content-right-place-order py-1 pl-1">
                <div class="pub-place-order py-0 px-0 box">
                  <div class="pub-place-order-tabs tabs is-small limit-order">
                    <div class="is-flex px-3 is-background-3 pt-1">
                      <ul>
                        <li class="  is-active"><a href="javascript:void(0);" class="a-button-pad px-0 mr-5 ">普通</a>
                        </li>
                      </ul>
                      <div class="header-wit-balance">
                        <div class="pub-place-order-form-wallet is-flex field has-text-2 spottick-text-color-4">
                          <div class="order-get">可用</div>
                          <div class="spacer"></div>
                          <div class="order-get">0.0000</div>
                          <div class="order-get"></div>
                        </div>
                      </div>
                      <div class="header-wit-balance">
                        <div class="pub-header-tick-right-setting is-background-3"><span
                            class="icon is-medium cursor-pointer"><i aria-hidden=""
                                                                     class="iconfont icon-Calculator fas fa-2x has-text-1 icon-fixed"></i></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pub-place-order-form has-text-2 px-3 pb-3">
                    <div class="pub-place-order-form-lever-btns field"><a class="button is-small has-border">全仓</a><a
                        class="button is-small has-border">125x</a></div>
                    <div class="pub-place-order-form-prz-input field">
                      <div class="control has-flex">
                        <div class="control is-background-3">
                          <button
                              class="button input2 is-static2 is-background-3 has-text-1 is-left-radius text-font-flex text-font-flex-long has-text-double button-input-height">
                            价格
                          </button>
                        </div>
                        <input type="number" placeholder="请输入价格" step="0.1" pattern="d*"
                               class="input is-right-radius input2 text-font-flex button-input-height">
                        <div class="control is-background-3 cursor-pointer">
                          <button
                              class="button input2 is-static2 is-background-3 has-text-primary is-left-radius text-font-flex text-font-flex-long button-input-height">
                            市价
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="pub-place-order-form-num-input field">
                      <div class="control">
                        <div class="control has-flex">
                          <div class="control is-background-3">
                            <button
                                class="button input2 is-static2 is-background-3 has-text-1 is-left-radius text-font-flex text-font-flex-long has-text-double button-input-height">
                              数量
                            </button>
                          </div>
                          <input type="number" placeholder="请输入数量" step="1" pattern="d*"
                                 class="input is-right-radius input2 text-font-flex button-input-height">
                          <div class="control is-background-3">
                            <button
                                class="button input2 is-static2 is-background-3  has-text-1 is-left-radius text-font-flex text-font-flex-long button-input-height">
                              张
                            </button>
                          </div>
                        </div>
                        <span class="pub-place-order-form-num-input-face-value">= 0.00BTC(0.00USDT)</span></div>
                    </div>
                    <div class="pub-place-order-form-stop-pl field"><label
                        class="pub-place-order-form-stop-pl-label label has-text-1">市价止盈止损设置（选填）
                      <div class="my-tooltip dropdown is-hoverable tooltip-icon">
                        <div class="dropdown-trigger  pub-place-order-form-stop-pl-label label has-text-1"><span><i
                            class="iconfont icon-icon-test spottick-prz-text-2"></i></span></div>
                        <div
                            class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-center  content-width">
                          <div class="dropdown-content" style="">
                            <div class="dropdown-item tooltip-text-justify"><p>
                              预设止盈止损需要委托成交后才生效，默认按照您的持仓数量全部挂止盈止损单。如果您撤销挂单委托，预设止盈止损将同时失效。做多，当最新价≥触发价时，触发止盈；做多，当最新价≤触发价时，触发止损；做空反之。止盈止损触发后将以市价发出委托，若发出的委托不能立即全部成交，系统会撤掉剩余委托并再次以市价发出平仓委托，以此类推直到仓位全部被平掉，因此成交价格可能与设置的价格有偏差。</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </label>
                      <div class="pub-place-order-form-stop-pl-input field has-addons">
                        <div class="pub-place-order-form-stop-pl-input-p control is-expanded"><input type="number"
                                                                                                     placeholder="止盈触发价"
                                                                                                     step="0.1"
                                                                                                     pattern="d*"
                                                                                                     class="input text-font-flex button-input-height">
                        </div>
                        <div class="pub-place-order-form-stop-pl-input-center control">&amp;</div>
                        <div class="pub-place-order-form-stop-pl-input-l control is-expanded"><input type="number"
                                                                                                     placeholder="止损触发价"
                                                                                                     step="0.1"
                                                                                                     pattern="d*"
                                                                                                     class="input text-font-flex button-input-height">
                        </div>
                      </div>
                    </div>
                    <div class="pub-place-order-form-trigger-prz-input field">
                      <div class="control"><input type="number" placeholder="" readonly="" class="input opacity-0">
                      </div>
                    </div>
                    <div class="spacer"></div>
                    <div class="pub-place-order-form-buttons field">
                      <div class="level-item">
                        <div class="button-width">
                          <div class="button-default-width">
                            <button
                                class="button is-success is-fullwidth button-radius text-font-flex ord-buy-sell is-text-3">
                              买入/做多(看涨)
                            </button>
                            <div class="pub-place-order-form-need-mgn is-flex spottick-text-color-4">
                              <div class="">委托保证金</div>
                              <div class="spacer"></div>
                              <div class="">0.00000</div>
                            </div>
                          </div>
                        </div>
                        <div class="button-width">
                          <div class="button-default-width">
                            <button
                                class="button is-danger is-fullwidth button-radius text-font-flex ord-buy-sell is-text-3">
                              卖出/做空(看跌)
                            </button>
                            <div class="pub-place-order-form-need-mgn is-flex spottick-text-color-4">
                              <div class="">委托保证金</div>
                              <div class="spacer"></div>
                              <div class="">0.00000</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="is-size-7 field spottick-text-color-1">高级设置</div>
                    <div class="control is-between is-size-7"><label class="radio"><input type="radio"
                                                                                          name="radio83683173">
                      <div class="my-tooltip dropdown is-hoverable ">
                        <div class="dropdown-trigger bdb-dashed ">GTC</div>
                        <div
                            class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-center  ">
                          <div class="dropdown-content" style="">
                            <div class="dropdown-item tooltip-text-justify">GTC（GoodTillCancel）普通限价委托，一直有效直至全部成交或取消。
                            </div>
                          </div>
                        </div>
                      </div>
                    </label><label class="radio"><input type="radio" name="radio83683173">
                      <div class="my-tooltip dropdown is-hoverable ">
                        <div class="dropdown-trigger bdb-dashed ">FOK</div>
                        <div
                            class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-center  ">
                          <div class="dropdown-content" style="">
                            <div class="dropdown-item tooltip-text-justify">FOK（Fill or Kill）订单若不能全部成交则立即取消。</div>
                          </div>
                        </div>
                      </div>
                    </label><label class="radio"><input type="radio" name="radio83683173">
                      <div class="my-tooltip dropdown is-hoverable ">
                        <div class="dropdown-trigger bdb-dashed ">FAK</div>
                        <div
                            class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-center  ">
                          <div class="dropdown-content" style="">
                            <div class="dropdown-item tooltip-text-justify">FAK（Fill and Kill）订单若不能立即成交则未成交部分立即取消。</div>
                          </div>
                        </div>
                      </div>
                    </label><label class="radio"><input type="radio" name="radio83683173">
                      <div class="my-tooltip dropdown is-hoverable ">
                        <div class="dropdown-trigger bdb-dashed ">被动委托</div>
                        <div
                            class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-left ">
                          <div class="dropdown-content" style="">
                            <div class="dropdown-item tooltip-text-justify">被动委托（Post
                              Only）订单不会立刻在市场上成交，保证用户始终为Maker，如果委托会立即成交，那么该委托会被取消。
                            </div>
                          </div>
                        </div>
                      </div>
                    </label></div>
                  </div>
                </div>
              </div>
              <div class="pub-layout-content-right-wallet  py-0 pl-1">
                <div class="pub-wallet-box box has-text-centered px-0 py-0">
                  <div class="pub-wallet-box-tabs tabs is-background-3 px-3 ">
                    <ul class="is-between">
                      <li class=""><a class="has-text-1-important wlt-coin">合约资产</a></li>
                    </ul>
                  </div>
                  <div class="pub-wallet-box-tabs tabs is-hidden">
                    <ul>
                      <li class=""><a class="">合约资产</a></li>
                    </ul>
                  </div>
                  <div class="pub-wallet-box-btns is-between px-3"><a class="button is-small has-border">买币</a><a
                      class="button is-small has-border">卖币</a><a class="button is-small has-border">划转</a></div>
                  <div class="pub-wallet px-3 py-3">
                    <div class="pub-wallet-content has-text-left">
                      <div class="level-item">
                        <div class="text--secondary has-text-2">
                          <div class="my-tooltip dropdown is-hoverable ">
                            <div class="dropdown-trigger bdb-dashed spottick-text-color-4">账户权益</div>
                            <div
                                class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-center  ">
                              <div class="dropdown-content" style="">
                                <div class="dropdown-item tooltip-text-justify">存入-取出+已实现盈亏+未实现盈亏</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="has-text-1">0.00000000</div>
                      </div>
                      <div class="level-item">
                        <div class="text--secondary has-text-2">
                          <div class="my-tooltip dropdown is-hoverable ">
                            <div class="dropdown-trigger bdb-dashed spottick-text-color-4">未实现盈亏</div>
                            <div
                                class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-center  ">
                              <div class="dropdown-content" style="">
                                <div class="dropdown-item tooltip-text-justify">所有未平仓合约的当前盈亏</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="has-text-1 ">
                          <div class=" ">0.00000000</div>
                        </div>
                      </div>
                      <div class="level-item">
                        <div class="text--secondary has-text-2">
                          <div class="my-tooltip dropdown is-hoverable ">
                            <div class="dropdown-trigger bdb-dashed spottick-text-color-4">仓位保证金</div>
                            <div
                                class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-center  ">
                              <div class="dropdown-content" style="">
                                <div class="dropdown-item tooltip-text-justify">保留所持仓位所需要的保证金</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="has-text-1">0.00000000</div>
                      </div>
                      <div class="level-item">
                        <div class="text--secondary has-text-2">
                          <div class="my-tooltip dropdown is-hoverable ">
                            <div class="dropdown-trigger bdb-dashed spottick-text-color-4">委托保证金</div>
                            <div
                                class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-center  ">
                              <div class="dropdown-content" style="">
                                <div class="dropdown-item tooltip-text-justify">委托所需要的保证金</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="has-text-1">0.00000000</div>
                      </div>
                      <div class="level-item">
                        <div class="text--secondary has-text-2">
                          <div class="my-tooltip dropdown is-hoverable ">
                            <div class="dropdown-trigger bdb-dashed spottick-text-color-4">可用保证金</div>
                            <div
                                class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-center  ">
                              <div class="dropdown-content" style="">
                                <div class="dropdown-item tooltip-text-justify">当前可用于开仓的保证金</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="has-text-1">0.00000000</div>
                      </div>
                      <div class="level-item">
                        <div class="text--secondary has-text-2">
                          <div class="my-tooltip dropdown is-hoverable ">
                            <div class="dropdown-trigger bdb-dashed spottick-text-color-4">可用赠金</div>
                            <div
                                class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-center  ">
                              <div class="dropdown-content" style="">
                                <div class="dropdown-item tooltip-text-justify">当前可用于开仓的赠金</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="has-text-1">0.00000000</div>
                      </div>
                      <div class="level-item">
                        <div class="text--secondary has-text-2 spottick-text-color-4">保证金使用率</div>
                        <div class="has-text-1">0.00%</div>
                      </div>
                      <div class="level-item mb-0-important">
                        <div class="text--secondary">
                          <div class="my-tooltip dropdown is-hoverable ">
                            <div class="dropdown-trigger bdb-dashed spottick-text-color-4">全仓风险度</div>
                            <div
                                class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-center  ">
                              <div class="dropdown-content" style="">
                                <div class="dropdown-item tooltip-text-justify"><p class="">
                                  风险度是用来衡量全仓仓位风险的指标，当风险度达到100%时，将进入强平流程，请知悉风险！</p>
                                  <p class="">风险度=(全仓维持保证金+委托保证金)/(账户权益-全仓未实现盈利-逐仓保证金)</p></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="has-text-1">0.00%</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pub-layout-content-right-spot-info py-1 pl-1">
                <div class="pub-spot-info box px-0 py-0">
                  <div class="pub-spot-info-tabs tabs is-background-3 px-3">
                    <ul>
                      <li class=""><a href="javascript:void(0);" class="has-text-1-important wlt-coin">合约信息</a></li>
                    </ul>
                  </div>
                  <div class="pub-spot-info-content px-3 py-3">
                    <div class="level-item">
                      <div class="text--secondary spottick-text-color-4">合约名称</div>
                      <div class="has-text-1">BTC/USDT 永续</div>
                    </div>
                    <div class="level-item">
                      <div class="text--secondary spottick-text-color-4">到期日</div>
                      <div class="has-text-1">永续</div>
                    </div>
                    <div class="level-item">
                      <div class="text--secondary spottick-text-color-4">计价币种</div>
                      <div class="has-text-1">USDT</div>
                    </div>
                    <div class="level-item">
                      <div class="text--secondary spottick-text-color-4">结算币种</div>
                      <div class="has-text-1">USDT</div>
                    </div>
                    <div class="level-item">
                      <div class="text--secondary spottick-text-color-4">合约大小</div>
                      <div class="has-text-1">0.01 BTC / 张</div>
                    </div>
                    <div class="level-item">
                      <div class="text--secondary spottick-text-color-4">最小价格变动</div>
                      <div class="has-text-1">0.1</div>
                    </div>
                    <div class="level-item">
                      <div class="text--secondary spottick-text-color-4">最小数量变动</div>
                      <div class="has-text-1">1</div>
                    </div>
                    <div class="level-item mb-0-important">
                      <div class="text--secondary has-text-primary cursor-pointer">更多...</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="clear: both"></div>
          </div>
          <!-- -->

        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "Trade",
}
</script>
<style src="../../static/css/trade.css" scoped type="text/css"></style>