export default `<p>
    <strong>XMR (Monero)</strong>
</p>
<p>
    <br/>
</p>
<p>
    <strong>1. 프로젝트 이름</strong>
</p>
<p>
    Monero (XMR)
</p>
<p>
    <br/>
</p>
<p>
    <strong>2. 프로젝트 포지셔닝</strong>
</p>
<p>
    Monero는 암호화를 사용하여 비트 코인 및 이더 리움과 같은 다른 공개 블록 체인과는 다른 송수신 주소 및 거래 금액을 보호합니다.
</p>
<p>
    <br/>
</p>
<p>
    기본적으로 모든 Monero 거래는 송수신 주소와 거래 금액을 혼동합니다. Monero는 교체 가능합니다. 이것은 Monero가 조사의 위험없이 항상 받아 들여진다는 것을 의미합니다.
</p>
<p>
    <br/>
</p>
<p>
    Monroe는 회사가 아닙니다. 시간을 기부하거나 커뮤니티 기부로 자금을 지원하는 전 세계의 암호화 및 분산 시스템 전문가가 개발했습니다. 즉, Monroe는 어떤 국가에서도 폐쇄 될 수 없으며 특정 법적 관할권에 의해 제한되지 않습니다.
</p>
<p>
    <br/>
</p>
<p>
    <strong>3, 통화 개요 및 배포</strong>
</p>
<p>
    총 토큰 : 약 1600 만 XMR + 산업 및 광업
</p>
<p>
    유통량 : 100 %
</p>
<p>
    <br/>
</p>
<p>
    <strong>4, 일반적으로 사용되는 링크</strong>
</p>
<p>
    프로젝트 웹 사이트 : https://getmonero.org
</p>
<p>
    블록 쿼리 : https://xmrchain.net; https://moneroblocks.info; https://moneroexplorer.com
</p>
<p>
    기타 : https://www.reddit.com/r/Monero
</p>
<p>
    <br/>
</p>
<p>
    <strong>5. 통화가있는 구역</strong>
</p>
<p>
    메인 존
</p>
<p>
    <br/>
</p>`