export default `<p>
    <strong>MKR（メーカー）</strong>
</p>
<p>
    <br/>
</p>
<p>
    1.プロジェクト名
</p>
<p>
    MakerDAO（MKR）
</p>
<p>
    <br/>
</p>
<p>
    2.プロジェクトのポジショニング
</p>
<p>
    MakerDAOは、分散型金融（DeFi）のインフラストラクチャであるイーサリアムで最初の分散型安定通貨であるDaiを開発しました。 MKRは、Makerシステムのガバナンスおよびエクイティトークンです。
</p>
<p>
    <br/>
</p>
<p>
    3、通貨の概要と流通
</p>
<p>
    合計トークン：1,005,577 MKR
</p>
<p>
    総循環：1,005,577 MKR
</p>
<p>
    <br/>
</p>
<p>
    4つの一般的に使用されるリンク
</p>
<p>
    プロジェクトのウェブサイト：https：//makerdao.com
</p>
<p>
    ホワイトペーパー：https：//makerdao.com/whitepaper
</p>
<p>
    ブロッククエリ：https：//etherscan.io/token/0x6b175474e89094c44da98b954eedeac495271d0f
</p>
<p>
    <br/>
</p>`