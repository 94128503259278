<template>
<main role="main" :style="'min-height: '+(screenHeight - 250)+'px'">
    <div class="container-divider"></div>
    <div class="container">
        <nav class="sub-nav">
            <ol class="breadcrumbs">
                <li title="STARTHESTAR">
                    <a href="/">STARTHESTAR</a>
                </li>
                <li title="新闻">
                    <a href="javascript:">{{$t('news.label')}}</a>
                </li>
            </ol>
            <!-- <form role="search" class="search" data-search="" action="/hc/zh-cn/search" accept-charset="UTF-8" method="get">
                <input name="utf8" type="hidden" value="✓">
                <input type="hidden" name="category" id="category" value="360002866853">
                <input type="search" name="query" id="query" placeholder="搜索" aria-label="搜索">
            </form> -->
        </nav>

        <div class="article-container" id="article-container">
            <!-- 更多新闻列表 -->
            <aside class="article-sidebar" aria-labelledby="section-articles-title">
                <div class="collapsible-sidebar">
                    <button type="button" class="collapsible-sidebar-toggle" aria-labelledby="section-articles-title" aria-expanded="false"></button>
                    <span id="section-articles-title" class="collapsible-sidebar-title sidenav-title">
                        {{$t('base.more')}}
                    </span>
                    <div class="collapsible-sidebar-body">
                        <ul>
                            <li v-for="(item,index) in newsList" :key="index">
                                <router-link :to="{name:'News',query:{id:item.id}}" :class="'sidenav-item ' + (currentNews && currentNews.id == item.id ?'current-article':'')">{{item.title}}</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </aside>

            <!-- 文章内容 -->
            <article class="article">
                <header class="article-header">
                    <h1 title="如何注册STARTHESTAR" class="article-title">{{currentNews.title}}</h1>

                    <div class="article-author">
                        <div class="avatar article-avatar">
                            <span class="icon-agent"></span>
                            <img src="/uploads/avatar.png" alt="" class="user-avatar">
                        </div>
                        <div class="article-meta">
                            {{currentNews.author}}
                            <ul class="meta-group">
                                <li class="meta-data">
                                    <time datetime="2020-01-04T14:07:42Z" title="2020-01-04 22:07" data-datetime="relative">{{currentNews.create_str}}</time>
                                </li>
                                <li class="meta-data">{{$t('news.update')}}</li>

                            </ul>
                        </div>
                    </div>
                    <!-- <a class="article-subscribe" title="打开登录对话框" rel="nofollow" role="button" data-auth-action="signin" aria-selected="false" href="/hc/zh-cn/articles/360040901174-%E5%A6%82%E4%BD%95%E6%B3%A8%E5%86%8CSTARTHESTAR/subscription.html">关注</a> -->
                </header>

                <section class="article-info">
                    <div class="article-content">
                        <!-- 内容 -->
                        <div class="article-body" v-html="currentNews.content"></div>
                        <div class="article-attachments">
                            <ul class="attachments"></ul>
                        </div>
                    </div>
                </section>
                <!-- <footer>
                    <div class="article-footer">
                        <div class="article-share">
                            <ul class="share">
                                <li><a href="https://www.facebook.com/share.php?title=%E5%A6%82%E4%BD%95%E6%B3%A8%E5%86%8CSTARTHESTAR&amp;u=https%3A%2F%2Fxmexhelp.zendesk.com%2Fhc%2Fzh-cn%2Farticles%2F360040901174-%25E5%25A6%2582%25E4%25BD%2595%25E6%25B3%25A8%25E5%2586%258CSTARTHESTAR" class="share-facebook">Facebook</a></li>
                                <li><a href="https://twitter.com/share?lang=zh&amp;text=%E5%A6%82%E4%BD%95%E6%B3%A8%E5%86%8CSTARTHESTAR&amp;url=https%3A%2F%2Fxmexhelp.zendesk.com%2Fhc%2Fzh-cn%2Farticles%2F360040901174-%25E5%25A6%2582%25E4%25BD%2595%25E6%25B3%25A8%25E5%2586%258CSTARTHESTAR" class="share-twitter">Twitter</a></li>
                                <li><a href="https://www.linkedin.com/shareArticle?mini=true&amp;source=xmex&amp;title=%E5%A6%82%E4%BD%95%E6%B3%A8%E5%86%8CSTARTHESTAR&amp;url=https%3A%2F%2Fxmexhelp.zendesk.com%2Fhc%2Fzh-cn%2Farticles%2F360040901174-%25E5%25A6%2582%25E4%25BD%2595%25E6%25B3%25A8%25E5%2586%258CSTARTHESTAR" class="share-linkedin">LinkedIn</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="article-return-to-top">
                        <a href="#article-container">返回页首<span class="icon-arrow-up"></span></a>
                    </div>
                </footer> -->
            </article>
        </div>
    </div>

</main>
</template>

<script>
import {
    getNewsList,
    getNewsInfo
} from '@/api/news'
export default {
    name: "News",
    props: ['screenHeight'],
    data() {
        return {
            //当前文章
            currentNews: {},
            //新闻列表
            newsList: [],
        }
    },
    watch: {
        '$route'(to) {
            console.log(to)
            this.getNewsInfo(to.query.id)
        }
    },
    created() {
        // 获取列表
        this.getNewsList()

        //当前文章
        console.log(this.$route.query)
        if (this.$route.query) {
            this.getNewsInfo(this.$route.query.id)
        }
    },
    methods: {
        listenLang(lang) {
            lang
            // 获取列表
            this.getNewsList()

            this.getNewsInfo(this.currentNews.id)

        },
        getNewsList() {
            getNewsList().then(res => {
                this.newsList = res.data

                if (!this.$route.query.id) {
                    this.getNewsInfo(this.newsList[0].id)
                }
            })
        },
        getNewsInfo(id) {
            //loading
            // this.$ui.openLoading()
            getNewsInfo({
                id: id
            }).then(res => {
                // this.$ui.closeLoading()
                this.currentNews = res.data
                console.log(this.currentNews, "当前新闻")
            }).catch(() => {
                // this.$ui.closeLoading()
            })
        }
    }
}
</script>

<style src="../../../static/css/helpbox.css" scoped type="text/css"></style>
