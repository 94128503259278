export default `<h2 style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:48px">
<strong><span style="font-family: 宋体;font-size: 16px;background: rgb(255, 255, 255)">EOS (EOS)</span></strong>
</h2>
<p style=";padding: 0;line-height: 48px;background: rgb(255, 255, 255)">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<strong><span style="font-family: &#39;Times New Roman&#39;">1.<span style="font-family:宋体">什么是</span><span style="font-family:Times New Roman">EOS</span><span style="font-family:宋体">？</span></span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style=";font-family:Calibri;font-size:16px">EOS (Enterprise Operation System)<span style="font-family:宋体">是由 </span><span style="font-family:Calibri">Block.one</span><span style="font-family:宋体">公司主导开发的一种全新的基于区块链智能合约平台，旨在为高性能分布式应用提供底层区块链平台服务。</span><span style="font-family:Calibri">EOS </span><span style="font-family:宋体">项目的目标是实现一个类似操作系统的支撑分布式应用程序的区块链架构。该架构可以提供账户，身份认证，数据库，异步通信以及可在数以万计的 </span><span style="font-family:Calibri">CPU/GPU</span><span style="font-family:宋体">群集上进行程序调度和并行运算。</span><span style="font-family:Calibri">EOS</span><span style="font-family:宋体">最终可以支持每秒执行数百万个交易，同时普通用户执行智能合约无需支付使用费用。</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<strong><span style="font-family: &#39;Times New Roman&#39;">2.EOS<span style="font-family:宋体">的功能</span></span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style=";font-family:Calibri;font-size:16px">EOS<span style="font-family:宋体">代币目前是</span><span style="font-family:Calibri">EOS</span><span style="font-family:宋体">区块链基础设施发布的基于以太坊的代币，主要有三大应用场景：带宽和日志存储</span><span style="font-family:Calibri">(</span><span style="font-family:宋体">硬盘</span><span style="font-family:Calibri">)</span><span style="font-family:宋体">，计算和计算储备</span><span style="font-family:Calibri">(CPU)</span><span style="font-family:宋体">，状态存储</span><span style="font-family:Calibri">(RAM)</span><span style="font-family:宋体">。</span><span style="font-family:Calibri">EOS</span><span style="font-family:宋体">主网上线后会将</span><span style="font-family:Calibri">ERC20</span><span style="font-family:宋体">代币</span><span style="font-family:Calibri">EOS</span><span style="font-family:宋体">转换为其主链上的代币。具体可解释为：</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">（</span>1<span style="font-family:宋体">）接收方支付：客户从该业务中购买特定产品，而这些产品的销售收入将用于支付业务成本，避免客户直接为使用区块链支付费用，也不会限制或阻止企业确定其产品的货币化策略</span><span style="font-family:Calibri">.</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">（</span>2<span style="font-family:宋体">）授权能力：如果一个区块链是基于 </span><span style="font-family:Calibri">EOS </span><span style="font-family:宋体">软件系统开发，而其代币是由一个代币持有者所持有，他可能不需要立即消耗全部或部分可用带宽，这样的代币持有者可以选择将未消耗的带宽给予或租给他人。</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">（</span>3<span style="font-family:宋体">）将交易成本与代币价值分开：如果应用程序所有者持有相应数量的代币，那么应用程序可以在固定的状态和带宽使用中持续运行。开发人员和用户不会受到代币市场价格波动的影响，因此不会依赖于价格。</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">（</span>4<span style="font-family:宋体">）区块奖励：每次生成一个区块时，</span><span style="font-family:Calibri">EOS</span><span style="font-family:宋体">都会奖励该区块生产者新的代币用于支付其运维成本。目前</span><span style="font-family:Calibri">EOS</span><span style="font-family:宋体">设定限制了区块生产者所得奖励上限，目前</span><span style="font-family:Calibri">EOS</span><span style="font-family:宋体">设定给予区块生产者的奖励上限为每年最多</span><span style="font-family:Calibri">5%</span><span style="font-family:宋体">，即代币新增供应的年总增长不超过</span><span style="font-family:Calibri">5%</span><span style="font-family:宋体">。</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<strong><span style="font-family: &#39;Times New Roman&#39;">3.<span style="font-family:宋体">常用链接</span></span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">官网：</span></span><a href="https://eos.io/"><span style=";font-family:&#39;Times New Roman&#39;;color:rgb(53,124,225)">https://eos.io/</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">白皮书：</span></span><a href="#support-millions-of-users"><span style=";font-family:&#39;Times New Roman&#39;;color:rgb(53,124,225)">https://github.com/EOSIO/Documentation/blob/master/TechnicalWhitePaper.md#support-millions-of-users</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p>
<span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
<p>
<br/>
</p>`