<template>
  <div id="dark">
    <div class="pub-layout-content-right is-pulled-right py-0 pub-layout-content-kline-right">
      <!-- 右边上面 -->
      <div class="pub-layout-content-right-place-order py-1 pl-1" style="position: relative;">
        <!-- 遮罩 -->
        <div class="ze-bg" v-if="token == ''">
          <div class="ze-btn">
            <button class="ze-button" @click="login">{{$t('login.login')}}</button>
            <button class="ze-button" @click="signup">{{$t('signup.signup')}}</button>
          </div>
        </div>
        <!-- 上面操作 -->
        <div class="pub-place-order py-0 px-0 box">
          <!-- 表头 -->
          <div class="pub-place-order-tabs tabs is-small limit-order">
            <div class="is-flex px-3 is-background-3 pt-1">
              <ul>
                <li class="  is-active">
                  <a href="javascript:void(0);" class="a-button-pad px-0 mr-5 ">{{$t('lever.title')}}</a>
                </li>
              </ul>
              <!-- 可用 -->
              <!-- <div class="header-wit-balance">
                            <div class="pub-place-order-form-wallet is-flex field has-text-2 spottick-text-color-4">
                                <div class="order-get">可用</div>
                                <div class="spacer"></div>
                                <div class="order-get">{{walletList[Defkey] && walletList[Defkey].balance}} {{currency.desc}}</div>
                                <div class="order-get"></div>
                            </div>
                        </div> -->
            </div>
          </div>

          <!-- 买和卖切换 -->
          <div class="pub-place-order-form-lever-btns field buy-sell">
            <button :class="'button is-fullwidth button-radius text-font-flex ord-buy-sell is-text-3 ' + (showView == TradeType.Buy?'is-success':'bs-btn')" @click="changeView(TradeType.Buy)">
              {{$t('coins.buy')}}
            </button>
            <button :class="'button is-fullwidth button-radius text-font-flex ord-buy-sell is-text-3 ' + (showView == TradeType.Sell?'is-danger':'bs-btn')" @click="changeView(TradeType.Sell)">
              {{$t('coins.sell')}}
            </button>
          </div>

          <!-- 交易界面 买入-->
          <div class="pub-place-order-form has-text-2 px-3 pb-3" v-if="showView == TradeType.Buy">
            <!-- 价格类型 -->
            <div class="pub-place-order-form-lever-btns field button-price">
              <a :class="'button is-small has-border ' + (buyPriceType == PriceType.Market?'active':'')" @click="btnBuyPrice(PriceType.Market)">{{$t('coins.market')}}</a>
              <a :class="'button is-small has-border ' + (buyPriceType == PriceType.Limit?'active':'')" @click="btnBuyPrice(PriceType.Limit)">{{$t('coins.limit')}}</a>
            </div>

            <!-- 点差价格 -->
            <div class="pub-place-order-form-prz-input field">
              <div class="control has-flex">
                <div class="control is-background-3">
                  <button class="button input2 is-static2 is-background-3 has-text-1 is-left-radius text-font-flex text-font-flex-long has-text-double button-input-height">
                    {{$t('coins.current')}}
                  </button>
                </div>
                <input type="text" :readonly="true" v-model="currentBuyPriceStr" :placeholder="$t('coins.price_desc')" step="0.1" pattern="d*" class="input is-right-radius input2 text-font-flex button-input-height">
                <div class="control is-background-3 cursor-pointer">
                  <button class="button input2 is-static2 is-background-3 has-text-primary is-left-radius text-font-flex text-font-flex-long button-input-height">
                    <!-- 市价 -->
                  </button>
                </div>
              </div>
            </div>

            <!-- 价格 -->
            <div class="pub-place-order-form-prz-input field" v-if="buyPriceType == PriceType.Limit">
              <div class="control has-flex">
                <div class="control is-background-3">
                  <button class="button input2 is-static2 is-background-3 has-text-1 is-left-radius text-font-flex text-font-flex-long has-text-double button-input-height">
                    {{$t('coins.price')}}
                  </button>
                </div>
                <input type="number" @input="autoBuyNumber" v-model="currentBuyPrice" :placeholder="$t('coins.price_desc')" step="0.1" pattern="d*" class="input is-right-radius input2 text-font-flex button-input-height">
                <div class="control is-background-3 cursor-pointer">
                  <button class="button input2 is-static2 is-background-3 has-text-primary is-left-radius text-font-flex text-font-flex-long button-input-height">
                    <!-- 市价 -->
                  </button>
                </div>
              </div>
            </div>
            <!-- 选比例 -->
            <div class="pub-place-order-form-prz-input field">
              <div class="control has-flex">
                <div class="control is-background-3">
                  <button class="button input2 is-static2 is-background-3 has-text-1 is-left-radius text-font-flex text-font-flex-long has-text-double button-input-height">
                    {{$t('lever.multiple')}}
                  </button>
                </div>
                <div class="l-select">
                  <div class="l-lable">
                    <input @click="openBuySelect" readonly type="number" v-model="buyRate" :placeholder="$t('lever.multiple_desc')" class="input is-right-radius input2 text-font-flex button-input-height">
                    <i class="el-icon-arrow-up" v-show="buySelect"></i>
                    <i class="el-icon-arrow-down" v-show="!buySelect"></i>
                  </div>
                  <div class="ul-select" v-show="buySelect">
                    <ul>
                      <li @click="onBuyRate(500)">500{{$t('lever.double')}}</li>
                      <li @click="onBuyRate(300)">300{{$t('lever.double')}}</li>
                      <li @click="onBuyRate(200)">200{{$t('lever.double')}}</li>
                      <li @click="onBuyRate(100)">100{{$t('lever.double')}}</li>
                    </ul>
                  </div>
                </div>
                <div class="control is-background-3 cursor-pointer">
                  <button class="button input2 is-static2 is-background-3 has-text-primary is-left-radius text-font-flex text-font-flex-long button-input-height">
                    {{$t('lever.double')}}
                  </button>
                </div>
              </div>
            </div>
            <!-- 数量 -->
            <div class="pub-place-order-form-num-input field" style="margin-bottom: 0;">
              <div class="control">
                <div class="control has-flex">
                  <div class="control is-background-3">
                    <button class="button input2 is-static2 is-background-3 has-text-1 is-left-radius text-font-flex text-font-flex-long has-text-double button-input-height">
                      {{$t('coins.number')}}
                    </button>
                  </div>
                  <input type="number" @input="inputBuyNumber" v-model="buy_number" :placeholder="$t('coins.number_desc')" step="0.1" class="input is-right-radius input2 text-font-flex button-input-height">
                  <div class="control is-background-3">
                    <button class="button input2 is-static2 is-background-3  has-text-1 is-left-radius text-font-flex text-font-flex-long button-input-height">
                      LOT
                    </button>
                  </div>
                </div>
                <!-- <span class="pub-place-order-form-num-input-face-value">≈ 0.00 {{currency.desc}} (手续费:0.00 {{currency.desc}})</span> -->
              </div>
            </div>
            <!-- 持仓 -->
            <div class="header-wit-balance">
              <div class="pub-place-order-form-wallet is-flex field has-text-2 spottick-text-color-4">
                <div class="order-get">{{$t('coins.available')}}</div>
                <div class="spacer"></div>
                <div class="order-get">{{usdtWallet && usdtWallet.balance}} {{usdtWallet.name}}</div>
                <div class="order-get"></div>
              </div>
            </div>

            <!-- 快捷操作 -->
            <div class="pub-place-order-form-lever-btns">
              <a class="button is-small has-border" @click="btnBuySet(0.5)">{{$t('coins.half')}}</a>
              <a class="button is-small has-border" @click="btnBuySet(1)">{{$t('coins.all')}}</a>
            </div>

            <!-- 手续费 -->
            <div class="header-wit-balance">
              <div class="pub-place-order-form-wallet is-flex field has-text-2 spottick-text-color-4">
                <div class="order-get">{{$t('coins.fee')}}</div>
                <div class="spacer"></div>
                <div class="order-get">≈ {{buy_fee_usdt}} {{currency.desc}}</div>
                <div class="order-get"></div>
              </div>
            </div>
            <!-- 交易额 -->
            <div class="header-wit-balance field">
              <div class="pub-place-order-form-wallet is-flex field has-text-2 spottick-text-color-4">
                <div class="order-get">{{$t('coins.amount')}}</div>
                <div class="spacer"></div>
                <div class="order-get">≈ {{buy_usdt}} {{currency.desc}}</div>
                <div class="order-get"></div>
              </div>
            </div>
            <div class="spacer"></div>
            <div class="pub-place-order-form-buttons field">
              <div class="level-item">
                <div class="button-width">
                  <div class="button-default-width">
                    <button class="button is-success is-fullwidth button-radius text-font-flex ord-buy-sell is-text-3" @click="openWind(TradeType.Buy,Direction.Buy)">
                      {{$t('lever.buy')}}
                    </button>
                  </div>
                </div>
                <div class="button-width">
                  <div class="button-default-width">
                    <button class="button is-danger is-fullwidth button-radius text-font-flex ord-buy-sell is-text-3" @click="openWind(TradeType.Buy,Direction.Sell)">
                      {{$t('lever.sell')}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
          </div>

          <!-- 卖出 -->
          <div class="pub-place-order-form has-text-2 px-3 pb-3" v-if="showView == TradeType.Sell">
            <!-- 价格类型 -->
            <div class="pub-place-order-form-lever-btns field button-price">
              <a :class="'button is-small has-border ' + (sellPriceType == PriceType.Market?'active':'')" @click="btnSellPrice(PriceType.Market)">{{$t('coins.market')}}</a>
              <a :class="'button is-small has-border ' + (sellPriceType == PriceType.Limit?'active':'')" @click="btnSellPrice(PriceType.Limit)">{{$t('coins.limit')}}</a>
            </div>
            <!-- 止盈止损 -->
            <div class="pub-place-order-form-lever-btns field button-price">
              <a :class="'button is-small has-border ' + (sellPriceType == PriceType.Profit?'active':'')" @click="btnSellPrice(PriceType.Profit)">{{$t('coins.profit')}}</a>
              <a :class="'button is-small has-border ' + (sellPriceType == PriceType.Loss?'active':'')" @click="btnSellPrice(PriceType.Loss)">{{$t('coins.loss')}}</a>
            </div>
            <!-- 价格 -->
            <div class="pub-place-order-form-prz-input field">
              <div class="control has-flex">
                <div class="control is-background-3">
                  <button class="button input2 is-static2 is-background-3 has-text-1 is-left-radius text-font-flex text-font-flex-long has-text-double button-input-height">
                    {{$t('coins.price')}}
                  </button>
                </div>
                <input type="number" @input="autoSellNumber" :readonly="(sellPriceType == PriceType.Market)" v-model="currentSellPrice" :placeholder="$t('coins.price_desc')" step="0.00000001" class="input is-right-radius input2 text-font-flex button-input-height">
                <div class="control is-background-3 cursor-pointer">
                  <button class="button input2 is-static2 is-background-3 has-text-primary is-left-radius text-font-flex text-font-flex-long button-input-height">
                  </button>
                </div>
              </div>
            </div>
            <!-- 数量 -->
            <div class="pub-place-order-form-num-input field" style="margin-bottom: 0;">
              <div class="control">
                <div class="control has-flex">
                  <div class="control is-background-3">
                    <button class="button input2 is-static2 is-background-3 has-text-1 is-left-radius text-font-flex text-font-flex-long has-text-double button-input-height">
                      {{$t('coins.number')}}
                    </button>
                  </div>
                  <input type="number" @input="inputSellNumber" v-model="sell_number" :placeholder="$t('coins.number_desc')" step="1" pattern="d*" class="input is-right-radius input2 text-font-flex button-input-height">
                  <div class="control is-background-3">
                    <button class="button input2 is-static2 is-background-3  has-text-1 is-left-radius text-font-flex text-font-flex-long button-input-height">
                      LOT
                    </button>
                  </div>
                </div>
                <!-- <span class="pub-place-order-form-num-input-face-value">≈ 0.00 {{currency.desc}} (手续费:0.00 {{currency.desc}})</span> -->
              </div>
            </div>
            <!-- 持仓 -->
            <div class="header-wit-balance">
              <div class="pub-place-order-form-wallet is-flex field has-text-2 spottick-text-color-4">
                <div class="order-get">{{$t('coins.available')}}</div>
                <div class="spacer"></div>
                <div class="order-get">{{currentWallet && currentWallet.balance}} {{currency.name}}</div>
                <div class="order-get"></div>
              </div>
            </div>

            <!-- 快捷操作 -->
            <div class="pub-place-order-form-lever-btns">
              <a class="button is-small has-border" @click="btnSellSet(0.5)">{{$t('coins.half')}}</a>
              <a class="button is-small has-border" @click="btnSellSet(1)">{{$t('coins.all')}}</a>
            </div>

            <!-- 交易额 -->
            <div class="header-wit-balance field">
              <div class="pub-place-order-form-wallet is-flex field has-text-2 spottick-text-color-4">
                <div class="order-get">{{$t('coins.amount')}}</div>
                <div class="spacer"></div>
                <div class="order-get">≈ {{sell_usdt}} {{currency.desc}}</div>
                <div class="order-get"></div>
              </div>
            </div>

            <div class="spacer"></div>
            <div class="pub-place-order-form-buttons field">
              <div class="level-item">
                <div class="button-width" style="width:100%">
                  <div class="button-default-width">
                    <button class="button is-danger is-fullwidth button-radius text-font-flex ord-buy-sell is-text-3" @click="openWind(TradeType.Sell)">
                      {{$t('coins.sell')}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
          </div>
        </div>
      </div>

      <div class="pub-layout-content-right-spot-info py-1 pl-1">
        <div class="pub-spot-info box px-0 py-0">
          <div class="pub-spot-info-tabs tabs is-background-3 px-3">
            <ul>
              <li class=""><a href="javascript:void(0);" class="has-text-1-important wlt-coin">{{$t('contract_menu.lever_info')}}</a></li>
            </ul>
          </div>
          <div class="pub-spot-info-content px-3 py-3">
            <div class="level-item">
              <div class="text--secondary spottick-text-color-4">{{$t('metal_info.pt1')}}</div>
              <div class="has-text-1" v-if="currency.symbol == 'mixusdt'">Metal Index</div>
              <div class="has-text-1" v-if="currency.symbol != 'mixusdt'">{{currency.name}}</div>
            </div>
            <div class="level-item">
              <div class="text--secondary spottick-text-color-4">{{$t('metal_info.pt2')}}</div>
              <div class="has-text-1">{{currency.desc}}</div>
            </div>
            <div class="level-item">
              <div class="text--secondary spottick-text-color-4">{{$t('metal_info.pt3')}}</div>
              <div class="has-text-1">dollar/{{currency.unit}}</div>
            </div>
            <div class="level-item">
              <div class="text--secondary spottick-text-color-4">{{$t('metal_info.pt4')}}</div>
              <div class="has-text-1">{{currency.unit_scale}}{{currency.unit}}/lot</div>
            </div>
            <div class="level-item">
              <div class="text--secondary spottick-text-color-4">{{$t('metal_info.pt5')}}</div>
              <div class="has-text-1">{{currency.bd_min}}dollar/{{currency.unit}}</div>
            </div>
            <div class="level-item">
              <div class="text--secondary spottick-text-color-4">{{$t('metal_info.pt51')}}</div>
              <div class="has-text-1">{{currency.trade_min}} lot</div>
            </div>
            <div class="level-item">
              <div class="text--secondary spottick-text-color-4">{{$t('metal_info.pt52')}}</div>
              <div class="has-text-1">{{currency.trade_max}} lot</div>
            </div>
            <div class="level-item">
              <div class="text--secondary spottick-text-color-4">{{$t('metal_info.pt10')}}</div>
              <div class="has-text-1">{{$t('metal_info.pv10',{name:currency.name,num:currency.lever_limit_val})}}</div>
            </div>
            <div class="level-item">
              <div class="text--secondary spottick-text-color-4">{{$t('metal_info.pt6')}}</div>
              <div class="has-text-1">{{$t('metal_info.pv4')}}</div>
            </div>
            <div class="level-item">
              <div class="text--secondary spottick-text-color-4">{{$t('metal_info.pt7')}}</div>
              <div class="has-text-1">{{currency.jy_time}}</div>
            </div>
            <div class="level-item">
              <div class="text--secondary spottick-text-color-4">{{$t('metal_info.pt8')}}</div>
              <div class="has-text-1">{{$t('metal_info.pv6')}}</div>
            </div>

            <!-- <div class="level-item mb-0-important">
                        <div class="text--secondary has-text-primary cursor-pointer">更多...</div>
                    </div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- 输入密码 -->
    <el-dialog :title="$t('trade.title')" :visible.sync="dialogVisible" :before-close="cancelTrade" class="trade-dialog trade-dg" style="width:50%;margin: 0 auto;top: 20%;">
      <el-row>
        <!-- <el-col :span="24">
                <label style="padding: 10px 0;color:#FFF;">交易密码:</label>
            </el-col> -->
        <el-col :span="24">
          <el-input v-model="tradePwd" type="password" :placeholder="$t('trade.enter_password')" style="width:100%"></el-input>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelTrade">{{$t('trade.cancel')}}</el-button>
        <el-button @click="confirmTrade">{{$t('trade.submit')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getWalletLever } from "@/api/user";
import { buyLever, sellLever } from "@/api/currency";
import { Float8, ScToNumber, Event, GetNewFixed } from "../../utils/helper";

const PriceType = {
  // 市价
  Market: 1,
  // 限价
  Limit: 2,
  // 止盈
  Profit: 3,
  // 止损
  Loss: 4,
};
const TradeType = {
  Buy: 1,
  Sell: 2,
};

const Direction = {
  Buy: 1,
  Sell: 2,
};

//默认交易对协议
const Defkey = "USDT";

export default {
  name: "MetalTrade",
  props: ["price"],
  data() {
    return {
      Defkey,
      PriceType,
      TradeType,
      Direction,

      //交易界面
      showView: TradeType.Buy,

      //当前币
      currency: {
        lever_buy_rate: 0,
      },
      //当前钱包
      currentWallet: {
        balance: 0,
      },
      //USDT当前钱包
      usdtWallet: {},

      //倍数菜单
      buySelect: false,
      //倍率
      buyRate: 500,
      //方向
      buyDirection: 0,

      //-------------------
      //买
      // 数量
      buy_number: "",
      //交易额
      buy_usdt: "0.00",
      //手续费
      buy_fee_usdt: "0.00",
      //总抵扣
      buy_total: 0,
      //当前价格
      currentBuyPrice: 0,
      //当前点差价格显示
      currentBuyPriceStr: "0.0000 / 0.0000",
      //交易类型
      buyPriceType: PriceType.Market,

      //-------------------
      //卖
      //卖的数量
      sell_number: "",
      //交易额
      sell_usdt: "0.00",
      //手续费
      sell_fee_usdt: "0.00",
      //总抵扣
      sell_total: 0,
      //当前价格
      currentSellPrice: 0,
      //交易类型
      sellPriceType: PriceType.Market,

      //弹出窗口
      dialogVisible: false,

      //交易密码
      tradePwd: "",
      tradeType: "",

      //token
      token: "",

      //监听用户资产信息
      u_total: 0,
      u_coins: 0,
      u_lever_profit: 0,
      u_lever_rate: 0,
    };
  },
  created() {
    console.log("MetalTrade");

    this.checkToken();
  },
  methods: {
    /**
     * 交易界面显示
     */
    changeView(view) {
      this.showView = view;
    },
    checkToken() {
      //获取缓存的token
      let token = localStorage.getItem("token");
      if (token) {
        this.token = token;
      } else {
        this.token = "";
      }
      return token;
    },
    login() {
      this.$router.push({
        name: "Login",
      });
    },
    signup() {
      this.$router.push({
        name: "Signup",
      });
    },
    setWallet(res, u_total, u_usable) {
      this.usdtWallet.balance = u_usable;
    },
    setDetail(detail) {
      this.currency.close = GetNewFixed(detail.close, this.currency.decimal);
      //更新价格
      this.updatePrice();
    },
    /**
     * 设置币
     */
    setCurrency(currency) {
      this.currency = currency;
      //更新价格
      this.updatePrice();

      //获取钱包
      this.getWalletLever();
    },
    /**
     * 获取币币账户
     */
    getWalletLever() {
      if (!this.checkToken()) {
        return;
      }
      let _this = this;
      getWalletLever({
        c_id: this.currency.id,
      }).then((res) => {
        //设置当前钱包
        if (_this.currency) {
          if (res.data[_this.currency.name]) {
            _this.currentWallet = res.data[_this.currency.name];
          }
          _this.usdtWallet = res.data[Defkey];
          console.log(
            "[LeverTrade] 当前" + _this.currency.name,
            _this.currency
          );
          console.log(
            "[LeverTrade] 当前" + _this.currency.name + "钱包",
            _this.currentWallet
          );
          console.log("[LeverTrade] 当前USDT钱包", _this.usdtWallet);
        }
      });
    },
    /**
     * 交易密码弹出框
     */
    openWind(type, direction) {
      if (type == TradeType.Buy) {
        //买的方向
        this.buyDirection = direction;
        if (!this.validBuy()) {
          return;
        }
      }

      if (type == TradeType.Sell) {
        if (!this.validSell()) {
          return;
        }
      }

      this.tradeType = type;
      this.tradePwd = "";
      // this.dialogVisible = true
      this.confirmTrade();
    },
    cancelTrade() {
      console.log("交易密码");
      this.dialogVisible = false;
    },
    confirmTrade() {
      console.log("确认交易密码");
      this.dialogVisible = false;
      if (this.tradeType == TradeType.Buy) {
        this.onBuy();
      }
      if (this.tradeType == TradeType.Sell) {
        this.onSell();
      }
    },
    updatePrice() {
      if (this.buyPriceType == PriceType.Market) {
        //更新价格 [通过改变输入框，重新计算交易额]
        this.currentBuyPrice = this.currency.close;
        //[调用]
        this.autoBuyNumber();
      }

      if (this.sellPriceType == PriceType.Market) {
        //更新价格 [通过改变输入框，重新计算交易额]
        this.currentSellPrice = this.currency.close;
        //[调用]
        this.autoSellNumber();
      }

      //价格计算点差值
      // console.log(this.currency)
      let cha = this.getCha();
      let price = parseFloat(this.currency.close);

      let buy = Float8(price + cha);
      let sell = Float8(price - cha);
      this.currentBuyPriceStr = buy + " / " + sell;
    },
    getCha() {
      let limitVal = this.currency.lever_limit_val
        ? this.currency.lever_limit_val
        : 0;
      // let price = parseFloat(this.currency.close)

      let cha = 0;
      if (limitVal > 0) {
        // limitVal = parseFloat(limitVal / 100)
        // cha = Float8(price * limitVal)
        cha = limitVal;
      }

      // console.log(limitVal, cha, price, "点差")
      return cha;
    },
    /**--------------------------------------------------
     * 价格类型
     */
    btnBuyPrice(type) {
      this.buyPriceType = type;
      // 立即更新
      this.updatePrice();
    },
    /**
     * 输入过滤
     */
    _BuyGetNumber() {
      //数初始化
      let number = this.buy_number;
      if (number) {
        let number = Number(number);
      }

      //数据过滤
      if (number < 0) {
        this.buy_number = "";
        // console.log(number, '数据负值')
        return 0;
      }

      //数据过滤
      if (number > 0 && number < 0.1) {
        this.buy_number = "";
        return 0.1;
      }

      if (!number || number == 0) {
        // console.log(number, '数据无值')
        this._SetBuyParams({
          number: number,
          real_usdt: "0.00",
          usdt: "0.00",
          fee: "0.00",
          total: 0,
        });
        return 0;
      }

      //console.log(number, '数据正常')
      number = ScToNumber(number);
      //console.log(number, '数据转换')
      if (number.indexOf(".") > 0) {
        return GetNewFixed(number, 1);
      }
      return number;
    },
    /**
     * 获取基本参数
     */
    _BuyGetBase() {
      //基本参数
      let price = parseFloat(this.currentBuyPrice);
      let rate = parseFloat(this.currency.lever_buy_rate / 100);
      let fee_min = parseFloat(this.currency.lever_buy_min);
      let balance = parseFloat(this.usdtWallet.balance);
      let buy_rate = parseFloat(this.buyRate);
      let unit_scale = parseFloat(this.currency.unit_scale);
      //console.log("当前价格：" + price)
      //console.log("倍数：" + this.buyRate)
      //console.log("费率：" + rate)
      //console.log("最小手续费：" + fee_min)
      //console.log("可用：" + balance + " USDT")

      return {
        price: price,
        rate: rate,
        fee_min: fee_min,
        balance: balance,
        buy_rate: buy_rate ? buy_rate : 1,
        unit_scale: unit_scale,
      };
    },
    /**
     * 计算结果设置
     */
    _SetBuyParams(res) {
      this.buy_usdt = res.real_usdt;
      this.buy_number = res.number;
      this.buy_fee_usdt = res.fee;
      this.buy_total = res.total;
    },
    /**
     * 已知数量
     */
    _BuyGetTotal(number) {
      if (!number || number < 0) {
        return {
          number: "",
          real_usdt: "0.00",
          usdt: "0.00",
          fee: "0.00",
          total: 0,
        };
      }

      //基本参数
      let base = this._BuyGetBase();

      //交易额 = 单价 * 数量 * 单位缩放
      let usdt = Float8(number * base.price * base.unit_scale);

      //手续费
      let fee = Float8(usdt * base.rate);
      if (fee < base.fee_min) {
        fee = base.fee_min;
      }

      //实际支付 保证金 = 数量 * 单价 / 倍数
      let realUsdt = Float8(usdt / base.buy_rate);

      //实际扣除
      let total = Float8(realUsdt + fee);

      return {
        number: number,
        usdt: usdt,
        real_usdt: realUsdt,
        fee: fee,
        total: total,
      };
    },
    /**
     * 根据总额求数量
     */
    _BuyGetTotal2(total) {
      if (!total || total < 0) {
        return {
          number: "",
          usdt: "0.00",
          real_usdt: "0.00",
          fee: "0.00",
          total: 0,
        };
      }
      //基本参数
      let base = this._BuyGetBase();

      //购买数量 [推算公式] 扣除 = (x * 单价) + (x * 单价)*费率
      let number = Float8(
        total /
          (base.price / base.buy_rate + base.rate * base.price) /
          base.unit_scale
      );

      //交易额 = 单价 * 数量 * 单位缩放
      let usdt = Float8(number * base.price * base.unit_scale);

      //实际支付
      let realUsdt = Float8(usdt / base.buy_rate);

      //计算费率
      let fee = Float8(usdt * base.rate);
      if (fee < base.fee_min) {
        fee = base.fee_min;

        //重新计算 实际购买手 = （余额 - 最低手续费）/ 单价 /比例
        number = Float8((total - fee) / base.price / base.unit_scale);
        //交易额
        usdt = Float8(number * base.unit_scale * base.price);

        if (!number || number < 0) {
          this.$message({
            message: "可用量不足",
            type: "warning",
          });
          return {
            number: "",
            usdt: "0.00",
            real_usdt: "0.00",
            fee: "0.00",
            total: 0,
          };
        }
      }

      //矫正手续费补差
      let real_fee = Float8(total - realUsdt);
      if (real_fee < 0) {
        real_fee = 0;
      }

      if (number.toString().indexOf(".") > 0) {
        number = GetNewFixed(number, 1);
        if (number < 0.1) {
          number = 0.1;
        }
      }

      return {
        number: number,
        usdt: usdt,
        real_usdt: realUsdt,
        fee: real_fee,
        total: total,
      };
    },

    /**
     * 半/全仓 更新
     */
    btnBuySet(num) {
      if (num == 1) {
        //全仓抵扣额
        let base = this._BuyGetBase();
        let res = this._BuyGetTotal2(base.balance);
        this._SetBuyParams(res);
        //console.log(res, '结果')
      } else if (num == 0.5) {
        //半仓抵扣额
        let base = this._BuyGetBase();
        let res = this._BuyGetTotal2(base.balance / 2);
        this._SetBuyParams(res);
        //console.log(res, '结果')
      }
    },
    /**
     * 输入数量更新
     */
    inputBuyNumber() {
      //过滤
      let number = this._BuyGetNumber();
      if (!number) {
        return;
      }

      //计算
      let res = this._BuyGetTotal(number);
      this._SetBuyParams(res);
      //console.log(res, '输入变化')
    },
    /**
     * 价格更新
     */
    autoBuyNumber() {
      //过滤
      let number = this._BuyGetNumber();
      if (!number) {
        return;
      }

      //计算
      let res = this._BuyGetTotal(number);
      //console.log(res, '价格自动变化')

      //校验
      // let base = this._BuyGetBase()
      // if (res.total > base.balance) {
      //     //再次计算
      //     res = this._BuyGetTotal2(base.balance)
      // }

      this._SetBuyParams(res);
    },
    validBuy() {
      if (this.buy_number < 0.1) {
        this.$message({
          message: this.$t("message.invalid_number"),
          type: "warning",
        });
        return false;
      }

      if (!this.buyRate) {
        this.$message({
          message: this.$t("message.invalid_rate"),
          type: "warning",
        });
        return false;
      }

      let base = this._BuyGetBase();
      if (this.buy_usdt > base.balance) {
        this.$message({
          message: this.$t("message.invalid_balance"),
          type: "warning",
        });
        return false;
      }

      //检测限价价格
      if (this.buyPriceType == 2) {
        let price = parseFloat(this.currency.close);
        let currentPrice = parseFloat(this.currentBuyPrice);
        // let cha = this.getCha()

        if (this.buyDirection == 1) {
          //涨
          let limitBuyPrice = Float8(price);
          if (currentPrice >= limitBuyPrice) {
            this.$message({
              message: this.$t("message.price_low", {
                price: limitBuyPrice,
              }),
              type: "warning",
            });
            return false;
          }
        } else if (this.buyDirection == 2) {
          //跌
          let limitBuyPrice = Float8(price);
          if (currentPrice <= limitBuyPrice) {
            this.$message({
              message: this.$t("message.price_high", {
                price: limitBuyPrice,
              }),
              type: "warning",
            });
            return false;
          }
        }
      }
      return true;
    },
    onBuy() {
      //loading
      this.$ui.openLoading();

      buyLever({
        price_type: this.buyPriceType,
        price: this.currentBuyPrice,
        buy_rate: this.buyRate,
        direction: this.buyDirection,
        number: this.buy_number,
        c_id: this.currency.id,
        trade_pwd: this.tradePwd,
      })
        .then((res) => {
          //close
          this.$ui.closeLoading();

          if (res.code == 1) {
            this.$message({
              message: res.msg,
              type: "error",
            });
            return;
          }

          this.$message({
            message: res.msg,
            type: "success",
          });
          console.log(res);
          //重新获取
          this.getWalletLever();
          //通知
          Event.$emit("onCoinsTrade");
        })
        .catch(() => {
          //close
          this.$ui.closeLoading();
        });
    },

    /**--------------------------------------------------
     * 价格类型
     */
    btnSellPrice(type) {
      this.sellPriceType = type;
      // 立即更新
      this.updatePrice();
    },
    /**
     * 输入过滤
     */
    _SellGetNumber() {
      //数初始化
      let number = this.sell_number;
      if (number) {
        let number = Number(number);
      }

      //数据过滤
      if (number < 0) {
        this.sell_number = "";
        // console.log(number, '数据负值')
        return 0;
      }

      //数据过滤
      if (number > 0 && number < 0.1) {
        this.sell_number = "";
        return 0.1;
      }

      if (!number || number == 0) {
        // console.log(number, '数据无值')
        this._SetSellParams({
          number: number,
          profit: "0.00",
          usdt: "0.00",
        });
        return 0;
      }

      //console.log(number, '数据正常')
      number = ScToNumber(number);
      //console.log(number, '数据转换')
      if (number.indexOf(".") > 0) {
        return GetNewFixed(number, 1);
      }
      return number;
    },
    /**
     * 获取基本参数
     */
    _SellGetBase() {
      //基本参数
      let price = parseFloat(this.currentSellPrice);
      let balance = parseFloat(this.currentWallet.balance);
      let bond = parseFloat(this.currentWallet.bond);
      let real_usdt = parseFloat(this.currentWallet.real_usdt);
      let direction = parseFloat(this.currentWallet.direction);
      let unit_scale = parseFloat(this.currency.unit_scale);

      //console.log("当前价格：" + price)
      //console.log("可用：" + balance + " " + this.currentWallet.name)

      return {
        price: price,
        balance: balance,
        bond: bond,
        real_usdt: real_usdt,
        direction: direction,
        unit_scale: unit_scale,
      };
    },
    /**
     * 计算结果设置
     */
    _SetSellParams(res) {
      this.sell_usdt = res.profit;
      this.sell_number = res.number;
    },
    /**
     * 已知数量
     */
    _SellGetTotal(number) {
      if (!number || number < 0) {
        return {
          number: "",
          profit: "0.00",
        };
      }

      //基本参数
      let base = this._SellGetBase();

      //交易额
      let real_usdt = Float8(number * base.price * base.unit_scale);

      //求得本次比例
      let rate = Float8(number / base.balance);
      //本次释放保证金
      let bond = Float8(rate * base.bond);
      //本次释放市值
      let usdt = Float8(rate * base.real_usdt);

      //本次收益
      let profit = Float8(
        (real_usdt - usdt) * (base.direction == 1 ? 1 : -1) + bond
      );

      return {
        real_usdt: real_usdt,
        number: number,
        profit: profit ? profit : "0.00",
        rate: rate,
        bond: bond,
        usdt: usdt,
      };
    },
    /**
     * 半/全仓
     */
    btnSellSet(num) {
      if (num == 1) {
        //全仓卖出
        let base = this._SellGetBase();
        let res = this._SellGetTotal(base.balance);
        this._SetSellParams(res);
        //console.log(res, '结果')
      } else if (num == 0.5) {
        //半仓卖出
        let base = this._SellGetBase();
        let res = this._SellGetTotal(base.balance / 2);
        this._SetSellParams(res);
        //console.log(res, '结果')
      }
    },
    /**
     * 输入数量更新
     */
    inputSellNumber() {
      //过滤
      let number = this._SellGetNumber();
      if (!number) {
        return;
      }

      //计算
      let res = this._SellGetTotal(number);
      this._SetSellParams(res);
      //console.log(res, '结果')
    },
    /**
     * 价格更新
     */
    autoSellNumber() {
      //过滤
      let number = this._SellGetNumber();
      if (!number) {
        return;
      }
      //计算
      let res = this._SellGetTotal(number);
      this._SetSellParams(res);
      //console.log(res, '结果')
    },
    validSell() {
      let base = this._SellGetBase();
      if (this.sell_number > base.balance) {
        this.$message({
          message: this.$t("message.invalid_number"),
          type: "warning",
        });
        return false;
      }

      if (!this.sell_number) {
        this.$message({
          message: this.$t("message.invalid_number"),
          type: "warning",
        });
        return false;
      }

      let price = parseFloat(this.currency.close);
      let currentPrice = parseFloat(this.currentSellPrice);
      let cha = this.getCha();

      //止盈
      if (this.sellPriceType == 3) {
        if (this.currentWallet.direction == 1) {
          //涨
          let limitSellPrice = Float8(price + cha);
          if (currentPrice <= limitSellPrice) {
            this.$message({
              message: this.$t("message.price_high", {
                price: limitSellPrice,
              }),
              type: "warning",
            });
            return false;
          }
        } else if (this.currentWallet.direction == 2) {
          //跌
          let limitSellPrice = Float8(price - cha);
          if (currentPrice >= limitSellPrice) {
            this.$message({
              message: this.$t("message.price_low", {
                price: limitSellPrice,
              }),
              type: "warning",
            });
            return false;
          }
        }
      }

      // 止损
      if (this.sellPriceType == 4) {
        if (this.currentWallet.direction == 1) {
          //涨
          let limitSellPrice = Float8(price - cha);
          if (currentPrice >= limitSellPrice) {
            this.$message({
              message: this.$t("message.price_low", {
                price: limitSellPrice,
              }),
              type: "warning",
            });
            return false;
          }
        } else if (this.currentWallet.direction == 2) {
          //跌
          let limitSellPrice = Float8(price + cha);
          if (currentPrice <= limitSellPrice) {
            this.$message({
              message: this.$t("message.price_high", {
                price: limitSellPrice,
              }),
              type: "warning",
            });
            return false;
          }
        }
      }
      return true;
    },
    onSell() {
      console.log(this.sell_number, this.currentSellPrice);
      //loading
      this.$ui.openLoading();

      sellLever({
        price_type: this.sellPriceType,
        price: this.currentSellPrice,
        number: this.sell_number,
        direction: this.currentWallet.direction,
        c_id: this.currentWallet.c_id,
        trade_pwd: this.tradePwd,
      })
        .then((res) => {
          //close
          this.$ui.closeLoading();

          if (res.code == 1) {
            this.$message({
              message: res.msg,
              type: "error",
            });
            return;
          }

          this.$message({
            message: res.msg,
            type: "success",
          });
          console.log(res);

          //重新获取
          this.getWalletLever();
          //通知
          Event.$emit("onCoinsTrade");
        })
        .catch(() => {
          //close
          this.$ui.closeLoading();
        });
    },
    openBuySelect() {
      this.buySelect = !this.buySelect;
    },
    onBuyRate(num) {
      this.openBuySelect();
      console.log(num);
      this.buyRate = num;
    },
  },
};
</script>

<style src="../../../static/css/lever.css" scoped type="text/css"></style>
