export default `<h2 style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:48px">
<strong><span style="font-family: Times New Roman;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 16px;background: rgb(255, 255, 255)">BTC (Bitcoin)</span></strong>
</h2>
<p>
<span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<strong><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">1. What is Bitcoin? </span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Bitcoin (</span>Bitcoin, abbreviated as BTC) is currently the most widely used digital currency. It was born on January 3, 2009. It is a digital encrypted currency for peer-to-peer (P2P) transmission with a total volume of 2100 Ten thousand pieces. The Bitcoin network releases a certain amount of coins every 10 minutes, which is expected to reach its limit in 2140. Bitcoin is called "digital gold" by investors. Bitcoin is generated through a large number of calculations based on a specific algorithm. It does not rely on specific currency institutions to issue. It uses a distributed database composed of many nodes in the entire P2P network to confirm and record all transaction behaviors, and uses cryptographic design to ensure currency circulation. The security of the link can ensure that the value of the currency cannot be manipulated through the large-scale production of Bitcoin. The design based on cryptography allows Bitcoin to be transferred, paid and cashed only by the real owner. It also ensures the anonymity of currency ownership and circulation transactions. </span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Bitcoin is favored by technology enthusiasts due to its decentralization, global circulation, low transaction costs</span> <span style="font-family:Microsoft Yahei"> and anonymous circulation. Recently, traditional financial institutions such as Wall Street and central banks of many countries have begun to study Bitcoin blockchain technology. The Japanese government has officially recognized Bitcoin as a legal payment method, and more and more Japanese businesses have accepted Bitcoin as payment. </span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<strong><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">2. What are the advantages of Bitcoin? </span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<strong><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">Free payment</span>&nbsp;-&nbsp;</span></strong><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing : 0;font-size: 14px"><span style="font-family:Microsoft Yahei">You can instantly pay and receive any amount of funds anytime and anywhere. No bank holidays, no borders, and no restrictions imposed. Bitcoin allows its users to fully control their funds. </span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<strong><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">very low cost</span>&nbsp;-&nbsp;</span></strong><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter -spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">Currently, there is no handling fee or only a very small handling fee for the processing of Bitcoin payments. Users can include the handling fee in the transaction to get the processing priority, and receive the transaction confirmation from the network faster. In addition, there are merchant processors that assist merchants in processing transactions, converting bitcoins into legal tender every day and depositing funds directly into merchants’ bank accounts. Because these services are based on Bitcoin, they can provide fees that are much lower than PayPal or credit card networks. </span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<strong><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">Reduce the risk of merchants</span>&nbsp;-</span></strong><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing : 0;font-size: 14px">&nbsp;Bitcoin transactions are secure, irrevocable, and do not contain sensitive or personal information of customers. This avoids losses to merchants due to fraud or fraudulent chargebacks, and there is no need to comply with PCI standards. Merchants can also easily expand into new markets where credit cards are unusable or fraud rates are unacceptably high. The end result is lower fees, a larger market, and fewer administrative costs. </span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<strong><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">Security and Control</span>&nbsp;-</span></strong><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;Bitcoin users are in full control of their transactions; it is impossible for merchants to forcefully charge fees that may occur in other payment methods that should not be or are not easy to find. Paying with Bitcoin eliminates the need to bind personal information in the transaction, which provides great protection against identity theft. Bitcoin users can also protect their funds through backup and encryption. </span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<strong><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">Transparency and neutrality</span>&nbsp;-</span></strong><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;All information about the Bitcoin funding supply itself is stored in the block chain, and anyone can check and use it in real time. No individual or organization can control or manipulate the Bitcoin protocol because it is password protected. This makes Bitcoin Core believed to be completely neutral, transparent and predictable. </span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<strong><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">3. What are the disadvantages of Bitcoin? </span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<strong><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">acceptance</span>&nbsp;-&nbsp;</span></strong><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing : 0;font-size: 14px"><span style="font-family:Microsoft Yahei">There are still many people who don’t know Bitcoin. Every day, more companies accept Bitcoin because they want to benefit from it, but this list is still small. In order to benefit from network effects, more companies still need to support Bitcoin. </span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<strong><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">Volatility</span>&nbsp;-&nbsp;</span></strong><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing : 0;font-size: 14px"><span style="font-family:Microsoft Yahei">The total value of Bitcoin in circulation and the number of companies using Bitcoin are still very small compared to their possible size. Therefore, relatively small events, transactions or business activities can significantly affect its price. In theory, as the Bitcoin market and technology mature, this volatility will decrease. This world has never seen any emerging currency before, so it is really difficult to imagine how it will progress</span> (and exciting at the same time). </span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<strong><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">In the development stage</span>&nbsp;-&nbsp;</span></strong><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter- spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">Bitcoin software is still in</span>beta version, and many unfinished functions are under active development. New tools, features and services are being developed to make Bitcoin safer and more accessible to the general public. Some of these functions are not currently available to every user. Most of the Bitcoin business is new and does not yet provide insurance. Generally speaking, Bitcoin is still in the process of maturity. </span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<strong><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">4. Detailed parameters</span></span> </strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Chinese name: Bitcoin</span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; English name: Bitcoin&nbsp; &nbsp; &nbsp;English abbreviation: BTC</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Developer:</span>Satoshi Nakamoto&nbsp; &nbsp;Core algorithm: SHA-256&nbsp; &nbsp; Release date: 2009/01/03</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Total: </span>21 million&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Block time: about 600 seconds/block&nbsp; Proof of consensus: POW</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Risk: There is a potential fork risk in the expansion dispute</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Currency unit:</span>BTC/XBT</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">1&nbsp; &nbsp; &nbsp; &nbsp;Bitcoins (Bitcoins, BTC)</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">10−2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp ;Bitcent (Bitcent, cBTC)</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">10−3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp ;Milli-Bitcoins (mBTC)</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">10−6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp ;Micro-Bitcoins (μBTC)</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">10−8&nbsp; &nbsp; &nbsp; Satoshi (Satoshi)< /span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<strong><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">5. Common links</span></span></span> </strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Official website:</span></span><a href="https://bitcoin.org/zh_CN/"><span style="font-family: Microsoft Yahei; color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">https://bitcoin.org/zh_CN/</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Bitcoin FAQ:</span></span><a href="https://bitcoin.org/zh_CN/faq"><span style="font-family: Microsoft Yahei; color: rgb(53 , 124, 225);letter-spacing: 0;font-size: 14px">https://bitcoin.org/zh_CN/faq</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Block query:</span></span><a href="https://blockchain.info/"><span style="font-family: Microsoft Yahei; color: rgb(53, 124, 225 );letter-spacing: 0;font-size: 14px">https://blockchain.info/</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Bitcoin Forum:</span></span><a href="https://bitcointalk.org/"><span style="font-family: Microsoft Yahei; color: rgb(53, 124, 225 );letter-spacing: 0;font-size: 14px">https://bitcointalk.org/</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Market value query website:</span></span><a href="https://coinmarketcap.com/"><span style="font-family: Microsoft Yahei; color: rgb(53, 124, 225 );letter-spacing: 0;font-size: 14px">https://coinmarketcap.com</span></a>
</p>
<p>
<span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
<p>
<br/>
</p>`