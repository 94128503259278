export default `<p>
    <strong>MANA（Decentraland）</strong>
</p>
<p>
    <br/>
</p>
<p>
    <strong>1.プロジェクト名</strong>
</p>
<p>
    Decentraland（MANA）
</p>
<p>
    に
</p>
<p>
    <strong>2.プロジェクトのポジショニング</strong>
</p>
<p>
    イーサリアムブロックチェーンに基づいてVRをサポートするMinecraftおよびSecondLife-styleゲームプラットフォーム。
</p>
<p>
    に
</p>
<p>
    <strong>3、プロジェクトの簡単なコメント</strong>
</p>
<p>
    プロジェクト開発の進捗は比較的完了しており、小規模な公開テストが実施されようとしています。最近の取引量は比較的多く、ユーザーコミュニティは活発であり、投資の可能性があります。
</p>
<p>
    最大のリスクは次のとおりです。1。最近の価格変動のリスク。2。内部ベータ版には大きなバグがあるか、ユーザーの期待に応えていません。
</p>
<p>
    に
</p>
<p>
    <strong>4.プロジェクトの進捗状況</strong>
</p>
<p>
    1.ICOは終了しました。
</p>
<p>
    2.内部ベータユーザーがゲームの内部ドメインオークションに参加するための登録は、12月15日まで受け付けています。
</p>
<p>
    3. 12月15日以降、鉄器時代のテストバージョンがリリースされ、ゲームの内部領域が購入と民営化のために開かれます。
</p>
<p>
    4.鉄器時代は、マルチユーザーサポート、仮想ポートレート、インスタントチャット機能を追加し、カスタムアイテムとスクリプトの作成を独自の領域で実行できるようにします。
</p>
<p>
    に
</p>
<p>
    <strong>5、プロジェクトの特徴</strong>
</p>
<p>
    1.ユーザーによって完全に制御される最初の仮想現実プラットフォーム、相互作用できる完全に統合された3D仮想世界。
</p>
<p>
    2.ユーザーは、イーサリアムブロックチェーンプラットフォームを介して土地を購入し、土地の所有者になることができます。
</p>
<p>
    3.ユーザーは、自分の領域のコンテンツを完全に制御できます。
</p>
<p>
    4.プラットフォームは、オープンスタンダードプロトコルによって管理および保護されています。
</p>
<p>
    5.ユーザーのドメイン所有権は、ブロックチェーンテクノロジーを使用して記録され、その不足と偽造防止を保証します。
</p>
<p>
    6. Microsoft Mixed Reality Platform（MR）をサポートするようになりました。
</p>
<p>
    に
</p>
<p>
    <strong>6、プロジェクトチーム</strong>
</p>
<p>
    プロジェクトリーダー：Benchriseの元共同創設者であるAriMeilich。
</p>
<p>
    創設者および技術リーダー：スマートコントラクトソリューションの元創設者であり、BitPayのソフトウェアエンジニアであるEstebanOrdano。
</p>
<p>
    に
</p>
<p>
    <strong>7.プロジェクトコンサルタント</strong>
</p>
<p>
    Li Xiaolai：INBlockchainの創設者
</p>
<p>
    Jake Brukhman：Coinfundの創設者
</p>
<p>
    Luis Cuende：アラゴンのプロジェクトリーダー
</p>
<p>
    ディエゴ・ドヴァル：元ニン最高技術責任者
</p>
<p>
    に
</p>
<p>
    <strong>8.ICOの進捗状況</strong>
</p>
<p>
    ICO時間：2017年8月18日
</p>
<p>
    ICOの進捗状況：100％
</p>
<p>
    に
</p>
<p>
    <strong>9、Tokenの概要</strong>
</p>
<p>
    プロジェクトトークン：MANA（ERC20）
</p>
<p>
    トークンの合計：28億500万
</p>
<p>
    ICO比率：40％
</p>
<p>
    コミュニティとパートナー：20％
</p>
<p>
    チームおよび初期の投資家：20％（3年間ロック）
</p>
<p>
    財団：20％
</p>
<p>
    トークンICOの価格：MANA = 0.16RMB
</p>
<p>
    に
</p>
<p>
    <strong>10.プロジェクトの資金調達と評価</strong>
</p>
<p>
    ICO融資：2400万
</p>
<p>
    ICO融資評価：6000万
</p>
<p>
    トークンの流通：81％
</p>
<p>
    に
</p>
<p>
    <strong>11.トークンの生成と使用</strong>
</p>
<p>
    トークン生成：スマートコントラクト
</p>
<p>
    トークンインフレ：年間8％のインフレ、そしてインフレ率は年々低下しています
</p>
<p>
    トークン機能：ゲームはテリトリーやその他の仮想製品やサービスを購入するために使用されます。テリトリーを購入した後のトークンは破棄されます。
</p>
<p>
    に
</p>
<p>
    <strong>12.一般的なリンク</strong>
</p>
<p>
    公式サイト：https：//decentraland.org/
</p>
<p>
    ホワイトペーパー：https：//decentraland.org/whitepaper.pdf
</p>
<p>
    に
</p>
<p>
    <strong>13.通貨が配置されているゾーン</strong>
</p>
<p>
    イノベーションゾーン
</p>
<p>
    <br/>
</p>`