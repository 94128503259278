<template>
<!-- 要么这样 -->
<div>
    <div class="div1">
        <div>
            <span>账号：</span>
            <input type="text">
        </div>
        <div>
            <span>密码：</span>
            <input type="text">
        </div>
        <div>
            <button>登录</button>
        </div>
        <img src="" alt="">

        <span></span>
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style>
.div1{
  padding: 50px 10px;
  width: 50%;
  margin: 0 auto;
}
</style>
