export default {
    title:"개인 정보 보호",
    content:`<p style="text-align: center;">
    <span style="font-size: 18px;"><strong>개인 정보 정책</strong></span>
</p>
<p>
    코디 글로벌
</p>
<p>
    <br/>
</p>
<p>
    &nbsp; &nbsp; Starthestar은(는) 이용자의 개인정보의 중요성을 인식하고 있으며, &quot;개인정보보호법&quot; 등 관계법령을 준수하며, 항상 이용자의 개인정보 보안을 존중하고 보호하겠습니다. 유틸리티 또는 서비스에 대한 더 나은 액세스를 제공하기 위해 당사는 귀하의 개인 정보를 수집, 저장, 유지 및 사용합니다. 따라서 Starthestar은 개인 정보 보호 정책을 통해 보안을 보장하기 위해 귀하의 개인 정보를 사용 및 저장하는 방법을 공개합니다.
</p>
<p>
    <br/>
</p>
<p>
    1 정보 수집
</p>
<p>
    &nbsp; &nbsp;귀하가 당사 서비스를 등록, 거래 또는 사용할 때 귀하는 귀하의 개인 정보 수집에 동의하는 것입니다. 즉, 귀하의 허가를 얻은 것입니다. 또한 Starthestar은 사기 또는 부적절한 수단을 사용하여 귀하의 개인 정보를 수집하지 않으며, 귀하에게 약관의 범위를 넘어 관련 정보를 제공하도록 요구하지 않습니다. 당사가 귀하의 개인정보를 수집하는 주된 목적은 귀하와의 원활한 의사소통 및 보다 나은 서비스 제공을 목적으로 하며, 수집되는 개인정보에는 이름, 연락처, 이메일 주소, 거래 정보, 귀하가 제공한 신원 정보 사본, 정부 발급 신분증 번호, 여권 번호, 장치 또는 인터넷 서비스와 관련된 모든 정보(예: IP 주소 및 MAC 번호) 및 특수 서비스 이용 시 은행 계좌와 같은 정보 제공을 요청받을 수 있습니다.
</p>
<p>
    <br/>
</p>
<p>
    2 목적:
</p>
<p>
    우리는 귀하의 개인 정보를 다음 용도로 사용합니다.
</p>
<p>
    <br/>
</p>
<p>
    2.1 Starthestar에서의 거래 프로세스 요약, 당사는 귀하가 당사에 제공한 개인 정보를 처리합니다.
</p>
<p>
    <br/>
</p>
<p>
    2.2 관련 사용자의 사기 행위를 방지하기 위해 실제 정보를 확인하고 확인합니다.
</p>
<p>
    <br/>
</p>
<p>
    2.3 합법적인 시민 확인, 자금 세탁 및 기타 부적절한 관행을 방지하기 위해 국제 표준을 채택했습니다.
</p>
<p>
    <br/>
</p>
<p>
    2.4 관련 약관 및 정책의 수정 사항을 즉시 통지합니다.
</p>
<p>
    <br/>
</p>
<p>
    2.5 비상 상황에서의 적시 의사소통
</p>
<p>
    <br/>
</p>
<p>
    2.6 국가 정부 기관의 관련 부서의 요구 사항을 따릅니다.
</p>
<p>
    <br/>
</p>
<p>
    2.7 사용자 맞춤형 서비스 지원
</p>
<p>
    <br/>
</p>
<p>
    2.8 Starthestar과의 원활한 커뮤니케이션, 관련 서비스에 대한 즉각적인 액세스 및 이벤트 기간 동안 경품 받기
</p>
<p>
    <br/>
</p>
<p>
    2.9 관련 법령의 필요성
</p>
<p>
    <br/>
</p>
<p>
    2.10 더 나은 기능과 제품을 제공하기 위해 Starthestar의 운영을 분석합니다.
</p>
<p>
    <br/>
</p>
<p>
    3 개인정보 보안 보호
</p>
<p>
    &nbsp; Starthestar은 귀하의 개인 정보를 다른 목적으로 사용하지 않습니다. 귀하의 허가 없이 Starthestar은 제3자(서비스와 관련된 제3자 보안 파트너 제외)에게 정보를 판매하거나 공개하지 않습니다. Starthestar은 귀하의 개인 정보를 사용합니다. 정보의 보안을 확보하여 분실, 파괴, 변조 또는 유출의 위험에 대비하고, Starthestar은 SSL 기술을 통해 귀하의 정보를 암호화 및 관리하고 있으며, 귀하만이 무단으로 사용 및 수정할 수 있습니다. , 다른 사람은 접근을 제한합니다. 또한 Starthestar 팀은 자율적이며 귀하의 개인 정보 보안을 최대한 보호하며 누출, 변조 등을 하지 않습니다.
</p>
<p>
    <br/>
</p>
<p>
    4 개인정보 설정
</p>
<p>
    &nbsp; &nbsp;귀하는 언제든지 Starthestar에 로그인하여 개인 정보를 조회, 추가, 수정 및 삭제할 수 있으며, 특별한 경우에는 변경을 위해 이메일로 당사에 연락해야 할 수도 있습니다. 개인정보취급방침에 동의하지 않을 경우 Starthestar에서 제공하는 관련 서비스가 종료될 수 있습니다.
</p>
<p>
    <br/>
</p>
<p>
    5 성명
</p>
<p>
    &nbsp; &nbsp; &nbsp;5.1 당사의 개인 데이터 취급에 대한 완전한 확신을 가지려면 개인 정보 보호 정책의 조건을 자세히 읽고 이해해야 합니다. 특히, 귀하가 당사 웹사이트에 로그인하면 귀하가 웹사이트에 등록되어 있는지 여부에 관계없이 귀하는 당사에 다음 사항을 수락, 동의, 착수 및 확인함을 표시합니다.
</p>
<p>
    <br/>
</p>
<p>
    5.2 귀하는 필요한 동의를 받아 자발적으로 개인 데이터를 당사에 공개합니다.
</p>
<p>
    <br/>
</p>
<p>
    5.3 귀하는 본 개인정보 보호정책의 모든 조건 및 제한 사항을 준수합니다.
</p>
<p>
    <br/>
</p>
<p>
    5.4 귀하는 당사가 이 웹사이트에 대한 로그인, 이 웹사이트 등록 및/또는 당사가 제공하는 서비스 사용을 통해 귀하의 정보를 수집하는 데 동의하며, 당사 개인정보 보호정책의 향후 개정에 동의합니다.
</p>
<p>
    <br/>
</p>
<p>
    6 개인정보 보호 문제 및 질문
</p>
<p>
    &nbsp; &nbsp; &nbsp;귀하의 ID와 비밀번호는 귀하가 개인적으로 보관하고 있습니다. 비밀번호를 항상 안전하게 관리하고, 타인에게 쉽게 공개하지 마십시오. 귀하의 비밀번호가 유출된 것을 발견하셨거나 정보가 비정상적이거나 Starthestar의 개인정보 보호에 관한 질문이나 제안 사항이 있는 경우 정책에 따라 다음 방법으로 문의하십시오. starthestar@starthestar.com으로 문의하십시오.
</p>
<p>
    <br/>
</p>`,
}