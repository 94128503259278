import request from '@/api/request'

const api = {
  // 钱包
  getCoins: 'user/getCoins',
  getLever: 'user/getLever',
  getContract: 'user/getContract',

  // 钱包记录
  getNewCurrenyRecord: 'user/getNewCurrenyRecord',
  getCoinsRecord: 'user/getCoinsRecord',
  getLeverRecord: 'user/getLeverRecord',
  getContractRecord: 'user/getContractRecord',

  // 币币
  getWalletCoins: 'user/getWalletCoins',
  getTradeCoinsList: 'user/getTradeCoinsList',
  getPendingCoinsList: 'user/getPendingCoinsList',
  getRecordCoinsList: 'user/getRecordCoinsList',

  // 杠杆
  getWalletLever: 'user/getWalletLever',
  getTradeLeverList: 'user/getTradeLeverList',
  getPendingLeverList: 'user/getPendingLeverList',
  getRecordLeverList: 'user/getRecordLeverList',

  // 合约
  getWalletContract: 'user/getWalletContract',
  getTradeContractList: 'user/getTradeContractList',
  getRecordContractList: 'user/getRecordContractList',

  //获取充值列表
  getDepositCurrency: 'user/getDepositCurrencyV2',
  getWithdrawalCurrency: 'user/getWithdrawalCurrencyV2',

  //提现记录
  getTransferRecord: 'user/getTransferRecord',
  getDepositRecord: 'user/getDepositRecord'
}

// 钱包
export function getDepositRecord(parameter) {
  return request({
    url: api.getDepositRecord,
    method: 'post',
    data: parameter
  })
}
export function getCoins(parameter) {
  return request({
    url: api.getCoins,
    method: 'post',
    data: parameter
  })
}
export function getLever(parameter) {
  return request({
    url: api.getLever,
    method: 'post',
    data: parameter
  })
}
export function getContract(parameter) {
  return request({
    url: api.getContract,
    method: 'post',
    data: parameter
  })
}

// 钱包记录
export function getNewCurrenyRecord(parameter) {
  return request({
    url: api.getNewCurrenyRecord,
    method: 'post',
    data: parameter
  })
}
export function getCoinsRecord(parameter) {
  return request({
    url: api.getCoinsRecord,
    method: 'post',
    data: parameter
  })
}
export function getLeverRecord(parameter) {
  return request({
    url: api.getLeverRecord,
    method: 'post',
    data: parameter
  })
}
export function getContractRecord(parameter) {
  return request({
    url: api.getContractRecord,
    method: 'post',
    data: parameter
  })
}

// 币币
export function getWalletCoins(parameter) {
  return request({
    url: api.getWalletCoins,
    method: 'post',
    data: parameter
  })
}
export function getTradeCoinsList(parameter) {
  return request({
    url: api.getTradeCoinsList,
    method: 'post',
    data: parameter
  })
}
export function getPendingCoinsList(parameter) {
  return request({
    url: api.getPendingCoinsList,
    method: 'post',
    data: parameter
  })
}
export function getRecordCoinsList(parameter) {
  return request({
    url: api.getRecordCoinsList,
    method: 'post',
    data: parameter
  })
}

// 杠杆
export function getWalletLever(parameter) {
  return request({
    url: api.getWalletLever,
    method: 'post',
    data: parameter
  })
}
export function getTradeLeverList(parameter) {
  return request({
    url: api.getTradeLeverList,
    method: 'post',
    data: parameter
  })
}
export function getPendingLeverList(parameter) {
  return request({
    url: api.getPendingLeverList,
    method: 'post',
    data: parameter
  })
}
export function getRecordLeverList(parameter) {
  return request({
    url: api.getRecordLeverList,
    method: 'post',
    data: parameter
  })
}

// 合约
export function getWalletContract(parameter) {
  return request({
    url: api.getWalletContract,
    method: 'post',
    data: parameter
  })
}
export function getTradeContractList(parameter) {
  return request({
    url: api.getTradeContractList,
    method: 'post',
    data: parameter
  })
}
export function getRecordContractList(parameter) {
  return request({
    url: api.getRecordContractList,
    method: 'post',
    data: parameter
  })
}

//充值列表
export function getDepositCurrency(parameter) {
  return request({
    url: api.getDepositCurrency,
    method: 'post',
    data: parameter
  })
}

export function getWithdrawalCurrency(parameter) {
  return request({
    url: api.getWithdrawalCurrency,
    method: 'post',
    data: parameter
  })
}

//提现记录
export function getTransferRecord(parameter) {
  return request({
    url: api.getTransferRecord,
    method: 'post',
    data: parameter
  })
}
