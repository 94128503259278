export default `<p>
    <strong>USDT（テザー）</strong>
</p>
<p>
    <br/>
</p>
<p>
    <strong>1.USDTとは</strong>
</p>
<p>
    USDTは、Tetherが立ち上げた安定した価値の通貨である米ドル（USD）に基づくトークンTether USD（以下USDT）です。1USDT= 1米ドル。ユーザーはUSDTを使用して、いつでもUSDと1：1で交換できます。テザーは1：1の予備保証を厳密に順守します。つまり、発行されたUSDTトークンごとに、その銀行口座には1米ドルの資金保証があります。ユーザーは、透明性を確保するために、テザープラットフォームで資金照会を行うことができます。ユーザーは、SWIFTを介してTetherが提供する銀行口座に米ドルを送金するか、交換を通じてUSDTを交換することができます。米ドルをご利用の場合は、逆の操作で十分です。ユーザーは、取引所でビットコインをUSDTに交換することもできます。
</p>
<p>
    に
</p>
<p>
    USDTは、ビットコインブロックチェーンでリリースされたオムニレイヤープロトコルに基づくデジタル資産です。 USDTの最大の特徴は、同額の米ドルに相当することです。 USDTは、デジタルネットワーク上の法定通貨のレプリカとして設計されており、不安定な暗号通貨市場で価値を維持するための優れたトークンになっています。
</p>
<p>
    に
</p>
<p>
    <strong>2.機能</strong>
</p>
<p>
    直感：USDTとUSDは同等で、1USDT = 1USDです。各通貨=米ドルでの単価に相当するUSDTの数。
</p>
<p>
    <br/>
</p>
<p>
    安定性：TEDAは法定通貨に支えられているため、ユーザーはほとんどのブロックチェーン資産の価格変動の影響を受けることなく、ブロックチェーン資産市場で取引できます。
</p>
<p>
    に
</p>
<p>
    透明性：TEDAコインの発行会社であるテザーは、市場で流通しているすべてのTEDAコインが対応する米ドルで裏付けられていることを確認するために、法定通貨保管口座が定期的に監査されていると主張しています。ストレージアカウントのステータスは公開されており、いつでも照会できます。さらに、すべてのTEDAコイン取引記録はパブリックチェーンで公開されます。
</p>
<p>
    に
</p>
<p>
    少額の取引手数料：テザーアカウント間の取引やTEDAコインを保管するウォレット間の取引には手数料はかかりません。 TEDAコインを法定通貨に変換するには、取引手数料が必要です。
</p>
<p>
    に
</p>
<p>
    <strong>3.頻繁に使用されるリンク</strong>
</p>
<p>
    公式サイト：https：//tether.to/
</p>
<p>
    ブロッククエリ：https：//www.omniexplorer.info/
</p>
<p>
    <br/>
</p>`