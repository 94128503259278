export default `<h2 style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:48px">
<strong><span style="font-family: Times New Roman;font-size: 16px;background: rgb(255, 255, 255)">COMP (Compound)</span></strong>
</h2>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">1. Project name</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">Compound (Comp)</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">Second, project positioning</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">Compound<span style="font-family:宋体">is an algorithmic, autonomous interest rate agreement built for developers to unlock A series of open financial applications. </span><span style="font-family:Calibri">Compound</span><span style="font-family:宋体">is a publicly accessible smart contract system based on Ethereum</span>< span style="font-family:Calibri">, </span><span style="font-family:宋体"> is committed to allowing borrowers to obtain loans by locking their encrypted assets in agreements, while lenders provide loans . The interest rates paid and received by borrowers and lenders are determined by the supply and demand of each encrypted asset. Mining each block will generate interest rates. Loans can be repaid at any time and locked assets can be withdrawn. </span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">3. Currency overview and distribution</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">total amount of tokens:</span>10,000,000 COMP</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">Circulation:</span>2,561,279&nbsp;COMP</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">4. Commonly used links</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">Project website:</span>&nbsp;</span><a href="https ://compound.finance/"><span style=";font-family:&#39;Times New Roman&#39;;color:rgb(53,124,225)">https://compound.finance/</span> </a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">White paper:</span></span><a href="https:// compound.finance/documents/Compound.Whitepaper.pdf"><span style=";font-family:&#39;Times New Roman&#39;;color:rgb(53,124,225)">https://compound.finance/ documents/Compound.Whitepaper.pdf</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">Block query</span>:&nbsp;</span><a href=" https://etherscan.io/token/0xc00e94cb662c3520282e6f5717214004a7f26888"><span style=";font-family:&#39;Times New Roman&#39;;color:rgb(53,124,225)">https://etherscan.io/ token/0xc00e94cb662c3520282e6f5717214004a7f26888</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p>
<span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
<p>
<br/>
</p>`