<template>
<div>
    <!-- 账户信息 -->
    <div v-if="progress == 'retrieve'" class="route-box">
        <div class="has-bg-level-1 theme--dark view-page-user-login">
            <div class="" style="height: 850px;">
                <div class="box has-bg-level-2 views-page-login-box-width px-7 pt-8 pb-7">
                    <div class="myTitle mb-3">
                        <div class="gang has-line-primary mr-4"></div>
                        <span class="title-large has-text-white">{{$t('retrieve.ptitle')}}</span>
                    </div>
                    <div class="py-0 mb-3 has-text-level-2 body-5">{{$t('login.email')}}</div>
                    <input type="text" class="input" v-model="params.email" @input="()=>{/*this.setEmailError(false)*/}" :placeholder="$t('login.email_desc')">
                    <div class="pt-2" style="height: 26px;">
                        <!-- <div class="body-3 has-text-tip-error" v-show="email_error.show">{{email_error.text}}</div> -->
                    </div>
                    <!--  -->
                    <div class="py-0 mb-3 has-text-level-2 body-5">{{$t('retrieve.pemail_code')}}</div>
                    <div class="control has-icons-right mb-6">
                        <div class="input-with-components">
                            <div class="input px-1">
                                <input maxlength="6" class="input without-border in-input" v-model="params.email_code" :placeholder="$t('valid_email.email_desc')">
                                <span class="line"></span>
                                <a class="body-1 views-page-login-send-code px-2" @click="sendEmail">{{overTime > 0 ? overTime +' s':$t('valid_email.get_code')}}</a>
                            </div>
                        </div>
                    </div>
                    <!--disabled=""-->
                    <button class="button mt-7 has-bg-primary button-large is-fullwidth has-text-white mb-5" @click="onNext()">{{$t('retrieve.pset')}}</button>
                    <div class="has-text-centered body-4 has-text-white">{{$t('signup.have_account')}}？<router-link to="login" class="has-text-primary">{{$t('login.login')}}</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- 设置密码 -->
    <div v-if="progress == 'setpwd'" class="route-box">
        <div class="has-bg-level-1 theme--dark view-page-user-login">
            <div class="" style="height: 850px;">
                <div class="box has-bg-level-2 views-page-login-box-width px-7 pt-8 pb-7">
                    <!-- <div class="title-large views-page-login-title opacity">{{$t('base.title')}}</div> -->
                    <div class="title-large has-text-white">{{$t('retrieve.set_title')}}</div>
                    <div class="py-0 mb-7 body-3 has-text-level-3">{{$t('retrieve.set_desc')}}</div>
                    <!--  -->
                    <div class="py-0 mb-3 has-text-level-2 body-5 mt-4">{{$t('retrieve.new_password')}}</div>
                    <div class="input-with-components">
                        <div class="input px-1">
                            <input :type="(isEyes?'text':'password')" class="input without-border in-input" v-model="params.password" @input="()=>{/*this.setPwdError(false)*/}" :placeholder="$t('retrieve.password_desc')">
                            <i :class="'iconfont mx-2 '+ (isEyes?'icon-yincang':'icon-zichanzhengyan')" @click="()=>{this.isEyes = !this.isEyes}"></i>
                        </div>
                    </div>
                    <div class="pt-2" style="height: 26px;">
                        <!-- <div class="body-3 has-text-tip-error" v-show="pwd_error.show">{{pwd_error.text}}</div> -->
                    </div>
                    <!--  -->
                    <div class="py-0 mb-3 has-text-level-2 body-5 mt-4">{{$t('retrieve.res_passowrd')}}</div>
                    <div class="input-with-components">
                        <div class="input px-1">
                            <input :type="(isEyes?'text':'password')" class="input without-border in-input" v-model="params.res_password" @input="()=>{/*this.setPwdError(false)*/}" :placeholder="$t('retrieve.res_passowrd_desc')">
                        </div>
                    </div>
                    <div class="pt-2" style="height: 26px;">
                        <!-- <div class="body-3 has-text-tip-error" v-show="pwd_error.show">{{pwd_error.text}}</div> -->
                    </div>
                    <!-- disabled="" -->
                    <button class="button my-3 has-bg-primary button-medium is-fullwidth has-text-white mb-2" @click="onSetPwd">{{$t('retrieve.pset')}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    checkEmailCode,
    updatePwd,
    sendEmail
} from '@/api/auth'
export default {
    name: "Retrieve",
    data() {
        return {

            progress: 'retrieve',

            //眼睛
            isEyes: false,
            params: {
                email: '',
                password: '',
                res_password: '',
                email_code: ''
            },

            //倒计时
            overTimeInterval: null,
            overTime: '',
        }
    },
    created() {
        //初始化倒计时
        this.runTime()
    },
    methods: {
        setProgress: function (val) {
            this.progress = val
        },
        validParam() {
            if (!this.params.email) {
                //this.setEmailError(true)
                this.$message({
                    message: this.$t("message.invalid_email"),
                    type: "warning",
                });
                return false;
            }

            if (!this.params.email_code) {
                this.$message({
                    message: this.$t("message.invalid_email_code"),
                    type: "warning",
                });
                return false;
            }

            return true
        },
        onNext() {
            if (!this.validParam()) {
                return
            }

            // loading
            this.$ui.openLoading()

            //检查邀请码
            checkEmailCode({
                email:this.params.email,
                email_code: this.params.email_code,
                scene:'find_password',
            }).then(res => {
                // close
                this.$ui.closeLoading()
                if (res.code == 1) {
                    this.$message({
                        message: res.msg,
                        type: "warning",
                    });
                    return
                }

                //进入下一阶段
                this.progress = 'setpwd'

            }).catch(() => {
                // close
                this.$ui.closeLoading()
            })
        },

        /**
         * 倒计时
         */
        runTime() {
            var _this = this
            if (_this.overTimeInterval == null) {
                this.setTime()
                _this.overTimeInterval = setInterval(function () {
                    //递减
                    _this.setTime()
                    console.log(_this.overTime, '倒计时')
                    if (_this.overTime <= 0) {
                        clearInterval(_this.overTimeInterval)
                        _this.overTimeInterval = null
                        console.log('倒计时结束')
                        return
                    }
                }, 1000)
            }
        },
        /**
         * 初始化时间
         */
        setTime() {
            // 初始化
            var endTime = parseInt(localStorage.getItem('find_endTime'))
            if (endTime) {
                this.overTime = parseInt(endTime - parseInt(new Date().getTime() / 1000))
            }
        },
        startTime(time) {
            if (this.overTime <= 0) {
                var endTime = parseInt(new Date().getTime() / 1000) + time;
                localStorage.setItem('find_endTime', endTime)
            }
            //倒计时
            this.runTime()
        },
        /**
         * 发送验证码
         */
        sendEmail() {

            let _this = this

            // 验证参数
            if (!this.params.email) {
                this.$message({
                    message: this.$t("message.invalid_email"),
                    type: "warning",
                });
                return;
            }

            if (this.overTime > 0) {
                this.$message({
                    message: this.$t("message.send_email", {
                        s: this.overTime
                    }),
                    type: "warning",
                });
                return;
            }

            // loading
            this.$ui.openLoading()

            // 请求接口
            sendEmail({
                    scene: "find_password",
                    email: this.params.email,
                })
                .then((res) => {
                    // close loading
                    _this.$ui.closeLoading();

                    if (res.code == 1) {
                        _this.$message({
                            message: res.msg,
                            type: "error",
                        });
                        return;
                    }

                    //倒计时
                    _this.startTime(res.data.time)

                    _this.$message({
                        message: res.msg,
                        type: "success",
                    });
                })
                .catch((res) => {
                    _this.$ui.closeLoading();
                    console.log("异常错误", res);
                });
        },
        onSetPwd() {
            let _this = this
            if (!this.validParam()) {
                return
            }

            if (!this.params.password) {
                //this.setPwdError(true)
                this.$message({
                    message: this.$t("message.invalid_password"),
                    type: "warning",
                });
                return false;
            }
            if (!this.params.res_password) {
                //this.setPwdError(true)
                this.$message({
                    message: this.$t("message.invalid_res_password"),
                    type: "warning",
                });
                return false;
            }

            // loading
            this.$ui.openLoading()

            // 提交注册
            updatePwd(this.params)
                .then((res) => {
                    // close
                    _this.$ui.closeLoading()

                    if (res.code === 1) {
                        this.$message({
                            message: res.msg,
                            type: "error",
                        });
                        return;
                    }

                    this.$message({
                        message: res.msg,
                        type: "success",
                    });

                    //删除之前的验证码倒计时
                    localStorage.removeItem('find_endTime')

                    // 前往登录
                    setTimeout(() => {
                        this.$router.push({
                            name: 'Login'
                        });
                    }, 1000);
                })
                .catch(() => {
                    // close
                    _this.$ui.closeLoading()
                });
        }
    }
}
</script>

<style src="../../static/css/signup.css" scoped type="text/css"></style>
