export default `<p>
    <strong>ELA (엘라 스 토스)</strong>
</p>
<p>
    <br/>
</p>
<p>
    1. 프로젝트 기본 상황
</p>
<p>
    프로젝트 이름 : Elastos
</p>
<p>
    프로젝트 약어 : Elastos
</p>
<p>
    토큰 이름 : ELA
</p>
<p>
    <br/>
</p>
<p>
    2. 프로젝트 소개
</p>
<p>
    Elastos는 블록 체인 기술을 기반으로 분산 된 SmartWeb을 구축하는 데 전념하는 차세대 Web 3.0 플랫폼입니다.
</p>
<p>
    Elastos는 메인 사이드 체인 구조, 다 계층 합의, 스마트 계약 합의 컴퓨팅 등과 같은 블록 체인 기술을 통해 플랫폼에 안전하고 신뢰할 수있는 기반을 제공하고 여기에 분산 된 ID, 통신, 스토리지, 컴퓨팅 등을 구축합니다. 플랫폼 서비스는 분산 된 애플리케이션을 지원하는 시스템 플랫폼을 제공하는 동시에 우수한 사용자 개인 정보 보호 및 데이터 보안을 제공하고 데이터 자산화 물결의 엔진이되는 것을 목표로합니다.
</p>
<p>
    <br/>
</p>
<p>
    3. 토큰 개요 및 배포
</p>
<p>
    총 발행량 : 28,219,999 (2021/02/23 업데이트)
</p>
<p>
    총 유통량 : 18,949,381 (2021/02/23 업데이트)
</p>
<p>
    <br/>
</p>
<p>
    ELA 토큰의 총 발행량은 약 2,822 만 개입니다.
</p>
<p>
    <br/>
</p>
<p>
    네, 일반적으로 사용되는 링크
</p>
<p>
    프로젝트 웹 사이트 : https://www.elastos.org
</p>
<p>
    백서 : https://www.elastos.org/static/file/elastos_whitepaper_v_0.2.pdf
</p>
<p>
    <br/>
</p>
<p>
    V. 통화가있는 구역
</p>
<p>
    혁신 존
</p>
<p>
    <br/>
</p>`