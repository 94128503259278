export default {
	pt1:"1.The user opens the STARTHESTAR official website: https://starthestar.com, clicks the \"download\" icon in the upper right corner of the homepage, and scans the corresponding QR code with the corresponding system mobile phone to download ",
	pimg1:"k7",
	pt2:"Or the user can open STARTHESTAR official mobile web version address: https://wap.starthestar.com, and the mobile web page can be downloaded directly. Take the IOS system as an example: click \"Download\" in the \"Download IOS App\" that appears below。",
	pimg2:"k7",
	// pt3:"In the redirected web interface, the first step is to get TestFlight, click \"View in App Store\" or directly open the App Store in your phone to search for TestFlight, and download and install it on your phone。",
	// pimg3:"k7",
	// pimg4:"k7",
	// pt4:"After installing TestFlight, go back to the web page and proceed to the second step, add this Bata version, and click \"Start Test\"",
	// pimg5:"k7",
	// pt5:"In the interface that appears, first click \"Accept\" after the test is completed, and finally click \"Download\". The download and installation process of  for the IOS version of the Apple mobile phone is completed.",
	pt6:"STARTHESTAR kindly reminds you: To download the Android version of mobile phone, click download after opening the browser to complete the installation. Some Android phones require system authorization during installation. Add the trusted APP permission and click confirm.",
}