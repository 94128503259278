export default {
    title:"执法请求政策",
    content:`<p style="text-align: center;">
    <span style="font-size: 18px;"><strong>执法请求政策</strong></span>
</p>
<p>
    Starthestar
</p>
<p>
    <br/>
</p>
<p>
    starthestar.com不时收到来自世界各地执法机构的客户信息请求，这里为您和执法部门提供关于如何处理这些请求的信息
</p>
<p>
    <br/>
</p>
<p>
    执法部门通常对两类数据感兴趣：有关客户身份的信息和有关其交易活动的信息。我们积极配合执法部门的工作，并只针对能提供授权证明的授权执法人员的执法请求做出回应
</p>
<p>
    <br/>
</p>
<p>
    我们根据服务条款，隐私政策和适用法律，向国际执法机关披露账户信息记录。starthestar.com要求执法请求附带适当的法律程序，以便于我们提供披露信息
</p>
<p>
    <br/>
</p>
<p>
    starthestar.com欢迎执法机构询问有关其政策和程序的问题，请通过starthestar@starthestar.com与我们联系
</p>
<p>
    <br/>
</p>
<p>
    发送执法请求时，请附带以下信息:
</p>
<p>
    <br/>
</p>
<p>
    1. 执法机关的名称;
</p>
<p>
    <br/>
</p>
<p>
    2. 执法人员被授权获取信息的证明文件（授权证明）;
</p>
<p>
    <br/>
</p>
<p>
    3. 执法人员的身份证明（执法机关内部证件）;
</p>
<p>
    <br/>
</p>
<p>
    4. 执法机关开具的调查函文件;
</p>
<p>
    <br/>
</p>
<p>
    5. 含有政府域名的电子邮件地址;
</p>
<p>
    <br/>
</p>
<p>
    6. 明确执法所需信息对应的法律主体名称;
</p>
<p>
    <br/>
</p>
<p>
    7. 请求客户信息具体包含的内容;
</p>
<p>
    <br/>
</p>
<p>
    8. 其他细节，包括提供合理的截止日期以及国际执法机构需提供根据国际司法互助条约所需的相关司法文件.
</p>
<p>
    <br/>
</p>
<p>
    starthestar.com保留向执法机构披露的权利，以保护自己和客户.
</p>
<p>
    <br/>
</p>`,
}