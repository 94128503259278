export default `<p>
    <strong>BAL（バランサー）</strong>
</p>
<p>
    <br/>
</p>
<p>
    1.プロジェクト名
</p>
<p>
    バランサー（BAL）
</p>
<p>
    <br/>
</p>
<p>
    2.プロジェクトのポジショニング
</p>
<p>
    バランサーは、プログラム可能な流動性のためのAMM（自動マーケットメイク）プロトコルです。 これにより、常に自己調整可能な柔軟な流動性プールを作成できます。 これらの流動性プールには、ウェイトが異なる複数のトークンと、カスタマイズ可能な取引手数料を含めることができます。 バランサープールは、従来のファイナンスのように維持費を支払う代わりに、流動性プロバイダーが報酬を得ることができる一種のインデックスファンドと考えることができます。 に
</p>
<p>
    3、通貨の概要と流通
</p>
<p>
    トークンの合計：35,735,000 BAL
</p>
<p>
    合計トークン制限：100,000,000 BAL（社会契約）
</p>
<p>
    総循環：6,943,831 BAL
</p>
<p>
    トークン価格：
</p>
<p>
    <br/>
</p>
<p>
    4つの一般的に使用されるリンク
</p>
<p>
    プロジェクトのウェブサイト：https：//balancer.finance/
</p>
<p>
    ホワイトペーパー：https：//balancer.finance/whitepaper/
</p>
<p>
    ブロッククエリ：https：//etherscan.io/address/0xba100000625a3754423978a60c9317c58a424e3d
</p>
<p>
    <br/>
</p>`