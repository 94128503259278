import request from "@/api/request"

const api = {
    checkInvitationCode: 'auth/checkInvitationCode',
    checkEmailCode: 'auth/checkEmailCode',
    signup: 'auth/signup',
    updatePwd: 'auth/updatePwd',
    sendEmail: 'auth/sendEmail',
    login: 'auth/login',
    saveCertified: 'auth/saveCertified',
    getCertified: 'auth/getCertified',

    //修改密码
    setPassword: 'auth/setPassword',
    setPayPassword: 'auth/setPayPassword',
    setFindPayPassword: 'auth/setFindPayPassword'
}

export function checkInvitationCode(parameter) {
    return request({
        url: api.checkInvitationCode,
        method: 'post',
        data: parameter
    })
}

export function checkEmailCode(parameter) {
    return request({
        url: api.checkEmailCode,
        method: 'post',
        data: parameter
    })
}

export function signup(parameter) {
    return request({
        url: api.signup,
        method: 'post',
        data: parameter
    })
}

export function updatePwd(parameter) {
    return request({
        url: api.updatePwd,
        method: 'post',
        data: parameter
    })
}

export function sendEmail(parameter) {
    return request({
        url: api.sendEmail,
        method: 'post',
        data: parameter
    })
}

export function login(parameter) {
    return request({
        url: api.login,
        method: 'post',
        data: parameter
    })
}

export function saveCertified(parameter) {
    return request({
        url: api.saveCertified,
        method: 'post',
        data: parameter
    })
}
export function getCertified(parameter) {
    return request({
        url: api.getCertified,
        method: 'post',
        data: parameter
    })
}

// 设置密码
export function setPassword(parameter) {
    return request({
        url: api.setPassword,
        method: 'post',
        data: parameter
    })
}

export function setPayPassword(parameter) {
    return request({
        url: api.setPayPassword,
        method: 'post',
        data: parameter
    })
}

export function setFindPayPassword(parameter) {
    return request({
        url: api.setFindPayPassword,
        method: 'post',
        data: parameter
    })
}