export default `<p>
    <strong>EOS（EOS）</strong>
</p>
<p>
    <br/>
</p>
<p>
    <strong>1. EOSとは何ですか？</strong>
</p>
<p>
    <br/>
</p>
<p>
    EOS（Enterprise Operation System）は、Block.oneによって開発されたまったく新しいブロックチェーンベースのスマートコントラクトプラットフォームであり、高性能の分散アプリケーションに基盤となるブロックチェーンプラットフォームサービスを提供することを目的としています。 EOSプロジェクトの目標は、オペレーティングシステムと同様の分散アプリケーションをサポートするブロックチェーンアーキテクチャを実現することです。このアーキテクチャは、アカウント、ID認証、データベース、非同期通信、プログラムスケジューリング、および数万のCPU / GPUクラスターでの並列操作を提供できます。 EOSは最終的に1秒あたり数百万のトランザクションの実行をサポートできますが、通常のユーザーはスマートコントラクトを実行するために使用料を支払う必要はありません。
</p>
<p>
    <br/>
</p>
<p>
    <strong>2.EOSの機能</strong>
</p>
<p>
    <br/>
</p>
<p>
    EOSトークンは現在、EOSブロックチェーンインフラストラクチャによって発行されるイーサリアムベースのトークンです。3つの主要なアプリケーションシナリオがあります。帯域幅とログストレージ（ハードディスク）、計算と計算の予約（CPU）、および状態ストレージ（RAM）です。 EOSメインネットが稼働すると、ERC20トークンEOSがメインチェーン上のトークンに変換されます。それは次のように説明することができます：
</p>
<p>
    <br/>
</p>
<p>
    （1）受取人の支払い：顧客はビジネスから特定の製品を購入し、これらの製品の販売収益はビジネスコストの支払いに使用され、顧客がブロックチェーンの使用に対して直接支払うことを回避し、制限または会社が製品の収益化戦略を決定するのを防ぎます。
</p>
<p>
    <br/>
</p>
<p>
    （2）認証機能：ブロックチェーンがEOSソフトウェアシステムに基づいて開発され、そのトークンがトークン所有者によって保持されている場合、使用可能な帯域幅の全部または一部をすぐに消費する必要がない場合があります。そのようなトークン所有者は次のことを選択できます。消費されていない帯域幅を他の人に譲渡またはリースします。
</p>
<p>
    に
</p>
<p>
    （3）トランザクションコストをトークン値から分離する：アプリケーションの所有者が対応する数のトークンを保持している場合、アプリケーションは固定状態と帯域幅の使用で実行を継続できます。開発者とユーザーはトークン市場の価格変動の影響を受けないため、価格に依存しません。
</p>
<p>
    に
</p>
<p>
    （4）ブロック報酬：ブロックが生成されるたびに、EOSはブロックプロデューサーに新しいトークンを報酬として与え、その運用と保守のコストを支払います。現在のEOS設定は、ブロックプロデューサーの報酬の上限を制限し、EOSがブロックプロデューサーに設定する報酬の現在の上限は、年間最大5％です。つまり、トークンの新しい供給の年間合計増加は、 5％を超えないこと。
</p>
<p>
    に
</p>
<p>
    <strong>3.頻繁に使用されるリンク</strong>
</p>
<p>
    公式サイト：https：//eos.io/
</p>
<p>
    ホワイトペーパー：https：//github.com/EOSIO/Documentation/blob/master/TechnicalWhitePaper.md#support-millions-of-users
</p>
<p>
    <br/>
</p>`