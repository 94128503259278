export default `<p>
    <strong>MLN (멜론)</strong>
</p>
<p>
    <br/>
</p>
<p>
    <strong>1. 프로젝트 이름</strong>
</p>
<p>
    멜론 (MLN)
</p>
<p>
    <br/>
</p>
<p>
    <strong>2. 프로젝트 포지셔닝</strong>
</p>
<p>
    Melon은 이더 리움 블록 체인으로 구동되는 디지털 자산 관리 플랫폼입니다. Melon의 목표는 개인 투자자이든 기관 투자 관리자이든 다양한 유형의 자산 관리자가 투자 포트폴리오를 안전하고 빠르고 효율적으로 관리하도록 돕는 것입니다.
</p>
<p>
    <br/>
</p>
<p>
    <strong>3, 통화 개요 및 배포</strong>
</p>
<p>
    총 토큰 : 1,524,081
</p>
<p>
    총 유통량 : 1,093,397
</p>
<p>
    <br/>
</p>
<p>
    <strong>4, 일반적으로 사용되는 링크</strong>
</p>
<p>
    프로젝트 웹 사이트 : https://melonprotocol.com/
</p>
<p>
    백서 : https://github.com/melonproject/paper/blob/master/melonprotocol.pdf
</p>
<p>
    블록 쿼리 : https://etherscan.io/token/0xec67005c4E498Ec7f55E092bd1d35cbC47C91892
</p>
<p>
    <br/>
</p>`