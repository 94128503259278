<template>
  <div id="dark">
    <div class="pub-layout-content-right is-pulled-right py-0 pub-layout-content-kline-right">

      <!-- 右边上面 -->
      <div class="pub-layout-content-right-place-order py-1 pl-1" style="position: relative;">
        <!-- 遮罩 -->
        <div class="ze-bg" v-if="token == ''">
          <div class="ze-btn">
            <button class="ze-button" @click="login">{{$t('login.login')}}</button>
            <button class="ze-button" @click="signup">{{$t('signup.signup')}}</button>
          </div>
        </div>
        <!-- 上面操作 -->
        <div class="pub-place-order py-0 px-0 box">
          <!-- 表头 -->
          <div class="pub-place-order-tabs tabs is-small limit-order">
            <div class="is-flex px-3 is-background-3 pt-1">
              <ul>
                <li class="  is-active">
                  <a href="javascript:void(0);" class="a-button-pad px-0 mr-5 ">{{$t('coins.title')}}</a>
                </li>
              </ul>
              <!-- 可用 -->
              <!-- <div class="header-wit-balance">
                            <div class="pub-place-order-form-wallet is-flex field has-text-2 spottick-text-color-4">
                                <div class="order-get">可用</div>
                                <div class="spacer"></div>
                                <div class="order-get">{{walletList[Defkey] && walletList[Defkey].balance}} USDT</div>
                                <div class="order-get"></div>
                            </div>
                        </div> -->
            </div>
          </div>

          <!-- 买和卖切换 -->
          <div class="pub-place-order-form-lever-btns field buy-sell">
            <button :class="'button is-fullwidth button-radius text-font-flex ord-buy-sell is-text-3 ' + (showView == TradeType.Buy?'is-success':'bs-btn')" @click="changeView(TradeType.Buy)">
              {{$t('coins.buy')}}
            </button>
            <button :class="'button is-fullwidth button-radius text-font-flex ord-buy-sell is-text-3 ' + (showView == TradeType.Sell?'is-danger':'bs-btn')" @click="changeView(TradeType.Sell)">
              {{$t('coins.sell')}}
            </button>
          </div>

          <!-- 交易界面 买入-->
          <div class="pub-place-order-form has-text-2 px-3 pb-3" v-if="showView == TradeType.Buy">
            <!-- 价格类型 -->
            <div class="pub-place-order-form-lever-btns field button-price">
              <a :class="'button is-small has-border ' + (buyPriceType == PriceType.Market?'active':'')" @click="btnBuyPrice(PriceType.Market)">{{$t('coins.market')}}</a>
              <a :class="'button is-small has-border ' + (buyPriceType == PriceType.Limit?'active':'')" @click="btnBuyPrice(PriceType.Limit)">{{$t('coins.limit')}}</a>
            </div>

            <!-- 价格 -->
            <div class="pub-place-order-form-prz-input field">
              <div class="control has-flex">
                <div class="control is-background-3">
                  <button class="button input2 is-static2 is-background-3 has-text-1 is-left-radius text-font-flex text-font-flex-long has-text-double button-input-height">
                    {{$t('coins.price')}}
                  </button>
                </div>
                <input type="number" @input="autoBuyNumber" :readonly="(buyPriceType == PriceType.Market)" v-model="currentBuyPrice" :placeholder="$t('coins.price_desc')" step="0.1" pattern="d*" class="input is-right-radius input2 text-font-flex button-input-height">
                <div class="control is-background-3 cursor-pointer">
                  <button class="button input2 is-static2 is-background-3 has-text-primary is-left-radius text-font-flex text-font-flex-long button-input-height">
                  </button>
                </div>
              </div>
            </div>
            <!-- 数量 -->
            <div class="pub-place-order-form-num-input field" style="margin-bottom: 0;">
              <div class="control">
                <div class="control has-flex">
                  <div class="control is-background-3">
                    <button class="button input2 is-static2 is-background-3 has-text-1 is-left-radius text-font-flex text-font-flex-long has-text-double button-input-height">
                      {{$t('coins.number')}}
                    </button>
                  </div>
                  <input type="number" @input="inputBuyNumber" v-model="buy_number" :placeholder="$t('coins.number_desc')" step="0.00000001" class="input is-right-radius input2 text-font-flex button-input-height">
                  <div class="control is-background-3">
                    <button class="button input2 is-static2 is-background-3  has-text-1 is-left-radius text-font-flex text-font-flex-long button-input-height">
                      {{currency.name}}
                    </button>
                  </div>
                </div>
                <!-- <span class="pub-place-order-form-num-input-face-value">≈ 0.00 USDT (手续费:0.00 USDT)</span> -->
              </div>
            </div>
            <!-- 持仓 -->
            <div class="header-wit-balance">
              <div class="pub-place-order-form-wallet is-flex field has-text-2 spottick-text-color-4">
                <div class="order-get">{{$t('coins.available')}}</div>
                <div class="spacer"></div>
                <div class="order-get">{{usdtWallet && usdtWallet.balance}} {{usdtWallet.name}}</div>
                <div class="order-get"></div>
              </div>
            </div>

            <!-- 快捷操作 -->
            <div class="pub-place-order-form-lever-btns">
              <a class="button is-small has-border" @click="btnBuySet(0.5)">{{$t('coins.half')}}</a>
              <a class="button is-small has-border" @click="btnBuySet(1)">{{$t('coins.all')}}</a>
            </div>

            <!-- 手续费 -->
            <div class="header-wit-balance">
              <div class="pub-place-order-form-wallet is-flex field has-text-2 spottick-text-color-4">
                <div class="order-get">{{$t('coins.fee')}}</div>
                <div class="spacer"></div>
                <div class="order-get">≈ {{buy_fee_usdt}} USDT</div>
                <div class="order-get"></div>
              </div>
            </div>
            <!-- 交易额 -->
            <div class="header-wit-balance field">
              <div class="pub-place-order-form-wallet is-flex field has-text-2 spottick-text-color-4">
                <div class="order-get">{{$t('coins.amount')}}</div>
                <div class="spacer"></div>
                <div class="order-get">≈ {{buy_usdt}} USDT</div>
                <div class="order-get"></div>
              </div>
            </div>
            <div class="spacer"></div>
            <div class="pub-place-order-form-buttons field">
              <div class="level-item">
                <div class="button-width">
                  <div class="button-default-width">
                    <button class="button is-success is-fullwidth button-radius text-font-flex ord-buy-sell is-text-3" @click="openWind(TradeType.Buy)">
                      {{$t('coins.buy')}}
                    </button>
                  </div>
                </div>
                <!-- <div class="button-width">
                                <div class="button-default-width">
                                    <button class="button is-danger is-fullwidth button-radius text-font-flex ord-buy-sell is-text-3">
                                        卖出
                                    </button>
                                </div>
                            </div> -->
              </div>
            </div>
            <!--  -->
          </div>

          <!-- 卖出 -->
          <div class="pub-place-order-form has-text-2 px-3 pb-3" v-if="showView == TradeType.Sell">
            <!-- 价格类型 -->
            <div class="pub-place-order-form-lever-btns field button-price">
              <a :class="'button is-small has-border ' + (sellPriceType == PriceType.Market?'active':'')" @click="btnSellPrice(PriceType.Market)">{{$t('coins.market')}}</a>
              <a :class="'button is-small has-border ' + (sellPriceType == PriceType.Limit?'active':'')" @click="btnSellPrice(PriceType.Limit)">{{$t('coins.limit')}}</a>
            </div>
            <!-- 止盈止损 -->
            <div class="pub-place-order-form-lever-btns field button-price">
              <a :class="'button is-small has-border ' + (sellPriceType == PriceType.Profit?'active':'')" @click="btnSellPrice(PriceType.Profit)">{{$t('coins.profit')}}</a>
              <a :class="'button is-small has-border ' + (sellPriceType == PriceType.Loss?'active':'')" @click="btnSellPrice(PriceType.Loss)">{{$t('coins.loss')}}</a>
            </div>

            <!-- 价格 -->
            <div class="pub-place-order-form-prz-input field">
              <div class="control has-flex">
                <div class="control is-background-3">
                  <button class="button input2 is-static2 is-background-3 has-text-1 is-left-radius text-font-flex text-font-flex-long has-text-double button-input-height">
                    {{$t('coins.price')}}
                  </button>
                </div>
                <input type="number" @input="autoSellNumber" :readonly="(sellPriceType == PriceType.Market)" v-model="currentSellPrice" :placeholder="$t('coins.price_desc')" step="0.00000001" class="input is-right-radius input2 text-font-flex button-input-height">
                <div class="control is-background-3 cursor-pointer">
                  <button class="button input2 is-static2 is-background-3 has-text-primary is-left-radius text-font-flex text-font-flex-long button-input-height">
                  </button>
                </div>
              </div>
            </div>
            <!-- 数量 -->
            <div class="pub-place-order-form-num-input field" style="margin-bottom: 0;">
              <div class="control">
                <div class="control has-flex">
                  <div class="control is-background-3">
                    <button class="button input2 is-static2 is-background-3 has-text-1 is-left-radius text-font-flex text-font-flex-long has-text-double button-input-height">
                      {{$t('coins.number')}}
                    </button>
                  </div>
                  <input type="number" @input="inputSellNumber" v-model="sell_number" :placeholder="$t('coins.number_desc')" step="1" pattern="d*" class="input is-right-radius input2 text-font-flex button-input-height">
                  <div class="control is-background-3">
                    <button class="button input2 is-static2 is-background-3  has-text-1 is-left-radius text-font-flex text-font-flex-long button-input-height">
                      {{currency.name}}
                    </button>
                  </div>
                </div>
                <!-- <span class="pub-place-order-form-num-input-face-value">≈ 0.00 USDT (手续费:0.00 USDT)</span> -->
              </div>
            </div>
            <!-- 持仓 -->
            <div class="header-wit-balance">
              <div class="pub-place-order-form-wallet is-flex field has-text-2 spottick-text-color-4">
                <div class="order-get">{{$t('coins.available')}}</div>
                <div class="spacer"></div>
                <div class="order-get">{{currentWallet && currentWallet.balance}} {{currency.name}}</div>
                <div class="order-get"></div>
              </div>
            </div>

            <!-- 快捷操作 -->
            <div class="pub-place-order-form-lever-btns">
              <a class="button is-small has-border" @click="btnSellSet(0.5)">{{$t('coins.half')}}</a>
              <a class="button is-small has-border" @click="btnSellSet(1)">{{$t('coins.all')}}</a>
            </div>

            <!-- 交易额 -->
            <div class="header-wit-balance field">
              <div class="pub-place-order-form-wallet is-flex field has-text-2 spottick-text-color-4">
                <div class="order-get">{{$t('coins.amount')}}</div>
                <div class="spacer"></div>
                <div class="order-get">≈ {{sell_usdt}} USDT</div>
                <div class="order-get"></div>
              </div>
            </div>
            <!-- 手续费 -->
            <!-- <div class="header-wit-balance field">
                        <div class="pub-place-order-form-wallet is-flex field has-text-2 spottick-text-color-4">
                            <div class="order-get">手续费</div>
                            <div class="spacer"></div>
                            <div class="order-get">≈ {{sell_fee_usdt}} USDT</div>
                            <div class="order-get"></div>
                        </div>
                    </div> -->
            <!-- 止盈止亏 -->
            <!-- <div class="pub-place-order-form-stop-pl field">
                                        <label class=" my-tooltip dropdown is-hoverable tooltip-icon dropdown-trigger bdb-dashed pub-place-order-form-stop-pl-label label has-text-1" style="margin-top: 10px; margin-bottom: 15px;">
                                            <div class="my-tooltip dropdown is-hoverable tooltip-icon">
                                                <div class="dropdown-trigger bdb-dashed ">止盈止损（选填）</div>
                                                <div class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-center  content-width">
                                                    <div class="dropdown-content" style="/*width: 10rem;*/">
                                                        <div class="dropdown-item tooltip-text-justify">
                                                            <p>
                                                                劈里啪啦的说一堆，反正你也看不懂，我就随便打点字表示这里是说明信息
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                        <div class="pub-place-order-form-stop-pl-input field has-addons">
                                            <div class="pub-place-order-form-stop-pl-input-p control is-expanded">
                                                <input type="number" placeholder="止盈价" step="0.1" pattern="d*" class="input text-font-flex button-input-height">
                                            </div>
                                            <div class="pub-place-order-form-stop-pl-input-center control">&amp;</div>
                                            <div class="pub-place-order-form-stop-pl-input-l control is-expanded">
                                                <input type="number" placeholder="止损价" step="0.1" pattern="d*" class="input text-font-flex button-input-height">
                                            </div>
                                        </div>
                                    </div> -->

            <!-- <div class="pub-place-order-form-trigger-prz-input field">
                                        <div class="control">
                                            <input type="number" placeholder="" readonly="" class="input opacity-0">
                                        </div>
                                    </div> -->
            <div class="spacer"></div>
            <div class="pub-place-order-form-buttons field">
              <div class="level-item">
                <!-- <div class="button-width">
                                <div class="button-default-width">
                                    <button class="button is-success is-fullwidth button-radius text-font-flex ord-buy-sell is-text-3">
                                        买入
                                    </button>
                                </div>
                            </div> -->
                <div class="button-width">
                  <div class="button-default-width">
                    <button class="button is-danger is-fullwidth button-radius text-font-flex ord-buy-sell is-text-3" @click="openWind(TradeType.Sell)">
                      {{$t('coins.sell')}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
          </div>
        </div>
      </div>

      <!-- 右边下面 -->
      <div class="pub-layout-content-right-wallet  py-0 pl-1">
        <div class="pub-wallet-box box has-text-centered px-0 py-0">
          <div class="pub-wallet-box-tabs tabs is-background-3 px-3 ">
            <ul class="is-between">
              <li class=""><a class="has-text-1-important wlt-coin">{{$t('coins_assets.title')}}</a></li>
            </ul>
          </div>
          <div class="pub-wallet px-3 py-3">
            <div class="pub-wallet-content has-text-left">
              <div class="level-item">
                <div class="text--secondary has-text-2">
                  <div class="my-tooltip dropdown is-hoverable ">
                    <div class="dropdown-trigger -bdb-dashed spottick-text-color-4">{{$t('coins_assets.total_equity')}}</div>
                    <!-- <div class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-center  ">
                                        <div class="dropdown-content" style="">
                                            <div class="dropdown-item tooltip-text-justify">存入-取出+已实现盈亏+未实现盈亏</div>
                                        </div>
                                    </div> -->
                  </div>
                </div>
                <div class="has-text-1">{{u_total}}</div>
              </div>
              <div class="level-item">
                <div class="text--secondary has-text-2">
                  <div class="my-tooltip dropdown is-hoverable ">
                    <div class="dropdown-trigger -bdb-dashed spottick-text-color-4">{{$t('coins_assets.equity')}}</div>
                  </div>
                </div>
                <div class="has-text-1 ">
                  <div class=" ">{{u_usable}}</div>
                </div>
              </div>
              <div class="level-item">
                <div class="text--secondary has-text-2">
                  <div class="my-tooltip dropdown is-hoverable ">
                    <div class="dropdown-trigger -bdb-dashed spottick-text-color-4">{{$t('coins_assets.number')}}</div>
                  </div>
                </div>
                <div class="has-text-1">{{u_number}}</div>
              </div>
              <div class="level-item">
                <div class="text--secondary has-text-2">
                  <div class="my-tooltip dropdown is-hoverable ">
                    <div class="dropdown-trigger -bdb-dashed spottick-text-color-4">{{$t('coins_assets.market_value')}}</div>
                  </div>
                </div>
                <div class="has-text-1">{{u_market_value}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pub-layout-content-right-spot-info py-1 pl-1">
        <div class="pub-spot-info box px-0 py-0">
          <div class="pub-spot-info-tabs tabs is-background-3 px-3">
            <ul>
              <li class=""><a href="javascript:void(0);" class="has-text-1-important wlt-coin">{{$t('coins_desc.title')}}</a></li>
            </ul>
          </div>
          <div class="pub-spot-info-content px-3 py-3">
            <div v-for="(item,index) in currencyDesc" :key="index">
              <div class="level-item" v-if="item.type == 1">
                <div class="text--secondary spottick-text-color-4">{{item.name}}</div>
                <div class="has-text-1" style="max-width: 60%;">{{item.value}}</div>
              </div>
              <div class="level-item" v-if="item.type == 2">
                <div class="text--secondary spottick-text-color-4">{{item.name}}</div>
                <a :href="item.value" target="_blank" class="has-text-1" style="text-decoration: underline;">{{item.value}}</a>
              </div>
              <div class="level-item mb-0-important" v-if="item.type == 3">
                <a :href="item.value">
                  <div class="text--secondary has-text-primary cursor-pointer">{{item.name}}...</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 输入密码 -->
    <el-dialog :title="$t('trade.title')" :visible.sync="dialogVisible" :before-close="cancelTrade" class="trade-dialog trade-dg" style="width:50%;margin: 0 auto;top: 20%;">
      <el-row>
        <!-- <el-col :span="24">
                <label style="padding: 10px 0;color:#FFF;">交易密码:</label>
            </el-col> -->
        <el-col :span="24">
          <el-input v-model="tradePwd" type="password" :placeholder="$t('trade.enter_password')" style="width:100%"></el-input>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelTrade">{{$t('trade.cancel')}}</el-button>
        <el-button @click="confirmTrade">{{$t('trade.submit')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getWalletCoins } from "@/api/user";
import { buyCoins, sellCoins } from "@/api/currency";
import {
  Float8,
  ScToNumber,
  Event,
  GetFixed,
  GetNewFixed,
} from "../../utils/helper";

const PriceType = {
  // 市价
  Market: 1,
  // 限价
  Limit: 2,
  // 止盈
  Profit: 3,
  // 止损
  Loss: 4,
};

const TradeType = {
  Buy: 1,
  Sell: 2,
};
//默认交易对协议
const Defkey = "USDT";

export default {
  name: "CoinsTrade",
  props: ["price"],
  data() {
    return {
      Defkey,
      PriceType,
      TradeType,

      //交易界面
      showView: TradeType.Buy,

      //当前币
      currency: {},
      //当前钱包
      currentWallet: {
        balance: 0,
      },
      //USDT当前钱包
      usdtWallet: {},

      //-------------------
      //买
      // 数量
      buy_number: "",
      //交易额
      buy_usdt: "0.00",
      //手续费
      buy_fee_usdt: "0.00",
      //总抵扣
      buy_total: 0,
      //当前价格
      currentBuyPrice: 0,
      //交易类型
      buyPriceType: PriceType.Market,

      //-------------------
      //卖
      //卖的数量
      sell_number: "",
      //交易额
      sell_usdt: "0.00",
      //手续费
      sell_fee_usdt: "0.00",
      //总抵扣
      sell_total: 0,
      //当前价格
      currentSellPrice: 0,
      //交易类型
      sellPriceType: PriceType.Market,

      //弹出窗口
      dialogVisible: false,

      //交易密码
      tradePwd: "",
      tradeType: "",

      //token
      token: "",

      //总
      u_total: 0,
      u_usable: 0,
      u_number: 0,
      u_market_value: 0,

      //币信息
      currencyDesc: [],
    };
  },
  created() {
    console.log("CoinsTrade");

    this.checkToken();
  },
  methods: {
    setWallet(res, u_total, u_usable) {
      //总资产
      this.u_total = u_total;
      this.u_usable = u_usable;
      this.usdtWallet.balance = u_usable;

      //币币数量
      if (res.coins[this.currency.symbol]) {
        this.u_number = GetNewFixed(res.coins[this.currency.symbol].balance);
        this.u_market_value = GetNewFixed(res.coins[this.currency.symbol].usdt);
      }
    },
    /**
     * 交易界面显示
     */
    changeView(view) {
      this.showView = view;
    },
    checkToken() {
      //获取缓存的token
      let token = localStorage.getItem("token");
      if (token) {
        this.token = token;
      } else {
        this.token = "";
      }

      return token;
    },
    login() {
      this.$router.push({
        name: "Login",
      });
    },
    signup() {
      this.$router.push({
        name: "Signup",
      });
    },
    setDetail(detail) {
      this.currency.close = GetFixed(detail.close);
      //更新价格
      this.updatePrice();
    },
    /**
     * 语言变化
     */
    listenLang(local) {
      console.log(local, "CoinsTrade");

      //获取币信息
      this.getCoinsDesc(this.currency.symbol);
    },
    /**
     * 设置币
     */
    setCurrency(currency) {
      this.currency = currency;
      //更新价格
      this.updatePrice();
      //获取币信息
      this.getCoinsDesc(currency.symbol);
      //获取钱包
      this.getWalletCoins();
    },
    /**
     * 获取币信息
     */
    getCoinsDesc(symbol) {
      //获取币的信息
      this.currencyDesc = this.$t(`coins_desc.${symbol}`);
    },
    /**
     * 获取币币账户
     */
    getWalletCoins() {
      if (!this.checkToken()) {
        return;
      }
      let _this = this;
      getWalletCoins({
        c_id: this.currency.id,
      }).then((res) => {
        //设置当前钱包
        if (_this.currency) {
          if (res.data[_this.currency.name]) {
            _this.currentWallet = res.data[_this.currency.name];
          } else {
            _this.currentWallet = {
              c_id: _this.currency.id,
              balance: 0,
              name: _this.currency.name,
              symbol: _this.currency.symbol,
            };
          }
          _this.usdtWallet = res.data[Defkey];
          console.log(
            "[CoinsTrade] 当前" + _this.currency.name,
            _this.currency
          );
          console.log(
            "[CoinsTrade] 当前" + _this.currency.name + "钱包",
            _this.currentWallet
          );
          console.log("[CoinsTrade] 当前USDT钱包", _this.usdtWallet);
        }
      });
    },
    /**
     * 交易密码弹出框
     */
    openWind(type) {
      if (type == TradeType.Buy && !this.validBuy()) {
        return;
      }

      if (type == TradeType.Sell && !this.validSell()) {
        return;
      }

      this.tradeType = type;
      this.tradePwd = "";
      // this.dialogVisible = true
      this.confirmTrade();
    },
    cancelTrade() {
      console.log("交易密码");
      this.dialogVisible = false;
    },
    confirmTrade() {
      console.log("确认交易密码");
      this.dialogVisible = false;
      if (this.tradeType == TradeType.Buy) {
        this.onBuy();
      }
      if (this.tradeType == TradeType.Sell) {
        this.onSell();
      }
    },
    updatePrice() {
      if (this.buyPriceType == PriceType.Market) {
        //更新价格 [通过改变输入框，重新计算交易额]
        this.currentBuyPrice = this.currency.close;
        //[调用]
        this.autoBuyNumber();
      }

      if (this.sellPriceType == PriceType.Market) {
        //更新价格 [通过改变输入框，重新计算交易额]
        this.currentSellPrice = this.currency.close;
        //[调用]
        this.autoSellNumber();
      }
    },

    /**--------------------------------------------------
     * 价格类型
     */
    btnBuyPrice(type) {
      this.buyPriceType = type;
      // 立即更新
      this.updatePrice();
    },
    /**
     * 输入过滤
     */
    _BuyGetNumber() {
      //数初始化
      let number = this.buy_number;
      if (number) {
        let number = Number(number);
      }

      //数据过滤
      if (number < 0) {
        this.buy_number = "";
        // console.log(number, '数据负值')
        return 0;
      }
      if (!number || number == 0) {
        // console.log(number, '数据无值')
        this._SetBuyParams({
          number: number,
          usdt: "0.00",
          fee: "0.00",
          total: 0,
        });
        return 0;
      }

      //console.log(number, '数据正常')
      number = ScToNumber(number);
      //console.log(number, '数据转换')

      return number;
    },
    /**
     * 获取基本参数
     */
    _BuyGetBase() {
      //基本参数
      let price = parseFloat(this.currentBuyPrice);
      let rate = parseFloat(this.currency.coins_buy_rate / 100);
      let fee_min = parseFloat(this.currency.coins_buy_min);
      let balance = parseFloat(this.usdtWallet.balance);

      //console.log("当前价格：" + price)
      //console.log("费率：" + rate)
      //console.log("最小手续费：" + fee_min)
      //console.log("可用：" + balance + " USDT")

      return {
        price: price,
        rate: rate,
        fee_min: fee_min,
        balance: balance,
      };
    },
    /**
     * 计算结果设置
     */
    _SetBuyParams(res) {
      this.buy_usdt = res.usdt;
      this.buy_number = res.number;
      this.buy_fee_usdt = res.fee;
      this.buy_total = res.total;
    },
    /**
     * 已知数量
     */
    _BuyGetTotal(number) {
      if (!number || number < 0) {
        return {
          number: "",
          usdt: "0.00",
          fee: "0.00",
          total: 0,
        };
      }

      //基本参数
      let base = this._BuyGetBase();

      //交易额
      let usdt = Float8(number * base.price);
      //手续费
      let fee = Float8(usdt * base.rate);
      if (fee < base.fee_min) {
        fee = base.fee_min;
      }
      //实际扣除
      let total = Float8(usdt + fee);

      return {
        number: number,
        usdt: usdt,
        fee: fee,
        total: total,
      };
    },
    /**
     * 根据总额求数量
     */
    _BuyGetTotal2(total) {
      if (!total || total < 0) {
        return {
          number: "",
          usdt: "0.00",
          fee: "0.00",
          total: 0,
        };
      }
      //基本参数
      let base = this._BuyGetBase();

      //购买数量 [推算公式] 扣除 = (x * 单价) + (x * 单价)*费率
      let number = Float8(total / (base.price + base.rate * base.price));

      //交易额
      let usdt = Float8(number * base.price);
      //计算费率
      let fee = Float8(usdt * base.rate);
      if (fee < base.fee_min) {
        fee = base.fee_min;

        //重新计算 [推算公式] 扣除 = (单价*x) + 100
        number = Float8((total - fee) / base.price);
        //交易额
        usdt = Float8(number * base.price);

        if (!number || number < 0) {
          this.$message({
            message: this.$t("message.not_enough"),
            type: "warning",
          });
          return {
            number: "",
            usdt: "0.00",
            fee: "0.00",
            total: 0,
          };
        }
      }

      //矫正手续费补差
      let fee2 = Float8(total - usdt);

      return {
        number: number,
        usdt: usdt,
        real_fee: fee,
        fee: fee2,
        total: total,
      };
    },

    /**
     * 半/全仓 更新
     */
    btnBuySet(num) {
      if (num == 1) {
        //全仓抵扣额
        let base = this._BuyGetBase();
        let res = this._BuyGetTotal2(base.balance);
        this._SetBuyParams(res);
        //console.log(res, '结果')
      } else if (num == 0.5) {
        //半仓抵扣额
        let base = this._BuyGetBase();
        let res = this._BuyGetTotal2(base.balance / 2);
        this._SetBuyParams(res);
        //console.log(res, '结果')
      }
    },
    /**
     * 输入数量更新
     */
    inputBuyNumber() {
      //过滤
      let number = this._BuyGetNumber();
      if (!number) {
        return;
      }

      //计算
      let res = this._BuyGetTotal(number);
      this._SetBuyParams(res);
      //console.log(res, '结果')
    },
    /**
     * 价格更新
     */
    autoBuyNumber() {
      //过滤
      let number = this._BuyGetNumber();
      if (!number) {
        return;
      }

      //计算
      let res = this._BuyGetTotal(number);
      //console.log(res, '结果')

      //校验
      let base = this._BuyGetBase();
      if (res.total > base.balance) {
        //再次计算
        res = this._BuyGetTotal2(base.balance);
        //console.log(res, '结果')
      }

      this._SetBuyParams(res);
    },
    validBuy() {
      if (!this.buy_number) {
        this.$message({
          message: this.$t("message.invalid_number"),
          type: "warning",
        });
        return false;
      }

      //检测限价价格
      // if (this.buyPriceType == 2) {
      //     let limitVal = this.currency.coins_limit_val ? this.currency.coins_limit_val : 0
      //     let price = parseFloat(this.currency.close)
      //     let currentPrice = this.currentBuyPrice

      //     let limitBuyPrice = Float8(price + limitVal)
      //     if (currentPrice <= limitBuyPrice) {
      //         this.$message({
      //             message: "价格必须高于 " + limitBuyPrice,
      //             type: "warning",
      //         });
      //         return false
      //     }
      // }
      return true;
    },
    onBuy() {
      //loading
      this.$ui.openLoading();
      console.log(this.currentWallet, "this.currentWallet");
      buyCoins({
        price_type: this.buyPriceType,
        price: this.currentBuyPrice,
        number: this.buy_number,
        c_id: this.currentWallet.c_id,
        trade_pwd: this.tradePwd,
      })
        .then((res) => {
          //close
          this.$ui.closeLoading();

          if (res.code == 1) {
            this.$message({
              message: res.msg,
              type: "error",
            });
            return;
          }

          this.$message({
            message: res.msg,
            type: "success",
          });
          console.log(res);
          //重新获取
          this.getWalletCoins();

          //通知
          Event.$emit("onCoinsTrade");
        })
        .catch(() => {
          //close
          this.$ui.closeLoading();
        });
    },

    /**--------------------------------------------------
     * 价格类型
     */
    btnSellPrice(type) {
      this.sellPriceType = type;
      // 立即更新
      this.updatePrice();
    },
    /**
     * 输入过滤
     */
    _SellGetNumber() {
      //数初始化
      let number = this.sell_number;
      if (number) {
        let number = Number(number);
      }

      //数据过滤
      if (number < 0) {
        this.sell_number = "";
        // console.log(number, '数据负值')
        return 0;
      }
      if (!number || number == 0) {
        // console.log(number, '数据无值')
        this._SetSellParams({
          number: number,
          usdt: "0.00",
        });
        return 0;
      }

      //console.log(number, '数据正常')
      number = ScToNumber(number);
      //console.log(number, '数据转换')

      return number;
    },
    /**
     * 获取基本参数
     */
    _SellGetBase() {
      //基本参数
      let price = parseFloat(this.currentSellPrice);
      let balance = parseFloat(this.currentWallet.balance);

      //console.log("当前价格：" + price)
      //console.log("可用：" + balance + " " + this.currentWallet.name)

      return {
        price: price,
        balance: balance,
      };
    },
    /**
     * 计算结果设置
     */
    _SetSellParams(res) {
      this.sell_usdt = res.usdt;
      this.sell_number = res.number;
    },
    /**
     * 已知数量
     */
    _SellGetTotal(number) {
      if (!number || number < 0) {
        return {
          number: "",
          usdt: "0.00",
        };
      }

      //基本参数
      let base = this._SellGetBase();

      //交易额
      let usdt = Float8(number * base.price);

      return {
        number: number,
        usdt: usdt,
      };
    },
    /**
     * 半/全仓
     */
    btnSellSet(num) {
      if (num == 1) {
        //全仓卖出
        let base = this._SellGetBase();
        let res = this._SellGetTotal(base.balance);
        this._SetSellParams(res);
        //console.log(res, '结果')
      } else if (num == 0.5) {
        //半仓卖出
        let base = this._SellGetBase();
        let res = this._SellGetTotal(base.balance / 2);
        this._SetSellParams(res);
        //console.log(res, '结果')
      }
    },
    /**
     * 输入数量更新
     */
    inputSellNumber() {
      //过滤
      let number = this._SellGetNumber();
      if (!number) {
        return;
      }

      //计算
      let res = this._SellGetTotal(number);
      this._SetSellParams(res);
      //console.log(res, '结果')
    },
    /**
     * 价格更新
     */
    autoSellNumber() {
      //过滤
      let number = this._SellGetNumber();
      if (!number) {
        return;
      }
      //计算
      let res = this._SellGetTotal(number);
      this._SetSellParams(res);
      //console.log(res, '结果')
    },
    validSell() {
      if (!this.sell_number) {
        this.$message({
          message: this.$t("message.invalid_number"),
          type: "warning",
        });
        return false;
      }

      //检测限价价格
      let limitVal = this.currency.coins_limit_val
        ? this.currency.coins_limit_val
        : 0;
      let price = parseFloat(this.currency.close);
      let currentPrice = parseFloat(this.currentSellPrice);

      let cha = 0;
      if (limitVal > 0) {
        limitVal = parseFloat(limitVal / 100);
        cha = Float8(price * limitVal);
      }

      console.log(limitVal, cha, price, "点差");

      if (this.sellPriceType == 3) {
        //止盈
        let limitSellPrice = Float8(price + cha);
        if (currentPrice <= limitSellPrice) {
          this.$message({
            message: this.$t("message.price_high", { price: limitSellPrice }),
            type: "warning",
          });
          return false;
        }
      } else if (this.sellPriceType == 4) {
        //止损
        let limitSellPrice = Float8(price - cha);
        if (currentPrice >= limitSellPrice) {
          this.$message({
            message: this.$t("message.price_low", { price: limitSellPrice }),
            type: "warning",
          });
          return false;
        }
      }

      return true;
    },
    onSell() {
      //loading
      this.$ui.openLoading();

      sellCoins({
        price_type: this.sellPriceType,
        price: this.currentSellPrice,
        number: this.sell_number,
        c_id: this.currentWallet.c_id,
        trade_pwd: this.tradePwd,
      })
        .then((res) => {
          //close
          this.$ui.closeLoading();

          if (res.code == 1) {
            this.$message({
              message: res.msg,
              type: "error",
            });
            return;
          }

          this.$message({
            message: res.msg,
            type: "success",
          });
          console.log(res);

          //重新获取
          this.getWalletCoins();
          //通知
          Event.$emit("onCoinsTrade");
        })
        .catch(() => {
          //close
          this.$ui.closeLoading();
        });
    },
  },
};
</script>

<style src="../../../static/css/coins.css" scoped type="text/css"></style>
