export default `<p>
    <strong>DCR（Decred）</strong>
</p>
<p>
    <br/>
</p>
<p>
    1.プロジェクト名
</p>
<p>
    宣言（DCR）
</p>
<p>
    <br/>
</p>
<p>
    2.プロジェクトのポジショニング
</p>
<p>
    Decredはビットコインに似ており、コミュニティの意見、オープンガバナンス、持続可能な資金調達と開発を強調しています。 DCRは、高度なPoW + PoSハイブリッドメカニズムを使用して、コミュニティの入力なしに小数の人がトランザクションプロセスを支配したり、Decredを変更したりできないようにします。
</p>
<p>
    <br/>
</p>
<p>
    3、通貨の概要と流通
</p>
<p>
    トークンの合計量：21,000,000
</p>
<p>
    流通量：約7,900,000（新しいブロックごとに生成される報酬の60％はPoWマイナーに属し、30％はPoS有権者に属し、10％はDecred R＆D補助金に属します）
</p>
<p>
    に
</p>
<p>
    4つの一般的に使用されるリンク
</p>
<p>
    プロジェクトのウェブサイト：https：//decred.org/
</p>
<p>
    ホワイトペーパー：https：//decred.org/dtb001.pdf
</p>
<p>
    ブロッククエリ：https：//mainnet.decred.org/
</p>
<p>
    に
</p>
<p>
    V.通貨が配置されているゾーン
</p>
<p>
    イノベーションゾーン
</p>
<p>
    <br/>
</p>`