import { render, staticRenderFns } from "./ContractInfo.vue?vue&type=template&id=f73b9b90&scoped=true&"
import script from "./ContractInfo.vue?vue&type=script&lang=js&"
export * from "./ContractInfo.vue?vue&type=script&lang=js&"
import style0 from "../../static/css/contract_info.css?vue&type=style&index=0&id=f73b9b90&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f73b9b90",
  null
  
)

export default component.exports