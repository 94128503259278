export default `<h2 style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:48px">
<strong><span style="font-family: Times New Roman;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 16px;background: rgb(255, 255, 255)">MANA (Decentraland)</span></strong>
</h2>
<p style=";text-indent: 0;padding: 0;line-height: 48px;background: rgb(255, 255, 255)">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 16px">&nbsp;</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: Song Ti;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:宋体"> 1. Project name</span></span></strong>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">Decentraland (MANA)</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: Song Ti;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:宋体"> 2. Project positioning</span></span></strong>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Based on the Ethereum blockchain, support</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">VR</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style=" font-family:Microsoft Yahei"></span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">Minecraft&nbsp;</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style= "font-family: Microsoft Yahei">and</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size : 14px">Second Life</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style ="font-family:Microsoft Yahei">-style game platform. </span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: Song Ti;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:宋体"> 3. Project brief comment</span></span></strong>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" > The project development progress is relatively complete, and a small-scale public test is about to be carried out. The recent transaction volume is relatively large, the user community is active, and there is investment potential. </span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >The biggest risk is:</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">1 .&nbsp;</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font -family: Microsoft Yahei">Recent price fluctuation risk;</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font -size: 14px">2.</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">The internal beta version is major</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter- spacing: 0;font-size: 14px">bug</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei"> may not meet user expectations. </span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: Song Ti;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:宋体"> 4. Project progress</span></span></strong>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">1.&nbsp;ICO</span><span style="font -family: Microsoft Yahei; color: rgb(35, 42, 74); letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei"> has ended. </span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">2.&nbsp;</span><span style="font- family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei">Internal beta users participate in the game Territory auction registration is open until</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">12</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">Month</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">15</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">Day. </span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">3.&nbsp;12</span><span style="font -family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei">Month</span> </span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">15</span><span style="font -family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei">The Iron Age will be released in the future Test version, and open the game's internal territory for purchase and privatization. </span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">4.&nbsp;</span><span style="font- family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei">Multi-users will be added in the Iron Age Support as well as virtual portraits and live chat functions will allow you to create custom items and scripts to run on your own territory. </span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: Song Ti;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:宋体"> 5. Project features</span></span></strong>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">1.&nbsp;</span><span style="font- family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei">The first one entirely by users Controlled virtual reality platform, a fully integrated and interactive</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0; font-size: 14px">3D</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">Virtual world. </span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">2.&nbsp;</span><span style="font- family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei">Users can go through the Ethereum zone The blockchain platform purchases land and becomes the owner of the land. </span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">3.&nbsp;</span><span style="font- family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei">The user’s Content has complete control. </span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">4.&nbsp;</span><span style="font- family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei">The platform consists of an open standard protocol Management and protection. </span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">5.&nbsp;</span><span style="font- family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei">The user’s domain ownership is used Blockchain technology records to ensure its scarcity and anti-counterfeiting. </span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">6.&nbsp;</span><span style="font- family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">Now supports Microsoft Mixed Reality Platform (</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">MR</span> <span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >). </span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: Song Ti;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:宋体"> 6. Project team</span></span></strong>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Project leader:</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">Ari Meilich </span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei">, front</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"> Benchrise&nbsp;</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font- family: Microsoft Yahei">Co-founder. </span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Founder and technical leader:</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px" >Esteban Ordano</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font -family: Microsoft Yahei">, front</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">Smart Contract Solutions&nbsp;</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei">Founder,</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0 ;font-size: 14px">BitPay&nbsp;</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px" ><span style="font-family:Microsoft Yahei">software engineer. </span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: Song Ti;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:宋体"> 7. Project consultant</span></span></strong>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Li Xiaolai:</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">INBlockchain</span ><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei ">Founder</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">Jake Brukhman</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">:</span></span ><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">Coinfund&nbsp;</span><span style="font-family : Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei">Founder</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">Luis Cuende</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">:</span></span ><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">Aragon&nbsp;</span><span style="font-family : Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei">Project leader</span> </span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">Diego Doval</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">: Front</span></span> span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">Ning</span><span style="font-family : Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei">Chief Technology Officer</span> </span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: Song Ti;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:宋体"> 8.</span></span></strong><strong><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px ">ICO</span></strong><strong><span style="font-family: Times New Roman;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:宋体">Progress</span></span></strong>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">ICO</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">Time:</span></span ><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">17</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">year</span></span ><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">8</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">Month</span></span ><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">18</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">Day</span></span >
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">ICO</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei">Progress:</span></span ><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">100%</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: Song Ti;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:宋体"> 9.</span></span></strong><strong><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px ">Token</span></strong><strong><span style="font-family: Times New Roman;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:宋体">Overview</span></span></strong>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Project tokens</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">: MANA</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">(</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">ERC20</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">)</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Total tokens:</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">28.05 </span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei ">100 million</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">ICO</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">Proportion:</span></span ><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">40%</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Community and partners:</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"> 20%</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Team and early investors:</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"> 20%</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font- family:Microsoft Yahei">(</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px" >3</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font- family: Microsoft Yahei ">year lock)</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Foundation:</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">20%</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Tokens</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">ICO</span ><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei ">Pricing:</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">MANA =0.16 RMB</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: Song Ti;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:宋体"> 10. Project financing and valuation</span></span></strong>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">ICO</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">Financing:</span></span ><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">2400</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">10,000</span></span >
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">ICO</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">Financing valuation:</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">6000</span><span style="font- family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:Microsoft Yahei">10,000</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Token circulation:</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">81 %</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: Song Ti;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:宋体"> 11. Generation and use of tokens</span></span></strong>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Token generation: smart contract</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Token inflation: annual inflation</span></span><span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"> 8%</span><span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font- family:Microsoft Yahei">The inflation rate is decreasing every year</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Token function: used to purchase territory and other virtual products and services within the game; tokens after purchasing the territory will be destroyed. </span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: Song Ti;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:宋体"> 12. Common links</span></span></strong>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Official website:</span></span><a href="https://decentraland.org/"><span style="font-family: Arial;color: rgb(53, 124, 225);letter- spacing: 0;font-size: 14px">https://decentraland.org/</span></a>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >White paper:</span></span><a href="https://decentraland.org/whitepaper.pdf"><span style="font-family: Arial;color: rgb(53, 124, 225) ;letter-spacing: 0;font-size: 14px">https://decentraland.org/whitepaper.pdf</span></a>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Arial;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: Song Ti;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:宋体"> 13. The zone where the currency is located</span></span></strong>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Innovation Zone</span></span>
</p>
<p>
<span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
<p>
<br/>
</p>`