export default {
    pt1:"1.用户登录STARTHESTAR后，点击“资产”后在点“我的钱包“”进入我的资产界面；",
    pimg1:"k4",
    pt2:"2.在我的资产界面，点击“存币”，",
    pimg2:"k4",
    pt3:"3.用户选择你将存币的币种，例如用户将存币BTC，就选择BTC币种，存币其他币种就选择对应的币种，然后点击“复制”或者二维码扫描",
    pimg3:"k4",
    pt4:"4.用户将复制的钱包地址粘贴或者二维码扫描到需要提币的平台。完成提币流程，等待链上处理成功，即存币到STARTHESTAR平台。（在我的资产界面查看到账情况）",
    pt5:"STARTHESTAR温馨提示您：",
    pt6:"①存币时注意币种对应的钱包地址，请勿填错，否则资产将无法找回。",
    pt7:"②存币USDT时，注意USDT对应的区块（ERC20、TRC20、OMNI），请勿填错，否则资产将无法找回。",
}