export default `<h2 style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:48px">
<strong><span style="font-family: Times New Roman;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 16px;background: rgb(255, 255, 255)">TRB (Tellor Tributes)</span></strong>
</h2>
<p style=";text-indent: 0;padding: 0;line-height: 48px;background: rgb(255, 255, 255)">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >1. Project name</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">Tellor Tributes (TRB)</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Two, project positioning</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">Tellor is a decentralized Oracle oracle network platform, Used to integrate high-value off-chain data into Ethereum. The system uses controversial miner network competition to resolve PoW issues, monitors user data requests on the chain, and monitors and receives data requests off the chain. Each successful Tellor data point will be mined using tokens, and the company will take a 10% development share to support the development of the ecosystem. </span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Three, currency overview and distribution</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Total Tokens:</span>1,505,461 TRB</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Circulation:</span>1,416,589 TRB</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp; &nbsp; &nbsp;&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Four, frequently used links</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Project website:</span>&nbsp;</span><a href="http://www.tellor.io/"><span style="font-family: Microsoft Yahei; color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">http://www.tellor.io</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Whitepaper:</span></span><a href="http://www.tellor.io/whitepaper"><span style="font-family: Microsoft Yahei; color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">http://www.tellor.io/whitepaper</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Block query</span>:</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<a href="https://explore.duneanalytics.com/public/dashboards/pVN6lRN8xxtfhjnEbcPbqreI8DNkPOng5P5fol0v"><span style="font-family: Microsoft Yahei;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">https://explore.duneanalytics.com/public/dashboards/pVN6lRN8xxtfhjnEbcPbqreI8DNkPOng5P5fol0v</span></a>
</p>
<p>
<span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
<p>
<br/>
</p>`