export default {
    title:"OTC trading rules",
    content:`<p>
    <strong>OTC trading rules</strong>
</p>
<p>
    <br/>
</p>
<p>
    OTC trading rules
</p>
<p>
    <br/>
</p>
<p>
    Basic principles of OTC trading:
</p>
<p>
    1. These rules will be officially implemented from the date of publication, and will be revised or improved from time to time. The revised or improved new rules will take effect from the date of publication;
</p>
<p>
    2. If you do not agree with these rules and the revised or improved rules, please stop using the platform services immediately;
</p>
<p>
    3. If you continue to use the platform services, it is deemed that you fully understand and accept these rules and the content of the rules revised or improved from time to time, and are voluntarily bound by them.
</p>
<p>
    <br/>
</p>
<p>
    Principles that buyers should follow:
</p>
<p>
    After creating an order, the buyer should:
</p>
<p>
    1. Complete the payment within the payment time specified in the order, and then click &quot;I have paid&quot;;
</p>
<p>
    2. Use my real-name payment method, the order amount and the collection account designated by the order to complete the order payment in real time;
</p>
<p>
    3. If you choose to pay the order in a non-real-time payment method, you must obtain the seller&#39;s consent in advance;
</p>
<p>
    <br/>
</p>
<p>
    The principles that the seller should follow:
</p>
<p>
    After generating the order, the seller should:
</p>
<p>
    1. Provide a collection account certified by the platform;
</p>
<p>
    2. After receiving the full payment from the buyer, complete the release within the time specified in the order;
</p>
<p>
    3. Without the consent of the buyer, the buyer/customer service shall not be required to cancel the order;
</p>
<p>
    4. No objection to the created order price shall be made without the consent of the buyer;
</p>
<p>
    <br/>
</p>
<p>
    Note: Non-real-time arrival methods currently include: Paypal to bank card, Visa to bank card, single transfer for over 50,000 orders from 17:00-9:00, etc. With the changes in the business of each payment channel, non-real-time arrival The specific type of account method will be adjusted accordingly.
</p>
<p>
    <br/>
</p>
<p>
    Users should abide by the anti-money laundering principles:
</p>
<p>
    <br/>
</p>
<p>
    1. Users should abide by the platform&#39;s &quot;StarthestarOTC Platform Anti-Money Laundering and Anti-Terrorist Financing User Guidelines&quot; and strictly perform relevant obligations;
</p>
<p>
    <br/>
</p>
<p>
    2. Users should strictly follow the platform&#39;s real-name authentication management requirements for user identities and accounts, and ensure that the relevant accounts displayed on the platform are all legally registered and used by them;
</p>
<p>
    <br/>
</p>
<p>
    3. When users trigger the platform&#39;s anti-money laundering risk control warning, they should actively cooperate with the platform&#39;s further anti-money laundering verification work, and actively assist in providing relevant risk control evidence materials (specific measures are subject to the platform&#39;s risk control audit requirements at that time).
</p>
<p>
    <br/>
</p>
<p>
    Buyer exception
</p>
<p>
    <br/>
</p>
<p>
    Treatment measures
</p>
<p>
    <br/>
</p>
<p>
    1. After the order is created, the buyer voluntarily cancels the order, or the system automatically cancels the order due to payment timeout.
</p>
<p>
    <br/>
</p>
<p>
    If it reaches 4 times on the day, the system will restrict the buyer&#39;s buying function; if it is triggered for 2 consecutive days, the system will further restrict some functions of the buyer&#39;s account.
</p>
<p>
    <br/>
</p>
<p>
    2. If the payment has not been made, click &quot;I have paid&quot;.
</p>
<p>
    <br/>
</p>
<p>
    The seller has the right to request to cancel the order. If the buyer has been complained twice on the same day, the system will restrict some functions of the buyer&#39;s account; if the single amount of such complaints exceeds 50,000 funds, the system will directly restrict the buyer&#39;s account for 48 hours.
</p>
<p>
    <br/>
</p>
<p>
    3. If the seller does not violate the rules and the buyer fails to pay, click &quot;Appeal&quot; for no reason.
</p>
<p>
    <br/>
</p>
<p>
    The seller has the right to request to cancel the order, if the buyer is complained twice on the same day, the system will restrict some functions of the buyer&#39;s account;
</p>
<p>
    <br/>
</p>
<p>
    4. The payment is not made according to the receiving account displayed on the seller&#39;s order details page.
</p>
<p>
    <br/>
</p>
<p>
    The seller has the right to request to cancel the order and return the digital assets to the seller, and the buyer bears the risk of transferring the wrong payment.
</p>
<p>
    <br/>
</p>
<p>
    5. If the payment is not made, please click &quot;I have paid&quot; first, and then make the payment.
</p>
<p>
    <br/>
</p>
<p>
    The seller has the right to choose to continue the transaction or cancel the order after a refund.
</p>
<p>
    1) If the seller chooses to make a deal, the seller user needs to complete the release within 10 minutes after receiving the full payment;
</p>
<p>
    2) If the seller chooses to cancel the order, the seller should complete the refund within 2 hours after the order is appealed, and the digital assets will be returned to the seller.
</p>
<p>
    <br/>
</p>
<p>
    6. Not using the payment account that matches the real name of the platform account to pay.
</p>
<p>
    <br/>
</p>
<p>
    The seller has the right to directly refuse the transaction. The seller can complete the refund process within 48 hours after receiving the payment, and the digital assets will be returned to the seller. Payments made using a corporate account (regardless of whether the legal person and the real name of the certified person are the same or not) are still non-real-name payments. If the buyer has been complained for 2 times due to this situation, the system will limit some functions of the buyer&#39;s account.
</p>
<p>
    <br/>
</p>
<p>
    7. Pay within the payment time specified in the order.
</p>
<p>
    (1) The buyer has completed all payments, and the order has been cancelled by the buyer voluntarily or by the system overtime;
</p>
<p>
    (2) The buyer completes the partial payment, but the order is cancelled by the buyer voluntarily or by the system overtime.
</p>
<p>
    <br/>
</p>
<p>
    If the order is voluntarily cancelled by the buyer and the order is cancelled by the system overtime, the seller has the right to choose to continue the transaction or refund the transaction.
</p>
<p>
    <br/>
</p>
<p>
    1. If the seller chooses to make a deal,
</p>
<p>
    - The seller is an ordinary user, and the order is directly re-opened by the platform. The seller needs to complete the asset release within 10 minutes after the re-opening.
</p>
<p>
    - The seller is an advertiser user, and the order amount is ≤50,000. Please use &quot;Reopen the order and release&quot; to complete the asset release operation; if the order amount is greater than 50,000, please complete the asset release within 10 minutes after the order is reopened.
</p>
<p>
    <br/>
</p>
<p>
    2. If the seller chooses to cancel the order, the seller must complete the refund within 60 minutes after receiving the feedback from the platform or the buyer&#39;s user, and return the digital assets to the seller;
</p>
<p>
    <br/>
</p>
<p>
    8. If multiple payments are required, if all transfers have not been completed, click &quot;I have paid&quot; first, and then continue to complete the payment.
</p>
<p>
    <br/>
</p>
<p>
    1) Communicate with the seller in advance, if the seller allows its behavior, the seller should continue to complete the order;
</p>
<p>
    2) Without the consent of the seller, the seller has the right to choose to continue the transaction or cancel the order after a refund.
</p>
<p>
    - If the seller chooses to proceed with the transaction, the seller should complete the release within 10 minutes of receiving all payments.
</p>
<p>
    - If the seller chooses to cancel the order, the seller must complete the refund within 30 minutes after receiving the payment, and the digital assets will be returned to the seller.
</p>
<p>
    <br/>
</p>
<p>
    9. The payment amount does not match the order amount.
</p>
<p>
    <br/>
</p>
<p>
    1) If the payment amount is greater than the order amount, and the order is completed, the seller should refund the excess amount within 30 minutes after receiving the payment.
</p>
<p>
    2) If the payment amount is less than the order amount, the seller has the right to choose to continue the transaction or request to cancel the order after a refund.
</p>
<p>
    - If the seller chooses to continue the transaction, he has the right to complete the transaction according to the actual order amount and complete the order.
</p>
<p>
    - If the seller chooses to cancel the order, the seller must complete the refund within 30 minutes after receiving the payment, and the digital assets will be returned to the seller.
</p>
<p>
    <br/>
</p>
<p>
    10. After the buyer clicks &quot;I have paid&quot;, the seller has not received the payment within 1 hour.
</p>
<p>
    <br/>
</p>
<p>
    1) The seller has the right to choose to continue the transaction or cancel the order after a refund.
</p>
<p>
    - If the seller chooses to make a deal, the seller needs to complete the release within 10 minutes of receiving the payment.
</p>
<p>
    - If the seller chooses to cancel the order, the seller must complete the refund within 30 minutes after receiving the payment, and the digital assets will be returned to the seller.
</p>
<p>
    <br/>
</p>
<p>
    2) The seller is the advertiser, and the buyer cannot be contacted within 10 minutes after the appeal. After verifying that the seller has not received the payment, the ombudsman will give priority to cancel the order within the scope of the advertiser&#39;s guaranteed assets.
</p>
<p>
    <br/>
</p>
<p>
    11. After the payment is received, the seller&#39;s receiving account cannot verify the real-name information on the account.
</p>
<p>
    <br/>
</p>
<p>
    1) After verifying that the buyer&#39;s payment account is consistent with the platform&#39;s real-name account, and the payment is completed within the time specified in the order, the order is normally completed.
</p>
<p>
    <br/>
</p>
<p>
    2) After verifying that the buyer&#39;s payment account is inconsistent with the platform&#39;s real-name account, it will be handled in accordance with the above-mentioned measures in Article 6.
</p>
<p>
    <br/>
</p>
<p>
    12. If the seller&#39;s collection account is abnormal after the buyer pays.
</p>
<p>
    <br/>
</p>
<p>
    1) If the buyer cannot provide proof of the normal account, the seller should complete the refund within 24 hours after the receiving account is transferred to normal, and the platform cancels the order.
</p>
<p>
    2) The buyer can provide proof that the account is normal. After verifying that the buyer&#39;s account is normal, the order is completed and the digital assets should be delivered to the buyer.
</p>
<p>
    <br/>
</p>
<p>
    For example: the seller is a V-plus user, within the scope of guaranteed assets, the digital assets are returned to the seller, and the seller should complete the refund within 24 hours after the receiving account is transferred to normal.
</p>
<p>
    <br/>
</p>
<p>
    13. Remarks when paying: similar to &quot;digital currency, BTC, Bitcoin, Starthestar&quot; and other words.
</p>
<p>
    <br/>
</p>
<p>
    The seller has the right to choose to continue the transaction or refund the transaction.
</p>
<p>
    1) If the seller chooses to make a deal, the seller should complete the release within 10 minutes after receiving the payment.
</p>
<p>
    2) If the seller chooses to refund and does not complete the transaction, the order will be cancelled, the digital assets will be returned to the seller, and the seller will complete the refund within 60 minutes after receiving the payment; if the seller&#39;s receiving account is therefore frozen, it will be dealt with according to the measures in Article 12 above.
</p>
<p>
    <br/>
</p>
<p>
    14. The buyer user is judged by the risk control system to maliciously rush to auction a low-priced order
</p>
<p>
    <br/>
</p>
<p>
    1) If the buyer fails to pay, the platform will cancel the order and return the assets to the seller.
</p>
<p>
    <br/>
</p>
<p>
    2) The buyer has paid, please complete the refund process within 10 minutes of the appeal processing, and the platform will assist in canceling the order and returning the assets to the seller.
</p>
<p>
    <br/>
</p>
<p>
    If the buyer acts like this twice, the risk control system will limit some functions of the buyer&#39;s account.
</p>
<p>
    <br/>
</p>
<p>
    Note: This rule involves the seller&#39;s refund or release, and if the seller fails to complete the operation on time, the system will restrict some functions of the seller&#39;s account.
</p>
<p>
    <br/>
</p>
<p>
    &nbsp; &nbsp;Note: In the process of using the three-party telephone system for OTC transactions, if you actively provoke language conflicts, abuse, ridicule or other excessive language behavior, the system will limit some functions of your account.
</p>
<p>
    <br/>
</p>
<p>
    Seller exception
</p>
<p>
    <br/>
</p>
<p>
    Treatment measures
</p>
<p>
    <br/>
</p>
<p>
    1. The seller is fully responsible for the receiving account displayed on the order details page and the receiving account sent in the order chat window;
</p>
<p>
    <br/>
</p>
<p>
    The collection account provided by the seller is not owned by me, or is guided by others to bind the collection account of others, or send the account of others to the chat window. After the buyer completes the payment, the asset determination will belong to the buyer.
</p>
<p>
    <br/>
</p>
<p>
    2. After the order is generated, there is an objection to the order price.
</p>
<p>
    <br/>
</p>
<p>
    Unless the order price is negotiated with the agreement of the buyer, the order is normally executed, and the seller shall release it within the time specified in the order.
</p>
<p>
    <br/>
</p>
<p>
    3. The collection account provided by the seller is abnormal.
</p>
<p>
    <br/>
</p>
<p>
    The buyer has the right to choose to continue the transaction or cancel the order.
</p>
<p>
    - If the buyer chooses to make a deal and confirms that the buyer has paid in full within the time specified in the order, the seller should complete the release within the time specified in the order after receiving the payment.
</p>
<p>
    - If the buyer chooses to cancel the order, the digital asset will be returned to the seller after the seller completes the refund.
</p>
<p>
    <br/>
</p>
<p>
    4. Because the seller did not provide a valid payment method in time, the buyer could not complete the payment, resulting in the cancellation of the order.
</p>
<p>
    <br/>
</p>
<p>
    Since the seller does not provide a payment method, the order cannot be completed. When the platform handles the appeal, it will directly cancel the order and impose penalties on the seller&#39;s user. The cancellation of the order by the platform does not count the number of cancellations by the buyer on the day;
</p>
<p>
    <br/>
</p>
<p>
    The seller has been complained for 2 times, and the system will limit some functions of the seller&#39;s account.
</p>
<p>
    <br/>
</p>
<p>
    5. The seller (non-advertiser) does not bind valid payment information consistent with his real name or provide the buyer with a payment method inconsistent with the platform&#39;s real name,
</p>
<p>
    <br/>
</p>
<p>
    For example, when receiving payment through Alipay or WeChat, the account number and the QR code payee name do not match the payee name displayed on the order details page.
</p>
<p>
    <br/>
</p>
<p>
    1) The buyer has the right to cancel the order and complain to the platform.
</p>
<p>
    2) The seller has been complained for 3 times, and the system will permanently limit the seller&#39;s account.
</p>
<p>
    The buyer chooses to continue the transaction, so the system will limit some functions of the buyer&#39;s account if the complaint reaches 2 times.
</p>
<p>
    <br/>
</p>
<p>
    6. The seller collects the payment from the buyer&#39;s account whose name is not the same on the platform, and does not refuse the transaction.
</p>
<p>
    <br/>
</p>
<p>
    1) The risk arising therefrom shall be borne by the seller.
</p>
<p>
    2) If there is a complaint due to this, the system will limit some functions of the seller&#39;s account.
</p>
<p>
    <br/>
</p>
<p>
    7. After the buyer pays in full, mark &quot;I have paid&quot;. After the seller receives the full payment from the buyer, the release is not completed within 20 minutes.
</p>
<p>
    <br/>
</p>
<p>
    The buyer has the right to choose to continue the transaction or cancel the order and request a refund.
</p>
<p>
    - If the buyer chooses to close, the Ombudsman will determine that the asset belongs to the buyer.
</p>
<p>
    - If the buyer cancels the order after requesting a refund, the seller should complete the refund within 30 minutes.
</p>
<p>
    <br/>
</p>
<p>
    For example: the buyer clicks the appeal within 20 minutes of marking the payment, resulting in the seller unable to release, and the seller cannot contact the buyer in the chat window, the order will be completed normally.
</p>
<p>
    <br/>
</p>
<p>
    &nbsp; &nbsp;Note: In the process of using the three-party telephone system for OTC transactions, if you actively provoke language conflicts, abuse, ridicule or other excessive language behavior, the system will limit some functions of your account.
</p>
<p>
    <br/>
</p>
<p>
    Trigger risk control exception
</p>
<p>
    <br/>
</p>
<p>
    Treatment measures
</p>
<p>
    <br/>
</p>
<p>
    1. When the user is detected abnormal by the risk control system, the user explicitly refuses or does not actively cooperate to complete the further anti-money laundering verification required by the platform&#39;s risk control.
</p>
<p>
    <br/>
</p>
<p>
    The platform will have the right to choose to directly identify this account as abnormal, and take measures including but not limited to raising the user&#39;s risk level, directly freezing such users&#39; accounts and associated accounts until the risk control evidence provided by the user&#39;s cooperation has passed the platform&#39;s risk management. Control audit.
</p>
<p>
    <br/>
</p>
<p>
    2. When the user is verified to buy coins from StarthestarOTC to other platforms/over-the-counter groups to sell for profit and other high-risk behaviors.
</p>
<p>
    <br/>
</p>
<p>
    The platform will have the right to choose to take measures including but not limited to delaying withdrawals, restricting transactions, freezing accounts, etc., depending on the situation;
</p>
<p>
    <br/>
</p>
<p>
    Note: Once such behavior is discovered, if there is an order in progress, the advertiser has the right to choose not to complete the transaction.
</p>
<p>
    <br/>
</p>
<p>
    3. The account is not used by the user or on behalf of others: when the user is verified by the platform risk control that the account is sold, lent, leased for others to use, or there is a behavior of buying and selling coins on behalf of others.
</p>
<p>
    Risk reminder: Please pay attention to your own information security and avoid being used by others to commit crimes.
</p>
<p>
    <br/>
</p>
<p>
    The platform will have the right to choose one or more measures including but not limited to temporarily or permanently restricting OTC transactions, delaying withdrawals, restricting withdrawals, or directly freezing the accounts and associated accounts of such users.
</p>
<p>
    <br/>
</p>
<p>
    Note: Once such behavior is discovered, if there is an order in progress, the advertiser has the right to choose not to complete the transaction.
</p>
<p>
    <br/>
</p>
<p>
    4. Using the test card of other users of the platform: when the user makes a small payment to other users of the platform without the order, and the behavior cannot be reasonably explained.
</p>
<p>
    <br/>
</p>
<p>
    The platform will have the right to choose one or more measures such as temporarily or permanently restricting OTC transactions, restricting currency withdrawals or freezing accounts.
</p>
<p>
    <br/>
</p>
<p>
    5. Receive or introduce digital assets or funds from illegal sources: When the user knows or judges with reasonable common sense that the coins or funds received on other platforms/WeChat groups are black coins or funds are black money, but still ignore the risk and directly transfer the The black coins/money flows into the accounts of other users of this platform.
</p>
<p>
    <br/>
</p>
<p>
    This platform will directly and permanently restrict all functions of such users&#39; accounts and associated accounts.
</p>
<p>
    <br/>
</p>
<p>
    6. Users identified as money laundering: When the user is detected by the risk control system or verified by the risk control staff, the user directly participates in or assists in criminal acts such as money laundering.
</p>
<p>
    <br/>
</p>
<p>
    This platform will directly and permanently restrict all functions of such users&#39; accounts and associated accounts.
</p>
<p>
    <br/>
</p>
<p>
    7. Judicial freezing: When the user account is requested to be frozen by the judicial authority.
</p>
<p>
    <br/>
</p>
<p>
    This platform will freeze the user account in strict accordance with the judicial letter produced by the judicial authority. When the freezing period expires, the judiciary must present a freezing renewal letter to continue the freezing. During the freezing period, the user&#39;s application to this platform for unfreezing is invalid, please contact the freezing agency for coordination.
</p>
<p>
    <br/>
</p>
<p>
    8. Judicial investigation: The user is required by the judicial authority to obtain the account information of this platform, including but not limited to: judicial investigation, consultation and other forms.
</p>
<p>
    <br/>
</p>
<p>
    This platform will restrict some functions of such users&#39; accounts and associated accounts as appropriate.
</p>
<p>
    <br/>
</p>
<p>
    Customer service email starthestar@starthestar.com
</p>
<p>
    <br/>
</p>`,
}