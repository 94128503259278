//zh.js
module.exports = {
  base: {
    title: 'STARTHESTAR',
    desc1: '合约之王',
    desc2: '全球合约交易领跑者',
    d1: '信任',
    d2: '诚信',
    d3: '实力',

    more: '查看更多',
    top: '回到顶部',
    download: '扫码下载APP',
    zszh: '真实账户',
    mnzh: '模拟账户',
    content: '与合约交易领域的全球市场领导者进行交易'
  },
  demo: {
    title: '注册成功！',
    title2: '模拟账户',
    submit: '确认'
  },
  lang: {
    title: '简体中文'
  },
  message: {
    invalid_email: '无效的邮箱',
    invalid_password: '无效的密码',
    invalid_new_password: '无效的新密码',
    invalid_res_password: '无效的确认密码',
    invalid_email_code: '无效的邮箱验证码',
    password_inconsistent: '密码不一致',
    invalid_invitation_code: '无效的邀请码',
    should_agree: '请阅读并同意注册条款',
    not_null: '该字段不能为空',
    send_email: '请{:s}秒后再操作',
    invalid_number: '无效的数值',
    invalid_balance: '余额不足',

    invalid_rate: '请选择倍数',
    invalid_rule: '请选择规则',
    invalid_system_rule: '系统暂无规则',

    not_enough: '可用量不足',
    price_high: '价格必须高于{price}',
    price_low: '价格必须低于{price}',

    number_high: '提币最高数量为{num}',
    number_low: '提币最低数量为{num}',
    copy_address: '复制地址成功 {text}',

    invalid_upload_front: '请上传证件正面照片',
    invalid_upload_back: '请上传证件反面照片',
    invalid_upload_hand: '请上传手持证件照',
    invalid_upload_name: '请输入证件姓名',
    invalid_upload_card: '请输入证件号',
    upload_pic_type: '上传图片只能是JPG,PNG格式',
    upload_pic_size: '上传图片大小不能超过10MB'
  },
  index: {
    title2: '全球行情自由交易24小时不停歇',
    desc2: '在流动性极佳的市场中施展您的交易策略,全平台24小时交易额',
    desc2_1: '',
    list: {
      name: '市场',
      h24_price: '24H最新价',
      h24_change: '24H涨跌幅',
      h24_high: '24H最高价',
      h24_low: '24H最低价',
      h24_vol: '24H成交量'
    },
    footer: {
      desc: `STARTHESTAR Blockchain Technology Ltd，总部位于美国，是一家全球领先的区块链技术公司，公司获得了美国MSB国际金融牌照，是一家合规的区块链技术服务公司，可在全球范围内合规的运营区块链业务服务。同时，STARTHESTAR Blockchain Technology Ltd技术团队人才均来自国际顶级科研机构和区块链技术开发团队，全力保障用户资金和账户安全，除技术团队外，公司旗下还拥有专业化的区块链团队：数字资产AI量化投资团队、区块链项目价值评估团队、数字资产风险投资团队等，布局全球，为全球区块链用户服务。
            公司目标愿景是为全球数字资产爱好者提供安全稳定、服务贴心、值得信赖的交易服务，打造一个专业化、数据化、国际化的全球知名区块链技术公司。`,

      item1: {
        tt: '产品展示',
        pt1: 'OTC交易规则',
        pt2: '新手指南',
        pt3: '费率标准',
        pt4: '合约说明'
      },
      item2: {
        tt: '支持',
        pt1: 'KYC和反洗钱协议',
        pt2: '执法请求政策',
        pt3: '数字资产介绍'
      },
      item3: {
        tt: '条款',
        pt1: '隐私保护',
        pt2: '用户协议',
        pt3: '风险告知'
      },
      item4: {
        tt: '联系我们',

        pt1: '商务合作',
        pt2: '机构合作',
        pt3: 'OTC申请',
        pt4: '客服邮箱',
        pt5: '投诉与建议',
        pt6: '媒体合作'
      }
    },
    bk3: {
      title: '引以为傲的创新研发实力',
      desc: '超乎想象的合约交易体验，专为金融操盘手打造',

      pt1: '开单止盈止损',
      pd1: '挂单不熬夜，省心不盯盘',

      pt2: '反向开单',
      pd2: '行情突变，一秒跟上',

      pt3: '支持全仓逐仓',
      pd3: '随心所欲，想怎么做就怎么做',

      pt4: '全仓阶梯强平',
      pd4: '强平先平一半，尽最大可能帮助投资者减少损失'
    },
    bk5: {
      title: '合约交易平台八大优势',
      desc: '我们时刻倾听，不断优化改进，为全球交易用户打造一流的交易体验',

      pt1: '市场深度',
      pd1: '平台前三个档位盘口深度已达百万美金量级，远超业内平均万、十万量级市场深度水准。点差小，成交快。',

      pt2: '交易流畅',
      pd2: '采用高效撮合交易引擎确保流畅交易体验，每秒可处理百万级订单，可支持同时在线用户数量超2000万的流畅稳定运行。',

      pt3: '价格公允',
      pd3: '“标记价格”选取全球三大交易所实时成交价作为指数价格进行回归计算得出，有效的防止最新成交价短时间内被异常操控造成的用户损失。',

      pt4: '自研技术',
      pd4: '扎根传统金融的撮合引擎，金融行业资深专家团队开发完成，精雕细琢每一个细节，保障交易平台的安全、稳定、高效运行。',

      pt5: '顶级风控',
      pd5: '秒级判断，多层加密，系统高度安全。冷热钱包相结合，军工级加密措施，资产交易安全有保障，100%保证用户数字资产的安全。',

      pt6: '资金安全',
      pd6: '三层安全系统，全面安全审计。自建业内最领先的分层确定性冷钱包体系，所有用户分配的充值地址皆为冷钱包地址，资金归集和提现通过离线签名完成。',

      pt7: '硬核实力',
      pd7: '数万BTC储备，金融创新基因，世界顶级管理团队。STARTHESTAR承若不会因为系统原因导致账户和交易数据错误，万一错误平台全额赔付！',

      pt8: '品质服务',
      pd8: '7*24h全天候专业客服，快速响应。以服务的思维出发，为全球客户提供一流的交易体验。极致体验源于服务客户的理念。'
    },
    bk6: {
      title: '安全合规全球化运营',
      desc: '拥抱监管始终坚持全球范围内合规化运营'
    },
    bk7: {
      title: '官方战略合作伙伴'
    },
    bk8: {
      title: '已服务超过100,000名全球交易用户',
      desc: '目前用户已遍布美国、日本、韩国、塞舌尔、越南等国家'
    },
    bk9: {
      title: '随时随地流畅交易',

      pt1: '实时撮合交易，充提秒速到账',
      pt2: '多种交易模式，最高125倍杠杆',

      dt1: 'iOS版本下载',
      dt2: 'Android版本下载'
    },
    about: {
      title: '关于我们',

      pt1: '平台理念',
      pd1: '让全球资产自由流通，打造合约新体验。X-xenogenetic，代表创新基因，为行业的创新提供源源不断的动力。M-motor，代表目标，以引领合约交易体验为目标不断向前发展。EX-exchange，代表交易平台，以服务、倾听、思进的运营理念，致力于打造公平、透明、高效的交易生态。',

      pt2: '核心价值观',
      pd2: '我们时刻倾听、关注、思进，致力于打造公平、透明、高效的交易生态。为全球交易者提供更优的交易体验 ，满足交易者实际所需，推动数字资产交易市场的发展。',

      pt3: '团队背景',
      pd3: '创始团队由顶级区块链投资人、传统金融领域高管以及顶级数字资产领域专家与传统金工技术团队组成组成，研发团队核心成员来自摩根士丹利、中金所、恒生、腾讯、诺亚财富。'
    }
  },
  start: {
    p_title: '交易，从这里开始',
    p_desc: '请在以下区域输入您的邮箱，即可立即注册',
    p_email: '邮箱',
    p_btn: '创建账户'
  },
  download: {
    p_title: '下载APP客户端',
    p_desc: '随时随地，多平台终端交易',
    p_btn: '下载客户端',
    p_scan_android: 'Android app',
    p_scan_ios: 'IOS app'
  },
  downpage: {
    t1: {
      p_title: '闪电交易性能',
      p_desc: '提供多样交易类型选择，订单极速撮合，一键闪兑，买卖更便捷'
    },
    t2: {
      p_title: '资产轻松划转',
      p_desc: '轻松划转账户内资产，实时的数字资产概览，增加币种占比饼图'
    },
    t3: {
      p_title: '自定义交易面板',
      p_desc: '根据不同交易习惯自由定义交易布局，增加K线与深度图入口'
    },
    t4: {
      p_title: '账户集成管理',
      p_desc: '多重安全验证设置，一个账户管理C2C账户与币币账户'
    }
  },
  login: {
    login: '登录',
    title: '用户登录',
    desc1: '欢迎登录',
    desc2: '官方网站',
    email: '邮箱',
    email2: '邮箱',
    email3: '电话号码',
    email_desc: '请输入邮箱',
    phone_desc: '请输入手机号',
    password: '密码',
    password_desc: '请输入密码',
    forgot_password: '忘记密码',
    no_account: '还没账号'
  },
  signup: {
    signup: '注册',
    title: '用户注册',
    desc1: '欢迎登录',
    desc2: '官方网站',
    invitation_code: '邀请码',
    invitation_desc: '请输入邀请码',
    accept: '接受',
    service: '用户协议',
    and: '及',
    policy: '隐私保护政策',
    have_account: '已有账号'
  },
  retrieve: {
    ptitle: '找回密码',
    pemail_code: '邮箱验证码',
    pset: '设置密码',
    set_title: '设置密码',
    set_desc: '您正在重新设置密码，请完成以下设置',
    new_password: '新密码',
    password_desc: '请输入新密码',
    res_passowrd: '确认密码',
    res_passowrd_desc: '请输入确认密码'
  },
  valid_email: {
    title: '注册验证',
    desc: '您正在注册账户，请完成以下验证',
    email_code: '邮箱验证码',
    email_desc: '请输入验证码',
    get_code: '获取验证码'
  },
  ihead: {
    begin_trade: '开始交易',
    helper: '新手帮助',
    text: '文档中心',
    new_currency: '新币认购'
  },
  uhead: {
    title1: '大宗商品',
    title2: '国际外汇',
    title3: '国际指数',
    currency: '数字货币',
    lever: '杠杆交易',
    coins: '币币交易',
    contract: '合约交易',
    metal: '国际贵金属',
    energy: '国际能源',
    contract_info: '合约信息',
    helper: '新手帮助',
    text: '文档中心',
    assets: '资产',
    u_wallet: '我的钱包',
    u_contract: '合约账户',
    u_lever: '杠杆账户',
    u_account: '账户信息',
    u_logout: '退出登录'
  },
  news: {
    label: '新闻',
    update: '更新于'
  },
  guide: {
    label: '新手指南',
    list: [
      {
        ptitle: '如何注册STARTHESTAR',
        pkey: 'k1'
      },
      {
        ptitle: '身份认证',
        pkey: 'k2'
      },
      {
        ptitle: '登录密码和交易密码设置',
        pkey: 'k3'
      },
      {
        ptitle: '存币',
        pkey: 'k4'
      },
      {
        ptitle: '币币交易流程',
        pkey: 'k5'
      },
      {
        ptitle: '提币',
        pkey: 'k6'
      },
      {
        ptitle: '手机APP下载',
        pkey: 'k7'
      }
    ],
    content: {
      k1: require('./currency/zh/guide/k1.js').default,
      k2: require('./currency/zh/guide/k2.js').default,
      k3: require('./currency/zh/guide/k3.js').default,
      k4: require('./currency/zh/guide/k4.js').default,
      k5: require('./currency/zh/guide/k5.js').default,
      k6: require('./currency/zh/guide/k6.js').default,
      k7: require('./currency/zh/guide/k7.js').default
    }
  },
  kline: {
    h24_high: '24H最高',
    h24_low: '24H最低',
    h24_vol: '24H成交量',
    commission: '当前委托',
    record: '成交记录',
    commission_list: '委托列表',
    deal_list: '最新成交',
    price: '价格',
    oprice: '减仓均价',
    xprice: '盈利价差',
    bzj: '保证金',
    n_price: '最新价格',
    n_zt: '涨停',
    number: '手',
    total: '总量',
    time: '时间',
    currency: '品种',
    type: '类型',
    direction: '方向',
    multiple: '倍数',
    fee: '手续费',
    amount: '总额',
    status: '状态',
    operate: '操作',
    buy: '买',
    sell: '卖',
    pending: '挂单中',
    trade_success: '交易成功',
    retract: '撤回',
    profit: '止盈',
    loss: '止损',

    all: '全部',
    all_currency: '全部币种',
    new_price: '最新价',
    change: '涨跌幅',
    protocol: '协议',
    _status1: '审核中',
    _status2: '提现成功',
    _status3: '已驳回'
  },
  coins: {
    title: '币币交易',
    market: '市价',
    limit: '限价',
    profit: '止盈',
    loss: '止损',
    price: '价格',
    current: '当前',
    price_desc: '请输入价格',
    number: '数量',
    number_desc: '请输入数量',
    available: '可用',
    half: '半仓',
    all: '全仓',
    fee: '手续费',
    amount: '交易额',
    buy: '买入',
    sell: '卖出',
    current_price: '当前价',
    open_price: '开盘价',
    high: '最高',
    low: '最低',
    yesterday: '昨日收盘价',
    today_zf: '当日涨幅',
    now_cc: '持仓',
    y_cc: '昨日持仓',
    jsj: '结算价',
    zrjsj: '昨日结算价'
  },
  lever: {
    title: '杠杆交易',
    multiple: '倍数',
    multiple_desc: '请选择倍数',
    double: '倍',
    buy: '买入(涨)',
    sell: '卖出(跌)'
  },
  contract: {
    title: '合约交易',
    time: '时间',
    time_desc: '请选择时间',
    minute: '分钟',
    second: '秒',
    buy: '买入(涨)',
    sell: '卖出(跌)',
    rate: '权利金比例'
  },
  wallet: {
    title: '我的资产',
    valuation: '总资产估值',
    my_wallet: '我的钱包',
    my_trade: '交易账户',
    my_transfer: '提现记录',
    my_deposit: '存币记录',
    cbdz: '存币地址',
    cbsl: '数量',
    deposit: '存币',
    withdrawal: '提币',
    search: '搜索',
    currency: '币种',
    protocol: '协议',

    liquidation: '平仓',
    liquidation_title: '快捷平仓',
    trade_pwd: '交易密码',
    enter_trade_pwd: '输入交易密码',
    submit_liquidation: '立即平仓',

    amount: '总额',
    available: '可用',
    freeze: '冻结',
    market: '估值',
    ljsy: '累计收入',
    zrsy: '昨天的收益',
    operate: '操作',
    countdown: '倒计时',

    lever_title: '杠杆账户',
    contract_title: '合约账户',
    direction: '方向',
    bond: '保证金',
    buy_rate: '倍数',
    profit: '盈亏',
    profit1: '盈亏',
    buy: '买',
    sell: '卖',
    many: '多单',
    empty: '空单',

    contract_time: '合约时间',
    premium: '权利金',
    minute: '分钟',

    address: '地址',
    address_desc: '请输入地址',
    number: '数量',
    number_desc: '请输入提现数量',
    copy: '复制',
    confirm: '确定',

    all: '全部',
    fee: '手续费',
    actual: '实际到账',

    //备注
    premark: '注意',
    text_1: '该地址仅接收{name}资产,请勿充值其它币种,否则资产将无法找回',
    text_2: '{name}矿工费为：当前市值{rate}%/笔,最低标准：{min}{name}/笔',
    text_3: '转账前请确认收款地址信息无误，资产一旦转出将无法撤回',
    text_4: '{name}矿工费为：{val} {name}/笔'
  },
  record: {
    title: '资金记录',
    type: '类型',
    all_currency: '全部币种',
    all_type: '全部类型',
    select: '查询',
    clear: '清除筛选',
    receive: '存币',
    transfer: '提现',
    buy: '买入',
    sell: '卖出',
    normal: '普通委托',
    pending: '止盈止损',
    wallet: '持仓明细',
    zzzqy: '账户总权益',
    zqy: '可用权益',
    fdyk: '盈亏',
    fxl: '风险率'
  },
  user: {
    account: '账号认证',
    account_desc: '存币，交易等功能必须先通过账号认证',

    password: '登录密码',
    password_desc: '用于保护账户安全',

    modify: '修改',
    set: '设置',

    find_paypwd: '找回密码',
    find_pay_pwd: '找回支付密码',
    email: '邮箱',
    enter_email: '请输入邮箱',
    email_code: '验证码',
    enter_email_code: '请输入邮箱验证码',
    send: '发送',

    pay_password: '支付密码',
    pay_password_desc: '用于交易时保护资产安全',

    not_certified: '未认证',
    certification: '认证中',
    verified: '已认证',
    rejected: '已驳回',

    update_pwd: '修改登录密码',
    update_pay_pwd: '修改支付密码',
    set_pay_pwd: '设置支付密码',

    old_pwd: '原始密码',
    enter_old_pwd: '请输入原始密码',

    new_pwd: '新密码',
    enter_new_pwd: '请输入新密码',

    res_pwd: '确认密码',
    enter_res_pwd: '请输入确认密码',

    submit: '提交'
  },
  certified: {
    title: '账户认证',
    front: '身份证正面',
    back: '身份证背面',
    hand: '手持身份证',
    name: '名称',
    name_desc: '请输入名称',
    card: '身份证号',
    card_desc: '请输入身份证号',
    submit: '提交'
  },
  trade: {
    title: '交易密码',
    enter_password: '请输入交易密码',
    cancel: '取消',
    submit: '提交'
  },
  revoke: {
    title: '提示消息',
    message: '确认要撤回该委托吗?',
    cancel: '取消',
    submit: '确认'
  },
  coins_assets: {
    title: '币币资产',
    total_equity: '账户总权益',
    equity: '账户权益',
    number: '持有数量',
    market_value: '当前估值'
  },
  lever_assets: {
    title: '杠杆资产',
    total_equity: '账户总权益',
    equity: '账户权益',
    profit: '未实现盈亏',
    risk: '风险度'
  },
  contract_assets: {
    title: '合约资产',
    total_equity: '账户总权益',
    equity: '账户权益',
    profit: '未实现盈亏'
  },

  //文档菜单
  contract_menu: {
    contract: '合约详情',
    lever: '杠杆详情',
    coins: '币币指数',
    history: '资金费率历史',
    coins_rules: [
      {
        value: '1D',
        name: '当天'
      },
      {
        value: '1W',
        name: '当周'
      },
      {
        value: '1M',
        name: '当月'
      },
      {
        value: '1J',
        name: '当季'
      }
    ],
    lever_info: '交易信息',
    contract_info: '合约信息'
  },

  //合约详情
  contract_info: {
    bzj: '保证金',

    pt1: '结算货币',
    pt2: '合约面值',
    pt3: '最小变动价位',
    pt4: '合约期限',
    pt5: '资金费率时间',
    pt6: '标记价格',
    pt7: '持仓量',
    pt8: '24小时成交量',
    pt9: '权利金',
    pt10: '到期交割收益',
    pt11: '建仓点差',
    pt12: '交割结算机制',
    pt13: '交易服务费',
    pt14: '锁仓',

    pv5: '每天08:00  16:00   24:00',
    pv10_1: '多仓：（平仓均价 - 建仓均价）*数量',
    pv10_2: '空仓：（建仓均价 - 平仓均价）*数量',
    pv11: '无',
    pv12: '到期自动交割',
    pv13: '无',
    pv14: '不支持'
  },

  //杠杆详情
  metal_info: {
    pt1: '交易品种',
    pt2: '结算货币',
    pt3: '报价单位',
    pt4: '交易单位',
    pt5: '最小变动单位',
    pt51: '最小交易单位',
    pt52: '最大交易单位',
    pt6: '合约时间',
    pt7: '交易时间',
    pt8: '交易服务费',
    pt9: '上市交易所',
    pt10: '目标价差',

    pv4: '永续',
    pv5: '每周一至周五24小时交易',
    pv51: '24小时交易',
    pv6: '合约价值的0.08%',
    pv7: '伦敦交易所（LME）',
    pv71: '纽约交易所（NYMEX)',
    pv10: '{name}目标差价为{num}点子'
  },

  //杠杆详情
  lever_info: {
    bei: '倍',

    pt1: '交易品种',
    pt2: '结算货币',
    pt3: '报价单位',
    pt4: '交易单位',
    pt5: '最小变动单位',
    pt51: '最小变动单位',
    pt6: '合约时间',
    pt7: '交易时间',
    pt8: '交易服务费',
    pt9: '上市交易所',
    pt10: '平仓收益',
    pt11: '建仓点差',
    pt12: '自动平仓机制',
    pt13: '交易服务费',
    pt14: '锁仓',

    pv4: '永续',
    pv5: '每周一至周五24小时交易',
    pv6: '合约价值的0.08%',
    pv7: '伦敦交易所（LME）',
    pv71: '纽约交易所（NYMEX)',
    pv10_1: '多仓：（平仓均价 - 建仓均价）*数量',
    pv10_2: '空仓：（建仓均价 - 平仓均价）*数量',
    pv12: '准备金风险率达到80%',
    pv13: '合约价值',
    pv14: '不支持'
  },

  //币币信息
  coins_desc: require('./currency/zh/coins.js').default,

  //资金费率历史
  fun_history: {
    t1: '日期',
    t2: '预计资金费率',
    t3: '实际资金费率'
  },

  // 文档
  docs: {
    list: [
      {
        name: 'OTC交易规则',
        key: 'otc'
      },
      {
        name: 'KYC和反洗钱协议',
        key: 'kyc'
      },
      {
        name: '执法请求政策',
        key: 'law'
      },
      {
        name: '隐私保护',
        key: 'privacy'
      },
      {
        name: '用户协议',
        key: 'protocol'
      },
      {
        name: '风险告知',
        key: 'risk'
      }
    ],
    otc: require('./currency/zh/otc').default,
    kyc: require('./currency/zh/kyc').default,
    law: require('./currency/zh/law').default,
    privacy: require('./currency/zh/privacy').default,
    protocol: require('./currency/zh/protocol').default,
    risk: require('./currency/zh/risk').default
  },

  // 数字资产介绍
  asset: {
    title: '数字资产介绍',
    list: '数字资产列表'
  },
  num_asset: require('./currency/zh/asset').default,

  // 新币认购
  new_currency: {
    _title: '新币认购',
    _all: '全部',
    _progress: '进行中',
    _end: '已结束',
    _success: '兑换成功',
    _progress_str: '进度',
    _total_str: '释放总额',
    _date_time: '开始结束日期',
    _submit: '立即兑换',

    _base_title: '基本信息',
    _status: '状态',
    _currency: '接受币种',
    _symbol: '货币符号',
    _amount: '解禁总额',
    _price: '初始价格',
    _limit: '个人限购次数',
    _limit_amount: '个人限购数量',
    _limit_one: '个人单笔限额下限',
    _no_limit: '不限制',
    _position: '持仓',
    _begin_time: '开始时间',
    _end_time: '结束时间',
    _redeemed: '已兑换金额',
    _surplus: '剩余',
    _wallet: '钱包',
    _have: '当前持有',
    _input: '输入数量',
    _choose: '请选择',

    _new_wallet: '新币账户',
    _new_price: '最新价',
    _lock: '锁定期',

    _login: '请登录',
    _no_data: '暂无数据',

    _avg_price: '均价'
  }
}
