export default {
    pt1:"1.用户登录STARTHESTAR后，点击“开始交易”后进入平台交易界面；",
    pimg1:"k5",
    pt2:"2.点击“币币交易”，将充值到平台的币种（BTC、ETH、TRX）兑换成USDT，例如用户充值的是BTC就点击BTC行情，点击“卖出”，输入需要兑换的数量，再点击“卖出”",
    pimg2:"k5",
    pt3:"3.在弹出界面输入交易密码，点击“提交”完成币币交易兑换。",
    pimg3:"k5",
    pt4:"STARTHESTAR温馨提示您：",
    pt5:"①用户在平台进行交易时，必须将充值到平台的非USDT币种，通过“币币交易”兑换成USDT，才能在平台去进行“杠杆交易”跟“合约交易”。",
    pt6:"②用户在STARTHESTAR平台进行“杆杆交易”跟“合约交易”获得利润后，可以通过“币币交易”买入相应的币种（BTC、ETH、TRX），从而提币。",
}