export default {
    pt1: "1. After the user logs in to STARTHESTAR, click \"Assets\" and then click \"My Wallet\" to enter the My Assets interface;",
    pimg1: "k6",
    pt2: "2. In the My Assets interface, click \"Withdraw\",",
    pimg2: "k6",
    pt3: "3. The user chooses the currency you will withdraw. For example, if the user wants to withdraw BTC, select the BTC currency, and withdraw other currencies, select the corresponding currency, and then select the corresponding currency in the platform where the currency needs to be deposited Paste the deposit address into the \"Address\" column, fill in the number of coins to be withdrawn, and click \"OK\".",
    pimg3: "k6",
    pt4: "4. After the user completes the withdrawal process, wait for the STARTHESTAR platform to confirm the withdrawal information. (Note: The withdrawal amount is currently set at a minimum of 1000 USD. Please do not submit the withdrawal application multiple times. Users can view themselves in the My Assets interface Whether there are frozen assets to determine whether the withdrawal operation is completed. After waiting for the platform to process, the frozen assets will be cleared, indicating that the platform has passed the withdrawal application and completed the withdrawal).",
    pt5: "STARTHESTAR reminds you:",
    pt6: "①When withdrawing coins, pay attention to the wallet address of the corresponding currency deposit platform. Please do not fill in the error, otherwise the assets will not be retrieved.",
    pt7: "②When withdrawing USDT, pay attention to the blocks corresponding to USDT (ERC20, TRC20, OMNI), please do not fill in the wrong, otherwise the assets will not be retrieved.",
}