module.exports = {
  base: {
    title: 'STARTHESTAR',
    desc1: 'Sopimuksen kuningas',
    desc2: 'Maailman johtava sopimuskaupan toimittaja',
    d1: 'Luottamus',
    d2: 'Rehellisyys',
    d3: 'Vahvuus',
    more: 'Lisää',
    top: 'takaisin alkuun',
    download: 'Lataa APP',
    zszh: 'Oikea tili',
    mnzh: 'Demotili',
    content: 'Käy kauppaa sopimuskaupan maailmanlaajuisen markkinajohtajan kanssa'
  },
  demo: {
    title: 'ilmoittautuminen onnistui!',
    title2: 'Analoginen tili',
    submit: 'Vahvistaa'
  },
  lang: {
    title: 'Suomalainen'
  },
  message: {
    invalid_email: 'Virheellinen sähköpostiosoite',
    invalid_password: 'Väärä salasana',
    invalid_new_password: 'Virheellinen uusi salasana',
    invalid_res_password: 'Virheellinen vahvistussalasana',
    invalid_email_code: 'Virheellinen sähköpostin vahvistuskoodi',
    password_inconsistent: 'Epäjohdonmukaiset salasanat',
    invalid_invitation_code: 'Virheellinen kutsukoodi',
    should_agree: 'Lue ja hyväksy rekisteröintiehdot',
    not_null: 'Tämä kenttä ei voi olla tyhjä',
    send_email: 'Yritä uudelleen {:s} s kuluttua',
    invalid_number: 'Kelpaamaton arvo',
    invalid_balance: 'Virheellinen saldo',
    invalid_rate: 'Valitse useita',
    invalid_rule: 'Valitse sääntö',
    invalid_system_rule: 'Ei sääntöjä järjestelmässä',
    not_enough: 'Ei tarpeeksi saatavilla',
    price_high: 'Hinnan on oltava korkeampi kuin {price}',
    price_low: 'Hinnan on oltava alle {price}',
    number_high: 'Kotiutusten enimmäismäärä on {num}',
    number_low: 'Pienin kotiutussumma on {num}',
    copy_address: 'Kopioi osoite onnistuneesti {text}',
    invalid_upload_front: 'Lataa henkilöllisyystodistuksen etukuva',
    invalid_upload_back: 'Lähetä kuva henkilötodistuksesi kääntöpuolelta',
    invalid_upload_hand: 'Lataa kädessä pidettävä henkilöllisyystodistus',
    invalid_upload_name: 'Anna varmenteen nimi',
    invalid_upload_card: 'Anna tunnusnumero',
    upload_pic_type: 'Ladattavat kuvat voivat olla vain JPG- ja PNG-muodossa',
    upload_pic_size: 'Lähetettävän kuvan koko ei saa ylittää 10 Mt'
  },
  index: {
    title2: 'Globaalien markkinoiden vapaa kauppa 24 tuntia non-stop',
    desc2: 'Toteuta kaupankäyntistrategiasi erittäin likvideillä markkinoilla 24 tunnin kaupankäyntivolyymilla',
    desc2_1: 'koko alustalla',
    list: {
      name: 'markkinoida',
      h24_price: '24h uusin hinta',
      h24_change: '24h muutos',
      h24_high: '24h korkein hinta',
      h24_low: '24h alin hinta',
      h24_vol: '24 tunnin tilavuus'
    },
    footer: {
      desc:
        'starthestar Blockchain Technology Ltd, jonka pääkonttori on Yhdysvalloissa, on maailman johtava blockchain-teknologiayritys. Yhtiö on saanut US MSB:n kansainvälisen rahoituslisenssin. Se on yhteensopiva lohkoketjuteknologian palveluyritys, joka voi toimia vaatimustenmukaisuuden mukaisesti globaalissa mittakaavassa Blockchain-liiketoimintapalveluissa. Samaan aikaan starthestar Blockchain Technology Ltd:n teknisen tiimin kyvyt tulevat huippuluokan kansainvälisistä tieteellisistä tutkimuslaitoksista ja blockchain-teknologian kehitystiimeistä suojaamaan täysin käyttäjävaroja ja tilien turvallisuutta. Teknisen tiimin lisäksi yrityksellä on myös ammattitaitoinen lohkoketjutiimi: digitaalisten varojen tekoälyn kvantitatiivisen sijoitustiimin, lohkoketjuprojektin arvon arviointitiimin, digitaalisen omaisuuden pääomasijoitustiimin jne. on globaali asettelu palvella globaaleja lohkoketjun käyttäjiä. Yhtiön tavoitteena ja visio on tarjota turvallisia, vakaita, huomaavaisia ja luotettavia transaktiopalveluita globaaleille digitaalisen omaisuuden harrastajille sekä rakentaa ammattimainen, digitaalinen ja kansainvälinen maailmankuulu lohkoketjuteknologiayritys.',
      item1: {
        tt: 'Tuotenäyttö',
        pt1: 'OTC-kaupankäynnin säännöt',
        pt2: 'Aloittelijan opas',
        pt3: 'Rate Standard',
        pt4: 'Sopimuksen kuvaus'
      },
      item2: {
        tt: 'Tuki',
        pt1: 'KYC ja rahanpesun vastainen sopimus',
        pt2: 'Täytäntöönpanopyyntökäytäntö',
        pt3: 'Johdatus digitaalisiin resursseihin'
      },
      item3: {
        tt: 'Ehdot',
        pt1: 'Yksityisyydensuoja',
        pt2: 'Käyttäjäsopimus',
        pt3: 'Riskiilmoitus'
      },
      item4: {
        tt: 'ota meihin yhteyttä',
        pt1: 'Yritysyhteistyö',
        pt2: 'Institutionaalinen yhteistyö',
        pt3: 'OTC-sovellus',
        pt4: 'Asiakaspalvelun sähköposti',
        pt5: 'Valitukset ja ehdotukset',
        pt6: 'mediayhteistyö'
      }
    },
    bk3: {
      title: 'Ylpeä innovatiivisesta T&K-voimasta',
      desc: 'Käsittämätön sopimuskaupankäyntikokemus, suunniteltu finanssialan toimijoille',
      pt1: 'Avoin tilaus pysäyttää voitto ja pysäyttää tappion',
      pd1: 'Odottavat tilaukset eivät pysy myöhään, huolehdi siitä, ettet katso tilausta',
      pt2: 'Käänteinen avoin järjestys',
      pd2: 'Markkinat muuttuvat äkillisesti, pysy perässä sekunnissa',
      pt3: 'Tukee koko varastoa varastokohtaisesti',
      pd3: 'Tee mitä haluat, tee mitä haluat',
      pt4: 'Koko asema porrastettu selvitystila',
      pd4: 'Ulkomainen likvidaatio on ensin puolet ja yritä auttaa sijoittajia vähentämään tappioita'
    },
    bk5: {
      title: 'Sopimuskaupankäyntialustan kahdeksan etua',
      desc: 'Kuuntelemme jatkuvasti, optimoimme ja parannamme jatkuvasti ja luomme ensiluokkaisen kaupankäyntikokemuksen maailmanlaajuisille kaupankäynnin käyttäjille',
      pt1: 'Markkinoiden syvyys',
      pd1: 'Alustan kolmen ensimmäisen kioskin markkinasyvyys on saavuttanut miljoonien dollarien luokkaa, mikä ylittää huomattavasti alan keskimääräisen markkinasyvyyden 10 000 ja 100 000. Hajotus on pieni ja kaupankäynti nopeaa.',
      pt2: 'Sujuvaa kaupankäyntiä',
      pd2: 'Käyttämällä tehokasta täsmäytystapahtumamoottoria sujuvan tapahtumakokemuksen varmistamiseksi, se voi käsitellä miljoonia tilauksia sekunnissa ja tukea sujuvaa ja vakaata toimintaa yli 20 miljoonan samanaikaisen online-käyttäjän kanssa.',
      pt3: 'Reilu hinta',
      pd3: "'Marked Price' valitsee regressiolaskennan indeksihinnaksi maailman kolmen suurimman pörssin reaaliaikaisen transaktiohinnan, mikä estää tehokkaasti käyttäjätappiot, jotka aiheutuvat viimeisimmän kauppahinnan epänormaalista manipuloinnista lyhyessä ajassa.",
      pt4: 'Itse kehitetty tekniikka',
      pd4: 'Perinteiseen rahoitukseen perustuva matchmaking-moottori, jonka on kehittänyt joukko rahoitusalan johtavia asiantuntijoita, ja joka on suunniteltu huolellisesti kaikki yksityiskohdat varmistaakseen kaupankäyntialustan turvallisen, vakaan ja tehokkaan toiminnan.',
      pt5: 'Huippu riskienhallinta',
      pd5: 'Toisen tason arviointi, monikerroksinen salaus, korkea järjestelmän suojaus. Kuumien ja kylmien lompakoiden yhdistelmä, sotilasluokan salaustoimenpiteet, omaisuuden transaktioturvallisuus on taattu ja 100% takaa käyttäjien digitaalisen omaisuuden turvallisuuden.',
      pt6: 'Rahastojen turvallisuus',
      pd6:
        'Kolmikerroksinen turvajärjestelmä, kattava turvatarkastus. Itse rakennettu alan johtava hierarkkinen deterministinen kylmälompakkojärjestelmä, kaikki käyttäjät määrittävät latausosoitteet ovat cold wallet -osoitteita, varojen kerääminen ja kotiuttaminen suoritetaan offline-allekirjoitusten kautta.',
      pt7: 'Kova voima',
      pd7: 'Kymmeniä tuhansia BTC-varantoja, rahoitusinnovaatiogeenejä ja maailman ylin johtoryhmä. starthestar lupaa, että jos tili- ja tapahtumatietovirheitä ei aiheudu järjestelmäsyistä, alusta maksaa virheiden sattuessa kokonaisuudessaan!',
      pt8: 'Laatupalvelu',
      pd8: '7*24h vuorokauden ympäri ammattitaitoinen asiakaspalvelu, nopea vastaus. Palveluajattelusta alkaen tarjoa ensiluokkaista kaupankäyntikokemusta maailmanlaajuisille asiakkaille. Lopullinen kokemus syntyy asiakkaiden palvelemisesta.'
    },
    bk6: {
      title: 'Turvallisuus ja vaatimustenmukaisuus maailmanlaajuisesti',
      desc: 'Hyväksy valvonta ja noudata aina maailmanlaajuisia vaatimustenmukaisuustoimia'
    },
    bk7: {
      title: 'Virallinen strateginen kumppani'
    },
    bk8: {
      title: 'Palvelee yli 100 000 maailmanlaajuista kaupankäynnin käyttäjää',
      desc: 'Tällä hetkellä käyttäjät ovat kaikkialla Yhdysvalloissa, Japanissa, Etelä-Koreassa, Seychellit, Vietnamissa ja muissa maissa'
    },
    bk9: {
      title: 'Sujuvaa kaupankäyntiä missä ja milloin tahansa',
      pt1: 'Reaaliaikaiset matchmaking-tapahtumat, talletukset ja nostot saapuvat sekunneissa',
      pt2: 'Useita kaupankäyntimuotoja, jopa 125-kertainen vipuvaikutus',
      dt1: 'iOS-version lataus',
      dt2: 'Android-version lataus'
    },
    about: {
      title: 'Meistä',
      pt1: 'Alustan käsite',
      pd1:
        'Anna globaalin omaisuuden liikkua vapaasti ja luo uusi sopimuskokemus. X-xenogenetic edustaa innovatiivisia geenejä ja tarjoaa tasaisen voiman teollisuuden innovaatioille. M-motor edustaa tavoitetta ja jatkaa eteenpäin tavoitteenaan johtaa sopimuskaupan kokemusta .EX-pörssi kaupankäyntialustan puolesta on sitoutunut luomaan reilun, läpinäkyvän ja tehokkaan kaupankäyntiekosysteemin toimintafilosofiallaan palvelusta, kuuntelemisesta ja ajattelusta.',
      pt2: 'Perusarvot',
      pd2:
        'Kuuntelemme, kiinnitämme huomiota ja ajattelemme eteenpäin ja olemme sitoutuneet luomaan oikeudenmukaisen, läpinäkyvän ja tehokkaan kaupan ekosysteemin. Tarjoa maailmanlaajuisille kauppiaille parempi kaupankäyntikokemus, vastaa kauppiaiden todellisiin tarpeisiin ja edistä digitaalisten omaisuuserien kaupankäynnin markkinoiden kehitystä.',
      pt3: 'Joukkueen tausta',
      pd3:
        'Perustajatiimi koostuu parhaista blockchain-sijoittajista, perinteisistä talousjohtajista, digitaalisen omaisuuden huippuasiantuntijoista ja perinteisistä metallintyöstöteknologiatiimeistä. T&K-tiimin ydinjäsenet ovat Morgan Stanleysta, CICC:stä, Hang Sengistä, Tencentistä ja Noah Wealthistä.'
    }
  },
  start: {
    p_title: 'Kauppa, aloita tästä',
    p_desc: 'Kirjoita sähköpostiosoitteesi seuraavaan kohtaan rekisteröityäksesi välittömästi',
    p_email: 'sähköposti',
    p_btn: 'Luoda'
  },
  download: {
    p_title: 'Lataa APP asiakas',
    p_desc: 'Milloin tahansa, missä tahansa, usean alustan päätetapahtuma',
    p_btn: 'ladata',
    p_scan_android: 'Android-sovellus',
    p_scan_ios: 'IOS-sovellus'
  },
  downpage: {
    t1: {
      p_title: 'Lightning Transaction Performance',
      p_desc: 'Tarjoa erilaisia tapahtumatyyppejä, joista valita, tilausten täsmäytys on erittäin nopeaa, yhden napsautuksen flash-vaihto ja kaupankäynti on kätevämpää'
    },
    t2: {
      p_title: 'Helppo omaisuuden siirto',
      p_desc: 'Helppo varojen siirto tilille, reaaliaikainen yleiskatsaus digitaalisista varoista, lisää valuuttasuhteen ympyräkaaviota'
    },
    t3: {
      p_title: 'Mukautettu kauppapaneeli',
      p_desc: 'Määrittele vapaasti kaupankäynnin asettelu eri kaupankäyntitottumusten mukaan ja lisää K-linjan ja syvyyskartan tuloa'
    },
    t4: {
      p_title: 'Tilin integroinnin hallinta',
      p_desc: 'Useita suojausvahvistusasetuksia, yksi tili hallinnoi C2C-tilejä ja valuuttatilejä'
    }
  },
  login: {
    login: 'Kirjaudu sisään',
    title: 'Käyttäjän kirjautuminen',
    desc1: 'Kirjaudu sisään',
    desc2: 'Virallinen nettisivu',
    email: 'Sähköposti',
    email2: 'Sähköposti',
    email3: 'Puhelinnumero',
    email_desc: 'Ole hyvä ja kirjoita sähköpostiosoitteesi',
    phone_desc: 'Anna puhelinnumero',
    password: 'Salasana',
    password_desc: 'Anna salasana',
    forgot_password: 'Unohtuiko salasana',
    no_account: 'Ei vielä tiliä'
  },
  signup: {
    signup: 'Kirjaudu',
    title: 'käyttäjän rekisteröinti',
    desc1: 'Tervetuloa kirjautumaan',
    desc2: 'Virallinen nettisivu',
    invitation_code: 'Kutsukoodi',
    invitation_desc: 'Anna kutsukoodi',
    accept: 'Hyväksyä',
    service: 'Käyttäjäsopimus',
    and: 'ja',
    policy: 'Yksityisyyden suojakäytäntö',
    have_account: 'Onko sinulla jo tili'
  },
  retrieve: {
    ptitle: 'Hae salasana',
    pemail_code: 'Sähköpostin vahvistuskoodi',
    pset: 'Aseta salasana',
    set_title: 'Aseta salasana',
    set_desc: 'Olet palauttamassa salasanasi. Tee seuraavat asetukset',
    new_password: 'uusi salasana',
    password_desc: 'Anna uusi salasana',
    res_passowrd: 'Vahvista salasana',
    res_passowrd_desc: 'Anna vahvistus salasana'
  },
  valid_email: {
    title: 'Rekisteröinnin vahvistus',
    desc: 'Olet rekisteröimässä tiliä, suorita seuraava vahvistus',
    email_code: 'Sähköpostin vahvistuskoodi',
    email_desc: 'Olkaa hyvä ja syöttäkää varmistuskoodi',
    get_code: 'Hanki vahvistuskoodi'
  },
  ihead: {
    begin_trade: 'Aloita kaupankäynti',
    helper: 'Aloittelijan apu',
    text: 'Asiakirjakeskus',
    new_currency: 'Uusi valuuttamerkintä'
  },
  uhead: {
    title1: 'Hyödykkeet',
    title2: 'Kansainvälinen Forex',
    title3: 'Kansainvälinen indeksi',
    currency: 'Valuutta',
    lever: 'Vipuvaikutteinen transaktio',
    coins: 'Valuuttatapahtuma',
    contract: 'Sopimustapahtuma',
    metal: 'Metalli',
    energy: 'Energiaa',
    contract_info: 'Sopimustiedot',
    helper: 'Aloittelijan apu',
    text: 'Asiakirjakeskus',
    assets: 'Omaisuus',
    u_wallet: 'Lompakkoni',
    u_contract: 'Sopimustili',
    u_lever: 'Vipuvaikutustili',
    u_account: 'Tilitiedot',
    u_logout: 'Kirjautua ulos'
  },
  news: {
    label: 'Uutiset',
    update: 'Päivitetty'
  },
  guide: {
    label: 'Aloittelijan opas',
    list: [
      {
        ptitle: 'Kuinka rekisteröityä starthestarn',
        pkey: 'k1'
      },
      {
        ptitle: 'Henkilöllisyyden todennus',
        pkey: 'k2'
      },
      {
        ptitle: 'Kirjautumissalasana ja tapahtumasalasanan asetukset',
        pkey: 'k3'
      },
      {
        ptitle: 'Talletuskolikot',
        pkey: 'k4'
      },
      {
        ptitle: 'Kolikkokauppaprosessi',
        pkey: 'k5'
      },
      {
        ptitle: 'Nosto',
        pkey: 'k6'
      },
      {
        ptitle: '-mobiilisovelluksen lataus',
        pkey: 'k7'
      }
    ],
    content: {
      k1: require('./currency/en/guide/k1.js').default,
      k2: require('./currency/en/guide/k2.js').default,
      k3: require('./currency/en/guide/k3.js').default,
      k4: require('./currency/en/guide/k4.js').default,
      k5: require('./currency/en/guide/k5.js').default,
      k6: require('./currency/en/guide/k6.js').default,
      k7: require('./currency/en/guide/k7.js').default
    }
  },
  kline: {
    h24_high: '24h korkea',
    h24_low: '24h alhainen',
    h24_vol: '24 tunnin tilavuus',
    commission: 'Nykyinen komissio',
    record: 'Tapahtumatietue',
    commission_list: 'Komission luettelo',
    deal_list: 'Viimeisin tarjous',
    price: 'Hinta',
    xprice: 'Voitto jakautuu',
    bzj: 'Marginaali',
    number: 'Määrä',
    total: 'Kaikki yhteensä',
    time: 'Aika',
    currency: 'Asemat',
    type: 'Tyyppi',
    direction: 'Suunta',
    multiple: 'Vipuvaikutus',
    fee: 'komissio',
    amount: 'Kaikki yhteensä',
    status: 'Tila',
    operate: 'Operaatio',
    buy: 'Ostaa',
    sell: 'Myydä',
    pending: 'Odottava tilaus',
    trade_success: 'Kaupankäynti onnistui',
    retract: 'Peruuttaa',
    profit: 'Ota voittoa',
    loss: 'Lopeta menetys',
    all: 'Kaikki',
    all_currency: 'Kaikki valuutat',
    new_price: 'Viimeisin hinta',
    change: 'Nousu ja lasku',
    protocol: 'protokollaa',
    _status1: 'Arvostelu',
    _status2: 'Onnistunut',
    _status3: 'Hylätty'
  },
  coins: {
    title: 'Valuuttatapahtuma',
    market: 'markkinahinta',
    limit: 'Rajahinta',
    profit: 'Ota voittoa',
    loss: 'Lopeta menetys',
    price: 'Hinta',
    current: 'Nykyinen',
    price_desc: 'Anna hinta',
    number: 'Määrä',
    number_desc: 'Anna numero',
    available: 'Saatavilla',
    half: 'puolikas varasto',
    all: 'Täysi varasto',
    fee: 'komissio',
    amount: 'Siirtosumma',
    buy: 'Ostaa',
    sell: 'Myydä',
    current_price: 'Nykyinen',
    open_price: 'Avoinna tänään',
    high: 'Korkea',
    low: 'Matala',
    yesterday: 'Sulje eilen',
    today_zf: 'Hintamuutos',
    now_cc: 'asema',
    y_cc: 'Y-asento',
    jsj: 'Sovitushinta',
    zrjsj: 'Y Selvityshinta'
  },
  lever: {
    title: 'Vipuvaikutteinen transaktio',
    multiple: 'Vipuvaikutus',
    multiple_desc: 'Valitse useita',
    double: 'kaksinkertainen',
    buy: 'Ostaa)',
    sell: 'Myy (syksy)'
  },
  contract: {
    title: 'Sopimustapahtuma',
    time: 'Aika',
    time_desc: 'Valitse aika',
    minute: 'minuutti',
    second: 'Toinen',
    buy: 'Ostaa)',
    sell: 'Myy (syksy)',
    rate: 'Rojaltisuhde'
  },
  wallet: {
    title: 'Omat varat',
    valuation: 'Kokonaisomaisuuden arvostus',
    my_wallet: 'Lompakkoni',
    my_trade: 'Kaupankäyntitili',
    my_transfer: 'Nostotennätys',
    my_deposit: 'Kolikon talletustietue',
    cbdz: 'talletusosoite',
    cbsl: 'Määrä',
    deposit: 'Talleta kolikoita',
    withdrawal: 'Nosto',
    search: 'Hae',
    currency: 'Asemat',
    protocol: 'pöytäkirja',
    liquidation: 'Sulje sijainti',
    liquidation_title: 'Nopea selvitystila',
    trade_pwd: 'Kaupankäynnin salasana',
    enter_trade_pwd: 'Anna kaupankäynnin salasana',
    submit_liquidation: 'Sulje asento välittömästi',
    amount: 'Kaikki yhteensä',
    available: 'Saatavilla',
    freeze: 'Jäädyttää',
    market: 'arvostus',
    ljsy: 'Kumulatiivinen tulo',
    zrsy: 'Eilen tulot',
    operate: 'Operaatio',
    countdown: 'Lähtölaskenta',
    lever_title: 'Vipuvaikutustili',
    contract_title: 'Sopimustili',
    direction: 'Suunta',
    bond: 'Marginaali',
    buy_rate: 'useita',
    profit: 'Voitto ja tappio',
    profit1: 'Voitto ja tappio',
    buy: 'Ostaa',
    sell: 'Myydä',
    many: 'Ostaa',
    empty: 'Myydä',
    contract_time: 'Sopimuksen aika',
    premium: 'Etuoikeus',
    minute: 'minuutti',
    address: 'Osoite',
    address_desc: 'Anna osoite',
    number: 'Määrä',
    number_desc: 'Syötä nostojen määrä',
    copy: 'Kopio',
    confirm: 'OK',
    all: 'Kaikki',
    fee: 'komissio',
    actual: 'Todellinen saapuminen',
    premark: 'Huomio',
    text_1: 'Tämä osoite hyväksyy vain {name} omaisuutta, älä lataa muita valuuttoja, muuten omaisuutta ei noudeta',
    text_2: '{name} kaivospalkkio on: nykyinen markkina-arvo {rate}%/tapahtuma, vähimmäisstandardi: {min}{name}/tapahtuma',
    text_3: 'Varmista ennen varojen siirtämistä, että vastaanottajan osoitetiedot ovat oikein. Kun varat on siirretty pois, niitä ei voi nostaa.',
    text_4: '{name} Kaivosmaksu on: {val} {name}/kynä'
  },
  record: {
    title: 'Rahastot Record',
    type: 'Tyyppi',
    all_currency: 'Kaikki valuutat',
    all_type: 'Kaikki tyypit',
    select: 'Kysely',
    clear: 'Tyhjennä suodatin',
    receive: 'Tallettaa',
    transfer: 'Nosto',
    buy: 'Ostaa',
    sell: 'Myydä',
    normal: 'Tavallinen',
    pending: 'Stop-Limit',
    wallet: 'Aseman tiedot',
    zzzqy: 'Saldo',
    zqy: 'Vapaa marginaali',
    fdyk: 'Kelluva P/L',
    fxl: 'Riskiaste'
  },
  user: {
    account: 'Tilin todennus',
    account_desc: 'Luottotalletuksen, tapahtuman ja muiden toimintojen on ensin läpäistävä tilitarkastus',
    password: 'Kirjautumissalasana',
    password_desc: 'Käytetään tilin turvallisuuden suojaamiseen',
    modify: 'Muuttaa',
    set: 'Aseta',
    find_paypwd: 'Hae salasana',
    find_pay_pwd: 'Hae maksun salasana',
    email: 'Sähköposti',
    enter_email: 'Ole hyvä ja syötä sähköpostiosoitteesi',
    email_code: 'Vahvistuskoodi',
    enter_email_code: 'Anna sähköpostin vahvistuskoodi',
    send: 'Lähettää',
    pay_password: 'Maksun salasana',
    pay_password_desc: 'Käytetään omaisuuden turvallisuuden suojaamiseen kaupankäynnin aikana',
    not_certified: 'Ei sertifioitu',
    certification: 'Sertifiointi käynnissä',
    verified: 'Vahvistettu',
    rejected: 'hylätty',
    update_pwd: 'Muuta kirjautumissalasanaa',
    update_pay_pwd: 'Muuta maksun salasanaa',
    set_pay_pwd: 'Aseta maksun salasana',
    old_pwd: 'Alkuperäinen salasana',
    enter_old_pwd: 'Anna alkuperäinen salasana',
    new_pwd: 'uusi salasana',
    enter_new_pwd: 'Anna uusi salasana',
    res_pwd: 'Vahvista salasana',
    enter_res_pwd: 'Anna vahvistus salasana',
    submit: 'Lähetä'
  },
  certified: {
    title: 'Tilin todennus',
    front: 'Henkilökortti edessä',
    back: 'Henkilökortin takapuoli',
    hand: 'Kädessä pidettävä henkilökortti',
    name: 'Nimi',
    name_desc: 'Anna nimi',
    card: 'ID-numero',
    card_desc: 'Anna tunnusnumero',
    submit: 'Lähetä'
  },
  trade: {
    title: 'tapahtuman salasana',
    enter_password: 'Anna tapahtuman salasana',
    cancel: 'peruuttaa',
    submit: 'Lähetä'
  },
  revoke: {
    title: 'Pikaviesti',
    message: 'Haluatko varmasti peruuttaa tilauksen?',
    cancel: 'peruuttaa',
    submit: 'vahvistaa'
  },
  coins_assets: {
    title: 'Valuuttavarat',
    total_equity: 'Kokonaispääoma',
    equity: 'Tilin pääoma',
    number: 'Tilojen lukumäärä',
    market_value: 'Nykyinen arvostus'
  },
  lever_assets: {
    title: 'Viputettu omaisuus',
    total_equity: 'Kokonaispääoma',
    equity: 'Tilin pääoma',
    profit: 'Realisoitumaton voitto ja tappio',
    risk: 'Riski'
  },
  contract_assets: {
    title: 'Sopimusomaisuus',
    total_equity: 'Kokonaispääoma',
    equity: 'Tilin pääoma',
    profit: 'Realisoitumaton voitto ja tappio'
  },
  contract_menu: {
    contract: 'Sopimuksen tiedot',
    lever: 'Vipuvaikutuksen tiedot',
    coins: 'Kolikkoindeksi',
    history: 'Rahaston korkohistoria',
    coins_rules: [
      {
        value: '1D',
        name: 'Päivänä'
      },
      {
        value: '1W',
        name: 'Tämä viikko'
      },
      {
        value: '1 milj',
        name: 'Tämä kuukausi'
      },
      {
        value: '1J',
        name: 'Nykyinen kausi'
      }
    ],
    lever_info: 'Hyödynnä tiedot',
    contract_info: 'Sopimustiedot'
  },
  contract_info: {
    bzj: 'Vakuus',
    pt1: 'Selvitysvaluutta',
    pt2: 'Sopimuksen nimellisarvo',
    pt3: 'Minimimuutoshinta',
    pt4: 'Sopimuskausi',
    pt5: 'Rahaston korkoaika',
    pt6: 'Merkitty hinta',
    pt7: 'Aseman määrä',
    pt8: '24 tunnin kaupankäyntivolyymi',
    pt9: 'Etuoikeus',
    pt10: 'Maturiteetti Toimitustulot',
    pt11: 'Avaa positiohajotus',
    pt12: 'Toimitus- ja selvitysmekanismi',
    pt13: 'Transaktiopalvelumaksu',
    pt14: 'Lukitusasento',
    pv5: 'Joka päivä 08:00 16:00 24:00',
    pv10_1: 'Pitkä positio: (keskimääräinen päätöskurssi-keskimääräinen avaushinta) x määrä',
    pv10_2: 'Lyhyt positio: (keskimääräinen avaushinta-keskimääräinen päätöskurssi) x määrä',
    pv11: 'ei',
    pv12: 'Automaattinen toimitus eräpäivänä',
    pv13: 'ei',
    pv14: 'ei tue'
  },
  metal_info: {
    pt1: 'Kaupankäynnin lajikkeet',
    pt2: 'Selvitysvaluutta',
    pt3: 'Tarjousyksikkö',
    pt4: 'Sopimuksen koko',
    pt5: 'Minimivaihtoyksikkö',
    pt51: 'Pienin kaupankäyntiyksikkö',
    pt52: 'Suurin kaupankäyntiyksikkö',
    pt6: 'Sopimuksen aika',
    pt7: 'Kaupankäyntiaika',
    pt8: 'komissio',
    pt9: 'Listattu vaihto',
    pt10: 'tavoite leviäminen',
    pv4: 'kestävää',
    pv5: '24 tunnin kauppa maanantaista perjantaihin',
    pv51: '24 tunnin kauppa',
    pv6: 'Sopimuksen arvo 0,08 %',
    pv7: 'Lontoon pörssi (LME)',
    pv71: 'New Yorkin pörssi (NYMEX)',
    pv10: '{name} tavoiteero on {num} pistettä'
  },
  lever_info: {
    title: 'Vipuvaikutuksen tiedot',
    bei: 'ajat',
    pt1: 'Selvitysvaluutta',
    pt2: 'Sopimuksen nimellisarvo',
    pt3: 'Minimimuutoshinta',
    pt4: 'Sopimuskausi',
    pt5: 'Rahaston korkoaika',
    pt6: 'Merkitty hinta',
    pt7: 'Aseman määrä',
    pt8: '24 tunnin kaupankäyntivolyymi',
    pt9: 'Riskireservi',
    pt10: 'Likvidaatiovoitto',
    pt11: 'Avaa positiohajotus',
    pt12: 'Automaattinen likvidaatiomekanismi',
    pt13: 'Transaktiopalvelumaksu',
    pt14: 'Lukitusasento',
    pv4: 'Kestävä',
    pv5: 'Joka päivä 08:00 16:00 24:00',
    pv10_1: 'Pitkä positio: (keskimääräinen päätöskurssi-keskimääräinen avaushinta) x määrä',
    pv10_2: 'Lyhyt positio: (keskimääräinen avaushinta-keskimääräinen päätöskurssi) x määrä',
    pv12: 'Varantoriskisuhde saavuttaa 80 %',
    pv13: 'Sopimusarvo',
    pv14: 'Ei tueta'
  },
  //币币信息
  coins_desc: require('./currency/en/coins.js').default,

  fun_history: {
    t1: 'Päivämäärä',
    t2: 'Arvioitu rahoitusprosentti',
    t3: 'Todellinen rahoitusprosentti'
  },
  docs: {
    list: [
      {
        name: 'OTC-kaupankäynnin säännöt',
        key: 'otc'
      },
      {
        name: 'KYC ja rahanpesun vastaiset sopimukset',
        key: 'kyc'
      },
      {
        name: 'Lainvalvontapyyntöjen käytäntö',
        key: 'laki'
      },
      {
        name: 'Yksityisyydensuoja',
        key: 'yksityisyyttä'
      },
      {
        name: 'Käyttäjäsopimus',
        key: 'protokollaa'
      },
      {
        name: 'Riskiilmoitus',
        key: 'riski'
      }
    ],
    otc: require('./currency/en/otc').default,
    kyc: require('./currency/en/kyc.js').default,
    law: require('./currency/en/law.js').default,
    privacy: require('./currency/en/privacy.js').default,
    protocol: require('./currency/en/protocol').default,
    risk: require('./currency/en/risk').default
  },
  asset: {
    title: 'Digitaalisen omaisuuden esittely',
    list: 'Luettelo digitaalisista resursseista'
  },
  num_asset: require('./currency/en/asset').default,

  new_currency: {
    _title: 'Uusi valuuttamerkintä',
    _all: 'Kaikki',
    _progress: 'Käynnissä',
    _end: 'Yli',
    _success: 'Vaihto onnistui',
    _progress_str: 'Edistyminen',
    _total_str: 'Yhteensä Julkaistu',
    _date_time: 'Aloitus- ja lopetuspäivämäärä',
    _submit: 'Lunasta nyt',
    _base_title: 'Perustiedot',
    _status: 'Tila',
    _currency: 'Hyväksytty valuutta',
    _symbol: 'Valuutan symboli',
    _amount: 'Täysin kumottu kielto',
    _price: 'Alkuhinta',
    _limit: 'Henkilökohtainen ostoraja',
    _limit_amount: 'Henkilökohtainen ostoraja',
    _limit_one: 'Henkilökohtainen yhden rajan alaraja',
    _no_limit: 'Ei rajoitusta',
    _position: 'asema',
    _begin_time: 'Aloitusaika',
    _end_time: 'Loppu aika',
    _redeemed: 'Lunastettu summa',
    _surplus: 'ylijäämä',
    _wallet: 'Lompakko',
    _have: 'Tällä hetkellä pidetty',
    _input: 'syöttömäärä',
    _choose: 'Valitse',
    _new_wallet: 'Uusi valuuttatili',
    _new_price: 'Viimeisin hinta',
    _lock: 'Lukitusaika',
    _login: 'Kirjaudu sisään, ole hyvä',
    _no_data: 'Ei dataa',

    _avg_price: 'keskimääräinen hinta'
  }
}
