export default `<p>
    <strong>DCR (거부 됨)</strong>
</p>
<p>
    <br/>
</p>
<p>
    <strong>1. 프로젝트 이름</strong>
</p>
<p>
    Decred (DCR)
</p>
<p>
    <br/>
</p>
<p>
    <strong>2. 프로젝트 포지셔닝</strong>
</p>
<p>
    Decred는 Bitcoin과 유사하며 커뮤니티 입력, 개방형 거버넌스, 지속 가능한 자금 및 개발을 강조합니다. DCR은 고급 PoW + PoS 하이브리드 메커니즘을 사용하여 소수 사람들이 커뮤니티 입력없이 거래 프로세스를 지배하거나 Decred를 수정할 수 없도록합니다.
</p>
<p>
    <br/>
</p>
<p>
    <strong>3, 통화 개요 및 배포</strong>
</p>
<p>
    토큰 총량 : 21,000,000
</p>
<p>
    유통량 : 약 7,900,000 (각 신규 블록에 대해 생성 된 보상의 60 %는 PoW 채굴 자, 30 %는 PoS 투표자, 10 %는 Decred R &amp; D 보조금에 속합니다)
</p>
<p>
    에
</p>
<p>
    <strong>4, 일반적으로 사용되는 링크</strong>
</p>
<p>
    프로젝트 웹 사이트 : https://decred.org/
</p>
<p>
    백서 : https://decred.org/dtb001.pdf
</p>
<p>
    블록 쿼리 : https://mainnet.decred.org/
</p>
<p>
    에
</p>
<p>
    <strong>5. 통화가있는 구역</strong>
</p>
<p>
    혁신 존
</p>
<p>
    <br/>
</p>`