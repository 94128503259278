export default `<p>
    <strong>BHD（BitcoinHD（BHD））</strong>
</p>
<p>
    <br/>
</p>
<p>
    1.プロジェクト名
</p>
<p>
    BitcoinHD（BHD）
</p>
<p>
    に
</p>
<p>
    2.プロジェクトのポジショニング
</p>
<p>
    BHDは、条件付き容量証明に基づく新しいタイプの暗号通貨です。その主な革新は、ハードドライブをコンセンサス参加者として使用する新しいタイプの条件付き容量証明コンセンサスメカニズムを採用することです。これにより、誰もが暗号通貨をマイニングできます。 ハードディスクマイニングはBHDをより分散化させ、数学的アルゴリズムと分散マイニングを通じてクレジットと価値を生み出します。 BHDは、暗号通貨の生成方法を変える価値のある金融システムの構築に努めます。
</p>
<p>
    <br/>
</p>
<p>
    3、通貨の概要と流通
</p>
<p>
    総供給量：2100万個
</p>
<p>
    現在の循環：6,457,200
</p>
<p>
    に
</p>
<p>
    4つの一般的に使用されるリンク
</p>
<p>
    公式サイト：www.btchd.org
</p>
<p>
    ホワイトペーパー：www.btchd.org/BHD-ホワイトペーパー2.0.pdf
</p>
<p>
    ブロッククエリ：www.btchd.org/explorer/
</p>
<p>
    <br/>
</p>`