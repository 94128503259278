<template>
  <div id="dark">
    <div class="pub-layout-content-left-list  pt-0 pb-1 pr-0">
      <div class="pub-trade-list py-0 px-0  box">
        <div class="pub-trade-list-tabs tabs spottick-prz-text-2 is-background-3 px-3">
          <ul>
            <!-- <li :class="'spottick-text-color-3 ' + (isActive == 1?'is-active':'')" @click="onActive(1)"><a href="javascript:void(0);" class="spottick-text-color-3">当前持仓</a></li> -->
            <li :class="'spottick-text-color-3 ' + (isActive == Menu.current?'is-active':'')" @click="onActive(Menu.current)"><a href="javascript:void(0);" class="spottick-text-color-3 ">{{$t('kline.commission')}}</a></li>
            <li :class="'spottick-text-color-3 ' + (isActive == Menu.history?'is-active':'')" @click="onActive(Menu.history)"><a href="javascript:void(0);" class="spottick-text-color-3  is-hidden-touch">{{$t('kline.record')}}</a></li>
          </ul>
        </div>
        <div class="pub-pos">
          <div class="table-container">
            <div class="pub-table-body-box" v-if="isActive == Menu.current" style="overflow: scroll;max-height: 35rem;">
              <div class="table-button">
                <button :class="'t-button ' + (isButton == Btn.normal?'active':'')" @click="onButton(Btn.normal)">{{$t('record.normal')}}</button>
                <button :class="'t-button ' + (isButton == Btn.pending?'active':'')" @click="onButton(Btn.pending)">{{$t('record.pending')}}</button>
              </div>
              <div v-if="isButton == Btn.normal">
                <!-- 普通委托 -->
                <table cellpadding="0" cellspacing="0" class="table is-hoverable">
                  <thead>
                    <tr class="">
                      <th class="table-title-font-w" width="250">
                        <div class="">{{$t('kline.time')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.currency')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.type')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.price')}}({{currency.desc}})</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.number')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.fee')}}({{currency.desc}})</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.amount')}}({{currency.desc}})</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.status')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.operate')}}</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="" v-for="(item,index) in trade_list" :key="index">
                      <td>{{item.create_time}}</td>
                      <td>{{item.currency.name}}</td>
                      <td>{{item.type == 1?$t('kline.buy'):$t('kline.sell')}}</td>
                      <td>{{item.price}}</td>
                      <td>{{item.number}}</td>
                      <td>{{item.fee}}</td>
                      <td>{{item.usdt}}</td>
                      <td>{{item.status == 1?$t('kline.pending'):$t('kline.trade_success')}}</td>
                      <td>
                        <a v-if="item.status == 1" href="javascript:" @click="openRevoke(item,Btn.normal)">{{$t('kline.retract')}}</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="isButton == Btn.pending">
                <!-- 止盈止损 -->
                <table cellpadding="0" cellspacing="0" class="table is-hoverable">
                  <thead>
                    <tr class="">
                      <th class="table-title-font-w" width="250">
                        <div class="">{{$t('kline.time')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.currency')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.type')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.price')}}({{currency.desc}})</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.number')}}</div>
                      </th>
                      <!-- <th class="table-title-font-w">
                                            <div class="">{{$t('kline.fee')}}({{currency.desc}})</div>
                                        </th>
                                        <th class="table-title-font-w">
                                            <div class="">{{$t('kline.amount')}}({{currency.desc}})</div>
                                        </th> -->
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.status')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.operate')}}</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="" v-for="(item,index) in pending_list" :key="index">
                      <td>{{item.create_time}}</td>
                      <td>{{item.currency.name}}</td>
                      <td>{{item.type == 1?$t('kline.profit'):$t('kline.loss')}}</td>
                      <td>{{item.price}}</td>
                      <td>{{item.number}}</td>
                      <td>{{item.status == 1?$t('kline.pending'):$t('kline.trade_success')}}</td>
                      <td>
                        <a v-if="item.status == 1" href="javascript:" @click="openRevoke(item,Btn.pending)">{{$t('kline.retract')}}</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="pub-table-body-box" v-if="isActive == Menu.history" style="overflow: scroll;max-height: 35rem;">
              <div class="table-button">
                <button :class="'t-button ' + (isButton == Btn.normal?'active':'')" @click="onButton(Btn.normal)">{{$t('record.normal')}}</button>
                <button :class="'t-button ' + (isButton == Btn.pending?'active':'')" @click="onButton(Btn.pending)">{{$t('record.pending')}}</button>
              </div>

              <div v-if="isButton == Btn.normal">
                <!-- 普通委托 -->
                <table cellpadding="0" cellspacing="0" class="table is-hoverable">
                  <thead>
                    <tr class="">
                      <th class="table-title-font-w" width="250">
                        <div class="">{{$t('kline.time')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.currency')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.type')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.price')}}({{currency.desc}})</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.number')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.fee')}}({{currency.desc}})</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.amount')}}({{currency.desc}})</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.status')}}</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="" v-for="(item,index) in record_list" :key="index">
                      <td>{{item.create_time}}</td>
                      <td>{{item.currency.name}}</td>
                      <td>{{item.type == 3?$t('kline.buy'):$t('kline.sell')}}</td>
                      <td>{{item.price}}</td>
                      <td>{{item.number}}</td>
                      <td>{{item.fee}}</td>
                      <td>{{item.usdt}}</td>
                      <td>{{$t('kline.trade_success')}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="isButton == Btn.pending">
                <!-- 止盈止损 -->
                <table cellpadding="0" cellspacing="0" class="table is-hoverable">
                  <thead>
                    <tr class="">
                      <th class="table-title-font-w" width="250">
                        <div class="">{{$t('kline.time')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.currency')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.type')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.price')}}({{currency.desc}})</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.number')}}</div>
                      </th>
                      <!-- <th class="table-title-font-w">
                                            <div class="">{{$t('kline.fee')}}({{currency.desc}})</div>
                                        </th>
                                        <th class="table-title-font-w">
                                            <div class="">{{$t('kline.amount')}}({{currency.desc}})</div>
                                        </th> -->
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.status')}}</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="" v-for="(item,index) in record_pending" :key="index">
                      <td>{{item.create_time}}</td>
                      <td>{{item.currency.name}}</td>
                      <td>{{item.type == 1?$t('kline.profit'):$t('kline.loss')}}</td>
                      <td>{{item.price}}</td>
                      <td>{{item.number}}</td>
                      <!-- <td>{{item.fee}}</td>
                                        <td>{{item.usdt}}</td> -->
                      <td>{{$t('kline.trade_success')}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 提示信息 -->
    <el-dialog :title="$t('revoke.title')" :visible.sync="revokeVisible" :before-close="cancelRevoke" class="trade-dialog trade-dg" style="width:50%;margin: 0 auto;top: 20%;">
      <el-row>
        <!-- <el-col :span="24">
                <label style="padding: 10px 0;color:#FFF;">交易密码:</label>
            </el-col> -->
        <el-col :span="24" style="color:#FFF;">
          {{$t('revoke.message')}}
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelRevoke">{{$t('revoke.cancel')}}</el-button>
        <el-button @click="confirmRevoke">{{$t('revoke.submit')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getTradeCoinsList,
  getPendingCoinsList,
  getRecordCoinsList,
} from "@/api/user";
import { revokeCoinsTrade, revokeCoinsPending } from "@/api/currency";
import { Event } from "../../utils/helper";

const Menu = {
  current: 1,
  history: 2,
};

const Btn = {
  normal: 1,
  pending: 2,
};

export default {
  name: "CoinsRecord",
  data() {
    return {
      Menu,
      Btn,

      isActive: Menu.current,
      isButton: Btn.normal,

      currency: {},
      trade_list: [],
      pending_list: [],

      record_list: [],
      record_pending: [],

      //撤销
      revokeVisible: false,
      revokeItem: {},
      revokeType: 0,
    };
  },
  created() {
    console.log("CoinsRecord");
    this.checkToken();
  },
  mounted() {
    Event.$on("onCoinsTrade", () => {
      console.log("收到了");
      this.onUpdate();
    });
  },
  methods: {
    checkToken() {
      //获取缓存的token
      let token = localStorage.getItem("token");
      if (token) {
        this.token = token;
      } else {
        this.token = "";
      }
      return token;
    },
    /**
     * 撤回确认
     */
    openRevoke(item, type) {
      this.revokeItem = item;
      this.revokeType = type;
      this.revokeVisible = true;
    },
    cancelRevoke() {
      this.revokeVisible = false;
    },
    confirmRevoke() {
      console.log("确认撤回", this.revokeItem);
      this.revokeVisible = false;

      if (this.revokeType == Btn.normal) {
        //限价挂单
        revokeCoinsTrade({ id: this.revokeItem.id }).then((res) => {
          if (res.code == 1) {
            this.$message({
              message: res.msg,
              type: "error",
            });

            return;
          }
          this.$message({
            message: res.msg,
            type: "success",
          });

          //重新加载挂单
          this.getTradeCoinsList(1);
        });
      } else if (this.revokeType == Btn.pending) {
        //止盈止损
        //限价挂单
        revokeCoinsPending({ id: this.revokeItem.id }).then((res) => {
          if (res.code == 1) {
            this.$message({
              message: res.msg,
              type: "error",
            });

            return;
          }
          this.$message({
            message: res.msg,
            type: "success",
          });

          //重新加载挂单
          this.getPendingCoinsList(1);
        });
      }
    },

    onActive(id) {
      this.isActive = id;
      this.onButton(1);
    },
    onButton(id) {
      this.isButton = id;

      //加载对应的记录
      if (this.isActive == Menu.current) {
        if (this.isButton == Btn.normal) {
          //普通委托
          this.getTradeCoinsList(1);
        } else if (this.isButton == Btn.pending) {
          //止盈止损
          this.getPendingCoinsList(1);
        }
      }

      if (this.isActive == Menu.history) {
        if (this.isButton == Btn.normal) {
          //普通委托
          this.getRecordCoinsList();
        } else if (this.isButton == Btn.pending) {
          //止盈止损
          this.getPendingCoinsList(2);
        }
      }
    },
    /**
     * 设置币
     */
    setCurrency(currency) {
      this.currency = currency;
      this.onUpdate();
    },
    onUpdate() {
      //获取委托
      this.getTradeCoinsList(1);
      // this.getRecordCoinsList(2)
    },
    getTradeCoinsList(status) {
      if (!this.checkToken()) {
        return;
      }
      // this.$ui.openLoading()
      getTradeCoinsList({
        c_id: this.currency.id,
        status: status,
      })
        .then((res) => {
          this.$ui.closeLoading();
          this.trade_list = res.data.list;
        })
        .catch(() => {
          this.$ui.closeLoading();
        });
    },
    getPendingCoinsList(status) {
      if (!this.checkToken()) {
        return;
      }
      // this.$ui.openLoading()
      getPendingCoinsList({
        c_id: this.currency.id,
        status: status,
      })
        .then((res) => {
          this.$ui.closeLoading();
          if (status == 1) {
            this.pending_list = res.data.list;
          } else if (status == 2) {
            this.record_pending = res.data.list;
          }
        })
        .catch(() => {
          this.$ui.closeLoading();
        });
    },
    getRecordCoinsList() {
      if (!this.checkToken()) {
        return;
      }
      // this.$ui.openLoading()
      getRecordCoinsList({
        c_id: this.currency.id,
      })
        .then((res) => {
          this.$ui.closeLoading();
          this.record_list = res.data.list;
        })
        .catch(() => {
          this.$ui.closeLoading();
        });
    },
  },
};
</script>

<style src="../../../static/css/coins.css" scoped type="text/css"></style>
