export default `<h2 style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:48px">
<strong><span style="font-family: Times New Roman;font-size: 16px;background: rgb(255, 255, 255)">DCR (Decred)</span></strong>
</h2>
<p style=";padding: 0;line-height: 48px;background: rgb(255, 255, 255)">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: &#39;Times New Roman&#39;">1<span style="font-family:宋体">, project name</span></span></span> </strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">Decred (DCR)</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: &#39;Times New Roman&#39;"><span style="font-family:宋体">2. Project positioning</span></span></span> </strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">Decred<span style="font-family:宋体">Similar to Bitcoin, emphasizing community input, open governance, and sustainable funding and development . </span><span style="font-family:Calibri">DCR</span><span style="font-family:宋体">Using advanced</span><span style="font-family:Calibri ">PoW + PoS</span><span style="font-family:宋体"> a hybrid mechanism to ensure that the decimal person cannot dominate the transaction process or right without community input</span><span style="font -family:Calibri">Decred</span><span style="font-family:宋体"> to modify. </span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: &#39;Times New Roman&#39;"><span style="font-family:宋体">3. Currency overview and distribution</span></span> </strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">Total Tokens:</span> &nbsp;21,000,000</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">Circulation quantity:</span> <span style="font-family:宋体"> Approximately</span>7,900,000 (<span style="font-family:宋体">reward for each new block</span><span style="font-family:Calibri">60%</span><span style="font-family:宋体">Belong to</span><span style="font-family:Calibri">PoW</span><span style="font-family:宋体">Miner</span>< span style="font-family:Calibri">, 30%</span><span style="font-family:宋体">belongs to</span><span style="font-family:Calibri">PoS</span> span><span style="font-family:宋体">Voters</span><span style="font-family:Calibri">, 10%</span><span style="font-family:宋体" >Belongs to</span><span style="font-family:Calibri">Decred</span><span style="font-family:宋体">R&D</span><span style="font-family:Calibri ">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span style="font-family:宋体">subsidies</span><span style=" font-family:Calibri">)</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: &#39;Times New Roman&#39;"><span style="font-family:宋体">4. Common links</span></span></span></span> </strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">Project website</span>: &nbsp;&nbsp;</span><a href= "https://decred.org/"><span style=";font-family:&#39;Times New Roman&#39;;color:rgb(53,124,225)">https://decred.org/</ span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">White paper:</span></span><a href="https:// decred.org/dtb001.pdf"><span style=";font-family:&#39;Times New Roman&#39;;color:rgb(53,124,225)">https://decred.org/dtb001.pdf< /span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">Block query</span>: &nbsp;</span><a href=" https://mainnet.decred.org/"><span style=";font-family:&#39;Times New Roman&#39;;color:rgb(53,124,225)">https://mainnet.decred.org /</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: &#39;Times New Roman&#39;"><span style="font-family:宋体">V. The zone where the currency is located</span></span> </strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">Innovation District</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p>
<span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
<p>
<br/>
</p>`