export default `<p>
    <strong>XMR（モネロ）</strong>
</p>
<p>
    <br/>
</p>
<p>
    <strong>1.プロジェクト名</strong>
</p>
<p>
    モネロ（XMR）
</p>
<p>
    <br/>
</p>
<p>
    <strong>2.プロジェクトのポジショニング</strong>
</p>
<p>
    Moneroは暗号化を使用して、送信アドレスと受信アドレス、およびトランザクション量をシールドします。これは、ビットコインやイーサリアムなどの他のパブリックブロックチェーンとは異なります。
</p>
<p>
    に
</p>
<p>
    デフォルトでは、すべてのMoneroトランザクションは、送信アドレスと受信アドレス、およびトランザクション量を混同します。 Moneroは交換可能です。 これは、Moneroが精査のリスクなしに常に受け入れられることを意味します。
</p>
<p>
    に
</p>
<p>
    モンローは会社ではありません。 これは、世界中の暗号化と分散システムの専門家によって開発されており、時間を寄付したり、コミュニティからの寄付によって資金提供されています。 これは、モンローがどの国によっても閉鎖されることはなく、特定の法域によって制限されることもないことを意味します。
</p>
<p>
    に
</p>
<p>
    <strong>3、通貨の概要と流通</strong>
</p>
<p>
    合計トークン：約1600万XMR +産業および鉱業
</p>
<p>
    循環量：100％
</p>
<p>
    に
</p>
<p>
    <strong>4つの一般的に使用されるリンク</strong>
</p>
<p>
    プロジェクトのウェブサイト：https：//getmonero.org
</p>
<p>
    ブロッククエリ：https：//xmrchain.net; https://moneroblocks.info; https://moneroexplorer.com
</p>
<p>
    その他：https：//www.reddit.com/r/Monero
</p>
<p>
    に
</p>
<p>
    <strong>5.通貨が配置されているゾーン</strong>
</p>
<p>
    メインゾーン
</p>
<p>
    <br/>
</p>`