export default {
    title: "コイン情報",
    btcusdt: [
        {
            type: 1,
            name: "中国名",
            value: "ビットコイン",
        },
        {
            type: 1,
            name: "英語名",
            value: "ビットコイン",
        },
        {
            type: 1,
            name: "英語の略語",
            value: "BTC",
        },
        {
            type: 1,
            name: "開発者",
            value: "中本聡",
        },
        {
            type: 1,
            name: "コアアルゴリズム",
            value: "SHA-256",
        },
        {
            type: 1,
            name: "リリース日",
            value: "2009/01/03",
        },
        {
            type: 1,
            name: "合計金額",
            value: "2100万",
        },
        {
            type: 1,
            name: "ブロック時間",
            value: "約600秒/ブロック",
        },
        {
            type: 1,
            name: "コンセンサスの証明",
            value: "POW",
        },
        {
            type: 1,
            name: "リスク",
            value: "拡張紛争,フォークの潜在的なリスクがあります",
        },
        {
            type: 1,
            name: "通貨単位",
            value: "BTC / XBT",
        },
        {
            type: 2,
            name: "公式ウェブサイト",
            value: "https://bitcoin.org/zh_CN/",
        },
        {
            type: 2,
            name: "ビットコインFAQ",
            value: "https://bitcoin.org/zh_CN/faq",
        },
        {
            type: 2,
            name: "ブロッククエリ",
            value: "https://blockchain.info/",
        },
        {
            type: 2,
            name: "ビットコインフォーラム",
            value: "https://bitcointalk.org/",
        },
        {
            type: 2,
            name: "市場価値クエリWebサイト",
            value: "https://coinmarketcap.com/",
        },
        {
            type: 3,
            name: "もっと",
            value: "https://bitcoin.org/zh_CN/",
        },
    ],
    ethusdt: [
        {
            type: 1,
            name: "中国名",
            value: "イーサリアム",
        },
        {
            type: 1,
            name: "英語名",
            value: "イーサリアム",
        },
        {
            type: 1,
            name: "英語の略語",
            value: "ETH",
        },
        {
            type: 1,
            name: "開発者",
            value: "ヴィタリックブテリン",
        },
        {
            type: 1,
            name: "コアアルゴリズム",
            value: "Ethash",
        },
        {
            type: 1,
            name: "リリース日",
            value: "2015/3/20",
        },
        {
            type: 1,
            name: "ブロック時間",
            value: "約15〜17秒/ブロック",
        },
        {
            type: 1,
            name: "ブロック報酬",
            value: "5",
        },
        {
            type: 1,
            name: "Money Total",
            value: "7200万+ 1872万/年",
        },
        {
            type: 1,
            name: "主な機能",
            value: "デジタル通貨やスマートコントラクトなどの機能が含まれています",
        },
        {
            type: 2,
            name: "公式ウェブサイト",
            value: "https://www.ethereum.org/",
        },
        {
            type: 2,
            name: "ブロッククエリ",
            value: "https://etherscan.io/",
        },
    ],
    usdthusd: [
        {
            type: 2,
            name: "公式ウェブサイト",
            value: "https://tether.to/",
        },
        {
            type: 2,
            name: "ブロッククエリ",
            value: "https://www.omniexplorer.info/",
        }
    ],
    trxusdt: [
        {
            type: 2,
            name: "プロジェクトのウェブサイト",
            value: "https://tron.network",
        },
        {
            type: 2,
            name: "ブロッククエリ",
            value: "https://tronscan.org/#/",
        },
        {
            type: 3,
            name: "ホワイトペーパー",
            value: "https://s3.amazonaws.com/tron.network/%E7%AE%80%E4%BB%8B%E4%B8%8E%E6%84%BF%E6%99%AF.pdf",
        },
    ],
    bsvusdt: [
        {
            type: 1,
            name: "プロジェクト名",
            value: "ビットコインSV（BSV）",
        },
        {
            type: 1,
            name: "プロジェクトの位置付け",
            value: "ビットコインSVはビットコインキャッシュ（BCH）のフルノード実装です。ビットコインキャッシュ（BCH）マイニングの巨人CoinGeekや他のマイナーの要求に応じて,チームはビットコインSVを作成し,マイナーに明確なビットコインキャッシュ（BCH）の実現を提供しました。選択し,企業がその強固で信頼性の高い基盤上にアプリケーションとWebサイトを構築できるようにします",
        },
        {
            type: 1,
            name: "合計トークン",
            value: "21,000,000 BSV",
        },
        {
            type: 2,
            name: "プロジェクトサイト",
            value: "https://bitcoinsv.io/",
        },
    ],
    mkrusdt: [
        {
            type: 1,
            name: "プロジェクト名",
            value: "MakerDAO（MKR）",
        },
        {
            type: 1,
            name: "プロジェクトの位置付け",
            value: "MakerDAOは,分散型ファイナンス（DeFi）のインフラストラクチャであるEthereumで最初の分散型stablecoinであるDaiを開発しました。MKRはMakerシステムのガバナンスおよびエクイティトークンです",
        },
        {
            type: 1,
            name: "合計トークン",
            value: "1,005,577 MKR",
        },
        {
            type: 1,
            name: "Total Circulation",
            value: "1,005,577 MKR",
        },
        {
            type: 2,
            name: "プロジェクトサイト",
            value: "https://makerdao.com",
        },
        {
            type: 2,
            name: "ホワイトペーパー",
            value: "https://makerdao.com/whitepaper",
        },
        {
            type: 3,
            name: "ブロッククエリ",
            value: "https://etherscan.io/token/0x6b175474e89094c44da98b954eedeac495271d0f",
        },
    ],
    linkusdt: [
        {
            type: 1,
            name: "プロジェクト名",
            value: "リンク",
        },
        {
            type: 1,
            name: "発行総数",
            value: "1,000,000,000",
        },
        {
            type: 1,
            name: "現在の流通",
            value: "350,000,000",
        },
        {
            type: 1,
            name: "トークン価格",
            value: "$ 0.0914 USD",
        },
        {
            type: 2,
            name: "プロジェクトサイト",
            value: "https://www.smartcontract.com/",
        },
        {
            type: 2,
            name: "ホワイトペーパー",
            value: "https://link.smartcontract.com/whitepaper",
        }
    ],
    compusdt: [
        {
            type: 1,
            name: "合計トークン",
            value: "10,000,000 COMP",
        },
        {
            type: 1,
            name: "循環",
            value: "2,561,279 COMP",
        },
        {
            type: 1,
            name: "プロジェクトサイト",
            value: "https://compound.finance/",
        },
        {
            type: 3,
            name: "ホワイトペーパー",
            value: "https://compound.finance/documents/Compound.Whitepaper.pdf",
        },
        {
            type: 3,
            name: "ブロッククエリ",
            value: "https://etherscan.io/token/0xc00e94cb662c3520282e6f5717214004a7f26888",
        },
    ],
    xmrusdt: [
        {
            type: 1,
            name: "合計トークン",
            value: "約1600万XMR +産業および鉱業",
        },
        {
            type: 1,
            name: "循環量",
            value: "100％",
        },
        {
            type: 2,
            name: "プロジェクトサイト",
            value: "https://getmonero.org",
        },
        {
            type: 2,
            name: "ブロッククエリ",
            value: "https://xmrchain.net",
        },
        {
            type: 2,
            name: "その他のサイト",
            value: "https://www.reddit.com/r/Monero",
        },
    ],
    bchusdt: [
        {
            type: 1,
            name: "プロジェクト名",
            value: "ビットコインキャッシュノード（BCH）",
        },
        {
            type: 1,
            name: "プロジェクトの紹介",
            value: `BCH（ビットコインキャッシュ）は2020年11月15日にハードフォークアップグレードを受けます。 BCHNコンピューティング能力の現在の割合には,コミュニティとユーザーのフィードバックに基づいて絶対的な利点があるため,HuobiGlobalは次の手配を行います。
            1. BCHNノードスキームは,BCHの元の名前,Kライン,および取引ペアを継承します。フォーク中の市場センチメントにより大幅な変動が生じる可能性がありますので,事前に対応・リスク管理を行ってください。合理的な判断と慎重な意思決定。
            2. BCHフォークの後,Huobi GlobalはすべてのユーザーのBCHアセットのスナップショットを取り,フォークトークンをエアドロップします。フォークノードが安定して稼働した後,コミュニティのフィードバックに基づいてフォーク通貨取引ペアを開くかどうかを評価します。 `,
        },
        {
            type: 1,
            name: "合計トークン",
            value: "21,000,000 BCH",
        },
    ],
    ltcusdt: [
        {
            type: 1,
            name: "中国名",
            value: "ライトコイン",
        },
        {
            type: 1,
            name: "英語名",
            value: "ライトコイン",
        },
        {
            type: 1,
            name: "英語の略語",
            value: "LTC",
        },
        {
            type: 1,
            name: "開発者",
            value: "チャーリー・リー",
        },
        {
            type: 1,
            name: "コアアルゴリズム",
            value: "Scrypt",
        },
        {
            type: 1,
            name: "リリース日",
            value: "2011/10/7",
        },
        {
            type: 1,
            name: "ブロック時間",
            value: "150秒/ブロック",
        },
        {
            type: 1,
            name: "発行総数",
            value: "8400万",
        },
        {
            type: 1,
            name: "Half Time",
            value: "4年",
        },
        {
            type: 1,
            name: "コンセンサスの証明",
            value: "POW",
        },
        {
            type: 1,
            name: "難易度調整",
            value: "2016ブロック",
        },
        {
            type: 1,
            name: "ブロック報酬",
            value: "最初は50LTC,現在は25LTC",
        },
        {
            type: 2,
            name: "公式ウェブサイト",
            value: "https://litecoin.org/",
        },
        {
            type: 2,
            name: "ブロッククエリ",
            value: "http://explorer.litecoin.net/",
        },
    ],
    neousdt: [
        {
            type: 2,
            name: "公式ウェブサイト",
            value: "https://neo.org"
        },
        {
            type: 2,
            name: "ホワイトペーパー",
            value: "http://docs.neo.org/zh-cn/"
        },
    ],
    eosusdt: [
        {
            type: 2,
            name: "公式ウェブサイト",
            value: "https://eos.io/"
        },
        {
            type: 3,
            name: "ホワイトペーパー",
            value: "https://github.com/EOSIO/Documentation/blob/master/TechnicalWhitePaper.md#support-millions-of-users"
        },
    ],
    etcusdt: [
        {
            type: 2,
            name: "公式ウェブサイト",
            value: "https://ethereumclassic.org/"
        },
        {
            type: 2,
            name: "ホワイトペーパー",
            value: "https://github.com/etclabscore"
        },
        {
            type: 2,
            name: "ブロックエクスプローラー",
            value: "https://blockscout.com/etc/mainnet/"
        },
        {
            type: 2,
            name: "公式Twitter",
            value: "https://twitter.com/etclabs"
        },
        {
            type: 2,
            name: "Reddit",
            value: "https://www.reddit.com/r/EthereumClassic/"
        },
    ],
    zec: [
        {
            type: 2,
            name: "公式ウェブサイト",
            value: "https://z.cash/zh/"
        },
        {
            type: 3,
            name: "技術文書",
            value: "http://zerocash-project.org/media/pdf/zerocash-extended-20140518.pdf"
        },
    ],
    adausdt: [
        {
            type: 2,
            name: "公式ウェブサイト",
            value: "https://cardanofoundation.org"
        },
        {
            type: 2,
            name: "ホワイトペーパー",
            value: "whycardano.com"
        },
    ],
    dotusdt: [
        {
            type: 2,
            name: "公式ウェブサイト",
            value: "https://polkadot.network/"
        },
        {
            type: 2,
            name: "ホワイトペーパー",
            value: "https://polkadot.network/PolkaDotPaper.pdf"
        },
        {
            type: 2,
            name: "ブロッククエリ",
            value: "https://polkadot.js.org/apps/#/explorer"
        },
    ],
    trbusdt: [
        {
            type: 2,
            name: "公式ウェブサイト",
            value: "http://www.tellor.io"
        },
        {
            type: 2,
            name: "ホワイトペーパー",
            value: "http://www.tellor.io/whitepaper"
        },
        {
            type: 3,
            name: "ブロッククエリ",
            value: "https://explore.duneanalytics.com/public/dashboards/pVN6lRN8xxtfhjnEbcPbqreI8DNkPOng5P5fol0v"
        },
    ],
    bhdusdt: [
        {
            type: 2,
            name: "公式ウェブサイト",
            value: "http://www.tellor.io"
        },
        {
            type: 2,
            name: "ホワイトペーパー",
            value: "www.btchd.org/BHD-ホワイトペーパー2.0.pdf"
        },
        {
            type: 2,
            name: "ブロッククエリ",
            value: "www.btchd.org/explorer/"
        },
    ],
    qtumusdt: [
        {
            type: 2,
            name: "公式ウェブサイト",
            value: "https://www.qtum.org/zh/"
        },
        {
            type: 2,
            name: "ホワイトペーパー",
            value: "https://www.qtum.org/zh/white-papers"
        },
    ],
    bandusdt: [
        {
            type: 2,
            name: "公式ウェブサイト",
            value: "https://bandprotocol.com/"
        },
        {
            type: 2,
            name: "ホワイトペーパー",
            value: "https://docs.bandchain.org/whitepaper/"
        },
        {
            type: 2,
            name: "ブロッククエリ",
            value: "https://cosmoscan.io/"
        },
    ],
    xtzusdt: [
        {
            type: 2,
            name: "公式ウェブサイト",
            value: "https://tezos.com/"
        },
        {
            type: 2,
            name: "財団のウェブサイト",
            value: "https://tezos.foundation/"
        },
        {
            type: 2,
            name: "ホワイトペーパー",
            value: "https://tezos.com/static/papers/white_paper.pdf"
        },
        {
            type: 2,
            name: "ブロッククエリ",
            value: "https://tezblock.io/"
        },
    ],
    balusdt: [
        {
            type: 2,
            name: "公式ウェブサイト",
            value: "https://balancer.finance/"
        },
        {
            type: 2,
            name: "ホワイトペーパー",
            value: "https://balancer.finance/whitepaper/"
        },
        {
            type: 2,
            name: "ブロッククエリ",
            value: "https://etherscan.io/address/0xba100000625a3754423978a60c9317c58a424e3d"
        },
    ],
    imfusdt: [
        {
            type: 1,
            name: "英語名",
            value: "IMF",
        },
        {
            type: 2,
            name: "ホワイトペーパー",
            value: "https://starthestar.com/whitepaper/IMF-WhitePaper.pdf"
        }
    ],
}