<template>
  <div id="dark">
    <div class="pub-layout-content-left-list  pt-0 pb-1 pr-0">
      <div class="pub-trade-list py-0 px-0  box">
        <div class="pub-trade-list-tabs tabs spottick-prz-text-2 is-background-3 px-3" style="margin-bottom: 2px;">
          <div class="lever_zf">
            <div>{{$t('record.zzzqy')}}：{{u_total}}</div>
            <div>{{$t('record.zqy')}}：{{u_usable}}</div>
            <!-- <div>保证金：{{lever_bond}}</div> -->
            <!-- <div>{{$t('record.fdyk')}}：{{lever_profit}}</div> -->
            <div>{{$t('record.fxl')}}：{{lever_rate}}%</div>
          </div>
        </div>
        <div class="pub-trade-list-tabs tabs spottick-prz-text-2 is-background-3 px-3">
          <ul>
            <li :class="'spottick-text-color-3 ' + (isActive == Menu.wallet?'is-active':'')" @click="onActive(Menu.wallet)"><a href="javascript:void(0);" class="spottick-text-color-3">{{$t('record.wallet')}}</a></li>
            <li :class="'spottick-text-color-3 ' + (isActive == Menu.current?'is-active':'')" @click="onActive(Menu.current)"><a href="javascript:void(0);" class="spottick-text-color-3 ">{{$t('kline.commission')}}</a></li>
            <li :class="'spottick-text-color-3 ' + (isActive == Menu.history?'is-active':'')" @click="onActive(Menu.history)"><a href="javascript:void(0);" class="spottick-text-color-3  is-hidden-touch">{{$t('kline.record')}}</a></li>
          </ul>
        </div>
        <div class="pub-pos px-3">
          <div class="table-container">
            <div class="pub-table-body-box" v-if="isActive == Menu.wallet" style="overflow: scroll;max-height: 35rem;">
              <!-- 持仓明细 -->
              <table cellpadding="0" cellspacing="0" class="table is-hoverable">
                <thead>
                  <tr class="">
                    <th class="table-title-font-w">
                      <div class="">{{$t('kline.currency')}}</div>
                    </th>
                    <th class="table-title-font-w">
                      <div class="">{{$t('kline.direction')}}</div>
                    </th>
                    <th class="table-title-font-w">
                      <div class="">{{$t('kline.number')}}</div>
                    </th>
                    <th class="table-title-font-w">
                      <div class="">{{$t('kline.multiple')}}</div>
                    </th>
                    <th class="table-title-font-w">
                      <div class="">{{$t('kline.price')}}({{currency.desc}})</div>
                    </th>
                    <th class="table-title-font-w">
                      <div class="">{{$t('kline.xprice')}}({{currency.desc}})</div>
                    </th>
                    <th class="table-title-font-w" width="250">
                      <div class="">{{$t('kline.bzj')}}({{currency.desc}})</div>
                    </th>
                    <th class="table-title-font-w">
                      <div class="">{{$t('kline.amount')}}({{currency.desc}})</div>
                    </th>
                    <th class="table-title-font-w">
                      <div class="">{{$t('record.fdyk')}}({{currency.desc}})</div>
                    </th>
                    <th class="table-title-font-w">
                      <div class="">{{$t('kline.operate')}}</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="" v-for="(item,index) in leverList" :key="index">
                    <td>{{item.name}}</td>
                    <td>{{item.direction == 1?$t('kline.buy'):$t('kline.sell')}}</td>
                    <td>{{item.balance}}</td>
                    <td>{{item.buy_rate}}</td>
                    <td>{{item.buy_price}}</td>
                    <td>{{item.x_price}}</td>
                    <td>{{item.bond}}</td>
                    <td>{{item.real_usdt}}</td>
                    <td :style="'color:'+(item.profit>0?'#48c774':'#df294a')">{{item.profit}}</td>
                    <td>
                      <a href="javascript:" @click="openWind(item)">{{$t('wallet.liquidation')}}</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="pub-table-body-box" v-if="isActive == Menu.current" style="overflow: scroll;max-height: 35rem;">
              <div class="table-button">
                <button :class="'t-button ' + (isButton == Btn.normal?'active':'')" @click="onButton(Btn.normal)">{{$t('record.normal')}}</button>
                <button :class="'t-button ' + (isButton == Btn.pending?'active':'')" @click="onButton(Btn.pending)">{{$t('record.pending')}}</button>
              </div>
              <div v-if="isButton == Btn.normal">
                <!-- 普通委托 -->
                <table cellpadding="0" cellspacing="0" class="table is-hoverable">
                  <thead>
                    <tr class="">
                      <th class="table-title-font-w" width="250">
                        <div class="">{{$t('kline.time')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.currency')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.type')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.direction')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.multiple')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.price')}}({{currency.desc}})</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.number')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.fee')}}({{currency.desc}})</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('record.fdyk')}}({{currency.desc}})</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.amount')}}({{currency.desc}})</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.status')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.operate')}}</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="" v-for="(item,index) in trade_list" :key="index">
                      <td>{{item.create_time}}</td>
                      <td>{{item.currency.name}}</td>
                      <td>{{item.type == 1?$t('kline.buy'):$t('kline.sell')}}</td>
                      <td>{{item.direction == 1?$t('kline.buy'):$t('kline.sell')}}</td>
                      <td>{{item.buy_rate}}</td>
                      <td>{{item.price}}</td>
                      <td>{{item.number}}</td>
                      <td>{{item.fee}}</td>
                      <td :style="'color:'+(item.profit>0?'#48c774':'#df294a')">{{item.profit}}</td>
                      <td>{{item.usdt}}</td>
                      <td>{{item.status == 1?$t('kline.pending'):$t('kline.trade_success')}}</td>
                      <td>
                        <a v-if="item.status == 1" href="javascript:" @click="openRevoke(item,Btn.normal)">{{$t('kline.retract')}}</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="isButton == Btn.pending">
                <!-- 止盈止损 -->
                <table cellpadding="0" cellspacing="0" class="table is-hoverable">
                  <thead>
                    <tr class="">
                      <th class="table-title-font-w" width="250">
                        <div class="">{{$t('kline.time')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.currency')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.type')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.direction')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.price')}}({{currency.desc}})</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.number')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.status')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.operate')}}</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="" v-for="(item,index) in pending_list" :key="index">
                      <td>{{item.create_time}}</td>
                      <td>{{item.currency.name}}</td>
                      <td>{{item.type == 1?$t('kline.profit'):$t('kline.loss')}}</td>
                      <td>{{item.direction == 1?$t('kline.buy'):$t('kline.sell')}}</td>
                      <td>{{item.price}}</td>
                      <td>{{item.number}}</td>
                      <td>{{item.status == 1?$t('kline.pending'):$t('kline.trade_success')}}</td>
                      <td>
                        <a v-if="item.status == 1" href="javascript:" @click="openRevoke(item,Btn.pending)">{{$t('kline.retract')}}</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="pub-table-body-box" v-if="isActive == Menu.history" style="overflow: scroll;max-height: 35rem;">
              <div class="table-button">
                <button :class="'t-button ' + (isButton == Btn.normal?'active':'')" @click="onButton(Btn.normal)">{{$t('record.normal')}}</button>
                <button :class="'t-button ' + (isButton == Btn.pending?'active':'')" @click="onButton(Btn.pending)">{{$t('record.pending')}}</button>
              </div>
              <div v-if="isButton == Btn.normal">
                <!-- 普通委托 -->
                <table cellpadding="0" cellspacing="0" class="table is-hoverable">
                  <thead>
                    <tr class="">
                      <th class="table-title-font-w" width="250">
                        <div class="">{{$t('kline.time')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.currency')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.type')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.direction')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.multiple')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.price')}}({{currency.desc}})</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.number')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.fee')}}({{currency.desc}})</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('record.fdyk')}}({{currency.desc}})</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.amount')}}({{currency.desc}})</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.status')}}</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="" v-for="(item,index) in record_list" :key="index">
                      <td>{{item.create_time}}</td>
                      <td>{{item.currency.name}}</td>
                      <td>{{item.type == 1?$t('kline.buy'):$t('kline.sell')}}</td>
                      <td>{{item.direction == 1?$t('kline.buy'):$t('kline.sell')}}</td>
                      <td>{{item.buy_rate}}</td>
                      <td>{{item.price}}</td>
                      <td>{{item.number}}</td>
                      <td>{{item.fee}}</td>
                      <td :style="'color:'+(item.profit>0?'#48c774':'#df294a')">{{item.profit}}</td>
                      <td>{{item.usdt}}</td>
                      <td>{{$t('kline.trade_success')}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="isButton == Btn.pending">
                <!-- 止盈止损 -->
                <table cellpadding="0" cellspacing="0" class="table is-hoverable">
                  <thead>
                    <tr class="">
                      <th class="table-title-font-w" width="250">
                        <div class="">{{$t('kline.time')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.currency')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.type')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.direction')}}</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.price')}}({{currency.desc}})</div>
                      </th>
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.number')}}</div>
                      </th>
                      <!-- <th class="table-title-font-w">
                                            <div class="">{{$t('kline.fee')}}({{currency.desc}})</div>
                                        </th>
                                        <th class="table-title-font-w">
                                            <div class="">{{$t('kline.amount')}}({{currency.desc}})</div>
                                        </th> -->
                      <th class="table-title-font-w">
                        <div class="">{{$t('kline.status')}}</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="" v-for="(item,index) in record_pending" :key="index">
                      <td>{{item.create_time}}</td>
                      <td>{{item.currency.name}}</td>
                      <td>{{item.type == 1?$t('kline.profit'):$t('kline.loss')}}</td>
                      <td>{{item.direction == 1?$t('kline.buy'):$t('kline.sell')}}</td>
                      <td>{{item.price}}</td>
                      <td>{{item.number}}</td>
                      <!-- <td>{{item.fee}}</td>
                                        <td>{{item.usdt}}</td> -->
                      <td>{{$t('kline.trade_success')}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 提示信息 -->
    <el-dialog :title="$t('revoke.title')" :visible.sync="revokeVisible" :before-close="cancelRevoke" class="trade-dialog trade-dg" style="width:50%;margin: 0 auto;top: 20%;">
      <el-row>
        <!-- <el-col :span="24">
                <label style="padding: 10px 0;color:#FFF;">交易密码:</label>
            </el-col> -->
        <el-col :span="24" style="color:#FFF;">
          {{$t('revoke.message')}}
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelRevoke">{{$t('revoke.cancel')}}</el-button>
        <el-button @click="confirmRevoke">{{$t('revoke.submit')}}</el-button>
      </span>
    </el-dialog>

    <!-- 输入密码 -->
    <el-dialog :title="$t('trade.title')" :visible.sync="dialogVisible" :before-close="cancelTrade" class="trade-dialog trade-dg" style="width:50%;margin: 0 auto;top: 20%;">
      <el-row>
        <!-- <el-col :span="24">
                <label style="padding: 10px 0;color:#FFF;">交易密码:</label>
            </el-col> -->
        <el-col :span="24">
          <el-input v-model="trade_password" type="password" :placeholder="$t('trade.enter_password')" style="width:100%"></el-input>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelTrade">{{$t('trade.cancel')}}</el-button>
        <el-button @click="confirmTrade">{{$t('trade.submit')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getLever,
  getTradeLeverList,
  getPendingLeverList,
  getRecordLeverList,
} from "@/api/user";
import {
  revokeLeverTrade,
  revokeLeverPending,
  sellLever,
} from "@/api/currency";
import { Event, GetNewFixed } from "../../utils/helper";
const Menu = {
  wallet: 1,
  current: 2,
  history: 3,
};

const Btn = {
  normal: 1,
  pending: 2,
};

export default {
  name: "LeverRecord",
  data() {
    return {
      Menu,
      Btn,

      isActive: Menu.wallet,
      isButton: Btn.normal,

      currency: {},

      //持仓
      leverList: [],

      //挂单
      trade_list: [],
      pending_list: [],

      record_list: [],
      record_pending: [],

      //撤销
      revokeVisible: false,
      revokeItem: {},
      revokeType: 0,

      //总
      u_total: 0,
      u_usable: 0,
      u_bond: 0,
      lever_profit: 0,
      lever_rate: 0,

      //快捷平仓
      dialogVisible: false,
      trade_password: "",
      sellContract: {},
    };
  },
  created() {},
  mounted() {
    Event.$on("onCoinsTrade", () => {
      console.log("收到了");
      this.onUpdate();
    });
  },
  methods: {
    checkToken() {
      //获取缓存的token
      let token = localStorage.getItem("token");
      if (token) {
        this.token = token;
      } else {
        this.token = "";
      }
      return token;
    },
    /**
     * socket 钱包记录
     * @param {*} data
     */
    setWallet(res, u_total, u_usable) {
      this.u_total = u_total;
      this.u_usable = u_usable;

      //币币数量
      this.lever_rate = GetNewFixed(res.lever_rate * 100, 2);
      if (res.lever[this.currency.symbol]) {
        this.lever_profit = GetNewFixed(res.lever[this.currency.symbol].profit);
      } else {
        this.lever_profit = 0;
      }

      for (var i in this.leverList) {
        var symbol = this.leverList[i].symbol;
        if (res.lever[symbol]) {
          this.leverList[i].profit = GetNewFixed(res.lever[symbol].profit);
          this.leverList[i].balance = GetNewFixed(res.lever[symbol].balance);
          this.leverList[i].bond = GetNewFixed(res.lever[symbol].bond);
          this.leverList[i].real_usdt = GetNewFixed(
            res.lever[symbol].real_usdt
          );
        }
      }
    },
    setDetail(detail) {
      for (var i in this.leverList) {
        if (this.leverList[i].symbol == detail.symbol) {
          this.leverList[i].x_price = GetNewFixed(
            (detail.close - this.leverList[i].buy_price) *
              (this.leverList[i].direction == 1 ? 1 : -1),
            this.currency.decimal
          );
        }
      }
    },
    /**---------------------------------------
     * 快捷平仓
     */
    openWind(item) {
      this.sellContract = item;
      this.trade_password = "";
      // this.dialogVisible = true
      this.confirmTrade();
    },
    cancelTrade() {
      console.log("交易密码");
      this.dialogVisible = false;
    },
    confirmTrade() {
      console.log("确认交易密码");
      this.dialogVisible = false;
      this.doSell();
    },
    /**
     * 立即平仓
     */
    doSell() {
      //loading
      this.$ui.openLoading();

      sellLever({
        c_id: this.sellContract.c_id,
        direction: this.sellContract.direction,
        number: this.sellContract.balance,
        price: "0.00",
        price_type: 1,
        trade_pwd: this.trade_password,
        wallet_id: 0,
      })
        .then((res) => {
          //close
          this.$ui.closeLoading();
          if (res.code == 1) {
            this.$message({
              message: res.msg,
              type: "error",
            });
            return;
          }

          this.$message({
            message: res.msg,
            type: "success",
          });

          this.sellContract = {};
          this.trade_password = "";
          this.windSell = false;

          //重新加载杠杆
          this.getLever();
        })
        .catch(() => {
          //close
          this.$ui.closeLoading();
        });
    },
    /**
     * 撤回确认
     */
    openRevoke(item, type) {
      this.revokeItem = item;
      this.revokeType = type;
      this.revokeVisible = true;
    },
    cancelRevoke() {
      this.revokeVisible = false;
    },
    confirmRevoke() {
      console.log("确认撤回", this.revokeItem, this.revokeType);
      this.revokeVisible = false;

      if (this.revokeType == Btn.normal) {
        //限价

        revokeLeverTrade({
          id: this.revokeItem.id,
        }).then((res) => {
          if (res.code == 1) {
            this.$message({
              message: res.msg,
              type: "error",
            });

            return;
          }
          this.$message({
            message: res.msg,
            type: "success",
          });

          //重新加载挂单
          this.getTradeLeverList(1);
        });
      } else if (this.revokeType == Btn.pending) {
        //止盈止损
        revokeLeverPending({
          id: this.revokeItem.id,
        }).then((res) => {
          if (res.code == 1) {
            this.$message({
              message: res.msg,
              type: "error",
            });

            return;
          }
          this.$message({
            message: res.msg,
            type: "success",
          });

          //重新加载挂单
          this.getPendingLeverList(1);
        });
      }
    },
    onActive(id) {
      this.isActive = id;
      this.onButton(1);
    },
    onButton(id) {
      this.isButton = id;

      if (this.isActive == Menu.wallet) {
        //持仓明细
        this.getLever();
      }

      //加载对应的记录
      if (this.isActive == Menu.current) {
        if (this.isButton == Btn.normal) {
          //普通委托
          this.getTradeLeverList(1);
        } else if (this.isButton == Btn.pending) {
          //止盈止损
          this.getPendingLeverList(1);
        }
      }

      if (this.isActive == Menu.history) {
        if (this.isButton == Btn.normal) {
          //普通委托
          this.getRecordLeverList();
        } else if (this.isButton == Btn.pending) {
          //止盈止损
          this.getPendingLeverList(2);
        }
      }
    },
    /**
     * 设置币
     */
    setCurrency(currency) {
      this.currency = currency;
      this.onUpdate();
    },
    onUpdate() {
      //默认获取
      this.getLever();

      //获取委托
      // this.getTradeLeverList(1)

      // this.getRecordLeverList()
    },
    getTradeLeverList(status) {
      if (!this.checkToken()) {
        return;
      }
      getTradeLeverList({
        c_id: this.currency.id,
        status: status,
      }).then((res) => {
        this.trade_list = res.data.list;
      });
    },
    getPendingLeverList(status) {
      if (!this.checkToken()) {
        return;
      }
      // this.$ui.openLoading()
      getPendingLeverList({
        c_id: this.currency.id,
        status: status,
      })
        .then((res) => {
          this.$ui.closeLoading();
          if (status == 1) {
            this.pending_list = res.data.list;
          } else if (status == 2) {
            this.record_pending = res.data.list;
          }
        })
        .catch(() => {
          this.$ui.closeLoading();
        });
    },
    getRecordLeverList() {
      if (!this.checkToken()) {
        return;
      }
      getRecordLeverList({
        c_id: this.currency.id,
      }).then((res) => {
        this.record_list = res.data.list;
      });
    },
    /**
     * 获取钱包
     */
    getLever() {
      if (!this.checkToken()) {
        return;
      }
      let _this = this;

      //loading
      this.$ui.openLoading();

      getLever({
        c_id: this.currency.id,
      })
        .then((res) => {
          //close
          _this.$ui.closeLoading();
          //赋值
          _this.leverList = res.data;
        })
        .catch(() => {
          //close
          _this.$ui.closeLoading();
        });
    },
  },
};
</script>

<style src="../../../static/css/coins.css" scoped type="text/css"></style>
