import { render, staticRenderFns } from "./FeeHistoryInfo.vue?vue&type=template&id=4bc51fce&scoped=true&"
import script from "./FeeHistoryInfo.vue?vue&type=script&lang=js&"
export * from "./FeeHistoryInfo.vue?vue&type=script&lang=js&"
import style0 from "../../static/css/contract_info.css?vue&type=style&index=0&id=4bc51fce&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bc51fce",
  null
  
)

export default component.exports