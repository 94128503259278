export default {
    pt1: "1.ユーザーがSTARTHESTARにログインしたら、[アカウント情報]をクリックしてアカウント情報インターフェイスに入ります。",
    pimg1: "k3",
    pt2: "2.支払いパスワードフィールドの[設定]をクリックして、支払いパスワード設定インターフェイスに入ります;",
    pimg2: "k3",
    pt3: "3.パスワードを入力し、パスワードを再入力し、[送信]をクリックして支払いパスワードを正常に設定します。",
    pimg3: "k3",
    pt4: "4.アカウント情報インターフェイスでログインパスワードを変更し、[ログインパスワード]フィールドの[変更]をクリックして、ログインパスワード変更インターフェイスに入ります;",
    pimg4: "k3",
    pt5: "5.元のパスワードを入力し、次に新しいパスワードを入力し、新しいパスワードを確認し、[送信]をクリックしてログインパスワードの変更を完了します。",
    pimg5: "k3",
    pt6: "STARTHESTARはあなたに思い出させます:あなたのSTARTHESTARアカウントの支払いパスワードとログインパスワードを正しく保管してください。",
}