export default `<h2 style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:48px">
<strong><span style="font-family: 宋体;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 16px;background: rgb(255, 255, 255)">DOT (Polkadot（DOT）)</span></strong>
</h2>
<p style=";text-indent: 0;padding: 0;line-height: 48px;background: rgb(255, 255, 255)">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">一、项目名称</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">Polkadot（DOT）</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">二、项目定位</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">Polkadot将会实现一个完全去中心化的互联网，用户拥有完全控制权力。它构想的互联网是每个人的身份和数据是由自己来掌控 – 不受从任何中央机构的影响。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">Polkadot旨在通过连接私链、联盟链、公链、开放式网络和预言机以及尚未创建的未来技术。Polkadot为互联网提供便利，独立区块链可以通过Polkadot的中继链以无信任的方式交换信息和交易。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">Polkadot使创建/连接去中心化的应用程序，服务和 机构变得前所未有的简单。通过授权创新者建立更好 的解决方案，我们寻求使社会免于依赖破碎的网络， 其庞大的机构不能侵犯我们的信任。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">三、币概况与分配</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">代币总量：</span>1,000,000,000 DOT（拆分后）</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">流通总量：</span>-</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">代币价格：</span>-</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">四、常用链接</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">项目网站：</span></span><a href="https://polkadot.network/"><span style="font-family: 微软雅黑;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">https://polkadot.network/</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">白皮书：</span></span><a href="https://polkadot.network/PolkaDotPaper.pdf"><span style="font-family: 微软雅黑;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">https://polkadot.network/PolkaDotPaper.pdf</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">区块查询：</span></span><a href="#/explorer"><span style="font-family: 微软雅黑;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">https://polkadot.js.org/apps/#/explorer</span></a>
</p>
<p>
<span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
<p>
<br/>
</p>`