<template>
    <div class="wrapper">
        <swiper :options="swiperOptions">
            <swiper-slide v-for="(item,index) in imgs" :key="index">
                <img class="swiper-img" src="../assets/1.png" alt="">
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
    export default {
        name: 'carrousel',
        props:{
            imgs:Array
        },
        data() {
            return {
                swiperOptions: {
                    //autoplay:5000,
                    loop: true,
                    // spaceBetween: 10,
                    effect: 'coverflow',
                    slidesPerView: 2,
                    centeredSlides: true,
                    coverflowEffect: {
                        rotate: 0, //slide做3d旋转时Y轴的旋转角度。默认50。
                        stretch: 30, //每个slide之间的拉伸值（距离），越大slide靠得越紧。 默认0。
                        depth: 100, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
                        modifier: 2, //depth和rotate和stretch的倍率，相当于depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。
                        slideShadows: true, //是否开启slide阴影
                    },
                }
            }
        },
    }
</script>
<style scoped>
    .wrapper {
        margin: 0 auto;
        overflow: hidden;
    }

    .swiper-img {
        width: 100%;
    }

    .swiper-pagination {
        bottom: 100px !important;
        z-index: 999;
        position: absolute;
    }

    .swiper-button-prev {
        position: relative;
        float: left;
    }

    .swiper-button-next {
        position: relative;
        float: right;
    }
</style>