export default `<p>
    <strong>BHD (비트 코인 HD (BHD))</strong>
</p>
<p>
    <br/>
</p>
<p>
    1. 프로젝트 이름
</p>
<p>
    BitcoinHD (BHD)
</p>
<p>
    에
</p>
<p>
    2. 프로젝트 포지셔닝
</p>
<p>
    BHD는 Conditioned Proof Of Capacity 기반의 새로운 유형의 암호 화폐입니다. 주된 혁신은 하드 드라이브를 합의 참여자로 사용하여 모든 사람이 암호 화폐를 채굴 할 수 있도록 새로운 유형의 조건부 용량 증명 합의 메커니즘을 채택하는 것입니다. 하드 디스크 마이닝은 BHD를보다 분산화하여 수학적 알고리즘과 분산 마이닝을 통해 신용과 가치를 생성합니다. BHD는 암호 화폐 생산 방식을 바꾸는 가치있는 금융 시스템을 구축하기 위해 노력할 것입니다.
</p>
<p>
    <br/>
</p>
<p>
    세, 통화 개요 및 배포
</p>
<p>
    총 공급량 : 2,100 만 개
</p>
<p>
    현재 유통량 : 6,457,200
</p>
<p>
    에
</p>
<p>
    네, 일반적으로 사용되는 링크
</p>
<p>
    공식 웹 사이트 : www.btchd.org
</p>
<p>
    백서 : www.btchd.org/BHD-White Paper 2.0.pdf
</p>
<p>
    블록 쿼리 : www.btchd.org/explorer/
</p>
<p>
    <br/>
</p>`