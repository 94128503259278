export default {
    pt1: "1. 사용자가 STARTHESTAR에 로그인 한 후\"자산 \"을 클릭 한 다음\"내 지갑 \"을 클릭하여 내 자산 인터페이스로 들어갑니다.",
    pimg1: "k4",
    pt2: "2. My Assets 인터페이스에서\"Deposit Coins \",",
    pimg2: "k4",
    pt3: "3. 사용자가 입금 할 통화를 선택합니다. 예를 들어 사용자가 BTC를 입금하는 경우 BTC 통화를 선택하고 다른 통화에 해당하는 통화를 선택한 다음\"복사 \"를 클릭하거나 QR 코드를 스캔합니다.",
    pimg3: "k4",
    pt4: "4. 사용자는 복사 한 지갑 주소를 붙여 넣거나 QR 코드를 스캔하여 코인을 인출해야하는 플랫폼에 보냅니다. 인출 프로세스를 완료하고 체인에서 성공적으로 처리 될 때까지 기다립니다. 즉, 코인을 STARTHESTAR 플랫폼. (내 자산 인터페이스 계정 상황에서보기) ",
    pt5: "STARTHESTAR는 다음을 상기시킵니다 :",
    pt6: "① 코인을 입금 할 때 통화 종류에 해당하는 지갑 주소에주의하십시오. 잘못 입력하지 마십시오. 그렇지 않으면 자산이 회수되지 않습니다.",
    pt7: "②USDT 입금시 USDT (ERC20, TRC20, OMNI)에 해당하는 블록에주의를 기울이십시오. 오류를 입력하지 마십시오. 그렇지 않으면 자산이 회수되지 않습니다.",
}