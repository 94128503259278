export default `<p>
    <strong>QTUM（クォンタムチェーン）</strong>
</p>
<p>
    <br/>
</p>
<p>
    <strong>1.量子チェーンとは何ですか？</strong>
</p>
<p>
    <br/>
</p>
<p>
    Qtumブロックチェーン（「QuantumChain」または「Qtum」と呼ばれる）は、ポイントツーポイントの値転送を実現するための値転送プロトコル（「値転送プロトコル」）を通じて、ビットコインとイーサリアムに加えて、3番目のブロックチェーンエコシステムの開発に取り組んでいます。そして、この合意に従って、複数の業界（金融、モノのインターネット、サプライチェーン、ソーシャル、ゲームなどを含む）をサポートする分散型アプリケーション開発プラットフォーム（「DAppプラットフォーム」）を構築します。 改良版のビットコインのコアインフラストラクチャと互換性のあるバージョンのイーサリアム仮想マシンを統合することで、クォンタムチェーンはビットコインの破壊不可能なブロックチェーンネットワークを備えているだけでなく、スマートコントラクトの無限の可能性を秘めています。
</p>
<p>
    <br/>
</p>
<p>
    <strong>2.QTUMの機能</strong>
</p>
<p>
    <br/>
</p>
<p>
    Dapp使用権/トークン投票/コミュニティ自律/使用アプリケーション機能の消費/支払い。
</p>
<p>
    <br/>
</p>
<p>
    <strong>3.詳細なパラメータ</strong>
</p>
<p>
    <br/>
</p>
<p>
    トークンの合計：100,196,328 QTUM
</p>
<p>
    総循環：73,696,328 QTUM
</p>
<p>
    ICO価格：$ 0.38
</p>
<p>
    <br/>
</p>
<p>
    <strong>4.頻繁に使用されるリンク</strong>
</p>
<p>
    <br/>
</p>
<p>
    公式サイト：https：//www.qtum.org/zh/
</p>
<p>
    ホワイトペーパー：https：//www.qtum.org/zh/white-papers
</p>
<p>
    <br/>
</p>`