export default {
    title:"사용자 동의",
    content:`<p style="text-align: center;">
    <span style="font-size: 18px;"><strong>사용자 계약</strong></span>
</p>
<p>
    코디 글로벌
</p>
<p>
    <br/>
</p>
<p>
    Starthestar은 미래 지향적인 디지털 화폐 및 디지털 자산 유통 및 거래 플랫폼으로, 웹사이트 https://starthestar.com/(이하 &quot;본 웹사이트&quot; 또는 &quot;웹사이트&quot;라 함)을 운영하고 있습니다. Starthestar이 별도로 명시하지 않는 한 관련 서비스(이하 &quot;서비스&quot; 또는 &quot;서비스&quot;)를 제공하는 플랫폼, 제품, 기술, 소프트웨어, 프로그램, 데이터 및 웹사이트에 사용 및 게시된 기타 정보는 Starthestar Limited(이하 회사)에 귀속됩니다. 본 약관의 표현의 편의를 위해 회사와 본 웹사이트는 본 약관에서 &quot;당사&quot; 또는 기타 1인칭 대명사를 통칭하여 사용합니다.
</p>
<p>
    <br/>
</p>
<p>
    중요한 힌트:
</p>
<p>
    우리는 구체적으로 다음을 상기시킵니다.
</p>
<p>
    <br/>
</p>
<p>
    1 디지털 자산 자체는 금융 기관이나 정부에서 보증하지 않습니다. 디지털 자산 시장은 완전히 새롭고 명확하고 안정적인 기대가 없습니다.
</p>
<p>
    <br/>
</p>
<p>
    2 디지털 자산 거래에는 매우 높은 위험이 있으며 하루 종일 중단 없이 거래되고 기복에 제한이 없으며 가격이 크게 변동하는 경향이 있습니다.
</p>
<p>
    <br/>
</p>
<p>
    3 국가 법률, 규정 및 규범 문서의 제정 또는 개정으로 인해 디지털 자산 거래는 언제든지 중단되거나 금지될 수 있습니다. .
</p>
<p>
    <br/>
</p>
<p>
    디지털 자산 거래는 위험이 매우 높으며 대부분의 사람들에게 적합하지 않습니다. 귀하는 이 투자가 부분적 또는 전체적 손실을 초래할 수 있음을 이해하고 이해하므로 감당할 수 있는 손실 수준에 따라 투자 금액을 결정해야 합니다. 디지털 자산이 파생상품 위험을 발생시킨다는 점을 이해하고 이해했는지 확인하십시오. 또한, 위에서 언급한 위험 외에도 예상치 못한 위험이 있습니다. 디지털 자산을 구매하거나 판매하기로 결정하기 전에 신중하게 고려하고 명확한 판단을 사용하여 재정 상황과 위의 위험을 평가하고 결과적으로 발생하는 모든 손실을 감수해야 합니다. 이에 대해 당사는 어떠한 책임도 지지 않습니다. .
</p>
<p>
    <br/>
</p>
<p>
    1. 일반
</p>
<p>
    1.1 본 사이트에서 제공하는 서비스를 이용하시기 전에 본 약관을 잘 읽어보시고 이해가 되지 않거나 기타 필요한 사항이 있으시면 전문 변호사와 상담하시기 바랍니다. 언제든지 이 계약 및/또는 수정 사항에 동의하지 않는 경우 이 웹사이트에서 제공하는 서비스 사용을 중단하거나 즉시 이 웹사이트에 로그인하십시오. 이 웹사이트에 로그인하고 이 웹사이트의 서비스를 사용하거나 기타 유사한 행위를 하면 언제든지 이 웹사이트에서 이 계약을 수정하는 것을 포함하여 이 계약의 내용을 이해하고 완전히 동의하는 것입니다. .
</p>
<p>
    <br/>
</p>
<p>
    1.2 본 사이트의 회원(이하 &quot;회원&quot;이라 함)은 본 사이트의 요구사항에 따라 관련 정보를 기입한 후, 기타 관련 절차를 거쳐 회원가입을 하시면 됩니다. 귀하가 전자적으로 서명하거나 이 웹사이트를 사용하는 과정에서 &quot;동의&quot; 또는 이와 유사한 버튼을 클릭할 때 또는 이 웹사이트에서 허용하는 다른 방식으로 이 웹사이트에서 제공하는 서비스를 실제로 사용할 때 귀하는 완전히 이해하고 동의하며 이 계약의 모든 조건을 수락하며, 이 계약은 귀하의 자필 서명 없이는 법적 구속력에 영향을 미치지 않습니다.
</p>
<p>
    <br/>
</p>
<p>
    1.3 귀하가 본 웹사이트의 회원이 된 후, 귀하는 회원 계정과 해당 비밀번호를 얻게 되며, 회원 계정 및 비밀번호는 회원이 보관하며, 귀하의 계정과 관련된 모든 활동 및 이벤트에 대한 법적 책임은 회원에게 있습니다. .
</p>
<p>
    <br/>
</p>
<p>
    1.2 본 사이트의 회원(이하 &quot;회원&quot;이라 함)은 본 사이트의 요구사항에 따라 관련 정보를 기입한 후, 기타 관련 절차를 거쳐 회원가입을 하시면 됩니다. 귀하가 전자적으로 서명하거나 이 웹사이트를 사용하는 과정에서 &quot;동의&quot; 또는 이와 유사한 버튼을 클릭할 때 또는 이 웹사이트에서 허용하는 다른 방식으로 이 웹사이트에서 제공하는 서비스를 실제로 사용할 때 귀하는 완전히 이해하고 동의하며 이 계약의 모든 조건을 수락하며, 이 계약은 귀하의 자필 서명 없이는 법적 구속력에 영향을 미치지 않습니다. :
</p>
<p>
    <br/>
</p>
<p>
    1.4.1 본 계약의 모든 조건을 준수해야 합니다. .
</p>
<p>
    <br/>
</p>
<p>
    1.4.2 귀하는 다른 관련 법률에 따라 계약을 체결할 수 있는 법적 연령에 도달했으며 이 조건을 완전히 수락할 능력이 있음을 확인합니다. .
</p>
<p>
    <br/>
</p>
<p>
    1.4.3 귀하는 거래와 관련된 디지털 자산이 귀하에게 속한 합법적으로 취득 및 소유되었음을 보증합니다. .
</p>
<p>
    <br/>
</p>
<p>
    1.4.4 귀하는 자신의 거래 또는 비거래 행위 및 모든 손익에 대해 전적으로 책임을 진다는 데 동의합니다. .
</p>
<p>
    <br/>
</p>
<p>
    1.4.5 귀하는 등록 시 제공된 정보가 사실이고 정확함을 확인합니다. .
</p>
<p>
    <br/>
</p>
<p>
    1.4.6 귀하는 세금 목적으로 거래 이익 보고를 포함하여 모든 관련 법적 요구 사항을 준수하는 데 동의합니다. .
</p>
<p>
    <br/>
</p>
<p>
    1.4.7 이 계약은 귀하와 당사 간의 권리와 의무를 구속할 뿐이며, 이 웹사이트와 다른 웹사이트의 사용자와 귀하 간의 디지털 자산 거래로 인해 발생하는 법적 관계 및 법적 분쟁을 포함하지 않습니다. .
</p>
<p>
    <br/>
</p>
<p>
    2. 계약의 수정
</p>
<p>
    당사는 이 약관을 수시로 개정할 수 있으며 별도의 고지 없이 홈페이지 공지사항을 통하여 공지할 수 있으며 변경된 약관은 변경된 시간을 이 약관의 홈페이지에 표시하고 공시됩니다. 웹사이트에서는 자동으로 즉시 적용됩니다. 귀하는 수시로 본 계약의 업데이트 시간과 내용을 살펴보고 주의를 기울여야 하며, 관련 변경 사항에 동의하지 않을 경우 즉시 본 웹사이트의 서비스 사용을 중단해야 합니다. 웹사이트, 그것은 귀하가 개정된 계약을 수락하고 이에 구속되는 데 동의함을 의미합니다. .
</p>
<p>
    <br/>
</p>
<p>
    3. 등록
</p>
<p>
    3.1 등록 자격
</p>
<p>
    귀하는 등록 절차를 완료하거나 이 웹사이트에서 허용하는 다른 방식으로 이 웹사이트에서 제공하는 서비스를 실제로 사용할 때 이 계약에 서명하고 해당 법률에서 요구하는 대로 이 웹사이트의 서비스를 사용할 수 있어야 합니다. 자연인, 법인 또는 기타 조직. 등록 동의 버튼을 클릭하면 귀하 또는 귀하의 권한 있는 대리인이 본 계약의 내용에 동의하고 대리인이 이 웹사이트의 서비스를 등록 및 사용했음을 의미합니다. 귀하가 앞서 언급한 주제 자격이 없는 경우 귀하와 귀하의 수권 대리인은 그로 인해 발생하는 모든 결과를 부담해야 하며 회사는 귀하의 계정을 취소하거나 영구적으로 동결하고 귀하와 귀하의 수권 대리인에게 책임을 물을 수 있는 권리를 보유합니다. .
</p>
<p>
    <br/>
</p>
<p>
    3.2 등록 목적
</p>
<p>
    귀하는 이 웹사이트에 등록하는 것이 법률 및 규정을 위반하거나 이 웹사이트의 디지털 자산 거래 질서를 방해할 목적이 아님을 확인하고 약속합니다. .
</p>
<p>
    <br/>
</p>
<p>
    3.3 등록 절차
</p>
<p>
    3.3.1 귀하는 이 웹사이트의 사용자 등록 페이지의 요구 사항에 따라 유효한 전자 메일, 휴대 전화 번호 및 기타 정보를 제공하는 데 동의합니다.이메일 주소, 제공 또는 확인하는 휴대 전화 번호 또는 기타 허용하는 방법을 사용할 수 있습니다. 이 웹사이트는 이 웹사이트에 들어가기 위한 로그인 수단입니다. 필요한 경우 다른 관할 지역의 관련 법률 및 규정에 따라 법률 및 규정, 개인 정보 보호 조항 및 자금 세탁 방지 조항에 규정된 귀하의 실명, 신원 문서 및 기타 관련 정보를 제공하고 등록 정보를 지속적으로 업데이트해야 합니다. 시의 적절하고 상세하며 정확한 정보에 따라 요구합니다. 원래 입력된 모든 데이터는 등록된 데이터로 인용됩니다. 귀하는 그러한 정보의 진실성, 완전성 및 정확성에 대해 책임을 져야 하며, 그로부터 발생하는 직간접적인 손실 및 불리한 결과를 부담해야 합니다. .
</p>
<p>
    <br/>
</p>
<p>
    3.3.2 귀하의 주권 국가 또는 지역의 법률, 규정, 규칙, 명령 및 기타 규범이 휴대폰 실명을 요구하는 경우, 귀하는 등록된 휴대폰 번호가 실명 등록으로 등록된다는 데 동의합니다. 불리한 결과는 귀하가 부담해야 합니다. .
</p>
<p>
    <br/>
</p>
<p>
    3.3.3 귀하는 등록에 필요한 정보를 적법하고 완전하며 효과적으로 제공하고 확인된 경우 이 웹사이트의 계정 번호와 비밀번호를 얻을 권리가 있습니다.본 웹사이트의 계정 번호와 비밀번호를 취득하면 등록은 성공적인 것으로 간주되며 이 웹사이트에서 회원으로 로그인할 수 있습니다. .
</p>
<p>
    <br/>
</p>
<p>
    3.3.4 귀하는 이 웹사이트에서 이 웹사이트의 관리 및 운영과 관련된 이메일 및/또는 단문 메시지를 수신하는 데 동의합니다. .
</p>
<p>
    <br/>
</p>
<p>
    4. 서비스
</p>
<p>
    이 웹사이트는 이 웹사이트를 통한 귀하의 디지털 자산 거래 활동(디지털 자산 거래 및 기타 서비스를 포함하되 이에 국한되지 않음)을 위한 온라인 거래 플랫폼 서비스만을 제공합니다. 이 웹사이트는 구매자 또는 판매자로서 디지털 자산을 사고 파는 행위에 참여하지 않습니다. . .
</p>
<p>
    <br/>
</p>
<p>
    4.1 서비스 내용
</p>
<p>
    4.1.1 귀하는 이 웹사이트에서 다양한 디지털 자산 제품의 실시간 시장 및 거래 정보를 열람할 수 있는 권리가 있으며, 이 웹사이트를 통해 디지털 자산 거래 지침을 제출하고 디지털 자산 거래를 완료할 권리가 있습니다. .
</p>
<p>
    <br/>
</p>
<p>
    4.1.2 귀하는 이 웹사이트에 게시된 활동 규칙에 따라 이 웹사이트에서 조직한 웹사이트 활동에 참여할 권리가 있습니다. .
</p>
<p>
    <br/>
</p>
<p>
    4.1.3 이 웹사이트가 귀하에게 제공하기로 약속한 기타 서비스. .
</p>
<p>
    <br/>
</p>
<p>
    4.2 서비스 규정
</p>
<p>
    귀하는 이 웹사이트의 다음 서비스 규칙을 준수할 것을 약속합니다.
</p>
<p>
    <br/>
</p>
<p>
    4.2.1 귀하는 귀하의 계정에 있는 모든 디지털 자산 소스의 합법성을 보장하기 위해 법률, 규정, 규칙 및 정책의 요구 사항을 준수해야 하며 이 웹사이트의 권리와 이익을 손상시키는 불법 또는 기타 활동에 관여해서는 안 됩니다. 법률, 규정을 위반하거나 타인의 권리와 이익을 침해하는 정보를 보내거나 받는 것, 피라미드 방식이나 기타 유해한 정보나 발언을 보내거나 받는 것, 이 웹사이트에서 또는 이 웹사이트의 서비스를 사용하는 제3자, 사용하거나 위조하는 행위 이 웹사이트의 승인 없이 이 웹사이트의 이메일 헤더 정보.
</p>
<p>
    <br/>
</p>
<p>
    4.2.2 귀하는 법령을 준수하고, 웹사이트 계정 및 로그인 비밀번호, 펀드 비밀번호, 등록 시 결합된 휴대전화 번호를 적절하게 사용하고 유지해야 하며, 수신한 휴대전화 인증 코드의 보안을 유지해야 합니다. 휴대 전화. 귀하는 웹사이트 계정 및 로그인 비밀번호, 펀드 비밀번호, 휴대폰 인증 코드 사용으로 인한 모든 운영 및 결과에 대해 전적으로 책임을 집니다. 이 웹사이트의 계좌번호, 로그인 비밀번호, 펀드 비밀번호 또는 인증 코드가 승인되지 않은 제3자에 의해 사용되는 것을 발견하거나 기타 계정 보안 문제가 있음을 발견한 경우, 귀하는 즉시 이 웹사이트에 통지하고 이 웹사이트의 정지를 요청해야 합니다. 이 웹사이트 계정의 서비스 . 이 웹사이트는 합리적인 시간 내에 귀하의 요청에 대해 조치를 취할 권리가 있지만, 이 웹사이트는 조치를 취하기 전에 발생한 결과(귀하의 손실을 포함하되 이에 국한되지 않음)에 대해 어떠한 책임도 지지 않습니다. 귀하는 이 웹사이트의 동의 없이 이 웹사이트의 계정을 타인에게 양도, 대여, 임대, 양도 또는 처분할 수 없습니다. .
</p>
<p>
    <br/>
</p>
<p>
    4.2.3 귀하는 모든 활동(정보 공개, 정보 게시, 다양한 규칙 및 계약에 동의하거나 제출하기 위한 온라인 클릭, 계약 또는 서비스 구매 서비스의 온라인 갱신 등을 포함하되 이에 국한되지 않음)에 대한 책임이 귀하에게 있다는 데 동의합니다. .
</p>
<p>
    <br/>
</p>
<p>
    4.2.4 이 웹사이트에서 디지털 자산 거래를 할 때 디지털 자산 거래의 정상적인 수행을 악의적으로 방해하거나 거래 질서를 방해해서는 안 되며, 이 웹사이트의 정상적인 운영을 방해하거나 서비스 이용을 방해해서는 안 됩니다. 기술적 수단 또는 기타 수단을 통해 다른 사용자가 이 웹사이트를 사용하는 행위, 가상의 사실로 이 웹사이트의 선의를 악의적으로 비방하지 마십시오. .
</p>
<p>
    <br/>
</p>
<p>
    4.2.5 온라인 거래로 인해 다른 사용자와 분쟁이 있는 경우 사법 또는 행정 채널을 통해 관련 정보를 제공하도록 이 웹사이트에 요청해서는 안 됩니다. .
</p>
<p>
    <br/>
</p>
<p>
    4.2.6 귀하는 이 웹사이트에서 제공하는 서비스를 사용하는 동안 발생하는 모든 관련 세금과 모든 하드웨어, 소프트웨어, 서비스 및 기타 비용에 대해 단독으로 책임을 져야 합니다. .
</p>
<p>
    <br/>
</p>
<p>
    4.2.7 귀하는 본 웹사이트에서 수시로 게시 및 업데이트하는 본 계약 및 기타 서비스 약관 및 운영 규칙을 준수해야 하며 언제든지 본 웹사이트에서 제공하는 서비스의 사용을 종료할 수 있는 권리가 있습니다. .
</p>
<p>
    <br/>
</p>
<p>
    4.3 제품 규칙
</p>
<p>
    4.3.1 토큰 거래 상품 규칙
</p>
<p>
    <br/>
</p>
<p>
    귀하는 거래를 위해 이 웹사이트에 들어갈 때 다음 거래 규칙을 준수하고 이 웹사이트를 통해 다른 사용자와 통화 거래를 할 것을 약속합니다.
</p>
<p>
    <br/>
</p>
<p>
    4.3.1.1 거래 정보 찾아보기
</p>
<p>
    이 웹사이트에서 통화 거래 정보를 검색할 때 거래 정보에 포함된 모든 내용을 주의 깊게 읽어야 합니다. 여기에는 가격, 수수료 금액, 수수료, 매수 또는 매도 방향이 포함되지만 이에 국한되지 않습니다. 거래 정보에서 내용 후 버튼을 클릭하여 거래할 수 있습니다. .
</p>
<p>
    <br/>
</p>
<p>
    4.3.1.2 주문 제출
</p>
<p>
    거래 정보를 확인하고 정확한지 확인한 후 거래 주문을 제출할 수 있습니다. 귀하가 거래 위탁을 제출한 후, 귀하는 이 웹사이트가 귀하를 대신하여 해당 거래 매칭을 수행하도록 승인합니다.이 웹사이트는 사전 통지 없이 귀하의 위탁 가격에 맞는 거래가 있을 경우 매칭 거래를 자동으로 완료합니다. .
</p>
<p>
    <br/>
</p>
<p>
    4.3.1.3 거래 내역 보기
</p>
<p>
    관리센터의 거래내역에서 해당 거래내역을 확인하여 내역을 확인할 수 있습니다.
</p>
<p>
    <br/>
</p>
<p>
    4.3.1.4 주문을 취소/수정하려면 주문이 거래에 도달하기 전에 언제든지 주문을 취소하거나 수정할 권리가 있습니다.
</p>
<p>
    <br/>
</p>
<p>
    V. 이 웹사이트의 권리와 의무
</p>
<p>
    5.1 귀하가 본 약관에 규정된 등록 자격이 없을 경우, 본 웹사이트는 귀하의 등록을 거부할 권리가 있습니다.이미 등록한 경우 본 웹사이트는 귀하의 회원 계정을 취소할 권리가 있습니다. 본 웹사이트는 귀하를 보유할 권리를 보유합니다. 또는 책임 있는 권한을 부여받은 대리인 책임에 대한 권리. 동시에 이 웹사이트는 다른 상황에서 귀하의 등록을 수락할지 여부를 결정할 권리가 있습니다. .
</p>
<p>
    <br/>
</p>
<p>
    5.2 이 웹사이트는 계정 사용자가 계정의 초기 등록자가 아님을 발견한 경우 계정 사용을 일시 중지하거나 종료할 수 있는 권한이 있습니다. .
</p>
<p>
    <br/>
</p>
<p>
    5.3 이 웹사이트는 기술 테스트, 수동 샘플링 및 기타 테스트 방법을 통해 귀하가 제공한 정보가 잘못되었거나, 부정확하거나, 유효하지 않거나 불완전하다고 합리적으로 의심되는 경우 정보를 수정 또는 업데이트하거나 제공을 중단 또는 종료하도록 통지할 권리가 있습니다. 이 웹사이트의 서비스. .
</p>
<p>
    <br/>
</p>
<p>
    5.4 이 웹사이트는 명백한 오류를 발견한 경우 이 웹사이트에 표시된 정보를 수정할 권리가 있습니다. .
</p>
<p>
    <br/>
</p>
<p>
    5.5 본 사이트는 착오거래가 발견된 경우 착오거래의 집행자 및/및 수취인(수익자)을 확인할 권리가 있으며, 착오거래로 확인된 경우 착오거래의 수취인(수익자) 이 웹사이트는 수신한 거래 내용을 반환하며, 수령인(수혜자)이 이 웹사이트 또는 직원으로부터 통지를 받은 후 협력하지 않는 경우, 이 웹사이트는 복구를 포함하되 그렇지 않은 관련 조치를 취할 권리가 있습니다. 이에 한함 이 약관은 중재를 개시하거나 사법적 강제조치(보존 및 압수 등)를 하기로 약정하고, 수령인(수익자)이 반환을 거부할 뿐만 아니라 통지를 받은 후 악의적으로 잘못된 거래 내용을 양도 또는 판매하는 경우 이 웹사이트 또는 직원으로부터, 이 웹사이트는 앞서 언급한 조치를 취하는 것 외에도 잘못된 거래의 내용에 따라 원래 결정된 통화 및 수량을 수령인(수익자)에게 반환하도록 요구하고 요구할 권리가 있습니다. 수취인(수취인)이 양도·판매한 부가가치 부분을 양도·판매하게 하고, 부당이득의 기타 과실과 함께 반환한다.
</p>
<p>
    <br/>
</p>
<p>
    5.6 이 웹사이트는 언제든지 이 웹사이트의 서비스를 수정, 중단 또는 종료할 수 있는 권리를 보유하며, 이 웹사이트는 서비스를 수정하거나 중단할 권리를 행사하기 위해 사전에 귀하에게 통지할 필요가 없습니다. 종료 통지. .
</p>
<p>
    <br/>
</p>
<p>
    5.7 본 사이트는 본 사이트가 정상적으로 운영될 수 있도록 필요한 기술적 수단과 관리적 대책을 강구하며, 디지털 자산 매매의 질서를 유지하기 위하여 필요하고 신뢰할 수 있는 거래 환경과 거래 서비스를 제공합니다. .
</p>
<p>
    <br/>
</p>
<p>
    5.8 이 웹 사이트는 기술 투자를 강화하고 보안 예방 조치 및 기타 조치를 개선하여 디지털 자산의 보안을 보호하며 귀하의 계정에 예측 가능한 보안 위험이 있는 경우 사전에 귀하에게 통지할 의무가 있습니다. .
</p>
<p>
    <br/>
</p>
<p>
    5.9 이 웹사이트는 귀하의 주권 국가 또는 지역의 법률, 규정, 규칙, 명령 및 기타 규범의 요구 사항에 따라 추가 정보 또는 자료를 제공하고 현지 규범의 요구 사항을 충족하기 위해 합리적인 조치를 취하도록 요청할 권리가 있습니다. 귀하는 협조할 의무가 있습니다; 이 웹사이트는 귀하의 주권자의 법률, 규정, 규칙, 명령 및 기타 규범의 요구 사항에 따라 귀하에 대한 이 웹사이트 및 서비스의 일부 또는 전부를 일시 중단하거나 영구적으로 중지할 권리가 있습니다. 국가 혹은 지역. .
</p>
<p>
    <br/>
</p>
<p>
    6. 보상
</p>
<p>
    6.1 어떠한 경우에도 귀하의 직접적인 손해에 대한 당사의 책임은 귀하가 삼(3) 개월 동안 웹사이트 서비스를 사용한 총 비용을 초과하지 않습니다.
</p>
<p>
    <br/>
</p>
<p>
    6.2 이 약관 또는 기타 법령에 위반되는 행위를 하는 경우, 귀하는 당사에 최소 200만 달러를 보상하고 이로 인해 발생하는 모든 비용(변호사 수임료 등 포함)을 부담해야 하며, 실제 손실을 충당하기에 충분하지 않은 경우 완료해야 합니다. .
</p>
<p>
    <br/>
</p>
<p>
    7. 금지명령 구제를 요청할 권리
</p>
<p>
    당사와 귀하는 계약 위반 또는 위반 가능성에 대한 관습법 구제가 당사가 입은 모든 손실을 충당하기에 충분하지 않을 수 있음을 인정하므로 불이행하지 않은 당사자는 다음과 같은 경우 금지 명령 구제 및 관습법 또는 형평법적 구제를 요청할 권리가 있습니다. 법이 허용하는 기타 모든 구제책을 위반하거나 위반할 가능성이 있는 경우. .
</p>
<p>
    <br/>
</p>
<p>
    8. 책임의 제한 및 부인
</p>
<p>
    8.1 귀하는 어떠한 경우에도 당사가 다음에 대해 책임을 지지 않음을 이해하고 동의합니다.
</p>
<p>
    <br/>
</p>
<p>
    8.1.1 소득 손실
</p>
<p>
    <br/>
</p>
<p>
    8.1.2 거래 이익 또는 계약 손실;
</p>
<p>
    <br/>
</p>
<p>
    8.1.3 서비스 중단
</p>
<p>
    <br/>
</p>
<p>
    8.1.4 예상되는 금전적 절감의 손실;
</p>
<p>
    <br/>
</p>
<p>
    8.1.5 정보의 손실
</p>
<p>
    <br/>
</p>
<p>
    8.1.6 기회, 영업권 또는 평판의 상실
</p>
<p>
    <br/>
</p>
<p>
    8.1.7 데이터 손상 또는 손실
</p>
<p>
    <br/>
</p>
<p>
    8.1.8 대체 제품 또는 서비스 구매 비용
</p>
<p>
    <br/>
</p>
<p>
    8.1.9 불법 행위(과실 포함), 계약 위반 또는 기타 원인으로 인해 발생하는 모든 간접적, 특별 또는 우발적 손실 또는 손해, 그러한 손실 또는 손해가 당사에서 합리적으로 예측할 수 있는지 여부, 그러한 손실이나 손상의 가능성이 존재합니다.
</p>
<p>
    <br/>
</p>
<p>
    8.1.1~8.1.9항은 서로 독립적입니다.
</p>
<p>
    <br/>
</p>
<p>
    8.2 귀하는 당사가 다음 상황에서 발생하는 귀하의 손해에 대해 책임을 지지 않는다는 점을 이해하고 동의합니다.
</p>
<p>
    <br/>
</p>
<p>
    8.2.1 당사는 귀하의 특정 거래에 중대한 법률 위반 또는 계약 위반이 있을 수 있다고 믿을 만한 합리적인 근거가 있습니다. .
</p>
<p>
    <br/>
</p>
<p>
    8.2.2 우리는 이 웹사이트에서의 귀하의 행위가 불법적이거나 부적절하다고 의심되는 합리적인 근거가 있습니다. .
</p>
<p>
    <br/>
</p>
<p>
    8.2.3 이 웹사이트의 서비스를 통한 데이터, 정보 또는 거래의 구매 또는 획득 또는 대체 행위로 인해 발생하는 비용 및 손실. .
</p>
<p>
    <br/>
</p>
<p>
    8.2.4 이 웹사이트의 서비스에 대한 귀하의 오해.
</p>
<p>
    <br/>
</p>
<p>
    8.2.5 이 웹사이트에서 제공하는 서비스와 관련하여 당사에 의해 야기되지 않은 기타 모든 손실. .
</p>
<p>
    <br/>
</p>
<p>
    8.3 우리는 정보 네트워크 장비의 유지 보수, 정보 네트워크 연결 장애, 컴퓨터, 통신 또는 기타 시스템 장애, 정전, 날씨, 사고, 파업, 노동 분쟁, 폭동, 봉기, 폭동, 생산성 또는 생산 자재 부족, 화재, 홍수, 폭풍, 폭발, 전쟁, 은행 또는 기타 파트너 사유, 디지털 자산 시장의 붕괴, 정부 조치, 사법 또는 행정 명령, 당사가 통제하거나 통제할 수 없는 기타 행위, 또는 제3자 당사는 가정하지 않습니다. 귀하의 손실뿐만 아니라 서비스를 제공할 수 없거나 서비스를 지연할 수 없는 것에 대한 모든 책임. .
</p>
<p>
    <br/>
</p>
<p>
    8.4 이 웹사이트에 포함된 모든 정보, 프로그램, 텍스트 등이 완전히 안전하다고 보장할 수 없으며 바이러스, 트로이 목마 등과 같은 악성 프로그램에 의해 방해되거나 파괴되지 않습니다. 이 웹사이트의 모든 서비스 또는 웹사이트에서 다운로드한 모든 프로그램을 다운로드 및 사용합니다. , 정보, 데이터 등은 모두 귀하의 개인적인 결정이며 귀하 자신의 위험 및 가능한 손실을 부담해야 합니다. .
</p>
<p>
    <br/>
</p>
<p>
    8.5 당사는 이 웹사이트에 링크된 제3자 웹사이트의 정보, 제품 및 비즈니스 또는 당사 주제에 속하지 않는 기타 콘텐츠에 대해 어떠한 보증 및 약속도 하지 않습니다. -당사 홈페이지 및 상품 등은 개인의 판단이며 이로 인해 발생하는 모든 책임은 본인에게 있습니다. .
</p>
<p>
    <br/>
</p>
<p>
    8.6 우리는 이 웹사이트에서 제공하는 서비스의 적용 가능성, 오류 또는 누락의 부재, 연속성, 정확성, 신뢰성 및 적합성을 포함하되 이에 국한되지 않는 이 웹사이트의 서비스 사용에 대해 명시적 또는 묵시적 보증을 하지 않습니다. 특정 목적을 위해. 동시에 우리는 이 웹사이트에서 제공하는 서비스와 관련된 기술 및 정보의 유효성, 정확성, 정확성, 신뢰성, 품질, 안정성, 완전성 및 적시성에 대해 어떠한 약속과 보증도 하지 않습니다. 로그인 또는 이 웹사이트에서 제공하는 서비스를 사용할지 여부는 귀하의 개인적인 결정이며 귀하의 위험과 손실 가능성은 귀하에게 있습니다. 우리는 디지털 자산의 시장, 가치 및 가격에 대해 어떠한 명시적 또는 묵시적 보증도 하지 않습니다. 귀하는 디지털 자산 시장이 불안정하며 가격과 가치가 언제든지 변동하거나 붕괴될 수 있음을 이해하고 이해합니다. 디지털 자산 거래는 당신의 개인적인 자유 선택하고 결정하는 것은 당신 자신의 위험과 손실 가능성입니다. .
</p>
<p>
    <br/>
</p>
<p>
    8.7 본 계약에 명시된 당사의 보증 및 약속은 본 계약 및 이 웹사이트에서 제공하는 서비스에 대한 당사의 유일한 보증 및 진술이며 서면 또는 구두, 명시적 또는 암시. 그러한 모든 보증 및 진술은 당사 자체의 약속 및 보증만을 나타내며 제3자가 본 계약에 포함된 보증 및 약속을 준수할 것이라고 보장하지 않습니다. .
</p>
<p>
    <br/>
</p>
<p>
    8.8 우리는 법이 적용할 수 있는 최대한의 손해에 대한 책임을 제한, 배제 또는 상쇄하기 위해 본 계약에 언급되지 않은 권리를 포기하지 않습니다. .
</p>
<p>
    <br/>
</p>
<p>
    8.9 등록함으로써 귀하는 본 계약에 명시된 규칙에 따라 당사가 수행하는 모든 작업에 동의하는 것이며 이로 인해 발생하는 모든 위험은 귀하가 부담해야 합니다. .
</p>
<p>
    <br/>
</p>
<p>
    9. 계약 해지
</p>
<p>
    9.1 이 웹사이트는 이 계약에 따라 이 웹사이트에서 귀하의 계정을 취소할 권리가 있으며 이 계약은 계정 취소 날짜에 종료됩니다.
</p>
<p>
    <br/>
</p>
<p>
    9.2 본 사이트는 본 약관에 따라 본 사이트의 모든 서비스를 종료할 수 있는 권리가 있으며 본 계약은 본 사이트의 모든 서비스가 종료되는 날에 종료됩니다. .
</p>
<p>
    <br/>
</p>
<p>
    9.3 본 계약이 종료된 후 귀하는 이 웹사이트가 서비스를 계속 제공하거나 다른 의무를 수행하도록 요구할 권리가 없습니다. 여기에는 이 웹사이트가 원래 웹사이트 계정의 정보를 귀하 또는 귀하에게 제3자가 읽거나 보내지 않은 정보 등을 전달합니다. .
</p>
<p>
    <br/>
</p>
<p>
    9.4 본 계약의 종료는 위반 당사자의 다른 책임에 대한 미준수 당사자의 요청에 영향을 미치지 않습니다.
</p>
<p>
    <br/>
</p>
<p>
    10. 지적 재산권
</p>
<p>
    10.1 이 웹사이트에 포함된 모든 지적 성취에는 웹사이트 로고, 데이터베이스, 웹사이트 디자인, 텍스트 및 그래픽, 소프트웨어, 사진, 비디오, 음악, 사운드 및 이들의 조합, 소프트웨어 편집, 관련 소스 코드 및 소프트웨어(작은 응용 프로그램 및 스크립트)는 이 웹사이트의 소유입니다. 상업적 목적으로 전술한 자료 또는 콘텐츠를 복제, 변경, 복사, 전송 또는 사용할 수 없습니다. .
</p>
<p>
    <br/>
</p>
<p>
    10.2 이 웹사이트의 이름에 포함된 모든 권리(영업권 및 상표, 로고를 포함하되 이에 국한되지 않음)는 회사가 소유합니다.
</p>
<p>
    <br/>
</p>
<p>
    10.3 귀하가 이 계약을 수락하면 복제권, 배포권, 대여권, 전시권, 실연권, 전시권, 방송을 포함하되 이에 국한되지 않는 이 웹사이트에 게시된 모든 형태의 정보에 대한 저작권을 자발적으로 주장하는 것으로 간주됩니다. 저작권, 정보통신망 보급권, 촬영권, 각색권, 번역권, 편집권, 기타 저작권 소유자가 향유해야 하는 양도 가능한 권리는 이 웹사이트에 독점적으로 무료로 양도됩니다. . 이 계약의 유효성은 콘텐츠가 이 계약에 서명하기 전이나 후에 형성되었는지 여부에 관계없이 이 웹사이트에 게시하는 저작권법으로 보호되는 모든 저작물의 콘텐츠에 적용됩니다. .
</p>
<p>
    <br/>
</p>
<p>
    10.4 귀하는 이 웹사이트의 서비스를 이용하는 과정에서 이 웹사이트 또는 타인의 지적재산권을 불법적으로 사용하거나 처분할 수 없습니다. 귀하는 이 웹사이트에 게시된 정보를 어떤 형태로든 사용하도록 다른 웹사이트(및 미디어)를 게시하거나 승인할 수 없습니다. .
</p>
<p>
    <br/>
</p>
<p>
    10.5 이 웹사이트에 대한 귀하의 로그인 또는 이 웹사이트가 제공하는 서비스의 사용은 당사가 귀하에게 지적 재산권을 이전하는 것으로 간주되지 않습니다.
</p>
<p>
    <br/>
</p>
<p>
    11 기타
</p>
<p>
    11.1 분리 가능성
</p>
<p>
    관할 법원에서 본 계약의 조항이 시행 불가능하거나 무효하거나 불법인 것으로 판명된 경우, 이는 본 계약의 나머지 조항의 유효성에 영향을 미치지 않습니다.
</p>
<p>
    <br/>
</p>
<p>
    11.2 비에이전시 관계
</p>
<p>
    본 계약에 달리 규정된 경우를 제외하고 본 계약의 어떠한 내용도 당사를 귀하의 대리인, 수탁자 또는 기타 대리인으로 만들거나 암시하거나 달리 취급하는 것으로 간주되지 않습니다.
</p>
<p>
    <br/>
</p>
<p>
    11.3 포기
</p>
<p>
    당사 또는 귀하 중 한 사람이 본 계약에 명시된 계약 위반 또는 기타 책임에 대한 책임을 포기하는 것은 계약 위반에 대한 다른 책임에 대한 포기로 간주되거나 해석되지 않습니다. 어떤 식 으로든 그러한 권리 또는 구제 수단의 포기로 해석됩니다.
</p>
<p>
    <br/>
</p>
<p>
    11.4 제목
</p>
<p>
    모든 제목은 편의를 위해서만 제공되며 본 계약 조건의 내용이나 범위를 확장하거나 제한하기 위한 것이 아닙니다.
</p>
<p>
    <br/>
</p>
<p>
    11.5 분쟁 해결
</p>
<p>
    계약 이행 및 사용 과정에서 발생하는 모든 분쟁은 양 당사자가 원만하게 협상해야 합니다.
</p>
<p>
    <br/>
</p>
<p>
    11. 계약의 효력 및 해석
</p>
<p>
    12.1 이 계약은 귀하가 이 웹사이트의 등록 페이지를 클릭하여 등록에 동의하고, 등록 절차를 완료하고, 이 웹사이트의 계정과 비밀번호를 획득할 때 발효되며, 이 웹사이트와 귀하를 구속합니다.
</p>
<p>
    <br/>
</p>
<p>
    12.2 본 계약은 등록이 완료되면 효력이 발생합니다.
</p>
<p>
    <br/>
</p>
<p>
    12.3 이 계약의 최종 해석권은 이 웹사이트에 있습니다.
</p>
<p>
    <br/>
</p>`,
}