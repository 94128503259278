import request from "@/api/request"

const api = {
    // 币币
    getCoins: 'currency/getCoins',
    getCoinsDesc: 'currency/getCoinsDesc',
    buyCoins: 'currency/buyCoins',
    sellCoins: 'currency/sellCoins',
    revokeCoinsTrade: 'currency/revokeCoinsTrade',
    revokeCoinsPending: 'currency/revokeCoinsPending',

    // 杠杆
    getLever: 'currency/getLever',
    buyLever: 'currency/buyLever',
    sellLever: 'currency/sellLever',
    revokeLeverTrade: 'currency/revokeLeverTrade',
    revokeLeverPending: 'currency/revokeLeverPending',

    // 合约
    getContract: 'currency/getContract',
    buyContract: 'currency/buyContract',
    getContractRules: 'currency/getContractRules',
    revokeContractTrade: 'currency/revokeContractTrade',

    // 贵金属杠杆
    getTrading: 'currency/getTrading',

    //筛选
    getCurrencyList: 'currency/getCurrencyList',

    // 提现
    deposit: 'currency/deposit',

}

export function getCoins(parameter) {
    return request({
        url: api.getCoins,
        method: 'post',
        data: parameter
    })
}
export function getCoinsDesc(parameter) {
    return request({
        url: api.getCoinsDesc,
        method: 'post',
        data: parameter
    })
}

export function buyCoins(parameter) {
    return request({
        url: api.buyCoins,
        method: 'post',
        data: parameter
    })
}
export function sellCoins(parameter) {
    return request({
        url: api.sellCoins,
        method: 'post',
        data: parameter
    })
}
export function revokeCoinsTrade(parameter) {
    return request({
        url: api.revokeCoinsTrade,
        method: 'post',
        data: parameter
    })
}
export function revokeCoinsPending(parameter) {
    return request({
        url: api.revokeCoinsPending,
        method: 'post',
        data: parameter
    })
}

// 杠杆
export function getLever(parameter) {
    return request({
        url: api.getLever,
        method: 'post',
        data: parameter
    })
}
export function buyLever(parameter) {
    return request({
        url: api.buyLever,
        method: 'post',
        data: parameter
    })
}
export function sellLever(parameter) {
    return request({
        url: api.sellLever,
        method: 'post',
        data: parameter
    })
}
export function revokeLeverTrade(parameter) {
    return request({
        url: api.revokeLeverTrade,
        method: 'post',
        data: parameter
    })
}
export function revokeLeverPending(parameter) {
    return request({
        url: api.revokeLeverPending,
        method: 'post',
        data: parameter
    })
}

// 合约
export function getContract(parameter) {
    return request({
        url: api.getContract,
        method: 'post',
        data: parameter
    })
}
export function buyContract(parameter) {
    return request({
        url: api.buyContract,
        method: 'post',
        data: parameter
    })
}
export function revokeContractTrade(parameter) {
    return request({
        url: api.revokeContractTrade,
        method: 'post',
        data: parameter
    })
}
export function getContractRules(parameter) {
    return request({
        url: api.getContractRules,
        method: 'post',
        data: parameter
    })
}
export function getCurrencyList(parameter) {
    return request({
        url: api.getCurrencyList,
        method: 'post',
        data: parameter
    })
}

// 杠杆
export function getTrading(parameter) {
    return request({
        url: api.getTrading,
        method: 'post',
        data: parameter
    })
}

// 提现
export function deposit(parameter) {
    return request({
        url: api.deposit,
        method: 'post',
        data: parameter
    })
}