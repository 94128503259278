export default `<p>
    <strong>XTZ（テゾス）</strong>
</p>
<p>
    <br/>
</p>
<p>
    <strong>1.プロジェクト名</strong>
</p>
<p>
    テゾス（XTZ）
</p>
<p>
    <br/>
</p>
<p>
    <strong>2.プロジェクトのポジショニング</strong>
</p>
<p>
    Tezosは、チューリング完全スマートコントラクトを備えたブロックチェーンネットワークです。メインネットワークは2018年9月に正式に開始されました。 Tezosは、早くも2014年にブロックチェーンネットワークのガバナンスの欠陥に気づき、最初にオンチェーン自律性のソリューションを提案しました。 Tezosプロトコルは、パラメーターの変更とプロトコルの反復更新を容易にするように注意深く設計されています。オンチェーン自律システムを通じて、トークン所有者はシステムのアップグレードルートと優先度を決定できます。これは、紛争を解決し、ネットワークハードフォークに別れを告げるのに役立ちます。さらに、Tezosは独自のLPOSメカニズムを採用しており、現在、世界中から470を超える検証者がネットワークの保護に参加しており、コードレベルのフォーマル検証設計と相まって、ネットワーク全体のセキュリティが最大化されています。
</p>
<p>
    <br/>
</p>
<p>
    <strong>3、通貨の概要と流通</strong>
</p>
<p>
    トークンの総数：763,306,930 XTZ、年率約5.5％で増加
</p>
<p>
    循環：607,489,041 XTZ
</p>
<p>
    トークン価格：$ 0.373995 USD
</p>
<p>
    <br/>
</p>
<p>
    <strong>4つの一般的に使用されるリンク</strong>
</p>
<p>
    プロジェクトのウェブサイト：https：//tezos.com/
</p>
<p>
    財団のウェブサイト：https：//tezos.foundation/
</p>
<p>
    ホワイトペーパー：https：//tezos.com/static/papers/white_paper.pdf
</p>
<p>
    ブロッククエリ：https：//tezblock.io/
</p>
<p>
    <br/>
</p>
<p>
    <strong>5、通貨ゾーン</strong>
</p>
<p>
    イノベーションゾーン
</p>
<p>
    <br/>
</p>`