export default `<h2 style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:48px">
<strong><span style="font-family: Times New Roman;font-size: 16px;background: rgb(255, 255, 255)">TRX (Tron)</span></strong>
</h2>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: &#39;Times New Roman&#39;">&nbsp;</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: &#39;Times New Roman&#39;"><span style="font-family:宋体">I. Project name</span></span></span> </strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">TRON</span>TRON<span style="font-family:宋体"> : Decentralize the Internet again</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:16px;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: &#39;Times New Roman&#39;"><span style="font-family:宋体"> 2. Project introduction</span></span></span> </strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">TRON</span>TRON<span style="font-family:宋体"> Taking the promotion of the decentralization of the Internet as its own responsibility, we are committed to building infrastructure for the decentralized Internet. The </span><span style="font-family:Calibri">TRON</span><span style="font-family:宋体"> protocol is the world’s largest blockchain-based decentralized application operation One of the system protocols, which provides high-throughput, high-scalability, and high-reliability underlying public chain support for the operation of decentralized applications on the protocol. TRON</span><span style="font-family:Calibri">TRON</span><span style="font-family:宋体"> also uses an innovative pluggable smart contract platform for the Ethereum smart contract Provide better compatibility. </span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">From</span>2018<span style="font-family:宋体">year </span><span style="font-family:Calibri">7</span><span style="font-family:宋体">month</span><span style="font-family:Calibri"> 24</span><span style="font-family:宋体">From today,</span><span style="font-family:Calibri">TRON</span><span style="font-family: Songti">Acquired an Internet technology company located in San Francisco</span><span style="font-family:Calibri">BitTorrent Inc.</span><span style="font-family:宋体">. </span><span style="font-family:Calibri">BitTorrent Inc.</span><span style="font-family:宋体">The distributed technology designed by <span style="font-family:宋体"> can effectively expand, maintain intelligence, and make Creators and consumers can control their content and data. Every month, more than </span><span style="font-family:Calibri">1.7</span><span style="font-family:宋体">100 million people use</span><span style=" font-family:Calibri">BitTorrent Inc.</span><span style="font-family:宋体">developed products. </span><span style="font-family:Calibri">BitTorrent Inc.</span><span style="font-family:宋体">’s protocol can transmit worldwide every day</span><span style=" font-family:Calibri">40</span><span style="font-family:宋体">% of Internet traffic. </span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: &#39;Times New Roman&#39;"><span style="font-family:宋体">Three,</span>Token<span style="font- family:宋体">General and Distribution</span></span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">total tokens</span>: 99,214,764,539.60352 TRX</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">Circulation:</span></span><span style=";font- family:Helvetica;font-size:15px;background:rgb(255,255,255)">66,682,072,191</span><span style=";font-family:Calibri;font-size:16px">&nbsp;TRX</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">4. Commonly used links</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">Project website:</span></span><a href="https:/ /tron.network/" title="https://tron.network"><span style=";font-family:&#39;Times New Roman&#39;;color:rgb(53,124,225)">https:/ /tron.network</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">White paper:</span></span><a href="https:// s3.amazonaws.com/tron.network/%E7%AE%80%E4%BB%8B%E4%B8%8E%E6%84%BF%E6%99%AF.pdf" title="https:// tron.network/resources?lng=en&amp;name=1"><span style=";font-family:&#39;Times New Roman&#39;;color:rgb(53,124,225)">https://s3. amazonaws.com/tron.network/%E7%AE%80%E4%BB%8B%E4%B8%8E%E6%84%BF%E6%99%AF.pdf</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;<span style="font-family:宋体">Block query:</span></span><a href=" #/" title="https://tronscan.org/#/"><span style=";font-family:&#39;Times New Roman&#39;;color:rgb(53,124,225)">https:/ /tronscan.org/#/</span></a><span style=";font-family:Calibri;font-size:16px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> <a href="https://www.trxplorer.io/" title="https://www.trxplorer.io/"><span style=";font-family:&#39;Times New Roman&#39 ;;color:rgb(53,124,225)">https://www.trxplorer.io/</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p>
<span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
<p>
<br/>
</p>`