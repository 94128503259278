export default `<p>
<strong>ADA（カルダノ）</strong>
</p>
<p>
<br/>
</p>
<p>
<strong>1.プロジェクト名</strong>
</p>
<p>
カルダノ（ADA）
</p>
<p>
<br/>
</p>
<p>
<strong>2.プロジェクト紹介</strong>
</p>
<p>
Cardanoは分散型のオープンブロックチェーンであり、暗号通貨プロジェクトでもあり、完全にオープンソースです。 Cardanoは、これまでに開発されたプロトコルよりも高度な機能を提供することを目的としたスマートコントラクトプラットフォームを開発しています。これは、科学哲学と研究主導のオリエンテーションから進化した最初のブロックチェーンプラットフォームです。開発チームは、グローバルな専門エンジニアと研究者で構成されています。
</p>
<p>
<strong>3.通貨の概要と配布</strong>
</p>
<p>
最大供給量：45,000,000,000 ADA
</p>
<p>
総供給量：31,112,483,745 ADA
</p>
<p>
循環供給：25,927,070,538 ADA
</p>
<p>
クラウドファンディング価格：$ 0.0026
</p>
<p>
トークンの配布：
</p>
<p>
その中で、カルダノ契約が発表されたとき、ADAコインは合計25,927,070,538枚のADAコインを販売しました。販売されたADAの数の20％に相当する別の5,185,414,108 ADAコインが生成され、Cardanoテクノロジーとビジネスエコシステムを構成する3つのエンティティ（IOHK、Emurgo、Cardano Foundation）に配布されます。したがって、リリース時のADAの総数は31,112,484,646でした。
</p>
<p>
<br/>
</p>
<p>
カルダノプロトコルの運用中に発行されたADAコインの残りの13,887,515,354ADAコインは、鋳造プロセス中に発行されます。
</p>
<p>
<br/>
</p>
<p>
注：ADAの数が45,000,000,000を超えることはありません
</p>
<p>
に
</p>
<p>
<strong>4つの一般的に使用されるリンク</strong>
</p>
<p>
プロジェクトのウェブサイト：cardanofoundation.org、iohk.io、emurgo.io
</p>
<p>
ホワイトペーパー：whycardano.com
</p>
<p>
<br/>
</p>
<p>
その他：
</p>
<p>
<br/>
</p>
<p>
<br/>
</p>
<p>
cardano.org
</p>
<p>
cardanodocs.com
</p>
<p>
cardanoexplorer.com
</p>
<p>
cardanoroadmap.com
</p>
<p>
https://github.com/input-output-hk/cardano-sl
</p>
<p>
https://www.reddit.com/r/cardano/
</p>
<p>
https://daedaluswallet.io/
</p>
<p>
<br/>
</p>
<p>
<strong>5、通貨ゾーン</strong>
</p>
<p>
メインゾーン
</p>
<p>
<br/>
</p>`
