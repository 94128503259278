export default `<h2 style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:48px">
<strong><span style="font-family: Times New Roman;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 16px;background: rgb(255, 255, 255)">ZEC (Zcash)</span></strong>
</h2>
<p style=";text-indent: 0;padding: 0;line-height: 48px;background: rgb(255, 255, 255)">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">1. What is Zcash</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">Zcash is a decentralized and open source encrypted internet currency , Based on the development of the paper Zerocash Protocol released at the IEEE Conference in 14 years, zero-knowledge proof and multiple account mechanisms are adopted to ensure the anonymity of transactions. </span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Compared with Bitcoin, it pays more attention to privacy and the controllability of transaction transparency. Specifically, the public blockchain encrypts the sender, receiver, and transaction volume in the transaction record; the user can choose whether to provide the viewing key to others at the discretion of the user, and only those who have the key can see the content of the transaction. </span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">2. Detailed parameters</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Total Tokens:</span>21,000,000 ZEC</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Token distribution: miners</span>90%; team, investors and consultants 10%</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">3. Common links</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Official website:</span></span><a href="https://z.cash/zh/"><span style="font-family: Microsoft Yahei; color: rgb(53, 124, 225 );letter-spacing: 0;font-size: 14px">https://z.cash/zh/</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Technical documents:</span></span><a href="http://zerocash-project.org/media/pdf/zerocash-extended-20140518.pdf"><span style="font-family: Microsoft Yahei;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">http://zerocash-project.org/media/pdf/zerocash-extended-20140518.pdf</ span></a>
</p>
<p>
<span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
<p>
<br/>
</p>`