export default `<p>
    <strong>TRB（テラートリビュート）</strong>
</p>
<p>
    <br/>
</p>
<p>
    1.プロジェクト名
</p>
<p>
    テラートリビュート（TRB）
</p>
<p>
    に
</p>
<p>
    2.プロジェクトのポジショニング
</p>
<p>
    テラーは、価値の高いオフチェーンデータをイーサリアムに統合するために使用される分散型のOracleオラクルネットワークプラットフォームです。 このシステムは、物議を醸しているマイナーネットワークの競合を使用して、PoWの問題を解決し、チェーン上のユーザーデータ要求を監視し、チェーン外のデータ要求を監視および受信します。 成功した各Tellerデータポイントはトークンを使用してマイニングされ、会社はエコシステムの開発をサポートするために10％の開発シェアを取得します。
</p>
<p>
    に
</p>
<p>
    3、通貨の概要と流通
</p>
<p>
    合計トークン：1,505,461 TRB
</p>
<p>
    循環：1,416,589 TRB
</p>
<p>
    、
</p>
<p>
    4つの一般的に使用されるリンク
</p>
<p>
    プロジェクトのウェブサイト：http：//www.tellor.io
</p>
<p>
    ホワイトペーパー：http：//www.tellor.io/whitepaper
</p>
<p>
    ブロッククエリ：
</p>
<p>
    https://explore.duneanalytics.com/public/dashboards/pVN6lRN8xxtfhjnEbcPbqreI8DNkPOng5P5fol0v
</p>
<p>
    <br/>
</p>`