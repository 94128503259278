export default `<h2 style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:48px">
<strong><span style="font-family: Times New Roman;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 16px;background: rgb(255, 255, 255)">HT (Huobi Token)</span></strong>
</h2>
<p style=";text-indent: 0;padding: 0;line-height: 48px;background: rgb(255, 255, 255)">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">HT (Huobi Global Points) is based on the blockchain The points system issued and managed is the only point officially issued by Huobi. HT will support Huobi's global business and full line of products, such as: VIP fee discounts, regular platform repurchase, certified merchant deposits, exclusive Huobi points activities, transactions with popular currencies, participation in Huobi business, etc. </span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Release time:</span>2018-1-24</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Existing total:</span>22.844 million HT</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Total circulation: </span>180.647 million HT</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Official website:</span></span><a href="https://www.huobi.be/"><span style="font-family: Microsoft Yahei; color: rgb(53, 124, 225 );letter-spacing: 0;font-size: 14px">https://www.huobi.be</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: Microsoft Yahei;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family: Microsoft Yahei" >Block query:</span></span><a href="https://etherscan.io/address/0x6f259637dcd74c767781e37bc6133cd6a68aa161"><span style="font-family: Microsoft Yahei;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">https://etherscan.io/address/0x6f259637dcd74c767781e37bc6133cd6a68aa161</span></a>
</p>
<p>
<span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
<p>
<br/>
</p>`