<template>
<div class="page-content page-content-chain" style="position: relative; min-height: 800px;">
    <div class="innovation">
        <div class="page-banner">
            <h2 class="title f-8-cl"></h2>
        </div>
        <div class="innovation-content">
            <div class="main-content c-4-bg">
                <div class="content-top c-5-bd">
                    <div class="img">
                        <img alt="" :src="currency.icon">
                    </div>
                    <!-- <div class="info">
                        <h2 class="tit f-1-cl">{{currency.name}}</h2>
                        <div class="share"></div>
                        <div class="info-text"></div>
                    </div> -->
                    <div class="l-kline-trade">
                        <div class="k-symbol">{{currency.name}} / {{currency.desc}}</div>
                        <div :class="'k-price '+ (currency.change >0?'up':'down')">{{currency.close}}</div>
                        <div :class="'k-change ' + (currency.change >0?'up':'down')">{{currency.change >0?'+':''}}{{currency.change}}%</div>
                        <div class="kline-24h">
                            <div class="k-24h-item"><label class="k-title">{{$t('kline.h24_high')}}</label><label>{{currency.high}}</label></div>
                            <div class="k-24h-item"><label class="k-title">{{$t('kline.h24_low')}}</label><label>{{currency.low}}</label></div>
                            <div class="k-24h-item"><label class="k-title">{{$t('kline.h24_vol')}}</label><label>{{currency.vol}}</label></div>
                        </div>
                    </div>
                </div>
                <div class="main-text">
                    <!-- <p>{{currency.name}}</p> -->
                    <!-- <div class="l-kline-trade">
                        <div class="k-symbol">{{currency.name}} / {{currency.desc}}</div>
                        <div :class="'k-price '+ (currency.change >0?'up':'down')">{{currency.close}}</div>
                        <div :class="'k-change ' + (currency.change >0?'up':'down')">{{currency.change >0?'+':''}}{{currency.change}}%</div>
                        <div class="kline-24h">
                            <div class="k-24h-item"><label class="k-title">{{$t('kline.h24_high')}}</label><label>{{currency.high}}</label></div>
                            <div class="k-24h-item"><label class="k-title">{{$t('kline.h24_low')}}</label><label>{{currency.low}}</label></div>
                            <div class="k-24h-item"><label class="k-title">{{$t('kline.h24_vol')}}</label><label>{{currency.vol}}</label></div>
                        </div>
                    </div> -->
                    <div class="kline-chart">
                        <TVChartContainer ref="TVChartContainer"></TVChartContainer>
                    </div>
                </div>
            </div>
            <div class="base-info c-4-bg">
                <h3 class="title f-1-cl">
                    {{$t('new_currency._base_title')}}
                </h3>
                <div class="bf">
                    <!-- <p>
                        <span>
                            {{$t('new_currency._progress_str')}}
                        </span>
                        <span class="f-r">
                            {{$t('new_currency._total_str')}}
                        </span>
                    </p>
                    <div class="jdt c-5-bg">
                        <div class="jdt-bar c-8-bg" :style="'width: '+info.progress+'%;'"></div>
                    </div> -->
                    <!-- <p class="f-1-cl">
                        <span>{{info.progress}}%</span>
                        <span class="f-r">
                            {{info.total}}
                            {{currency.name}}
                        </span>
                    </p> -->
                </div>
                <div class="infor-list">
                    <p class="key">
                        {{$t('new_currency._status')}}
                    </p>
                    <p class="val f-1-cl">
                        {{getStatusStr(info.status)}}
                    </p>
                    <p class="key">
                        {{$t('new_currency._currency')}}
                    </p>
                    <p class="val f-1-cl">
                        {{currency.name}}
                    </p>
                    <p class="key">
                        {{$t('new_currency._symbol')}}
                    </p>
                    <p class="val f-1-cl">
                        {{currency.name}}
                    </p>
                    <p class="key">
                        {{$t('new_currency._amount')}}
                    </p>
                    <p class="val f-1-cl">
                        {{info.total}} {{currency.name}}
                    </p>
                    <p class="key">
                        {{$t('new_currency._price')}}
                    </p>
                    <p class="val f-1-cl">
                        1 {{currency.name}} = {{info.init_price}} USD
                    </p>
                    <p class="key">
                        {{$t('new_currency._limit')}}
                    </p>
                    <p class="val f-1-cl" v-if="info.limit > 0">{{info.limit}}</p>
                    <p class="val f-1-cl" v-if="info.limit == 0">{{$t('new_currency._no_limit')}}</p>
                    <p class="key">
                        {{$t('new_currency._limit_amount')}}
                    </p>
                    <!---->
                    <p class="val f-1-cl" v-if="info.limit_amount > 0">
                        {{$t('new_currency._position')}} {{currency.name}} ≤ {{info.limit_amount}} {{currency.name}}
                    </p>
                    <p class="val f-1-cl" v-if="info.limit_amount == 0">
                        {{$t('new_currency._no_limit')}}
                    </p>
                    <p class="key">
                        {{$t('new_currency._limit_one')}}
                    </p>
                    <!---->
                    <p class="val f-1-cl" v-if="info.exchange_min > 0">
                        {{info.exchange_min}} USD
                    </p>
                    <p class="val f-1-cl" v-if="info.exchange_min == 0">
                        {{$t('new_currency._no_limit')}}
                    </p>
                    <!-- <p class="val f-1-cl">
                        持仓
                        FIL ≥
                        1,000
                        或<br>
                        持仓
                        TUSDT ≥
                        50,000 :
                        限购
                        100
                        FIL
                    </p> -->
                    <p class="key">
                        {{$t('new_currency._begin_time')}}
                    </p>
                    <p class="val f-1-cl">{{info.begin_time}}</p>
                    <p class="key">
                        {{$t('new_currency._end_time')}}
                    </p>
                    <p class="val f-1-cl">{{info.end_time}}</p>
                    <!-- <div class="tsm c-3-bg">
                        <p class="key">
                            {{$t('new_currency._redeemed')}}
                        </p>
                        <p class="val f-1-cl">
                            {{info.exchange}}
                            {{currency.name}}
                        </p>
                    </div> -->
                    <!-- <p class="vol">
                        {{$t('new_currency._surplus')}}
                        {{info.surplus}}
                        {{currency.name}}
                    </p> -->

                    <div class="t-box">
                        <div class="no-login" v-if="token == ''">
                            <div class="no-box">
                                <router-link :to="{name:'Login'}">
                                <button>{{$t('login.login')}}</button>
                                </router-link>
                                <router-link :to="{name:'Signup'}">
                                <button>{{$t('signup.signup')}}</button>
                                </router-link>
                            </div>
                        </div>
                        <div class="select">
                            <div id="" class="common-select select-value">
                                <div class="input_line_content a-2-bd" @click="open()">
                                    <input type="text" required="required" readonly="readonly" class="input_line_inp b-1-cl" v-model="current.name" :placeholder="$t('new_currency._choose')">
                                    <i class="icon icon-triangle b-1-bd icon-triangle-down"></i>
                                    <label class="input_line_prompt b-2-cl">
                                        {{$t('new_currency._wallet')}}
                                    </label>
                                    <span class="input_line_activeLine a-12-bg"></span>
                                </div>
                                <div class="input_line_errorStence">
                                    {{$t('new_currency._position')}}：{{current.balance}} {{current.name}}
                                </div>
                                <div :class="'select-options-box a-5-bg b-1-cl ' + (isOpen?'slideInDown-enter-active':'slideInDown-leave-active slideInDown-leave-to')" style="">
                                    <div class="select-option-list" style="max-height: 200px;">
                                        <div class="__vuescroll hasVBar vBarVisible" style="height: 100%; width: 100%; padding: 0px; position: relative; overflow: hidden;">
                                            <div class="__panel" style="position: relative; box-sizing: border-box; height: 100%; overflow: hidden scroll; margin-right: -17px;">
                                                <div class="__view" style="position: relative; box-sizing: border-box; min-width: 100%; min-height: 100%; width: -webkit-fit-content;">
                                                    <ul class="currency-list">
                                                        <li class="select-option-item" style="height: 40px; line-height: 40px;" v-for="(item,index) in walletList" :key="index" @click="setCurrent(item)">
                                                            {{item.name}}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="not_option" style="display: none;">
                                        暂无数据
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="tt" style="text-align: left;">
                            {{$t('new_currency._have')}}: {{balance.balance}} {{balance.name}}
                        </p>
                        <section class="input-line-content" style="width: 100%; margin-top: 0px;">
                            <div class="input-line-baseStance a-2-bd">
                                <div class="input_line_inp">
                                    <!---->
                                    <input autocomplete="off" @input="onNumber()" v-model="number" maxlength="100000" required="required" type="text" class="b-1-cl input" :placeholder="$t('new_currency._input')">
                                    <!-- <label class="input-line-prompt  b-2-cl">数量</label> -->
                                    <div class="input-line-slot clearfix"><span class="f-1-cl">{{current.name}}</span>
                                    </div>
                                </div>
                                <span class="input-line-activeLine a-12-bg" style="width: 0px;"></span>
                            </div>
                            <!---->
                            <div class="input-line-limit"></div>
                        </section>
                        <p class="vol">
                            ≈ {{real_number}} {{currency.name}}
                        </p>
                        <section class="common-verify">
                            <section class="common-geetest" style="width: 100%; margin-top: 6px;">
                                <div class="common-geetest-baseStance a-2-bd">
                                    <!---->
                                    <div id="captchaBox" class="a-5-bg"></div><span class="common-geetest-activeLine a-12-bg" style="width: 0%;"></span>
                                </div>
                                <div class="common-geetest-errorStence b-6-cl">
                                    <!---->
                                </div>
                            </section>
                            <!---->
                        </section>
                        <button  class="common-button common-button-solid u-8-bg u-16-cl " style="width: 100%; height: 40px; padding-left: 0px; padding-right: 0px; margin-top: 0px;" @click="openWind()">
                            <div class="common-button-slot">
                                {{$t('new_currency._submit')}}
                            </div>
                        </button>
                    </div>
                    <!---->
                    <!---->
                </div>
            </div>
            <p style="clear: both;"></p>
        </div>
        <section class="common-dialog">
            <!---->
            <!---->
        </section>
    </div>

    <!-- 输入密码 -->
    <el-dialog :title="$t('trade.title')" :visible.sync="dialogVisible" :before-close="cancelTrade" class="trade-dialog trade-dg" style="width:50%;margin: 0 auto;top: 20%;">
        <el-row>
            <!-- <el-col :span="24">
                <label style="padding: 10px 0;color:#FFF;">交易密码:</label>
            </el-col> -->
            <el-col :span="24">
                <el-input v-model="tradePwd" type="password" :placeholder="$t('trade.enter_password')" style="width:100%"></el-input>
            </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button @click="cancelTrade()" style="padding: 12px 20px">{{$t('trade.cancel')}}</el-button>
            <el-button @click="onExchange()" style="padding: 12px 20px">{{$t('trade.submit')}}</el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>
import TVChartContainer from '@/components/TVChartContainer.vue'
import socket from '@/utils/socket'
import {
    GetFixed,
    Float8,
    ScToNumber
} from '@/utils/helper'
import {
    getInfo,
    getMyWallet,
    exchange
} from '@/api/new_currency'
export default {
    name: "NewTrade",
    components: {
        TVChartContainer
    },
    data() {
        return {
            token: '',
            isOpen: false,
            id: 0,
            info: {},
            currency: {
                desc: 'USDT',
                name: "HTA",
                symbol: "htausdt",
                close: 0,
                change: 0,
                high: 0,
                low: 0,
                vol: 0,
                amount: 0,
            },

            balance: {},
            walletList: [],
            current: {},

            number: '',
            real_number: '0.0000',

            tradePwd: '',
            dialogVisible: false,
        }
    },
    created() {
        //是否登录
        let token = localStorage.getItem('token')
        if (token) {
            this.token = token
        }

        let id = this.$route.query.id
        if (id == undefined) {
            this.$router.push({
                name: 'NewCurrency'
            })
            return
        }

        this.id = id

        //获取信息
        this.getInfo();

        //获取我的钱包
        this.getMyWallet()
    },
    mounted() {

    },
    methods: {
        openWind() {
            if (!this.number) {
                this.$message({
                    message: this.$t('message.invalid_number'),
                    type: "warning",
                });
                return false
            }
            this.tradePwd = ''
            this.dialogVisible = true
        },
        cancelTrade() {
            this.dialogVisible = false
        },
        /**
         * 提交兑换
         */
        onExchange() {
            this.dialogVisible = false
            //loading
            this.$ui.openLoading()
            exchange({
                id: this.info.id,
                c_id: this.current.c_id,
                number: this.number,
                trade_pwd: this.tradePwd,
            }).then(res => {
                //close
                this.$ui.closeLoading()
                console.log(res)
                if (res.code == 1) {
                    this.$message({
                        message: res.msg,
                        type: "error",
                    });
                    return
                }

                this.$message({
                    message: res.msg,
                    type: "success",
                });

                //重新获取数据
                this.getMyWallet()

                //重新获取信息
                this.updateInfo()

                console.log(res)
            })
        },
        /**
         * 输入过滤
         */
        _GetNumber() {
            //数初始化
            let number = this.number
            if (number) {
                let number = Number(number)
            }

            //数据过滤
            if (number < 0) {
                this.number = ''
                return 0
            }

            if (!number || number == 0) {
                return 0
            }

            //console.log(number, '数据正常')
            number = ScToNumber(number)
            //console.log(number, '数据转换')
            return number
        },
        _GetReal(number) {
            console.log(number)
            //当前兑换币
            let c_price = parseFloat(this.current.close)

            //被兑换币
            let price = parseFloat(this.currency.close)

            //兑换币计算
            let usdt = Float8(number * c_price)

            //获得兑换数量
            let real = Float8(usdt / price)
            console.log(real)
            return real
        },
        onNumber() {
            //过滤
            let number = this._GetNumber()

            if (!number) {
                return
            }

            if (number > parseFloat(this.current.balance)) {
                this.number = parseFloat(this.current.balance)
                number = this._GetNumber()
            }

            this.real_number = this._GetReal(number)
        },
        setCurrent(item) {
            this.current = item
            this.open()
        },
        open() {
            this.isOpen = !this.isOpen
        },
        getStatusStr(status) {
            switch (status) {
                case 2:
                    return this.$t('new_currency._progress')
                case 3:
                    return this.$t('new_currency._end')
                case 4:
                    return this.$t('new_currency._success')
            }
            return this.$t('new_currency._progress')
        },
        getMyWallet() {
            let _this = this
            getMyWallet({
                id: this.id
            }).then(res => {
                _this.walletList = res.data.list
                _this.balance = res.data.balance

                //默认 
                if (JSON.stringify(_this.current) == '{}') {
                    _this.current = _this.walletList[0]
                } else {
                    for (var i in _this.walletList) {
                        if (_this.current.name == _this.walletList[i].name) {
                            _this.current = _this.walletList[i]
                        }
                    }
                }
            })
        },
        getInfo() {
            let _this = this
            getInfo({
                id: this.id
            }).then(res => {
                console.log(res)
                if (res.code == 1) {
                    this.$message({
                        message: res.msg,
                        type: "error",
                    });
                    return
                }

                //币种信息
                _this.currency = res.data.currency
                _this.info = res.data.info

                // 初始化k线
                this.$refs.TVChartContainer.initWidget(this.currency)
                //监听
                this.socketListen()
            })
        },

        updateInfo() {
            let _this = this
            getInfo({
                id: this.id
            }).then(res => {
                if (res.code == 1) {
                    this.$message({
                        message: res.msg,
                        type: "error",
                    });
                    return
                }
                //币种信息
                _this.info = res.data.info
            })
        },
        socketListen() {
            let _this = this
            // 初始化历史k线
            socket.on("detail", function (data) {
                // 最新信息
                if (_this.currency.symbol == data.symbol) {
                    _this.currency.close = GetFixed(data.close)
                    _this.currency.change = data.change
                    _this.currency.high = GetFixed(data.high)
                    _this.currency.low = GetFixed(data.low)
                    _this.currency.vol = GetFixed(data.vol)
                    _this.currency.amount = GetFixed(data.amount)
                }

                // if (_this.current.symbol == data.symbol) {
                //     _this.current.close = GetFixed(data.close)
                // }
            })

            // 连接后
            socket.on("onReady", function () {
                //启动心跳
                socket.sendPing()
            })

            socket.on("onReconnect", function () {
                // 断线重连
                // _this.getCoins()
            })
        },
    }
}
</script>

<style src="../../../static/css/new/index.css" scoped type="text/css"></style>
<style src="../../../static/css/new/trade.css" scoped type="text/css"></style><style>
.kline-chart {
    height: 40rem;
    width: 100%;
    position: relative;
}
</style>
