export default `<p>
    <strong>ETC (이더 리움 클래식)</strong>
</p>
<p>
    <br/>
</p>
<p>
    1. 프로젝트 소개
</p>
<p>
    Ethereum Classic (ETC)은 암호 화폐 및 퍼블릭 블록 체인입니다. ETC는 현재 Defi 분야와 다른 블록 체인, 보안 애플리케이션, 스마트 계약 및 P2P 거래와의 상호 운용성에 초점을 맞추고 있습니다 .ETC는 진정으로 탄력적이고 분산 된 네트워크를 목표로 2016 년에 커뮤니티 주도의 노력을 통해 시작되었습니다.
</p>
<p>
    <br/>
</p>
<p>
    두 가지 세부 매개 변수
</p>
<p>
    가격 : 6.8 USD (Coinbase-2020 년 11 월 18 일)
</p>
<p>
    총 공급량 : 2 억 1 천만 개
</p>
<p>
    블록 시간 : 13 초
</p>
<p>
    핵심 개발자 : etccore.io
</p>
<p>
    <br/>
</p>
<p>
    세, 관련 링크
</p>
<p>
    공식 웹 사이트 : https://ethereumclassic.org/
</p>
<p>
    백서 : https://github.com/etclabscore
</p>
<p>
    블록 탐색기 : https://blockscout.com/etc/mainnet/
</p>
<p>
    공식 Twitter : https://twitter.com/etclabs
</p>
<p>
    레딧 : https://www.reddit.com/r/EthereumClassic/
</p>
<p>
    <br/>
</p>`