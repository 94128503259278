<template>
<div class="desc-content">
    <div class="desc-box" :style="'min-height:'+(screenHeight - 73)+'px;'">
        <!-- 左边 -->
        <left-item></left-item>
        <!-- 右边 -->
        <div class="right-box">

            <!-- 标题 -->
            <div class="l-title">
                <h2>{{$t('contract_menu.history')}}</h2>
            </div>
            <!-- 选择 -->
            <div class="l-select">
                <div class="list-select">
                    <el-select v-model="contractId" placeholder="币种" @change="onChangeContract">
                        <el-option v-for="(item,index) in contractList" :key="index" :label="item.name + 'USDT'" :value="index">
                        </el-option>
                    </el-select>
                </div>
                <div class="list-select">
                    <el-select v-model="usdtId" placeholder="请选择">
                        <el-option v-for="(item,index) in usdtList" :key="index" :label="item.label" :value="index">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <!-- 详情 -->
            <div class="info-box fee-table">
                <table class="f-table" cellpadding="0" cellspacing="0">
                    <thead>
                        <tr>
                            <th>{{$t('fun_history.t1')}}</th>
                            <th>{{$t('fun_history.t2')}}</th>
                            <th>{{$t('fun_history.t3')}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in historyList" :key="index">
                            <td>{{item.create_time}}</td>
                            <td>{{item.expected}}%</td>
                            <td>{{item.actual}}%</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    getCoins,
} from '@/api/currency'
import {
    getRateHistory,
} from '@/api/index'

import LeftItem from '../components/LeftItem.vue'

export default {
    components: {
        // TVChartContainer,
        LeftItem
    },
    name: "Desc1",
    props: ['screenHeight'],
    data() {
        return {
            //合约列表
            contractList: [],
            //USDT保证金
            usdtList: [{
                value: "USDT",
                label: "USDT保证金"
            }],

            //当前合约
            contractId: 0,
            //当前USDT
            usdtId: 0,

            //当前合约信息
            currentInfo: {},
            //当前货币
            currentUSDT: {},

            //历史记录
            historyList: [],
        }
    },
    watch: {
        '$route'(to) {
            console.log('路由', to)
            this.Init()
        }
    },
    created() {

        //设置保证金列表
        this.setUsdt()

        //获取合约列表
        this.getCoins()
    },
    methods: {
        changList(type) {
            this.currentListType = type
        },

        /**
         * 切换合约币种
         */
        onChangeContract(index) {
            this.contractId = index
            this.setContractInfo(index)
        },

        /**
         * usdt保证金
         */
        setUsdt() {
            this.usdtList = [{
                value: "USDT",
                label: "USDT" + this.$t('contract_info.bzj')
            }]

            //默认就是usdt
            this.currentUSDT = this.usdtList[this.usdtId]
        },
        /**
         * 设置当前币
         */
        setContractInfo(index) {
            //当前合约信息
            this.currentInfo = this.contractList[index]

            //获取历史记录
            this.getRateHistory(this.currentInfo.id)

            console.log(this.currentInfo, '当前币种信息')
        },
        /**
         * 获取合约
         */
        getCoins() {
            // 获取列表
            getCoins().then(res => {
                this.contractList = res.data

                //设置默认
                this.setContractInfo(this.contractId)
            })
            this.setTimeRules(this.contractRuleId)
        },
        getRateHistory(c_id) {
            getRateHistory({
                c_id: c_id
            }).then(res => {
                this.historyList = res.data
            })
        }
        // socketListen() {
        //     let _this = this
        //     // 初始化历史k线
        //     socket.on("detail", function (data) {
        //         // 最新信息
        //         if (_this.currentInfo.symbol == data.symbol) {
        //             _this.currentInfo.close = GetFixed(data.close)
        //             _this.currentInfo.change = data.change
        //             _this.currentInfo.high = GetFixed(data.high)
        //             _this.currentInfo.low = GetFixed(data.low)
        //             _this.currentInfo.vol = GetFixed(data.vol)
        //             _this.currentInfo.amount = GetFixed(data.amount)
        //         }
        //     })

        //     // 连接后
        //     socket.on("onReady", function () {
        //         //启动心跳
        //         socket.sendPing()
        //     })

        //     socket.on("onReconnect", function () {
        //         // 断线重连
        //         // _this.getCoins()
        //     })
        // },
    }
}
</script>

<style src="../../static/css/contract_info.css" scoped type="text/css"></style>
