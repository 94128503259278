export default {
    pt1: "1. 사용자가 STARTHESTAR에 로그인 한 후\"자산 \"을 클릭 한 다음\"내 지갑 \"을 클릭하여 내 자산 인터페이스로 들어갑니다.",
    pimg1: "k6",
    pt2: "2. 내 자산 인터페이스에서\"인출 \"을 클릭하십시오.",
    pimg2: "k6",
    pt3: "3. 사용자가 출금 할 통화를 선택합니다. 예를 들어 사용자가 BTC를 출금하려면 BTC 통화를 선택하고 다른 통화를 출금하려면 해당 통화를 선택한 다음 플랫폼에서 해당 통화를 선택합니다. 입금이 필요합니다 입금 주소를 \"주소\"열에 붙여넣고 인출 할 코인 수를 입력 한 다음 \"확인\"을 클릭합니다",
    pimg3: "k6",
    pt4: "4. 사용자가 출금 절차를 완료 한 후 STARTHESTAR 플랫폼에서 출금 정보를 확인할 때까지 기다립니다. (참고 : 출금 금액은 현재 최소 1000 USD로 설정되어 있습니다. 출금 신청서를 여러 번 제출하지 마십시오. 사용자는 내 자산 인터페이스에서 자신을 볼 수 있습니다. 인출 작업이 완료되었는지 확인하기 위해 동결 자산이 있는지 여부를 확인할 수 있습니다. 플랫폼이 처리 될 때까지 기다린 후 동결 된 자산이 삭제되어 플랫폼이 인출 신청을 통과하고 완료되었음을 나타냅니다. 철수).",
    pt5: "STARTHESTAR는 다음을 상기시킵니다 :",
    pt6: "① 코인 인출시 해당 화폐 입금 플랫폼의 지갑 주소에주의하십시오. 오류를 입력하지 마십시오. 그렇지 않으면 자산이 회수되지 않습니다.",
    pt7: "②USDT를 인출 할 때 USDT (ERC20, TRC20, OMNI)에 해당하는 블록에주의를 기울이고 잘못 입력하지 마십시오. 그렇지 않으면 자산이 회수되지 않습니다.",
}