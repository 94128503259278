export default `<p>
    <strong>MKR (메이커)</strong>
</p>
<p>
    <br/>
</p>
<p>
    <strong>1. 프로젝트 이름</strong>
</p>
<p>
    MakerDAO (MKR)
</p>
<p>
    <br/>
</p>
<p>
    <strong>2. 프로젝트 포지셔닝</strong>
</p>
<p>
    MakerDAO는 탈 중앙화 금융 (DeFi)의 인프라 인 이더 리움에서 최초의 탈 중앙화 안정 통화 인 Dai를 개발했습니다. MKR은 Maker 시스템의 거버넌스 및 지분 토큰입니다.
</p>
<p>
    <br/>
</p>
<p>
    <strong>3, 통화 개요 및 배포</strong>
</p>
<p>
    총 토큰 : 1,005,577 MKR
</p>
<p>
    총 유통량 : 1,005,577 MKR
</p>
<p>
    <br/>
</p>
<p>
    <strong>4, 일반적으로 사용되는 링크</strong>
</p>
<p>
    프로젝트 웹 사이트 : https://makerdao.com
</p>
<p>
    백서 : https://makerdao.com/whitepaper
</p>
<p>
    블록 쿼리 : https://etherscan.io/token/0x6b175474e89094c44da98b954eedeac495271d0f
</p>
<p>
    <br/>
</p>`