<template>
<div class="box hb-day">
    <div class="content page_assetintro" :style="'min-height:' + (screenHeight - 297) + 'px'">
        <div class="l_title">{{$t('asset.title')}}</div>
        <div class="cont">
            <div class="mod sider">
                <div class="mod_hd">
                    <h2>{{$t('asset.list')}}</h2>
                </div>
                <div class="mod_bd" id="sider">
                    <dl>
                        <div class="sider_flow">
                            <!-- <dd data-coin="usdt" class="cur">
                                <h3 class="slider_inner"> <span class="uppercase">USDT</span> <span>(Tether)</span> </h3>
                            </dd> -->
                            <router-link :to="{name:'Asset',query:{key:item.key}}" v-for="(item,index) in list" :key="index">
                                <dd data-coin="usdt" :class="(currentKey == item.key ?'cur':'')">
                                    <h3 class="slider_inner"> <span class="uppercase">{{item.name}}</span></h3>
                                </dd>
                            </router-link>
                        </div>
                    </dl>
                </div>
            </div>
            <div class="mod assetintro_content" id="assetintro">
                <div class="mod_hd">
                    <h2>{{currentTitle}}</h2> <a href="/zh-cn/exchange/" class="mod_but">去交易</a>
                </div>
                <div class="mod_bd" v-html="currentInfo">
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "Asset",
    props: ['screenHeight'],
    data() {
        return {
            //列表
            list: [],

            //当前KEY
            currentKey: 'ada',
            //当前标题
            currentTitle: '',
            //当前信息
            currentInfo: {},

        }
    },
    watch: {
        '$route'(to) {
            this.setCurrentInfo(to.query.key)
        }
    },
    created() {
        this.init()
    },
    methods: {
        listenLang(lang) {
            lang
            this.init()
        },
        init() {
            //获取当前KEY
            let key = this.$route.query.key
            this.currentKey = key

            //获取列表
            this.list = this.$t('num_asset.list');

            //信息列表
            this.infos = this.$t('num_asset.infos')

            //设置当前信息
            this.setCurrentInfo(this.currentKey)
        },

        setCurrentInfo(key) {

            let _this = this

            //当前KEY
            _this.currentKey = key

            this.list.forEach(item => {
                if (item.key == _this.currentKey) {
                    _this.currentTitle = item.name
                }
            });

            //当前信息
            this.currentInfo = this.infos[key]
            console.log(this.currentTitle, _this.currentKey, '当前title')
        }

    }
}
</script>

<style src="../../../static/css/asset.css" scoped type="text/css"></style>
