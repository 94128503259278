export default {
    title:"Law enforcement request policy",
    content:`<p style="text-align: center;">
    <span style="font-size: 18px;"><strong>Law Enforcement Request Policy</strong></span>
</p>
<p>
    Starthestar
</p>
<p>
    <br/>
</p>
<p>
    From time to time, starthestar.com receives requests for customer information from law enforcement agencies around the world, and here is information for you and law enforcement on how to handle these requests
</p>
<p>
    <br/>
</p>
<p>
    Law enforcement is generally interested in two types of data: information about the identity of customers and information about their trading activities. We actively cooperate with law enforcement and only respond to law enforcement requests from authorized law enforcement officers who can provide proof of authorization
</p>
<p>
    <br/>
</p>
<p>
    We disclose account information records to international law enforcement authorities in accordance with our Terms of Service, Privacy Policy and applicable law. starthestar.com requires law enforcement requests to accompany due process of law in order for us to provide disclosures
</p>
<p>
    <br/>
</p>
<p>
    starthestar.com welcomes questions from law enforcement agencies about its policies and procedures, please contact us at starthestar@starthestar.com
</p>
<p>
    <br/>
</p>
<p>
    When sending a law enforcement request, please include the following information:
</p>
<p>
    <br/>
</p>
<p>
    1. The name of the law enforcement agency;
</p>
<p>
    <br/>
</p>
<p>
    2. Proof that law enforcement officials are authorized to obtain information (certificate of authorization);
</p>
<p>
    <br/>
</p>
<p>
    3. The identity certificate of the law enforcement officer (internal certificate of the law enforcement agency);
</p>
<p>
    <br/>
</p>
<p>
    4. Investigation letter documents issued by law enforcement agencies;
</p>
<p>
    <br/>
</p>
<p>
    5. An email address containing a government domain name;
</p>
<p>
    <br/>
</p>
<p>
    6. Clarify the name of the legal subject corresponding to the information required for law enforcement;
</p>
<p>
    <br/>
</p>
<p>
    7. Request the specific content of customer information;
</p>
<p>
    <br/>
</p>
<p>
    8. Other details, including providing reasonable deadlines and relevant judicial documents required by international law enforcement agencies under international mutual legal assistance treaties.
</p>
<p>
    <br/>
</p>
<p>
    starthestar.com reserves the right to disclose to law enforcement agencies to protect itself and its clients.
</p>
<p>
    <br/>
</p>`,
}