export default {
    title: "币币信息",
    btcusdt: [
        {
            type: 1,
            name: "中文名",
            value: "比特币",
        },
        {
            type: 1,
            name: "英文名",
            value: "Bitcoin",
        },
        {
            type: 1,
            name: "英文简称",
            value: "BTC",
        },
        {
            type: 1,
            name: "研发者",
            value: "Satoshi Nakamoto",
        },
        {
            type: 1,
            name: "核心算法",
            value: "SHA-256",
        },
        {
            type: 1,
            name: "发布日期",
            value: "2009/01/03",
        },
        {
            type: 1,
            name: "总量",
            value: "2100万",
        },
        {
            type: 1,
            name: "区块时间",
            value: "约600秒/块",
        },
        {
            type: 1,
            name: "共识证明",
            value: "POW",
        },
        {
            type: 1,
            name: "风险",
            value: "扩容之争，存在潜在的分叉风险",
        },
        {
            type: 1,
            name: "货币单位",
            value: "BTC/XBT",
        },
        {
            type: 2,
            name: "官方网站",
            value: "https://bitcoin.org/zh_CN/",
        },
        {
            type: 2,
            name: "比特币常见问题",
            value: "https://bitcoin.org/zh_CN/faq",
        },
        {
            type: 2,
            name: "区块查询",
            value: "https://blockchain.info/",
        },
        {
            type: 2,
            name: "比特币论坛",
            value: "https://bitcointalk.org/",
        },
        {
            type: 2,
            name: "市值查询网站",
            value: "https://coinmarketcap.com/",
        },
        {
            type: 3,
            name: "更多",
            value: "https://bitcoin.org/zh_CN/",
        },
    ],
    ethusdt: [
        {
            type: 1,
            name: "中文名",
            value: "以太坊",
        },
        {
            type: 1,
            name: "英文名",
            value: "Ethereum",
        },
        {
            type: 1,
            name: "英文简称",
            value: "ETH",
        },
        {
            type: 1,
            name: "研发者",
            value: "Vitalik Buterin",
        },
        {
            type: 1,
            name: "核心算法",
            value: "Ethash",
        },
        {
            type: 1,
            name: "发布日期",
            value: "2015/3/20",
        },
        {
            type: 1,
            name: "区块时间",
            value: "约15-17秒/块",
        },
        {
            type: 1,
            name: "区块奖励",
            value: "5",
        },
        {
            type: 1,
            name: "货币总量",
            value: "7200万+1872万/年",
        },
        {
            type: 1,
            name: "主要特色",
            value: "含数字货币和智能合约等特色功能",
        },
        {
            type: 2,
            name: "官方网站",
            value: "https://www.ethereum.org/",
        },
        {
            type: 2,
            name: "区块查询",
            value: "https://etherscan.io/",
        },
    ],
    usdthusd: [
        {
            type: 2,
            name: "官方网站",
            value: "https://tether.to/",
        },
        {
            type: 2,
            name: "区块查询",
            value: "https://www.omniexplorer.info/",
        }
    ],
    trxusdt: [
        {
            type: 2,
            name: "项目网站",
            value: "https://tron.network",
        },
        {
            type: 2,
            name: "区块查询",
            value: "https://tronscan.org/#/",
        },
        {
            type: 3,
            name: "白皮书",
            value: "https://s3.amazonaws.com/tron.network/%E7%AE%80%E4%BB%8B%E4%B8%8E%E6%84%BF%E6%99%AF.pdf",
        },
    ],
    bsvusdt: [
        {
            type: 1,
            name: "项目名称",
            value: "Bitcoin SV (BSV)",
        },
        {
            type: 1,
            name: "项目定位",
            value: "比特币 SV 是比特币现金（BCH）的全节点实现。应比特币现金（BCH）矿业巨头 CoinGeek 及其他矿工的要求，团队创建了比特币 SV，旨在为矿工提供明确的比特币现金（BCH）实现选择，并允许企业在其稳固可靠的基础上构建应用程序和网站",
        },
        {
            type: 1,
            name: "代币总量",
            value: "21,000,000 BSV",
        },
        {
            type: 2,
            name: "项目网站",
            value: "https://bitcoinsv.io/",
        },
    ],
    mkrusdt: [
        {
            type: 1,
            name: "项目名称",
            value: "MakerDAO（MKR）",
        },
        {
            type: 1,
            name: "项目定位",
            value: "MakerDAO开发了以太坊上第一个去中心化稳定币Dai，是去中心化金融（DeFi）的基础设施。MKR是Maker系统的治理和权益代币",
        },
        {
            type: 1,
            name: "代币总量",
            value: "1,005,577 MKR",
        },
        {
            type: 1,
            name: "流通总量",
            value: "1,005,577 MKR",
        },
        {
            type: 2,
            name: "项目网站",
            value: "https://makerdao.com",
        },
        {
            type: 2,
            name: "白皮书",
            value: "https://makerdao.com/whitepaper",
        },
        {
            type: 3,
            name: "区块查询",
            value: "https://etherscan.io/token/0x6b175474e89094c44da98b954eedeac495271d0f",
        },
    ],
    linkusdt: [
        {
            type: 1,
            name: "项目名称",
            value: "Link",
        },
        {
            type: 1,
            name: "发行总量",
            value: "1,000,000,000",
        },
        {
            type: 1,
            name: "当前流通量",
            value: "350,000,000",
        },
        {
            type: 1,
            name: "代币价格",
            value: "$0.0914 USD",
        },
        {
            type: 2,
            name: "项目网站",
            value: "https://www.smartcontract.com/",
        },
        {
            type: 2,
            name: "白皮书",
            value: "https://link.smartcontract.com/whitepaper",
        }
    ],
    compusdt: [
        {
            type: 1,
            name: "代币总量",
            value: "10,000,000 COMP",
        },
        {
            type: 1,
            name: "流通量",
            value: "2,561,279 COMP",
        },
        {
            type: 1,
            name: "项目网站",
            value: "https://compound.finance/",
        },
        {
            type: 3,
            name: "白皮书",
            value: "https://compound.finance/documents/Compound.Whitepaper.pdf",
        },
        {
            type: 3,
            name: "区块查询",
            value: "https://etherscan.io/token/0xc00e94cb662c3520282e6f5717214004a7f26888",
        },
    ],
    xmrusdt: [
        {
            type: 1,
            name: "代币总量",
            value: "约1600万 XMR + 工矿",
        },
        {
            type: 1,
            name: "流通数量",
            value: "100%",
        },
        {
            type: 2,
            name: "项目网站",
            value: "https://getmonero.org",
        },
        {
            type: 2,
            name: "区块查询",
            value: "https://xmrchain.net",
        },
        {
            type: 2,
            name: "其他网站",
            value: "https://www.reddit.com/r/Monero",
        },
    ],
    bchusdt: [
        {
            type: 1,
            name: "项目名称",
            value: "Bitcoin Cash Node (BCH)",
        },
        {
            type: 1,
            name: "项目介绍",
            value: `BCH (Bitcoin Cash) 于2020年11月15日进行硬分叉升级。因当前BCHN算力占比具有绝对优势，根据社区与用户反馈，火币全球站将作出如下安排：
            1、BCHN节点方案将继承BCH原有名称、K线和交易对。分叉时受市场情绪影响可能会有大幅波动，请提前做好相应安排和风险控制。理性判断，审慎决策；
            2、BCH分叉后，火币全球站将对全站用户的BCH资产进行快照并空投分叉代币。分叉节点稳定运行后，我们将根据社区反馈并评估是否开设分叉币交易对。`,
        },
        {
            type: 1,
            name: "代币总量",
            value: "21,000,000 BCH",
        },
    ],
    ltcusdt: [
        {
            type: 1,
            name: "中文名",
            value: "莱特币",
        },
        {
            type: 1,
            name: "英文名",
            value: "Litecoin",
        },
        {
            type: 1,
            name: "英文简称",
            value: "LTC",
        },
        {
            type: 1,
            name: "研发者",
            value: "Charlie Lee",
        },
        {
            type: 1,
            name: "核心算法",
            value: "Scrypt",
        },
        {
            type: 1,
            name: "发布日期",
            value: "2011/10/7",
        },
        {
            type: 1,
            name: "区块时间",
            value: "150秒/块",
        },
        {
            type: 1,
            name: "发行总量",
            value: "8400万",
        },
        {
            type: 1,
            name: "减半时间",
            value: "4年",
        },
        {
            type: 1,
            name: "共识证明",
            value: "POW",
        },
        {
            type: 1,
            name: "难度调整",
            value: "2016个区块",
        },
        {
            type: 1,
            name: "区块奖励",
            value: "最初50LTC，当前25LTC",
        },
        {
            type: 2,
            name: "官方网站",
            value: "https://litecoin.org/",
        },
        {
            type: 2,
            name: "区块查询",
            value: "http://explorer.litecoin.net/",
        },
    ],
    neousdt: [
        {
            type: 2,
            name: "官网",
            value: "https://neo.org"
        },
        {
            type: 2,
            name: "白皮书",
            value: "http://docs.neo.org/zh-cn/"
        },
    ],
    eosusdt: [
        {
            type: 2,
            name: "官网",
            value: "https://eos.io/"
        },
        {
            type: 3,
            name: "白皮书",
            value: "https://github.com/EOSIO/Documentation/blob/master/TechnicalWhitePaper.md#support-millions-of-users"
        },
    ],
    etcusdt: [
        {
            type: 2,
            name: "官方网站",
            value: "https://ethereumclassic.org/"
        },
        {
            type: 2,
            name: "白皮书",
            value: "https://github.com/etclabscore"
        },
        {
            type: 2,
            name: "区块浏览器",
            value: "https://blockscout.com/etc/mainnet/"
        },
        {
            type: 2,
            name: "官方推特",
            value: "https://twitter.com/etclabs"
        },
        {
            type: 2,
            name: "Reddit",
            value: "https://www.reddit.com/r/EthereumClassic/"
        },
    ],
    zec: [
        {
            type: 2,
            name: "官方网站",
            value: "https://z.cash/zh/"
        },
        {
            type: 3,
            name: "技术文档",
            value: "http://zerocash-project.org/media/pdf/zerocash-extended-20140518.pdf"
        },
    ],
    adausdt: [
        {
            type: 2,
            name: "官方网站",
            value: "https://cardanofoundation.org"
        },
        {
            type: 2,
            name: "白皮书",
            value: "whycardano.com"
        },
    ],
    dotusdt: [
        {
            type: 2,
            name: "官方网站",
            value: "https://polkadot.network/"
        },
        {
            type: 2,
            name: "白皮书",
            value: "https://polkadot.network/PolkaDotPaper.pdf"
        },
        {
            type: 2,
            name: "区块查询",
            value: "https://polkadot.js.org/apps/#/explorer"
        },
    ],
    trbusdt: [
        {
            type: 2,
            name: "官方网站",
            value: "http://www.tellor.io"
        },
        {
            type: 2,
            name: "白皮书",
            value: "http://www.tellor.io/whitepaper"
        },
        {
            type: 3,
            name: "区块查询",
            value: "https://explore.duneanalytics.com/public/dashboards/pVN6lRN8xxtfhjnEbcPbqreI8DNkPOng5P5fol0v"
        },
    ],
    bhdusdt: [
        {
            type: 2,
            name: "官方网站",
            value: "http://www.tellor.io"
        },
        {
            type: 2,
            name: "白皮书",
            value: "www.btchd.org/BHD-白皮书2.0.pdf"
        },
        {
            type: 2,
            name: "区块查询",
            value: "www.btchd.org/explorer/"
        },
    ],
    qtumusdt: [
        {
            type: 2,
            name: "官方网站",
            value: "https://www.qtum.org/zh/"
        },
        {
            type: 2,
            name: "白皮书",
            value: "https://www.qtum.org/zh/white-papers"
        },
    ],
    bandusdt: [
        {
            type: 2,
            name: "官方网站",
            value: "https://bandprotocol.com/"
        },
        {
            type: 2,
            name: "白皮书",
            value: "https://docs.bandchain.org/whitepaper/"
        },
        {
            type: 2,
            name: "区块查询",
            value: "https://cosmoscan.io/"
        },
    ],
    xtzusdt: [
        {
            type: 2,
            name: "官方网站",
            value: "https://tezos.com/"
        },
        {
            type: 2,
            name: "基金会网站",
            value: "https://tezos.foundation/"
        },
        {
            type: 2,
            name: "白皮书",
            value: "https://tezos.com/static/papers/white_paper.pdf"
        },
        {
            type: 2,
            name: "区块查询",
            value: "https://tezblock.io/"
        },
    ],
    balusdt: [
        {
            type: 2,
            name: "官方网站",
            value: "https://balancer.finance/"
        },
        {
            type: 2,
            name: "白皮书",
            value: "https://balancer.finance/whitepaper/"
        },
        {
            type: 2,
            name: "区块查询",
            value: "https://etherscan.io/address/0xba100000625a3754423978a60c9317c58a424e3d"
        },
    ],
    // 
    imfusdt: [
        {
            type: 1,
            name: "英文名称",
            value: "IMF",
        },
        {
            type: 2,
            name: "白皮书",
            value: "https://starthestar.com/whitepaper/IMF-WhitePaper.pdf"
        }
    ],
}