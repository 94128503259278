export default `<p>
    <strong>DASH（ダッシュ）</strong>
</p>
<p>
    <br/>
</p>
<p>
    1.ダッシュとは何ですか？
</p>
<p>
    に
</p>
<p>
    Dash（英語名DASH）は、オープンソースのピアツーピア（P2P）暗号化通貨であり、即時トランザクション、匿名トランザクション、および代替通貨機能を提供できます。
</p>
<p>
    <br/>
</p>
<p>
    Dashネットワークは、プルーフオブワークマイニング（ビットコインで使用されるコンセンサスメカニズム）メカニズムによって保護されており、11の異なるハッシュ関数に基づくX11アルゴリズムを使用しています。マスターノードはサービス証明レイヤーであり、これらのノードはトランザクションミキサーとブースターとして同時に機能し、さらにダッシュ管理システムの投票メカニズムとしても機能します。
</p>
<p>
    <br/>
</p>
<p>
    ダッシュのブロック報酬は2つのレイヤー（マイナーとマスターノード）に分割されます。その中で、マイナーはリターンの45％を受け取り、マスターノードは45％を受け取り、残りの10％は分散型ダッシュバジェットシステムに割り当てられます。各ブロックの生成時間は2.5分で、各ブロックは鉱夫3.6ダッシュ報酬。詳細は以下のとおりです。
</p>
<p>
    <br/>
</p>
<p>
    2.背景
</p>
<p>
    ビットコインの匿名性とトランザクション処理の速度を考慮して、Evan Duffieldと彼のチームはDASHの研究開発を開始し、2014年1月に正式にDashを設立しました。これまでのところ、Dashはビットコインに基づいて関連する改善を完了し、分散型ネットワークサーバー「マスターノード」によってトランザクションを難読化することによってトランザクションの匿名性を改善しました。同時に、ノードに基づいてトランザクション処理速度が向上し、分散型で匿名化されたダッシュが誕生しました。
</p>
<p>
    <br/>
</p>
<p>
    3.機能
</p>
<p>
    匿名性が高い：ビットコイントランザクションでは、すべてのトランザクションデータがデータブロックチェーンに記録され、ビットコインの所在を確認できるため、受信側と送信側を照会できます。ただし、Dashは匿名送信のテクノロジーと分散型ネットワークサーバーの「マスターノード」を使用してトランザクションを難読化するため、トランザクションを追跡および照会できないため、高い匿名性が実現されます。
</p>
<p>
    に
</p>
<p>
    即時配信：ビットコインネットワークはトランザクションを確認するのに10分または数時間かかり、ダッシュは即座に送信できます。
</p>
<p>
    に
</p>
<p>
    独立した管理システム：このシステムを通じて、Dash Networkは提案と投票を通じてR＆Dとマーケティングにリソースを割り当てることができ、すべてのユーザーがネットワーク上で提案を作成して送信できます。
</p>
<p>
    に
</p>
<p>
    4.頻繁に使用されるリンク
</p>
<p>
    DASH公式ウェブサイト：https：//www.dash.org/
</p>
<p>
    ブロックエクスプローラー：https：//explorer.dash.org/chain/Dash
</p>
<p>
    ウォレットのダウンロード：https：//www.dash.org/
</p>
<p>
    DASHフォーラム：https：//www.dash.org/forum/
</p>
<p>
    ツイッター：https：//twitter.com/Dashpay/with_replies
</p>
<p>
    Facebook：https：//www.facebook.com/DashPay/
</p>
<p>
    <br/>
</p>`