export default {
    title:"Risk notification",
    content:`<p style="text-align: center;">
    <span style="font-size: 18px;"><strong>&quot;Starthestar Risk Notice&quot;</strong></span>
</p>
<p>
    Dear Starthestar platform registered users:
</p>
<p>
    <br/>
</p>
<p>
    In order to enable you to better understand the risks associated with the transaction of encrypted digital assets, the Starthestar platform solemnly reminds you: Before trading encrypted digital assets on the Starthestar platform, you should ensure that you understand and carefully evaluate the risks of encrypted digital asset transactions. Your own risk tolerance, after full consideration, decides to trade encrypted digital assets on the Starthestar platform.
</p>
<p>
    <br/>
</p>
<p>
    Tips on the risks of encrypted digital asset trading on the Starthestar platform
</p>
<p>
    <br/>
</p>
<p>
    1. OTC transactions, currency transactions, leveraged transactions, contract transactions, PoS mining pools, voting for listing and other online content on the Starthestar platform are only provided to qualified users who meet the registration requirements of the Starthestar platform (hereinafter referred to as &quot;&quot; platform users&quot;)
</p>
<p>
    <br/>
</p>
<p>
    2. Platform users shall conduct real-name authentication of registered accounts and supplement and improve personal information.
</p>
<p>
    <br/>
</p>
<p>
    3. Platform users should take good care of their accounts and passwords, do not entrust others to manage their accounts, and do not inform others of their accounts and passwords, otherwise the resulting risks will be borne by the platform users themselves.
</p>
<p>
    <br/>
</p>
<p>
    4. Platform users may face risks in terms of policy risk, compliance risk, revenue risk, transaction risk and force majeure risk in the process of trading encrypted digital assets on the Starthestar platform. The specific risks are as follows:
</p>
<p>
    <br/>
</p>
<p>
    a. Policy risk: If the country (the countries in this notice include the United States of America, Canada, the Republic of Singapore, and the country where the platform users are located) macro policies and relevant laws, regulations and policies change, it may affect encrypted digital assets. normal transactions, which in turn lead to losses for platform users.
</p>
<p>
    <br/>
</p>
<p>
    b. Compliance risk: During the transaction of encrypted digital assets, the risks caused by violation of national laws and regulations shall be borne by the platform users themselves.
</p>
<p>
    <br/>
</p>
<p>
    c. Income risk: Due to the particularity of the encrypted digital asset trading industry, the appreciation or depreciation of encrypted digital assets fluctuates greatly, and platform users should bear the risk of negative trading income and financial income.
</p>
<p>
    <br/>
</p>
<p>
    d. Transaction risk: The successful transfer transaction between platform users is based on the mutual recognition of the transfer behavior by both parties, and the Starthestar platform does not promise or guarantee any successful transaction.
</p>
<p>
    <br/>
</p>
<p>
    e. Force majeure risk: refers to the occurrence of unforeseeable, unavoidable and insurmountable objective situations such as natural disasters, wars, strikes, hacker attacks, etc., which will seriously affect the normal operation of the Starthestar platform, resulting in the loss of platform users or the destruction of encrypted digital assets. theft, etc. Starthestar platform does not assume any civil liability for losses caused by force majeure.
</p>
<p>
    <br/>
</p>
<p>
    f. Offline risk of encrypted digital assets: The developer of encrypted digital assets has bankruptcy, liquidation, dissolution, etc., or because of violation of national laws and regulations, Starthestar will take it offline, or the developer of encrypted digital assets will take the initiative to request the offline to stop trading. risks of.
</p>
<p>
    <br/>
</p>
<p>
    g. Technical risk: In the daily transaction of encrypted digital assets, the failure or error of the technical system may affect the normal operation of the transaction or cause the interests of platform users to be affected.
</p>
<p>
    <br/>
</p>
<p>
    h. Operational risk: the risk caused by platform users due to operational errors or violations of operational procedures.
</p>
<p>
    <br/>
</p>
<p>
    i. Account freezing risk: the risk of being judicially frozen or enforced due to a platform user’s default of debt or suspected illegal or criminal behavior.
</p>
<p>
    <br/>
</p>
<p>
    Notice on Prohibited Behaviors of Cryptocurrency Trading on the Starthestar Platform
</p>
<p>
    <br/>
</p>
<p>
    1. Money laundering, false transactions, illegal fundraising, fraud, etc. are prohibited, and it is not allowed to use the identity of others for registration, and it is not allowed to deliberately conceal or deceive information when conducting KYC verification on the Starthestar platform. The Starthestar platform has the right to take measures such as freezing the account, terminating the use of the account, or hiding or delisting the encrypted digital assets for the platform users who have committed the above-mentioned suspicious behaviors.
</p>
<p>
    <br/>
</p>
<p>
    2. The matters notified in this risk notice are only listed in nature, and cannot list all the risks faced by platform users and all factors that may cause losses. Before trading encrypted digital assets on the Starthestar platform, platform users should carefully read and understand the relevant trading rules, product activity content and other relevant agreements and the entire content of this risk notice, and make sure that they have done enough risk assessment and financial Arrangements to avoid unbearable losses from participating in encrypted digital asset transactions.
</p>
<p>
    <br/>
</p>
<p>
    3. The risk of trading encrypted digital assets on the Starthestar platform shall be borne by the platform users. The Starthestar platform does not in any way make a commitment to the platform users that the principal of their encrypted digital assets will not be lost or that they will obtain certain income.
</p>
<p>
    <br/>
</p>
<p>
    Platform User Commitment
</p>
<p>
    <br/>
</p>
<p>
    Platform users will not conduct any acts on the Starthestar platform that may violate the laws and regulations of the United States of America, Canada, the Republic of Singapore and the countries where the platform users are domiciled; if the platform users may violate the laws or regulations or the platform users&#39; transaction behaviors trigger Shouaa The risk control mechanism of the Global platform, platform users agree that the Starthestar platform has the right to take measures such as freezing accounts and canceling accounts, and has the right to pursue legal responsibilities of platform users.
</p>
<p>
    <br/>
</p>
<p>
    Special reminder: platform users click to agree to this risk notice, indicating that they have understood and are willing to bear the transaction risks and losses themselves, and the Starthestar platform does not undertake any guarantee or joint liability for the return of the principal and income of encrypted digital assets.
</p>
<p>
    <br/>
</p>`,
}