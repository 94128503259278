export default `<p>
    <strong>DOT (Polkadot (DOT))</strong>
</p>
<p>
    <br/>
</p>
<p>
    1. 프로젝트 이름
</p>
<p>
    폴카 닷 (DOT)
</p>
<p>
    <br/>
</p>
<p>
    2. 프로젝트 포지셔닝
</p>
<p>
    Polkadot은 사용자가 완전히 제어 할 수있는 완전히 분산 된 인터넷을 실현할 것입니다. 인터넷은 모든 사람의 신원과 데이터가 중앙 기관의 영향을받지 않고 스스로 제어한다는 것입니다.
</p>
<p>
    Polkadot은 프라이빗 체인, 컨소시엄 체인, 퍼블릭 체인, 개방형 네트워크 및 오라클은 물론 아직 생성되지 않은 미래 기술을 연결하는 것을 목표로합니다. Polkadot은 인터넷에 편리함을 제공하고 독립적 인 블록 체인은 Polkadot의 릴레이 체인을 통해 신뢰할 수없는 방식으로 정보와 거래를 교환 할 수 있습니다.
</p>
<p>
    Polkadot을 사용하면 분산 형 애플리케이션, 서비스 및 기관을 그 어느 때보 다 쉽게 생성 / 연결할 수 있습니다. 혁신가가 더 나은 솔루션을 구축 할 수 있도록 권한을 부여함으로써 우리는 큰 기관이 우리의 신뢰를 침해 할 수없는 끊어진 네트워크에 의존하는 사회를 해방 시키려고합니다.
</p>
<p>
    에
</p>
<p>
    세, 통화 개요 및 배포
</p>
<p>
    총 토큰 : 1,000,000,000 DOT (분할 후)
</p>
<p>
    총 유통량 :-
</p>
<p>
    토큰 가격 :-
</p>
<p>
    에
</p>
<p>
    네, 일반적으로 사용되는 링크
</p>
<p>
    프로젝트 웹 사이트 : https://polkadot.network/
</p>
<p>
    백서 : https://polkadot.network/PolkaDotPaper.pdf
</p>
<p>
    블록 쿼리 : https://polkadot.js.org/apps/#/explorer
</p>
<p>
    <br/>
</p>`