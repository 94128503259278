export default {
    title:"风险告知",
    content:`<p style="text-align: center;">
    <span style="font-size: 18px;"><strong>《Starthestar风险告知书》</strong></span>
</p>
<p>
    尊敬的Starthestar平台注册用户：
</p>
<p>
    <br/>
</p>
<p>
    为了使您更好的了解加密数字资产交易的相关风险，现Starthestar平台郑重提示您：在Starthestar平台进行加密数字资产交易前， 您应确保自己详细了解和审慎评估加密数字资产交易风险，了解自身风险承受能力，在充分考虑后自行决定在Starthestar平台进行加密数字资产交易。
</p>
<p>
    <br/>
</p>
<p>
    关于Starthestar平台加密数字资产交易风险的提示
</p>
<p>
    <br/>
</p>
<p>
    一、Starthestar平台上线的OTC交易、币币交易、杠杆交易、合约交易、PoS矿池、投票上币以及上线的其它内容是仅向符合Starthestar平台注册要求的合格用户提供（以下简称为“平台用户”）
</p>
<p>
    <br/>
</p>
<p>
    二、平台用户应当进行注册账户的实名认证，补充完善个人信息。
</p>
<p>
    <br/>
</p>
<p>
    三、平台用户应当妥善保管好自己的账户及密码，不委托他人管理自己的账户，不将自己的账户与密码告知他人，否则由此导致的风险将由平台用户自行承担。
</p>
<p>
    <br/>
</p>
<p>
    四、平台用户在Starthestar平台进行加密数字资产交易过程中，可能会面临政策风险、合规风险、收益风险、交易风险和不可抗力风险等方面的风险。具体风险如下：
</p>
<p>
    <br/>
</p>
<p>
    a、政策风险：如国家（本告知书中的国家包括美利坚合众国以及加拿大、新加坡共和国以及平台用户住所地国家在内）宏观政策以及相关法律法规政策发生变化，则其将有可能影响加密数字资产的正常交易，进而导致平台用户发生损失。
</p>
<p>
    <br/>
</p>
<p>
    b、合规风险：在加密数字资产交易过程中，若违反国家法律、法规的规定而引起的风险由平台用户自行承担。
</p>
<p>
    <br/>
</p>
<p>
    c、收益风险：由于加密数字资产交易行业的特殊性，加密数字资产的增值或者贬值情况波动较大，平台用户应自行承担交易收益与理财收益为负的风险。
</p>
<p>
    <br/>
</p>
<p>
    d、交易风险：平台用户间转让交易成功是基于交易双方对转让行为的共同认可，Starthestar平台不承诺或者担保任何交易成功的情形。
</p>
<p>
    <br/>
</p>
<p>
    e、不可抗力风险：指自然灾害、战争、罢工、黑客攻击等不能预见、不能避免并不能克服的客观情况的出现，将严重影响Starthestar平台的正常运行，从而导致平台用户损失或者加密数字资产被盗等情形。因发生不可抗力导致的损失，Starthestar平台对此不承担任何民事责任。
</p>
<p>
    <br/>
</p>
<p>
    f、加密数字资产下线风险：加密数字资产的开发者出现破产、清算、解散等事由或者因为违反国家法律法规而导致Starthestar将其下线或者加密数字资产的开发者主动要求下线停止交易的风险。
</p>
<p>
    <br/>
</p>
<p>
    g、技术风险：在加密数字资产日常交易中，可能因技术系统的故障或者差错而影响交易的正常进行或者导致平台用户利益受到影响。
</p>
<p>
    <br/>
</p>
<p>
    h、操作风险：平台用户因操作失误或违反操作规程而引起的风险。
</p>
<p>
    <br/>
</p>
<p>
    i、账户冻结风险：因平台用户出现债务违约或者涉嫌违法犯罪行为而被司法冻结或者强制执行风险。
</p>
<p>
    <br/>
</p>
<p>
    关于在Starthestar平台进行加密数字资产交易的禁止性行为告知
</p>
<p>
    <br/>
</p>
<p>
    一、禁止洗钱、虚假交易、非法集资、诈骗等行为，不得使用他人身份进行注册、不得在Starthestar平台进行KYC核实信息时故意隐瞒或弄虚作假。Starthestar平台有权对上述可疑行为的平台用户采取冻结账户、终止该账户的使用、或者隐藏、下线该加密数字资产等措施。
</p>
<p>
    <br/>
</p>
<p>
    二、本风险告知书的告知事项仅为列举性质，未能详尽列明平台用户所面对的全部风险和可能遭受损失的所有因素。平台用户在Starthestar平台进行加密数字资产交易前，应认真阅读并理解相关的交易规则、产品活动内容等相关协议及本风险告知书的全部内容，并确信自身已做好足够的风险评估与财务安排，避免因参与加密数字资产交易而遭受难以承受的损失。
</p>
<p>
    <br/>
</p>
<p>
    三、在Starthestar平台进行加密数字资产交易的风险由平台用户自行承担，Starthestar平台不以任何方式向平台用户作出其加密数字资产本金不受损失或其取得一定收益的承诺。
</p>
<p>
    <br/>
</p>
<p>
    平台用户承诺
</p>
<p>
    <br/>
</p>
<p>
    平台用户不会在Starthestar平台上进行任何可能违反美利坚合众国以及加拿大、新加坡共和国以及平台用户住所地国家的法律法规的行为；若平台用户出现了可能违法违规行为或者平台用户的交易行为触发了Starthestar平台的风控机制，平台用户同意Starthestar平台有权采取冻结账户、注销账户等措施，并有权追究平台用户的法律责任。
</p>
<p>
    <br/>
</p>
<p>
    特别提示：平台用户点击同意本风险告知书，则表示已经理解并愿意自行承担交易风险和损失，Starthestar平台不承担任何返还加密数字资产本金及收益的保证或者连带责任。
</p>
<p>
    <br/>
</p>`,
}