export default {
    pt1: "1.用户登录STARTHESTAR后，点击“账户信息”进入账户信息界面，",
    pimg1: "k3",
    pt2: "2.点击支付密码栏的“设置”，进入支付密码设置界面；",
    pimg2: "k3",
    pt3: "3.输入密码，再次重复输入密码，点击“提交”后即设置支付密码成功。",
    pimg3: "k3",
    pt4: "4.登录密码的修改在账户信息界面，点击登录密码栏的“修改”进入登录密码修改界面；",
    pimg4: "k3",
    pt5: "5.输入原始密码，再输入新密码，确认新密码，点击“提交”即完成登录密码的修改。",
    pimg5: "k3",
    pt6: "STARTHESTAR温馨提示您：请妥善保存您的STARTHESTAR账号的支付密码及登录密码。",
}