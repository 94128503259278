export default {
    title:"リスク通知",
    content:`<p style="text-align: center;">
    <span style="font-size: 18px;"><strong>「Shouaaグローバルリスク通知」</strong></span>
</p>
<p>
    親愛なるShouaaグローバルプラットフォーム登録ユーザー：
</p>
<p>
    <br/>
</p>
<p>
    暗号化されたデジタル資産取引に関連するリスクをよりよく理解するために、Starthestarプラットフォームは厳粛に思い出させます：Starthestarプラットフォームで暗号化されたデジタル資産を取引する前に、暗号化された取引リスクを理解し、注意深く評価する必要がありますデジタル資産：あなた自身のリスク許容度は、十分に検討した後、暗号化されたデジタル資産をShouaaGlobalプラットフォームで取引することを決定します。
</p>
<p>
    <br/>
</p>
<p>
    Shouaaグローバルプラットフォームでの暗号化されたデジタル資産取引のリスクに関するヒント
</p>
<p>
    <br/>
</p>
<p>
    1. OTC取引、通貨取引、レバレッジ取引、契約取引、PoSマイニングプール、Starthestarプラットフォームでのリストへの投票、およびその他のオンラインコンテンツは、Starthestarプラットフォーム（以下、以下を参照）の登録要件を満たす資格のあるユーザーにのみ提供されます。 「」プラットフォームユーザーとして）
</p>
<p>
    <br/>
</p>
<p>
    2.プラットフォーム利用者は、登録口座の実名認証を行い、個人情報を補足・改善するものとします。
</p>
<p>
    <br/>
</p>
<p>
    3.プラットフォームユーザーは、自分のアカウントとパスワードに十分注意し、他人に自分のアカウントの管理を任せたり、自分のアカウントとパスワードを他人に知らせたりしないでください。そうしないと、結果として生じるリスクはプラットフォームユーザー自身が負担します。
</p>
<p>
    <br/>
</p>
<p>
    4.プラットフォームのユーザーは、Starthestarプラットフォームで暗号化されたデジタル資産を取引する過程で、ポリシーリスク、コンプライアンスリスク、収益リスク、トランザクションリスク、および強制的な巨大リスクの観点からリスクに直面する可能性があります。具体的なリスクは次のとおりです。
</p>
<p>
    <br/>
</p>
<p>
    a。ポリシーリスク：国（この通知に記載されている国には、アメリカ合衆国、カナダ、シンガポール共和国、およびプラットフォームユーザーが所在する国が含まれます）のマクロポリシーおよび関連する法律、規制、ポリシーが変更された場合、暗号化されたデジタル資産に影響を与える可能性があります。通常のトランザクションは、プラットフォームユーザーの損失につながります。
</p>
<p>
    <br/>
</p>
<p>
    b。コンプライアンスリスク：暗号化されたデジタル資産の取引中、国内法および規制の違反によって引き起こされるリスクは、プラットフォームユーザー自身が負担するものとします。
</p>
<p>
    <br/>
</p>
<p>
    c。収入リスク：暗号化されたデジタル資産取引業界の特殊性により、暗号化されたデジタル資産の評価または減価償却は大きく変動し、プラットフォームユーザーは負の取引収入および金融収入のリスクを負う必要があります。
</p>
<p>
    <br/>
</p>
<p>
    d。トランザクションリスク：プラットフォームユーザー間の転送トランザクションの成功は、両当事者による転送動作の相互認識に基づいており、ShouaaGlobalプラットフォームはトランザクションの成功を約束または保証しません。
</p>
<p>
    <br/>
</p>
<p>
    e。不可抗力リスク：自然災害、戦争、ストライキ、ハッカー攻撃など、予測不可能、不可避、および克服できない客観的状況の発生を指します。これは、Starthestarプラットフォームの通常の運用に深刻な影響を及ぼし、損失をもたらします。プラットフォームユーザーの破壊または暗号化されたデジタル資産の破壊、盗難など。 Starthestarプラットフォームは、不可抗力によって引き起こされた損失に対する民事責任を負いません。
</p>
<p>
    <br/>
</p>
<p>
    f。暗号化されたデジタル資産のオフラインリスク：暗号化されたデジタル資産の開発者は破産、清算、解散などを持っているか、国内法および規制に違反しているため、Starthestarはそれをオフラインにするか、暗号化されたデジタル資産の開発者は取引を停止するためにオフラインを要求するイニシアチブを取ります。
</p>
<p>
    <br/>
</p>
<p>
    g。技術的リスク：暗号化されたデジタル資産の日常のトランザクションでは、技術システムの障害またはエラーがトランザクションの通常の動作に影響を及ぼしたり、プラットフォームユーザーの利益に影響を及ぼしたりする可能性があります。
</p>
<p>
    <br/>
</p>
<p>
    h。オペレーショナルリスク：オペレーショナルエラーまたはオペレーショナル手順の違反によりプラットフォームユーザーが引き起こすリスク。
</p>
<p>
    <br/>
</p>
<p>
    i。アカウントの凍結リスク：プラットフォームユーザーの債務不履行または違法または犯罪行為の疑いにより、司法的に凍結または執行されるリスク。
</p>
<p>
    <br/>
</p>
<p>
    Shouaaグローバルプラットフォームでの暗号通貨取引の禁止されている行動に関する通知
</p>
<p>
    <br/>
</p>
<p>
    1.マネーロンダリング、虚偽の取引、違法な資金調達、詐欺などは禁止されており、登録のために他人の身元を使用することは許可されていません。グローバルプラットフォーム。 Starthestarプラットフォームは、アカウントの凍結、アカウントの使用の終了、または上記の疑わしい行動を行ったプラットフォームユーザーの暗号化されたデジタル資産の非表示または除外などの措置を講じる権利を有します。
</p>
<p>
    <br/>
</p>
<p>
    2.このリスク通知で通知される事項は、本質的にリストされているだけであり、プラットフォームユーザーが直面するすべてのリスクおよび損失を引き起こす可能性のあるすべての要因をリストすることはできません。 Starthestarプラットフォームで暗号化されたデジタル資産を取引する前に、プラットフォームユーザーは、関連する取引ルール、製品活動の内容、その他の関連する契約、およびこのリスク通知の内容全体を注意深く読んで理解し、十分なリスク評価を行っていることを確認する必要があります。暗号化されたデジタル資産取引に参加することによる耐え難い損失を回避するための財政的取り決め。
</p>
<p>
    <br/>
</p>
<p>
    3. Starthestarプラットフォームで暗号化されたデジタル資産を取引するリスクは、プラットフォームユーザーが負担するものとします。ShouaaGlobalプラットフォームは、暗号化されたデジタル資産のプリンシパルが失われないことをプラットフォームユーザーに約束するものではありません。または彼らは一定の収入を得るでしょう。
</p>
<p>
    <br/>
</p>
<p>
    プラットフォームユーザーのコミットメント
</p>
<p>
    <br/>
</p>
<p>
    プラットフォームユーザーは、アメリカ合衆国、カナダ、シンガポール共和国、およびプラットフォームユーザーが居住する国の法律および規制に違反する可能性のあるStarthestarプラットフォームに対して、いかなる行為も行わないものとします。プラットフォームユーザーが法律に違反する可能性がある場合または規制またはプラットフォームユーザーのトランザクション動作がShouaaをトリガーするグローバルプラットフォームのリスク管理メカニズムであるプラットフォームユーザーは、Shouaaグローバルプラットフォームがアカウントの凍結やアカウントのキャンセルなどの措置を講じる権利を持ち、法的を追求する権利を持っていることに同意しますプラットフォームユーザーの責任。
</p>
<p>
    <br/>
</p>
<p>
    特別な注意：プラットフォームユーザーは、このリスク通知に同意するためにクリックします。これは、取引のリスクと損失を理解し、負担する意思があることを示します。ShouaaGlobalプラットフォームは、元本の返還についていかなる保証または連帯責任も負いません。暗号化されたデジタル資産の収入。
</p>
<p>
    <br/>
</p>`,
}