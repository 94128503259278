export default `<p>
    KNC (Kyber Network)
</p>
<p>
    <br/>
</p>
<p>
    <strong>1. 카이 버 네트워크 (KNC) 란?</strong>
</p>
<p>
    <br/>
</p>
<p>
    카이 버 네트워크는 제 3자를 신뢰할 필요가없는 탈 중앙화 거래소로 암호화 된 자산 간의 즉각적인 변환 / 교환이 가능합니다. 카이 버 네트워크를 통해 사용자는 Token A를 사용하여 Token B 지불을 받고자하는 해당 수신자에게 지불 할 수 있으며 전체 프로세스가 한 단계에 있습니다. 뿐만 아니라 카이 버 네트워크는 특정 토큰 만 허용하는 기존 지갑이 기존 또는 미래 토큰 (예 : PAY 및 CVC)을 수락 할 수있는 새로운 스마트 계약 인터페이스를 출시했습니다. 즉, 카이 버 네트워크를 통해 스마트 계약 및 결제 수신자가 더 광범위한 사용자에게 도달하고 해당 플랫폼에서 지원하는 모든 토큰으로 결제를받을 수 있습니다.
</p>
<p>
    <br/>
</p>
<p>
    카이 버 네트워크는 리저브 매니저, 리저브 기여자, 다이내믹 리저브 풀, 리저브 관리를위한 스마트 계약을 포함하여 유동성, 무 신뢰성 및 토큰의 즉각적인 교환을 보장하는 혁신적인 방법을 사용합니다. 앞서 언급 한 참여자들은 각각 다른 방식으로 스마트 컨트랙트와 상호 작용하며, 카이 버 네트워크의 다이나믹 리저브는 사용자의 요청을 충족시키고 모든 토큰의 전환을 한 번에 실현할 수 있습니다.
</p>
<p>
    <br/>
</p>
<p>
    또한 카이 버 네트워크는 다수의 적립금이 공존 할 수 있도록하며, 플랫폼은 사용자에게 일련의 비교에서 최고의 환율을 제공 할 수 있습니다. 운영자 외에도 카이 버 네트워크와 관련이없는 개인 또는 조직도이 시스템에 등록하여 전체 예비금 풀에 기여하고 개인 자산의 예비 관리자가 될 수 있습니다. 이 경우 카이 버 네트워크는 실제로 거래량이 적은 토큰에 대해 더 많은 교환 및 거래 채널을 제공합니다. 카이 버 네트워크는 독립적 인 리저브 매니저가 관리하는 리저브 펀드를 보유하지 않지만 (이 펀드는 리저브 계약에 저장됩니다), 그들의 리저브 계약이 카이 버 네트워크 플랫폼의 조건을 준수하는지 확인하십시오.
</p>
<p>
    <br/>
</p>
<p>
    카이 버 네트워크는 리저브 매니저가 다른 유휴 자산을 토큰화할 수있는 플랫폼을 만들었습니다. 사용자의 거래 요청에 대한 서비스를 제공함으로써 예약 기관은 가격 스프레드에서 이익을 얻습니다. 카이 버 네트워크는 지갑 제공자와 협력하여 더 많은 트래픽을 확보하고, 다양한 다른 토큰 프로젝트와 카이 버 네트워크 내 네트워크 효과를 결합하고 있으며, 예약 관리자는 거래량으로부터 직접적인 혜택을받을 수 있습니다.
</p>
<p>
    <br/>
</p>
<p>
    또한, 카이 버 네트워크는 예약 관리자가 예약 인 / 아웃 거래를 관리 할 수 있도록 예약 정보 패널 소프트웨어도 제공합니다. 예비금 정보 패널에는 예비금 관리자가 자동으로 가격을 설정하고 투자 포트폴리오를 재조정 할 수 있도록 표준 및 인기있는 거래 알고리즘 / 전략이 포함됩니다. 플랫폼의 이러한 유연성을 통해 예비 관리자는 언제 어디서나 자신의 전략을 구현하고 배포 할 수 있습니다.
</p>
<p>
    <br/>
</p>
<p>
    <strong>2. 공통 링크</strong>
</p>
<p>
    <br/>
</p>
<p>
    공식 웹 사이트 : https://kyber.network/
</p>
<p>
    백서 : https://cn.kyber.network/assets/KyberNetworkWhitepaper-cn.pdf
</p>
<p>
    <br/>
</p>`