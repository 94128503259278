export default {
    title:"KYCおよびマネーロンダリング防止協定",
    content:`<p style="text-align: center;">
    <span style="font-size: 18px;"><strong>KYCおよびマネーロンダリング防止プロトコル</strong></span>
</p>
<p>
    コブディグローバル
</p>
<p>
    <br/>
</p>
<p>
    1はじめに
</p>
<p>
    &nbsp; &nbsp;1.1私たちは、顧客を知ることおよびマネーロンダリング防止に関連する法律および規制を慎重に遵守し、顧客を知ることおよびマネーロンダリング防止のポリシーに故意に違反しないことを約束します。私たちの合理的な管理の範囲内で、私たちの財政的に必要な手段と技術は、犯罪容疑者のマネーロンダリングによって引き起こされる損失からあなたを保護するために、可能な限りあなたに安全なサービスを提供します
</p>
<p>
    <br/>
</p>
<p>
    1.2私たちのKnowYour CustomerおよびAnti-MoneyLaunderingポリシーは、あなたが所属するさまざまな法的管轄区域のKnow YourCustomerおよびAnti-MoneyLaunderingポリシーを含む、包括的な国際ポリシーシステムです。当社の堅牢なコンプライアンスフレームワークにより、規制要件と規制レベルをローカルおよびグローバルに確実に準拠し、このWebサイトの継続的な運用を保証します。
</p>
<p>
    <br/>
</p>
<p>
    2次のように顧客とマネーロンダリング防止ポリシーを理解します。
</p>
<p>
    2.1顧客を知るポリシーとマネーロンダリング防止ポリシーを公布し、対応する法律や規制によって設定された基準を満たすように随時更新します
</p>
<p>
    <br/>
</p>
<p>
    2.2このウェブサイトの運営に関するいくつかの指針と規則を公布し、更新します。当社の従業員は、原則と規則の指導に従ってフルサービスを提供します。
</p>
<p>
    <br/>
</p>
<p>
    2.3厳格な手段による身元の確認、マネーロンダリング防止作業を担当する専門家チームの設置など、トランザクションの内部監視および制御の手順を設計および完了します。
</p>
<p>
    <br/>
</p>
<p>
    2.4リスク防止アプローチを使用して、クライアントのデューデリジェンスと継続的な監視を実施する
</p>
<p>
    <br/>
</p>
<p>
    2.5発生した取引のレビューと定期検査
</p>
<p>
    <br/>
</p>
<p>
    2.6疑わしい取引を管轄当局に報告する
</p>
<p>
    <br/>
</p>
<p>
    2.7事前の通知なしに規制当局に提出された場合、身分証明書、住所の証明、および取引記録は少なくとも6年間保持されます。
</p>
<p>
    <br/>
</p>
<p>
    2.8取引プロセス全体を通じて、クレジットカードの使用は禁止されています
</p>
<p>
    <br/>
</p>
<p>
    2.9関係当局が提供する定期的なトレーニングに参加し、定期的に従業員をトレーニングする
</p>
<p>
    <br/>
</p>
<p>
    3身元情報と確認確認
</p>
<p>
    &nbsp; &nbsp;3.1ID情報
</p>
<p>
    <br/>
</p>
<p>
    &nbsp; &nbsp;3.1.1管轄区域や事業体の種類によって異なる規制により、当社が収集するお客様の情報の内容に一貫性がない場合があります。原則として、以下の情報は登録された個人から収集されます。
</p>
<p>
    <br/>
</p>
<p>
    &nbsp; &nbsp;基本的な個人情報：氏名、住所（および異なる場合は本籍地）、生年月日、国籍など、その他の情報を入手できます。認証は、パスポート、IDカード、またはさまざまな管轄区域によって要求およびトリガーされるその他の身分証明書など、公的機関または他の同様の機関によって発行された文書に基づく必要があります。ご提供いただいた住所は、旅客輸送手形や料金手形の確認、投票者登録の確認など、適切な方法で確認されます。
</p>
<p>
    <br/>
</p>
<p>
    有効な写真：登録する前に、胸にIDを持っている写真を提出する必要があります
</p>
<p>
    <br/>
</p>
<p>
    連絡先：電話/携帯電話番号と有効なメールアドレス
</p>
<p>
    <br/>
</p>
<p>
    3.1.2あなたが会社またはその他の法人である場合、私たちはあなたまたは信託口座の最終的な受益者を特定するために以下の情報を収集します
</p>
<p>
    <br/>
</p>
<p>
    会社の登録および登録証明書;会社の定款および覚書のコピー;会社の株式保有構造および所有権声明の詳細な証明書資料、このWebサイトでの口座開設の決定、および承認されたクライアントの取締役会決議の実施を証明する;必要に応じて、会社の取締役、大株主の身分証明書、およびこのWebサイトのアカウントの承認された署名者;会社の主な事業所の住所が会社の郵送先住所と異なる場合は、郵送先住所を提供します。会社の住所が主要な事業所の住所と一致しない場合は、リスクの高い顧客と見なされ、追加の書類を提出する必要があります。
</p>
<p>
    <br/>
</p>
<p>
    当社が要求する他の認証および当局によって発行された文書、および異なる法域および異なる種類の事業体における異なる規制に従って当社が必要とみなす文書
</p>
<p>
    <br/>
</p>
<p>
    3.1.3身元情報は英語、日本語、韓国語で受け付けます。そうでない場合は、身元情報を英語、日本語、韓国語に翻訳して公証してください。
</p>
<p>
    <br/>
</p>
<p>
    3.2確認と検証
</p>
<p>
    <br/>
</p>
<p>
    3.2.1身分証明書の両面を提供する必要があります
</p>
<p>
    <br/>
</p>
<p>
    3.2.2身分証明書を胸に持っている写真を提供してください
</p>
<p>
    <br/>
</p>
<p>
    3.2.3補足文書のコピーは、通常、元の文書と照合する必要があります。ただし、信頼できる適切な認証者が、コピーがオリジナルの正確で完全な複製であることを証明できる場合、そのコピーは許容されます。そのような認証者には、大使、司法委員、治安判事などが含まれます。
</p>
<p>
    <br/>
</p>
<p>
    3.2.4最終的な受益者とアカウント管理を特定するための要件は、直接の顧客を最終的に所有または管理する個人を特定すること、および/または進行中の取引が他者に代わって実行されることを特定することです。事業の場合、大株主（10％以上の議決権を有する株主など）の身元を確認する必要があります。通常、株式の25％を保有することは通常のリスクとみなされ、株主の身元を確認する必要があります。10％以上の議決権または株式を保有する場合はリスクが高いと見なされ、株主の身元を確認する必要があります。
</p>
<p>
    <br/>
</p>
<p>
    4トランザクションを監視する
</p>
<p>
    4.1 1日の取引および引き出しの上限は、セキュリティおよび実際の取引条件に応じて随時設定および調整されます。
</p>
<p>
    <br/>
</p>
<p>
    4.2取引が登録されたあなたに頻繁に集中している場合、または合理的ではない場合、当社の専門家チームが疑わしいかどうかを評価および決定します
</p>
<p>
    <br/>
</p>
<p>
    4.3当社は、当社の判断により疑わしい取引と特定された場合、取引を一時停止し、取引およびその他の制限措置を拒否し、可能な限り取引を取り消し、同時に所管官庁に報告する場合がありますが、通知されません
</p>
<p>
    <br/>
</p>
<p>
    4.4当社は、国際的なマネーロンダリング防止基準を満たしていない、または政治的に暴露された人物と見なされる可能性のある法域の人物からの搭乗申請を拒否する権利を留保し、疑わしいと判断した取引を一時停止または終了する権利を留保します。時間ですが、私たちはあなたに対するいかなる義務や責任にも違反しません
</p>
<p>
    <br/>
</p>`,
}