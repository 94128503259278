export const GetFixed = (number) => {
    number = parseFloat(number)
    if (number >= 1000) {
        return number.toFixed(2)
    } else if (number >= 1 && number < 1000) {
        return number.toFixed(4)
    } else if (number < 1) {
        return number.toFixed(6)
    }
    return number
}


//新能源接入时兼容
export const GetNewFixed = (number, num = 0) => {
    number = parseFloat(number)
    if (num > 0) {
        return number.toFixed(num)
    }
    return GetFixed(number)
}

export const Float8 = (number) => {
    return parseFloat(number.toFixed(8))
}


/**
 * 科学计数法转换
 */
export const ScToNumber = (num) => {
    var str = num.toString();
    var reg = /^(\d+)(e)([\\-]?\d+)$/;
    var arr, len,
        zero = '';

    /*6e7或6e+7 都会自动转换数值*/
    if (!reg.test(str)) {
        return num;
    } else {
        /*6e-7 需要手动转换*/
        arr = reg.exec(str);
        len = Math.abs(arr[3]) - 1;
        for (var i = 0; i < len; i++) {
            zero += '0';
        }
        return '0.' + zero + arr[1];
    }
}

/**
 * 计算时间差
 */
export const getDay = (seconds) => {
    if (seconds <= 0) {
        seconds = 0
    }
    // var ms = Math.abs(date2 - date1);
    // 两个日期相减得到毫秒数  1s=1000ms
    var ms = seconds * 1000
    var s = parseInt(ms / 1000); // 毫秒转为秒
    var d1 = 3600 * 24
    var day = Math.floor(s / d1) //天
    s = s - (day * d1)
    var hour = Math.floor(s / 3600); //小时
    s = s - (hour * 3600)
    var minute = Math.floor(s / 60); //分
    s = s - (minute * 60)
        // return day + " Day " + hour + " Hour " + minute + " Minute " + s + " S";
        // return day + " Day " + hour + " H " + minute + " M " + s + " S";
    return (hour > 9 ? hour : '0' + hour) + ":" + (minute > 9 ? minute : '0' + minute) + ":" + (s > 9 ? s : '0' + s);
}

/**
 * 计算时间差 [秒]
 */
export const getDayBySecond = (s) => {
    var d1 = 3600 * 24;
    var day = Math.floor(s / d1); //天
    s = s - day * d1;
    var hour = Math.floor(s / 3600); //小时
    s = s - hour * 3600;
    var minute = Math.floor(s / 60); //分
    s = s - minute * 60;

    if (hour <= 9) {
        hour = '0' + hour
    }
    if (minute <= 9) {
        minute = '0' + minute
    }
    if (s <= 9) {
        s = '0' + s
    }
    return day + ' Day ' + hour + ' Hour ' + minute + ' Minute ' + s + ' S';
}

/**
 * 计算时间差 [秒]
 */
export const getMinuteBySecond = (s) => {
    var d1 = 3600 * 24;
    var day = Math.floor(s / d1); //天
    s = s - day * d1;
    var hour = Math.floor(s / 3600); //小时
    s = s - hour * 3600;
    var minute = Math.floor(s / 60); //分
    s = s - minute * 60;

    if (hour <= 9) {
        hour = '0' + hour
    }
    if (minute <= 9) {
        minute = '0' + minute
    }
    if (s <= 9) {
        s = '0' + s
    }
    return minute + ' Minute ' + s + ' S';
}

/**
 * 子组件通信
 */
import Vue from 'vue';
export const Event = new Vue();