export default {
    pt1: "1.ユーザーがSTARTHESTARにログインした後,[アセット]をクリックし,[マイウォレット]をクリックしてマイアセットインターフェイスに入ります;",
    pimg1: "k6",
    pt2: "2. [マイアセット]インターフェイスで,[撤回]をクリックします。",
    pimg2: "k6",
    pt3: "3。ユーザーが引き出す通貨を選択します。たとえば,ユーザーがBTCを引き出したい場合は,BTC通貨を選択し,他の通貨を引き出し,対応する通貨を選択してから,プラットフォームで対応する通貨を選択します。通貨を入金する必要があります入金先住所を\"住所\"欄に貼り付け,引き出すコインの数を入力して\"OK\"をクリックします。",
    pimg3: "k6",
    pt4: "4。ユーザーが引き出しプロセスを完了した後,STARTHESTARプラットフォームが引き出し情報を確認するのを待ちます（注:引き出し金額は現在最低1000米ドルに設定されています。引き出し申請を複数回提出しないでください。ユーザーは,マイアセットインターフェースで自分自身を表示して,引き出し操作が完了したかどうかを判断するための凍結資産があるかどうかを確認できます。プラットフォームが処理されるのを待った後,凍結資産はクリアされ,プラットフォームが引き出しアプリケーションに合格し,撤退）。",
    pt5: "STARTHESTARはあなたに思い出させます:",
    pt6: "①コインを引き出すときは,対応する通貨預金プラットフォームのウォレットアドレスに注意してください。エラーを入力しないでください。入力しないと,資産が取得されません。",
    pt7: "②USDTを引き出すときは,USDTに対応するブロック（ERC20,TRC20,OMNI）に注意してください。間違って入力しないでください。そうしないと,資産が取得されません。",
}