export default `<p>
    <strong>BAL (밸런서)</strong>
</p>
<p>
    <br/>
</p>
<p>
    1. 프로젝트 이름
</p>
<p>
    밸런서 (BAL)
</p>
<p>
    <br/>
</p>
<p>
    2. 프로젝트 포지셔닝
</p>
<p>
    Balancer는 프로그래밍 가능한 유동성을위한 AMM (Automatic Market Making) 프로토콜입니다. 이를 통해 지속적으로 자체 조정되는 유연한 유동성 풀을 만들 수 있습니다. 이러한 유동성 풀에는 가중치가 다른 여러 토큰과 맞춤형 거래 수수료가 포함될 수 있습니다. Balancer 풀은 일종의 인덱스 펀드로 생각할 수 있으며, 유동성 공급자는 전통적인 금융과 같은 유지 보수 비용을 지불하는 대신 보상을받을 수 있습니다. 에
</p>
<p>
    세, 통화 개요 및 배포
</p>
<p>
    총 토큰 : 35,735,000 BAL
</p>
<p>
    총 토큰 한도 : 100,000,000 BAL (소셜 계약)
</p>
<p>
    총 유통량 : 6,943,831 BAL
</p>
<p>
    토큰 가격 :
</p>
<p>
    <br/>
</p>
<p>
    네, 일반적으로 사용되는 링크
</p>
<p>
    프로젝트 웹 사이트 : https://balancer.finance/
</p>
<p>
    백서 : https://balancer.finance/whitepaper/
</p>
<p>
    블록 쿼리 : https://etherscan.io/address/0xba100000625a3754423978a60c9317c58a424e3d
</p>
<p>
    <br/>
</p>`