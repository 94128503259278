<template>
<!-- 第一块-->
<div class="route-box">
    <div class="has-bg-level-1 theme--dark view-page-user-login">
        <div class="" style="height: 850px;">
            <div class="box has-bg-level-2 views-page-login-box-width px-7 pt-8 pb-7">
                <div class="myTitle mb-3">
                    <div class="gang has-line-primary mr-4"></div>
                    <span class="title-large has-text-white">{{$t('login.title')}}</span>
                </div>
                <div class="mt-3 mb-7 body-6"><span class="has-text-white">{{$t('login.desc1')}}</span>
                    <span class="has-text-primary"> {{$t('base.title')}} </span>
                    <span class="has-text-white">{{$t('login.desc2')}}</span>
                </div>

                <div class="mb-3 has-text-level-2 body-5">{{$t('login.email')}}</div>
                <input type="text" class="input" v-model="params.email" :placeholder="$t('login.email_desc')">
                <div class="pt-2" style="height: 26px;display: none;">
                    <!-- <div class="body-3 has-text-tip-error">该字段不能为空</div> -->
                </div>

                <div class="mb-3 mt-4 has-text-level-2 body-5">{{$t('login.password')}}</div>
                <div class="input-with-components">
                    <div class="input px-1">
                        <input type="password" class="input without-border in-input" v-model="params.password" :placeholder="$t('login.password_desc')">
                        <!-- <i class="iconfont mx-2 icon-zichanzhengyan -icon-yincang"></i> -->
                    </div>
                </div>
                <div class="pt-2 forgetPwd" style="height: 26px;display: block;">
                    <!-- <div class="body-3 has-text-tip-error forgetPwd-left">该字段不能为空</div> -->
                    <div class="has-text-right forgetPwd-right body-3">
                        <router-link :to="{name:'Retrieve'}" class="has-text-primary">{{$t('login.forgot_password')}}？</router-link>
                    </div>
                </div>
                <button :disabled="btn_disabled" class="button mt-7 has-bg-primary is-fullwidth has-text-white body-5 mb-5" @click="onLogin">{{$t('login.login')}}</button>
                <div class="has-text-centered has-text-white body-4 ">{{$t('login.no_account')}}？<router-link to="signup" class="has-text-primary">{{$t('signup.signup')}}</router-link>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    login
} from '@/api/auth'
export default {
    name: "Login.vue",
    data() {
        return {
            //登录按钮状态
            btn_disabled: false,
            //登录参数
            params: {
                email: '',
                password: '',
            }
        }
    },
    watch: {
        'params.email'() {
            if (this.params.email == '' || this.params.password == '') {
                this.btn_disabled = true
            } else {
                this.btn_disabled = false
            }
        },
        'params.password'() {
            if (this.params.email == '' || this.params.password == '') {
                this.btn_disabled = true
            } else {
                this.btn_disabled = false
            }
        }
    },
    created() {
    },
    destroyed() {

    },
    methods: {
        validParam() {
            if (!this.params.email) {
                this.$message({
                    message: this.$t("message.invalid_email"),
                    type: "warning",
                });
                return false
            }

            if (!this.params.password) {
                this.$message({
                    message: this.$t("message.invalid_password"),
                    type: "warning",
                });
                return false
            }
            return true
        },
        onLogin() {
            let _this = this
            if (!this.validParam()) {
                return
            }

            // loading
            this.$ui.openLoading()

            login(this.params).then(res => {
                // close
                _this.$ui.closeLoading()
                if (res.code == 1) {
                    this.$message({
                        message: res.msg,
                        type: "warning",
                    });
                    return
                }

                _this.$message({
                    message: res.msg,
                    type: "success",
                });

                //登录账号
                localStorage.setItem('token', res.data.token)
                localStorage.setItem('uid', res.data.uid)
                localStorage.setItem('email', res.data.email)
                localStorage.setItem('is_auth', res.data.is_auth)

                setTimeout(() => {
                    _this.$router.push({name:"Index"})
                }, 1000);
            }).catch(() => {
                // close
                _this.$ui.closeLoading()
            })
        }
    }
}
</script>

<style src="../../static/css/login.css" scoped type="text/css"></style>
