export default `<h2 style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:48px">
<strong><span style="font-family: Times New Roman;font-size: 16px;background: rgb(255, 255, 255)">EOS (EOS)</span></strong>
</h2>
<p style=";padding: 0;line-height: 48px;background: rgb(255, 255, 255)">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<strong><span style="font-family: &#39;Times New Roman&#39;">1.<span style="font-family:宋体">What is</span><span style="font -family:Times New Roman">EOS</span><span style="font-family:宋体">? </span></span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style=";font-family:Calibri;font-size:16px">EOS (Enterprise Operation System)<span style="font-family:宋体">is created by</span><span style="font- family:Calibri">Block.one</span><span style="font-family:宋体">a brand-new blockchain-based smart contract platform led by the company to provide the bottom layer for high-performance distributed applications Blockchain platform services. </span><span style="font-family:Calibri">EOS </span><span style="font-family:宋体">The goal of the project is to realize an operating system-like area that supports distributed applications Block chain architecture. The architecture can provide accounts, identity authentication, databases, asynchronous communication and tens of thousands of</span><span style="font-family:Calibri">CPU/GPU</span><span style="font -family:宋体">Program scheduling and parallel operations on the cluster. </span><span style="font-family:Calibri">EOS</span><span style="font-family:宋体"> can eventually support the execution of millions of transactions per second, while ordinary users perform smart There is no use fee for the contract. </span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<strong><span style="font-family: &#39;Times New Roman&#39;">2.EOS<span style="font-family:宋体"> features</span></span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style=";font-family:Calibri;font-size:16px">EOS<span style="font-family:宋体">The token is currently</span><span style="font-family:Calibri ">EOS</span><span style="font-family:宋体">The Ethereum-based tokens released by the blockchain infrastructure mainly have three main application scenarios: bandwidth and log storage</span><span style ="font-family:Calibri">(</span><span style="font-family:宋体">hard disk</span><span style="font-family:Calibri">)</span><span style="font-family:宋体">, calculation and calculation reserve</span><span style="font-family:Calibri">(CPU)</span><span style="font-family:宋体"> , State storage</span><span style="font-family:Calibri">(RAM)</span><span style="font-family:宋体">. </span><span style="font-family:Calibri">EOS</span><span style="font-family:宋体">After the mainnet goes live</span><span style="font- family:Calibri">ERC20</span><span style="font-family:宋体">Tokens</span><span style="font-family:Calibri">EOS</span><span style=" font-family:宋体"> is converted to a token on the main chain. It can be explained as:</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">(</span>1<span style="font-family:宋体">) Recipient payment: Customers purchase specific products from the business, and the sales revenue of these products will be used to pay for business costs, avoiding customers to directly pay for the use of blockchain, and will not restrict or prevent the company from determining the monetization of its products Strategy</span><span style="font-family:Calibri">.</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">(</span>2<span style="font-family:宋体">) Authorization capability: If a blockchain is based on </span><span style="font-family:Calibri">EOS </span><span style="font-family:宋体">software system development, and its generation The coin is held by a token holder, and he may not need to consume all or part of the available bandwidth immediately. Such token holders can choose to give or lease the unconsumed bandwidth to others. </span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">(</span>3<span style="font-family:宋体">) Separate transaction costs from token value: If the application owner holds a corresponding amount of tokens, then the application can continue to run in a fixed state and bandwidth usage. Developers and users will not be affected by price fluctuations in the token market and therefore will not be dependent on prices. </span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">(</span>4<span style="font-family:宋体">) Block reward: every time a block is generated, </span><span style="font-family:Calibri">EOS</span><span style="font-family:宋体"> will reward the block The producer's new tokens are used to pay for its operation and maintenance costs. Currently</span><span style="font-family:Calibri">EOS</span><span style="font-family:宋体"> sets a limit on the upper limit of block producers’ rewards. Currently</span ><span style="font-family:Calibri">EOS</span><span style="font-family:宋体"> sets the maximum reward for block producers to a maximum per year</span><span style ="font-family:Calibri">5%</span><span style="font-family:宋体">, that is, the total annual growth of the new supply of tokens does not exceed</span><span style="font -family:Calibri">5%</span><span style="font-family:宋体">. </span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<strong><span style="font-family: &#39;Times New Roman&#39;">3.<span style="font-family:宋体">Common links</span></span></span> </strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">Official website:</span></span><a href="https:// eos.io/"><span style=";font-family:&#39;Times New Roman&#39;;color:rgb(53,124,225)">https://eos.io/</span></a >
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style=";font-family:Calibri;font-size:16px"><span style="font-family:宋体">White paper:</span></span><a href="#support-millions -of-users"><span style=";font-family:&#39;Times New Roman&#39;;color:rgb(53,124,225)">https://github.com/EOSIO/Documentation/blob/master /TechnicalWhitePaper.md#support-millions-of-users</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:21px">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p>
<span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
<p>
<br/>
</p>`