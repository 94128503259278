export default `<p>
    <strong>TRX (Tron)</strong>
</p>
<p>
    <br/>
</p>
<p>
    <strong>1. 프로젝트 이름</strong>
</p>
<p>
    TRON : 인터넷을 다시 분산 시키십시오
</p>
<p>
    <br/>
</p>
<p>
    <strong>2. 프로젝트 소개</strong>
</p>
<p>
    TRON은이를 인터넷의 분산화를 촉진하는 임무로 삼고 분산 형 인터넷을위한 인프라 구축에 전념하고 있습니다. TRON 프로토콜은 세계에서 가장 큰 블록 체인 기반의 분산 형 애플리케이션 운영 체제 프로토콜 중 하나로서 프로토콜에서 분산 형 애플리케이션의 운영을위한 높은 처리량, 고 확장 성, 고 신뢰성 기반 퍼블릭 체인 지원을 제공합니다. TRON은 또한 혁신적인 플러그 형 스마트 계약 플랫폼을 통해 이더 리움 스마트 계약에 대한 더 나은 호환성을 제공합니다.
</p>
<p>
    2018 년 7 월 24 일부터 TRON은 샌프란시스코에 기반을 둔 인터넷 기술 회사 인 BitTorrent Inc.를 인수했습니다. BitTorrent Inc.가 설계 한 분산 기술은 효과적으로 확장하고 지능을 유지하며 제작자와 소비자가 자신의 콘텐츠와 데이터를 제어 할 수 있도록합니다. 매달 1 억 7 천만 명 이상의 사람들이 BitTorrent Inc.에서 개발 한 제품을 사용합니다. BitTorrent Inc.의 프로토콜은 매일 전 세계 인터넷 트래픽의 40 %를 전송할 수 있습니다.
</p>
<p>
    <br/>
</p>
<p>
    <strong>3. 토큰 개요 및 배포</strong>
</p>
<p>
    총 토큰 : 99,214,764,539.60352 TRX
</p>
<p>
    유통량 : 66,682,072,191 TRX
</p>
<p>
    <br/>
</p>
<p>
    <strong>4, 일반적으로 사용되는 링크</strong>
</p>
<p>
    프로젝트 웹 사이트 : https://tron.network
</p>
<p>
    백서 : https://s3.amazonaws.com/tron.network/%E7%AE%80%E4%BB%8B%E4%B8%8E%E6%84%BF%E6%99%AF.pdf
</p>
<p>
    블록 쿼리 : https://tronscan.org/#/ https://www.trxplorer.io/
</p>
<p>
    <br/>
</p>`