export default {
    title: "코인 정보",
    btcusdt: [
        {
            type: 1,
            name: "중국 이름",
            value: "비트 코인",
        },
        {
            type: 1,
            name: "영어 이름",
            value: "비트 코인",
        },
        {
            type: 1,
            name: "영어 약어",
            value: "BTC",
        },
        {
            type: 1,
            name: "개발자",
            value: "나카 모토 사토시",
        },
        {
            type: 1,
            name: "핵심 알고리즘",
            value: "SHA-256",
        },
        {
            type: 1,
            name: "출시일",
            value: "2009/01/03",
        },
        {
            type: 1,
            name: "총액",
            value: "21 백만",
        },
        {
            type: 1,
            name: "차단 시간",
            value: "블록 당 약 600 초",
        },
        {
            type: 1,
            name: "합의 증명",
            value: "POW",
        },
        {
            type: 1,
            name: "위험",
            value: "확장 분쟁, 잠재적 인 포크 위험이 있습니다.",
        },
        {
            type: 1,
            name: "통화 단위",
            value: "BTC / XBT",
        },
        {
            type: 2,
            name: "공식 웹 사이트",
            value: "https://bitcoin.org/zh_CN/",
        },
        {
            type: 2,
            name: "비트 코인 FAQ",
            value: "https://bitcoin.org/zh_CN/faq",
        },
        {
            type: 2,
            name: "검색어 차단",
            value: "https://blockchain.info/",
        },
        {
            type: 2,
            name: "비트 코인 포럼",
            value: "https://bitcointalk.org/",
        },
        {
            type: 2,
            name: "시장 가치 조회 웹 사이트",
            value: "https://coinmarketcap.com/",
        },
        {
            type: 3,
            name: "더보기",
            value: "https://bitcoin.org/zh_CN/",
        },
    ],
    ethusdt: [
        {
            type: 1,
            name: "중국 이름",
            value: "이더 리움",
        },
        {
            type: 1,
            name: "영어 이름",
            value: "이더 리움",
        },
        {
            type: 1,
            name: "영어 약어",
            value: "ETH",
        },
        {
            type: 1,
            name: "개발자",
            value: "비탈 릭 부 테린",
        },
        {
            type: 1,
            name: "핵심 알고리즘",
            value: "Ethash",
        },
        {
            type: 1,
            name: "출시일",
            value: "2015/3/20",
        },
        {
            type: 1,
            name: "차단 시간",
            value: "블록 당 약 15-17 초",
        },
        {
            type: 1,
            name: "블록 보상",
            value: "5",
        },
        {
            type: 1,
            name: "총액",
            value: "72 백만 + 18.72 백만 / 년",
        },
        {
            type: 1,
            name: "주요 기능",
            value: "디지털 통화 및 스마트 계약과 같은 기능 포함",
        },
        {
            type: 2,
            name: "공식 웹 사이트",
            value: "https://www.ethereum.org/",
        },
        {
            type: 2,
            name: "검색어 차단",
            value: "https://etherscan.io/",
        },
    ],
    usdthusd: [
        {
            type: 2,
            name: "공식 웹 사이트",
            value: "https://tether.to/",
        },
        {
            type: 2,
            name: "검색어 차단",
            value: "https://www.omniexplorer.info/",
        }
    ],
    trxusdt: [
        {
            type: 2,
            name: "프로젝트 웹 사이트",
            value: "https://tron.network",
        },
        {
            type: 2,
            name: "검색어 차단",
            value: "https://tronscan.org/#/",
        },
        {
            type: 3,
            name: "백서",
            value: "https://s3.amazonaws.com/tron.network/%E7%AE%80%E4%BB%8B%E4%B8%8E%E6%84%BF%E6%99%AF.pdf",
        },
    ],
    bsvusdt: [
        {
            type: 1,
            name: "프로젝트 이름",
            value: "비트 코인 SV (BSV)",
        },
        {
            type: 1,
            name: "프로젝트 포지셔닝",
            value: "비트 코인 SV는 비트 코인 캐시 (BCH)의 풀 노드 구현입니다. 비트 코인 캐시 (BCH) 마이닝 거인 CoinGeek 및 기타 채굴 자의 요청에 따라 팀은 채굴 자에게 명확한 비트 코인 캐시 (BCH)를 제공하기 위해 비트 코인 SV를 만들었습니다. 기업이 견고하고 신뢰할 수있는 기반 위에 애플리케이션과 웹 사이트를 구축 할 수 있도록합니다. ",
        },
        {
            type: 1,
            name: "총 토큰",
            value: "21,000,000 BSV",
        },
        {
            type: 2,
            name: "프로젝트 사이트",
            value: "https://bitcoinsv.io/",
        },
    ],
    mkrusdt: [
        {
            type: 1,
            name: "프로젝트 이름",
            value: "MakerDAO (MKR)",
        },
        {
            type: 1,
            name: "프로젝트 포지셔닝",
            value: "MakerDAO는 분산 형 금융 (DeFi)의 인프라 인 이더 리움에서 최초의 분산 형 스테이 블코 인 Dai를 개발했습니다. MKR은 Maker 시스템의 거버넌스 및 지분 토큰입니다.",
        },
        {
            type: 1,
            name: "총 토큰",
            value: "1,005,577 MKR",
        },
        {
            type: 1,
            name: "총 유통량",
            value: "1,005,577 MKR",
        },
        {
            type: 2,
            name: "프로젝트 사이트",
            value: "https://makerdao.com",
        },
        {
            type: 2,
            name: "백서",
            value: "https://makerdao.com/whitepaper",
        },
        {
            type: 3,
            name: "검색어 차단",
            value: "https://etherscan.io/token/0x6b175474e89094c44da98b954eedeac495271d0f",
        },
    ],
    linkusdt: [
        {
            type: 1,
            name: "프로젝트 이름",
            value: "링크",
        },
        {
            type: 1,
            name: "총 발행",
            value: "1,000,000,000",
        },
        {
            type: 1,
            name: "현재 유통",
            value: "350,000,000",
        },
        {
            type: 1,
            name: "토큰 가격",
            value: "$ 0.0914 USD",
        },
        {
            type: 2,
            name: "프로젝트 사이트",
            value: "https://www.smartcontract.com/",
        },
        {
            type: 2,
            name: "백서",
            value: "https://link.smartcontract.com/whitepaper",
        }
    ],
    compusdt: [
        {
            type: 1,
            name: "총 토큰",
            value: "10,000,000 COMP",
        },
        {
            type: 1,
            name: "순환",
            value: "2,561,279 COMP",
        },
        {
            type: 1,
            name: "프로젝트 사이트",
            value: "https://compound.finance/",
        },
        {
            type: 3,
            name: "백서",
            value: "https://compound.finance/documents/Compound.Whitepaper.pdf",
        },
        {
            type: 3,
            name: "검색어 차단",
            value: "https://etherscan.io/token/0xc00e94cb662c3520282e6f5717214004a7f26888",
        },
    ],
    xmrusdt: [
        {
            type: 1,
            name: "총 토큰",
            value: "약 1,600 만 XMR + 산업 및 광업",
        },
        {
            type: 1,
            name: "순환 수량",
            value: "100 %",
        },
        {
            type: 2,
            name: "프로젝트 사이트",
            value: "https://getmonero.org",
        },
        {
            type: 2,
            name: "검색어 차단",
            value: "https://xmrchain.net",
        },
        {
            type: 2,
            name: "기타 사이트",
            value: "https://www.reddit.com/r/Monero",
        },
    ],
    bchusdt: [
        {
            type: 1,
            name: "프로젝트 이름",
            value: "비트 코인 캐시 노드 (BCH)",
        },
        {
            type: 1,
            name: "프로젝트 소개",
            value: `BCH (Bitcoin Cash)는 2020 년 11 월 15 일에 하드 포크 업그레이드를 진행합니다. BCHN 컴퓨팅 파워의 현재 비율이 절대적인 이점을 가지고 있기 때문에 커뮤니티 및 사용자 피드백을 기반으로 Huobi Global은 다음과 같은 조치를 취할 것입니다.
            1. BCHN 노드 체계는 BCH의 원래 이름, K- 라인 및 거래 쌍을 상속합니다. 포크 중 시장 심리에 따라 큰 변동이있을 수 있으니 사전에 대응 조치 및 리스크 관리를 부탁드립니다. 합리적인 판단과 신중한 의사 결정
            2. BCH 포크 후, Huobi Global은 모든 사용자의 BCH 자산의 스냅 샷을 찍고 포크 토큰을 에어 드랍합니다. 포크 노드가 안정적으로 실행되면 커뮤니티 피드백을 기반으로 포크 통화 거래 쌍을 열지 여부를 평가합니다. `,
        },
        {
            type: 1,
            name: "총 토큰",
            value: "21,000,000 BCH",
        },
    ],
    ltcusdt: [
        {
            type: 1,
            name: "중국 이름",
            value: "라이트 코인",
        },
        {
            type: 1,
            name: "영어 이름",
            value: "라이트 코인",
        },
        {
            type: 1,
            name: "영어 약어",
            value: "LTC",
        },
        {
            type: 1,
            name: "개발자",
            value: "찰리 리",
        },
        {
            type: 1,
            name: "핵심 알고리즘",
            value: "Scrypt",
        },
        {
            type: 1,
            name: "출시일",
            value: "2011/10/7",
        },
        {
            type: 1,
            name: "차단 시간",
            value: "150 초 / 블록",
        },
        {
            type: 1,
            name: "총 발행",
            value: "8400 만",
        },
        {
            type: 1,
            name: "하프 타임",
            value: "4 년",
        },
        {
            type: 1,
            name: "합의 증명",
            value: "POW",
        },
        {
            type: 1,
            name: "난이도 조정",
            value: "2016 블록",
        },
        {
            type: 1,
            name: "블록 보상",
            value: "초기 50LTC, 현재 25LTC",
        },
        {
            type: 2,
            name: "공식 웹 사이트",
            value: "https://litecoin.org/",
        },
        {
            type: 2,
            name: "검색어 차단",
            value: "http://explorer.litecoin.net/",
        },
    ],
    neousdt: [
        {
            type: 2,
            name: "공식 웹 사이트",
            value: "https://neo.org"
        },
        {
            type: 2,
            name: "백서",
            value: "http://docs.neo.org/zh-cn/"
        },
    ],
    eosusdt: [
        {
            type: 2,
            name: "공식 웹 사이트",
            value: "https://eos.io/"
        },
        {
            type: 3,
            name: "백서",
            value: "https://github.com/EOSIO/Documentation/blob/master/TechnicalWhitePaper.md#support-millions-of-users"
        },
    ],
    etcusdt: [
        {
            type: 2,
            name: "공식 웹 사이트",
            value: "https://ethereumclassic.org/"
        },
        {
            type: 2,
            name: "백서",
            value: "https://github.com/etclabscore"
        },
        {
            type: 2,
            name: "블록 탐색기",
            value: "https://blockscout.com/etc/mainnet/"
        },
        {
            type: 2,
            name: "공식 Twitter",
            value: "https://twitter.com/etclabs"
        },
        {
            type: 2,
            name: "레딧",
            value: "https://www.reddit.com/r/EthereumClassic/"
        },
    ],
    zec: [
        {
            type: 2,
            name: "공식 웹 사이트",
            value: "https://z.cash/zh/"
        },
        {
            type: 3,
            name: "기술 문서",
            value: "http://zerocash-project.org/media/pdf/zerocash-extended-20140518.pdf"
        },
    ],
    adausdt: [
        {
            type: 2,
            name: "공식 웹 사이트",
            value: "https://cardanofoundation.org"
        },
        {
            type: 2,
            name: "백서",
            value: "whycardano.com"
        },
    ],
    dotusdt: [
        {
            type: 2,
            name: "공식 웹 사이트",
            value: "https://polkadot.network/"
        },
        {
            type: 2,
            name: "백서",
            value: "https://polkadot.network/PolkaDotPaper.pdf"
        },
        {
            type: 2,
            name: "검색어 차단",
            value: "https://polkadot.js.org/apps/#/explorer"
        },
    ],
    trbusdt: [
        {
            type: 2,
            name: "공식 웹 사이트",
            value: "http://www.tellor.io"
        },
        {
            type: 2,
            name: "백서",
            value: "http://www.tellor.io/whitepaper"
        },
        {
            type: 3,
            name: "검색어 차단",
            value: "https://explore.duneanalytics.com/public/dashboards/pVN6lRN8xxtfhjnEbcPbqreI8DNkPOng5P5fol0v"
        },
    ],
    bhdusdt: [
        {
            type: 2,
            name: "공식 웹 사이트",
            value: "http://www.tellor.io"
        },
        {
            type: 2,
            name: "백서",
            value: "www.btchd.org/BHD- 백서 2.0.pdf"
        },
        {
            type: 2,
            name: "검색어 차단",
            value: "www.btchd.org/explorer/"
        },
    ],
    qtumusdt: [
        {
            type: 2,
            name: "공식 웹 사이트",
            value: "https://www.qtum.org/zh/"
        },
        {
            type: 2,
            name: "백서",
            value: "https://www.qtum.org/zh/white-papers"
        },
    ],
    bandusdt: [
        {
            type: 2,
            name: "공식 웹 사이트",
            value: "https://bandprotocol.com/"
        },
        {
            type: 2,
            name: "백서",
            value: "https://docs.bandchain.org/whitepaper/"
        },
        {
            type: 2,
            name: "검색어 차단",
            value: "https://cosmoscan.io/"
        },
    ],
    xtzusdt: [
        {
            type: 2,
            name: "공식 웹 사이트",
            value: "https://tezos.com/"
        },
        {
            type: 2,
            name: "재단 웹 사이트",
            value: "https://tezos.foundation/"
        },
        {
            type: 2,
            name: "백서",
            value: "https://tezos.com/static/papers/white_paper.pdf"
        },
        {
            type: 2,
            name: "검색어 차단",
            value: "https://tezblock.io/"
        },
    ],
    balusdt: [
        {
            type: 2,
            name: "공식 웹 사이트",
            value: "https://balancer.finance/"
        },
        {
            type: 2,
            name: "백서",
            value: "https://balancer.finance/whitepaper/"
        },
        {
            type: 2,
            name: "검색어 차단",
            value: "https://etherscan.io/address/0xba100000625a3754423978a60c9317c58a424e3d"
        },
    ],
    imfusdt: [
        {
            type: 1,
            name: "영어 이름",
            value: "IMF",
        },
        {
            type: 2,
            name: "백",
            value: "https://starthestar.com/whitepaper/IMF-WhitePaper.pdf"
        }
    ],
}