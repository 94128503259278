export default `<p>
    <strong>LINK（ChainLink）</strong>
</p>
<p>
    <br/>
</p>
<p>
    <strong>1.プロジェクト名</strong>
</p>
<p>
    プロジェクト名：ChainLink
</p>
<p>
    トークン名：LINK
</p>
<p>
    に
</p>
<p>
    <strong>2.プロジェクト紹介</strong>
</p>
<p>
    ChainLinkのLINKネットワークは、誰でも安全にスマートコントラクトを提供し、重要な外部データ、オフライン支払い、その他のAPI機能にアクセスできるようにする最初の分散型オラクルネットワークです。データフィード、オフラインサービス（ローカルペイメントなど）、またはその他のAPIを持っているユーザーは、LINKトークンと引き換えにスマートコントラクトに直接提供できます。
</p>
<p>
    に
</p>
<p>
    <strong>3.プロジェクトアプリケーション</strong>
</p>
<p>
    ChainLinkは、スマートコントラクトの作成者がさまざまなデータプロバイダー、支払いソリューション、および従来の銀行システムと対話する方法を開発しました。例えば：
</p>
<p>
    1.スマートコントラクト証券には、市場価格に関するデータと、金利などの市場参照データが必要です。また、通常、既存の銀行口座に多くのユーザーに支払う必要があります。これは、既存の銀行システムに接続されたChainLinkを介して行うことができます。
</p>
<p>
    2.スマートコントラクト保険には、違法な倉庫の磁気ドアのロック、インターネット上にファイアウォールがあるかどうか、ユーザー保険のフライトに間に合うように到着するなど、保険イベントに関連するモノのインターネットデータに関連するデータデータが必要です。保険商品は通常、ユーザーがすでに受け取りたい形式での支払いを望んでいます（エンドユーザーの銀行口座の米ドル）。
</p>
<p>
    3.簡単な資金調達のスマートコントラクトには通常、出荷に関するGPSデータ、サプライチェーンERPシステムからのデータ、および輸送される商品に関する税関データが必要です。これらのデータはすべて、ChainLinkを介してスマートコントラクトに追加する必要があります。多くの貿易金融取引は依然として法定通貨で決済されており、ChainLinkを使用して支払うこともできます。
</p>
<p>
    に
</p>
<p>
    <strong>4.トークンの概要と配布</strong>
</p>
<p>
    総発行額：1,000,000,000
</p>
<p>
    現在の発行部数：350,000,000（2018年1月19日現在）
</p>
<p>
    トークン価格：$ 0.0914 USD
</p>
<p>
    に
</p>
<p>
    <strong>5つの一般的に使用されるリンク</strong>
</p>
<p>
    プロジェクトのウェブサイト：https：//www.smartcontract.com/
</p>
<p>
    ホワイトペーパー：https：//link.smartcontract.com/whitepaper
</p>
<p>
    <br/>
</p>
<p>
    第六に、通貨ゾーン
</p>
<p>
    イノベーションゾーン
</p>
<p>
    <br/>
</p>`