export default {
    pt1:"1.사용자는 STARTHESTAR 공식 웹사이트(https://starthestar.com)를 열고 홈페이지 오른쪽 상단의 \"다운로드\" 아이콘을 클릭하고 해당 시스템 휴대폰으로 해당 QR 코드를 스캔하여 를 다운로드합니다。",
    pimg1:"k7",
    pt2:"또는 사용자가 STARTHESTAR 공식 모바일 웹 버전 주소(https://wap.starthestar.com)를 열고 모바일 웹 페이지를 직접 다운로드할 수 있습니다. IOS 시스템을 예로 들어 보겠습니다. 아래에 나타나는 \"IOS 앱 다운로드\"에서 \"다운로드\"를 클릭합니다.。",
    pimg2:"k7",
    // pt3:"리디렉션된 웹 인터페이스에서 첫 번째 단계는 TestFlight를 다운로드하고 \"App Store에서 보기\"를 클릭하거나 휴대폰에서 App Store를 직접 열어 TestFlight를 검색하고 다운로드하여 휴대폰에 설치하는 것입니다。",
    // pimg3:"k7",
	// pimg4:"k7",
    // pt4:"TestFlight를 설치한 후 웹 페이지로 돌아가서 두 번째 단계로 진행하고 이 Bata 버전을 추가하고 \"테스트 시작\"을 클릭합니다.",
	// pimg5:"k7",
    // pt5:"표시되는 인터페이스에서 테스트가 완료된 후 먼저 \"수락\"을 클릭하고 마지막으로 \"다운로드\"를 클릭합니다. Apple 휴대폰의 IOS 버전용  다운로드 및 설치 프로세스가 완료되었습니다.",
    pt6:"STARTHESTAR는 친절하게 알려드립니다: Android 버전의 휴대 전화를 다운로드하려면 브라우저가 열린 후 다운로드를 클릭하여 설치를 완료하십시오. 일부 Android 휴대 전화는 설치 중에 시스템 인증이 필요합니다. 신뢰할 수 있는 APP 권한을 높이려면 확인을 클릭하십시오.",
}