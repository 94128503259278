export default `<p>
    <strong>BTC (비트 코인)</strong>
</p>
<p>
    <br/>
</p>
<p>
    <strong>1. 비트 코인이란?</strong>
</p>
<p>
    <br/>
</p>
<p>
    비트 코인 (줄여서 BTC)은 현재 가장 널리 사용되는 디지털 통화로 2009 년 1 월 3 일에 탄생했습니다. P2P (Peer-to-Peer) 전송을위한 디지털 암호화 통화로 총 2100 만 개입니다. 비트 코인 네트워크는 10 분마다 일정량의 코인을 출시하며, 이는 2140 년에 한계에 도달 할 것으로 예상됩니다. 비트 코인은 투자자들에 의해 &quot;디지털 금&quot;이라고 불립니다. 비트 코인은 특정 알고리즘을 기반으로 많은 계산을 통해 생성됩니다. 특정 통화 기관에 의존하지 않고 전체 P2P 네트워크에서 여러 노드로 구성된 분산 데이터베이스를 사용하여 모든 거래 행동을 확인하고 기록하고 사용합니다. 통화 순환을 보장하는 암호화 설계 링크의 보안은 Bitcoin의 대량 생산을 통해 통화의 가치를 조작 할 수 없도록 보장 할 수 있습니다. 암호화에 기반한 설계는 비트 코인을 실제 소유자에 의해서만 전송, 지불 및 현금화 할 수 있도록합니다. 또한 통화 소유권 및 유통 거래의 익명 성을 보장합니다.
</p>
<p>
    에
</p>
<p>
    비트 코인은 분산화, 글로벌 유통, 낮은 거래 비용 및 익명 유통으로 인해 기술 애호가들에게 선호됩니다. 최근 월스트리트와 많은 국가의 중앙 은행과 같은 전통적인 금융 기관들이 비트 코인 블록 체인 기술을 연구하기 시작했습니다. 일본 정부는 비트 코인을 법적 결제 수단으로 공식적으로 인정했으며 점점 더 많은 일본 기업이 비트 코인 결제를 받아들이고 있습니다.
</p>
<p>
    <br/>
</p>
<p>
    <strong>2. 비트 코인의 장점은 무엇입니까?</strong>
</p>
<p>
    에
</p>
<p>
    지불의 자유-모든 금액의 자금을 언제 어디서나 즉시 지불하고받을 수 있습니다. 공휴일도, 국경도, 제한도 없습니다. 비트 코인은 사용자가 자금을 완전히 통제 할 수 있도록합니다.
</p>
<p>
    에
</p>
<p>
    매우 낮은 수수료-현재 비트 코인 결제 처리에 수수료가 없거나 아주 적은 수수료 만 있습니다. 사용자는 처리 우선 순위를 얻기 위해 트랜잭션에 처리 수수료를 포함하고 네트워크에서 트랜잭션 확인을 더 빨리받을 수 있습니다. 또한 거래를 처리하고 매일 비트 코인을 법정 화폐로 변환하고 판매자의 은행 계좌에 직접 자금을 입금하는 데있어 판매자를 지원하는 판매자 프로세서가 있습니다. 이러한 서비스는 비트 코인을 기반으로하기 때문에 PayPal 또는 신용 카드 네트워크보다 훨씬 낮은 수수료를 제공 할 수 있습니다.
</p>
<p>
    에
</p>
<p>
    가맹점의 위험 감소-비트 코인 거래는 안전하고 취소 할 수 없으며 고객의 민감하거나 개인 정보를 포함하지 않습니다. 이는 사기 또는 사기성 지불 거절로 인한 판매자의 손실을 방지하며 PCI 표준을 준수 할 필요가 없습니다. 판매자는 신용 카드를 사용할 수 없거나 사기 율이 용납 할 수 없을 정도로 높은 새로운 시장으로 쉽게 확장 할 수 있습니다. 최종 결과는 더 낮은 수수료, 더 큰 시장 및 더 적은 관리 비용입니다.
</p>
<p>
    에
</p>
<p>
    보안 및 제어-비트 코인 사용자는 거래를 완전히 제어 할 수 있습니다. 상인은 찾기 쉽지 않거나 찾기 쉽지 않은 다른 결제 방법에서 발생할 수있는 수수료를 강제로 부과 할 수 없습니다. 비트 코인으로 지불하면 거래에서 개인 정보를 묶을 필요가 없으므로 신원 도용에 대한 뛰어난 보호를 제공합니다. 비트 코인 사용자는 백업 및 암호화를 통해 자금을 보호 할 수도 있습니다.
</p>
<p>
    에
</p>
<p>
    투명성 및 중립성-비트 코인의 자금 공급 자체에 대한 모든 정보는 블록 체인에 저장되며 누구나 실시간으로 확인하고 사용할 수 있습니다. 비트 코인 프로토콜은 암호로 보호되기 때문에 개인이나 조직은 비트 코인 프로토콜을 제어하거나 조작 할 수 없습니다. 이것은 Bitcoin Core가 완전히 중립적이고 투명하며 예측 가능하다고 믿게 만듭니다.
</p>
<p>
    에
</p>
<p>
    <strong>3. 비트 코인의 단점은 무엇입니까?</strong>
</p>
<p>
    에
</p>
<p>
    수용 수준-여전히 비트 코인을 모르는 사람들이 많이 있습니다. 비트 코인의 혜택을 받기 위해 매일 더 많은 기업이 비트 코인을 받아들이지 만이 목록은 여전히 적습니다. 네트워크 효과를 활용하려면 더 많은 기업이 비트 코인을 지원해야합니다.
</p>
<p>
    에
</p>
<p>
    변동성-유통중인 비트 코인의 총 가치와 비트 코인을 사용하는 회사의 수는 가능한 규모에 비해 여전히 매우 적습니다. 따라서 비교적 작은 이벤트, 거래 또는 비즈니스 활동이 가격에 큰 영향을 미칠 수 있습니다. 이론적으로 비트 코인 시장과 기술이 성숙함에 따라 이러한 변동성은 감소 할 것입니다. 이 세계는 이전에 신흥 통화를 본 적이 없기 때문에 어떻게 발전 할 것인지 상상하기가 정말 어렵고 동시에 흥미 롭습니다.
</p>
<p>
    에
</p>
<p>
    개발 단계에서 비트 코인 소프트웨어는 아직 베타 버전이며 많은 미완성 기능이 개발 단계에 있습니다. Bitcoin을 더 안전하고 일반 대중이 더 쉽게 이용할 수 있도록 새로운 도구, 기능 및 서비스가 개발되고 있습니다. 이러한 기능 중 일부는 현재 모든 사용자가 사용할 수있는 것은 아닙니다. 대부분의 비트 코인 비즈니스는 새롭고 아직 보험을 제공하지 않습니다. 일반적으로 Bitcoin은 아직 성숙 단계에 있습니다.
</p>
<p>
    에
</p>
<p>
    <strong>4. 세부 매개 변수</strong>
</p>
<p>
    에
</p>
<p>
    중국어 이름 : 비트 코인 영어 이름 : 비트 코인 영어 약어 : BTC
</p>
<p>
    개발자 : Satoshi Nakamoto Core Algorithm : SHA-256 출시일 : 2009/01/03
</p>
<p>
    총 : 2100 만 블록 시간 : 약 600 초 / 블록 합의 증명 : POW
</p>
<p>
    위험 : 확장 분쟁에서 포크의 잠재적 위험이 있습니다.
</p>
<p>
    통화 단위 : BTC / XBT
</p>
<p>
    비트 코인 1 개 (비트 코인, BTC)
</p>
<p>
    10-2 Bitcent (Bitcent, cBTC)
</p>
<p>
    10−3 Milli-Bitcoins (Milli-Bitcoins, mBTC)
</p>
<p>
    10-6 마이크로 비트 코인 (μBTC)
</p>
<p>
    10-8 사토시
</p>
<p>
    에
</p>
<p>
    <strong>5. 자주 사용하는 링크</strong>
</p>
<p>
    <br/>
</p>
<p>
    공식 웹 사이트 : https://bitcoin.org/zh_CN/
</p>
<p>
    비트 코인 FAQ : https://bitcoin.org/zh_CN/faq
</p>
<p>
    블록 쿼리 : https://blockchain.info/
</p>
<p>
    비트 코인 포럼 : https://bitcointalk.org/
</p>
<p>
    시장 가치 조회 웹 사이트 : https://coinmarketcap.com
</p>
<p>
    <br/>
</p>`