import request from "@/api/request"

const api = {
    getNewsList: 'index/getNewsList',
    getRateHistory: 'index/getRateHistory',
    getIndexCoins: 'index/getIndexCoins',
}

export function getNewsList(parameter) {
    return request({
        url: api.getNewsList,
        method: 'post',
        data: parameter
    })
}
export function getRateHistory(parameter) {
    return request({
        url: api.getRateHistory,
        method: 'post',
        data: parameter
    })
}
export function getIndexCoins(parameter) {
    return request({
        url: api.getIndexCoins,
        method: 'post',
        data: parameter
    })
}