import { render, staticRenderFns } from "./Certified.vue?vue&type=template&id=0a6ff06f&scoped=true&"
import script from "./Certified.vue?vue&type=script&lang=js&"
export * from "./Certified.vue?vue&type=script&lang=js&"
import style0 from "./Certified.vue?vue&type=style&index=0&lang=css&"
import style1 from "../../static/css/certified.css?vue&type=style&index=1&id=0a6ff06f&scoped=true&tyle=text%2Fcss&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a6ff06f",
  null
  
)

export default component.exports