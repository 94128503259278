class Socket {
  constructor(ip, port) {
    let protocol = location.protocol === "https:" ? "wss://" : "ws://";
    this.url = protocol + ip + ":" + port;
    //let token = localStorage.getItem('token')
    //this.url = "wss://server.starthestar.com/ws";
    this.url = "wss://starthestar.com/ws";
    this.methods = {};
    this.socket = {};
    this.reconnect_time = "2000";
    this.connect_inter = null;
    this.open();
    return this;
  }
  on(method, fn) {
    this.methods[method] = fn;
  }
  open(isReconnect) {
    var that = this;
    /**----------------------------------*/
    //console.log(that.url,'socket 请求连接');
    /**----------------------------------*/
    that.socket = new WebSocket(that.url);
    that.socket.onopen = function() {
      clearInterval(that.connect_inter);
      /**----------------------------------*/
      console.log(that.url, "socket 连接成功!");
      /**----------------------------------*/
      //    that.hasConnected()
      if (that.methods["onReady"]) {
        that.methods["onReady"]();
      }

      if (isReconnect) {
        if (that.methods["onReconnect"]) {
          that.methods["onReconnect"]();
        }
      }
    };
    that.socket.onerror = function(msg) {
      console.log(msg, "连接失败");
      if (that.methods["onError"]) {
        that.methods["onError"](msg);
      }
      that.reconnect();
    };
    that.socket.onmessage = function(msg) {
      let data = JSON.parse(msg.data);
      /**----------------------------------*/
      // console.log(data,'socket 接收数据');
      if (data.type == "ping") {
        that.socket.send('{"pong":' + Date.parse(new Date()) + "}");
      } else {
        /**----------------------------------*/
        if (that.methods[data.method]) {
          that.methods[data.method](data.data);
        }
      }
    };
  }
  connected(fn) {
    if (this.socket.readyState === 1) {
      //成功
      fn(this);
      this.sendPing();
    } else {
      setTimeout(() => {
        console.log("等待连接");
        this.connected(fn);
      }, 1000);
    }
  }
  send(infos) {
    if (infos.constructor == Array) {
      /**----------------------------------*/
      // console.log(infos, infos.constructor, 'socket 发送Array数据!');
      /**----------------------------------*/
      for (var i in infos) {
        this.socket.send(
          JSON.stringify({
            type: infos[i].type,
            params: infos[i].params,
          })
        );
      }
    } else if (infos.constructor == String) {
      /**----------------------------------*/
      // console.log(infos, infos.constructor, 'socket 发送String数据!');
      /**----------------------------------*/
      this.socket.send(infos);
    } else {
      /**----------------------------------*/
      //console.log(infos,infos.constructor,'socket 发送数据!');
      /**----------------------------------*/
      this.socket.send(JSON.stringify(infos));
    }
  }
  close() {
    //var that = this;
    this.socket.onclose = function() {
      this.close();
      console.log("关闭连接");
      // that.reconnect();
    };
  }
  reconnect() {
    clearInterval(this.connect_inter);
    this.connect_inter = setInterval(() => {
      this.open(true);
    }, this.reconnect_time);
  }
  /**获取当前socket状态*/
  getReadyState() {
    return this.socket.readyState;
  }
  /**发送心跳 */
  sendPing() {
    if (this.connect_inter) {
      clearInterval(this.connect_inter);
    }
    this.send('{"ping":' + Date.parse(new Date()) + "}");
    this.connect_inter = setInterval(() => {
      if (this.socket.readyState != 1) {
        this.reconnect();
      } else {
        this.send('{"ping":' + Date.parse(new Date()) + "}");
      }
    }, 5000);
  }
}
// var socket=new WebSocket('ws://newnb.m88mo.cn/ws')

var socket = new Socket("127.0.0.1", "2000");
export default socket;
