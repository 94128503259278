export default `<h2 style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ;line-height:48px">
<strong><span style="font-family: 宋体;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 16px;background: rgb(255, 255, 255)">ADA (卡尔达诺)</span></strong>
</h2>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">一、项目名称</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">卡尔达诺</span>(ADA)</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">二、项目介绍</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:16px;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">卡尔达诺是一个分散且公开的区块链，也是ㄧ个加密货币项目，而且是完全开源的。卡尔达诺正在开发一个智能合约平台，旨在提供比以前开发的任何协议更先进的功能。它是第一个从科学哲学和以研究为首驱使导向，进而演变而来的区块链平台。开发团队由全球专业工程师和研究人员组成。</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">三、币种概况与分配</span></span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">最大供给量：</span>45,000,000,000&nbsp;ADA</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">总供给量：</span>31,112,483,745&nbsp;ADA</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">流通供给量：</span>25,927,070,538&nbsp;ADA</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">众筹价格：</span>$0.0026</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">代币分配：</span></span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">其中</span>ADA 币在卡尔达诺协议发布时共出售 25,927,070,538 个 ADA币。另有5,185,414,108 个 ADA 币，相当于所售 ADA 数量的 20%，生成并分发给组成卡尔达诺技术和业务生态系统的三个实体：IOHK, Emurgo 以及 卡尔达诺基金会。因此 ADA 发布时的总量是 31,112,484,646 个。</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">卡尔达诺协议运行期间发行的</span> ADA 币剩余的 13,887,515,354 个 ADA 币，将在铸币的过程中发行。</span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">注意：</span>ADA 的数量永远不会超过 45,000,000,000</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">四、常用链接</span></span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">项目网站：</span>&nbsp;</span><a href="http://cardanofoundation.org/"><span style="font-family: 微软雅黑;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">cardanofoundation.org</span></a><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;,&nbsp;</span><a href="http://iohk.io/"><span style="font-family: 微软雅黑;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">iohk.io</span></a><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px">&nbsp;,&nbsp;</span><a href="http://emurgo.io/"><span style="font-family: 微软雅黑;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">emurgo.io</span></a><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">白皮书：</span></span><a href="https://bluzelle.com/wp-content/uploads/2017/10/bluzelle-whitepaper-english1-4.pdf"></a><a href="http://whycardano.com/"><span style="font-family: 微软雅黑;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">whycardano.com</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">其他</span>:</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<a href="http://cardano.org/"></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<a href="http://cardano.org/"></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<a href="http://cardano.org/"><span style="font-family: 微软雅黑;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">cardano.org</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<a href="http://cardanodocs.com/"><span style="font-family: 微软雅黑;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">cardanodocs.com</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<a href="https://cardanoexplorer.com/"><span style="font-family: 微软雅黑;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">cardanoexplorer.com</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<a href="http://cardanoroadmap.com/"><span style="font-family: 微软雅黑;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">cardanoroadmap.com</span></a><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><a href="https://github.com/input-output-hk/cardano-sl"><span style="font-family: 微软雅黑;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">https://github.com/input-output-hk/cardano-sl</span></a><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><a href="https://www.reddit.com/r/cardano/"><span style="font-family: 微软雅黑;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">https://www.reddit.com/r/cardano/</span></a><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><br/></span><a href="https://daedaluswallet.io/"><span style="font-family: 微软雅黑;color: rgb(53, 124, 225);letter-spacing: 0;font-size: 14px">https://daedaluswallet.io/</span></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<a href="https://daedaluswallet.io/"></a>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<strong><span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">五、币种所在分区</span></span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;padding:0 0 0 0 ">
<span style="font-family: 微软雅黑;color: rgb(35, 42, 74);letter-spacing: 0;font-size: 14px"><span style="font-family:微软雅黑">主区</span></span>
</p>
<p>
<span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
</p>
<p>
<br/>
</p>`
