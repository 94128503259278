export default `<p>
    <strong>BSV (비트 코인 SV)</strong>
</p>
<p>
    <br/>
</p>
<p>
    1. 프로젝트 이름
</p>
<p>
    비트 코인 SV (BSV)
</p>
<p>
    <br/>
</p>
<p>
    2. 프로젝트 포지셔닝
</p>
<p>
    비트 코인 SV는 비트 코인 캐시 (BCH)의 전체 노드 실현입니다. 비트 코인 캐시 (BCH) 마이닝 거인 코인 긱 및 기타 채굴 자의 요청에 따라 팀은 채굴 자에게 명확한 비트 코인 캐시 (BCH) 구현 옵션을 제공하고 기업이 견고하고 안정적인 기반 앱 및 웹 사이트를 구축 할 수 있도록하는 것을 목표로 비트 코인 SV를 만들었습니다.
</p>
<p>
    <br/>
</p>
<p>
    세, 통화 개요 및 배포
</p>
<p>
    총 토큰 : 21,000,000 BSV
</p>
<p>
    <br/>
</p>
<p>
    네, 일반적으로 사용되는 링크
</p>
<p>
    프로젝트 웹 사이트 : https://bitcoinsv.io/
</p>
<p>
    <br/>
</p>`