export default `<p>
    <strong>ETC（イーサリアムクラシック）</strong>
</p>
<p>
    <br/>
</p>
<p>
    1.プロジェクト紹介
</p>
<p>
    イーサリアムクラシック（ETC）は、暗号通貨とパブリックブロックチェーンです。 ETCは現在、Defi分野と、他のブロックチェーン、セキュリティアプリケーション、スマートコントラクト、ピアツーピアトランザクションとの相互運用性に重点を置いています。ETCは、真に回復力のある分散型ネットワークになることを目指して、コミュニティ主導の取り組みを通じて2016年に開始されました。
</p>
<p>
    <br/>
</p>
<p>
    2つの詳細なパラメータ
</p>
<p>
    価格：6.8米ドル（コインベース-2020年11月18日）
</p>
<p>
    総供給量：2億1000万個
</p>
<p>
    ブロック時間：13秒
</p>
<p>
    コア開発者：etccore.io
</p>
<p>
    <br/>
</p>
<p>
    3つの関連リンク
</p>
<p>
    公式サイト：https：//ethereumclassic.org/
</p>
<p>
    ホワイトペーパー：https：//github.com/etclabscore
</p>
<p>
    ブロックエクスプローラー：https：//blockscout.com/etc/mainnet/
</p>
<p>
    公式ツイッター：https：//twitter.com/etclabs
</p>
<p>
    Reddit：https：//www.reddit.com/r/EthereumClassic/
</p>
<p>
    <br/>
</p>`