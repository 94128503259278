export default `<p>
    <strong>TRB (Tellor Tributes)</strong>
</p>
<p>
    <br/>
</p>
<p>
    <strong>1. 프로젝트 이름</strong>
</p>
<p>
    텔러 트리뷰트 (TRB)
</p>
<p>
    <br/>
</p>
<p>
    <strong>2. 프로젝트 포지셔닝</strong>
</p>
<p>
    Tellor는 고가의 오프 체인 데이터를 이더 리움에 통합하는 데 사용되는 분산 형 Oracle 오라클 네트워크 플랫폼입니다. 이 시스템은 논쟁의 여지가있는 광부 네트워크 경쟁을 사용하여 PoW 문제를 해결하고, 체인에서 사용자 데이터 요청을 모니터링하고, 체인에서 데이터 요청을 모니터링하고 수신합니다. 성공적인 각 텔러 데이터 포인트는 토큰을 사용하여 채굴 될 것이며 회사는 생태계 개발을 지원하기 위해 10 %의 개발 점유율을 확보 할 것입니다.
</p>
<p>
    <br/>
</p>
<p>
    <strong>3, 통화 개요 및 배포</strong>
</p>
<p>
    총 토큰 : 1,505,461 TRB
</p>
<p>
    유통량 : 1,416,589 TRB
</p>
<p>
    <br/>
</p>
<p>
    <strong>4, 일반적으로 사용되는 링크</strong>
</p>
<p>
    프로젝트 웹 사이트 : http://www.tellor.io
</p>
<p>
    백서 : http://www.tellor.io/whitepaper
</p>
<p>
    블록 쿼리 :
</p>
<p>
    https://explore.duneanalytics.com/public/dashboards/pVN6lRN8xxtfhjnEbcPbqreI8DNkPOng5P5fol0v
</p>
<p>
    <br/>
</p>`