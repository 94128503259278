export default `<p>
    <strong>LINK (체인 링크)</strong>
</p>
<p>
    <br/>
</p>
<p>
    <strong>1. 프로젝트 이름</strong>
</p>
<p>
    프로젝트 이름 : ChainLink
</p>
<p>
    토큰 이름 : LINK
</p>
<p>
    에
</p>
<p>
    <strong>2. 프로젝트 소개</strong>
</p>
<p>
    ChainLink의 LINK 네트워크는 누구나 안전하게 스마트 계약을 제공하고, 중요한 외부 데이터에 액세스하고, 오프라인 결제 및 기타 API 기능을 사용할 수있는 최초의 분산 형 오라클 네트워크입니다. 데이터 피드, 오프라인 서비스 (예 : 로컬 결제) 또는 기타 API가있는 모든 사용자는 LINK 토큰과 교환하여 스마트 계약에 직접 제공 할 수 있습니다.
</p>
<p>
    에
</p>
<p>
    <strong>3. 프로젝트 신청</strong>
</p>
<p>
    ChainLink는 스마트 계약 작성자가 다양한 데이터 제공 업체, 결제 솔루션 및 기존 은행 시스템과 상호 작용할 수있는 방법을 개발했습니다. 예 :
</p>
<p>
    1. 스마트 계약 증권에는 시장 가격에 대한 데이터와 이자율과 같은 시장 참조 데이터가 필요합니다. 또한 일반적으로 많은 사용자에게 기존 은행 계좌로 지불해야하며, 이는 기존 은행 시스템에 연결된 ChainLink를 통해 수행 할 수 있습니다.
</p>
<p>
    2. 스마트 계약 보험에는 불법 창고 마그네틱 도어 잠금, 인터넷에 방화벽이 있는지 여부, 사용자 보험 항공편으로 정시에 도착하는 등 보험 가능한 이벤트와 관련된 사물 인터넷 데이터와 관련된 데이터 데이터가 필요합니다. 일부 보험 상품은 또한 일반적으로 사용자가 이미 받기를 원하는 형식 (최종 사용자의 은행 계좌에있는 미국 달러)으로 Pay를 희망합니다.
</p>
<p>
    3. 손쉬운 금융 스마트 계약은 일반적으로 배송에 대한 GPS 데이터, 공급망 ERP 시스템의 데이터 및 운송되는 상품에 대한 세관 데이터를 필요로합니다. 이러한 모든 데이터는 ChainLink를 통해 스마트 계약에 추가되어야합니다. 많은 무역 금융 거래는 여전히 법정 화폐로 결제되며 ChainLink를 사용하여 지불 할 수도 있습니다.
</p>
<p>
    에
</p>
<p>
    <strong>4. 토큰 개요 및 배포</strong>
</p>
<p>
    총 발행량 : 1,000,000,000
</p>
<p>
    현재 유통량 : 350,000,000 (2018 년 1 월 19 일 현재)
</p>
<p>
    토큰 가격 : $ 0.0914 USD
</p>
<p>
    에
</p>
<p>
    <br/>
</p>
<p>
    <strong>5.가장 많이 사용되는 링크</strong>
</p>
<p>
    프로젝트 웹 사이트 : https://www.smartcontract.com/
</p>
<p>
    백서 : https://link.smartcontract.com/whitepaper
</p>
<p>
    <br/>
</p>
<p>
    <strong>6, 통화 영역</strong>
</p>
<p>
    혁신 존
</p>
<p>
    <br/>
</p>`