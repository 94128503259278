<template>
<main role="main">
    <div class="container-divider"></div>
    <div class="container" :style="'min-height:' + (screenHeight - 297) + 'px'">
        <nav class="sub-nav">
            <ol class="breadcrumbs">
                <li title="STARTHESTAR">
                    <a href="/">{{$t('base.title')}}</a>
                </li>
                <li title="新手指引">
                    <a href="javascript:">{{$t('guide.label')}}</a>
                </li>
            </ol>

            <!-- <form role="search" class="search" data-search="" action="/hc/zh-cn/search" accept-charset="UTF-8" method="get">
                <input name="utf8" type="hidden" value="✓"><input type="hidden" name="category" id="category" value="360002866853">
                <input type="search" name="query" id="query" placeholder="搜索" aria-label="搜索">
            </form> -->
        </nav>

        <div class="article-container" id="article-container">
            <aside class="article-sidebar" aria-labelledby="section-articles-title">
                <div class="collapsible-sidebar">
                    <button type="button" class="collapsible-sidebar-toggle" aria-labelledby="section-articles-title" aria-expanded="false"></button>
                    <span id="section-articles-title" class="collapsible-sidebar-title sidenav-title">
                        {{$t('base.more')}}
                    </span>
                    <div class="collapsible-sidebar-body">
                        <ul>
                            <li v-for="(item,index) in list" :key="index">
                                <router-link :to="{name:'Guide',query:{key:item.pkey}}" :class="'sidenav-item ' + (currentKey == item.pkey ?'current-article':'')">{{item.ptitle}}</router-link>
                            </li>
                        </ul>

                    </div>
                </div>

            </aside>

            <article class="article">
                <header class="article-header">
                    <h1 title="如何注册STARTHESTAR" class="article-title">
                        {{currentTitle}}
                    </h1>
                </header>
                <section class="article-info">
                    <div class="article-content">
                        <div class="article-body">
                            <div v-for="(item,key) in currentContent" :key="key">
                                <p v-if="key.indexOf('pt') == 0">{{item}}</p>
                                <div v-if="lang == 'en-us'">
                                    <p v-if="item == 'k1'">
                                        <img v-if="key.indexOf('pimg1') == 0" src="../../../static/imgs/guide/k1_1_en.png" alt="">
                                        <img v-if="key.indexOf('pimg2') == 0" src="../../../static/imgs/guide/k1_2_en.png" alt="">
                                        <img v-if="key.indexOf('pimg3') == 0" src="../../../static/imgs/guide/k1_3_en.png" alt="">
                                    </p>
                                    <p v-if="item == 'k2'">
                                        <img v-if="key.indexOf('pimg1') == 0" src="../../../static/imgs/guide/k2_1_en.png" alt="">
                                        <img v-if="key.indexOf('pimg2') == 0" src="../../../static/imgs/guide/k2_2_en.png" alt="">
                                        <img v-if="key.indexOf('pimg3') == 0" src="../../../static/imgs/guide/k2_3_en.png" alt="">
                                    </p>
                                    <p v-if="item == 'k3'">
                                        <img v-if="key.indexOf('pimg1') == 0" src="../../../static/imgs/guide/k3_1_en.png" alt="">
                                        <img v-if="key.indexOf('pimg2') == 0" src="../../../static/imgs/guide/k3_2_en.png" alt="">
                                        <img v-if="key.indexOf('pimg3') == 0" src="../../../static/imgs/guide/k3_3_en.png" alt="">
                                        <img v-if="key.indexOf('pimg4') == 0" src="../../../static/imgs/guide/k3_4_en.png" alt="">
                                        <img v-if="key.indexOf('pimg5') == 0" src="../../../static/imgs/guide/k3_5_en.png" alt="">
                                    </p>
                                    <p v-if="item == 'k4'">
                                        <img v-if="key.indexOf('pimg1') == 0" src="../../../static/imgs/guide/k4_1_en.png" alt="">
                                        <img v-if="key.indexOf('pimg2') == 0" src="../../../static/imgs/guide/k4_2_en.png" alt="">
                                        <img v-if="key.indexOf('pimg3') == 0" src="../../../static/imgs/guide/k4_3_en.png" alt="">
                                    </p>
                                    <p v-if="item == 'k5'">
                                        <img v-if="key.indexOf('pimg1') == 0" src="../../../static/imgs/guide/k5_1_en.png" alt="">
                                        <img v-if="key.indexOf('pimg2') == 0" src="../../../static/imgs/guide/k5_2_en.png" alt="">
                                        <img v-if="key.indexOf('pimg3') == 0" src="../../../static/imgs/guide/k5_3_en.png" alt="">
                                    </p>
                                    <p v-if="item == 'k6'">
                                        <img v-if="key.indexOf('pimg1') == 0" src="../../../static/imgs/guide/k6_1_en.png" alt="">
                                        <img v-if="key.indexOf('pimg2') == 0" src="../../../static/imgs/guide/k6_2_en.png" alt="">
                                        <img v-if="key.indexOf('pimg3') == 0" src="../../../static/imgs/guide/k6_3_en.png" alt="">
                                    </p>
                                    <p v-if="item == 'k7'">
                                        <img v-if="key.indexOf('pimg1') == 0" src="../../../static/imgs/guide/k7_1_en.png" alt="">
                                        <img v-if="key.indexOf('pimg2') == 0" src="../../../static/imgs/guide/k7_2_en.png" alt="">
                                        <!--<img v-if="key.indexOf('pimg3') == 0" src="../../../static/imgs/guide/k7_3_en.png" alt="">-->
                                        <!-- <img v-if="key.indexOf('pimg4') == 0" src="../../../static/imgs/guide/k7_4_en.png" alt=""> -->
                                        <!-- <img v-if="key.indexOf('pimg5') == 0" src="../../../static/imgs/guide/k7_5_en.png" alt=""> -->
                                    </p>
                                </div>

                                <div v-if="lang == 'zh-cn'">
                                    <p v-if="item == 'k1'">
                                        <img v-if="key.indexOf('pimg1') == 0" src="../../../static/imgs/guide/k1_1_zh.png" alt="">
                                        <img v-if="key.indexOf('pimg2') == 0" src="../../../static/imgs/guide/k1_2_zh.png" alt="">
                                        <img v-if="key.indexOf('pimg3') == 0" src="../../../static/imgs/guide/k1_3_zh.png" alt="">
                                    </p>
                                    <p v-if="item == 'k2'">
                                        <img v-if="key.indexOf('pimg1') == 0" src="../../../static/imgs/guide/k2_1_zh.png" alt="">
                                        <img v-if="key.indexOf('pimg2') == 0" src="../../../static/imgs/guide/k2_2_zh.png" alt="">
                                        <img v-if="key.indexOf('pimg3') == 0" src="../../../static/imgs/guide/k2_3_zh.png" alt="">
                                    </p>
                                    <p v-if="item == 'k3'">
                                        <img v-if="key.indexOf('pimg1') == 0" src="../../../static/imgs/guide/k3_1_zh.png" alt="">
                                        <img v-if="key.indexOf('pimg2') == 0" src="../../../static/imgs/guide/k3_2_zh.png" alt="">
                                        <img v-if="key.indexOf('pimg3') == 0" src="../../../static/imgs/guide/k3_3_zh.png" alt="">
                                        <img v-if="key.indexOf('pimg4') == 0" src="../../../static/imgs/guide/k3_4_zh.png" alt="">
                                        <img v-if="key.indexOf('pimg5') == 0" src="../../../static/imgs/guide/k3_5_zh.png" alt="">
                                    </p>
                                    <p v-if="item == 'k4'">
                                        <img v-if="key.indexOf('pimg1') == 0" src="../../../static/imgs/guide/k4_1_zh.png" alt="">
                                        <img v-if="key.indexOf('pimg2') == 0" src="../../../static/imgs/guide/k4_2_zh.png" alt="">
                                        <img v-if="key.indexOf('pimg3') == 0" src="../../../static/imgs/guide/k4_3_zh.png" alt="">
                                    </p>
                                    <p v-if="item == 'k5'">
                                        <img v-if="key.indexOf('pimg1') == 0" src="../../../static/imgs/guide/k5_1_zh.png" alt="">
                                        <img v-if="key.indexOf('pimg2') == 0" src="../../../static/imgs/guide/k5_2_zh.png" alt="">
                                        <img v-if="key.indexOf('pimg3') == 0" src="../../../static/imgs/guide/k5_3_zh.png" alt="">
                                    </p>
                                    <p v-if="item == 'k6'">
                                        <img v-if="key.indexOf('pimg1') == 0" src="../../../static/imgs/guide/k6_1_zh.png" alt="">
                                        <img v-if="key.indexOf('pimg2') == 0" src="../../../static/imgs/guide/k6_2_zh.png" alt="">
                                        <img v-if="key.indexOf('pimg3') == 0" src="../../../static/imgs/guide/k6_3_zh.png" alt="">
                                    </p>
                                    <p v-if="item == 'k7'">
                                        <img v-if="key.indexOf('pimg1') == 0" src="../../../static/imgs/guide/k7_1_zh.png" alt="">
                                        <img v-if="key.indexOf('pimg2') == 0" src="../../../static/imgs/guide/k7_2_zh.png" alt="">
                                        <!-- <img v-if="key.indexOf('pimg3') == 0" src="../../../static/imgs/guide/k7_3_zh.png" alt=""> -->
                                        <!-- <img v-if="key.indexOf('pimg4') == 0" src="../../../static/imgs/guide/k7_4_zh.png" alt=""> -->
                                        <!-- <img v-if="key.indexOf('pimg5') == 0" src="../../../static/imgs/guide/k7_5_zh.png" alt=""> -->
                                    </p>
                                </div>
                                <div v-if="lang == 'ja'">
                                    <p v-if="item == 'k1'">
                                        <img v-if="key.indexOf('pimg1') == 0" src="../../../static/imgs/guide/k1_1_ja.png" alt="">
                                        <img v-if="key.indexOf('pimg2') == 0" src="../../../static/imgs/guide/k1_2_ja.png" alt="">
                                        <img v-if="key.indexOf('pimg3') == 0" src="../../../static/imgs/guide/k1_3_ja.png" alt="">
                                    </p>
                                    <p v-if="item == 'k2'">
                                        <img v-if="key.indexOf('pimg1') == 0" src="../../../static/imgs/guide/k2_1_ja.png" alt="">
                                        <img v-if="key.indexOf('pimg2') == 0" src="../../../static/imgs/guide/k2_2_ja.png" alt="">
                                        <img v-if="key.indexOf('pimg3') == 0" src="../../../static/imgs/guide/k2_3_ja.png" alt="">
                                    </p>
                                    <p v-if="item == 'k3'">
                                        <img v-if="key.indexOf('pimg1') == 0" src="../../../static/imgs/guide/k3_1_ja.png" alt="">
                                        <img v-if="key.indexOf('pimg2') == 0" src="../../../static/imgs/guide/k3_2_ja.png" alt="">
                                        <img v-if="key.indexOf('pimg3') == 0" src="../../../static/imgs/guide/k3_3_ja.png" alt="">
                                        <img v-if="key.indexOf('pimg4') == 0" src="../../../static/imgs/guide/k3_4_ja.png" alt="">
                                        <img v-if="key.indexOf('pimg5') == 0" src="../../../static/imgs/guide/k3_5_ja.png" alt="">
                                    </p>
                                    <p v-if="item == 'k4'">
                                        <img v-if="key.indexOf('pimg1') == 0" src="../../../static/imgs/guide/k4_1_ja.png" alt="">
                                        <img v-if="key.indexOf('pimg2') == 0" src="../../../static/imgs/guide/k4_2_ja.png" alt="">
                                        <img v-if="key.indexOf('pimg3') == 0" src="../../../static/imgs/guide/k4_3_ja.png" alt="">
                                    </p>
                                    <p v-if="item == 'k5'">
                                        <img v-if="key.indexOf('pimg1') == 0" src="../../../static/imgs/guide/k5_1_ja.png" alt="">
                                        <img v-if="key.indexOf('pimg2') == 0" src="../../../static/imgs/guide/k5_2_ja.png" alt="">
                                        <img v-if="key.indexOf('pimg3') == 0" src="../../../static/imgs/guide/k5_3_ja.png" alt="">
                                    </p>
                                    <p v-if="item == 'k6'">
                                        <img v-if="key.indexOf('pimg1') == 0" src="../../../static/imgs/guide/k6_1_ja.png" alt="">
                                        <img v-if="key.indexOf('pimg2') == 0" src="../../../static/imgs/guide/k6_2_ja.png" alt="">
                                        <img v-if="key.indexOf('pimg3') == 0" src="../../../static/imgs/guide/k6_3_ja.png" alt="">
                                    </p>
                                    <p v-if="item == 'k7'">
                                        <img v-if="key.indexOf('pimg1') == 0" src="../../../static/imgs/guide/k7_1_ja.png" alt="">
                                        <img v-if="key.indexOf('pimg2') == 0" src="../../../static/imgs/guide/k7_2_ja.png" alt="">
                                        <!-- <img v-if="key.indexOf('pimg3') == 0" src="../../../static/imgs/guide/k7_3_ja.png" alt=""> -->
                                        <!-- <img v-if="key.indexOf('pimg4') == 0" src="../../../static/imgs/guide/k7_4_ja.png" alt=""> -->
                                        <!-- <img v-if="key.indexOf('pimg5') == 0" src="../../../static/imgs/guide/k7_5_ja.png" alt=""> -->
                                    </p>
                                </div>
                                <div v-if="lang == 'ko'">
                                    <p v-if="item == 'k1'">
                                        <img v-if="key.indexOf('pimg1') == 0" src="../../../static/imgs/guide/k1_1_ko.png" alt="">
                                        <img v-if="key.indexOf('pimg2') == 0" src="../../../static/imgs/guide/k1_2_ko.png" alt="">
                                        <img v-if="key.indexOf('pimg3') == 0" src="../../../static/imgs/guide/k1_3_ko.png" alt="">
                                    </p>
                                    <p v-if="item == 'k2'">
                                        <img v-if="key.indexOf('pimg1') == 0" src="../../../static/imgs/guide/k2_1_ko.png" alt="">
                                        <img v-if="key.indexOf('pimg2') == 0" src="../../../static/imgs/guide/k2_2_ko.png" alt="">
                                        <img v-if="key.indexOf('pimg3') == 0" src="../../../static/imgs/guide/k2_3_ko.png" alt="">
                                    </p>
                                    <p v-if="item == 'k3'">
                                        <img v-if="key.indexOf('pimg1') == 0" src="../../../static/imgs/guide/k3_1_ko.png" alt="">
                                        <img v-if="key.indexOf('pimg2') == 0" src="../../../static/imgs/guide/k3_2_ko.png" alt="">
                                        <img v-if="key.indexOf('pimg3') == 0" src="../../../static/imgs/guide/k3_3_ko.png" alt="">
                                        <img v-if="key.indexOf('pimg4') == 0" src="../../../static/imgs/guide/k3_4_ko.png" alt="">
                                        <img v-if="key.indexOf('pimg5') == 0" src="../../../static/imgs/guide/k3_5_ko.png" alt="">
                                    </p>
                                    <p v-if="item == 'k4'">
                                        <img v-if="key.indexOf('pimg1') == 0" src="../../../static/imgs/guide/k4_1_ko.png" alt="">
                                        <img v-if="key.indexOf('pimg2') == 0" src="../../../static/imgs/guide/k4_2_ko.png" alt="">
                                        <img v-if="key.indexOf('pimg3') == 0" src="../../../static/imgs/guide/k4_3_ko.png" alt="">
                                    </p>
                                    <p v-if="item == 'k5'">
                                        <img v-if="key.indexOf('pimg1') == 0" src="../../../static/imgs/guide/k5_1_ko.png" alt="">
                                        <img v-if="key.indexOf('pimg2') == 0" src="../../../static/imgs/guide/k5_2_ko.png" alt="">
                                        <img v-if="key.indexOf('pimg3') == 0" src="../../../static/imgs/guide/k5_3_ko.png" alt="">
                                    </p>
                                    <p v-if="item == 'k6'">
                                        <img v-if="key.indexOf('pimg1') == 0" src="../../../static/imgs/guide/k6_1_ko.png" alt="">
                                        <img v-if="key.indexOf('pimg2') == 0" src="../../../static/imgs/guide/k6_2_ko.png" alt="">
                                        <img v-if="key.indexOf('pimg3') == 0" src="../../../static/imgs/guide/k6_3_ko.png" alt="">
                                    </p>
                                    <p v-if="item == 'k7'">
                                        <img v-if="key.indexOf('pimg1') == 0" src="../../../static/imgs/guide/k7_1_ko.png" alt="">
                                        <img v-if="key.indexOf('pimg2') == 0" src="../../../static/imgs/guide/k7_2_ko.png" alt="">
                                        <!-- <img v-if="key.indexOf('pimg3') == 0" src="../../../static/imgs/guide/k7_3_ko.png" alt=""> -->
                                        <!-- <img v-if="key.indexOf('pimg4') == 0" src="../../../static/imgs/guide/k7_4_ko.png" alt=""> -->
                                        <!-- <img v-if="key.indexOf('pimg5') == 0" src="../../../static/imgs/guide/k7_5_ko.png" alt=""> -->
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="article-attachments">
                            <ul class="attachments">

                            </ul>
                        </div>
                    </div>
                </section>
                <div class="article-relatives">
                </div>

            </article>
        </div>
    </div>
</main>
</template>

<script>
import {
    getLanguage
} from '@/utils/vue-i18n'
export default {
    name: "Content",
    props: ['screenHeight'],
    data() {
        return {
            list: [],
            currentKey: 'k1',

            currentTitle: "",
            currentContent: '',

            lang:''
        }
    },
    watch: {
        '$route'(to) {
            //获取key
            this.currentKey = to.query.key
            this.init()
        }
    },
    created() {
        this.init();
        this.lang = getLanguage().key
        console.log(this.lang)
    },
    methods: {
        listenLang(lang){
            this.lang = lang

            //初始化
            this.init()
        },
        init() {
            //获取列表
            this.list = this.$t('guide.list')

            //获取默认文章
            this.getContent(this.currentKey)
        },
        getContent(key) {

            this.list.forEach(item => {
                if (item.pkey == this.currentKey) {
                    this.currentTitle = item.ptitle
                }
            })

            this.currentContent = this.$t(`guide.content.${key}`)
            console.log(key, this.currentTitle, "当前文章")
        }
    }
}
</script>

<style src="../../../static/css/helpbox.css" scoped type="text/css"></style>
