export default `<p>
    <strong>ZEC（Zcash）</strong>
</p>
<p>
    <br/>
</p>
<p>
    1.Zcashとは
</p>
<p>
    Zcashは、分散型でオープンソースの暗号化されたインターネット通貨です。14年間にIEEE会議で発表された紙のZerocashプロトコルに基づいて開発され、トランザクションの匿名性を確保するためにゼロ知識証明と複数のアカウントメカニズムを採用しています。
</p>
<p>
    <br/>
</p>
<p>
    ビットコインと比較して、プライバシーとトランザクションの透明性の制御可能性により多くの注意を払っています。 具体的には、パブリックブロックチェーンはトランザクションレコードの送信者、受信者、トランザクションボリュームを暗号化します。ユーザーはユーザーの裁量で他のユーザーに表示キーを提供するかどうかを選択でき、キーを持っている人だけがトランザクション。
</p>
<p>
    <br/>
</p>
<p>
    2.詳細なパラメータ
</p>
<p>
    合計トークン：21,000,000 ZEC
</p>
<p>
    トークンの配布：鉱夫の場合は90％、チーム、投資家、コンサルタントの場合は10％
</p>
<p>
    <br/>
</p>
<p>
    3.一般的なリンク
</p>
<p>
    公式サイト：https：//z.cash/zh/
</p>
<p>
    技術文書：http：//zerocash-project.org/media/pdf/zerocash-extended-20140518.pdf
</p>
<p>
    <br/>
</p>`