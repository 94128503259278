export default `<p>
    <strong>UIP（将来の著作権）</strong>
</p>
<p>
    <br/>
</p>
<p>
    <strong>1.プロジェクト名</strong>
</p>
<p>
    将来の著作権（UIP）
</p>
<p>
    <br/>
</p>
<p>
    <strong>2.プロジェクト紹介</strong>
</p>
<p>
    Future Copyrightは、シンガポールUIPFoundationが管理するブロックチェーンテクノロジーに基づく文化的および娯楽的著作権のためのインテリジェントな取引プラットフォームです。 Future Copyrightは、グローバルエンターテインメントIP著作権所有者に著作権デポジット、著作権トランザクション、NFT製品インキュベーション、およびハイエンド暗号化アートNFTトランザクションを提供することに取り組んでいます。これはワンストップのスマートエンターテインメント著作権サービスです。将来的には、著作権は、著作権所有者が著作権デジタル資産NFTを作成し、著作権NFTを取引し、ブロックチェーン技術によってもたらされる資産トークン化、所有権マーキング、デジタル不足などを通じて著作権価値を最大化するのに役立ちます。
</p>
<p>
    <br/>
</p>
<p>
    <strong>3.トークンの概要と配布</strong>
</p>
<p>
    トークンの合計：3,000,000,000
</p>
<p>
    総発行部数：1,340,000,000
</p>
<p>
    トークン価格：$ 0.029
</p>
<p>
    トークンの配布：
</p>
<p>
    <br/>
</p>
<p>
    50％は主要な取引所で流通しています。
</p>
<p>
    16.7％は、文化的および娯楽的エコロジーを展開するためのUIPのコミュニティ基金として使用されます。
</p>
<p>
    33.3％は、UIPプロジェクトの開発資金として使用され、無期限にロックされ、UIP著作権取引のエコロジカルな構築、取引プラットフォームの媒体、およびチームの拡大に使用されます。に
</p>
<p>
    <br/>
</p>
<p>
    <strong>4<strong style="white-space: normal;">.</strong>つの一般的に使用されるリンク</strong>
</p>
<p>
    プロジェクトのウェブサイト：http：//www.uip.group/#/
</p>
<p>
    ホワイトペーパー：http：//cdn.uip.vip/whitePaper/UnlimitedIP_WhitePaper.pdf
</p>
<p>
    ブロッククエリ：https：//etherscan.io/token/0x4290563c2d7c255b5eec87f2d3bd10389f991d68
</p>
<p>
    <br/>
</p>`