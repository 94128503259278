<template>
<div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view :screenWidth="screenWidth" :screenHeight="screenHeight" :scrollTop="scrollTop" />
</div>
</template>

<script>
export default {
    name: "App",
    data() {
        return {
            screenWidth: document.documentElement.clientWidth, //实时屏幕宽度
            screenHeight: document.documentElement.clientHeight, //实时屏幕高度
            scrollTop: document.body.scrollTop,
        }
    },
    watch: {
        screenHeight() {
            //let that = this;
            //console.log("实时屏幕高度�?", that.screenHeight);
        },
        screenWidth() {
            //let that = this;
            //console.log("实时屏幕宽度�?", that.screenWidth);
        },
        scrollTop() {
            //let that = this;
            //console.log("实时滚动高度�?", that.scrollTop);
        }
    },
    beforeCreate() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        if (flag) {
            window.location.href = "https://wap.starthestar.com"
        }
    },
    mounted() {
        var that = this;
        // <!--把window.onresize事件挂在到mounted函数�?-->
        window.onresize = () => {
            return (() => {
                window.fullHeight = document.documentElement.clientHeight;
                window.fullWidth = document.documentElement.clientWidth;
                that.screenHeight = window.fullHeight; // �?
                that.screenWidth = window.fullWidth; // �?
            })()
        };
        window.onscroll = () => {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            return (() => {
                that.scrollTop = scrollTop
            })()
        }

        if (this._isMobile()) {
            console.log("手机端");
            //window.location.href = "https://wap.starthestar.com"
            //this.$router.replace('/m_index');
        } else {
            console.log("pc端");
            //this.$router.replace('/pc_index');
        }
    },
    methods: {
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
    }
}
</script>

<style>
/* 密码弹出�? */
.el-dialog {
    background: #0a162b !important;
}

/* 标�?? */
.el-dialog__title {
    color: #FFF !important;
}

/* 输入�? */
.el-input__inner {
    background-color: var(--style-bg3) !important;
    color: #FFF !important;
    border: 1px solid #5a5a5a !important;
}

/* 选中输入�? */
.el-input__inner:focus {
    border-color: #426871 !important;
}

/* 按钮 */
/* .el-button:focus, .el-button:hover {
    color: #fffcf7 !important;
    border-color: #c6e2ff !important;
    background-color: #67a3b1 !important;
} */
/* 按钮背景 */
.dialog-footer .el-button {
    background: #363c4e !important;
    color: #FFF !important;
}

.dialog-footer .el-button:hover {
    background: #565f7a !important;
    color: #FFF !important;
}

/* 隐藏input数字滚动图标 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
}

/* 左侧弹出栏的内�?�高�? */
.el-drawer__body {
    height: 100% !important;
}

.video-js {
    width: 100%;
    height: 400px;
}

.video-js .vjs-big-play-button {
    top: 50% !important;
    left: 50% !important;
    margin-left: -1.5em;
    margin-top: -1em;
    background-color: rgba(0, 0, 0, .45);
    font-size: 3.5em;
    height: 2em !important;
    line-height: 2em !important;
    margin-top: -1em !important;
}
</style>
