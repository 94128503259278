import Vue from 'vue';
import axios from 'axios'
import VueAxios from 'vue-axios'
import { Notification } from 'element-ui';
Vue.prototype.$notify = Notification;

// 创建 axios 实例
const request = axios.create({
    // API 请求的默认前缀
    baseURL: "/web2",
    timeout: 20000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
    console.log('异常错误', error)
    if (error.response) {
        if (error.response.status === 500 || error.response.status === 404) {
            Notification({
                message: "Server exception",
                type: 'error',
            });
        }
    }
    return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
    // 如果 token 存在
    const token = localStorage.getItem('token')
    if (token) {
        config.headers['Access-Token'] = token
    }

    // 设置语言
    config.headers['Accept-Language'] = "en-us,en;q=0.5"
    let lang = localStorage.getItem('lang')
    if (lang) {
        config.headers['Accept-Language'] = lang + ";q=0.5"
    }

    return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {

    if (response.data.code == 101) {
        //登录过期
        localStorage.removeItem('token')
        localStorage.removeItem('uid')
            // Notification({
            //     message: response.data.msg,
            //     type: 'error',
            // });
        return Promise.reject(response.data)
    }

    return response.data
}, errorHandler)

const installer = {
    vm: {},
    install(Vue) {
        Vue.use(VueAxios, request)
    }
}

export default request

export {
    installer as VueAxios,
    request as axios
}