export default {
    list:[
        {
            name:"ADA (카르 다노)",
            key:"ada",
        },
        {
            name:"BAL (Balancer)",
            key:"bal",
        },
        {
            name:"BAND (Band Protocol)",
            key:"band",
        },
        {
            name:"BCH (Bitcoin Cash Node)",
            key:"bch",
        },
        {
            name:"BHD (BitcoinHD (BHD))",
            key:"bhd",
        },
        {
            name:"BSV (Bitcoin SV )",
            key:"bsv",
        },
        {
            name:"BTC（비트 코인）",
            key:"btc",
        },
        {
            name:"COMP (Compound)",
            key:"comp",
        },
        {
            name:"DASH (대시)",
            key:"dash",
        },
        {
            name:"DCR (Decred)",
            key:"dcr",
        },
        {
            name:"DOT (Polkadot（DOT）)",
            key:"dot",
        },
        {
            name:"ELA (Elastos)",
            key:"ela",
        },
        {
            name:"EOS (EOS)",
            key:"eos",
        },
        {
            name:"ETC (이더 리움 클래식)",
            key:"etc",
        },
        {
            name:"ETH（이더 리움）",
            key:"eth",
        },
        {
            name:"HT (Huobi Token)",
            key:"ht",
        },
        {
            name:"KNC (Kyber Network)",
            key:"knc",
        },
        {
            name:"LINK (ChainLink)",
            key:"link",
        },
        {
            name:"LTC (라이트 코인)",
            key:"ltc",
        },
        {
            name:"MANA (Decentraland)",
            key:"mana",
        },
        {
            name:"MKR (Maker)",
            key:"mkr",
        },
        {
            name:"MLN (Melon)",
            key:"mln",
        },
        {
            name:"NEO (NEO)",
            key:"neo",
        },
        {
            name:"QTUM (양자 사슬)",
            key:"qtum",
        },
        {
            name:"TRB (Tellor Tributes)",
            key:"trb",
        },
        {
            name:"TRX (Tron)",
            key:"trx",
        },
        {
            name:"UIP (향후 저작권)",
            key:"uip",
        },
        {
            name:"USDT（Tether）",
            key:"usdt",
        },
        {
            name:"XMR (Monero)",
            key:"xmr",
        },
        {
            name:"XTZ (Tezos)",
            key:"xtz",
        },
        {
            name:"ZEC (Zcash)",
            key:"zec",
        },
    ],
    infos:{
        ada:require('./asset/ada').default,
        bal:require('./asset/bal').default,
        band:require('./asset/band').default,
        bch:require('./asset/bch').default,
        bhd:require('./asset/bhd').default,
        bsv:require('./asset/bsv').default,
        btc:require('./asset/btc').default,
        comp:require('./asset/comp').default,
        dash:require('./asset/dash').default,
        dcr:require('./asset/dcr').default,
        dot:require('./asset/dot').default,
        ela:require('./asset/ela').default,
        eos:require('./asset/eos').default,
        etc:require('./asset/etc').default,
        eth:require('./asset/eth').default,
        ht:require('./asset/ht').default,
        knc:require('./asset/knc').default,
        link:require('./asset/link').default,
        ltc:require('./asset/ltc').default,
        mana:require('./asset/mana').default,
        mkr:require('./asset/mkr').default,
        mln:require('./asset/mln').default,
        neo:require('./asset/neo').default,
        qtum:require('./asset/qtum').default,
        trb:require('./asset/trb').default,
        trx:require('./asset/trx').default,
        uip:require('./asset/uip').default,
        usdt:require('./asset/usdt').default,
        xmr:require('./asset/xmr').default,
        xtz:require('./asset/xtz').default,
        zec:require('./asset/zec').default,
    }
}