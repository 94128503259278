export default `<p>
    <strong>EOS (EOS)</strong>
</p>
<p>
    <br/>
</p>
<p>
    1. EOS 란 무엇입니까?
</p>
<p>
    <br/>
</p>
<p>
    EOS (Enterprise Operation System)는 Block.one에서 개발 한 새로운 블록 체인 기반 스마트 계약 플랫폼으로, 고성능 분산 애플리케이션을위한 기본 블록 체인 플랫폼 서비스를 제공하는 것을 목표로합니다. EOS 프로젝트의 목표는 운영 체제와 유사한 분산 애플리케이션을 지원하는 블록 체인 아키텍처를 실현하는 것입니다. 이 아키텍처는 수만 개의 CPU / GPU 클러스터에서 계정, ID 인증, 데이터베이스, 비동기 통신, 프로그램 스케줄링 및 병렬 작업을 제공 할 수 있습니다. EOS는 궁극적으로 초당 수백만 건의 트랜잭션 실행을 지원할 수 있으며 일반 사용자는 스마트 계약을 실행하기 위해 사용료를 지불 할 필요가 없습니다.
</p>
<p>
    <br/>
</p>
<p>
    2. EOS의 기능
</p>
<p>
    <br/>
</p>
<p>
    EOS 토큰은 현재 EOS 블록 체인 인프라에서 발행하는 이더 리움 기반 토큰입니다. 대역폭 및 로그 저장소 (하드 디스크), 계산 및 계산 예약 (CPU), 상태 저장소 (RAM)의 세 가지 주요 애플리케이션 시나리오가 있습니다. EOS 메인 넷이 활성화 된 후 ERC20 토큰 EOS는 메인 체인에서 토큰으로 변환됩니다. 다음과 같이 설명 할 수 있습니다.
</p>
<p>
    <br/>
</p>
<p>
    (1) 수취인 지불 : 고객은 사업체에서 특정 제품을 구매하고, 이러한 제품의 판매 수익은 사업 비용을 지불하는 데 사용되며, 고객이 블록 체인 사용에 대해 직접 지불하는 것을 피하고 제한하지 않습니다. 회사가 제품 수익 화 전략을 결정하지 못하게합니다.
</p>
<p>
    <br/>
</p>
<p>
    (2) 인증 능력 : 블록 체인이 EOS 소프트웨어 시스템을 기반으로 개발되고 토큰 보유자가 토큰을 보유하고있는 경우, 즉시 사용 가능한 대역폭의 전부 또는 일부를 소비 할 필요가 없습니다. 이러한 토큰 보유자는 선택할 수 있습니다. 사용되지 않은 대역폭을 다른 사람에게 제공하거나 임대합니다.
</p>
<p>
    에
</p>
<p>
    (3) 토큰 값과 거래 비용 분리 : 애플리케이션 소유자가 해당 개수의 토큰을 보유하고있는 경우 애플리케이션은 고정 된 상태 및 대역폭 사용량으로 계속 실행될 수 있습니다. 개발자와 사용자는 토큰 시장의 가격 변동에 영향을받지 않으므로 가격에 의존하지 않습니다.
</p>
<p>
    에
</p>
<p>
    (4) 블록 보상 : 블록이 생성 될 때마다 EOS는 블록 생산자에게 운영 및 유지 비용을 지불하기 위해 새로운 토큰을 보상합니다. 현재 EOS 설정은 블록 생산자에 대한 보상의 상한을 제한하고 있으며, 현재 EOS가 블록 생산자에게 설정 한 보상의 상한은 연간 최대 5 %입니다. 즉, 새로운 토큰 공급량의 연간 총 증가량은 5 %를 초과하지 마십시오.
</p>
<p>
    에
</p>
<p>
    3. 자주 사용하는 링크
</p>
<p>
    공식 웹 사이트 : https://eos.io/
</p>
<p>
    백서 : https://github.com/EOSIO/Documentation/blob/master/TechnicalWhitePaper.md#support-millions-of-users
</p>
<p>
    <br/>
</p>`