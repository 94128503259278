export default `<p>
    <strong>MLN（メロン）</strong>
</p>
<p>
    <br/>
</p>
<p>
    1.プロジェクト名
</p>
<p>
    メロン（MLN）
</p>
<p>
    <br/>
</p>
<p>
    2.プロジェクトのポジショニング
</p>
<p>
    Melonは、Ethereumブロックチェーンを搭載したデジタル資産管理プラットフォームです。 メロンの目標は、個人投資家であれ機関投資家であれ、さまざまなタイプの資産運用会社が投資ポートフォリオを安全、迅速、効率的に管理できるよう支援することです。
</p>
<p>
    <br/>
</p>
<p>
    3、通貨の概要と流通
</p>
<p>
    合計トークン：1,524,081
</p>
<p>
    総循環：1,093,397
</p>
<p>
    <br/>
</p>
<p>
    4つの一般的に使用されるリンク
</p>
<p>
    プロジェクトのウェブサイト：https：//melonprotocol.com/
</p>
<p>
    ホワイトペーパー：https：//github.com/melonproject/paper/blob/master/melonprotocol.pdf
</p>
<p>
    ブロッククエリ：https：//etherscan.io/token/0xec67005c4E498Ec7f55E092bd1d35cbC47C91892
</p>
<p>
    <br/>
</p>`