export default `<p>
    <strong>ZEC (Zcash)</strong>
</p>
<p>
    <br/>
</p>
<p>
    1. Zcash 란?
</p>
<p>
    Zcash는 분산 된 오픈 소스 암호화 인터넷 통화로, 14 년 만에 IEEE 컨퍼런스에서 발표 된 종이 제로 캐시 프로토콜을 기반으로 개발되었으며 거래의 익명 성을 보장하기 위해 제로 지식 증명 및 다중 계정 메커니즘을 채택합니다.
</p>
<p>
    <br/>
</p>
<p>
    비트 코인과 비교하여 개인 정보 보호 및 거래 투명성의 제어 가능성에 더 많은 관심을 기울입니다. 특히 퍼블릭 블록 체인은 거래 기록에서 발신자, 수신자, 거래량을 암호화합니다. 거래.
</p>
<p>
    <br/>
</p>
<p>
    2. 세부 매개 변수
</p>
<p>
    총 토큰 : 21,000,000 ZEC
</p>
<p>
    토큰 분배 : 채굴 자의 경우 90 %, 팀, 투자자 및 컨설턴트의 경우 10 %
</p>
<p>
    <br/>
</p>
<p>
    3. 공통 링크
</p>
<p>
    공식 웹 사이트 : https://z.cash/zh/
</p>
<p>
    기술 문서 : http://zerocash-project.org/media/pdf/zerocash-extended-20140518.pdf
</p>
<p>
    <br/>
</p>`