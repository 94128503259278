<template>
  <div class="route-box">
    <section>
      <div>
        <div class="pub-layout">
          <!-- 左侧栏 -->
          <el-drawer :visible.sync="drawer" :with-header="false" :modal="false" direction="ltr" size="300px" style="-top: 15%; -height: 50%; color: #fff">
            <div class="drawer-content">
              <div class="d-title">
                <div class="d-line"></div>
                <span class="d-text">{{ $t("kline.all_currency") }}</span>
              </div>
              <div class="d-title">
                <div class="d-line"></div>
                <div style="width: 100%;">
                  <input
                    type="text"
                    v-model="searchText"
                    placeholder="search text"
                    class="input is-right-radius input2 text-font-flex button-input-height"
                  />
                </div>
              </div>
              <div class="d-table-box">
                <table class="d-table">
                  <thead>
                    <tr>
                      <th>{{ $t("kline.currency") }}</th>
                      <th>{{ $t("kline.new_price") }}</th>
                      <th>{{ $t("kline.change") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in coinsMoreList" :key="index" @click="changeSymbol(item)" v-show="isShow(item)">
                      <td class="d-title-one">
                        <div class="d-symbol">{{ item.name }}</div>
                        <div class="d-desc">/ {{ item.desc }}</div>
                      </td>
                      <td :class="
                          item.change >= 0
                            ? 'has-text-success'
                            : 'has-text-danger'
                        ">
                        {{ item.close }}
                      </td>
                      <td :class="
                          item.change >= 0
                            ? 'has-text-success'
                            : 'has-text-danger'
                        ">
                        {{ item.change >= 0 ? "+" : "" }}{{ item.change }}%
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </el-drawer>
          <!-- 币种列表 -->
          <div class="pub-layout-title-header pb-1 pub-layout-title">
            <div class="box pub-header-title is-flex py-3 px-3 -header-title-body" style="overflow-y: scroll">
              <!-- 更多 -->
              <div class="btn-all" @click="drawer = true">
                <div>{{ $t("kline.all") }}</div>
                <i class="el-icon-d-arrow-right"></i>
              </div>
              <div class="is-flex pub-header-title-x">
                <!-- 币种列表 -->
                <div class="
                    has-text-1
                    px-3
                    header-title-bor
                    cursor-pointer
                    spottick-prz-text-1
                  " v-for="(item, index) in coinsList" :key="index" @click="changeSymbol(item)">
                  <p class="">
                    <span class="header-title-futurelist">{{ item.name }}</span>
                    <span class="has-text-3 px-1 spottick-text-color-4">/</span>
                    <span class="has-text-3 spottick-text-color-4">{{
                      item.desc
                    }}</span>
                  </p>
                  <p class="header-title-text font-weight-bold is-flex">
                    <span :class="
                        'heaer-title-span header-title-conent is-flex ' +
                        (item.change >= 0
                          ? 'has-text-success'
                          : 'has-text-danger')
                      ">
                      <span class="header-title-span-text">{{
                        item && item.close
                      }}</span>
                      <i :class="
                          'iconfont spottick-prz-text-1 header-title-span-icon ' +
                          (item.change >= 0 ? 'icon-rise' : 'icon-fall')
                        "></i>
                    </span>
                    <span class="header-title-conent">
                      <span :class="
                          item.change >= 0
                            ? 'has-text-success'
                            : 'has-text-danger'
                        ">{{ item.change >= 0 ? "+" : "" }}</span>
                      <span :class="
                          item.change >= 0
                            ? 'has-text-success'
                            : 'has-text-danger'
                        ">{{ item.change }}%</span>
                    </span>
                  </p>
                  <!-- 选中下划线 -->
                  <hr :class="
                      'my-0 ' +
                      (currency.symbol == item.symbol
                        ? 'has-background-hr1'
                        : '')
                    " />
                </div>
              </div>
            </div>
          </div>

          <!-- 币种信息 -->
          <div class="pub-layout-tick">
            <div class="pub-header-tick box is-flex pb-3">
              <!-- 币信息 -->
              <div class="pub-header-tick-left">
                <div class="pub-header-tick-left-box1 is-flex">
                  <p class="
                      tick-name
                      px-3
                      spottick-prz-text-6 spottick-header-tick
                      is-flex
                    ">
                    <span class="has-text-1 header-title-futurelist">{{
                      currency && currency.name
                    }}</span>
                    <span class="has-text-3 px-2">/</span>
                    <span class="has-text-1 header-title-futurelist">{{
                      currency && currency.desc
                    }}</span>
                    <span class="spottick-prz-text-7 spottick-name">HOT</span>
                  </p>
                  <p :class="
                      'tick-number has-text-1 px-3 spottick-prz-width ' +
                      (currency && currency.change >= 0
                        ? 'has-text-success'
                        : 'has-text-danger')
                    ">
                    <span class="spottick-prz-text-6 font-weight-bold">{{
                      currency && currency.close
                    }}</span>
                    <span :class="
                        'is-hidden-touch ' +
                        (currency && currency.change >= 0
                          ? 'has-text-success'
                          : 'has-text-danger')
                      ">
                      <i :class="
                          'icon-fall-rise iconfont ' +
                          (currency && currency.change >= 0
                            ? 'icon-rise'
                            : 'icon-fall')
                        "></i>
                    </span>
                  </p>
                </div>
                <span class="
                    is-hidden-touch
                    spottick-prz-text-5
                    font-weight-bold
                    header-tick-rf
                  ">
                  <span :class="
                      currency && currency.change >= 0
                        ? 'has-text-success'
                        : 'has-text-danger'
                    ">{{ currency && currency.change >= 0 ? "+" : "" }}</span>
                  <span :class="
                      currency && currency.change >= 0
                        ? 'has-text-success'
                        : 'has-text-danger'
                    ">{{ currency && currency.change }}%</span>
                </span>
                <table class="is-hidden-touch tick-info ml-2">
                  <tr class="">
                    <td class="pr-2">
                      <div class="">
                        <p class="mb-1 spottick-text-color-4">
                          {{ $t("kline.h24_high") }}
                        </p>
                        <p class="
                            has-text-1
                            tick-info-number
                            has-text-1-important
                            font-weight-bold
                          ">
                          {{ currency && currency.high }}
                        </p>
                      </div>
                    </td>
                    <td class="pr-2">
                      <div class="">
                        <p class="mb-1 spottick-text-color-4">
                          {{ $t("kline.h24_low") }}
                        </p>
                        <p class="
                            has-text-1
                            tick-info-number
                            has-text-1-important
                            font-weight-bold
                          ">
                          {{ currency && currency.low }}
                        </p>
                      </div>
                    </td>
                    <td class="pr-2">
                      <div class="">
                        <p class="mb-1 spottick-text-color-4">
                          {{ $t("kline.h24_vol") }}
                        </p>
                        <p class="
                            has-text-1
                            tick-info-number
                            has-text-1-important
                            font-weight-bold
                          ">
                          {{ currency && currency.vol }}
                        </p>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="spacer"></div>
            </div>
          </div>

          <!-- 下面一大块 -->
          <div class="pub-layout-content is-clearfix">
            <!-- 左边大块 -->
            <div class="
                pub-layout-content-left
                is-pulled-left is-clearfix
                pub-layout-content-kline-left
              ">
              <!-- 左边-上面大块 -->
              <div class="pub-layout-content-left-top columns is-flex">
                <!-- 左边 - TradingView -->
                <div class="pub-layout-content-left-kline column py-top" style="padding: 0">
                  <div class="pub-kline box">
                    <div class="pub-kline-iframe" style="height: 100%">
                      <div id="tv_chart_container" style="height: 100%">
                        <TVChartContainer ref="TVChartContainer"></TVChartContainer>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 右边 - 挂单 -->
                <div class="pub-layout-content-left-dish py-1 px-0">
                  <div class="is-background-2" style="height: 100%">
                    <div class="pub-dish has-text-1">
                      <!-- 卖单 -->
                      <!-- 机器人 与 火币模式 -->
                      <div class="pub-dish-box">
                        <!-- <div class="pub-dish-box-title has-text-1 is-background-3">{{$t('kline.commission_list')}}</div> -->
                        <div class="pub-dish-box-title has-text-1 is-background-3">
                          {{ currency.name }}
                        </div>
                        <div class="pub-dish-top" v-if="currency.kline_type == 2 || currency.kline_type == 3">
                          <p class="pub-dish-top-pic has-text-2">
                            <span>{{ $t("kline.price") }}</span>
                            <span class="">({{ currency.desc }})</span>
                          </p>
                          <p class="pub-dish-top-num has-text-2">
                            <span>{{ $t("kline.number") }}</span><span class="">(LOT)</span>
                          </p>
                          <p class="pub-dish-top-time is-hidden-touch has-text-2">
                            <span>{{ $t("kline.total") }}</span><span class="">({{ currency.desc }})</span>
                          </p>
                        </div>

                        <!-- 卖单列表 -->
                        <div class="pub-dish-list-item is-flex" v-for="(item, index) in ask" :key="index">
                          <div class="">
                            <p class="w100 has-text-left has-text-danger">
                              {{ item.price }}
                            </p>
                          </div>
                          <div class="">
                            <p class="w100">{{ item.quantity }}</p>
                          </div>
                          <div class="is-hidden-touch">
                            <p class="w100 has-text-right">{{ item.total }}</p>
                          </div>
                          <div class="
                              pub-dish-list-item-mode
                              has-background-danger
                              dynamic-effect
                            " :style="
                              'width:' +
                              (item.change > 100 ? 100 : item.change) +
                              '%;'
                            "></div>
                        </div>
                      </div>
                      <!-- 贵金属显示模式 -->
                      <div class="pub-dish-box" v-if="currency.kline_type == 1">
                        <div class="js_main_box">
                          <div class="js_sell">
                            <span>{{ $t("coins.sell") }}</span>
                            <span class="has-text-danger">{{
                              detail.data && detail.data.bid
                            }}</span>
                          </div>
                          <div class="js_sell">
                            <span>{{ $t("coins.buy") }}</span>
                            <span class="has-text-danger">{{
                              detail.data && detail.data.ask
                            }}</span>
                          </div>
                          <div class="js_info">
                            <div class="js_box">
                              <div class="js_box_info">
                                <div>{{ $t("coins.current_price") }}</div>
                                <div class="has-text-success">
                                  {{ detail.close }}
                                </div>
                              </div>
                              <div class="js_box_info">
                                <div>{{ $t("coins.open_price") }}</div>
                                <div class="has-text-success">
                                  {{ detail.open }}
                                </div>
                              </div>
                              <div class="js_box_info">
                                <div>{{ $t("coins.high") }}</div>
                                <div class="has-text-success">
                                  {{ detail.high }}
                                </div>
                              </div>
                              <div class="js_box_info">
                                <div>{{ $t("coins.low") }}</div>
                                <div class="has-text-success">
                                  {{ detail.low }}
                                </div>
                              </div>
                              <div class="js_box_info">
                                <div>{{ $t("coins.yesterday") }}</div>
                                <div class="has-text-warring">
                                  {{ detail.open }}
                                </div>
                              </div>
                              <div class="js_box_info">
                                <div>{{ $t("coins.today_zf") }}</div>
                                <div class="has-text-warring">
                                  {{ detail.change }}%
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- 买单 -->
                      <div class="pub-dish-box" v-if="currency.kline_type != 4">
                        <!-- 中间价格显示 -->
                        <div class="pub-dish-tick">
                          <div class="is-flex">
                            <div class="">
                              <span :class="
                                  'has-text-weight-semibold is-size-4 ' +
                                  (currency.change >= 0
                                    ? 'has-text-success'
                                    : 'has-text-danger')
                                ">{{ currency.close }}</span>
                              <span :class="
                                  'has-text-weight-semibold is-size-4 ' +
                                  (currency.change >= 0
                                    ? 'has-text-success'
                                    : 'has-text-danger')
                                ">
                                <i :class="
                                    'iconfont ' +
                                    (currency.change >= 0
                                      ? 'icon-rise'
                                      : 'icon-fall')
                                  "></i>
                              </span>
                            </div>
                            <div class="spacer"></div>
                            <div class="">
                              <span :class="
                                  'has-text-weight-semibold is-size-5 ' +
                                  (currency.change >= 0
                                    ? 'has-text-success'
                                    : 'has-text-danger')
                                ">{{ currency.change >= 0 ? "+" : "" }}</span>
                              <span :class="
                                  'has-text-weight-semibold is-size-5 ' +
                                  (currency.change >= 0
                                    ? 'has-text-success'
                                    : 'has-text-danger')
                                ">{{ currency.change }}%</span>
                            </div>
                          </div>
                        </div>
                        <!-- 买单列表 -->
                        <div class="pub-dish-list-item is-flex" v-for="(item, index) in bids" :key="index">
                          <div class="">
                            <p class="w100 has-text-left has-text-success">
                              {{ item.price }}
                            </p>
                          </div>
                          <div class="">
                            <p class="w100">{{ item.quantity }}</p>
                          </div>
                          <div class="is-hidden-touch">
                            <p class="w100 has-text-right">{{ item.total }}</p>
                          </div>
                          <div class="
                              pub-dish-list-item-mode
                              has-background-success
                              dynamic-effect
                            " :style="
                              'width:' +
                              (item.change > 100 ? 100 : item.change) +
                              '%;'
                            "></div>
                        </div>
                      </div>

                      <!-- 交易记录 -->
                      <div class="pub-dish-box mt-1">
                        <div class="pub-dish-box-title has-text-1 is-background-3">
                          {{ $t("kline.deal_list") }}
                        </div>
                        <div class="pub-new-trade">
                          <div class="pub-new-trade-top">
                            <p class="pub-new-trade-top-pic has-text-2">
                              {{ $t("kline.price") }}({{ currency.desc }})
                            </p>
                            <p class="pub-new-trade-top-num has-text-2">
                              {{ $t("kline.number") }}(LOT)
                            </p>
                            <p class="pub-new-trade-top-time has-text-2">
                              {{ $t("kline.time") }}
                            </p>
                          </div>
                          <!-- 交易记录列表 -->
                          <div class="pub-new-trade-list">
                            <div class="pub-new-trade-list-item level is-relative" v-for="(item, index) in trade" :key="index">
                              <div class="w30">
                                <p :class="
                                    'w100 has-text-left ' +
                                    (item.direction == 'sell'
                                      ? 'has-text-danger'
                                      : 'has-text-success')
                                  ">
                                  {{ item.price }}
                                </p>
                              </div>
                              <div class="w30">
                                <p class="w100 has-text-1 pub-new-trade-new">
                                  {{ item.num }}
                                </p>
                              </div>
                              <div class="w30">
                                <p class="w100 has-text-2">
                                  {{ item.time_str }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--  -->
                    </div>
                  </div>
                </div>
              </div>

              <!-- 左边下面 -->
              <router-view ref="record" name="record"></router-view>
            </div>

            <!-- 右边操作块 -->
            <router-view ref="trade" name="trade"></router-view>
            <!--  -->

            <div style="clear: both"></div>
          </div>
          <!-- -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { getCoins, getLever, getTrading, getContract } from "@/api/currency";
import TVChartContainer from "../components/TVChartContainer.vue";
import socket from "../utils/socket";
import { GetNewFixed } from "../utils/helper";
export default {
  components: {
    TVChartContainer,
  },
  name: "KlineChart",
  data() {
    return {
      //币种列表
      coinsList: [],
      //更多列表
      coinsMoreList: [],
      //当前币种
      currency: {},
      //交易
      trade: [],
      //挂单
      ask: [],
      bids: [],

      drawer: false,

      //页面参数
      defCurrency: {},
      symbol: "",

      //贵金属详情
      detail: {},

      searchText: ''
    };
  },
  watch: {
    $route(to) {
      console.log("路由切换", to);

      // 获取币币
      this.getList();
    },
  },
  created() {
    //带参数
    console.log("带参数", this.$route.query);
    if (this.$route.query.symbol != undefined) {
      this.symbol = this.$route.query.symbol;
    }

     // 连接后
     let that = this
     socket.on("onReady", function () {
        //启动心跳
        socket.sendPing();
      });

      socket.on("onReconnect", function () {
        // 断线重连
        that.getList();
        // _this.getTrading({ i: _this.$route.query.i });
      });

    // 获取币币
    this.getList();
  },
  destroyed() {
    //console.log("销毁");
    if (socket.getReadyState() == 1) {
      socket.send({
        type: "unsub.detail",
      });
      socket.send({
        type: "unsub.kline",
      });
      socket.send({
        type: "unsub.depth",
      });

      let uid = localStorage.getItem("uid");
      socket.send({
        type: "unsub.user",
        data: {
          uid: uid,
        },
      });
    }
  },
  methods: {
    isShow(item) {
      if (this.drawer) {
        var text = item.name + item.desc;
        var search = this.searchText.toLowerCase();
        if (text.toLowerCase().indexOf(search) > -1) {
          // console.log(item.symbol,'isShow')
          return true;
        }
      }
    },
    /**
     * 语言变化
     */
    listenLang(local) {
      console.log(local, "KlineChart");
      if (typeof this.$refs.trade.listenLang == "function") {
        this.$refs.trade.listenLang(local);
      }
    },
    getList() {
      if (this.$route.name == "Coins") {
        this.getCoins();
      } else if (this.$route.name == "Lever") {
        this.getLever();
      } else if (this.$route.name == "Contract") {
        this.getContract();
      } else if (this.$route.name == "Trading") {
        this.getTrading(this.$route.query);
      }
    },
    /**
     * 获取币币列表
     */
    getCoins() {
      let _this = this;
      //load
      this.$ui.openLoading();

      getCoins()
        .then((res) => {
          //close
          this.$ui.closeLoading();
          _this.coinsMoreList = res.data;

          _this.coinsList = [];
          res.data.forEach((item, index) => {
            item.close = GetNewFixed(item.close, item.decimal);

            //设置当前币 参数默认币
            if (_this.symbol != "" && _this.symbol == item.symbol) {
              _this.defCurrency = item;
            }

            //页面只放11个
            if (index < 11) {
              _this.coinsList.push(item);
            }
          });

          if (_this.symbol != "") {
            _this.setCurrency(_this.defCurrency);
          } else {
            _this.setCurrency(_this.coinsList[0]);
          }
        })
        .catch(() => {
          //close
          this.$ui.closeLoading();
        });
    },
    /**
     * 获取币币列表
     */
    getLever() {
      let _this = this;
      //load
      this.$ui.openLoading();

      getLever()
        .then((res) => {
          //close
          this.$ui.closeLoading();
          _this.coinsMoreList = res.data;

          _this.coinsList = [];
          res.data.forEach((item, index) => {
            item.close = GetNewFixed(item.close, item.decimal);

            //设置当前币 参数默认币
            if (_this.symbol != "" && _this.symbol == item.symbol) {
              _this.defCurrency = item;
            }

            if (index < 11) {
              _this.coinsList.push(item);
            }
          });

          //设置当前币
          if (_this.symbol != "") {
            _this.setCurrency(_this.defCurrency);
          } else {
            _this.setCurrency(_this.coinsList[0]);
          }
        })
        .catch(() => {
          //close
          this.$ui.closeLoading();
        });
    },
    /**
     * 获取币币列表
     */
    getContract() {
      let _this = this;
      //load
      this.$ui.openLoading();

      getContract()
        .then((res) => {
          //close
          this.$ui.closeLoading();
          _this.coinsMoreList = res.data;

          _this.coinsList = [];
          res.data.forEach((item, index) => {
            item.close = GetNewFixed(item.close, item.decimal);

            //设置当前币 参数默认币
            if (_this.symbol != "" && _this.symbol == item.symbol) {
              _this.defCurrency = item;
            }

            if (index < 11) {
              _this.coinsList.push(item);
            }
          });

          //设置当前币
          if (_this.symbol != "") {
            _this.setCurrency(_this.defCurrency);
          } else {
            _this.setCurrency(_this.coinsList[0]);
          }
        })
        .catch(() => {
          //close
          this.$ui.closeLoading();
        });
    },
    /**
     * 国际贵金属
     */
    getTrading(query) {
      let _this = this;
      //load
      this.$ui.openLoading();

      getTrading({
        id: query.i,
      })
        .then((res) => {
          //close
          this.$ui.closeLoading();
          _this.coinsMoreList = res.data;

          _this.coinsList = [];
          res.data.forEach((item, index) => {
            item.close = GetNewFixed(item.close, item.decimal);

            //设置当前币 参数默认币
            if (_this.symbol != "" && _this.symbol == item.symbol) {
              _this.defCurrency = item;
            }

            if (index < 11) {
              _this.coinsList.push(item);
            }
          });

          //设置当前币
          if (_this.symbol != "") {
            _this.setCurrency(_this.defCurrency);
          } else {
            _this.setCurrency(_this.coinsList[0]);
          }
        })
        .catch(() => {
          //close
          this.$ui.closeLoading();
        });
    },
    socketListen() {
      let _this = this;
      // 初始化历史k线
      socket.on("detail", function (data) {
        // 最新信息
        for (var i in _this.coinsList) {
          if (_this.coinsList[i].symbol == data.symbol) {
            let decimal = _this.coinsList[i].decimal;
            _this.coinsList[i].close = GetNewFixed(data.close, decimal);
            _this.coinsList[i].change = data.change;
            _this.coinsList[i].high = GetNewFixed(data.high, decimal);
            _this.coinsList[i].low = GetNewFixed(data.low, decimal);
            _this.coinsList[i].vol = GetNewFixed(data.vol, decimal);
          }

          if (_this.currency.symbol == data.symbol) {
            //设置当前币详情
            _this.detail = data;

            // 回调 setDetail
            if (typeof _this.$refs.trade.setDetail == "function") {
              _this.$refs.trade.setDetail(data);
            }

            if (typeof _this.$refs.record.setDetail == "function") {
              _this.$refs.record.setDetail(data);
            }
          }
        }
      });

      // 深度信息
      socket.on("depth", function (data) {
        if (_this.currency.symbol == data.symbol) {
          if (
            _this.currency.kline_type == 2 ||
            _this.currency.kline_type == 3
          ) {
            _this.ask = data.asks;
            _this.bids = data.bids;
          }
        }
      });

      // 交易信息
      socket.on("trade", function (data) {
        data.forEach((item) => {
          if (_this.currency.symbol == item.symbol) {
            if (_this.currency.kline_type == 4) {
              if (_this.trade.length >= 10) {
                _this.trade.pop();
              }
              _this.trade.splice(0, 0, item);
            } else {
              //删掉第一个
              if (_this.trade.length >= 6) {
                _this.trade.shift();
              }
              _this.trade.push(item);
            }
          }
        });
      });

      //钱包信息
      socket.on("wallet", function (res) {
        // console.log(res,'wallet')

        //总权益
        let u_total = GetNewFixed(res.total_equity);
        //可用
        let u_usable = GetNewFixed(res.user_usable);

        // 回调 setDetail
        if (typeof _this.$refs.record.setWallet == "function") {
          _this.$refs.record.setWallet(res, u_total, u_usable);
        }

        //回调 setWallet
        if (typeof _this.$refs.trade.setWallet == "function") {
          _this.$refs.trade.setWallet(res, u_total, u_usable);
        }
      });
    },
    /**
     * 设置当前币
     */
    setCurrency(currency) {
      this.currency = currency;

      this.reSetParam(currency);

      // 回调 setCurrency
      if (typeof this.$refs.trade.setCurrency == "function") {
        this.$refs.trade.setCurrency(currency);
      }
      // 回调 setCurrency
      if (typeof this.$refs.record.setCurrency == "function") {
        this.$refs.record.setCurrency(currency);
      }
      //监听
      this.socketListen();
      // 初始化k线
      this.$refs.TVChartContainer.initWidget(currency);
    },
    reSetParam(currency) {
      //重置参数
      this.trade = [];
      this.ask = [];
      this.bids = [];
      this.detail = {
        change: 0,
        close: currency.close,
        data: currency.data,
        high: currency.high,
        low: currency.low,
        open: currency.open,
        symbol: currency.symbol,
      };
    },
    /**
     * 切换币种
     */
    changeSymbol(currency) {
      this.drawer = false;

      this.currency = currency;

      this.reSetParam(currency);

      // 回调 setCurrency
      if (typeof this.$refs.trade.setCurrency == "function") {
        this.$refs.trade.setCurrency(currency);
      }
      // 回调 setCurrency
      if (typeof this.$refs.record.setCurrency == "function") {
        this.$refs.record.setCurrency(currency);
      }
      //切换币种
      this.$refs.TVChartContainer.changeSymbol(currency);
    },
  },
};
</script>

<style src="../../static/css/coins.css" scoped type="text/css"></style>
