import socket from './socket'

var datafeed = {
  config: {
    supports_search: true,
    supports_group_request: false,
    supported_resolutions: ['1', '5', '15', '30', '60', '1D', '1W', '1M', '12M'],
    supports_marks: false,
    supports_time: true,
    exchanges: {
      value: '',
      name: 'All Exchanges',
      desc: ''
    }
  },
  description: '',
  currency: {}, //当前币种
  history: [],
  meta: {
    noData: false
  },
  resolution: {
    '1': '1min',
    '5': '5min',
    '15': '15min',
    '30': '30min',
    '60': '60min',
    '1D': '1day',
    '1W': '1week',
    '1M': '1mon'
  },
  methods: [],
  on(method, fn) {
    this.methods[method] = fn
  },
  /**
   * 设置商品描述
   */
  init(currency) {
    if (currency) {
      this.currency = currency
      this.description = currency.name + '/' + currency.desc
    }
    this.meta.noData = false
    //console.log("[datafeed] inited",this.currency,this.description);
  },
  /**
   * 获取交易对
   * @returns symbol
   */
  getSymbol() {
    return this.currency.symbol
  },
  /**
   * 计算小数位数
   * @param {*} number
   * @returns
   */
  getPriceScale(number) {
    if (parseFloat(number) >= 1000) {
      return Math.pow(10, 2)
    } else if (parseFloat(number) > 1 && parseFloat(number) < 1000) {
      return Math.pow(10, 4)
    } else if (parseFloat(number) < 1) {
      return Math.pow(10, 6)
    }
    return Math.pow(10, 6)
  },

  //新能源接入时兼容
  GetNewFixed(number, num = 0) {
    number = parseFloat(number)
    if (num > 0) {
      return Math.pow(10, num)
    }
    return this.getPriceScale(number)
  },
  /**
   * 图表初始化完成后调用
   * @param cb
   */
  onReady(cb) {
    //console.log("[datafeed] onReady运行");
    setTimeout(() => cb(this.config), 0)
  },

  /**
   * 配置Symbol
   * @param symbolName 名称'btcusdt'
   * @param onSymbolResolvedCallback 配置回调
   * @param onResolveErrorCallback
   */
  resolveSymbol: (symbolName, onSymbolResolvedCallback) => {
    //计算小数位数
    var pricescale = datafeed.GetNewFixed(datafeed.currency.close, datafeed.currency.decimal)
    // console.log("[datafeed] 配置交易对",symbolName,datafeed.description,pricescale);

    var symbol_stub = {
      name: symbolName,
      description: datafeed.description,
      has_no_volume: false,
      minmov: 1,
      minmov2: 2,
      pricescale: pricescale,
      session: '24x7',
      has_intraday: true,
      has_daily: true,
      has_weekly_and_monthly: true,
      supported_resolutions: ['1', '5', '15', '30', '60', 'D', 'W', 'M', '12M'],
      ticker: symbolName,
      timezone: 'America/New_York', //"Asia/Shanghai",
      type: 'btccoin'
    }
    setTimeout(() => onSymbolResolvedCallback(symbol_stub), 0)
  },
  /**
   * 获取历史数据
   * @param symbolInfo
   * @param resolution
   * @param from
   * @param to
   * @param onHistoryCallback
   * @param onErrorCallback
   * @param firstDataRequest
   */
  getBars: (symbolInfo, resolution, from, to, onHistoryCallback) => {
    // console.log("[datafeed] 获取历史数据",symbolInfo.name,resolution,from,to);

    if (!datafeed.meta.noData) {
      console.log('获取历史', socket.getReadyState())
      if (socket.getReadyState() == 1) {
        socket.send({
          type: 'history',
          symbol: symbolInfo.name,
          resolution: resolution
        })
      }

      //首次加载历史数据
      socket.on('history', function(data) {
        let bars = []
        if (data) {
          for (var i in data) {
            var _bar = {
              time: data[i].id * 1000,
              close: parseFloat(data[i].close),
              open: parseFloat(data[i].open),
              high: parseFloat(data[i].high),
              low: parseFloat(data[i].low),
              volume: parseFloat(data[i].vol)
            }
            bars.push(_bar)
            //console.log(_bar,"_bar")
          }
          datafeed.meta = {
            noData: true
          }
        }

        //立即回调
        //console.log('[datafeed] 正在加载历史数据',_bar)
        onHistoryCallback(bars, datafeed.meta)
        // setTimeout(() => );
      })
    } else {
      //console.log('[datafeed] 历史数据已加载完成')
      onHistoryCallback([], datafeed.meta)
    }
  },
  /**
   * 订阅消息
   * @param symbolInfo
   * @param resolution
   * @param onRealtimeCallback
   * @param subscribeUID
   * @param onResetCacheNeededCallback
   */
  subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscribeUID) => {
    subscribeUID
    //调试
    console.log('[datafeed] 订阅信息', subscribeUID, { symbol: symbolInfo.name, resolution: resolution })
    if (datafeed.methods['trade']) {
      datafeed.methods['trade'](symbolInfo.name)
    }

    //订阅socket
    if (socket.getReadyState() == 1) {
      socket.send({ type: 'sub.kline', data: { symbol: symbolInfo.name, resolution: resolution } })
      socket.send({ type: 'sub.depth', data: { symbol: symbolInfo.name } })
      // socket.send({type:"sub.detail",data:{symbol: symbolInfo.name}})
      socket.send({ type: 'sub.detail' }) //订阅所有信息

      let uid = localStorage.getItem('uid')
      if (uid) {
        socket.send({ type: 'sub.user', data: { uid: uid } }) //订阅所有信息
      }
    }

    //处理kline
    socket.on('kline', function(data) {
      // console.log(data.period,datafeed.resolution[resolution]);
      // console.log(data.symbol,datafeed.currency.symbol);
      //if (data.symbol == datafeed.subscriber.kline.symbol && data.period == datafeed.resolution[datafeed.subscriber.kline.resolution]){
      //console.log(data,"更新数据");
      if (data.period == datafeed.resolution[resolution] && data.symbol == datafeed.currency.symbol) {
        var bar = {
          time: data.id * 1000,
          close: parseFloat(data.close),
          open: parseFloat(data.open),
          high: parseFloat(data.high),
          low: parseFloat(data.low),
          volume: parseFloat(data.vol)
        }
        setTimeout(() => onRealtimeCallback(bar))
      }
    })
  },
  unsubscribeBars(subscribeUID) {
    subscribeUID
    // console.log('[datafeed] 取消订阅',subscribeUID)
    // socket.send({
    //     "unsub": datafeed.subscriber['kline'][subscribeUID],
    //     "id": "id1"
    // })
    // socket.send({
    //     "unsub": datafeed.subscriber['depth'][subscribeUID],
    //     "id": "id1"
    // })
  },
  unixTimeToDateTime(unixtime) {
    var now = new Date(unixtime * 1000) // 依情况进行更改 * 1
    var y = now.getFullYear()
    var m = now.getMonth() + 1
    var d = now.getDate()
    return y + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10 ? '0' + d : d) + ' ' + now.toTimeString().substr(0, 8)
  },
  unixTimeToDate(unixtime) {
    var d = new Date(parseInt(unixtime) * 1000) // 依情况进行更改 * 1
    return d.getFullYear() + '-' + (d.getMonth() + 1 > 9 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)) + '-' + (d.getDate() > 9 ? d.getDate() : '0' + d.getDate())
  },
  DateToTimestamp(time) {
    //把时间日期转成时间戳
    return new Date(time).getTime() / 1000
  }
}

export default datafeed
