export default {
    title:"User Agreement",
    content:`<p style="text-align: center;">
    <span style="font-size: 18px;"><strong>User Agreement</strong></span>
</p>
<p>
    Starthestar
</p>
<p>
    <br/>
</p>
<p>
    Starthestar is a future-oriented digital currency and digital asset circulation and trading platform. It operates a website https://starthestar.com/ (hereinafter referred to as &quot;this website&quot; or &quot;website&quot;), which is a dedicated website for users to conduct digital asset transactions. and the platform that provides related services (hereinafter referred to as &quot;the service&quot; or &quot;service&quot;), unless otherwise stated by Starthestar, all rights to the products, technologies, software, programs, data and other information used and published on the website belong to Starthestar Limited (hereinafter referred to as the company). For the convenience of expression in this agreement, the company and this website collectively use &quot;we&quot; or other first-person pronouns in this agreement.
</p>
<p>
    <br/>
</p>
<p>
    important hint :
</p>
<p>
    We specifically remind you that:
</p>
<p>
    <br/>
</p>
<p>
    1 Digital assets themselves are not endorsed by any financial institution or government. The digital asset market is brand new and has no clear and stable expectations;
</p>
<p>
    <br/>
</p>
<p>
    2 There is a very high risk in digital asset trading. It is traded uninterrupted throughout the day, there is no limit on ups and downs, and the price is prone to large fluctuations;
</p>
<p>
    <br/>
</p>
<p>
    3 Due to the formulation or revision of national laws, regulations and normative documents, digital asset transactions may be suspended or prohibited at any time. .
</p>
<p>
    <br/>
</p>
<p>
    Digital asset trading has extremely high risks and is not suitable for most people. You understand and understand that this investment may result in partial or total loss, so you should decide the amount of investment based on the level of loss you can afford. Please confirm that you understand and understand that digital assets will generate derivative risks. In addition, in addition to the risks mentioned above, there will be unforeseen risks. Before making any decision to buy or sell digital assets, you should carefully consider and use clear judgment to assess your financial situation and the above risks, and bear all the resulting losses, for which we do not assume any responsibility. .
</p>
<p>
    <br/>
</p>
<p>
    1. General
</p>
<p>
    1.1 Before using the services provided by this website, you should read this agreement carefully, and if you have any incomprehension or other necessity, please consult a professional lawyer. If you do not agree to this agreement and/or its modifications at any time, please stop using the services provided by this website or log in to this website immediately. Once you log in to this website, use any services of this website or any other similar behavior, you have understood and fully agree to the contents of this agreement, including any modification made by this website to this agreement at any time. .
</p>
<p>
    <br/>
</p>
<p>
    1.2 You can become a member of this website (hereinafter referred to as &quot;member&quot;) by filling in relevant information in accordance with the requirements of this website and successfully registering after other relevant procedures. Clicking the &quot;Agree&quot; button during the registration process means that you sign electronically or when you click any button marked &quot;Agree&quot; or similar in the process of using this website, or when you actually use the services provided by this website in other ways permitted by this website, you are fully Understand, agree and accept all the terms under this agreement, and this agreement will not have any effect on your legal binding without your handwritten written signature.
</p>
<p>
    <br/>
</p>
<p>
    1.3 After you become a member of this website, you will obtain a member account and the corresponding password, and the member account and password shall be kept by the member; the member shall be legally responsible for all activities and events with your account. .
</p>
<p>
    <br/>
</p>
<p>
    1.2 You can become a member of this website (hereinafter referred to as &quot;member&quot;) by filling in relevant information in accordance with the requirements of this website and successfully registering after other relevant procedures. Clicking the &quot;Agree&quot; button during the registration process means that you sign electronically or when you click any button marked &quot;Agree&quot; or similar in the process of using this website, or when you actually use the services provided by this website in other ways permitted by this website, you are fully Understand, agree and accept all the terms under this agreement, and this agreement will not have any effect on your legal binding without your handwritten written signature. :
</p>
<p>
    <br/>
</p>
<p>
    1.4.1 Be bound by all terms and conditions of this Agreement. .
</p>
<p>
    <br/>
</p>
<p>
    1.4.2 You confirm that you have reached the legal age to enter into a contract under different applicable laws and are fully capable of accepting these terms. .
</p>
<p>
    <br/>
</p>
<p>
    1.4.3 You guarantee that the digital assets involved in the transaction belong to you are legally obtained and owned. .
</p>
<p>
    <br/>
</p>
<p>
    1.4.4 You agree that you are solely responsible for your own trading or non-trading actions and any gains or losses. .
</p>
<p>
    <br/>
</p>
<p>
    1.4.5 You confirm that the information provided during registration is true and accurate. .
</p>
<p>
    <br/>
</p>
<p>
    1.4.6 You agree to comply with any relevant legal requirements, including reporting any trading profits, for tax purposes. .
</p>
<p>
    <br/>
</p>
<p>
    1.4.7 This agreement only binds the rights and obligations between you and us, and does not involve legal relationships and legal disputes arising from digital asset transactions between users of this website and other websites and you. .
</p>
<p>
    <br/>
</p>
<p>
    2. Amendments to the Agreement
</p>
<p>
    We reserve the right to revise this agreement from time to time, and announce it by way of website announcement, without notifying you separately. The changed agreement will be marked with the change time on the home page of this agreement, and once it is published on the website, it will automatically take effect immediately. You should browse and pay attention to the update time and content of this agreement from time to time. If you do not agree with the relevant changes, you should immediately stop using the services of this website; if you continue to use the services of this website, it means that you accept and agree to be bound by the revised agreement . .
</p>
<p>
    <br/>
</p>
<p>
    3. Registration
</p>
<p>
    3.1 Eligibility for registration
</p>
<p>
    You confirm and undertake that: when you complete the registration process or actually use the services provided by this website in other ways permitted by this website, you should have the ability to sign this agreement and use the services of this website as required by applicable laws natural person, legal person or other organization. Once you click on the Agree to Register button, it means that you or your authorized agent has agreed to the content of this agreement and registered and used the services of this website by its agent. If you do not have the aforementioned subject qualifications, you and your authorized agent shall bear all the consequences arising therefrom, and the company reserves the right to cancel or permanently freeze your account and hold you and your authorized agent accountable . .
</p>
<p>
    <br/>
</p>
<p>
    3.2 Purpose of registration
</p>
<p>
    You confirm and promise that your registration on this website is not for the purpose of violating laws and regulations or disrupting the order of digital asset transactions on this website. .
</p>
<p>
    <br/>
</p>
<p>
    3.3 Registration Process
</p>
<p>
    3.3.1 You agree to provide valid e-mail, mobile phone number and other information according to the requirements of the user registration page of this website. You can use the email address, mobile phone number you provide or confirm or other methods allowed by this website as a means of login to enter this website. If necessary, in accordance with the relevant laws and regulations of different jurisdictions, you must provide your real name, identity documents and other relevant information stipulated in laws and regulations, privacy clauses and anti-money laundering clauses, and constantly update the registration information, in line with timely, detailed and accurate information. Require. All originally typed data will be cited as registered data. You shall be responsible for the truthfulness, completeness and accuracy of such information and shall bear any direct or indirect losses and adverse consequences arising therefrom. .
</p>
<p>
    <br/>
</p>
<p>
    3.3.2 If the laws, regulations, rules, orders and other norms of your sovereign country or region require real-name mobile phone numbers, you agree that the registered mobile phone number is registered with real-name registration. Any direct or indirect losses and adverse consequences should be borne by you. .
</p>
<p>
    <br/>
</p>
<p>
    3.3.3 You have the right to obtain the account number and password of this website if you legally, completely and effectively provide the information required for registration and have been verified. When you obtain the account number and password of this website, the registration is deemed successful, and you can log in as a member on this website. .
</p>
<p>
    <br/>
</p>
<p>
    3.3.4 You agree to receive emails and/or short messages related to the management and operation of this website from this website. .
</p>
<p>
    <br/>
</p>
<p>
    4. Services
</p>
<p>
    This website only provides online trading platform services for your digital asset trading activities (including but not limited to digital asset trading and other services) through this website. This website does not participate in the act of buying and selling digital assets as a buyer or seller. .
</p>
<p>
    <br/>
</p>
<p>
    4.1 Service Content
</p>
<p>
    4.1.1 You have the right to browse the real-time market and transaction information of various digital asset products on this website, and have the right to submit digital asset transaction instructions and complete digital asset transactions through this website. .
</p>
<p>
    <br/>
</p>
<p>
    4.1.2 You have the right to participate in website activities organized by this website in accordance with the activity rules published on this website. .
</p>
<p>
    <br/>
</p>
<p>
    4.1.3 Other services that this website promises to provide you. .
</p>
<p>
    <br/>
</p>
<p>
    4.2. Service Rules
</p>
<p>
    You undertake to abide by the following service rules of this website:
</p>
<p>
    <br/>
</p>
<p>
    4.2.1 You should abide by the requirements of laws, regulations, rules, and policies to ensure the legality of all sources of digital assets in your account, and shall not engage in illegal or other activities that damage the rights and interests of this website or third parties on this website or using its services , such as sending or receiving any information that violates laws, regulations, or infringing on the rights and interests of others, sending or receiving pyramid schemes or other harmful information or remarks, using or falsifying the email header information of this website without the authorization of this website.
</p>
<p>
    <br/>
</p>
<p>
    4.2.2 You should abide by laws and regulations and properly use and keep your website account and login password, fund password, and the mobile phone number bound to it during registration, as well as the security of the mobile phone verification code received by the mobile phone. You are fully responsible for any operations and consequences of using your website account and login password, fund password, and mobile phone verification code. When you find that the account number, login password, or fund password or verification code of this website is used by an unauthorized third party, or there are other account security problems, you should immediately and effectively notify this website and request this website to suspend the service of this website account . This website has the right to take action on your request within a reasonable time, but this website does not assume any responsibility for the consequences that have occurred before taking action (including but not limited to any loss to you). You may not give, borrow, lease, transfer or otherwise dispose of your account on this website to others without the consent of this website. .
</p>
<p>
    <br/>
</p>
<p>
    4.2.3 You agree that you are responsible for all activities (including but not limited to information disclosure, publishing information, online clicking to agree to or submitting various rules and agreements, online renewal of agreements or purchasing services, etc.) responsibility. .
</p>
<p>
    <br/>
</p>
<p>
    4.2.4 When you conduct digital asset transactions on this website, you shall not maliciously interfere with the normal conduct of digital asset transactions or disrupt the transaction order; you shall not interfere with the normal operation of this website or interfere with the use of services of this website by other users by any technical means or other means; Do not maliciously slander the goodwill of this website by fictitious facts. .
</p>
<p>
    <br/>
</p>
<p>
    4.2.5 If you have disputes with other users due to online transactions, you shall not request this website to provide relevant information through judicial or administrative channels. .
</p>
<p>
    <br/>
</p>
<p>
    4.2.6 You shall be solely responsible for all applicable taxes, as well as all hardware, software, service and other expenses incurred during your use of the services provided by this website. .
</p>
<p>
    <br/>
</p>
<p>
    4.2.7 You should abide by this agreement and other terms of service and operating rules published and updated by this website from time to time, and have the right to terminate the use of the services provided by this website at any time. .
</p>
<p>
    <br/>
</p>
<p>
    4.3. Product Rules
</p>
<p>
    4.3.1 Token trading product rules
</p>
<p>
    <br/>
</p>
<p>
    You promise to abide by the following trading rules when you enter this website for transactions and conduct currency transactions with other users through this website.
</p>
<p>
    <br/>
</p>
<p>
    4.3.1.1 Browse transaction information
</p>
<p>
    When you browse the currency transaction information on this website, you should carefully read all the contents contained in the transaction information, including but not limited to price, commission volume, handling fee, buying or selling direction, and you fully accept all the contents contained in the transaction information After the content, you can click the button to trade. .
</p>
<p>
    <br/>
</p>
<p>
    4.3.1.2 Submitting an order
</p>
<p>
    After viewing the transaction information and confirming that it is correct, you can submit the transaction order. After you submit the transaction entrustment, you authorize this website to conduct the corresponding transaction matching on your behalf. This website will automatically complete the matching transaction when there is a transaction that meets your entrusted price without notifying you in advance. .
</p>
<p>
    <br/>
</p>
<p>
    4.3.1.3 View transaction details
</p>
<p>
    You can check the corresponding transaction records in the transaction details of the management center to confirm your detailed transaction records.
</p>
<p>
    <br/>
</p>
<p>
    4.3.1.4 To cancel/modify the order, you have the right to revoke or modify the order at any time before the order has reached a transaction.
</p>
<p>
    <br/>
</p>
<p>
    V. The rights and obligations of this website
</p>
<p>
    5.1 If you do not have the registration qualifications stipulated in this agreement, this website has the right to refuse your registration. For those who have already registered, this website has the right to cancel your membership account. This website reserves the right to hold you or your authorized agent accountable. the right to responsibility. At the same time, this website reserves the right to decide whether to accept your registration under any other circumstances. .
</p>
<p>
    <br/>
</p>
<p>
    5.2 This website has the right to suspend or terminate the use of the account when it finds that the account user is not the initial registrant of the account. .
</p>
<p>
    <br/>
</p>
<p>
    5.3 When this website reasonably suspects that the information you provide is wrong, inaccurate, invalid or incomplete through technical testing, manual sampling and other testing methods, it has the right to notify you to correct or update the information or to suspend or terminate the provision of services on this website. .
</p>
<p>
    <br/>
</p>
<p>
    5.4 This website reserves the right to correct any information displayed on this website when it finds any obvious error. .
</p>
<p>
    <br/>
</p>
<p>
    5.5 This website has the right to verify the executor of the erroneous transaction or/and the receiver (the beneficiary party) when any erroneous transaction is found. If it is confirmed as an erroneous transaction, the receiver of the erroneous transaction (the beneficiary party) shall cooperate. This website returns the transaction content it has received; if the recipient (the beneficiary party) does not cooperate after receiving the notice from this website or staff, this website has the right to take relevant measures to recover, including but not limited to This agreement stipulates to initiate arbitration or take judicial compulsory measures (preservation and seizure, etc.); if the recipient (the beneficiary party) not only refuses to return, but also maliciously transfers or sells the wrong transaction content after receiving the notice from this website or the staff, In addition to taking the aforementioned measures, this website also has the right to require the recipient (the beneficiary party) to return the currency and quantity originally determined according to the content of the wrong transaction, and to require the recipient (the beneficiary party) to transfer and sell the value-added part that has been transferred and sold. It shall be returned together with other fruits of unjust enrichment.
</p>
<p>
    <br/>
</p>
<p>
    5.6 This website reserves the right to modify, suspend or terminate the services of this website at any time, and this website does not need to inform you in advance to exercise the right to modify or suspend services; Effective on the date of publication of the termination notice. .
</p>
<p>
    <br/>
</p>
<p>
    5.7 This website shall take necessary technical means and management measures to ensure the normal operation of this website, and provide necessary and reliable trading environment and trading services to maintain the order of digital asset trading. .
</p>
<p>
    <br/>
</p>
<p>
    5.8 This website protects the security of your digital assets by strengthening technical investment, improving security precautions and other measures, and is obliged to notify you in advance when there are foreseeable security risks in your account. .
</p>
<p>
    <br/>
</p>
<p>
    5.9 This website has the right to request you to provide more information or materials according to the requirements of the laws, regulations, rules, orders and other norms of your sovereign country or region, and take reasonable measures to meet the requirements of local norms, You are obliged to cooperate; this website has the right to suspend or permanently stop the opening of this website and some or all of its services to you in accordance with the requirements of the laws, regulations, rules, orders and other norms of your sovereign country or region. .
</p>
<p>
    <br/>
</p>
<p>
    6. Compensation
</p>
<p>
    6.1 IN NO EVENT SHALL OUR LIABILITY FOR YOUR DIRECT DAMAGES EXCEED THE TOTAL COST OF YOUR USE OF THE WEBSITE SERVICES FOR A THREE (3) MONTHS.
</p>
<p>
    <br/>
</p>
<p>
    6.2 If you violate this agreement or other laws and regulations, etc. you must compensate us at least 2 million US dollars and bear all the costs (including attorney fees, etc.) arising therefrom. If it is not enough to cover the actual loss, you must complete it. .
</p>
<p>
    <br/>
</p>
<p>
    7. The right to seek injunctive relief
</p>
<p>
    We and you both acknowledge that common law remedies for breach of contract or possible breach may not be sufficient to cover all losses suffered by us, so the non-defaulting party has the right to seek injunctive relief and common law or equitable relief in the event of breach or potential breach all other remedies permitted by law. .
</p>
<p>
    <br/>
</p>
<p>
    8. Limitation of Liability and Disclaimer
</p>
<p>
    8.1 You understand and agree that under no circumstances shall we be liable for:
</p>
<p>
    <br/>
</p>
<p>
    8.1.1 loss of income;
</p>
<p>
    <br/>
</p>
<p>
    8.1.2 Trading profits or contract losses;
</p>
<p>
    <br/>
</p>
<p>
    8.1.3 Service interruption
</p>
<p>
    <br/>
</p>
<p>
    8.1.4 loss of expected monetary savings;
</p>
<p>
    <br/>
</p>
<p>
    8.1.5 loss of information;
</p>
<p>
    <br/>
</p>
<p>
    8.1.6 loss of opportunity, goodwill or reputation;
</p>
<p>
    <br/>
</p>
<p>
    8.1.7 Corruption or loss of data;
</p>
<p>
    <br/>
</p>
<p>
    8.1.8 the cost of purchasing alternative products or services;
</p>
<p>
    <br/>
</p>
<p>
    8.1.9 Any indirect, special or incidental loss or damage arising from tort (including negligence), breach of contract or any other cause, whether or not such loss or damage could be reasonably foreseen by us; whether or not we have been notified in advance The possibility of such loss or damage exists.
</p>
<p>
    <br/>
</p>
<p>
    Clauses 8.1.1 to 8.1.9 are independent of each other.
</p>
<p>
    <br/>
</p>
<p>
    8.2 You understand and agree that we shall not be liable for any damages to you arising from any of the following circumstances:
</p>
<p>
    <br/>
</p>
<p>
    8.2.1 We have reasonable grounds to believe that your specific transaction may have a material violation of law or breach of contract. .
</p>
<p>
    <br/>
</p>
<p>
    8.2.2 We have reasonable grounds to believe that your conduct on this website is suspected of being illegal or inappropriate. .
</p>
<p>
    <br/>
</p>
<p>
    8.2.3 Expenses and losses arising from the purchase or acquisition of any data, information or transactions through the services of this website or any substitute behavior. .
</p>
<p>
    <br/>
</p>
<p>
    8.2.4 Your misunderstanding of the services of this website.
</p>
<p>
    <br/>
</p>
<p>
    8.2.5 Any other loss related to the services provided by this website which is not caused by us. .
</p>
<p>
    <br/>
</p>
<p>
    8.3 We are responsible for the maintenance of information network equipment, information network connection failure, computer, communication or other system failure, power failure, weather, accidents, strikes, labor disputes, riots, uprisings, riots, lack of productivity or production materials, fires , floods, storms, explosions, wars, bank or other partner reasons, the collapse of the digital asset market, government actions, judicial or administrative orders, other acts beyond our control or our ability to control, or third parties We do not assume any responsibility for the inability to serve or delay the service, as well as for your losses. .
</p>
<p>
    <br/>
</p>
<p>
    8.4 We cannot guarantee that all the information, programs, texts, etc. contained in this website are completely safe, and will not be interfered and destroyed by any malicious programs such as viruses, Trojan horses, etc., so you log in, use any services of this website, or download and use any programs downloaded from the website. , information, data, etc. are all your personal decisions and bear your own risks and possible losses. .
</p>
<p>
    <br/>
</p>
<p>
    8.5 We do not make any guarantees and commitments for any information, products and business of any third-party website linked in this website, or any other content that does not belong to our subject. If you use any service, information provided by a third-party website and products, etc. are your personal decisions and bear all the responsibilities arising therefrom. .
</p>
<p>
    <br/>
</p>
<p>
    8.6 We do not make any express or implied warranties for your use of the services of this website, including but not limited to the applicability of the services provided by this website, the absence of errors or omissions, continuity, accuracy, reliability, and suitability for a particular purpose. At the same time, we do not make any promises and guarantees for the validity, accuracy, correctness, reliability, quality, stability, completeness and timeliness of the technology and information involved in the services provided by this website. Whether to log in or use the services provided by this website is your personal decision and is at your own risk and possible losses. We do not make any express or implied guarantee for the market, value and price of digital assets. You understand and understand that the digital asset market is unstable, and prices and values may fluctuate or collapse at any time. Trading digital assets is your personal freedom Choose and decide at your own risk and possible losses. .
</p>
<p>
    <br/>
</p>
<p>
    8.7 Our warranties and undertakings set out in this Agreement are our sole warranties and representations with respect to this Agreement and the services provided by this website, and supersede any warranties and undertakings arising from any other means and means, whether written or oral, express or implied. All such warranties and representations represent only our own promises and warranties and do not guarantee that any third party will comply with the warranties and promises contained in this Agreement. .
</p>
<p>
    <br/>
</p>
<p>
    8.8 We do not waive any rights not mentioned in this Agreement to limit, exclude or set off our liability for damages to the fullest extent applicable by law. .
</p>
<p>
    <br/>
</p>
<p>
    8.9 By registering, you are agreeing to any operation we perform in accordance with the rules set forth in this Agreement, and any risk arising therefrom shall be borne by you. .
</p>
<p>
    <br/>
</p>
<p>
    9. Termination of the Agreement
</p>
<p>
    9.1 This website has the right to cancel your account on this website in accordance with this agreement, and this agreement will be terminated on the date of account cancellation.
</p>
<p>
    <br/>
</p>
<p>
    9.2 This website has the right to terminate all services of this website in accordance with this agreement. This agreement shall be terminated on the day when all services of this website are terminated. .
</p>
<p>
    <br/>
</p>
<p>
    9.3 After the termination of this agreement, you have no right to require this website to continue to provide it with any services or perform any other obligations, including but not limited to requiring this website to retain or disclose any information in its original website account to you or to you. Third parties forward any information, etc. that they have not read or sent. .
</p>
<p>
    <br/>
</p>
<p>
    9.4 The termination of this agreement does not affect the non-compliance party&#39;s request for other responsibilities from the breaching party.
</p>
<p>
    <br/>
</p>
<p>
    10. Intellectual Property Rights
</p>
<p>
    10.1 All intellectual achievements contained in this website include but are not limited to website logos, databases, website designs, text and graphics, software, photos, videos, music, sounds and combinations thereof, software compilation, related source code and software (including small applications and scripts) are owned by this website. You may not reproduce, alter, copy, transmit or use any of the foregoing materials or content for commercial purposes. .
</p>
<p>
    <br/>
</p>
<p>
    10.2 All rights (including but not limited to goodwill and trademarks, logos) contained in the name of this website are owned by the company.
</p>
<p>
    <br/>
</p>
<p>
    10.3 Your acceptance of this agreement shall be deemed that you voluntarily claim the copyright of any form of information published on this website, including but not limited to: reproduction rights, distribution rights, rental rights, exhibition rights, performance rights, exhibition rights, broadcasting rights, The information network dissemination rights, filming rights, adaptation rights, translation rights, compilation rights and other transferable rights that should be enjoyed by the copyright owner are exclusively transferred to this website for free. . The validity of this agreement applies to any content of works protected by copyright law that you publish on this website, whether the content is formed before or after the signing of this agreement. .
</p>
<p>
    <br/>
</p>
<p>
    10.4 You shall not illegally use or dispose of the intellectual property rights of this website or others in the process of using the services of this website. You may not publish or authorize other websites (and media) to use the information published on this website in any form. .
</p>
<p>
    <br/>
</p>
<p>
    10.5 Your login to this website or use of any services provided by this website shall not be deemed as any transfer of intellectual property rights by us to you.
</p>
<p>
    <br/>
</p>
<p>
    Eleven other
</p>
<p>
    11.1 Severability
</p>
<p>
    If any provision of this Agreement is found by any court of competent jurisdiction to be unenforceable, invalid or illegal, it shall not affect the validity of the remaining provisions of this Agreement.
</p>
<p>
    <br/>
</p>
<p>
    11.2 Non-Agency Relationships
</p>
<p>
    Nothing in this Agreement shall be deemed to create, imply or otherwise treat us as your agent, fiduciary or other representative, except as otherwise provided in this Agreement
</p>
<p>
    <br/>
</p>
<p>
    11.3 Waiver
</p>
<p>
    The waiver by us or either of you of any liability for breach of contract or other liability stipulated in this Agreement shall not be deemed or construed as a waiver of any other liability for breach of contract; the failure to exercise any right or remedy shall not in any way be construed as a waiver of such right or remedy give up
</p>
<p>
    <br/>
</p>
<p>
    11.4 Title
</p>
<p>
    All headings are provided for convenience only and are not intended to expand or limit the content or scope of the terms of this agreement.
</p>
<p>
    <br/>
</p>
<p>
    11.5 Resolution of Disputes
</p>
<p>
    Any disputes arising from the performance of the agreement and in the use process should be negotiated amicably by both parties.
</p>
<p>
    <br/>
</p>
<p>
    11. Effectiveness and interpretation of the agreement
</p>
<p>
    12.1 This agreement takes effect when you click on the registration page of this website to agree to register, complete the registration process, obtain the account and password of this website, and are binding on this website and you.
</p>
<p>
    <br/>
</p>
<p>
    12.2 This Agreement shall take effect upon successful registration.
</p>
<p>
    <br/>
</p>
<p>
    12.3 The final interpretation right of this agreement belongs to this website.
</p>
<p>
    <br/>
</p>`,
}