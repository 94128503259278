<template>
  <div id="dark">
    <div class="pub-layout-content-right is-pulled-right py-0 pub-layout-content-kline-right">
      <!-- 右边上面 -->
      <div class="pub-layout-content-right-place-order py-1 pl-1" style="position: relative;">
        <!-- 遮罩 -->
        <div class="ze-bg" v-if="token == ''">
          <div class="ze-btn">
            <button class="ze-button" @click="login">{{ $t('login.login') }}</button>
            <button class="ze-button" @click="signup">{{ $t('signup.signup') }}</button>
          </div>
        </div>
        <!-- 上面操作 -->
        <div class="pub-place-order py-0 px-0 box">
          <!-- 表头 -->
          <div class="pub-place-order-tabs tabs is-small limit-order">
            <div class="is-flex px-3 is-background-3 pt-1">
              <ul>
                <li class="  is-active">
                  <a href="javascript:void(0);" class="a-button-pad px-0 mr-5 ">{{ $t('lever.title') }}</a>
                </li>
              </ul>
              <!-- 可用 -->
              <!-- <div class="header-wit-balance">
                            <div class="pub-place-order-form-wallet is-flex field has-text-2 spottick-text-color-4">
                                <div class="order-get">可用</div>
                                <div class="spacer"></div>
                                <div class="order-get">{{walletList[Defkey] && walletList[Defkey].balance}} {{currency.desc}}</div>
                                <div class="order-get"></div>
                            </div>
                        </div> -->
            </div>
          </div>

          <!-- 买和卖切换 -->
          <div class="pub-place-order-form-lever-btns field buy-sell">
            <button :class="'button is-fullwidth button-radius text-font-flex ord-buy-sell is-text-3 ' + (showView == TradeType.Buy ? 'is-success' : 'bs-btn')" @click="changeView(TradeType.Buy)">
              {{ $t('coins.buy') }}
            </button>
            <button :class="'button is-fullwidth button-radius text-font-flex ord-buy-sell is-text-3 ' + (showView == TradeType.Sell ? 'is-danger' : 'bs-btn')" @click="changeView(TradeType.Sell)">
              {{ $t('coins.sell') }}
            </button>
          </div>

          <!-- 交易界面 买入-->
          <div class="pub-place-order-form has-text-2 px-3 pb-3" v-if="showView == TradeType.Buy">
            <!-- 价格类型 -->
            <div class="pub-place-order-form-lever-btns field button-price">
              <a :class="'button is-small has-border ' + (buyPriceType == PriceType.Market ? 'active' : '')" @click="btnBuyPrice(PriceType.Market)">{{ $t('coins.market') }}</a>
              <a :class="'button is-small has-border ' + (buyPriceType == PriceType.Limit ? 'active' : '')" @click="btnBuyPrice(PriceType.Limit)">{{ $t('coins.limit') }}</a>
            </div>

            <!-- 点差价格 -->
            <div class="pub-place-order-form-prz-input field">
              <div class="control has-flex">
                <div class="control is-background-3">
                  <button class="button input2 is-static2 is-background-3 has-text-1 is-left-radius text-font-flex text-font-flex-long has-text-double button-input-height">
                    {{ $t('coins.current') }}
                  </button>
                </div>
                <input type="text" :readonly="true" v-model="currentBuyPriceStr" :placeholder="$t('coins.price_desc')" step="0.1" pattern="d*" class="input is-right-radius input2 text-font-flex button-input-height" />
                <div class="control is-background-3 cursor-pointer">
                  <button class="button input2 is-static2 is-background-3 has-text-primary is-left-radius text-font-flex text-font-flex-long button-input-height">
                    <!-- 市价 -->
                  </button>
                </div>
              </div>
            </div>

            <!-- 价格 -->
            <div class="pub-place-order-form-prz-input field" v-if="buyPriceType == PriceType.Limit">
              <div class="control has-flex">
                <div class="control is-background-3">
                  <button class="button input2 is-static2 is-background-3 has-text-1 is-left-radius text-font-flex text-font-flex-long has-text-double button-input-height">
                    {{ $t('coins.price') }}
                  </button>
                </div>
                <input type="number" @input="autoBuyNumber" v-model="currentBuyPrice" :placeholder="$t('coins.price_desc')" step="0.1" pattern="d*" class="input is-right-radius input2 text-font-flex button-input-height" />
                <div class="control is-background-3 cursor-pointer">
                  <button class="button input2 is-static2 is-background-3 has-text-primary is-left-radius text-font-flex text-font-flex-long button-input-height">
                    <!-- 市价 -->
                  </button>
                </div>
              </div>
            </div>
            <!-- 选比例 -->
            <div class="pub-place-order-form-prz-input field">
              <div class="control has-flex">
                <div class="control is-background-3">
                  <button class="button input2 is-static2 is-background-3 has-text-1 is-left-radius text-font-flex text-font-flex-long has-text-double button-input-height">
                    {{ $t('lever.multiple') }}
                  </button>
                </div>
                <div class="l-select">
                  <div class="l-lable">
                    <input @click="openBuySelect" readonly type="number" v-model="buyRate" :placeholder="$t('lever.multiple_desc')" class="input is-right-radius input2 text-font-flex button-input-height" />
                    <i class="el-icon-arrow-up" v-show="buySelect"></i>
                    <i class="el-icon-arrow-down" v-show="!buySelect"></i>
                  </div>
                  <div class="ul-select" v-show="buySelect">
                    <ul>
                      <!-- <li @click="onBuyRate(10)">10{{$t('lever.double')}}</li> -->
                      <!-- <li @click="onBuyRate(50)">50{{$t('lever.double')}}</li> -->
                      <li @click="onBuyRate(100)">100{{ $t('lever.double') }}</li>
                      <li @click="onBuyRate(200)">200{{ $t('lever.double') }}</li>
                      <li @click="onBuyRate(300)">300{{ $t('lever.double') }}</li>
                    </ul>
                  </div>
                </div>
                <div class="control is-background-3 cursor-pointer">
                  <button class="button input2 is-static2 is-background-3 has-text-primary is-left-radius text-font-flex text-font-flex-long button-input-height">
                    {{ $t('lever.double') }}
                  </button>
                </div>
              </div>
            </div>
            <!-- 数量 -->
            <div class="pub-place-order-form-num-input field" style="margin-bottom: 0;">
              <div class="control">
                <div class="control has-flex">
                  <div class="control is-background-3">
                    <button class="button input2 is-static2 is-background-3 has-text-1 is-left-radius text-font-flex text-font-flex-long has-text-double button-input-height">
                      {{ $t('coins.number') }}
                    </button>
                  </div>
                  <input type="number" @input="inputBuyNumber" v-model="buy_number" :placeholder="$t('coins.number_desc')" step="0.00000001" class="input is-right-radius input2 text-font-flex button-input-height" />
                  <div class="control is-background-3">
                    <button class="button input2 is-static2 is-background-3  has-text-1 is-left-radius text-font-flex text-font-flex-long button-input-height">
                      {{ currency.name }}
                    </button>
                  </div>
                </div>
                <!-- <span class="pub-place-order-form-num-input-face-value">≈ 0.00 {{currency.desc}} (手续费:0.00 {{currency.desc}})</span> -->
              </div>
            </div>
            <!-- USDT数量 -->
            <div class="pub-place-order-form-num-input field" style="margin-bottom: 0;">
              <div class="control">
                <div class="control has-flex">
                  <div class="control is-background-3">
                    <button class="button input2 is-static2 is-background-3 has-text-1 is-left-radius text-font-flex text-font-flex-long has-text-double button-input-height">
                      {{ $t('wallet.bond') }}
                    </button>
                  </div>
                  <input type="number" @input="inputBuyNumberUsdt" v-model="buy_number_usdt" :placeholder="$t('coins.number_desc')" step="0.00000001" class="input is-right-radius input2 text-font-flex button-input-height" />
                  <div class="control is-background-3">
                    <button class="button input2 is-static2 is-background-3  has-text-1 is-left-radius text-font-flex text-font-flex-long button-input-height">
                      {{ currency.desc }}
                    </button>
                  </div>
                </div>
                <!-- <span class="pub-place-order-form-num-input-face-value">≈ 0.00 {{currency.desc}} (手续费:0.00 {{currency.desc}})</span> -->
              </div>
            </div>
            <!-- 持仓 -->
            <div class="header-wit-balance">
              <div class="pub-place-order-form-wallet is-flex field has-text-2 spottick-text-color-4">
                <div class="order-get">{{ $t('coins.available') }}</div>
                <div class="spacer"></div>
                <div class="order-get">{{ usdtWallet && usdtWallet.balance }} {{ usdtWallet.name }}</div>
                <div class="order-get"></div>
              </div>
            </div>

            <!-- 快捷操作 -->
            <div class="pub-place-order-form-lever-btns">
              <a class="button is-small has-border" @click="btnBuySet(0.5)">{{ $t('coins.half') }}</a>
              <a class="button is-small has-border" @click="btnBuySet(1)">{{ $t('coins.all') }}</a>
            </div>

            <!-- 手续费 -->
            <div class="header-wit-balance">
              <div class="pub-place-order-form-wallet is-flex field has-text-2 spottick-text-color-4">
                <div class="order-get">{{ $t('coins.fee') }}</div>
                <div class="spacer"></div>
                <div class="order-get">≈ {{ buy_fee_usdt }} {{ currency.desc }}</div>
                <div class="order-get"></div>
              </div>
            </div>
            <!-- 交易额 -->
            <div class="header-wit-balance field">
              <div class="pub-place-order-form-wallet is-flex field has-text-2 spottick-text-color-4">
                <div class="order-get">{{ $t('coins.amount') }}</div>
                <div class="spacer"></div>
                <div class="order-get">≈ {{ buy_usdt }} {{ currency.desc }}</div>
                <div class="order-get"></div>
              </div>
            </div>
            <div class="spacer"></div>
            <div class="pub-place-order-form-buttons field">
              <div class="level-item">
                <div class="button-width">
                  <div class="button-default-width">
                    <button class="button is-success is-fullwidth button-radius text-font-flex ord-buy-sell is-text-3" @click="openWind(TradeType.Buy, Direction.Buy)">
                      {{ $t('lever.buy') }}
                    </button>
                  </div>
                </div>
                <div class="button-width">
                  <div class="button-default-width">
                    <button class="button is-danger is-fullwidth button-radius text-font-flex ord-buy-sell is-text-3" @click="openWind(TradeType.Buy, Direction.Sell)">
                      {{ $t('lever.sell') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
          </div>

          <!-- 卖出 -->
          <div class="pub-place-order-form has-text-2 px-3 pb-3" v-if="showView == TradeType.Sell">
            <!-- 价格类型 -->
            <div class="pub-place-order-form-lever-btns field button-price">
              <a :class="'button is-small has-border ' + (sellPriceType == PriceType.Market ? 'active' : '')" @click="btnSellPrice(PriceType.Market)">{{ $t('coins.market') }}</a>
              <a :class="'button is-small has-border ' + (sellPriceType == PriceType.Limit ? 'active' : '')" @click="btnSellPrice(PriceType.Limit)">{{ $t('coins.limit') }}</a>
            </div>
            <!-- 止盈止损 -->
            <div class="pub-place-order-form-lever-btns field button-price">
              <a :class="'button is-small has-border ' + (sellPriceType == PriceType.Profit ? 'active' : '')" @click="btnSellPrice(PriceType.Profit)">{{ $t('coins.profit') }}</a>
              <a :class="'button is-small has-border ' + (sellPriceType == PriceType.Loss ? 'active' : '')" @click="btnSellPrice(PriceType.Loss)">{{ $t('coins.loss') }}</a>
            </div>
            <!-- 价格 -->
            <div class="pub-place-order-form-prz-input field">
              <div class="control has-flex">
                <div class="control is-background-3">
                  <button class="button input2 is-static2 is-background-3 has-text-1 is-left-radius text-font-flex text-font-flex-long has-text-double button-input-height">
                    {{ $t('coins.price') }}
                  </button>
                </div>
                <input type="number" @input="autoSellNumber" :readonly="sellPriceType == PriceType.Market" v-model="currentSellPrice" :placeholder="$t('coins.price_desc')" step="0.00000001" class="input is-right-radius input2 text-font-flex button-input-height" />
                <div class="control is-background-3 cursor-pointer">
                  <button class="button input2 is-static2 is-background-3 has-text-primary is-left-radius text-font-flex text-font-flex-long button-input-height"></button>
                </div>
              </div>
            </div>
            <!-- 数量 -->
            <div class="pub-place-order-form-num-input field" style="margin-bottom: 0;">
              <div class="control">
                <div class="control has-flex">
                  <div class="control is-background-3">
                    <button class="button input2 is-static2 is-background-3 has-text-1 is-left-radius text-font-flex text-font-flex-long has-text-double button-input-height">
                      {{ $t('coins.number') }}
                    </button>
                  </div>
                  <input type="number" @input="inputSellNumber" v-model="sell_number" :placeholder="$t('coins.number_desc')" step="1" pattern="d*" class="input is-right-radius input2 text-font-flex button-input-height" />
                  <div class="control is-background-3">
                    <button class="button input2 is-static2 is-background-3  has-text-1 is-left-radius text-font-flex text-font-flex-long button-input-height">
                      {{ currency.name }}
                    </button>
                  </div>
                </div>
                <!-- <span class="pub-place-order-form-num-input-face-value">≈ 0.00 {{currency.desc}} (手续费:0.00 {{currency.desc}})</span> -->
              </div>
            </div>
            <!-- USDT数量 -->
            <div class="pub-place-order-form-num-input field" style="margin-bottom: 0;">
              <div class="control">
                <div class="control has-flex">
                  <div class="control is-background-3">
                    <button class="button input2 is-static2 is-background-3 has-text-1 is-left-radius text-font-flex text-font-flex-long has-text-double button-input-height">
                      {{ $t('wallet.bond') }}
                    </button>
                  </div>
                  <input type="number" @input="inputSellNumberUsdt" v-model="sell_number_usdt" :placeholder="$t('coins.number_desc')" step="0.00000001" class="input is-right-radius input2 text-font-flex button-input-height" />
                  <div class="control is-background-3">
                    <button class="button input2 is-static2 is-background-3  has-text-1 is-left-radius text-font-flex text-font-flex-long button-input-height">
                      {{ currency.desc }}
                    </button>
                  </div>
                </div>
                <!-- <span class="pub-place-order-form-num-input-face-value">≈ 0.00 {{currency.desc}} (手续费:0.00 {{currency.desc}})</span> -->
              </div>
            </div>
            <!-- 持仓 -->
            <div class="header-wit-balance">
              <div class="pub-place-order-form-wallet is-flex field has-text-2 spottick-text-color-4">
                <div class="order-get">{{ $t('coins.available') }}</div>
                <div class="spacer"></div>
                <div class="order-get">{{ currentWallet && currentWallet.balance }} {{ currency.name }}</div>
                <div class="order-get"></div>
              </div>
            </div>

            <!-- 快捷操作 -->
            <div class="pub-place-order-form-lever-btns">
              <a class="button is-small has-border" @click="btnSellSet(0.5)">{{ $t('coins.half') }}</a>
              <a class="button is-small has-border" @click="btnSellSet(1)">{{ $t('coins.all') }}</a>
            </div>

            <!-- 交易额 -->
            <div class="header-wit-balance field">
              <div class="pub-place-order-form-wallet is-flex field has-text-2 spottick-text-color-4">
                <div class="order-get">{{ $t('coins.amount') }}</div>
                <div class="spacer"></div>
                <div class="order-get">≈ {{ sell_usdt }} {{ currency.desc }}</div>
                <div class="order-get"></div>
              </div>
            </div>
            <!-- 手续费 -->
            <!-- <div class="header-wit-balance field">
                        <div class="pub-place-order-form-wallet is-flex field has-text-2 spottick-text-color-4">
                            <div class="order-get">手续费</div>
                            <div class="spacer"></div>
                            <div class="order-get">≈ {{sell_fee_usdt}} {{currency.desc}}</div>
                            <div class="order-get"></div>
                        </div>
                    </div> -->
            <!-- 止盈止亏 -->
            <!-- <div class="pub-place-order-form-stop-pl field">
                                        <label class=" my-tooltip dropdown is-hoverable tooltip-icon dropdown-trigger bdb-dashed pub-place-order-form-stop-pl-label label has-text-1" style="margin-top: 10px; margin-bottom: 15px;">
                                            <div class="my-tooltip dropdown is-hoverable tooltip-icon">
                                                <div class="dropdown-trigger bdb-dashed ">止盈止损（选填）</div>
                                                <div class="dropdown-menu has-text-white arrows  my-tooltip-position-bottom  my-tooltip-direction-center  content-width">
                                                    <div class="dropdown-content" style="/*width: 10rem;*/">
                                                        <div class="dropdown-item tooltip-text-justify">
                                                            <p>
                                                                劈里啪啦的说一堆，反正你也看不懂，我就随便打点字表示这里是说明信息
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                        <div class="pub-place-order-form-stop-pl-input field has-addons">
                                            <div class="pub-place-order-form-stop-pl-input-p control is-expanded">
                                                <input type="number" placeholder="止盈价" step="0.1" pattern="d*" class="input text-font-flex button-input-height">
                                            </div>
                                            <div class="pub-place-order-form-stop-pl-input-center control">&amp;</div>
                                            <div class="pub-place-order-form-stop-pl-input-l control is-expanded">
                                                <input type="number" placeholder="止损价" step="0.1" pattern="d*" class="input text-font-flex button-input-height">
                                            </div>
                                        </div>
                                    </div> -->

            <!-- <div class="pub-place-order-form-trigger-prz-input field">
                                        <div class="control">
                                            <input type="number" placeholder="" readonly="" class="input opacity-0">
                                        </div>
                                    </div> -->
            <div class="spacer"></div>
            <div class="pub-place-order-form-buttons field">
              <div class="level-item">
                <!-- <div class="button-width">
                                <div class="button-default-width">
                                    <button class="button is-success is-fullwidth button-radius text-font-flex ord-buy-sell is-text-3">
                                        卖出/做多(涨)
                                    </button>
                                </div>
                            </div> -->
                <div class="button-width" style="width:100%">
                  <div class="button-default-width">
                    <button class="button is-danger is-fullwidth button-radius text-font-flex ord-buy-sell is-text-3" @click="openWind(TradeType.Sell)">
                      {{ $t('coins.sell') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
          </div>
        </div>
      </div>

      <!-- 右边下面 -->
      <!-- <div class="pub-layout-content-right-wallet  py-0 pl-1">
            <div class="pub-wallet-box box has-text-centered px-0 py-0">
                <div class="pub-wallet-box-tabs tabs is-background-3 px-3 ">
                    <ul class="is-between">
                        <li class=""><a class="has-text-1-important wlt-coin">{{$t('lever_assets.title')}}</a></li>
                    </ul>
                </div>
                <div class="pub-wallet px-3 py-3">
                    <div class="pub-wallet-content has-text-left">
                        <div class="level-item">
                            <div class="text--secondary has-text-2">
                                <div class="my-tooltip dropdown is-hoverable ">
                                    <div class="dropdown-trigger -bdb-dashed spottick-text-color-4">{{$t('lever_assets.total_equity')}}</div>
                                </div>
                            </div>
                            <div class="has-text-1">{{u_total}}</div>
                        </div>
                        <div class="level-item">
                            <div class="text--secondary has-text-2">
                                <div class="my-tooltip dropdown is-hoverable ">
                                    <div class="dropdown-trigger -bdb-dashed spottick-text-color-4">{{$t('lever_assets.equity')}}</div>
                                </div>
                            </div>
                            <div class="has-text-1">{{u_usable}}</div>
                        </div>
                        <div class="level-item">
                            <div class="text--secondary has-text-2">
                                <div class="my-tooltip dropdown is-hoverable ">
                                    <div class="dropdown-trigger -bdb-dashed spottick-text-color-4">{{$t('lever_assets.profit')}}</div>
                                </div>
                            </div>
                            <div class="has-text-1 ">
                                <div class=" ">{{u_lever_profit}}</div>
                            </div>
                        </div>
                        <div class="level-item">
                            <div class="text--secondary has-text-2 spottick-text-color-4">{{$t('lever_assets.risk')}}</div>
                            <div class="has-text-1">{{u_lever_rate}}%</div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

      <div class="pub-layout-content-right-spot-info py-1 pl-1">
        <div class="pub-spot-info box px-0 py-0">
          <div class="pub-spot-info-tabs tabs is-background-3 px-3">
            <ul>
              <li class="">
                <a href="javascript:void(0);" class="has-text-1-important wlt-coin">{{ $t('contract_menu.lever_info') }}</a>
              </li>
            </ul>
          </div>
          <div class="pub-spot-info-content px-3 py-3">
            <div class="level-item">
              <div class="text--secondary spottick-text-color-4">{{ $t('lever_info.pt1') }}</div>
              <div class="has-text-1">{{ currency.desc }}</div>
            </div>
            <div class="level-item">
              <div class="text--secondary spottick-text-color-4">{{ $t('lever_info.pt2') }}</div>
              <div class="has-text-1">0.01 {{ currency.name }}</div>
            </div>
            <div class="level-item">
              <div class="text--secondary spottick-text-color-4">{{ $t('lever_info.pt3') }}</div>
              <div class="has-text-1">0.01</div>
            </div>
            <div class="level-item">
              <div class="text--secondary spottick-text-color-4">{{ $t('lever_info.pt4') }}</div>
              <div class="has-text-1">{{ $t('lever_info.pv4') }}</div>
            </div>
            <div class="level-item">
              <div class="text--secondary spottick-text-color-4">{{ $t('lever_info.pt5') }}</div>
              <div class="has-text-1">{{ $t('lever_info.pv5') }}</div>
            </div>
            <div class="level-item">
              <div class="text--secondary spottick-text-color-4">{{ $t('lever_info.pt11') }}</div>
              <div class="has-text-1">{{ currency.lever_limit_val }}%</div>
            </div>
            <div class="level-item">
              <div class="text--secondary spottick-text-color-4">{{ $t('lever_info.pt12') }}</div>
              <div class="has-text-1">{{ $t('lever_info.pv12') }}</div>
            </div>
            <div class="level-item">
              <div class="text--secondary spottick-text-color-4">{{ $t('lever_info.pt13') }}</div>
              <div class="has-text-1">{{ $t('lever_info.pv13') }}</div>
            </div>
            <div class="level-item">
              <div class="text--secondary spottick-text-color-4">{{ $t('lever_info.pt14') }}</div>
              <div class="has-text-1">{{ $t('lever_info.pv14') }}</div>
            </div>
            <!-- <div class="level-item mb-0-important">
                        <div class="text--secondary has-text-primary cursor-pointer">更多...</div>
                    </div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- 输入密码 -->
    <el-dialog :title="$t('trade.title')" :visible.sync="dialogVisible" :before-close="cancelTrade" class="trade-dialog trade-dg" style="width:50%;margin: 0 auto;top: 20%;">
      <el-row>
        <!-- <el-col :span="24">
                <label style="padding: 10px 0;color:#FFF;">交易密码:</label>
            </el-col> -->
        <el-col :span="24">
          <el-input v-model="tradePwd" type="password" :placeholder="$t('trade.enter_password')" style="width:100%"></el-input>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelTrade">{{ $t('trade.cancel') }}</el-button>
        <el-button @click="confirmTrade">{{ $t('trade.submit') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getWalletLever } from '@/api/user'
import { buyLever, sellLever } from '@/api/currency'
import { Float8, ScToNumber, Event, GetFixed, GetNewFixed } from '../../utils/helper'

const PriceType = {
  // 市价
  Market: 1,
  // 限价
  Limit: 2,
  // 止盈
  Profit: 3,
  // 止损
  Loss: 4
}
const TradeType = {
  Buy: 1,
  Sell: 2
}

const Direction = {
  Buy: 1,
  Sell: 2
}

//默认交易对协议(USDT钱包)
const Defkey = 'USDT'

export default {
  name: 'MetalTrade',
  props: ['price'],
  data() {
    return {
      Defkey,
      PriceType,
      TradeType,
      Direction,

      //交易界面
      showView: TradeType.Buy,

      //当前币
      currency: {
        lever_buy_rate: 0
      },
      //当前钱包
      currentWallet: {
        balance: 0
      },
      //USDT当前钱包
      usdtWallet: {},

      //倍数菜单
      buySelect: false,
      //倍率
      buyRate: '',
      //方向
      buyDirection: 0,

      //-------------------
      //买
      // 数量
      buy_number: '',
      // USDT
      buy_number_usdt: '',
      //交易额
      buy_usdt: '0.00',
      //手续费
      buy_fee_usdt: '0.00',
      //总抵扣
      buy_total: 0,
      //当前价格
      currentBuyPrice: 0,
      //当前点差价格显示
      currentBuyPriceStr: '0.0000 / 0.0000',
      //交易类型
      buyPriceType: PriceType.Market,

      //-------------------
      //卖
      //卖的数量
      sell_number: '',
      // USDT
      sell_number_usdt: '',
      //交易额
      sell_usdt: '0.00',
      //手续费
      sell_fee_usdt: '0.00',
      //总抵扣
      sell_total: 0,
      //当前价格
      currentSellPrice: 0,
      //交易类型
      sellPriceType: PriceType.Market,

      //弹出窗口
      dialogVisible: false,

      //交易密码
      tradePwd: '',
      tradeType: '',

      //token
      token: '',

      //监听用户资产信息
      u_total: 0,
      u_usable: 0,
      u_lever_profit: 0,
      u_lever_rate: 0
    }
  },
  created() {
    console.log('MetalTrade')

    this.checkToken()
  },
  methods: {
    setWallet(res, u_total, u_usable) {
      //总权益
      this.u_total = u_total
      this.u_usable = u_usable
      this.usdtWallet.balance = u_usable

      //风险率
      if (res.lever[this.currency.symbol] >= 0) {
        this.u_lever_profit = GetNewFixed(res.lever[this.currency.symbol].profit)
        this.u_lever_rate = GetNewFixed(res.lever[this.currency.symbol].rate * 100)
      } else {
        this.u_lever_profit = 0
        this.u_lever_rate = 0
      }
    },
    /**
     * 交易界面显示
     */
    changeView(view) {
      this.showView = view
    },
    checkToken() {
      //获取缓存的token
      let token = localStorage.getItem('token')
      if (token) {
        this.token = token
      } else {
        this.token = ''
      }
      return token
    },
    login() {
      this.$router.push({
        name: 'Login'
      })
    },
    signup() {
      this.$router.push({
        name: 'Signup'
      })
    },
    setDetail(detail) {
      this.currency.close = GetFixed(detail.close)
      //更新价格
      this.updatePrice()
    },
    /**
     * 设置币
     */
    setCurrency(currency) {
      this.currency = currency
      //更新价格
      this.updatePrice()
      //获取钱包
      this.getWalletLever()
    },
    /**
     * 获取币币账户
     */
    getWalletLever() {
      if (!this.checkToken()) {
        return
      }
      let _this = this
      getWalletLever({
        c_id: this.currency.id
      }).then(res => {
        //设置当前钱包
        if (_this.currency) {
          if (res.data[_this.currency.name]) {
            _this.currentWallet = res.data[_this.currency.name]
          }
          _this.usdtWallet = res.data[Defkey]
          console.log('[LeverTrade] 当前' + _this.currency.name, _this.currency)
          console.log('[LeverTrade] 当前' + _this.currency.name + '钱包', _this.currentWallet)
          console.log('[LeverTrade] 当前USDT钱包', _this.usdtWallet)
        }
      })
    },
    /**
     * 交易密码弹出框
     */
    openWind(type, direction) {
      if (type == TradeType.Buy) {
        //买的方向
        this.buyDirection = direction
        if (!this.validBuy()) {
          return
        }
      }

      if (type == TradeType.Sell) {
        if (!this.validSell()) {
          return
        }
      }

      this.tradeType = type
      this.tradePwd = ''
      // this.dialogVisible = true
      this.confirmTrade()
    },
    cancelTrade() {
      console.log('交易密码')
      this.dialogVisible = false
    },
    confirmTrade() {
      console.log('确认交易密码')
      this.dialogVisible = false
      if (this.tradeType == TradeType.Buy) {
        this.onBuy()
      }
      if (this.tradeType == TradeType.Sell) {
        this.onSell()
      }
    },
    updatePrice() {
      if (this.buyPriceType == PriceType.Market) {
        //更新价格 [通过改变输入框，重新计算交易额]
        this.currentBuyPrice = this.currency.close
        //[调用]
        this.autoBuyNumber()
      }

      if (this.sellPriceType == PriceType.Market) {
        //更新价格 [通过改变输入框，重新计算交易额]
        this.currentSellPrice = this.currency.close
        //[调用]
        this.autoSellNumber()
      }

      //价格计算点差值
      // console.log(this.currency)
      let cha = this.getCha()
      let price = parseFloat(this.currency.close)

      let buy = Float8(price + cha)
      let sell = Float8(price - cha)
      this.currentBuyPriceStr = buy + ' / ' + sell
    },
    getCha() {
      let limitVal = this.currency.lever_limit_val ? this.currency.lever_limit_val : 0
      // let price = parseFloat(this.currency.close)

      let cha = 0
      if (limitVal > 0) {
        // limitVal = parseFloat(limitVal / 100)
        // cha = Float8(price * limitVal)
        cha = limitVal
      }

      // console.log(limitVal, cha, price, "点差")
      return cha
    },
    /**--------------------------------------------------
     * 价格类型
     */
    btnBuyPrice(type) {
      this.buyPriceType = type
      // 立即更新
      this.updatePrice()
    },
    /**
     * 输入过滤
     */
    _BuyGetNumber() {
      //数初始化
      let number = this.buy_number
      if (number) {
        let number = Number(number)

        //
      }

      //数据过滤
      if (number < 0) {
        this.buy_number = ''
        // console.log(number, '数据负值')
        return 0
      }
      if (!number || number == 0) {
        // console.log(number, '数据无值')
        this._SetBuyParams({
          number: number,
          real_usdt: '0.00',
          usdt: '0.00',
          fee: '0.00',
          total: 0
        })
        return 0
      }

      //console.log(number, '数据正常')
      number = ScToNumber(number)
      //console.log(number, '数据转换')

      return number
    },
    /**
     * 获取基本参数
     */
    _BuyGetBase() {
      //基本参数
      let price = parseFloat(this.currentBuyPrice)
      let rate = parseFloat(this.currency.lever_buy_rate / 100)
      let fee_min = parseFloat(this.currency.lever_buy_min)
      let balance = parseFloat(this.usdtWallet.balance)
      let buy_rate = parseFloat(this.buyRate)
      let unit_scale = parseFloat(this.currency.unit_scale)
      //console.log("当前价格：" + price)
      //console.log("倍数：" + this.buyRate)
      //console.log("费率：" + rate)
      //console.log("最小手续费：" + fee_min)
      //console.log("可用：" + balance + " USDT")

      return {
        price: price,
        rate: rate,
        fee_min: fee_min,
        balance: balance,
        buy_rate: buy_rate ? buy_rate : 1,
        unit_scale: unit_scale
      }
    },
    /**
     * 计算结果设置
     */
    _SetBuyParams(res) {
      let that = this;
      setTimeout(function() {
        that.buy_usdt = res.real_usdt;
        that.buy_number = res.number;
        that.buy_fee_usdt = res.fee;
        that.buy_total = res.total;
      }, 50);
    },
    /**
     * 已知数量
     */
    _BuyGetTotalBak(number) {
      if (!number || number < 0) {
        return {
          number: '',
          real_usdt: '0.00',
          usdt: '0.00',
          fee: '0.00',
          total: 0
        }
      }

      //基本参数
      let base = this._BuyGetBase()

      //交易额 = 单价 * 数量 * 单位缩放
      let usdt = Float8(number * base.price * base.unit_scale)

      //手续费
      let fee = Float8(usdt * base.rate)
      if (fee < base.fee_min) {
        fee = base.fee_min
      }

      //实际支付 保证金 = 数量 * 单价 / 倍数
      let realUsdt = Float8(usdt / base.buy_rate)

      //实际扣除
      let total = Float8(realUsdt + fee)

      return {
        number: number,
        usdt: usdt,
        real_usdt: realUsdt,
        fee: fee,
        total: total
      }
    },
        /**
     * 已知数量
     */
     _BuyGetTotal(number) {
      console.log(number, parseFloat(number), "number");

      let usdt = 0;
      let realUsdt = 0;
      let fee = 0;
      let total = 0;

      console.log(this.currency,"this.currency")

      if (parseFloat(number) > 0) {
        //保留两位
        number = parseFloat(
          parseFloat(number).toFixed(this.currency.trade_decimal)
        );

        //基本参数
        let base = this._BuyGetBase();

        //交易额 = 单价 * 数量 * 单位缩放
        usdt = Float8(number * base.price * base.unit_scale);

        //手续费
        fee = Float8(usdt * base.rate);
        if (fee < base.fee_min) {
          fee = base.fee_min;
        }

        //实际支付 保证金 = 数量 * 单价 / 倍数
        realUsdt = Float8(usdt / base.buy_rate);

        //实际扣除
        total = Float8(realUsdt + fee);
      }

      //无效输入
      if (isNaN(parseFloat(number))) {
        number = "";
      }

      //返回原
      return {
        number: number,
        usdt: usdt,
        real_usdt: realUsdt,
        fee: fee,
        total: total
      };
    },
    /**
     * 根据总额求数量
     */
    _BuyGetTotal2Bak(total) {
      if (!total || total < 0) {
        return {
          number: '',
          usdt: '0.00',
          real_usdt: '0.00',
          fee: '0.00',
          total: 0
        }
      }
      //基本参数
      let base = this._BuyGetBase()

      //购买数量 [推算公式] 扣除 = (x * 单价) + (x * 单价)*费率
      let number = Float8(total / (base.price / base.buy_rate + base.rate * base.price) / base.unit_scale)

      //交易额 = 单价 * 数量 * 单位缩放
      let usdt = Float8(number * base.price * base.unit_scale)

      //实际支付
      let realUsdt = Float8(usdt / base.buy_rate)

      //计算费率
      let fee = Float8(usdt * base.rate)
      if (fee < base.fee_min) {
        fee = base.fee_min

        //重新计算 实际购买手 = （余额 - 最低手续费）/ 单价 /比例
        number = Float8((total - fee) / base.price / base.unit_scale)
        //交易额
        usdt = Float8(number * base.unit_scale * base.price)

        if (!number || number < 0) {
          this.$message({
            message: '可用量不足',
            type: 'warning'
          })
          return {
            number: '',
            usdt: '0.00',
            real_usdt: '0.00',
            fee: '0.00',
            total: 0
          }
        }
      }

      //矫正手续费补差
      let real_fee = Float8(total - realUsdt)
      if (real_fee < 0) {
        real_fee = 0
      }

      return {
        number: number,
        usdt: usdt,
        real_usdt: realUsdt,
        fee: real_fee,
        total: total
      }
    },
    /**
     * 根据总额求数量
     */
     _BuyGetTotal2(realUsdt) {
      console.log(realUsdt, parseFloat(realUsdt), "realUsdt");

      realUsdt = parseFloat(realUsdt);
      let number = 0;
      let usdt = 0;
      let fee = 0;
      let total = 0;

      if (realUsdt > 0) {
        //保留两位
        realUsdt = parseFloat(parseFloat(realUsdt).toFixed(0));

        //基本参数
        let base = this._BuyGetBase();

        //市值 = 数量 * 单价 * 手
        //保证金 = 市值 / 100倍
        //手续费 = 市值 * 费率 ?

        //市值 = 保证金 * 倍数
        usdt = Float8(realUsdt * base.buy_rate);

        //数量 = 市值 / (单价 * 手)
        number = Float8(usdt / (base.price * base.unit_scale));

        //保留两位
        number = parseFloat(number.toFixed(this.currency.trade_decimal));

        //手续费 = 市值 * 费率
        fee = Float8(usdt * base.rate);
        if (fee < base.fee_min) {
          fee = base.fee_min;
        }

        //实际扣除
        total = Float8(realUsdt + fee);
      }

      //无效输入
      if (isNaN(realUsdt)) {
        realUsdt = "";
      }

      //保证金为空
      if (realUsdt == "") {
        number = "";
        realUsdt = 0;
      }

      //返回原
      return {
        number: number,
        usdt: usdt,
        real_usdt: realUsdt,
        fee: fee,
        total: total
      };
    },

    /**
     * 半/全仓 更新
     */
    btnBuySet(num) {
      if (num == 1) {
        //全仓抵扣额
        let base = this._BuyGetBase()
        let res = this._BuyGetTotal2(base.balance)
        this._SetBuyParams(res)
        //console.log(res, '结果')
      } else if (num == 0.5) {
        //半仓抵扣额
        let base = this._BuyGetBase()
        let res = this._BuyGetTotal2(base.balance / 2)
        this._SetBuyParams(res)
        //console.log(res, '结果')
      }
    },
    /**
     * 输入USDT
     */
    inputBuyNumberUsdt() {
      //计算
      let res = this._BuyGetTotal2(this.buy_number_usdt)
      this._SetBuyParams(res)
    },
    /**
     * 输入数量更新
     */
    inputBuyNumber() {
      //过滤
      this.buy_number_usdt = "";
      let number = this._BuyGetNumber()
      if (!number) {
        return
      }

      //计算
      let res = this._BuyGetTotal(number)
      this._SetBuyParams(res)
      //console.log(res, '输入变化')
    },
    /**
     * 价格更新
     */
    autoBuyNumber() {
      //过滤
      let number = this._BuyGetNumber()
      if (!number) {
        return
      }

      //计算
      let res = this._BuyGetTotal(number)
      //console.log(res, '价格自动变化')

      //校验
      let base = this._BuyGetBase()
      if (res.total > base.balance) {
        //再次计算
        res = this._BuyGetTotal2(base.balance)
        //console.log(res, '价格自动变化')
      }

      this._SetBuyParams(res)
    },
    validBuy() {
      if (!this.buy_number) {
        this.$message({
          message: this.$t('message.invalid_number'),
          type: 'warning'
        })
        return false
      }

      if (!this.buyRate) {
        this.$message({
          message: this.$t('message.invalid_rate'),
          type: 'warning'
        })
        return false
      }

      //检测限价价格
      if (this.buyPriceType == 2) {
        let price = parseFloat(this.currency.close)
        let currentPrice = parseFloat(this.currentBuyPrice)
        // let cha = this.getCha()

        if (this.buyDirection == 1) {
          //涨
          let limitBuyPrice = Float8(price)
          if (currentPrice >= limitBuyPrice) {
            this.$message({
              message: this.$t('message.price_low', {
                price: limitBuyPrice
              }),
              type: 'warning'
            })
            return false
          }
        } else if (this.buyDirection == 2) {
          //跌
          let limitBuyPrice = Float8(price)
          if (currentPrice <= limitBuyPrice) {
            this.$message({
              message: this.$t('message.price_high', {
                price: limitBuyPrice
              }),
              type: 'warning'
            })
            return false
          }
        }
      }
      return true
    },
    onBuy() {
      //loading
      this.$ui.openLoading()

      buyLever({
        price_type: this.buyPriceType,
        price: this.currentBuyPrice,
        buy_rate: this.buyRate,
        direction: this.buyDirection,
        number: this.buy_number,
        c_id: this.currency.id,
        trade_pwd: this.tradePwd
      })
        .then(res => {
          //close
          this.$ui.closeLoading()

          if (res.code == 1) {
            this.$message({
              message: res.msg,
              type: 'error'
            })
            return
          }

          this.$message({
            message: res.msg,
            type: 'success'
          })
          console.log(res)
          //重新获取
          this.getWalletLever()
          //通知
          Event.$emit('onCoinsTrade')
        })
        .catch(() => {
          //close
          this.$ui.closeLoading()
        })
    },

    /**--------------------------------------------------
     * 价格类型
     */
    btnSellPrice(type) {
      this.sellPriceType = type
      // 立即更新
      this.updatePrice()
    },
    /**
     * 输入过滤
     */
    _SellGetNumber() {
      //数初始化
      let number = this.sell_number
      if (number) {
        let number = Number(number)
      }

      //数据过滤
      if (number < 0) {
        this.sell_number = ''
        // console.log(number, '数据负值')
        return 0
      }
      if (!number || number == 0) {
        // console.log(number, '数据无值')
        this._SetSellParams({
          number: number,
          profit: '0.00',
          usdt: '0.00'
        })
        return 0
      }

      //console.log(number, '数据正常')
      number = ScToNumber(number)
      //console.log(number, '数据转换')

      return number
    },
    /**
     * 获取基本参数
     */
    _SellGetBase() {
      //基本参数
      let price = parseFloat(this.currentSellPrice)
      let balance = parseFloat(this.currentWallet.balance)
      let bond = parseFloat(this.currentWallet.bond)
      let real_usdt = parseFloat(this.currentWallet.real_usdt)
      let direction = parseFloat(this.currentWallet.direction)

      //console.log("当前价格：" + price)
      //console.log("可用：" + balance + " " + this.currentWallet.name)

      return {
        price: price,
        balance: balance,
        bond: bond,
        real_usdt: real_usdt,
        direction: direction
      }
    },
    /**
     * 计算结果设置
     */
    _SetSellParams(res) {
      let that = this;
      setTimeout(function() {
        that.sell_usdt = res.profit;
        that.sell_number = res.number;
      }, 50);
    },
    /**
     * 已知数量
     */
    _SellGetTotalBak(number) {
      if (!number || number < 0) {
        return {
          number: '',
          profit: '0.00'
        }
      }

      //基本参数
      let base = this._SellGetBase()

      //交易额
      let real_usdt = Float8(number * base.price)

      //求得本次比例
      let rate = Float8(number / base.balance)
      //本次释放保证金
      let bond = Float8(rate * base.bond)
      //本次释放市值
      let usdt = Float8(rate * base.real_usdt)

      //本次收益
      let profit = Float8((real_usdt - usdt) * (base.direction == 1 ? 1 : -1) + bond)

      return {
        real_usdt: real_usdt,
        number: number,
        profit: profit ? profit : '0.00',
        rate: rate,
        bond: bond,
        usdt: usdt
      }
    },
     /**
     * 已知数量
     */
     _SellGetTotal(number) {
      console.log(number, parseFloat(number), "number");

      let usdt = 0;
      let real_usdt = 0;
      let bond = 0;
      let profit = 0;
      let rate = 0;

      if (parseFloat(number) > 0) {
        //保留两位
        number = parseFloat(
          parseFloat(number).toFixed(this.currency.trade_decimal)
        );

        //基本参数
        let base = this._SellGetBase();

        //交易额
        real_usdt = Float8(number * base.price);

        //求得本次比例
        rate = Float8(number / base.balance);
        //本次释放保证金
        bond = Float8(rate * base.bond);
        //本次释放市值
        usdt = Float8(rate * base.real_usdt);

        //本次收益
        profit = Float8(
          (real_usdt - usdt) * (base.direction == 1 ? 1 : -1) + bond
        );
      }

      return {
        real_usdt: real_usdt,
        number: number,
        profit: profit,
        rate: rate,
        bond: bond,
        usdt: usdt
      };
    },
    /**
     * 根据总额求数量
     */
    _SellGetTotal2(bond) {
      console.log(bond, parseFloat(bond), 'bond')

      bond = parseFloat(bond)
      let usdt = 0
      let real_usdt = 0
      let profit = 0
      let rate = 0
      let number = 0

      if (bond > 0) {
        //基本参数
        let base = this._SellGetBase()

        console.log(base,'base')

        //求得本次比例
        rate = Float8(bond / base.bond)

        //获得数量
        number = Float8(rate * base.balance)

        //保留两位
        number = parseFloat(number.toFixed(this.currency.trade_decimal))

        //交易额
        real_usdt = Float8(number * base.price)

        //本次释放市值
        usdt = Float8(rate * base.real_usdt)

        //本次收益
        profit = Float8((real_usdt - usdt) * (base.direction == 1 ? 1 : -1) + bond)
      }

      return {
        real_usdt: real_usdt,
        number: number,
        profit: profit,
        rate: rate,
        bond: bond,
        usdt: usdt
      }
    },
    /**
     * 半/全仓
     */
    btnSellSet(num) {
      if (num == 1) {
        //全仓卖出
        let base = this._SellGetBase()
        let res = this._SellGetTotal(base.balance)
        this._SetSellParams(res)
        //console.log(res, '结果')
      } else if (num == 0.5) {
        //半仓卖出
        let base = this._SellGetBase()
        let res = this._SellGetTotal(base.balance / 2)
        this._SetSellParams(res)
        //console.log(res, '结果')
      }
    },
    /**
     * 输入USDT
     */
    inputSellNumberUsdt() {
      //计算
      let res = this._SellGetTotal2(this.sell_number_usdt)
      this._SetSellParams(res)
    },
    /**
     * 输入数量更新
     */
    inputSellNumber() {
      //过滤
      let number = this._SellGetNumber()
      if (!number) {
        return
      }

      //计算
      let res = this._SellGetTotal(number)
      this._SetSellParams(res)
      //console.log(res, '结果')
    },
    /**
     * 价格更新
     */
    autoSellNumber() {
      //过滤
      let number = this._SellGetNumber()
      if (!number) {
        return
      }
      //计算
      let res = this._SellGetTotal(number)
      this._SetSellParams(res)
      //console.log(res, '结果')
    },
    validSell() {
      if (!this.sell_number) {
        this.$message({
          message: this.$t('message.invalid_number'),
          type: 'warning'
        })
        return false
      }

      let price = parseFloat(this.currency.close)
      let currentPrice = parseFloat(this.currentSellPrice)
      let cha = this.getCha()

      //止盈
      if (this.sellPriceType == 3) {
        if (this.currentWallet.direction == 1) {
          //涨
          let limitSellPrice = Float8(price + cha)
          if (currentPrice <= limitSellPrice) {
            this.$message({
              message: this.$t('message.price_high', {
                price: limitSellPrice
              }),
              type: 'warning'
            })
            return false
          }
        } else if (this.currentWallet.direction == 2) {
          //跌
          let limitSellPrice = Float8(price - cha)
          if (currentPrice >= limitSellPrice) {
            this.$message({
              message: this.$t('message.price_low', {
                price: limitSellPrice
              }),
              type: 'warning'
            })
            return false
          }
        }
      }

      // 止损
      if (this.sellPriceType == 4) {
        if (this.currentWallet.direction == 1) {
          //涨
          let limitSellPrice = Float8(price - cha)
          if (currentPrice >= limitSellPrice) {
            this.$message({
              message: this.$t('message.price_low', {
                price: limitSellPrice
              }),
              type: 'warning'
            })
            return false
          }
        } else if (this.currentWallet.direction == 2) {
          //跌
          let limitSellPrice = Float8(price + cha)
          if (currentPrice <= limitSellPrice) {
            this.$message({
              message: this.$t('message.price_high', {
                price: limitSellPrice
              }),
              type: 'warning'
            })
            return false
          }
        }
      }
      return true
    },
    onSell() {
      console.log(this.sell_number, this.currentSellPrice)
      //loading
      this.$ui.openLoading()

      sellLever({
        price_type: this.sellPriceType,
        price: this.currentSellPrice,
        number: this.sell_number,
        direction: this.currentWallet.direction,
        c_id: this.currentWallet.c_id,
        trade_pwd: this.tradePwd
      })
        .then(res => {
          //close
          this.$ui.closeLoading()

          if (res.code == 1) {
            this.$message({
              message: res.msg,
              type: 'error'
            })
            return
          }

          this.$message({
            message: res.msg,
            type: 'success'
          })
          console.log(res)

          //重新获取
          this.getWalletLever()
          //通知
          Event.$emit('onCoinsTrade')
        })
        .catch(() => {
          //close
          this.$ui.closeLoading()
        })
    },
    openBuySelect() {
      this.buySelect = !this.buySelect
    },
    onBuyRate(num) {
      this.openBuySelect()
      console.log(num)
      this.buyRate = num
    }
  }
}
</script>

<style src="../../../static/css/lever.css" scoped type="text/css"></style>
