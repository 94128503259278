export default `<p>
    <strong>COMP（コンパウンド）</strong>
</p>
<p>
    <br/>
</p>
<p>
    1.プロジェクト名
</p>
<p>
    コンパウンド（Comp）
</p>
<p>
    <br/>
</p>
<p>
    2.プロジェクトのポジショニング
</p>
<p>
    Compoundは、開発者が一連のオープンな金融アプリケーションのロックを解除するために構築された、アルゴリズムによる自律的な金利契約です。 化合物は、イーサリアムに基づく公的にアクセス可能なスマートコントラクトシステムであり、貸し手がローンを提供している間、借り手が暗号化された資産を契約にロックすることによってローンを取得できるようにすることを約束します。 借り手と貸し手が支払ったり受け取ったりする金利は、暗号化された各資産の需要と供給によって決まります。 各ブロックをマイニングすると、金利が生成されます。 ローンはいつでも返済でき、ロックされた資産は撤回できます。
</p>
<p>
    に
</p>
<p>
    3、通貨の概要と流通
</p>
<p>
    合計トークン：10,000,000 COMP
</p>
<p>
    循環：2,561,279 COMP
</p>
<p>
    に
</p>
<p>
    4つの一般的に使用されるリンク
</p>
<p>
    プロジェクトのウェブサイト：https：//compound.finance/
</p>
<p>
    ホワイトペーパー：https：//compound.finance/documents/Compound.Whitepaper.pdf
</p>
<p>
    ブロッククエリ：https：//etherscan.io/token/0xc00e94cb662c3520282e6f5717214004a7f26888
</p>
<p>
    <br/>
</p>`